import DateFnsUtils from "@date-io/date-fns";
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Button from "components/ui/buttons/Button";
import { format, parse } from "date-fns";
import endpoints from "endpoints";
import { Form, FormikProvider, useFormik } from "formik";
import { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import useSWR from "swr";
import { IGodmodeSubscription } from "./IGodmodeSubscription";
import React from "react";

export const EditPaymentDueDateDialog: React.FC<DialogStateProps & { subscriptionId: number }> = ({ subscriptionId, open, onClose }) => {
  const { data } = useSWR<IGodmodeSubscription>(endpoints.subscriptionById(subscriptionId));

  const form = useFormik({
    initialValues: {
      id: subscriptionId,
      payment_due: data?.purchase_process?.payment_due
    },
    onSubmit: values => {
      return justFetch(endpoints.godmode.purchase(data?.purchase_process?.id!), 'PUT', values)
        .then(res => {
          if (res.ok) {
            onClose();
          }
        })
    }
  });

  return <Dialog open={open}>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DialogTitle>Edit Payment Due Date</DialogTitle>
      <DialogContent>
        <FormikProvider value={form}>
          <Form onSubmit={form.handleSubmit}>
            <Typography variant="subtitle1">Payment Due Date</Typography>
            <DatePicker
              onChange={date => { form.setFieldValue('payment_due', format(date as Date, 'yyyy-MM-dd')); }}
              value={parse(form.values.payment_due!, 'yyyy-MM-dd', new Date())}
              format="MM/dd/yyyy"
              inputVariant="outlined"
              InputProps={{
                margin: 'dense'
              }}
            />
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            form.resetForm();
            onClose();
          }}
          variant="outlined"
          disabled={form.isSubmitting}
        >Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={form.submitForm}
          disabled={form.isSubmitting}
        >Save</Button>
      </DialogActions>
    </MuiPickersUtilsProvider>
  </Dialog>;
}