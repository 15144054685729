import { useState } from "react";

export interface DialogStateProps {
  open: boolean;
  onClose: () => void;
  handleOpen?: () => void;
}

const useDialogState = (initialState: boolean = false) => {
  const [open, setOpen] = useState(initialState);

  return {
    open,
    onClose: () => setOpen(false),
    handleOpen: () => setOpen(true)
  }
}

export default useDialogState;