import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons/faFacebook";
import Button from "./Button";
import {Link} from "@material-ui/core";
import React from "react";

const FacebookShareButton: React.VFC<{shareUrl: string}> = ({shareUrl}) => {
  return (
    <Link component={Button}
          href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
          underline="none"
          style={{backgroundColor: '#306199', color: "white", fontSize: 12, paddingLeft: 15, paddingRight: 15, width: 125}}
          startIcon={<FontAwesomeIcon icon={faFacebook} color={'white'}/>}>
      FACEBOOK
    </Link>
  )
}

export default FacebookShareButton