import * as Sentry from '@sentry/react';
import ErrorPage from 'pages/ErrorPage';
import React from "react";

if (process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn:
      'https://9039cd44584e422c8b7fb9b9239a610d@o259245.ingest.sentry.io/5545307',
    // integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const SentryProvider: React.FC = ({ children }) => {
    return <Sentry.ErrorBoundary fallback={ErrorPage}>{children}</Sentry.ErrorBoundary>
}

export default SentryProvider;