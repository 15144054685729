import React, { useState, Fragment } from 'react';
import Modal from 'components/modal/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Amplitude from 'utils/Amplitude';
import {IResource} from "../../../types/IResource";


interface ShareButtonProps {
  video: IResource;
}

interface ShareModalProps {
  video: IResource;
  closeModal: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ video, closeModal }) => {
  const shareText = 'Check out this coding lesson from @kodable';
  const [copied, setCopied] = useState(false);

  function onFacebookShare() {
    Amplitude.track('Video Shared', {
      video_id: video.id,
      shared_to: 'Facebook',
    });
  }

  function onTwitterShare() {
    Amplitude.track('Video Shared', {
      video_id: video.id,
      shared_to: 'Twitter',
    });
  }

  function onEmailShare() {
    Amplitude.track('Video Shared', {
      video_id: video.id,
      shared_to: 'Email',
    });
  }

  return (
    <Modal closeModal={closeModal}>
      <div className="w-full flex-shrink-0 flex flex-col justify-center items-center">
        <div className="relative font-bold text-3xl text-white w-full p-6 bg-purple-600 text-center mx-auto flex flex-col justify-center items-center">
          <svg
            className="h-12 w-12"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
            />
          </svg>
          Share Video
        </div>
        <div className="bg-gray-100 py-10 px-4 border-b border-gray-300 w-full">
          <div>
            <div>
              <ul className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-1">
                <li className="flex-1">
                  <a
                    onClick={onFacebookShare}
                    className="flex px-1 sm:px-2 py-2 justify-center items-center text-white facebookButton"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://www.kodable.com/videos/${video.id}`}>
                    <span>
                      <svg
                        fill="currentColor"
                        className="w-4 h-4 mr-2 sm:mr-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 29 29">
                        <path d="M26.4 0H2.6C1.714 0 0 1.715 0 2.6v23.8c0 .884 1.715 2.6 2.6 2.6h12.393V17.988h-3.996v-3.98h3.997v-3.062c0-3.746 2.835-5.97 6.177-5.97 1.6 0 2.444.173 2.845.226v3.792H21.18c-1.817 0-2.156.9-2.156 2.168v2.847h5.045l-.66 3.978h-4.386V29H26.4c.884 0 2.6-1.716 2.6-2.6V2.6c0-.885-1.716-2.6-2.6-2.6z"></path>
                      </svg>
                    </span>
                    <span className="uppercase text-xs sm:text-sm font-semibold">
                      facebook
                    </span>
                  </a>
                </li>
                <li className="flex-1">
                  <a
                    onClick={onTwitterShare}
                    className="flex px-1 sm:px-2 py-2 justify-center items-center text-white twitterButton"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://twitter.com/intent/tweet?hashtags=kidscancode%2Ccs4all&text=${shareText}&tw_p=tweetbutton&url=https://www.kodable.com/videos/${video.id}`}>
                    <span>
                      <svg
                        className="w-4 h-4 mr-2 sm:mr-4"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 28 28">
                        <path d="M24.253 8.756C24.69 17.08 18.297 24.182 9.97 24.62a15.093 15.093 0 0 1-8.86-2.32c2.702.18 5.375-.648 7.507-2.32a5.417 5.417 0 0 1-4.49-3.64c.802.13 1.62.077 2.4-.154a5.416 5.416 0 0 1-4.412-5.11 5.43 5.43 0 0 0 2.168.387A5.416 5.416 0 0 1 2.89 4.498a15.09 15.09 0 0 0 10.913 5.573 5.185 5.185 0 0 1 3.434-6.48 5.18 5.18 0 0 1 5.546 1.682 9.076 9.076 0 0 0 3.33-1.317 5.038 5.038 0 0 1-2.4 2.942 9.068 9.068 0 0 0 3.02-.85 5.05 5.05 0 0 1-2.48 2.71z"></path>
                      </svg>
                    </span>
                    <span className="uppercase text-xs sm:text-sm font-semibold">
                      twitter
                    </span>
                  </a>
                </li>
                <li className="flex-1">
                  <a
                    onClick={onEmailShare}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex px-1 sm:px-2 py-2 justify-center items-center text-white emailButton"
                    href={`mailto:?subject=${shareText}&body=${video.description} https://www.kodable.com/videos/${video.id}`}>
                    <span>
                      <svg
                        className="w-4 h-4 mr-2 sm:mr-4"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 28 28">
                        <path d="M20.11 26.147c-2.335 1.05-4.36 1.4-7.124 1.4C6.524 27.548.84 22.916.84 15.284.84 7.343 6.602.45 15.4.45c6.854 0 11.8 4.7 11.8 11.252 0 5.684-3.193 9.265-7.398 9.3-1.83 0-3.153-.934-3.347-2.997h-.077c-1.208 1.986-2.96 2.997-5.023 2.997-2.532 0-4.36-1.868-4.36-5.062 0-4.75 3.503-9.07 9.11-9.07 1.713 0 3.7.4 4.6.972l-1.17 7.203c-.387 2.298-.115 3.3 1 3.4 1.674 0 3.774-2.102 3.774-6.58 0-5.06-3.27-8.994-9.304-8.994C9.05 2.87 3.83 7.545 3.83 14.97c0 6.5 4.2 10.2 10 10.202 1.987 0 4.09-.43 5.647-1.245l.634 2.22zM16.647 10.1c-.31-.078-.7-.155-1.207-.155-2.572 0-4.596 2.53-4.596 5.53 0 1.5.7 2.4 1.9 2.4 1.44 0 2.96-1.83 3.31-4.088l.592-3.72z"></path>
                      </svg>
                    </span>
                    <span className="uppercase text-xs sm:text-sm font-semibold">
                      email
                    </span>
                  </a>
                </li>
                <li className="flex-1">
                  <CopyToClipboard
                    text={`https://www.kodable.com/videos/${video.id}`}
                    onCopy={() => {
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 500);
                    }}>
                    <button className="flex px-1 sm:px-2 py-2 justify-center items-center text-white copyButton">
                      <span>
                        <svg
                          className="w-4 h-4 mr-2 sm:mr-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor">
                          <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                          <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                        </svg>
                      </span>
                      <span className="uppercase text-xs sm:text-sm font-semibold">
                        {copied ? 'COPIED' : 'COPY LINK'}
                      </span>
                    </button>
                  </CopyToClipboard>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-end my-4 px-4 w-full">
          <button
            type="button"
            onClick={closeModal}
            className="flex items-center justify-center text-gray-500 border border-gray-200 bg-white hover:bg-gray-100 active:bg-gray-200 px-4 py-2 rounded">
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}

const ShareButton: React.FC<ShareButtonProps> = ({ video }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      {open && <ShareModal closeModal={() => setOpen(false)} video={video} />}
      <button
        onClick={() => setOpen(true)}
        className="bg-purple-800 text-white px-4 py-2 rounded-md flex justify-center items-center">
        <svg
          className="w-4 h-4 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
          />
        </svg>
        <span>Share Public Link</span>
      </button>
    </Fragment>
  );
}

export default ShareButton;
