import { useState } from 'react';
import OnboardingLayout from './shared/OnboardingLayout';
import useParentInit, { reload } from 'loaders/useParentInit';
import useCreateStudent from 'mutations/useCreateStudent';
import { useAlertDispatch } from 'context/AlertProvider';
import { useHistory } from 'react-router-dom';
import Amplitude from 'utils/Amplitude';
import { useEffect } from 'react';
import { routes } from 'routes';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  buttonAddLearner: {
    marginRight: 25,
    [theme.breakpoints.down('md')]: {
      marginRight: 0
    }
  },
  buttonNext: {

  }
}))

const AddPlayerPage = () => {
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const { students, loading } = useParentInit();
  const createStudent = useCreateStudent();
  const dispatch = useAlertDispatch();
  const history = useHistory();
  const classes = useStyles()

  useEffect(() => {
    Amplitude.track('(Parent Onboarding) Viewed Add Player Page');
  }, []);

  if (loading) {
    return null;
  }

  // AB testing for intro page.
  // This A/B test exists in LinkPlayer and BaseAuthRoute as well.
  const to = routes.parentOnboarding.intro;

  function submitForm(to: string | undefined) {
    // just skip to next page if at max students
    if (students && students.length === 4 && to) {
      history.push(to);
      return;
    }

    // skip to next page if student already created.
    if (students && students.length > 0 && name === '' && to) {
      history.push(to);
      return;
    }

    if (name === '') {
      return;
    }

    setSubmitting(true);

    createStudent({ name }).then((student) => {
      if (student.id) {
        reload()
          .then(() => {
            dispatch({
              id: 'addStudent',
              type: 'success',
              title: 'Student Created!',
              message: `${student.name}’s profile is created.`,
            });
            setTimeout(() => {
              dispatch({ type: 'removeAlert', id: 'addStudent' });
            }, 3000);
            setName('');
            setSubmitting(false);
            if (to) {
              history.push(to);
            }
          })
          .catch(() => {
            setSubmitting(false);
          });
      } else {
        setSubmitting(false);
      }
    });
  }

  return (
    <OnboardingLayout
      imgName="spaceship"
      imgSrc="/images/onboarding/space-ship.jpg"
      title="Welcome to Kodable! Let’s go!"
      activeSteps={1}
    >

      {students && students.length > 0 ? (
        <div className="mt-8 ml-4 gap-y-4 grid grid-rows-2">
          {students.map((student) => (
            <div
              className="flex items-center font-bold text-gray-600 mr-16"
              key={student.id}>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="w-5 h-5 text-kodable-green mr-2">
                <path
                  fill="currentColor"
                  d="M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z"></path>
              </svg>{' '}
              {student.name}
            </div>
          ))}
        </div>
      ) : (
        <p className="mt-4 text-center lg:text-left">
          Add your first learner below. You can add up to four players.
        </p>
      )}
      <form
        className="flex justify-center lg:justify-start"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
        }}>
        {students && students.length <= 3 ? (
          <div className="mt-8 flex flex-col space-y-2 max-w-xs ml-2">
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="border rounded text-gray-600 border-gray-400 text-lg px-3 py-2 bg-gray-50 block mb-16"
              type="text"
              placeholder="Your child's name"
              name="name"
            />
          </div>
        ) : (
          <div></div>
        )}
      </form>
      <div className={classes.buttons}>
        {students && students.length <= 3 ? (
          <button
            disabled={name === '' || submitting}
            onClick={() => submitForm(to)}
            className={`${classes.buttonAddLearner} bg-kodable-blue mb-16 hover:bg-kodable-blue-dark active:bg-kodable-blue-darker text-white justify-center items-center font-semibold text-lg flex px-12 py-2 rounded shadow-kodable text-center disabled:opacity-50 disabled:cursor-not-allowed`}>
            Add another learner
          </button>
        ) : (
          <div className="w-64"></div>
        )}
        <button
          disabled={(students && students.length === 0 && name === '') || submitting}
          onClick={() => submitForm(to)}
          className="bg-kodable-orange hover:bg-kodable-orange-dark active:bg-kodable-orange-darker text-white items-center font-semibold text-lg px-4 flex py-2 rounded shadow-kodable text-center w-32 disabled:opacity-50 disabled:cursor-not-allowed">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="w-6 h-6 mr-4">
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
          </svg>
          Next
        </button>
      </div>
    </OnboardingLayout>
  );
}

export default AddPlayerPage