import { useParams } from 'react-router-dom';
import useParentInit from 'loaders/useParentInit';
import find from 'lodash/find';
import {IRouteParams} from "../AppRoutes";
import {IStudent} from "../types/IStudent";

interface IUseStudent extends IStudent {
  mutate: any,
}

const useStudent = (): IUseStudent =>  {
  const { studentId } = useParams<IRouteParams>();
  const { students, mutate, data } = useParentInit();
  const student = find(students, {
    id: Number(studentId),
  });


  return {
    ...student!,
    mutate,
    custom_fuzzes: student?.custom_fuzzes ? student.custom_fuzzes : [],
    selected_fuzz: student?.selected_fuzz,
  };
}

export default useStudent