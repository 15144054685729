import DateFnsUtils from "@date-io/date-fns";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Switch, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import { differenceInMonths, format, parse } from "date-fns";
import endpoints from "endpoints";
import { Form, FormikProvider, useFormik } from "formik";
import useDialogState, { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { DowngradeSubscriptionDialog } from "./DowngradeSubscriptionDialog";
import { EditPaymentDueDateDialog } from "./EditPaymentDueDateDialog";
import { IGodmodeSubscription } from "./IGodmodeSubscription";
import { SubscriptionDeleteButton } from "pages/godmode/Subscriptions/delete/SubscriptionDeleteButton";

export const EditSubscriptionDialog: React.FC<DialogStateProps & { subscriptionId: number }> = ({ open, onClose, subscriptionId }) => {
  const { data } = useSWR<IGodmodeSubscription>(endpoints.subscriptionById(subscriptionId));
  const alert = useAlert();
  const [error, setError] = useState<string>();
  const form = useFormik<IGodmodeSubscription>({
    initialValues: data ? {
      ...data!,
      new_start_date: data.start_date,
      new_end_date: data.end_date
    } : undefined as any,
    enableReinitialize: true,
    onSubmit: values => {
      const v = { ...values }
      if (v.student_limit === data?.student_limit) {
        v.student_limit = undefined as any;
      }
      return justFetch(endpoints.subscriptionById(subscriptionId), 'PUT', v)
        .then(res => {
          if (res.ok) {
            alert.success('Subscription updated');
            mutate(endpoints.subscriptionById(subscriptionId));
            onClose();
          } else {
            res.json().then(body => setError(body.message || body.error || 'An unknown error occurred'))
          }
        })
        .catch(() => setError('An unknown error occurred'))
    }
  });

  useEffect(() => {
    if (open) {
      form.resetForm();
      setCalculateNewContractDuration(false);
      setError(undefined);
    }
  }, [open]);
  const [calculateNewContractDuration, setCalculateNewContractDuration] = useState(false);
  useEffect(() => {
    if (calculateNewContractDuration && form.values) {
      form.setFieldValue('contract_duration', differenceInMonths(new Date(form.values.new_end_date!), new Date(form.values.new_start_date!)))
    } else if (form.values) {
      form.setFieldValue('contract_duration', data?.contract_duration)
    }
  }, [calculateNewContractDuration, form.values?.new_start_date, form.values?.new_end_date])

  const editPaymentDueDateDialogState = useDialogState();
  const downgradeSubscriptionDialogState = useDialogState();
  const sharedClasses = useSharedStyles();

  return <Dialog open={open}>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DialogTitle>
        <Box display="flex" alignItems="center" className={sharedClasses.hspacing2}>
          <Box>
            Edit Subscription {subscriptionId}
          </Box>
          <Button
            onClick={downgradeSubscriptionDialogState.handleOpen}
            variant="contained"
            color="red"
          >
            Downgrade
          </Button>
          <SubscriptionDeleteButton subscriptionId={subscriptionId} />          
        </Box>
      </DialogTitle>
      {form.values && data && <DialogContent>
        {form.values.purchase_process && <EditPaymentDueDateDialog {...editPaymentDueDateDialogState} subscriptionId={subscriptionId} />}
        <DowngradeSubscriptionDialog {...downgradeSubscriptionDialogState} teacherId={form.values.teacher.id} />
        <FormikProvider value={form}>
          <Form onSubmit={form.handleSubmit}>
            <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2}>
              {data.purchase_process && <Box>
                <Box display="flex" alignItems="center">Purchase State:&nbsp;<Typography variant="subtitle1" style={{ textTransform: 'capitalize' }}>{data.purchase_process?.state.replaceAll('_', ' ')}</Typography></Box>
                <Box display="flex" alignItems="center">Payment Due Date: {format(new Date(data.purchase_process.payment_due), 'PPP')}&nbsp;{data.purchase_process.state !== 'paid' && <IconButton onClick={editPaymentDueDateDialogState.handleOpen}><Edit /></IconButton>}</Box>
              </Box>}
              <TextField
                id="student_limit"
                name="student_limit"
                onChange={form.handleChange}
                value={form.values.student_limit}
                type="number"
                label="Student Limit"
                fullWidth={false}
              />
              <Box>
                <Typography variant="subtitle1">Subscription Start Date</Typography>
                <DatePicker
                  onChange={date => { form.setFieldValue('new_start_date', format(date as Date, 'yyyy-MM-dd')); }}
                  name="new_start_date"
                  id="new_start_date"
                  value={parse(form.values.new_start_date!, 'yyyy-MM-dd', new Date())}
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  InputProps={{
                    margin: 'dense'
                  }}
                />
              </Box>
              <Box>
                <Typography variant="subtitle1">Subscription End Date</Typography>
                <DatePicker
                  onChange={date => { form.setFieldValue('new_end_date', format(date as Date, 'yyyy-MM-dd')); }}
                  name="new_end_date"
                  id="new_end_date"
                  value={parse(form.values.new_end_date!, 'yyyy-MM-dd', new Date())}
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  InputProps={{
                    margin: 'dense'
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <Switch
                    onChange={e => setCalculateNewContractDuration(e.target.checked)}
                    checked={calculateNewContractDuration}
                  />
                  <Box>Calculate new contract duration?</Box>&nbsp;<Typography variant="subtitle1">This affects ARR!</Typography>
                </Box>
                <Box>
                  Current contract duration: {data.contract_duration} months
                </Box>
                <Box>
                  {calculateNewContractDuration && <>New contract duration: {form.values.contract_duration} months</>}
                </Box>
              </Box>
            </Box>
          </Form>
        </FormikProvider>
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>}
      {!data && <DialogContent>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </DialogContent>}
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={form.isSubmitting}
        >Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={form.submitForm}
          disabled={form.isSubmitting}
        >Save</Button>
      </DialogActions>
    </MuiPickersUtilsProvider>
  </Dialog>;
}