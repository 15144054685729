import { useState } from 'react';
import ModalConfirmCancellation from 'components/modal/ModalConfirmCancellation';
import useCurrentUser from 'loaders/useCurrentUser';

type CancelReasons = {
  [key in '1' | '2' | '3' | '4' | '5' | '6']: string;
} & { [key: string]: string };

const CANCEL_REASONS: CancelReasons = {
  1: 'My children no longer use Kodable',
  2: 'The subscription was too expensive',
  3: 'We finished all of the content',
  4: 'My child uses Kodable at school',
  5: 'I do not think Kodable is worth the money',
  6: 'Other / Prefer not to say',
};

const CancelSubscriptionPage = () => {
  const { currentUser } = useCurrentUser();
  const [reason, setReason] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="max-w-screen-lg my-8 mx-8">
      <div className="flex flex-col justify-center items-center">
        <img
          className="w-48 h-40"
          src="/images/fuzzes/sad_simon.png"
          alt="sad simon"
        />
        <h1 className="text-4xl font-thin">We're sorry to see you go!</h1>
        <p className="text-sm mt-2 text-gray-700">
          Do you mind telling us why you're cancelling?
        </p>
        <form className="flex flex-col mt-4">
          {Object.keys(CANCEL_REASONS).map((key) => (
            <label key={key}>
              <input
                value={key}
                onChange={() => setReason(key)}
                name="cancelReason"
                className="mr-2 mb-2"
                type="radio"></input>
              <span className="font-semibold">{CANCEL_REASONS[key]}</span>
            </label>
          ))}
          <div className="mx-auto">
            <button
              type="button"
              disabled={!reason}
              onClick={() => setOpenModal(true)}
              className="mt-8 relative bg-kodable-orange text-white px-6 py-2 rounded hover:bg-kodable-orange-dark active:bg-kodable-orange-darker disabled:opacity-50 disabled:cursor-not-allowed">
              Cancel Subscription
            </button>
            {openModal && (
              <ModalConfirmCancellation
                currentUser={currentUser}
                closeModal={() => setOpenModal(false)}
                reason={reason}
              />
            )}
          </div>
        </form>
        <p className="mt-8">
          Need Help? Please{' '}
          <a
            href="mailto:support@kodable.com"
            className="underline text-kodable-blue w-full">
            Contact Support
          </a>
        </p>
      </div>
    </div>
  );
}

export default CancelSubscriptionPage