import { Box, Chip, ChipProps } from "@material-ui/core"
import grades from "types/Grades"
import React from "react";

export interface GradeChipProps extends ChipProps {
  /**
   * The variant to use.
   */
  kVariant?: 'full' | 'mini',
  /**
   * The grade
   */
  grade: keyof typeof grades
}

const GradeChip: React.VFC<GradeChipProps> = ({ kVariant = 'full', grade, ...chipProps }) => {
  if (!grades[grade] || grade === 'all') {
    return null;
  }

  if (kVariant === 'full') {
    return <Chip style={{ backgroundColor: grades[grade].color, color: 'white', fontWeight: 600 }} label={grades[grade].name} {...chipProps} />
  }

  return <Box
    bgcolor={grades[grade].color}
    color="white"
    borderRadius={16}
    py={0.25}
    px={1}
    fontSize="calc(1rem - 4px)"
  >
    {grades[grade].name}
  </Box>
}

export default GradeChip;