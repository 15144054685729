import {IFuzzData} from "../../types/IFuzzData";
import {IStudentSchoolProfile} from "../../types/IStudentSchoolProfile";
import React, {useMemo, useState} from "react";
import {format} from "date-fns";
import useDialogState from "../../hooks/useDialogState";
import DeleteStudentWorkDialog from "../dialogs/student/DeleteStudentWorkDialog";
import StudentWorkCard from "../../pages/classes/StudentCreations/StudentWorkCard";
import endpoints from "../../endpoints";
import {justFetch} from "../../mutations/mutate";
import FuzzDetailsDialog from "../dialogs/student/content/FuzzDetailsDialog";


const attemptRegenerateFuzz = async (studentId: number, fuzzId: number): Promise<void> => {
  try {
    justFetch(endpoints.fuzzRegen(studentId, fuzzId), 'GET')
  } catch (e) {
    console.log("Could not Regenerate Missing Fuzz")
  }
}

const today = new Date();

const FuzzCard: React.VFC<{ fuzz: IFuzzData, student: IStudentSchoolProfile }> = ({student, fuzz}) => {
  const {client_id, created_at, config} = fuzz;
  const {clazzName} = config;
  const [fuzzLoaded, setFuzzLoaded] = useState<boolean>(true)
  const fuzzDetailDialog = useDialogState()
  const deleteDialogState = useDialogState();

  const handleFuzzLoaded = (loaded: boolean): void => {
    if (!loaded) {
      attemptRegenerateFuzz(student.id, client_id)
    }
    setFuzzLoaded(loaded)
  }

  const dateStr = useMemo(() => {
    const date = new Date(created_at);

    return format(date, date.getFullYear() !== today.getFullYear() ? 'MMMM d, yyyy' : 'MMMM d')
  }, [created_at]);

  // Hide broken or missing fuzzes by returning null on failed image load
  if (!fuzzLoaded) {
    return null
  }


  return <>
    <DeleteStudentWorkDialog
      {...deleteDialogState}
      fuzz={fuzz}
      student={student}
    />
    <FuzzDetailsDialog
      student={student}
      fuzz={fuzz}
      onClose={fuzzDetailDialog.onClose}
      onDelete={deleteDialogState.handleOpen}
      open={fuzzDetailDialog.open}
    />

    <StudentWorkCard
      cardImageSrc={endpoints.studentWork('fuzz', 'fullsize', `${student.student_code.slice(0, 5)}${client_id}`)}
      cardImageFallbackSrc="/images/student-creations-page/no-fuzz.png"
      handleFuzzLoaded={handleFuzzLoaded}
      cardTitle={clazzName}
      cardContent={dateStr}
      maxWidth={140}
      onClick={fuzzDetailDialog.handleOpen}
    />
  </>
}

export default FuzzCard