import {Box, Link, Typography} from "@material-ui/core";
import Button from "../../../../components/ui/buttons/Button";
import React from "react";

const LessonFooter: React.VFC<{unitId: number}> = ({unitId}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} style={{backgroundColor: '#00a79d', color: 'white', height: 170, marginTop: 25}}>
      <Typography variant={'h1'} style={{fontSize: 30, fontWeight: 100, paddingBottom: 15}}>Everything you need to teach your class to code</Typography>
      <Link component={Button}
            href={`${process.env.REACT_APP_KODABLE_WEBSITE}/register?lesson=${unitId}`}
            underline={'none'}
            style={{backgroundColor: 'orange', width: 180, height: 46, color: 'white', fontSize: 18, fontWeight: 600}}
      >
        Get Started
      </Link>
    </Box>
  )
}

export default LessonFooter