import {Box, useTheme} from "@material-ui/core";
import {motion} from "framer-motion";
import React from "react";

interface LoadingProps {
  width?: number,
  height?: number
}

export const Loading: React.VFC<LoadingProps> = ({width = 90, height = 90}) => {
  const theme = useTheme()
  return (
    <Box width={width} height={height} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <motion.div
        initial={{ transform: "translateZ(1px)" }}
      >
        <motion.div
          className="circle"
          style={{
            margin: 8,
            borderRadius: "50%",
            width: width,
            height: height,
            background: theme.palette.darkOrange.main
          }}
          animate={{
            rotateY: 3600,
          }}
          transition={{
            duration: 12.4,
            repeat: Infinity,
            ease: "linear"
          }}
        />
      </motion.div>
    </Box>
  );
}

export default Loading