import PageContainer from "components/ui/PageContainer";
import useTeacherInit from "loaders/useTeacherInit";
import React, {useEffect, useMemo, useState} from "react";
import {KlassSetupModalContent} from "components/modal/KlassSetupModal";
import {KlassSetupModalState} from "types/KlassSetupBannerModalState";
import {MultiClassPrintoutDialog} from "pages/printouts/MultiClassPrintoutDialog";
import {routes} from "routes";
import {GenerateMultipleClassLinksDialog} from "pages/hoc/GenerateMultipleClassLinksDialog";
import {Box, Typography} from "@material-ui/core";
import ParentInvites, {TrackParentInviteMethod, TrackParentInviteMethodLocation} from "utils/ParentInvites";
import {IKlass} from "types/IKlass";
import ParentLettersPDF from "../printouts/ParentLettersPDF";

export const ParentConnectKlassesLinked: React.VFC = () => {
    const { teacherData } = useTeacherInit();
    const [numLinked, setNumLinked] = useState<number>(0)

    const linkedKlasses = useMemo(() => {
        return teacherData?.klasses?.filter((klass) => {
            if (!klass?.parent_invites) {
              return false
            }
            // If any of the methods, email, download, or link have a value of 1, then the teacher
            // is considered as having invited this klass parents
          return Object.values(klass?.parent_invites).indexOf(1) > -1
        })
    }, [teacherData])

    useEffect(() => {
        if (!linkedKlasses || linkedKlasses?.length <= 0) {
          return
        }
        setNumLinked(linkedKlasses?.length)
    }, [linkedKlasses])
    return (
        <>
            <Typography
                align="center"
                variant="h1"
            >
            {numLinked > 0 &&
                <Box style={{
                    marginBottom: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <span style={{marginRight: '.5rem'}}>
                    <span style={{
                        color: '#27a9e1',
                        fontWeight: 'bold'}}
                    >{numLinked}</span> {numLinked > 1 ? 'classes have their Home keys' : 'class has their Home key'}
                </span>
                <img
                    width="38"
                    height="36"
                    src="/images/onboarding/heart-key.png"
                    style={{height: '36px'}}
                />
                </Box>
            }
            {numLinked <= 0 &&
                <span>Invite Families</span>
            }
            </Typography>
            <Typography
                align="center"
                style={{marginBottom: '1rem'}}
            >
                {(linkedKlasses && numLinked > 0) &&
                <>
                    {(teacherData?.klasses && numLinked >= teacherData?.klasses?.length) ?
                        'All classes have been invited. Resend invites to build coding skills at home and let families follow progress for free.'
                        :
                        'Invite your classes to help students build coding skills at home, while families follow their progress for free.'
                    }
                </>
                }
                {(!linkedKlasses || numLinked <= 0) && 'Invite your classes to help students build coding skills at home, while families follow their progress for free.'}
            </Typography>
        </>
    )
}

const ParentConnectStudentsLinked: React.VFC = () => {
    const { teacherData } = useTeacherInit();
    const [numLinked, setNumLinked] = useState<number>(0)

    const linkedStudents = useMemo(() => {
        return teacherData?.students?.filter((student) => {
            return student?.lead?.state === 'linked'
        })
    }, [teacherData])

    useEffect(() => {
        setNumLinked(linkedStudents?.length || 0)
    }, [linkedStudents])

    return (
        <>
            <Typography
                align="center"
                variant="h1"
            >
            {numLinked > 0 &&
                <Box style={{
                    marginBottom: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <span style={{marginRight: '.5rem'}}>
                    <span style={{
                        color: '#27a9e1',
                        fontWeight: 'bold'}}
                    >{numLinked}</span> {numLinked === 1 ? 'student has their Home key' : 'students have their Home keys'}
                </span>
                <img
                    width="38"
                    height="36"
                    src="/images/onboarding/heart-key.png"
                    style={{height: '36px'}}
                />
                </Box>
            }
            {numLinked <= 0 &&
                <span>Invite Families</span>
            }
            </Typography>
            <Typography
                align="center"
                style={{marginBottom: '1rem'}}
            >
                {(linkedStudents && numLinked > 0) &&
                    'Resend invites so the rest of the class can unlock Home access'
                }
                {(!linkedStudents || numLinked <= 0) && 
                    'Send invites to families to unlock free levels and progress updates at Home.'
                }
            </Typography>
        </>
    )
}

export const ParentConnectContent: React.VFC<{location?: TrackParentInviteMethodLocation, shouldShowStudentCount?: boolean, klass?: IKlass}> = ({location = TrackParentInviteMethodLocation.sidebar, shouldShowStudentCount = false, klass}) => {
    const { teacherData } = useTeacherInit();
    const [modalState, setModalState] = useState<KlassSetupModalState>('default')

    const handleClose = () => {
        setModalState('default')
    }

    useEffect(() => {
        if (modalState !== 'default' && modalState !== 'closed') {
            ParentInvites.track(klass, TrackParentInviteMethod.clicked, location)
        }
    }, [modalState])

    return (
        <>
        {(!shouldShowStudentCount || !klass) &&
            <ParentConnectKlassesLinked />
        }
        {(shouldShowStudentCount && klass) &&
            <ParentConnectStudentsLinked />
        }
        <KlassSetupModalContent setState={setModalState}/>
        {(modalState === 'print') && teacherData && 
            <MultiClassPrintoutDialog
                title="Generate Parent Letters"
                helperText="Select the classes for which you want to generate parent letters."
                open={modalState === 'print'}
                onClose={() => {
                    handleClose()
                }}
                handout={(klassId) => <ParentLettersPDF teacherData={teacherData} classId={klassId.toString()} />}
                filename={(klass) => `Kodable Parent Letters - ${klass.klass_name || "New Class"}`}
                selectKlass={klass?.id}
                trackingLocation={location}
                v2
            />
        }
        {(modalState === 'share') &&
            <GenerateMultipleClassLinksDialog
                open={modalState === 'share'}
                onClose={() => handleClose()}
                location={location}
            />
        }
        </>
    )
}


const ParentConnect: React.VFC = () => {
    return (
    <PageContainer>
        <ParentConnectContent />
    </PageContainer>
    )
}

export default ParentConnect