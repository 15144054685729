import { Route } from "react-router-dom";
import { routes } from "routes";
import { SubmitOrderIndex } from "./submit-order/SubmitOrderIndex";
import React from "react";

export const OrdersIndex: React.VFC = () => {
  return <>
    <Route
      path={routes.sales.orders.submit.index}
      component={SubmitOrderIndex}
    />
  </>;
}