import {Box, Divider, Link, Typography} from "@material-ui/core";
import LessonOverviewSection from "./LessonOverviewSection";
import {ILesson} from "../../../../types/ILesson";
import Button from "../../../../components/ui/buttons/Button";
import React from "react";

const LessonOverview: React.VFC<{lesson: ILesson}> = ({lesson}) => {
  return (
    <Box style={{marginLeft: 25, width: '65%'}}>
      <Box style={{marginLeft: 75}}>
        <Typography variant={'h1'} style={{fontSize: 28, fontWeight: 500, paddingBottom: 10}}>{lesson?.name}</Typography>
        <div dangerouslySetInnerHTML={{ __html: lesson.niceGrades}} style={{fontSize: 18}} />
        <Link component={Button}
              href={`${process.env.REACT_APP_KODABLE_WEBSITE}/register?lesson=${lesson.unit_id}`}
              underline={'none'}
              style={{backgroundColor: '#00a79d', color: 'white', position: "absolute", right: 20, top: 250}}
        >
          Sign up to view this lesson
        </Link>
        <Divider style={{width: '100%', marginTop: 10}} />
      </Box>
      <Box>
        { lesson.direct_instruction &&
          <LessonOverviewSection title={'Direct Instruction'}
                                 icon={'/images/curriculum_images/Teacher.png'}
                                 iconColor={'#27a9e1'}
                                 duration={lesson.direct_instruction_time || undefined}
                                 body={`${lesson.direct_instruction?.slice(0, 250)}...`}
          />
        }
        { lesson.guided_practice_description &&
          <LessonOverviewSection title={'Guided Practice Activity'}
                                 icon={'/images/curriculum_images/Homepage_Results.png'}
                                 iconColor={'#ed2a7b'}
                                 duration={lesson.guided_practice_time || undefined}
                                 body={`${lesson.guided_practice_description?.slice(0, 250)}...`}
          />
        }
        { lesson.check_understanding &&
          <LessonOverviewSection title={'Check for Understanding / Informal Assessment'}
                                 icon={'/images/curriculum_images/CCSSHandout.png'}
                                 iconColor={'#00a79d'}
                                 duration={lesson.check_understanding_time || undefined}
                                 body={`${lesson.check_understanding?.slice(0, 250)}...`}
          />
        }
      </Box>
    </Box>
  )
}

export default LessonOverview