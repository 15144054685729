import React from "react";
import useSharedStyles from "components/useSharedStyles";
import {useOrganizationProfile} from "context/OrganizationProfileProvider";
import {Box, Link, Typography} from "@material-ui/core";
import {SubscriptionStateCardsListing} from "pages/godmode/Subscriptions/SubscriptionStateCardsListing";
import OrganizationProfileBillingDetailsModal from "./OrganizationProfileBillingDetailsModal";
import useSWR from "swr";
import { ISalesProfile } from "pages/settings/teacher/ISalesProfile";
import endpoints from "endpoints";
import { routes } from "routes";
import { getQuoteNumber } from "pages/sales/quotes/getQuoteNumber";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";

export const OrganizationProfileDetailBillingTab: React.FC = () => {
  const sharedClasses = useSharedStyles()
  const {organization_profile} = useOrganizationProfile()
  const { data: salesProfile } = useSWR<ISalesProfile>(endpoints.salesProfile(organization_profile?.administrator_id));

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box className={sharedClasses?.vspacing2} width="100%">
          <Typography variant="h1">Quotes</Typography>
            {salesProfile?.quotes.map((quote, i, { length }) => {
              return <div key={i}>
                <Link component={RouterLink} style={{ fontWeight: 'normal' }} to={routes.sales.quotes.view(quote.secret_code)}>Premium{quote.has_curriculum ? '+' : ''} x {quote.num_sites} on {format(new Date(quote.created_at), 'PP')} ({getQuoteNumber(quote)})</Link>
              </div>
            })}
            <br/>
            {!salesProfile?.quotes?.length && <Typography>No quotes associated with this organization.</Typography>}
            <br/>
        </Box>
        <Box className={sharedClasses?.vspacing4} width="100%">
          <Typography variant="h1">Payments</Typography>
          <SubscriptionStateCardsListing organizationProfile={organization_profile}/>
        </Box>
      </Box>
      <Box className={sharedClasses?.vspacing4}
        style={{
          width: '48%',
          backgroundColor: 'white',
          padding: '2rem'
        }}
      >
        <Typography variant="h1">Billing Details</Typography>
        <Box className={sharedClasses?.vspacing1}>
          <Typography variant="h2">Billing Contact</Typography>
          <Typography variant="body1"><strong>{organization_profile?.billing_contact_name}</strong></Typography>
          <Typography variant="body1">{organization_profile?.billing_contact_email}</Typography>
          <Box>
            <Typography variant="body1">{organization_profile?.display_name}</Typography>
            <Typography variant="body1">{organization_profile?.billing_contact_address_street}</Typography>
            <Typography
              variant="body1">{organization_profile?.billing_contact_address_city}, {organization_profile?.billing_contact_address_province} {organization_profile?.billing_contact_address_zip}</Typography>
          </Box>
        </Box>
        <OrganizationProfileBillingDetailsModal />
      </Box>
    </Box>
  )
}