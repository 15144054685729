import React, { useState, useEffect } from 'react';
import useVideos from 'loaders/useVideos';
import Loading from 'components/loading/Loading';
import { Link } from 'react-router-dom';
import VideoNav from './VideoNav';
import ShareButton from './ShareButton';
import Amplitude from 'utils/Amplitude';

interface CategoryVideosProps {
  title: string;
  resourceType: string;
  link: string;
}

const CategoryVideos: React.FC<CategoryVideosProps> = ({ title, resourceType, link }) => {
  const { videos, loading } = useVideos();
  const [search, setSearch] = useState('');
  const conceptVideos = videos
    ?.filter((v) => v.resource_type === resourceType)
    .filter((v) => {
      if (search === '') {
        return true;
      }

      return v.name.toLowerCase().includes(search.toLowerCase());
    });

  useEffect(() => {
    if (videos?.length > 0) {
      Amplitude.track('Viewed Videos Page', {
        Section: 'Video List',
        'Video Type': resourceType,
      });
    }
  }, [videos?.length, resourceType]);

  return (
    <div className="relative flex flex-col min-h-full">
      <VideoNav title={title} link={link} backLink="/videos" />
      <div className="bg-gray-50 relative flex-1">
        <div className="max-w-5xl p-4 mx-auto">
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="border px-4 py-2 w-full rounded-3xl ring-kodable-orange focus:outline-none focus:ring-2"
            type="text"
            placeholder="Search Video"
          />
        </div>
        {loading && (
          <div className="flex justify-center items-center mt-32 h-64">
            <Loading />
          </div>
        )}
        {!loading && (
          <div className="max-w-5xl mx-auto flex flex-col justify-center items-center mt-8 pb-24">
            {conceptVideos?.map((video) => (
              <div className="flex px-8 pb-16 md:flex-row flex-col" key={video.id}>
                <div className="flex-1 relative">
                  <Link
                    to={`/videos/${link}/${video.id}`}
                    className="relative group hover:ring-4 rounded-2xl ring-kodable-orange focus:outline-none focus:ring-4 block">
                    <div className="absolute w-full h-full flex justify-center items-center cursor-pointer">
                      <svg
                        className="w-32 h-32 text-white opacity-75 group-hover:opacity-100 relative"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    {video.resource_thumb_url &&
                      <img
                        className="w-full rounded-2xl"
                        width="432"
                        height="243"
                        src={video.resource_thumb_url}
                        alt={video.name}
                      />
                    }
                  </Link>
                </div>
                <div className="flex-1 pt-4 md:pt-0 md:px-4">
                  <h4 className="text-3xl font-bold text-gray-700">{video.name}</h4>
                  <p className="text-gray-700 mt-2">{video.description}</p>
                  <div className="flex justify-start mt-4">
                    <ShareButton video={video} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default CategoryVideos;
