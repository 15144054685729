import endpoints from 'endpoints';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { IResource } from 'types/IResource';

interface ResourcesContextProps {
    resources: IResource[];
    loading: boolean;
    search: string | null;
    setSearch: React.Dispatch<React.SetStateAction<string | null>>;
    filters: IResourceFilters;
    setFilters: React.Dispatch<React.SetStateAction<IResourceFilters>>;
    resetFilters: () => void;
    emptyAfterFilter: boolean;
    error: any;
    mutate: () => void;
}

const ResourcesContext = createContext<ResourcesContextProps>({
  resources: [],
  loading: true,
  search: null,
  setSearch: () => {},
  filters: {filter_type: 'all', filter_unit: 'all', filter_published: 'Published'},
  setFilters: () => {},
  resetFilters: () => {},
  emptyAfterFilter: false,
  error: null,
  mutate: () => {}
});

interface ResourcesProviderProps {
  children: ReactNode;
}

interface IResourceFilters {
  filter_type: ResourceType;
  filter_unit: string;
  filter_published?: ResourcePublishStatus;
}

export type ResourceType = 'all' | 'activities' | 'materials' | 'teacher_video' | 'student_video' | 'planning' | 'certificates' | 'creative_project_videos' | 'coding_concept_video' | 'other' | 'v2_course_materials';
export type ResourceTypeMap = {
  [key in ResourceType]: string
}
export type ResourceFileType = 'static' | 'video'
export type ResourcePublishStatus = 'all' | 'Published' | 'Archived';

export const ResourcesProvider: React.FC<ResourcesProviderProps> = ({ children }) => {
  const [search, setSearch] = useState<string | null>(null)
  const [resources, setResources] = useState<IResource[]>([]);
  const [filters, setFilters] = useState<IResourceFilters>({filter_type: 'all', filter_unit: 'all', filter_published: 'Published'})
  const [emptyAfterFilter, setEmptyAfterFilter] = useState<boolean>(false)
  
  const { data, mutate, error, isValidating } = useSWR(endpoints.resources);
  
  const resetFilters = () => {
    setSearch('')
    setFilters({filter_type: 'all', filter_unit: 'all', filter_published: 'Published'})
  }
  
  useEffect(() => {
    if (data) {
      setResources(data);
    }
  }, [data]);

  useEffect(() => {
    if (!data) {
      return
    }

    if (search || filters) {
      // Filter data here
      const filteredData = data.filter((data: IResource) => {
        // Filter by search
        if (search && search?.length > 0 && !data.name.toLowerCase().includes(search.toLowerCase())) {
          return false
        }
        // Filter by unit
        if (filters?.filter_unit !== 'all' && data.unit_id?.toString() !== filters.filter_unit) {
          return false
        }
        // Filter by resource type
        if (filters?.filter_type !== 'all' && data.resource_type !== filters.filter_type) {
          return false
        }
        // Filter by published status (we don't need to explicitly handle 'all' here)
        if (filters?.filter_published === 'Published' && data?.is_archived !== false) {
          return false
        }
        return !(filters?.filter_published === 'Archived' && data?.is_archived !== true);

      })

      setEmptyAfterFilter(filteredData?.length === 0)
      setResources(filteredData)
      return 
    }

    setEmptyAfterFilter(data.length === 0)
    setResources(data)
  }, [search, filters, data])
  
  return (
    <ResourcesContext.Provider value={{ resources, loading: isValidating, search, setSearch, filters, setFilters, resetFilters, emptyAfterFilter, error, mutate }}>
      {children}
    </ResourcesContext.Provider>
  );
};

export const useResourcesContext = () => useContext(ResourcesContext);
