const studentLoginIcons = [
  'add', 'balloons', 'battery', 'bee', 'bell', 'bike', 'block',
  'broccoli', 'brush', 'bug', 'bunny', 'bus', 'cactus', 'car',
  'carrot', 'chair', 'coffee', 'diamond', 'duck', 'eye', 'fire',
  'firetruck', 'flag', 'flower', 'football', 'frenchfries', 'frog',
  'globe', 'graduationcap', 'guitar', 'hamburger', 'hammer', 'hat',
  'headphones', 'helmet', 'hoodie', 'hotdog', 'house', 'key',
  'lightbulb', 'megaphone', 'mitten', 'money', 'owl', 'pig', 'plane',
  'planet', 'pointer', 'reindeer', 'rocket', 'snowman', 'soccer-ball',
  'stop-light', 'sunglasses', 'taco', 'trophy', 'umbrella'
];

export const getStudentPasswordIcon = (studentCode: string) =>
  studentLoginIcons[(studentCode.charCodeAt(0) + studentCode.charCodeAt(1) + studentCode.charCodeAt(2)) % 57];