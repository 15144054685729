import mutate from './mutate';
import * as useCurrentUser from 'loaders/useCurrentUser';

const useDeleteStudent = (studentId: number) => {
  return async () => {
    await mutate(`/v2/students/${studentId}`, 'DELETE', {});
    return await useCurrentUser.mutate();
  }
}

export default useDeleteStudent