import {
  Box,
  Tab,
  Tabs,
} from "@material-ui/core";
import {routes} from "../../routes";
import {Route, useHistory, useLocation} from "react-router-dom";
import CleverStudents from "./CleverStudents";
import CleverClasses from "./CleverClasses";
import PageHeader from "../../components/ui/PageHeader";
import useDialogState from "../../hooks/useDialogState";
import CleverTeachers from "./CleverTeachers";
import CleverSchools from "./CleverSchools";
import Button from "../../components/ui/buttons/Button";
import CleverSecureSyncDialog from "./components/CleverSecureSyncDialog";
import {useLayoutEffect, useState} from "react";
import Amplitude from "../../utils/Amplitude";
import useTeacherInit from "../../loaders/useTeacherInit";
import SyncCleverDialog from "../../components/dialogs/clever/SyncCleverDialog";
import ManageKlassesDialog from "./components/ManageKlassesDialog";


const CleverPage: React.VFC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const syncCleverDialogState = useDialogState();
  const [cleverSyncing, setCleverSyncing] = useState(false)
  const {teacherData} = useTeacherInit()
  const rosterDialogState = useDialogState();

  useLayoutEffect(() => {
    Amplitude.track('Clever Table Viewed');
  }, [])

  return (
    <Box>
      <PageHeader title="Clever" inAppBar showCleverIcon={true} />
      {teacherData?.has_clever_secure_sync ?
        <CleverSecureSyncDialog
          open={syncCleverDialogState.open}
          onClose={() => {
            syncCleverDialogState.onClose()
            setCleverSyncing(true)
          }}
        /> : <SyncCleverDialog open={(syncCleverDialogState.open)} onClose={syncCleverDialogState.onClose} klasses={teacherData!.klasses}/>
      }
      {!teacherData?.has_clever_secure_sync && <ManageKlassesDialog open={rosterDialogState.open} onClose={rosterDialogState.onClose} />}

      <Tabs
        value={pathname}
        onChange={(ev, newPage) => history.push(newPage)}
        indicatorColor="primary"
        textColor="primary"
      >
        {teacherData?.has_clever_secure_sync && <Tab label="Schools" value={teacherData?.has_clever_secure_sync ? routes.clever.index : routes.clever.schools} disableRipple /> }
        {teacherData?.has_clever_secure_sync && <Tab label="Teachers" value={routes.clever.teachers} disableRipple hidden={!teacherData?.has_clever_secure_sync} /> }
        <Tab label="Classes" value={!teacherData?.has_clever_secure_sync ? routes.clever.index : routes.clever.classes} disableRipple />
        <Tab label="Students" value={routes.clever.students} disableRipple />
        <Box
          display="flex"
          flexDirection={'row'}
          justifyContent="flex-end"
          width={'100%'}
          mr={5}
          mb={1}
          alignItems={'center'}
        >
          {!teacherData?.has_clever_secure_sync &&
            <Button
              variant={"contained"}
              color={'primary'}
              onClick={rosterDialogState.handleOpen}
              style={{marginRight: 5}}
            >
              Add/Remove Classes
            </Button>
          }
          <Button
            variant={"outlined"}
            onClick={syncCleverDialogState.handleOpen}
          >
            Update Class Rosters
          </Button>
        </Box>
      </Tabs>
      <Route
        component={CleverSchools}
        path={teacherData?.has_clever_secure_sync ? routes.clever.index : routes.clever.schools}
        exact
      />
      <Route
        component={CleverClasses}
        path={!teacherData?.has_clever_secure_sync ? routes.clever.index : routes.clever.classes}
        exact
      />
      <Route
        component={CleverStudents}
        path={routes.clever.students}
        exact
      />
      <Route
        component={CleverTeachers}
        path={routes.clever.teachers}
        exact
      />
    </Box>
  )
}

export default CleverPage