import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize } from "@material-ui/core"
import Button from "components/ui/buttons/Button"
import { useAlert } from "context/AlertProvider"
import { useOrganizationProfile } from "context/OrganizationProfileProvider"
import endpoints from "endpoints"
import { Form, FormikProvider, useFormik } from "formik"
import { justFetch } from "mutations/mutate"
import { IDialogProps } from "pages/godmode/Events/DeleteEventDialog"
import { IOrganizationProfileNote } from "types/IOrganizationProfile"
import * as Yup from 'yup'
import React from "react";

const OrganizationProfileNoteDialog: React.FC<{dialogState: IDialogProps, note?: IOrganizationProfileNote, mutateNotes: () => void}> = ({dialogState, note, mutateNotes}) => {
    const { organization_profile } = useOrganizationProfile()
    const alert = useAlert()

    const form = useFormik({
        initialValues: {
          note_content: note?.note
        },
        validationSchema: Yup.object({
          note_content: Yup.string().required('You must add a note to submit')
        }),
        onSubmit: async ({note_content}) => {
            if (!organization_profile) {
              return;
            }

            let res;
            
            if (!note || !note?.id) {
                res = await justFetch(endpoints.godmode.organizationProfile.notes.organizationNotes(organization_profile.id), 'POST', {
                    subscription_id: organization_profile?.current_subscription?.id,
                    note: note_content
                })
            } else {
                res = await justFetch(endpoints.godmode.organizationProfile.notes.editNote(note?.id), 'PUT', {
                    ...note,
                    note: note_content
                })
            }

            if (res.ok) {
                if (typeof dialogState?.onClose === 'function') {
                    dialogState.onClose()
                }
                mutateNotes()
                alert.success(`Note ${note ? "edited" : "added"} successfully!`)
                return;
            }

            const error = await res.json()

            alert.error(error?.message || `Could not ${note ? "edit" : "add"} note`)
        }
      });
    
    return (
        <Dialog open={dialogState.open}>
            <DialogTitle>
                {note ? "Edit Note" : "Add a new Note"}
            </DialogTitle>
            <DialogContent>
                <FormikProvider value={form}>
                    <Form>
                        <TextareaAutosize
                            id="note_content"
                            name="note_content"
                            placeholder={note ? "Edit Note" : "Add a new Note"}
                            value={form.values.note_content}
                            onChange={form.handleChange}
                            minRows={8}
                            style={{
                                width: '100%',
                                border: '1px solid',
                                padding: '1rem'
                            }}
                        />
                    </Form>
                </FormikProvider>
            </DialogContent>
            <DialogActions>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Button
                    variant="contained"
                    color="newOrange"
                    onClick={dialogState.onClose}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="blue"
                    disabled={form.isSubmitting}
                    onClick={() => form.submitForm()}
                >
                    Save
                </Button>
            </Box>
            </DialogActions>
        </Dialog>
    )
}

export default OrganizationProfileNoteDialog;