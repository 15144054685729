import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import PageContainer from "components/ui/PageContainer"
import PageHeader from "components/ui/PageHeader"
import useSharedStyles from "components/useSharedStyles"
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { IUserProfile } from "types/IUserProfile";
import { ISchoolSearchResultItem } from "./ISchoolSearchResultItem";
import { Link as RouterLink } from 'react-router-dom';
import { useTracking } from "context/TrackingProvider";
import {routes} from "../../../routes";

const UpdateSchoolSuccessPage: React.FC = () => {
  const sharedClasses = useSharedStyles();
  const { state } = useLocation<undefined | { selectedSchool: ISchoolSearchResultItem }>();
  const history = useHistory();
  const { currentUser } = useCurrentUser();

  const { data: unfilteredTeachers, error } = useSWR<IUserProfile[]>(state?.selectedSchool ? endpoints.teachersBySchool(state.selectedSchool.id.toString()) : null);

  const teachers = useMemo(() => {
    return unfilteredTeachers?.filter(({ id }) => id !== currentUser.id);
  }, [currentUser, unfilteredTeachers]);
  const { track } = useTracking();


  useEffect(() => {
    track('Viewed Onboarding Page', {
      'Page Name': 'Add School Search'
    });
  }, []);

  if (!state) {
    history.push(routes.settings.index);
    return null;
  }

  if (!unfilteredTeachers && !error) {
    return <PageContainer variant="centered">
      <PageHeader title="Select A School" />
      <Box minHeight={500} maxHeight="80vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    </PageContainer>
  }

  return <PageContainer variant="centered">
    <Box className={sharedClasses.vspacing2}>
      <PageHeader title="School Updated" />
      <Typography>
        You've added {state.selectedSchool.school_name} as your school.
      </Typography>

      {!teachers || teachers?.length === 0
        ? <Typography>
          You are the first teacher to use Kodable at {state.selectedSchool.school_name}!
        </Typography>
        : <Typography>
          There are {teachers?.length} teachers using Kodable at {state.selectedSchool.school_name}:
        </Typography>
      }

      {
        teachers && teachers.length > 0 && <Box component={Paper} maxHeight="50vh" overflow="scroll">
          <List>
            {
              teachers!.map(({ name}, index) => <ListItem key={index}>
                <ListItemIcon>
                  <FontAwesomeIcon size="2x" icon={faUser} />
                </ListItemIcon>
                <ListItemText primary={name}></ListItemText>
              </ListItem>)
            }
          </List>
        </Box>
      }

      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          disableElevation
          {...{
            component: RouterLink,
            to: routes.classes.index
          }}
        >
          Finish
        </Button>
      </Box>
    </Box>
  </PageContainer>
}

export default UpdateSchoolSuccessPage;