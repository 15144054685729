import {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import endpoints from "../../../endpoints";
import React from "react";

const MazePrintout: React.VFC<{mazeId: string, name: string, createdAt: string}> = ({mazeId, name, createdAt}) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={'/images/kodable-logo-dark.png'}/>
          <Text style={styles.title}>Education</Text>
        </View>
        <View style={styles.mazeContainer}>
          <Image src={endpoints.contentImageById(mazeId)} style={styles.mazeImage} />
        </View>
        <View style={styles.designerContainer}>
          <Text style={styles.infoText}>Maze designed by:</Text>
          <Text style={styles.userInfoText}>________________</Text>
        </View>
        <View style={styles.designedByContainer}>
          <Text style={styles.designedBy}>{name}</Text>
        </View>
        <View style={styles.createdContainer}>
          <Text style={styles.infoText}>Maze created on:</Text>
          <Text style={styles.userInfoText}>_________________</Text>
        </View>
        <View style={styles.createdAtContainer}>
          <Text style={styles.createdAt}>{createdAt}</Text>
        </View>
      </Page>
    </Document>
  )
}

Font.register({
  family: 'Bjola',
  src: '/fonts/bjola.ttf'
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    height: 450
  },
  logo: {
    width: 120,
    height: 29
  },
  title: {
    fontSize: 36,
    textAlign: 'center',
    fontFamily: 'Bjola',
    paddingTop: 20,
    paddingLeft: 2
  },
  header: {
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20
  },
  mazeContainer: {
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
  },
  mazeImage: {
    width: 500,
    height: 375
  },
  designerContainer: {
    flexDirection: 'row',
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 75,
    paddingTop: 30,
  },
  designedByContainer: {
    position: "relative",
    width: 250,
    left: 315,
    top: -55,
    textAlign: 'center'
  },
  designedBy: {
    fontFamily: 'Bjola',
    fontSize: 26,
  },
  infoText: {
    fontFamily: 'Bjola',
    fontSize: 28
  },
  userInfoText: {
    position: "relative",
    fontSize: 28,
    marginLeft: 10,
    top: -10
  },
  createdAt: {
    fontFamily: 'Bjola',
    fontSize: 26,
  },
  createdAtContainer: {
    position: "relative",
    width: 265,
    left: 300,
    top: -75,
    textAlign: 'center'
  },
  createdContainer: {
    position: "relative",
    top: -20,
    flexDirection: 'row',
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 75
  },
});


export default MazePrintout