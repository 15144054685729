import Cookies from 'universal-cookie';

function mutate(path: string, method: string, body: {}) {
  return justFetch(path, method, body).then((result) => result.json());
}

function justFetch(path: string, method: string, body: {} | undefined = undefined, token_override: string | undefined = undefined) {
  const endpoint = `${process.env.REACT_APP_API_ENDPOINT}${!path.startsWith('/') ? '/' : ''}${path}`;
  const cookies = new Cookies();

  return fetch(endpoint, {
    method: method,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      'KODABLE-API-TOKEN': token_override || cookies.get('kodable_kode'),
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
}

export { justFetch }

export default mutate;