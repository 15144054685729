import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const LearningPathSection: React.FC = () => {
  return (
    <Fragment>
      <div className="bg-kodable-teal bg-opacity-80 text-white py-4 px-4 font-bold">
        Kodable Learning Path
      </div>

      <div className="flex flex-col lg:flex-row">
        <div className="flex-1 relative mt-12 mb-12">
          <div className="relative">
            <img
              width="720"
              className="w-full"
              src="/images/learning-tools/learning-path.png"
              alt="learning path"
            />
          </div>
        </div>
        <div className="flex-1 lg:ml-12 lg:my-12 mb-6 flex flex-col justify-center">
          <h4 className="text-2xl font-bold text-gray-700 mb-4">
            Where should my child start?
          </h4>
          <p className="font-thin mb-2">
            The Kodable Learning path helps you understand how to guide your
            child as they learn with Kodable based on their age, grade, and
            other pre-requisites.
          </p>
          <div className="self-start">
            <Link
              to="/parent/learning-tools/learning-path"
              className="mt-4 flex items-center justify-center bg-kodable-green border border-kodable-green hover:border-kodable-green  hover:bg-kodable-green-dark active:bg-kodable-green-darker active:border-kodable-green px-4 py-2 rounded text-white">
              View Learning Path
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LearningPathSection;
