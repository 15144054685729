import { CssBaseline } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import type {} from '@material-ui/lab/themeAugmentation';
import React, { useMemo } from 'react';

const spacing = 8;

export const customColors = [
  'darkOrange', 'orange', 'green', 'purple', 'red', 'pink', 'yellow',
  'teal', 'blue', 'kodableGrey', 'cleverBlue', 'studentInstructions',
  'newOrange', 'newPink', 'kodableGreen'] as const;
export type CustomColorType = typeof customColors[number]

const palette: PaletteOptions = {
  primary: {
    main: '#27a9e1',
    contrastText: '#ffffff',
    dark: '#1ea2da'
  },
  secondary: {
    // main: '#8cc63e',
    // dark: '#84bd38'
    main: '#27a9e1',
    contrastText: '#ffffff'
  },
  error: {
    main: '#e84461'
  },
  warning: {
    main: '#fbaf3f',
  },
  info: {
    main: '#27a9e1'
  },
  orange: {
    main: '#fbaf3f'
  },
  green: {
    main: '#8cc63e'
  },
  purple: {
    main: '#652d90',
    contrastText: '#ffffff',
  },
  red: {
    main: '#e84461'
  },
  pink: {
    main: '#ed2a7b',
    contrastText: '#ffffff',
  },
  blue: {
    main: '#27a9e1'
  },
  teal: {
    main: '#00a79d'
  },
  yellow: {
    main: '#f1eb54'
  },
  kodableGrey: {
    main: '#fafafa'
  },
  background: {
    default: 'rgba(250,250,250,1)'
  },
  cleverBlue: {
    main: '#4274f6',
    contrastText: '#ffffff',
  },
  studentInstructions: {
    main: '#FAC710'
  },
  newOrange: {
    main: '#ffb40f'
  },
  newPink: {
    main: '#C32F96',
    contrastText: '#ffffff',
  },
  darkOrange: {
    main: '#f68c0e'
  },
  kodableGreen: {
    main: '#8CC63E'
  }
  // success: {
  //   // main: '#8cc63e'
  // }
};

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    orange: Palette['primary'];
    green: Palette['primary'];
    purple: Palette['primary'];
    red: Palette['primary'];
    pink: Palette['primary'];
    blue: Palette['primary'];
    teal: Palette['primary'];
    yellow: Palette['primary'];
    kodableGrey: Palette['primary'];
    cleverBlue: Palette['primary'];
    studentInstructions: Palette['primary'];
    newOrange: Palette['primary'];
    newPink: Palette['primary'];
    darkOrange: Palette['primary'];
  }
  interface PaletteOptions {
    orange: SimplePaletteColorOptions;
    green: SimplePaletteColorOptions;
    purple: SimplePaletteColorOptions;
    red: SimplePaletteColorOptions;
    pink: SimplePaletteColorOptions;
    blue: SimplePaletteColorOptions;
    teal: SimplePaletteColorOptions;
    yellow: SimplePaletteColorOptions;
    kodableGrey: SimplePaletteColorOptions;
    cleverBlue: SimplePaletteColorOptions;
    studentInstructions: SimplePaletteColorOptions;
    newOrange: SimplePaletteColorOptions;
    newPink: SimplePaletteColorOptions;
    darkOrange: SimplePaletteColorOptions;
    kodableGreen: SimplePaletteColorOptions;
  }
}

const DashboardThemeProvider: React.FC<{ removeBaseline?: boolean }> = ({ children, removeBaseline }) => {
  const theme = useMemo(() => createTheme({
    props: {
      MuiMenu: {
        elevation: 0
      },
      MuiIconButton: {
        disableRipple: true
      },
      MuiToggleButton: {
        disableRipple: true
      },
      MuiTab: {
        disableRipple: true
      },
      MuiDialog: {
        fullWidth: true
      }
    },
    spacing,
    typography: {
      fontFamily: [
        'Source Sans Pro', 'Open Sans', 'sans-serif'
      ].join(', '),
      h1: {
        fontSize: '26px',
        fontWeight: 300
      },
      h2: {
        fontSize: '22px',
        fontWeight: 300
      },
      subtitle1: {
        fontWeight: 600
      }
    },
    palette,
    overrides: {
      MuiButtonBase: {
        root: {
          '&:focus': {
            outline: 'none !important'
          }
        },
      },
      MuiButton: {
        label: {
          textTransform: 'capitalize',
          fontWeight: 600,
        },
        iconSizeMedium: {
          '& > *:first-child': {
            fontSize: 16
          }
        }
      },
      MuiChip: {
        label: {
          fontWeight: 600
        }
      },
      MuiTableCell: {
        head: {
          fontWeight: 600
        },
        body: {
          fontSize: '1rem'
        }
      },
      MuiDialogTitle: {
        root: {
          textAlign: 'center',
          '& > *': {
            fontSize: '26px !important',
            fontWeight: 300
          }
        }
      },
      MuiDialogActions: {
        root: {
          padding: spacing * 3,
          display: 'flex',
          justifyContent: 'space-between'
        }
      },
      MuiFormControl: {
        marginDense: {
          marginTop: 0,
          marginBottom: 0
        }
      },
      MuiAppBar: {
        root: {
          zIndex: 1201,
        },
        colorDefault: {
          backgroundColor: '#fafafa'
        }
      },
      MuiTabs: {
        root: {
          backgroundColor: 'rgba(245,245,245,1)',
          paddingTop: spacing * 4
        },
        flexContainer: {
          paddingLeft: spacing * 6,
          borderBottom: `2px solid ${palette.blue?.main}`,
        },
        indicator: {
          display: 'none'
        }
      },
      MuiTab: {
        textColorPrimary: {
          color: 'black',
          '&:hover': {
            color: palette.blue.main
          }
        },
        root: {
          textTransform: 'none',
          background: 'rgba(0,0,0,0)',
          marginBottom: -2,
          '&.Mui-selected': {
            backgroundColor: palette.background?.default,
            border: `2px solid ${palette.blue?.main}`,
            borderBottomColor: palette.background?.default,
            borderRadius: '3px 3px 0 0'
          }
        }
      },
      MuiMenu: {
        paper: {
          border: `1px solid rgb(228, 228, 231);`
        }
      },
      MuiToggleButton: {
        root: {
          textTransform: 'none'
        }
      },
      MuiToolbar: {
        root: {
          backgroundColor: 'white',
          '@media all': {
            height: 76
          }
        }
      },
      MuiTypography: {
        h2: {
          fontWeight: 'bold'
        }
      },
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: 'initial'
          },
          '@media print': {
            body: {
              height: 'initial'
            }
          },
          '@font-face': [{
            fontFamily: 'Kodable',
            src: `url('fonts/Kodable.ttf')`
          }, {
            fontFamily: 'Bjola',
            src: `url('fonts/bjola.ttf')`
          }],
          'a': {
            fontWeight: 'bold'
          }
        }
      }
    }
  }), []);

  return <ThemeProvider theme={theme}>{!removeBaseline && <CssBaseline />} {children}</ThemeProvider>
}

export default DashboardThemeProvider;