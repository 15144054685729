import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

//Routes
import KidsVideosPage from './KidsVideosPage';
import KidsVideoPage from './KidsVideoPage';

export default function KidsIndexPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={KidsVideosPage} exact path={`${path}/videos`} />
      <Route component={KidsVideoPage} exact path={`${path}/videos/:id`} />
      <Redirect to={`${path}/videos`} />
    </Switch>
  );
}
