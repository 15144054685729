import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";
import React, { useState } from "react";

export interface IABTest {
    id: string;
    segments?: IABTestSegment[];
}

export interface IABTestSegment {
    id: number;
    content?: React.ReactNode;
    match: boolean;
}

export interface IABTestsContext {
    abTests: IABTest[];
    addABTest: (test: IABTest) => void;
    setABTests: React.Dispatch<React.SetStateAction<IABTest[]>>;
    getTestById: (event_id: string) => IABTest | null;
    getMatchingSegment: (event_id: string) => IABTestSegment | null;
    trackEvent: (testId: string, eventData: {}) => void;
}

export const ABTestsContext = React.createContext<IABTestsContext>(null!);

const ABTestsProvider: React.FC = ({ children }) => {
    const [abTests, setABTests] = useState<IABTest[]>([]);

    const addABTest = (test: IABTest) : void => {
        if (abTests.includes(test)) {
            return
        }
        setABTests([...abTests, test])
    }

    const getTestById = (testId: string) : IABTest | null => {
        const test = abTests.find(test => test.id === testId)
        if (!test) {
            return null
        }
        return test
    }

    const getMatchingSegment = (testId: string) : IABTestSegment | null => {
        const test = getTestById(testId)
        if (!test) {
            return null
        }
        const segment = test.segments?.find(segment => segment?.match)
        if (!segment) {
            return null
        }
        return segment
    }

    const trackEvent = (testId: string, eventData?: {}) : void => {
        if (!testId) {
            return
        }
        const segment = getMatchingSegment(testId)
        if (!segment) {
            return
        }
        justFetch(endpoints.segmentTrackEvent, 'POST', {
            test_id: testId,
            segment_id: segment?.id,
            event_data: eventData
        })
        .catch((error) => {
            console.error(error)
        })
    }

    return (
        <ABTestsContext.Provider value={{abTests, setABTests, addABTest, getTestById, getMatchingSegment, trackEvent}}>
            {children}
        </ABTestsContext.Provider>
    );
}

export default ABTestsProvider;
