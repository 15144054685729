import React, { useState } from 'react';

interface DropdownProps {
  filter: string;
  setFilter: (value: string) => void;
  values: { id: number; name: string }[];
}

const Dropdown: React.FC<DropdownProps> = ({ filter, setFilter, values }) => {
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <div className="relative w-full">
      <input
        className="border px-2 py-2 w-full cursor-pointer"
        type="text"
        readOnly
        value={filter}
        onClick={() => setOpenFilter(!openFilter)}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Select Concept"
      />
      <svg
        className="h-6 w-6 absolute right-2 top-2 text-gray-700"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor">
        <path
          fillRule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
      {openFilter && (
        <ul className="list-none bg-white border overflow-y-scroll max-h-36 absolute z-10 w-full">
          {values.map((u) => (
            <li
              onClick={() => {
                setFilter(u.name);
                setOpenFilter(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setFilter(u.name);
                  setOpenFilter(false);
                }
              }}
              tabIndex={0}
              className="px-2 pb-1 text-gray-700 active:bg-kodable-blue-darker focus:bg-kodable-blue-dark focus:text-white cursor-pointer"
              key={u.id}>
              {u.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
