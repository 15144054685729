import { Box, CircularProgress } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import { ICourseData } from "./ICourseData";
import MissionCard from "./components/MissionCard";

const ViewCourseMissions = () => {
  const { params: { courseId } } = useRouteMatch<IRouteParams>();
  const { data: courseData, error: courseDetailsError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const courseDetails = useMemo(() => {
    return courseData?.find(({ id }) => id.toString() === courseId);
  }, [courseId, courseData]);
  const courseDetailsLoading = !courseDetails && !courseDetailsError;
  const sharedClasses = useSharedStyles();

  if (courseDetailsLoading) {
    return <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  return <Box>
    <Box className={sharedClasses.vspacing2}>
      {courseDetails?.configuration?.missionNodes?.map((mission, i) => <MissionCard course={courseDetails!} key={i} order={i} mission={mission} />)}
    </Box>
  </Box>;
}

export default ViewCourseMissions;

