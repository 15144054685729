import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Box, Link, Typography, useTheme} from "@material-ui/core";
import PageContainer from "components/ui/PageContainer";
import useSharedStyles from "components/useSharedStyles";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "routes";
import { IOrderRequest } from "../IOrderRequest";
import Button from "../../../../components/ui/buttons/Button";

export const SubmitOrderSuccess: React.VFC = () => {
  const { state } = useLocation<IOrderRequest>();
  const theme = useTheme();
  const sharedClasses = useSharedStyles();
  const history = useHistory();

  useEffect(() => {
    if (!state) {
      history.push(routes.sales.orders.submit.index);
    }
  }, [state]);

  return <PageContainer variant="centered">
    <Box width="100%" height={"100%"} display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center" className={sharedClasses.vspacing2}>
      <Typography variant="h2">{state?.organization_name}'s order confirmed!</Typography>
      <Typography style={{fontSize:16, fontStyle: "italic"}}>Your order will be approved within 24 hours.</Typography>
      <FontAwesomeIcon color={theme.palette.green.main} size="5x" icon={faCheckCircle} />
      <Typography>
        {state?.license_holder_name} will receive instructions to get started at {state?.license_holder_email} within 1 business day..
      </Typography>

      <Typography>
        The invoice has been sent to {state?.billing_contact_first_name} {state?.billing_contact_last_name} for payment at {state?.billing_contact_email}.
      </Typography>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'center'} height={100}>
        <Link component={Button}
              href={`https://calendly.com/teachers-kodable/onboardingcall`}
              underline={'none'}
              style={{backgroundColor: theme.palette.green.main, width: 360, height: 46, color: 'white', fontSize: 16, fontWeight: 600}}
        >
          Schedule Your Setup Call
        </Link>
      </Box>

    </Box>
  </PageContainer>;
}