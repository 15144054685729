import { useAuth } from 'context/AuthProvider';
import { mutate as swrMutate } from 'swr';
import endpoints from "../endpoints";

export function mutate() {
  return swrMutate(endpoints.session);
}

const useCurrentUser = () => useAuth();

export default useCurrentUser;
