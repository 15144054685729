import { Box } from "@material-ui/core";
import PageHeader from "components/ui/PageHeader";
import ClassesProgress from "./ClassesProgress";
import React from "react";

const SchoolReport: React.VFC = () => {
  return <Box p={4}>
    <PageHeader inAppBar title="Kodable Learning Report" />
    <ClassesProgress />
  </Box>
}

export default SchoolReport;