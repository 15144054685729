import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

// Routes
import SmeeborgCreativeProjects from './SmeeborgCreativeProjects';
import AsteroidiaCreativeProjects from './AsteroidiaCreativeProjects';
import BugWorldCreativeProjects from './BugWorldCreativeProjects';

const CreativeProjectsIndexPage: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={SmeeborgCreativeProjects} path={`${path}/smeeborg`} />
      <Route component={AsteroidiaCreativeProjects} path={`${path}/asteroidia`} />
      <Route component={BugWorldCreativeProjects} path={`${path}/bugworld`} />
      <Redirect to={`${path}/smeeborg`} />
    </Switch>
  );
};

export default CreativeProjectsIndexPage;
