import React from "react"
import {GodmodePageWrapper} from "pages/godmode/GodmodePageWrapper"
import QuotesTable from "./QuotesTable";

export const QuotesIndex: React.FC = () => {
    return (
        <GodmodePageWrapper title="Quotes" loading={false}>
            <QuotesTable />
        </GodmodePageWrapper>
    )
}

