import '../styles.css';
import {Box, Link, Typography} from "@material-ui/core";
import RoundIcon from "./RoundIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/ui/buttons/Button";
import React from "react";


const SectionContainer: React.FC<{
  title: string, icon?: string, iconColor?: string, duration?: string, innerHtml: string, subsectionTitle?: string, subsectionInnerHtml?: string, downloadResourceUrl?: string
}> = ({title, icon, iconColor, duration, innerHtml, subsectionTitle, subsectionInnerHtml, downloadResourceUrl}) => {
  return (
    <Box maxWidth={800} marginTop={5}>
      <Box display={'flex'} width={800}>
        {(icon && iconColor) &&
          <Box marginRight={2}>
            <RoundIcon color={iconColor} imageUrl={icon}/>
          </Box>
        }
        <Box>
          <Box width={'100%'}>
            <Typography variant={'h6'}>{title} {duration && <span style={{fontSize: '65%', fontWeight: 400}}>{duration} minutes</span>}</Typography>
            <div dangerouslySetInnerHTML={{__html: innerHtml || ""}} className={'curriculum'}/>
          </Box>
          {(subsectionTitle && subsectionInnerHtml) &&
            <Box marginTop={2}>
              <Typography variant={'h6'}>{subsectionTitle}</Typography>
              <div dangerouslySetInnerHTML={{__html: subsectionInnerHtml || ""}} className={'curriculum'}/>
            </Box>
          }
          {downloadResourceUrl &&
            <Link component={Button}
                  href={downloadResourceUrl}
                  target={"_blank"}
                  underline={'none'}
                  startIcon={<FontAwesomeIcon icon={faDownload}/>}
                  style={{borderColor: '#27a9e1', border: '1px solid', padding: '5px 15px'}}
            >
              Download Resources
            </Link>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default SectionContainer