import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { DialogStateProps } from "hooks/useDialogState"
import React, { ReactNode, useState } from "react";

export interface IGodmodeConfirmDialogProps {
    dialogState: DialogStateProps;
    dialogTitle?: string | ReactNode;
    dialogContent?: string | ReactNode;
    variant?: GodmodeConfirmDialogVariant
    onConfirm?: () => Promise<void>;
    confirmInputPlaceholder?: string;
    confirmInputMatch?: string;
}

type GodmodeConfirmDialogVariant = 'default' | 'danger'
type GodmodeConfirmDialogVariantColor = 'green' | 'red'
type GodmodeVariantColorMappingType = {
    [key in GodmodeConfirmDialogVariant]: GodmodeConfirmDialogVariantColor;
};

export const GodmodeConfirmDialog: React.FC<IGodmodeConfirmDialogProps> = ({
        dialogState,
        dialogTitle,
        dialogContent, 
        onConfirm,
        variant='default',
        confirmInputPlaceholder='Type CONFIRM to confirm',
        confirmInputMatch='CONFIRM'
    }) => {
    const sharedClasses = useSharedStyles()
    const [confirmText, setConfirmText] = useState<string>('')
    const variantColorMapping : GodmodeVariantColorMappingType = {
        'default': 'green',
        'danger': 'red'
    }

    const handleConfirm = async () => {
        if (confirmText !== confirmInputMatch) {
          return
        }
        if (typeof onConfirm === 'function') {
          onConfirm()
        }
        setConfirmText('')
    }

    return (
        <Dialog open={dialogState.open}>
            {dialogTitle &&
                <DialogTitle>{dialogTitle}</DialogTitle>
            }
            <DialogContent className={sharedClasses.vspacing2}>
                {dialogContent &&
                    <Box>{dialogContent}</Box>
                }
                <TextField
                    onChange={(e) => setConfirmText(e.target.value)}
                    id="godmode_confirm_modal"
                    name="godmode_confirm_modal"
                    value={confirmText}
                    placeholder={confirmInputPlaceholder}
                />
            </DialogContent>
            <DialogActions>
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Button
                        variant="contained"
                        color="orange"
                        onClick={() => {
                            setConfirmText('')
                            dialogState.onClose()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color={variantColorMapping[variant]}
                        onClick={handleConfirm}
                        disabled={confirmText !== confirmInputMatch}
                    >
                        Confirm
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}