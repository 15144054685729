import React, { useState } from 'react';
import useVideoAssignments from 'loaders/useVideoAssignments';
import useVideos from 'loaders/useVideos';
import Loading from 'components/loading/Loading';
import Youtube from 'react-youtube';
import curriculumUnits from 'json/curriculum_units_simple.json';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import KidsSideNav from './shared/KidsSideNav';
import { Link, useParams } from 'react-router-dom';
import Amplitude from 'utils/Amplitude';

interface Unit {
  id: number;
  name: string;
}

interface Video {
  id: number;
  name: string;
  description: string;
  video_id: string;
  unit_id: number;
  resource_type: string;
}

interface DropdownProps {
  filter: string;
  setFilter: (value: string) => void;
  currentUnits: Unit[];
}

const Dropdown: React.FC<DropdownProps> = ({ filter, setFilter, currentUnits }) => {
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <div className="relative">
      <input
        className="border px-2 py-2 w-full cursor-pointer"
        type="text"
        readOnly
        value={filter}
        onClick={() => setOpenFilter(!openFilter)}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Filter by Video"
      />
      <svg
        className="h-6 w-6 absolute right-2 top-2 text-gray-700"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor">
        <path
          fillRule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
      {openFilter && (
        <ul className="list-none bg-white border overflow-y-scroll max-h-36 absolute z-10 w-full">
          {currentUnits.map((u) => (
            <li
              onClick={() => {
                setFilter(u.name);
                setOpenFilter(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setFilter(u.name);
                  setOpenFilter(false);
                }
              }}
              tabIndex={0}
              className="px-2 pb-1 text-gray-700 active:bg-kodable-blue-darker focus:bg-kodable-blue-dark focus:text-white cursor-pointer"
              key={u.id}>
              {u.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

const KidsVideosPage: React.FC = () => {
  const { studentCode } = useParams<{ studentCode: string }>();
  const { videoAssignments } = useVideoAssignments(studentCode);
  const { videos } = useVideos();
  const [filter, setFilter] = useState('');

  const filteredVideos = videos?.filter((v) => {
    return (
      v.resource_type === 'coding_concept_video' ||
      v.resource_type === 'creative_project_videos'
    );
  });

  const selectedUnits = curriculumUnits
    .filter((unit) => unit.name.toLowerCase().includes(filter.toLowerCase()))
    .map((u) => u.id);

  const unitsToShow = uniq(compact(filteredVideos?.map((v) => v.unit_id)));
  const currentUnits = curriculumUnits.filter((unit) =>
    unitsToShow.includes(unit.id)
  );

  // @ts-ignore
  return (
    <div className="flex max-h-screen h-full">
      <KidsSideNav
        studentCode={studentCode}
        studentName={
          videoAssignments && videoAssignments.student
            ? videoAssignments.student.name
            : studentCode
        }
      />
      <div className="relative overflow-y-scroll w-full h-screen pt-16 sm:pt-0">
        <div className="relative flex flex-col min-h-full max-w-screen-lg mx-auto px-4">
          {!videos && (
            <div className="flex justify-center items-center mt-32 h-64">
              <Loading />
            </div>
          )}
          {videos && (
            <div className="mt-12">
              <Dropdown
                filter={filter}
                setFilter={setFilter}
                currentUnits={currentUnits}
              />
              <div className="flex flex-col justify-center items-center mt-12">
                {filteredVideos
                  ?.filter((v) => v.unit_id && selectedUnits.includes(Number(v.unit_id)))
                  .map((video) => (
                    <div key={video.id} className="max-w-3xl w-full mb-16">
                      {video.video_id &&
                        <Youtube
                          onPlay={() => {
                            Amplitude.track('Viewed Kids Video Page', {
                              Section: 'Single Video',
                              'Video Name': video.name,
                            });
                          }}
                          videoId={video.video_id}
                          containerClassName="youtubeContainer"
                        />
                      }
                      <Link className="group" to={`videos/${video.id}`}>
                        <h3 className="font-bold text-gray-700 -mt-8 group-hover:text-kodable-blue">
                          {video.name}
                        </h3>
                        <p className="font-light text-gray-700 mt-2 group-hover:text-kodable-blue">
                          {video.description}
                        </p>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default KidsVideosPage;
