import { useEffect } from "react";

export const useAskBeforeClosingPage = () => {
  useEffect(() => {
    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    }
  }, []);
}