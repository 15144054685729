import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Button from 'components/ui/buttons/Button';
import { DialogStateProps } from 'hooks/useDialogState';
import React from 'react';

export const SalesQuoteSameContactModal: React.FC<{dialogState: DialogStateProps, setBillingContactIsSubmitter: (value: boolean) => void}> = ({dialogState, setBillingContactIsSubmitter}) => {
    return (
        <Dialog open={dialogState?.open}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <Typography variant="body1">It looks like you listed yourself as the billing contact. This should be the person in charge of processing payments for your school, not the person in charge of using Kodable in the classroom.</Typography>
            </DialogContent>
            <DialogActions>
                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                    <Button
                        startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                        variant="outlined"
                        color="red"
                        onClick={() => {
                            dialogState?.onClose()
                        }}
                    >
                        No, go back
                    </Button>
                    <Button
                        endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            dialogState?.onClose()
                            setBillingContactIsSubmitter(true)
                        }}
                    >
                        Yes, continue
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}