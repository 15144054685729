import mutate from './mutate';

type config = {
  student_codes: string[],
  teacher_id: number
}

const useImportStudent = () => {
  return (args: config) => {
    return mutate(`/v2/students/import`, 'POST', args);
  };
}

export default useImportStudent