import { Dialog, DialogTitle, DialogContent, Typography, MenuItem, DialogActions, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { routes } from "routes";
import Button from "components/ui/buttons/Button";
import Select from "components/ui/Select";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import { useTracking } from "context/TrackingProvider";
import endpoints from "endpoints";
import { useFormik } from "formik";
import { DialogStateProps } from "hooks/useDialogState";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import states from "states-us/dist";
import { mutate } from "swr";
import * as Yup from "yup";


interface IAddSchoolRequest {
  teacher_id: number;
  school_name: string;
  school_mailing_city: string;
  school_mailing_state: string;
  school_searched_zip?: string;
}

const RequestAddSchoolDialog: React.VFC<DialogStateProps & { school_mailing_city?: string, school_mailing_state?: string, school_searched_zip?: string }> = ({ open = false, school_mailing_city, school_mailing_state, school_searched_zip, onClose = () => {} }) => {
  const { session, currentUser } = useCurrentUser();
  const [submitError, setSubmitError] = useState(false);
  const history = useHistory();
  const alert = useAlert();
  const { track } = useTracking();

  const form = useFormik<IAddSchoolRequest>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      teacher_id: currentUser.id,
      school_name: '',
      school_mailing_city: school_mailing_city || '',
      school_mailing_state: school_mailing_state || '',
      school_searched_zip: school_searched_zip || ''
    },
    onSubmit: values => {
      justFetch(endpoints.requestAddSchool, 'POST', values)
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          alert.success('School Request Submitted');
          track('Add School Completed', {
            result: 'school requested'
          });
          return mutate(endpoints.session, {
            ...session,
            user: {
              ...session.user,
              is_non_usa: true
            }
          });
        })
        .then(() => history.push(routes.classes.index))
        .catch(() => setSubmitError(true))
    },
    validationSchema: Yup.object({
      school_name: Yup.string().required('Enter the name of your school'),
      school_mailing_city: Yup.string().required('Enter the city that your school is in'),
      school_mailing_state: Yup.string().required('Enter the state that your school is in'),
    })
  });
  const sharedClasses = useSharedStyles();
  useEffect(() => {
    if (open) {
      form.resetForm();
      setSubmitError(false);
    }
  }, [open]);

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>
        Add A New School
      </DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <Typography>Please enter your school name, city, and state. We will add your school.</Typography>
        <TextField
          label="School"
          id="school_name"
          name="school_name"
          value={form.values.school_name}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
          helperText={form.errors.school_name}
          error={form.submitCount > 0 && !!form.errors.school_name}
        />
        <TextField
          label="City"
          id="school_mailing_city"
          name="school_mailing_city"
          value={form.values.school_mailing_city}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
          helperText={form.errors.school_mailing_city}
          error={form.submitCount > 0 && !!form.errors.school_mailing_city}
        />
        <Select
          label="State"
          id="school_mailing_state"
          name="school_mailing_state"
          value={form.values.school_mailing_state}
          onChange={(e) =>
            form.setFieldValue("school_mailing_state", e.target.value)
          }
          disabled={form.isSubmitting}
          displayEmpty
          fullWidth
          helperText={form.errors.school_mailing_state}
          error={form.submitCount > 0 && !!form.errors.school_mailing_state}
        >
          <MenuItem disabled value="">
            Select your state
          </MenuItem>
          {states
            .filter(({ territory }) => !territory)
            .map(({ name, abbreviation }) => (
              <MenuItem key={abbreviation} value={abbreviation}>{name}</MenuItem>
            ))}
        </Select>
        {form.submitCount > 0 && !form.isValid && <Alert severity="error">Please correct the errors above to continue.</Alert>}
        {submitError && <Alert severity="error" action={<Button size="small" color="inherit" onClick={() => form.handleSubmit()}>Try Again</Button>}>There was an error submitting this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={form.isSubmitting}
          onClick={onClose}
          type="reset"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="green"
          disabled={form.isSubmitting}
          type="submit"
        >
          Add School
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default RequestAddSchoolDialog;