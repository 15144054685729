import {ISubscription, ISubscriptionSource, ISubscriptionTerm} from "../../types/ISubscription";
import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {format} from "date-fns";
import {getPlanName} from "../../utils/planHelpers";


type BillingInformationBoxProps = {
  subscription: ISubscription
  invoice: any
}

const BillingInformationBox: React.VFC<BillingInformationBoxProps> = ({subscription, invoice}) => {
  return (
    <div className="flex flex-col mb-12 border shadow rounded pb-4 pt-2 px-4 text-gray-800 bg-white">
      <h2 className="text-3xl">Billing Information</h2>
      {subscription.term === ISubscriptionTerm.None && (
        <Fragment>
          <p className="mt-2 mb-1">You are currently on the free plan</p>
          <div className="mt-6 self-end">
            <Link
              to={{pathname: "/parent/upgrade/info", state: {showUI: true}}}
              className="text-center bg-kodable-blue block hover:bg-kodable-blue-dark active:bg-kodable-blue-darker px-4 py-2 rounded text-white">
              Upgrade
            </Link>
          </div>
        </Fragment>
      )}
      {subscription.end_date && (subscription.term === ISubscriptionTerm.Gift6 || subscription.term === ISubscriptionTerm.Gift12) && (
        <p className="mt-2 mb-1">
          Your {getPlanName(subscription.term)} will end on {format(new Date(subscription.end_date), 'MMM dd, yyyy')}.
        </p>
      )}
      {(subscription.term === ISubscriptionTerm.Lifetime || subscription.term === ISubscriptionTerm.GiftLifetime) && (
        <Fragment>
          <p className="mt-2 mb-1">You have lifetime access.</p>
        </Fragment>
      )}
      {(subscription.term === ISubscriptionTerm.Monthly ||
          subscription.term === ISubscriptionTerm.Yearly) &&
        subscription.source === ISubscriptionSource.Stripe && (
          <Fragment>
            {invoice && !invoice.has_upcoming_invoice && subscription?.end_date && (
              <p className="mt-2 mb-1">
                Your current Kodable {getPlanName(subscription.term)} {invoice.is_trial ? 'Trial' : 'Subscription'} will end
                on{' '}
                {format(new Date(subscription.end_date), 'MMM dd, yyyy')}.
              </p>
            )}
            {invoice && invoice.has_upcoming_invoice && (
              <Fragment>
                <p className="mt-2 mb-1">
                  {invoice &&
                    invoice.is_trial &&
                    `Your trial for Kodable ${getPlanName(subscription.term)
                    } will end on ${format(
                      new Date(invoice.trial_end),
                      'MMM dd, yyyy'
                    )}`}
                  {invoice &&
                    !invoice.is_trial &&
                    `Your ${getPlanName(subscription.term)} Subscription will renew
                  on ${format(new Date(invoice.due_date), 'MMM dd, yyyy')}`}
                </p>

                <div>
                  <p className="mt-2 font-bold">
                    Next Bill Date:{' '}
                    <span className="font-normal">
                        {format(new Date(invoice.due_date), 'MMM dd, yyyy')}
                      </span>
                  </p>
                  <p className="mb-1 font-bold">
                    Next Bill Amount:{' '}
                    <span className="font-normal">${invoice.amount_due}</span>
                  </p>
                </div>
              </Fragment>
            )}
            {invoice && invoice.has_upcoming_invoice && (
              <div className="text-center mt-6 self-start space-y-2 md:space-y-0 md:self-end flex flex-col md:flex-row">
                {subscription.term === ISubscriptionTerm.Monthly && (<React.Fragment>
                  <Link
                    to={{pathname: "/parent/upgrade/plan/yearly", state: {showUI: true, hidePlansButton: true}}}
                    className="bg-kodable-green text-sm sm:text-base px-2 block hover:bg-kodable-green-dark active:bg-kodable-green-darker sm:px-4 py-2 rounded text-white mr-6">
                    Upgrade to Yearly
                  </Link>
                </React.Fragment>)}
                <Link
                  to={{pathname: "/parent/upgrade/plan/lifetime", state: {showUI: true, hidePlansButton: true}}}
                  className="text-center bg-kodable-green text-sm sm:text-base px-2 block hover:bg-kodable-green-dark active:bg-kodable-green-darker sm:px-4 py-2 rounded text-white mr-6">
                  Purchase Lifetime Access
                </Link>
                <Link
                  to="/settings/update-billing"
                  className="text-center bg-kodable-blue text-sm sm:text-base px-2 block hover:bg-kodable-blue-dark active:bg-kodable-blue-darker sm:px-4 py-2 rounded text-white mr-6">
                  Update Billing Info
                </Link>
                <Link
                  to="/settings/cancel-subscription"
                  className="text-center bg-kodable-red text-sm sm:text-base px-2 block hover:bg-kodable-red-dark active:bg-kodable-red-darker sm:px-4 py-2 rounded text-white">
                  Cancel {invoice.is_trial ? 'Trial' : 'Subscription'}
                </Link>
              </div>
            )}
            {invoice && !invoice.has_upcoming_invoice && (
              <div className="mt-6 self-end flex">
                <Link
                  to={{pathname: "/parent/upgrade/info", state: {showUI: true}}}
                  className="bg-kodable-green text-sm sm:text-base px-2 block hover:bg-kodable-green-dark active:bg-kodable-green-darker sm:px-4 py-2 rounded text-white">
                  Resume Subscription
                </Link>
              </div>
            )}
          </Fragment>
        )}

      {subscription.source === ISubscriptionSource.Apple && (
        <Fragment>
          <p className="mt-2 mb-1">
            Your subscription is managed through iTunes.{' '}
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.apple.com/en-us/HT202039"
              className="text-kodable-blue">
              Manage or cancel your subscription
            </a>
          </p>

          <div className="mt-6 self-start space-y-2 md:space-y-0 flex flex-col md:flex-row">
            <Link
              to={{pathname: "/parent/upgrade/plan/lifetime", state: {showUI: true, hidePlansButton: true}}}
              className="text-center bg-kodable-green text-sm sm:text-base px-2 block hover:bg-kodable-green-dark active:bg-kodable-green-darker sm:px-4 py-2 rounded text-white">
              Purchase Lifetime Access
            </Link>
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default BillingInformationBox