import useStudentMazeSettings from 'loaders/useStudentMazeSettings';
import { compareDesc, parseISO } from 'date-fns';
import {useParams} from "react-router-dom";
import {IRouteParams} from "../AppRoutes";
import useParentInit from "./useParentInit";
import find from "lodash/find";

export default function useProjects() {
  const { studentId } = useParams<IRouteParams>();
  const { students } = useParentInit();
  const student = find(students, {
    id: Number(studentId),
  });
  const { loading, error, mazeSettings = [] } = useStudentMazeSettings(
    student!.student_code
  );

  const custom_fuzzes = student?.custom_fuzzes || [];

  const projects = [...mazeSettings, ...custom_fuzzes]
    .map((project) => {
      if (project?.category === 'Maze') {
        return {
          ...project,
          type: 'maze',
        };
      } else if (project?.superClazzName === 'Fuzz') {
        return { config: project, type: 'fuzz', created_at: project.id, updated_at: project.id, student_id: studentId, id: project.id, client_id: project.id};
      } else {
        return { ...project, type: 'game' };
      }
    })
    .sort((a, b) => compareDesc(parseISO(a.created_at), parseISO(b.created_at)));

  return { loading, error, projects };
}
