import React, { Dispatch, Reducer } from 'react';

import Alert from 'components/alert/Alert';
import IAlert from 'components/alert/IAlert';

const AlertDispatchContext = React.createContext<Dispatch<IAlert>>(() => {
  throw new Error("Cannot dispatch alerts outside an alert context.")
});

const alertReducer: Reducer<{ alerts: IAlert[] }, IAlert> = (state, action) => {
  const id = action.id || '_' + Math.random().toString(36).substr(2, 9);

  switch (action.type) {
    case 'success': {
      return {
        alerts: [...state.alerts, { ...action, id }],
      };
    }
    case 'error': {
      return {
        alerts: [...state.alerts, { ...action, id }],
      };
    }
    case 'removeAlert': {
      return {
        alerts: state.alerts.filter((alert) => alert.id !== action.id),
      };
    }
    case 'removeAllAlerts': {
      return {
        alerts: [],
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const AlertProvider: React.FC = ({ children }) => {
  const [state, _dispatch] = React.useReducer(alertReducer, { alerts: [] });

  const dispatch: React.Dispatch<IAlert> = (alert: IAlert) => {
    _dispatch(alert);
    if ((alert.type === 'success' || alert.type === 'error') && alert.timeout) {
      setTimeout(() => {
        _dispatch({ type: 'removeAlert', id: alert.id });
      }, alert.timeout);
    }
  }

  return (
    <AlertDispatchContext.Provider value={dispatch}>
      <Alert alerts={state.alerts} />
      {children}
    </AlertDispatchContext.Provider>
  );
}

function useAlertDispatch() {
  return React.useContext(AlertDispatchContext);
}

export function useAlert() {
  const dispatch = useAlertDispatch();

  return {
    success: (title?: string, message?: string, timeout: number = 5000) => {
      dispatch({ type: 'success', id: +(new Date()), title, message, timeout })
    },
    error: (title?: string, message?: string, timeout: number = 5000) => {
      dispatch({ type: 'error', id: +(new Date()), title, message, timeout })
    },
  }
}

export default AlertProvider;

export { useAlertDispatch };
