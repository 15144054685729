import React, { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import makeApiMethodOnLoadHook from "./makeApiMethodOnLoadHook";

export const appleIdClientId = 'com.surfscore.kodable.web';

interface AppleIDContextValue {
  didLoad: boolean;
}

const AppleIDContext = createContext<AppleIDContextValue>(null!);

const AppleIDProvider: React.FC = ({ children }) => {
  const [didLoad, setDidLoad] = useState(false);

  const handleApiLoad = () => {
    (window as any).AppleID.auth.init({
      clientId : appleIdClientId,
      redirectURI : window.location.origin,
      scope : 'email',
      usePopup : true
    });

    setDidLoad(true);
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.onload = handleApiLoad;
    document.head.appendChild(script);
  }, []);

  return (<AppleIDContext.Provider value={{ didLoad }}>
    {children}
  </AppleIDContext.Provider>)
}

export const useAppleId = makeApiMethodOnLoadHook(AppleIDContext, () => (window as any).AppleID);

export default AppleIDProvider;
