import { NavContext } from "context/NavProvider";
import React, { useEffect } from "react";
import { useContext, useState } from "react";
import ReactDOM from "react-dom";

const AppBarContent: React.FC = ({ children }) => {
  const navContext = useContext(NavContext);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (navContext.navPortal.current) {
      setShowContent(true);
    }
  }, [navContext.navPortal.current])

  return (showContent && ReactDOM.createPortal(children, navContext.navPortal.current!)) || null;
}

export { AppBarContent };