import {Box, Typography, useTheme} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import Button from "components/ui/buttons/Button";
import LicensesTable from "pages/admin/LicensesTable";
import useTeacherInit from "loaders/useTeacherInit";
import { routes } from "routes";
import { useHistory } from "react-router-dom";
import useCurrentUser from "loaders/useCurrentUser";
import { mutate as mutateCurrentUser } from "loaders/useCurrentUser";

import endpoints from "endpoints";
import mutate from 'mutations/mutate';

const Warning: React.VFC<{ message: string, backgroundColor: string }> = ({ message, backgroundColor }) => {
  return (
    <Box
      bgcolor={backgroundColor}
      height={45}
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={16}
      paddingX={2}
    >
      <Box
        textAlign="center"
        style={{
          fontWeight: 400,
          fontSize: '18px',
        }}
      >
        {message}
      </Box>
    </Box>
  );
};

export const SuperAdminOnboardingAssignLicenses: React.FC = () => {
    const { teacherData, isValidating } = useTeacherInit();
    const history = useHistory();
    const { currentUser } = useCurrentUser();
    const theme = useTheme();
    const [clickedToContinue, setClickedToContinue] = useState<boolean>(false);

    const allAssigned = () => teacherData?.subscription?.licenses.filter(license => license.teacher_id).length == teacherData?.subscription?.licenses?.length;
    const warningMessage = allAssigned()
        ? 'Great! All site licenses have an administrator assigned.'
        : 'You must assign an administrator to each site license to continue!';
    const warningColor = allAssigned() ? theme.palette.green.main : theme.palette.red.main;

    return (
    <Box style={{ marginLeft: '5%', marginRight: '5%' }}>
      <br />
      <Box
        display="flex"
        alignItems="center"
        marginBottom={2}
        position="relative"
      >
        <Box flexGrow={1} display="flex" justifyContent="center">
          <Warning message={warningMessage} backgroundColor = {warningColor}/>
        </Box>
        <Box position="absolute" right={0}>
          <Button
            color="primary"
            variant="contained"
            disabled={!allAssigned() || clickedToContinue}
            onClick={async () => {
                setClickedToContinue(true);
                if (!currentUser.is_dashboard_onboarded) {
                    try {
                        await mutate(endpoints.parent.onboarding.finalize(currentUser.id),'PUT',{ is_dashboard_onboarded: true });
                        await mutateCurrentUser();
                    } catch (error) {
                        console.error('Failed to update onboarding status:', error);
                        return;
                    }
                }
                history.push(routes.classes.index);            
            }}
          >
            Continue To Dashboard
          </Button>
        </Box>
      </Box>
      <LicensesTable />
    </Box>
  );
};
