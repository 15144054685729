import {IRouteParams} from 'AppRoutes';
import React, {useLayoutEffect, useState} from 'react'
import {useRouteMatch} from 'react-router-dom';
import {GodmodePageWrapper} from 'pages/godmode/GodmodePageWrapper';
import {OrganizationProfileProvider} from 'context/OrganizationProfileProvider';
import {justFetch} from "mutations/mutate";
import endpoints from "endpoints";
import OrganizationDetailTabs from "./OrganizationDetailTabs";
import { IOrganizationProfile } from 'types/IOrganizationProfile';

const OrganizationProfileDetailIndex: React.FC = () => {
    const { params } = useRouteMatch<IRouteParams>();
    const [organizationProfile, setOrganizationProfile] = useState<IOrganizationProfile>()

    useLayoutEffect(() => {
        const fetchOrgDetail = async () => {
            const data = await justFetch(endpoints.godmode.organizationProfile.byId(params?.organizationProfileId,
              [
                'administrator',
                'teachers',
                'subscriptions',
                'current_subscription',
                'licenses',
                'payment',
                'purchase_process',
                'quote_request_v2',
                'clever'
              ]),
              'GET');
            const payload: IOrganizationProfile = await data.json();
            setOrganizationProfile(payload);
        }
        fetchOrgDetail();
    }, [params])



    return (
        <OrganizationProfileProvider organizationProfileId={params?.organizationProfileId}>
            <GodmodePageWrapper title={organizationProfile?.display_name || ''} loading={false} showCleverIcon={organizationProfile?.clever}>
                <OrganizationDetailTabs />
            </GodmodePageWrapper>
        </OrganizationProfileProvider>
    )
}

export default OrganizationProfileDetailIndex

