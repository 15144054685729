import {Box, Typography, useTheme} from "@material-ui/core";

const AssignedGameBadge: React.VFC<{basics: boolean, creator: boolean}> = ({basics, creator}) => {
  const theme = useTheme()

  return (
    <Box
      style={{
        gap: '0.5rem',
        marginLeft: 15,
        marginRight: 15,
      }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {basics &&
        <Typography
          style={{
            fontFamily: 'Bjola',
            fontWeight: 'bold',
            fontSize: '1rem',
            lineHeight: '1rem',
            paddingTop: '.75rem',
            color: theme.palette.newOrange.main
          }}
        >
          BASICS
        </Typography>
      }
      {basics && creator
        && <Typography
          style={{
            fontFamily: 'Bjola',
            fontWeight: 'bold',
            fontSize: '1rem',
            lineHeight: '1rem',
            paddingTop: '.75rem'
          }}>•</Typography>
      }
      {creator &&
        <Typography
          style={{
            fontFamily: 'Bjola',
            fontWeight: 'bold',
            fontSize: '1rem',
            lineHeight: '1rem',
            paddingTop: '.75rem',
            color: theme.palette.newPink.main
          }}
        >
          CREATOR
        </Typography>
      }
    </Box>
  )
}

export default AssignedGameBadge