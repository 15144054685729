import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, LinearProgress, Radio, RadioGroup, TextField, Typography, useTheme } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import { Alert } from "@material-ui/lab";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useFormik } from "formik";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import React, { useState } from "react";
import { useEffect } from "react";
import * as Yup from 'yup';
import { useRedirectToLogin } from "context/AuthProvider";
import { useAlert } from "context/AlertProvider";

interface TransferDialogProps {
  open: boolean;
  onClose: (...args: any[]) => any;
}

const TransferAccountDialog: React.VFC<TransferDialogProps> = ({ open, onClose }) => {
  const { currentUser } = useCurrentUser();
  const { redirectToLogin } = useRedirectToLogin();
  const theme = useTheme();
  const alert = useAlert();
  const hasMultipleLicenses = (currentUser?.organization_profile?.current_subscription?.licenses?.length || 0) > 1;

  function delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  function getErrorMessage(error: unknown): string {
    if (error instanceof Error) {
      return error.message;
    } else if (typeof error === 'string') {
      return error;
    } else if (typeof error === 'object' && error !== null) {
      return JSON.stringify(error);
    } else {
      return 'An unknown error occurred.';
    }
  }  
  const form = useFormik({
    validateOnChange: false,
    validateOnMount: true,
    initialValues: {
      name: '',
      email: '',
      confirm: ''
    },
    validationSchema: Yup.object({
      confirm: Yup.string().required('Please type TRANSFER').matches(/transfer/i, 'Please type TRANSFER'),
      name: Yup.string().required('Please enter name'),
      email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Please enter your email address.')
    }),
    onSubmit: async () => {
      try {
        const res = await justFetch(endpoints.teacherTransferAccount(currentUser?.id), 'PUT', {
          transfer_to_name: form.values.name,
          transfer_to_email: form.values.email
          });
        if (!res.ok) {
          console.log('TEST: transfer1')
          const body = await res.json();
          console.log('TEST: transfer2', body)
          alert.error(body.error || body.message);
          console.log('TEST: transfer3')
          setSubmitError(true);
        } else {
          alert.success(`Account Transfered!`)
          onClose();
          await delay(3000);
          redirectToLogin(true);
        }
      } catch (error) {
          console.log('TEST: transfer4')

        alert.error(getErrorMessage(error));
        return setSubmitError(true);
      }
    }
  });

  const [submitError, setSubmitError] = useState(false);
  const sharedClasses = useSharedStyles();

  useEffect(() => {
    if (open) {
      form.handleReset(null);
      setSubmitError(false);
    }
  }, [open]);

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>Transfer Account?</DialogTitle>
      <DialogContent>

        <Typography>
          The transferee will get access to all of your students and classes, and you will no longer be able to access your account.
          {hasMultipleLicenses? ' This person will also become the new administrator for your organization.':''}
          <br/>
        </Typography>

        <br/>

        <Typography
          variant="subtitle1"
        >Name of transferee:</Typography>

        <TextField
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={form.errors.name}
          error={!!form.errors.name}
          disabled={form.isSubmitting}
          id="name"
          type="text"
          variant="outlined"
          fullWidth
          margin="dense"
        />
        <br/>
        <br/>

        <Typography
          variant="subtitle1"
        >Email address of transferee:</Typography>

        <TextField
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={form.errors.email}
          error={!!form.errors.email}
          disabled={form.isSubmitting}
          id="email"
          type="text"
          variant="outlined"
          fullWidth
          margin="dense"
        />
        <br/>
        <br/>
        <label htmlFor="confirm">
          <Typography
            variant="subtitle1"
          >Type TRANSFER to confirm that you want to perform transfer:</Typography>
        </label>

        <TextField
          value={form.values.confirm}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={form.errors.confirm}
          error={!!form.errors.confirm}
          disabled={form.isSubmitting}
          id="confirm"
          type="text"
          variant="outlined"
          fullWidth
          margin="dense"
        />

        {submitError && <Alert severity="error" action={
          <Button
            color="inherit"
            size="small"
            onClick={() => form.submitForm()}
          >Try again</Button>
        }>There was an error trying to transfer your account.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          classes={{ root: sharedClasses.dangerButton }}
          disabled={form.isSubmitting}
          type="submit"
        >
          Confirm Transfer
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default TransferAccountDialog;