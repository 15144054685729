import endpoints from 'endpoints';
import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

export type SchoolsRequestsProviderProps = {
    schoolRequest: ISchoolRequest | undefined;
    error: any;
    setShouldMutate: Dispatch<SetStateAction<boolean>>;
}

export interface ISchoolRequest {
    school: ISchoolRequestSchool;
    matches: ISchoolRequestsMatch[];
    school_teacher: ISchoolRequestTeacher;
}

export interface ISchoolRequestSchool {
    id: number;
    teacher_id: number;
    email: string | null;
    school_name: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    created_at: string | null;
    updated_at: string | null;
}


export interface ISchoolRequestTeacher {
    id: number;
    username: string;
    name: string;
    occupation: string;
    plan: string;
    student_limit: null | number;
    is_administrator: boolean;
    administrator_id: null | number;
    temp_password: boolean;
    is_god: boolean;
    is_parent: boolean;
    created_at: string;
    updated_at: string;
    administrator_code: null | string;
    school_id: null | number;
    is_non_usa: null | boolean;
    used_klass_code: null | string;
    is_onboarded: boolean;
    has_curriculum: boolean;
    is_registered_via_website: boolean;
    is_dashboard_onboarded: boolean;
    from_school: boolean;
    quickbooks_id: null | number;
}

export interface ISchoolRequestsMatch {
    id: number;
    nces_id: string | null;
    nces_school_id: number | null;
    state_school_id: string | null;
    school_name: string | null;
    school_phone: string | null;
    school_mailing_address: string | null;
    school_mailing_city: string | null;
    school_mailing_state: string | null;
    school_mailing_zip: number | null;
    school_mailing_zip4: number | null;
    school_location_address: string | null;
    school_location_city: string | null;
    school_location_state: string | null;
    school_location_zip: number | null;
    school_location_zip4: number | null;
    school_nces_code: number | null;
    school_nces_status: number | null;
    nces_locale_code: null | number;
    school_latitude: string | null;
    school_longitude: string | null;
    school_ansi_county_num: null | number;
    school_county_name: null | string;
    school_low_grade: number | null;
    school_high_grade: number | null;
    school_grade_level: string | null;
    is_charter: boolean | null;
    is_public: boolean | null;
    created_at: string | null;
    updated_at: string | null;
    latitude: string | null;
    longitude: string | null;
    ll_point: string | null;
    district_id: number | null;
}

export interface SchoolsContextProps {
    schoolRequest: ISchoolRequest | undefined;
    loading: boolean;
    setShouldMutate: React.Dispatch<React.SetStateAction<boolean>>;
    error: any;
    mutate: () => void;
}

const SchoolRequestContext = createContext<SchoolsContextProps>({
  schoolRequest: undefined,
  loading: true,
  setShouldMutate: () => {},
  error: null,
  mutate: () => {}
});

interface SchoolsRequestProviderProps {
    children: ReactNode;
}

export const SchoolsRequestProvider: React.FC<SchoolsRequestProviderProps> = ({ children }) => {
  const [schoolRequest, setSchoolRequest] = useState<ISchoolRequest>();
  const [shouldMutate, setShouldMutate] = useState<boolean>(false);
  
  const { data, mutate, error, isValidating } = useSWR<ISchoolRequest>(endpoints.godmode.schoolRequests);
  
  useEffect(() => {
    if (data) {
        setSchoolRequest(data);
    }
  }, [data]);

  // Mutate (re-request) each time the page/search changes
  useEffect(() => {
    if (!shouldMutate) {
        return
    }
    mutate().then(() => {
        setShouldMutate(false)
    })
  }, [shouldMutate, mutate])
  
  return (
    <SchoolRequestContext.Provider value={{ schoolRequest, loading: isValidating, setShouldMutate, error, mutate }}>
      {children}
    </SchoolRequestContext.Provider>
  );
};

export const useSchoolRequestContext = () => useContext(SchoolRequestContext);
