import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import useSharedStyles from "../../../../components/useSharedStyles";
import Button from "../../../../components/ui/buttons/Button";
import React from "react";
import useCurrentUser from "loaders/useCurrentUser";
import { IExistingOrder } from "./ConfigurePlan";

const ExistingOrderDialog: React.VFC<{open: boolean, onClose: () => void, existingOrder: IExistingOrder | undefined}> = ({open, onClose, existingOrder}) => {
  const sharedClasses = useSharedStyles()
  const { currentUser } = useCurrentUser();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>An order for your organization exists!</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
          {existingOrder?.email_matches? 
            <Typography variant="body1">You already submitted an order for this organization using the contact information below.</Typography> :
            <Typography variant="body1">Someone at your organization has already submitted an order. Please contact them to get more information.</Typography>}
        <Box marginLeft={3}>
          <Typography variant="body1" style={{fontWeight: 700}}>Name: <span style={{fontWeight: 400}}>{existingOrder?.email_matches? currentUser?.name : existingOrder?.name}</span></Typography>
          <Typography variant="body1" style={{fontWeight: 700}}>Email: <span style={{fontWeight: 400}}>{existingOrder?.email_matches? currentUser?.username : existingOrder?.email}</span></Typography>
        </Box>
          <Typography variant="body1">If you have any questions please contact <a
            href="mailto:support@kodable.com">support@kodable.com</a></Typography>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExistingOrderDialog