import {IBadgeData} from "../../types/IBadgeData";

export const badgeData: IBadgeData[] = [
  // MAZES
  {
    id: "TutorialCompleted",
    courseId: 6,
    tierProgressIds: ["Complete Tutorial 0"],
    name: "Tutorial Badge",
    description: "Completed Maze Maker Tutorial",
    size: "minor",
    tierValues: ["Complete the Tutorial Levels"],
    icon: "minor_icon_b_tutorial"
  },
  {
    id: "SequenceCourse",
    courseId: 8,
    tierProgressIds: ["Complete Cloudhaven 0", "Complete Cloudhaven 1", "Complete Cloudhaven 2", "Complete Cloudhaven 3"],
    name: "Sequence Course Badge",
    description: "Completed Sequence Course",
    size: "major",
    tierValues: ["Beginner", "Debug", "Intermediate", "Mastery"],
    icon: "major_icon_b_seq"
  },
  {
    id: "ConditionCourse",
    courseId: 2,
    tierProgressIds: ["Complete Smeeborg 0", "Complete Smeeborg 1", "Complete Smeeborg 2", "Complete Smeeborg 3"],
    name: "Condition Course Badge",
    description: "Completed Condition Course",
    size: "major",
    tierValues: ["Beginner", "Debug", "Intermediate", "Mastery"],
    icon: "major_icon_b_cond"  
  },
  {
    id: "BooleanCourse",
    courseId: 4,
    tierProgressIds: ["Complete Asteroid Belt 0", "Complete Asteroid Belt 1", "Complete Asteroid Belt 2", "Complete Asteroid Belt 3"],
    name: "Boolean Course Badge",
    description: "Completed Boolean Course",
    size: "major",
    tierValues: ["Beginner", "Debug", "Intermediate", "Mastery"],
    icon: "major_icon_b_bools"
  },
  {
    id: "LoopCourse",
    courseId: 3,
    tierProgressIds: ["Complete Aquatopia 0", "Complete Aquatopia 1", "Complete Aquatopia 2", "Complete Aquatopia 3"],
    name: "Loop Course Badge",
    description: "Completed Loop Course",
    size: "major",
    tierValues: ["Beginner", "Debug", "Intermediate", "Mastery"],
    icon: "major_icon_b_loops"
  },
  {
    id: "FunctionCourse",
    courseId: 9,
    tierProgressIds: ["Complete Moongarden 0", "Complete Moongarden 1", "Complete Moongarden 2", "Complete Moongarden 3"],
    name: "Function Course Badge",
    description: "Completed Function Course",
    size: "major",
    tierValues: ["Beginner", "Debug", "Intermediate", "Mastery"],
    icon: "major_icon_b_func"
  },
  {
    id: "StarsCollected",
    courseId: 1,
    tierProgressIds: ["Collect Stars"],
    name: "Star Collector Badge",
    description: "Collect Stars",
    size: "minor",
    tierValues: ["10", "50", "300", "760"],
    icon: "minor_icon_b_stars_collect",
    unit: "star",
    pluralUnit: "stars"
  },
  {
    id: "VortexLevelsCompleted",
    courseId: 1,
    tierProgressIds: ["Complete Vortex Level"],
    name: "Vortex Badge",
    description: "Complete Vortex Levels",
    size: "major",
    tierValues: ["1", "10", "50", "300"],
    icon: "major_icon_b_vortex",
    unit: "level",
    pluralUnit: "levels"
  },
  // PETS
  {
    id: "PetsCollected",
    courseId: 904,
    tierProgressIds: ["Get New Pet"],
    name: "Pet Collector Badge",
    description: "Collect More Pets",
    size: "minor",
    tierValues: ["1", "2", "4", "7"],
    icon: "minor_icon_pets_collector",
    unit: "pet",
    pluralUnit: "pets"
  },
  {
    id: "PetsEvolved",
    courseId: 904,
    tierProgressIds: ["Evolve Pet"],
    name: "Pet Trainer Badge",
    description: "Evolve Pets",
    size: "minor",
    tierValues: ["1", "2", "5", "15"],
    icon: "minor_icon_b_pets_evolve",
    unit: "pet evolved",
    pluralUnit: "pets evolved"
  },
  {
    id: "CarebotUpgrades",
    courseId: 904,
    tierProgressIds: ["Upgrade Carebot"],
    name: "CareBot Upgrader Badge",
    description: "Upgrade Carebot",
    size: "minor",
    tierValues: ["1", "2", "3", "5"],
    icon: "minor_icon_pets_carebot_upgrader",
    unit: "upgrade",
    pluralUnit: "upgrades"
  },
  {
    id: "ProgramCarebot",
    courseId: 904,
    tierProgressIds: ["Carebot Functions", "Carebot Functions With Loops", "Carebot Functions With Conditions"],
    name: "CareBot Programmer Badge",
    description: "Program Carebot",
    size: "major",
    tierValues: ["function", "loops", "conditions"],
    icon: "major_icon_b_pets_prog_carebot"
  },
  {
    id: "PetsDecorationsCollected",
    courseId: 904,
    tierProgressIds: ["Get Pets Decoration"],
    name: "Pet House Decorator Badge",
    description: "Number of Pet Decorations Collected",
    size: "minor",
    tierValues: ["1", "3", "7", "15"],
    icon: "minor_icon_b_pets_decor_collect",
    unit: "decoration",
    pluralUnit: "decorations"
  },
  {
    id: "PetsGiftsCollected",
    courseId: 904,
    tierProgressIds: ["Collect Pets Gift"],
    name: "Paw Gift Collector Badge",
    description: "Number of Paw Gifts Collected",
    size: "minor",
    tierValues: ["1", "5", "20", "100"],
    icon: "minor_icon_b_pets_present",
    unit: "gift",
    pluralUnit: "gifts"
  },
  {
    id: "Pets7DayLoginStreak",
    courseId: 904,
    tierProgressIds: ["Pets Login Streak"],
    name: "Consistent Caretaker Badge",
    description: "Logged in 7 days in a row",
    size: "minor",
    tierValues: ["7"],
    icon: "minor_icon_b_7login",
  },
  //MAZE MAKER
  {
    id: "MazeMakerMazesMade",
    courseId: 901,
    tierProgressIds: ["Make Maze","Make Maze With Conditions","Make Maze With Loops","Make Maze With Functions"],
    name: "Maze Maker Architect",
    description: "Types of mazes created",
    size: "major",
    tierValues: ["Sequence", "Conditions", "Loops", "Functions"],
    icon: "major_icon_b_make_maze",
    unit: "maze",
    pluralUnit: "mazes"
  },
  {
    id: "MazeMakerMazesPlayed",
    courseId: 901,
    tierProgressIds: ["Play Maze Maker Maze"],
    name: "Maze Maker Power Player",
    description: "Number of custom mazes played",
    size: "minor",
    tierValues: ["1", "5", "20", "100"],
    icon: "minor_icon_b_mm_mazes_played",
    unit: "maze",
    pluralUnit: "mazes"
  },
  {
    id: "MazeMakerDecorations",
    courseId: 901,
    tierProgressIds: ["Place Maze Maker Decoration"],
    name: "Maze Maker Use Decoration",
    description: "Number of maze decorations used",
    size: "minor",
    tierValues: ["5", "20", "50", "100"],
    icon: "minor_icon_b_mm_decor",
    unit: "decoration",
    pluralUnit: "decorations"
  },
  {
    id: "MazeMakerQuicksort",
    courseId: 901,
    tierProgressIds: ["Complete Quicksort Chain"],
    name: "Maze Maker Quicksort",
    description: "Number of times playing through quicksort mazes",
    size: "minor",
    tierValues: ["1", "3", "10", "30"],
    icon: "minor_icon_b_rapid_sort",
    unit: "play",
    pluralUnit: "plays"
  },
  // Fuzz Builder
  {
    id: "FuzzPartsCollected",
    courseId: 902,
    tierProgressIds: ["Fuzz Parts Collected"],
    name: "Fuzz Parts Collector Badge",
    description: "Number of Fuzz Parts Collected",
    size: "minor",
    tierValues: ["5", "15", "50", "150"],
    icon: "minor_icon_b_fb_item_collect",
    unit: "part",
    pluralUnit: "parts"
  },
  {
    id: "CustomizeFuzzes",
    courseId: 902,
    tierProgressIds: ["Custom Fuzz Count"],
    name: "Fuzz Customizer Badge",
    description: "Number of Fuzzes created",
    size: "major",
    tierValues: ["1", "2", "3", "5"],
    icon: "major_icon_b_fb_custom_x",
    unit: "fuzz",
    pluralUnit: "fuzzes"
  },
  // BeachCleanup
  {
    id: "BeachCleanupLevelsCompleted",
    courseId: 7,
    tierProgressIds: ["Complete Easy Beach Cleanup Level", "Complete Medium Beach Cleanup Level", "Complete Hard Beach Cleanup Level"],
    name: "Beach Cleanup Badge",
    description: "Complete Beach Cleanup",
    size: "major",
    tierValues: ["easy", "medium", "hard"],
    icon: "major_icon_b_bc_levels"
  },
  {
    id: "BeachCleanupAnimalsRescued",
    courseId: 7,
    tierProgressIds: ["Beach Cleanup Save Animal"],
    name: "Animal Rescuer Badge",
    description: "Save animals in Beach Cleanup",
    size: "minor",
    tierValues: ["1", "5", "20", "100"],
    icon: "minor_icon_b_bc_animals",
    unit: "animal",
    pluralUnit: "animals"
  },
  // EXTRAS
  {
    id: "FuzztopiaDecorationsCollected",
    courseId: 1, // TODO: Update CourseId
    tierProgressIds: ["Fuzztopia Decorations Collected"],
    name: "Fuzztopia Decorations Collector Badge",
    description: "Collect Decorations in Fuzztopia",
    size: "minor",
    tierValues: ["2", "5", "15", "50"],
    icon: "minor_icon_b_fuzztopia_decor_collect",
    unit: "decoration",
    pluralUnit: "decorations"
  },
  {
    id: "EventsCompleted",
    courseId: 1, // TODO: Update CourseId
    tierProgressIds: ["Complete Modular Event"],
    name: "Events Completionist Badge",
    description: "Complete Events",
    size: "minor",
    tierValues: ["1", "2", "3", "5"],
    icon: "minor_icon_b_events",
    unit: "event",
    pluralUnit: "events"
  },
  {
    id: "PlayMazesAfterSchool",
    courseId: 1, // TODO: Update CourseId
    tierProgressIds: ["Play Maze After School"],
    name: "Overachiever Badge",
    description: "Play Mazes after school",
    size: "minor",
    tierValues: ["1", "5", "15", "50"],
    icon: "minor_icon_afterschool",
    unit: "maze",
    pluralUnit: "mazes"
  },
  {
    id: "UseFuzztopiaDecorations",
    courseId: 1, // TODO: Update CourseId
    tierProgressIds: ["Fuzztopia Decorations Placed"],
    name: "Fuzztopia Decorator",
    description: "Place decorations in Fuzztopia",
    size: "minor",
    tierValues: ["1", "5", "15", "40"],
    icon: "minor_icon_b_fuzztopia_decor_used",
    unit: "decoration",
    pluralUnit: "decorations"
  },
  // CREATOR COURSES
  {
    id: "HighFlyerCourse",
    courseId: 1001,
    tierProgressIds: ["Complete HighFlyer Debug","Complete HighFlyer Elevate","Complete HighFlyer Hack","Complete HighFlyer Master"],
    name: "High Flyer Course",
    description: "Completing High Flyer Course Missions",
    size: "major",
    tierValues: ["Debug", "Elevate", "Hack", "Master"],
    icon: "major_icon_creator_flyer",
  },
  {
    id: "RunAndJumpCourse",
    courseId: 1002,
    tierProgressIds: ["Complete RunAndJump Debug","Complete RunAndJump Elevate","Complete RunAndJump Hack","Complete RunAndJump Master"],
    name: "Run And Jump Course",
    description: "Completing Run And Jump Course Missions",
    size: "major",
    tierValues: ["Debug", "Elevate", "Hack", "Master"],
    icon: "major_icon_creator_runjump",
  },
  {
    id: "CaptureTheFlagCourse",
    courseId: 1003,
    tierProgressIds: ["Complete CaptureTheFlag Debug","Complete CaptureTheFlag Elevate","Complete CaptureTheFlag Hack","Complete CaptureTheFlag Master"],
    name: "Capture The Flag Course",
    description: "Completing Capture The Flag Course Missions",
    size: "major",
    tierValues: ["Debug", "Elevate", "Hack", "Master"],
    icon: "major_icon_creator_flag",
  },
  {
    id: "KeyQuestCourse",
    courseId: 1004,
    tierProgressIds: ["Complete KeyQuest Debug","Complete KeyQuest Elevate","Complete KeyQuest Hack","Complete KeyQuest Master"],
    name: "Key Quest Course",
    description: "Completing Key Quest Course Missions",
    size: "major",
    tierValues: ["Debug", "Elevate", "Hack", "Master"],
    icon: "major_icon_creator_key",
  },
  // FREE CREATE
  {
    id: "GamesShared",
    courseId: 1800, // TODO: update courseId
    tierProgressIds: ["Publish Game"],
    name: "Game Sharing Badge",
    description: "Sharing created games with others",
    size: "major",
    tierValues: ["1", "5", "15", "50"],
    icon: "major_icon_creator_fc_sharedgames",
    unit: "game",
    pluralUnit: "games"
  },
  {
    id: "PublishedGamePlays",
    courseId: 1800, // TODO: update courseId
    tierProgressIds: ["Total Published Game Plays"],
    name: "Bestseller Badge",
    description: "How many players have played your shared games",
    size: "minor",
    tierValues: ["1", "20", "100", "1000"],
    icon: "minor_icon_creator_fc_players",
    unit: "player",
    pluralUnit: "players"
  },
  {
    id: "CustomGamesPlayed",
    courseId: 1800, // TODO: update courseId
    tierProgressIds: ["Play Custom Game"],
    name: "Guru Gamer Badge",
    description: "Number of custom games played",
    size: "minor",
    tierValues: ["1", "3", "10", "25"],
    icon: "minor_icon_creator_fc_games_played",
    unit: "game",
    pluralUnit: "games"
  },
  {
    id: "MakeGameMultipleLevels",
    courseId: 1800, // TODO: update courseId
    tierProgressIds: ["Make Game With Multiple Levels"],
    name: "Multilevel Game Badge",
    description: "Create a Game with Multiple Levels",
    size: "minor",
    tierValues: ["1"],
    icon: "minor_icon_creator_fc_levels"
  },
  {
    id: "MakeGameConditions",
    courseId: 1800, // TODO: update courseId
    tierProgressIds: ["Make Game With Conditions"],
    name: "Conditional Game Badge",
    description: "Create a Game with Conditions",
    size: "minor",
    tierValues: ["1"],
    icon: "minor_icon_creator_fc_cond"
  },
  {
    id: "AskCatBot",
    courseId: 1800, // TODO: update courseId
    tierProgressIds: ["Ask CatBot"],
    name: "CatBot Inquirer Badge",
    description: "Asked CatBot questions",
    size: "minor",
    tierValues: ["1", "3", "5", "10"],
    icon: "minor_icon_creator_catbot",
    unit: "question",
    pluralUnit: "questions"
  },
  {
    id: "UseEachGameTemplate",
    courseId: 1800, // TODO: update courseId
    tierProgressIds: ["Num Templates Used"],
    name: "Templates Badge",
    description: "Games created using different templates",
    size: "minor",
    tierValues: ["4"],
    icon: "minor_icon_creator_templates",
  },
  // BUG WORLD
  {
    id: "World1Completed",
    courseId: 1, // TODO: update courseId
    tierProgressIds: ["Complete bugworld_pack_1"],
    name: "World 1 Completionist Badge",
    description: "Completed World 1",
    size: "major",
    tierValues: ["1"],
    icon: "major_icon_bw_w1"
  },
  {
    id: "World2Completed",
    courseId: 1, // TODO: update courseId
    tierProgressIds: ["Complete bugworld_pack_2"],
    name: "World 2 Completionist Badge",
    description: "Completed World 2",
    size: "major",
    tierValues: ["1"],
    icon: "major_icon_bw_w2"
  },
  {
    id: "World3Completed",
    courseId: 1, // TODO: update courseId
    tierProgressIds: ["Complete bugworld_pack_3"],
    name: "World 3 Completionist Badge",
    description: "Completed World 3",
    size: "major",
    tierValues: ["1"],
    icon: "major_icon_bw_w3"
  },
  {
    id: "CustomTowersCreated",
    courseId: 1, // TODO: update courseId
    tierProgressIds: ["Create Custom Tower"],
    name: "Custom Tower Builder Badge",
    description: "Custom Towers Created",
    size: "minor",
    tierValues: ["1", "3", "5", "7"],
    icon: "minor_icon_bw_tower1",
    unit: "custom tower",
    pluralUnit: "custom towers"
  },
];
