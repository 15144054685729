import useSharedStyles from "../../../components/useSharedStyles";
import React, {useState} from "react";
import {Box, Dialog, DialogActions, DialogContent, Grid, Paper, Typography} from "@material-ui/core";
import Button from "../../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShare, faTrash} from "@fortawesome/free-solid-svg-icons";
import ReactImageFallback from "react-image-fallback";

interface StudentWorkGameCardProps {
  gameId: string;
  cardImageSrc: string;
  cardImageFallbackSrc: string;
  cardTitle?: string;
  cardContent?: string;
  dialogContent: React.ReactNode;
  maxWidth?: number;
  onDelete?: () => void;
}

const StudentWorkGameCard: React.VFC<StudentWorkGameCardProps> = ({
                                                                    gameId,
                                                                    cardImageSrc,
                                                                    cardImageFallbackSrc,
                                                                    cardTitle,
                                                                    cardContent,
                                                                    dialogContent,
                                                                    maxWidth,
                                                                    onDelete
                                                                  }) => {
  const sharedClasses = useSharedStyles();

  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  return <Box component={Grid} {...{item: true}} width={300} display="flex" flexDirection="column" alignItems="center">
    <Dialog open={detailsDialogOpen} fullWidth>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        {onDelete &&
          <Box className={sharedClasses.hspacing2}>
            <Button
              variant="outlined"
              color="red"
              startIcon={<FontAwesomeIcon icon={faTrash}/>}
              onClick={onDelete}
            >Delete</Button>
          </Box>
        }
        <Box className={sharedClasses.hspacing2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faShare}/>}
          ><a href={`${process.env.REACT_APP_GAME_ENDPOINT}/creator/?game=${gameId}`} target="_blank"
              rel="noopener noreferrer">Open Game</a></Button>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setDetailsDialogOpen(false)}
        >Close</Button>
      </DialogActions>
    </Dialog>
    <Paper variant="outlined" style={{width: '100%'}} className={sharedClasses.hoverCursorPointer}
           onClick={() => setDetailsDialogOpen(true)}>
      <Box overflow="hidden" maxHeight={140}>
        <Box display="flex" justifyContent="center">
          <ReactImageFallback
            src={cardImageSrc}
            fallbackImage={cardImageFallbackSrc}
            style={{
              width: '100%',
              maxWidth
            }}
          />
        </Box>
      </Box>
      <Box p={2} textAlign="center">
        <Typography variant="subtitle1">{cardTitle}</Typography>
        <Typography variant="body2">{cardContent}</Typography>
      </Box>
    </Paper>
  </Box>
}

export default StudentWorkGameCard