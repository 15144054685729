import {IStudentSchoolProfile} from "../../types/IStudentSchoolProfile";
import React, {useMemo} from "react";
import {format} from "date-fns";
import useDialogState from "../../hooks/useDialogState";
import DeleteStudentWorkDialog from "../dialogs/student/DeleteStudentWorkDialog";
import StudentWorkCard from "../../pages/classes/StudentCreations/StudentWorkCard";
import endpoints from "../../endpoints";
import {IMazeData} from "../../types/IMazeData";
import MazeDetailsDialog from "../dialogs/student/content/MazeDetailsDialog";


const today = new Date();

const MazeCard: React.VFC<{ maze: IMazeData, student: IStudentSchoolProfile }> = ({student, maze}) => {
  const mazeDetailDialog = useDialogState()
  const deleteDialogState = useDialogState();

  const dateStr = useMemo(() => {
    const date = new Date(maze.created_at);

    return format(date, date.getFullYear() !== today.getFullYear() ? 'MMMM d, yyyy' : 'MMMM d')
  }, [maze.created_at]);


  return <>
    <DeleteStudentWorkDialog
      {...deleteDialogState}
      maze={maze}
      student={student}
    />
    <MazeDetailsDialog
      student={student}
      maze={maze}
      onClose={mazeDetailDialog.onClose}
      onDelete={deleteDialogState.handleOpen}
      open={mazeDetailDialog.open}
    />

    <StudentWorkCard
      cardImageSrc={endpoints.contentImageById(maze.id)}
      cardImageFallbackSrc="/images/student-creations-page/no-maze.png"
      cardContent={dateStr}
      maxWidth={300}
      onClick={mazeDetailDialog.handleOpen}
    />
  </>
}

export default MazeCard