import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { IOrderRequest } from "../IOrderRequest";
import { SubmitOrderPageActions } from "./SubmitOrderPageActions";
import { SubmitOrderTextField } from "./SubmitOrderTextField";
import { useSubmitOrderFormHooks } from "./useSubmitOrderFormHooks";
import endpoints from "endpoints";

export const UploadPurchaseOrder: React.VFC = () => {
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const form = useFormikContext<IOrderRequest>();
  const { allowNext, sharedClasses } = useSubmitOrderFormHooks({
    validateFields: ['purchase_order_number']
  });

  const uploadFile = (file?: File) => {
    if (!file) {
      return;
    }

    setUploading(true);

    const data = new FormData()
    data.append('file', file);

    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/v2/sales/purchase-order`;

    fetch(`${process.env.REACT_APP_API_ENDPOINT}` + endpoints.sales.purchaseOrder, { // todo convert to justFetch 
      method: 'POST',
      body: data
    }).then(res => {
      setUploading(false);
      if (res.ok) {
        setUploadError(false);
        res.json().then(({ file_id }) => {
          form.setFieldValue('purchase_order_file_id', file_id);
          form.setFieldValue('purchase_order_filename', file.name);
        })
      }
    }).catch(() => {
      setUploading(false);
      setUploadError(true);
    });
  }

  return <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2}>
    <Box>
      <Typography variant="subtitle1">Are you purchasing with a purchase order?</Typography>

      {form.values.purchase_order_file_id && `Attached ${form.values.purchase_order_filename!}`}

      <Box display="flex" flexDirection="row" alignItems="center" className={sharedClasses.hspacing2}>
        <Box>
          {!uploading ? <Button
            variant="outlined"
            color="blue"
            {...{
              component: 'label'
            }}
            startIcon={<FontAwesomeIcon icon={faUpload} />}
          >
            {form.values.purchase_order_file_id ? 'Change attachment' : 'Upload'}
            <input
              type="file"
              onChange={e => {
                uploadFile(e.target?.files?.[0]);
              }}
              hidden
            />
          </Button> : <CircularProgress size="2rem" />}
        </Box>
        {form.values.purchase_order_file_id && <Button
          variant="outlined"
          color="red"
          startIcon={<FontAwesomeIcon icon={faTrash} />}
          onClick={() => {
            form.setFieldValue('purchase_order_file_id', undefined);
          }}
        >Remove attachment</Button>}
      </Box>
    </Box>

    {uploadError && 'There was an error uploading this purchase order'}

    {form.values.purchase_order_file_id && <SubmitOrderTextField
      label="Purchase Order Number"
      field="purchase_order_number"
    />}

    <SubmitOrderPageActions
      allowPrev
      allowNext={!uploading && allowNext}
      nextStr={form.values.purchase_order_file_id ? undefined : 'Continue with a different payment method'}
    />
  </Box>;
}