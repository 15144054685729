import React, { useState, useEffect, useRef, ReactNode } from 'react';

interface ActiveNavProps {
  section: string;
  activeSection: string;
  children: ReactNode;
}

export function ActiveNav({ section, activeSection, children }: ActiveNavProps) {
  return (
    <li className={`${activeSection && activeSection.includes(section) ? 'active' : 'inactive'}`}>
      {children}
    </li>
  );
}

export function useOnScreen(ref: React.RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    try {
      const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        { rootMargin: '15px' }
      );

      if (ref.current) {
        observer.observe(ref.current);
      }
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    } catch (error) {}
  }, [ref]);

  return isIntersecting;
}

interface SectionProps {
  children: ReactNode;
  id: string;
  className?: string;
  setActiveSection: (section: string) => void;
  activeSection: string;
}

export function Section({ children, id, className, setActiveSection }: SectionProps) {
  const ref = useRef<HTMLElement>(null);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible) {
      setActiveSection(id);
    }
  }, [isVisible, id, setActiveSection]);

  return (
    <section id={id} ref={ref} className={className}>
      {children}
    </section>
  );
}
