import useCurrentUser from "loaders/useCurrentUser";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const UpgradeSuccessPage = () => {
  const {currentUser} = useCurrentUser();
  const location = useLocation<{ wasAppleSubscriber: boolean }>();

  useEffect(() => {
    cookies.remove('used-parent-connect-id');
    cookies.remove('skip_onboarding');
  });

  const needsOnboarding = currentUser &&
    !currentUser?.temp_password &&
    currentUser.is_registered_via_website &&
    !currentUser.is_dashboard_onboarded &&
    !cookies.get('skip_onboarding');

  const nextHref = needsOnboarding
    ? '/'
    : (!!cookies.get('used-parent-connect-id')
        ? `/parent/students/${cookies.get('used-parent-connect-id')}`
        : `${process.env.REACT_APP_GAME_ENDPOINT}/play?user=n${!!cookies.get('skip_onboarding') ? '&hoc=main' : ''}`
    );

  const nextText = needsOnboarding ? 'Get Started' : (!!cookies.get('used-parent-connect-id') ? 'View Student' : 'Play Now');

  return (
    <React.Fragment>
      <div className="max-w-screen-lg my-8 px-2 mx-auto flex flex-col items-center text-center"
           style={{marginTop: '50vh', transform: 'translateY(-50%)'}}>
        <h1 className="text-4xl mb-8">Order Complete!</h1>
        <h2 className="text-2xl mb-8">Thank you for your purchase. A receipt has been emailed to you.</h2>
        <div className="flex flex-row">
          <img src="/images/fuzzes/happyblue.gif" style={{maxWidth: 300}} alt="Happy blue fuzz jumping up and down"/>
          <div style={{minWidth: '25vw'}} className="flex flex-col justify-center items-center">
            {location.state?.wasAppleSubscriber ? <div>
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-8"
                   role="alert">
                <strong className="font-bold">You have an active iTunes subscription.&nbsp;</strong>
                <span className="block sm:inline">Make sure to <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.apple.com/en-us/HT202039"
                  className="text-kodable-blue">
                                cancel your subscription in iTunes
                            </a>
                  &nbsp;to avoid being billed again.
                            </span>
              </div>
            </div> : null
            }
            <a
              href={nextHref}
              className="text-2xl relative bg-kodable-green text-white px-12 py-6 rounded hover:bg-kodable-green-dark active:bg-kodable-green-darker"
            >{nextText}</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UpgradeSuccessPage