import { Box, List, ListItem, TextField, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import React, { useState } from "react";
import { ITeacherData } from "types/ITeacherData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import ParentInvites, { TrackParentInviteMethod, TrackParentInviteMethodLocation } from "utils/ParentInvites";
import { useAlert } from "context/AlertProvider";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

const InviteParentsListItem: React.VFC<{text: string}> = ({text}) => {
    return (
      <ListItem style={{display: 'flex', alignItems: 'center', padding: '8px 16px'}}>
        <img
            style={{ width: '24px', marginRight: '.5rem'}}
            width="24"
            height="22"
            src="/images/onboarding/heart-key.png"
        />
        <Typography variant="body1">{text}</Typography>
      </ListItem>
    )
}

const ParentInviteEmailForm: React.FC<{
        teacherData: ITeacherData,
        klassID: number | null,
        handleSkip?: (...args: any[]) => any,
        location?: TrackParentInviteMethodLocation
    }> = ({
        teacherData,
        klassID,
        handleSkip = undefined,
        location
    }) => {
    const sharedClasses = useSharedStyles();
    const [parentEmails, setParentEmails] = useState<string>('')
    const [submitting, setSubmitting] = useState<boolean>(false)
    const alert = useAlert();
    const history = useHistory()

    const handleParentEmailsSubmit = () => {
        if (submitting) {
            return
        }
        setSubmitting(true)
        
        if (!klassID) {
            return
        }
        
        const currentClass = teacherData?.klasses?.find((klass) => klass.id === klassID)
        if (!currentClass) {
            return
        }

        ParentInvites.track(
            currentClass,
            TrackParentInviteMethod.email,
            location,
            [],
            parentEmails.split(/\r?\n/)
        ).then((res) => {
            if (res?.status === 201) {
                alert.success('Invites sent successfully')
                setParentEmails('')
                setSubmitting(false)
                if (location === TrackParentInviteMethodLocation.onboarding) {
                    history.push(routes.class.index(klassID))
                }
                return
            }
            if (res?.status === 500) {
                alert.error('Invites could not be sent', 'Please try again later')
            }
            if (res?.status === 404) {
                alert.error('Could not find class', 'Please try again later')
            }
            if (res?.status === 400) {
                alert.error('Invite method invalid', 'Please try again later')
            }
            setSubmitting(false)
        }).catch((error) => {
            alert.error('Invites could not be sent', 'Please try again later')
            setSubmitting(false)
        })
    }

    return (
        <>
        <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" flexGrow={1}>
            {(typeof handleSkip !== 'undefined') &&
                <Button
                    onClick={handleSkip}
                    disabled={submitting}
                    style={{color: '#27a9e1', position: 'absolute', top: '.5rem', right: '.5rem'}}
                    color="kodableGrey"
                    size="small"
                >
                    Skip for now
                </Button>
            }
            <Typography variant="body1" align="center">
                <>Send invites to families to unlock free levels and progress updates at Home!</>
            </Typography>
            <img
                src="/images/onboarding/home.png"
                style={{ height: '162px', margin: '0 auto', width: 'auto'}}
                height="162"
                width="120px"
            />
            <List style={{padding: '0'}}>
                <InviteParentsListItem text="Enter parent or guardian email address below" />
                <InviteParentsListItem text="Families accept the invite for their student" />
                <InviteParentsListItem text="Students receive the key to unlock Home levels" />
            </List>
            <TextField
                multiline
                minRows={4}
                placeholder="Enter one parent email per line"
                variant="outlined"
                value={parentEmails}
                onChange={e => setParentEmails(e.target.value)}
                style={{ background: 'white'}}
                disabled={submitting}
            />
            <Box display="flex" justifyContent="flex-end">
                <Button
                    startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                    onClick={handleParentEmailsSubmit}
                    disabled={submitting}
                    color="blue"
                    size="small"
                    variant="contained"
                >
                    Invite
                </Button>
            </Box>
        </Box>
        </>
    )
}

export default ParentInviteEmailForm