import SectionContainer from "./SectionContainer";
import React from "react";


const Objectives: React.FC<{objectives: string}> = ({objectives}) => {
  return (
    <SectionContainer
      title={'Objectives'}
      icon={'/images/curriculum_images/Heart.png'}
      iconColor={'#800080'}
      innerHtml={objectives}
    />
  )
}

export default Objectives