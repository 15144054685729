import React, { Fragment } from 'react';
import TopNav from '../shared/TopNav';
import { Link } from 'react-router-dom';

const AsteroidiaLandingPage: React.FC = () => {
  return (
    <Fragment>
      <TopNav
        title="Asteroidia"
        navTitle="Asteroidia"
        guideImage="/images/teacher-guides/asteroidia/asteroidiaThumb.jpg"
        navLinks={[
          { title: 'Learning Tools', to: '/learning-tools' },
          { title: 'Asteroidia', to: '/learning-tools/asteroidia' },
        ]}
      />
      <div className="flex flex-col justify-center items-center w-full">
        <div className="my-8 w-full max-w-screen-lg px-2">
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="w-full lg:max-w-xs">
              <img
                width="320"
                height="233"
                className="w-full lg:max-w-xs mb-4"
                src="/images/curriculum_images/kodable_art_asteroidia_smaller.png"
                alt="Smeeborg"
              />
            </div>
            <div className="flex-1 lg:px-6">
              <div className="bg-kodable-purple px-4 py-3 text-white font-bold">
                Resources
              </div>
              <div className="grid grid-cols-2 xl:grid-cols-3 gap-4 py-4 px-4">
                <Link
                  to="/parent/learning-tools/guides/asteroidia"
                  className="bg-kodable-purple text-white text-center transition duration-500 transform hover:scale-110 font-bold flex flex-col justify-center items-center py-2 shadow-md rounded">
                  <svg
                    aria-hidden="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    className="w-12 h-12">
                    <path
                      fill="currentColor"
                      d="M369.941 97.941l-83.882-83.882A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48V131.882a48 48 0 0 0-14.059-33.941zm-22.627 22.628a15.89 15.89 0 0 1 4.195 7.431H256V32.491a15.88 15.88 0 0 1 7.431 4.195l83.883 83.883zM336 480H48c-8.837 0-16-7.163-16-16V48c0-8.837 7.163-16 16-16h176v104c0 13.255 10.745 24 24 24h104v304c0 8.837-7.163 16-16 16zm-161.471-67.404l-25.928-7.527a5.1 5.1 0 0 1-3.476-6.32l58.027-199.869a5.1 5.1 0 0 1 6.32-3.476l25.927 7.527a5.1 5.1 0 0 1 3.476 6.32L180.849 409.12a5.1 5.1 0 0 1-6.32 3.476zm-48.446-47.674l18.492-19.724a5.101 5.101 0 0 0-.351-7.317L105.725 304l38.498-33.881a5.1 5.1 0 0 0 .351-7.317l-18.492-19.724a5.1 5.1 0 0 0-7.209-.233L57.61 300.279a5.1 5.1 0 0 0 0 7.441l61.263 57.434a5.1 5.1 0 0 0 7.21-.232zm139.043.232l61.262-57.434a5.1 5.1 0 0 0 0-7.441l-61.262-57.434a5.1 5.1 0 0 0-7.209.233l-18.492 19.724a5.101 5.101 0 0 0 .351 7.317L278.275 304l-38.499 33.881a5.1 5.1 0 0 0-.351 7.317l18.492 19.724a5.1 5.1 0 0 0 7.209.232z"></path>
                  </svg>
                  Parent Guide
                </Link>
                <Link
                  to="/parent/learning-tools/creative-projects/asteroidia"
                  className="bg-kodable-purple text-white text-center transition duration-500 transform hover:scale-110 font-bold flex flex-col justify-center items-center py-2 shadow-md rounded">
                  <svg
                    aria-hidden="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                    className="w-12 h-12">
                    <path
                      fill="currentColor"
                      d="M248 104c-84.02 0-152 68-152 152 0 84.02 68 152 152 152 84.02 0 152-68 152-152 0-84.02-68-152-152-152zm0 256c-57.35 0-104-46.65-104-104s46.65-104 104-104 104 46.65 104 104-46.65 104-104 104zm0-352C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 448c-110.28 0-200-89.72-200-200S137.72 56 248 56s200 89.72 200 200-89.72 200-200 200zm0-256c-30.88 0-56 25.12-56 56s25.12 56 56 56 56-25.12 56-56-25.12-56-56-56z"></path>
                  </svg>
                  Creative Projects
                </Link>
                <Link
                  to="/parent/learning-tools/answer-keys/asteroidia"
                  className="bg-kodable-purple text-white text-center transition duration-500 transform hover:scale-110 font-bold flex flex-col justify-center items-center py-2 shadow-md rounded">
                  <svg
                    aria-hidden="true"
                    className="w-12 h-12"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path
                      fill="currentColor"
                      d="M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z"></path>
                  </svg>
                  Answer Keys
                </Link>
                <Link
                  to="/parent/learning-tools/vocabulary/asteroidia"
                  className="bg-kodable-purple text-white text-center transition duration-500 transform hover:scale-110 font-bold flex flex-col justify-center items-center py-2 shadow-md rounded">
                  <svg
                    aria-hidden="true"
                    className="w-12 h-12"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path
                      fill="currentColor"
                      d="M128 184c0-30.879 25.122-56 56-56h136V56c0-13.255-10.745-24-24-24h-80.61C204.306 12.89 183.637 0 160 0s-44.306 12.89-55.39 32H24C10.745 32 0 42.745 0 56v336c0 13.255 10.745 24 24 24h104V184zm32-144c13.255 0 24 10.745 24 24s-10.745 24-24 24-24-10.745-24-24 10.745-24 24-24zm184 248h104v200c0 13.255-10.745 24-24 24H184c-13.255 0-24-10.745-24-24V184c0-13.255 10.745-24 24-24h136v104c0 13.2 10.8 24 24 24zm104-38.059V256h-96v-96h6.059a24 24 0 0 1 16.97 7.029l65.941 65.941a24.002 24.002 0 0 1 7.03 16.971z"></path>
                  </svg>
                  Vocabulary
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AsteroidiaLandingPage;
