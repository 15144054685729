import React, { createContext, useContext, useState, useEffect } from 'react';
import useSWR from 'swr';
import { ICleverDistrictResponse } from 'pages/godmode/Organizations/CleverDistricts/CleverDistrictsIndex';
import endpoints from 'endpoints';

// Define the context's value type
interface ICleverDistrictNeedsDisconnectRenewedContext {
  needs_disconnect: ICleverDistrictResponse[];
  renewed: ICleverDistrictResponse[];
  mutate: () => Promise<any>; // Adjust the return type of mutate as needed
  loading: boolean;
  error: any;
}

// Create the context
const CleverDistrictRenewedNeedsDisconnectContext = createContext<ICleverDistrictNeedsDisconnectRenewedContext | undefined>(undefined);

// Context provider component
export const CleverDistrictRenewedNeedsDisconnectProvider: React.FC = ({ children }) => {
  const { data, error, mutate } = useSWR(endpoints.godmode.salesCleverDistrictsRenewedNeedsDisconnect)
  const [loading, setLoading] = useState<boolean>(true)
  const [needs_disconnect, setNeedsDisconnect] = useState<ICleverDistrictResponse[]>([])
  const [renewed, setRenewed] = useState<ICleverDistrictResponse[]>([])

  useEffect(() => {
    if (data) {
        setNeedsDisconnect(data.needs_disconnect || [])
        setRenewed(data.renewed || [])
        setLoading(false)
    }
  }, [data]);

  return (
    <CleverDistrictRenewedNeedsDisconnectContext.Provider value={{ needs_disconnect, renewed, mutate, loading, error }}>
      {children}
    </CleverDistrictRenewedNeedsDisconnectContext.Provider>
  );
};

// Hook to use the context
export const useCleverDistrictRenewedNeedsDisconnect = (): ICleverDistrictNeedsDisconnectRenewedContext => {
  const context = useContext(CleverDistrictRenewedNeedsDisconnectContext)
  if (context === undefined) {
    throw new Error('useCleverDistrictRenewedNeedsDisconnect must be used within a CleverDistrictRenewedNeedsDisconnectProvider');
  }
  return context;
};
