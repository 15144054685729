import {Box, Link, Paper, Typography} from "@material-ui/core"
import useSharedStyles from "components/useSharedStyles";
import { IGodmodeUserProfile } from "../IGodmodeUserProfile";
import React from "react";

export const AmbassadorDetailsBlock: React.FC<{ teacher: IGodmodeUserProfile }> = ({ teacher }) => {
    const sharedClasses = useSharedStyles();
    const referralLink = `${process.env.REACT_APP_KODABLE_WEBSITE}/teachers?referral_code=${teacher.referrals.referral_code}`;

    return <Paper variant="outlined">
        <Box p={2} display="flex" flexDirection="column" alignItems="center" className={sharedClasses.vspacing2}>
            <Typography variant="h5">Ambassador Details</Typography>
            <Box display="flex" flexDirection="row" justifyContent="center">
                <Box display="flex" flexDirection="row" alignItems="center" ml={2}>
                    <Typography variant="subtitle1">Referral Link:</Typography>&nbsp;
                    <Link target="_blank" rel="noopener noreferrer" href={referralLink}>
                        {referralLink}
                    </Link>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center">
                <Box display="flex" flexDirection="row" alignItems="center" mr={2}>
                    <Typography variant="subtitle1">Referral Code:</Typography>&nbsp;
                    <Typography variant="body2">{teacher.referrals.referral_code}</Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center" ml={2}>
                    <Typography variant="subtitle1">Referrals:</Typography>&nbsp;
                    <Typography variant="body2">{teacher.referrals.referees.length}</Typography>
                </Box>
            </Box>
        </Box>
    </Paper>;
}