import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "./Button";
import {faAt} from "@fortawesome/free-solid-svg-icons/faAt";
import {Link} from "@material-ui/core";
import React from "react";

const EmailShareButton: React.VFC<{subject: string, body: string}> = ({subject, body}) => {
  const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`

  return (
    <Link component={Button}
          href={mailtoUrl}
          underline="none"
          style={{backgroundColor: '#0a88ff', color: "white", fontSize: 12, paddingLeft: 15, paddingRight: 15, width: 125}}
          startIcon={<FontAwesomeIcon icon={faAt} color={'white'}/>}
    >
      EMAIL
    </Link>
  )
}

export default EmailShareButton