import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import React, { useState } from "react";
import { SubmitOrderPageActions } from "./SubmitOrderPageActions";

export const ConfigurePayment: React.VFC = () => {
  const sharedClasses = useSharedStyles();
  const [paymentMethod, setPaymentMethod] = useState<'bank' | 'cc' | 'po'>();

  return <>
    <Box className={sharedClasses.vspacing2}>
      <Typography variant="h2" align="center">Select Your Payment Method</Typography>
      <RadioGroup
        onChange={(e) =>
          setPaymentMethod(e.target.value as any)
        }
        value={paymentMethod}
      >
        <FormControlLabel
          value="po"
          control={<Radio />}
          label="Purchase Order"
        />
        <FormControlLabel
          value="bank"
          control={<Radio />}
          label="Check or Bank Transfer"
        />
        <FormControlLabel
          value="cc"
          control={<Radio />}
          label="Credit Card"
        />
      </RadioGroup>
    </Box>
    <SubmitOrderPageActions allowPrev allowNext={!!paymentMethod} />
  </>
}