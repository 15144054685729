import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import useSharedStyles from "components/useSharedStyles";
import {Box, Typography} from "@material-ui/core";
import React from "react";

type RevenueBarTopBarItemIconTextProps = {
  icon: FontAwesomeIconProps['icon'],
  text: string
}

const RevenueScoreCardIcon: React.FC<RevenueBarTopBarItemIconTextProps> = ({
                                                                                            icon,
                                                                                            text
                                                                                          }) => {
  const sharedClasses = useSharedStyles()
  return (
    <Box className={sharedClasses.hspacing1} display="flex" alignItems="center">
      <FontAwesomeIcon icon={icon}/>
      <Typography variant="body1">{text}</Typography>
    </Box>
  )
}

export default RevenueScoreCardIcon