import {Box, Dialog, DialogActions, DialogTitle, Typography, useTheme} from "@material-ui/core";
import Button from "../ui/buttons/Button";
import React from "react";
import {useHistory} from "react-router-dom";
import {routes} from "../../routes";
import {accountTrackedDate} from "../../pages/godmode/utils/time";

const SubscriptionExpiringSoonModal: React.VFC<{open: boolean, onClose: () => void, daysToExpire: number, expirationDate: string}> = ({expirationDate, open, onClose, daysToExpire}) => {
  const theme = useTheme()
  const history = useHistory()

  const DogEar: React.VFC<{imageUrl: string, color: string}> = ({imageUrl, color}) => (
    <div style={{
      width: 150,
      height: 150,
      backgroundColor: color,
      position: "relative",
      float: 'right',
      top: -75,
      left: 550,
      transform: 'rotate(45deg)',
    }}>
      <div style={{
        transform: 'rotate(-45deg)'
      }}>
        <img src={imageUrl}
             style={{
               position: "relative",
               width: 35,
               height: 35,
               top: 65,
               left: -30
             }} />
      </div>
    </div>
  )

  const Benefit: React.VFC<{image: string, subtext: string}> = ({image, subtext}) => (
    <Box width={150}>
      <img src={image} style={{margin: "auto", width: 80, paddingBottom: 15}} />
      <Typography>{subtext}</Typography>
    </Box>
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <DogEar imageUrl={'/images/badges/premium-badge-100.png'} color={theme.palette.blue.main} />
      <DialogTitle style={{
        position: "relative",
        top: -120,
      }}>
        <Typography style={{
          fontFamily: 'Bjola',
          fontWeight: 400,
          fontSize: 28,
          lineHeight: '1rem'
        }}>
          Your Subscription Expires in {daysToExpire} Days
        </Typography>
      </DialogTitle>
      <Box display={'flex'} flexDirection={'column'} textAlign={'center'} m={5} style={{height: 170, position: 'relative', top: -150}}>
        <Typography>Renew by {accountTrackedDate(expirationDate)} to keep access to:</Typography>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} marginTop={5}>
          <Benefit image={'/images/paywall/meeting_room.png'} subtext={'Co-teachers'} />
          <Benefit image={'/images/paywall/customer_review.png'} subtext={'Pro home access'} />
          <Benefit image={'/images/paywall/internet.png'} subtext={'Usage reports'} />
          <Benefit image={'/images/paywall/purposefulness.png'} subtext={'Full game access'} />
        </Box>
        <Box display={'flex'} justifyContent={'center'} alignContent={'center'} style={{marginTop: 60}}>
          <Button variant={"contained"}
                  color={'blue'}
                  onClick={() => history.push(routes.settings.billing)}
                  style={{width: 200, height: 70, borderRadius: 10, fontSize: 22, fontWeight: 500}}
          >Renew now</Button>
        </Box>
        <a href={'https://calendly.com/teachers-kodable/meet-with-your-kodable-account-manager'} target={'_blank'} style={{marginTop: 35, textDecoration: 'underline', color: 'blue'}} rel="noreferrer">Talk to my account manager</a>
      </Box>
      <DialogActions>
        <Button variant={"outlined"} onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubscriptionExpiringSoonModal