import {
  SalesCityStateSelection,
  SalesSchoolSelectionAutocomplete,
  SalesZipSelection
} from "../SalesSchoolSelection";
import PageContainer from "../../../components/ui/PageContainer";
import {useOrganizationSelection} from "../../../context/OrganizationSelectionProvider";
import useSharedStyles from "../../../components/useSharedStyles";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Box, Collapse, FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import Button from "../../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

const FindSchoolPage = () => {
  const { zipCode, setZipCode, city, setCity, state, setState, setSiteType } = useOrganizationSelection()
  const sharedClasses = useSharedStyles()
  const [searchOption, setSearchOption] = useState<'zipcode' | 'citystate'>()
  const history = useHistory()

  useEffect(() => {
    setZipCode(null)
    setCity(null)
    setState(null)
  }, [searchOption, setCity, setState, setZipCode])

  return (
    <PageContainer variant="centered">
      <Box className={sharedClasses?.vspacing2}>
        <Typography variant="h1" align="center">Find your School</Typography>
        <Typography variant="body1" align="center">Please enter your location information to find your school</Typography>
        <RadioGroup
          id="searchOption"
          onChange={(e) =>
            setSearchOption(e.target.value as 'zipcode' | 'citystate')
          }
          value={searchOption}
        >
          <Box className={sharedClasses?.vspacing2}>
            <Box display="flex" flexDirection="column">
              <FormControlLabel
                value="zipcode"
                control={<Radio />}
                label={<Typography style={{fontWeight: searchOption === 'zipcode' ? 'bold' : 'normal'}}>Search by Zip Code</Typography>}
              />
              <FormControlLabel
                value="citystate"
                control={<Radio />}
                label={<Typography style={{fontWeight: searchOption === 'citystate' ? 'bold' : 'normal'}}>Search by City and State</Typography>}
              />
            </Box>
            <Box>
              <Collapse in={searchOption === "zipcode"}>
                <SalesZipSelection />
              </Collapse>
              <Collapse in={searchOption === "citystate"}>
                <SalesCityStateSelection />
              </Collapse>
            </Box>
          </Box>
        </RadioGroup>
        {((zipCode && zipCode.toString().length === 5) || (city && state)) &&
          <SalesSchoolSelectionAutocomplete isOrder={false}/>
        }
        <Box width="100%" display="flex" alignItems="center" justifyContent="flex-start">
          <Button
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            variant="outlined"
            color="primary"
            onClick={() => {
              setSiteType(null)
              history.goBack()
            }}
          >
            Back
          </Button>
        </Box>
      </Box>
    </PageContainer>
  )
}

export default FindSchoolPage