import { useMemo } from "react";
import { getOrderedCoursesBySection, orderedSections } from "pages/courses/courseSections";
import { ICourseData, IGalaxyCourse } from "pages/courses/ICourseData";

const useOrderedCourses = (courseData?: ICourseData[], courseAssignments?: number[]) => {
  return useMemo(() => {
    const orderedCoursesBySection = getOrderedCoursesBySection(courseData);

    return orderedSections
      .reduce((acc: ICourseData[], section) => {
        return acc.concat(orderedCoursesBySection[section] || []);
      }, [])
      .filter(({ id }) => (courseAssignments && courseAssignments?.includes(id)) || !courseAssignments)
      .map(course => ({
        ...course,
        galaxyImage: (courseData?.find(({ id }) => id === 1) as IGalaxyCourse)?.configuration.planetNodes.find(planetNode => planetNode.courseID === course.id)?.image
      }));
  }, [orderedSections, courseData, courseAssignments]);
}

export default useOrderedCourses;