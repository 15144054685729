import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

//Routes
import LearningPage from './LearningPage';
import WorksheetsPage from './WorksheetsPage';
import GuidesIndexPage from 'pages/learning/guides/GuidesIndexPage';
import AnswerKeysIndexPage from 'pages/learning/answer-keys/AnswerKeysIndexPage';
import VocabularyIndexPage from 'pages/learning/vocabulary/VocabularyIndexPage';
import CreativeProjectsIndexPage from 'pages/learning/creative-projects/CreativeProjectsIndexPage';
import LearningPathPage from './LearningPathPage';
import GetToKnowPage from './GetToKnowPage';
import WhatIsCodingPage from './WhatIsCodingPage';
import SmeeborgLandingPage from 'pages/learning/landing-pages/SmeeborgLandingPage';
import AsteroidiaLandingPage from 'pages/learning/landing-pages/AsteroidiaLandingPage';
import BugWorldLandingPage from 'pages/learning/landing-pages/BugWorldLandingPage';

export default function LearningIndexPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={LearningPage} exact path={path} />
      <Route component={WorksheetsPage} exact path={`${path}/worksheets`} />
      <Route component={GuidesIndexPage} path={`${path}/guides`} />
      <Route component={AnswerKeysIndexPage} path={`${path}/answer-keys`} />
      <Route component={VocabularyIndexPage} path={`${path}/vocabulary`} />
      <Route component={SmeeborgLandingPage} path={`${path}/smeeborg`} />
      <Route component={AsteroidiaLandingPage} path={`${path}/asteroidia`} />
      <Route component={BugWorldLandingPage} path={`${path}/bugworld`} />
      <Route
        component={CreativeProjectsIndexPage}
        path={`${path}/creative-projects`}
      />
      <Route
        component={LearningPathPage}
        exact
        path={`${path}/learning-path`}
      />
      <Route
        component={GetToKnowPage}
        exact
        path={`${path}/get-to-know-kodable`}
      />
      <Route
        component={WhatIsCodingPage}
        exact
        path={`${path}/what-is-coding`}
      />
      <Redirect to={path} />
    </Switch>
  );
}
