import React, { useRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import usePortal from 'hooks/usePortal';
import { motion, AnimatePresence } from 'framer-motion';
import useOnClickOutside from 'hooks/useOnClickOutside';

interface PortalProps {
  children: ReactNode;
}

function Portal({ children }: PortalProps) {
  const target = usePortal();
  return createPortal(children, target);
}

export interface ModalProps {
  children: ReactNode;
  closeModal: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, closeModal }) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, closeModal);

  return (
    <Portal>
      <div
        style={{ zIndex: 2147483004 }}
        className="fixed inset-0 overflow-y-hidden box-border h-full">
        <div className="flex items-start justify-center min-h-screen px-4 mx-auto text-center sm:p-0 max-w-3xl">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-800 opacity-25"></div>
          </div>
          <AnimatePresence>
            <motion.div
              className="w-full"
              initial={{ opacity: 0, scale: 0.35 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.25, delay: 0.1 }}
              exit={{ opacity: 0, scale: 0 }}>
              <div className="relative w-full pt-8 lg:pt-24 lg:pb-8 h-screen px-2 lg:max-w-xl mx-auto">
                <div className="h-full overflow-y-auto rounded-lg">
                  <div
                    ref={ref}
                    className="align-bottom bg-white text-left rounded-lg shadow-xl w-full transform transition-all"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    {children}
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
