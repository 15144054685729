import React, { Fragment, useEffect } from 'react';
import TopNav from './shared/TopNav';
import WelcomeSection from './shared/WelcomeSection';
import WhatIsCodingSection from './shared/WhatIsCodingSection';
import LearningPathSection from './shared/LearningPathSection';
import { useTracking } from 'context/TrackingProvider';

const GetToKnowPage: React.FC = () => {
  const { track } = useTracking();

  useEffect(() => {
    track('Viewed Parent Dashboard', { Section: 'Get To Know Kodable' });
  }, [track]);

  return (
    <Fragment>
      <TopNav
        title="Welcome to Fuzztopia"
        backLink="/learning-tools"
        navLinks={[
          { title: 'Learning Tools', to: '/learning-tools' },
          { title: 'Welcome to Fuzztopia', to: '/learning-tools/get-to-know-kodable' },
        ]}
      />
      <div className="mx-4">
        <div className="relative mt-10 mx-auto max-w-screen-lg pb-32">
          <WelcomeSection />
          <LearningPathSection />
          <WhatIsCodingSection />
        </div>
      </div>
    </Fragment>
  );
}

export default GetToKnowPage;
