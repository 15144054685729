import {ConfirmationDialog} from "components/dialogs/base/ConfirmationDialog";
import {justFetch} from "mutations/mutate";
import endpoints from "endpoints";
import React, {useState} from "react";

type RefundDowngradeDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  teacherId: number
  lastChargeId: number | undefined
}

const RefundDowngradeDialog: React.VFC<RefundDowngradeDialogProps> =
  ({open, onClose, onSuccess, teacherId, lastChargeId}) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  const updateSubscription = async () => {
    setSubmitting(true);
    const res = await justFetch(endpoints.refundLastStripeCharge(teacherId), 'POST', {
      stripe_charge_id: lastChargeId
    });
    setSubmitting(false);

    if (res.ok) {
      onSuccess();
    } else {
      const body = await res.json();
      setError(body.error || body.message || 'An unknown error occurred.');
    }
  }

  return (
    <ConfirmationDialog
      open={open}
      title="Refund and downgrade?"
      subtitle="This will refund and downgrade the user immediately."
      verb="Refund and downgrade"
      onClose={onClose}
      onConfirm={updateSubscription}
      danger
      submitting={submitting}
      error={error}
    />
  )
}

export default RefundDowngradeDialog;
