import {Box, Typography} from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import React, { useMemo, useState } from "react";
import ReactImageFallback from "react-image-fallback";
import useSWR from "swr";
import {ICourseData, ILessonNode, ILevelData, IMissionNode} from "../ICourseData";
import LevelSolutionModal from "../../../components/modal/LevelSolutionModal";
import {ExpandLess, ExpandMore} from "@material-ui/icons";


const MissionGameContent: React.VFC<{ course: ICourseData, mission: IMissionNode }> = ({ course, mission }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return <Box pt={2}>

    {course.meta.unlimitedLevels && <Typography variant="subtitle1" paragraph>Unlimited Levels</Typography>}
    {mission.meta?.dashboardDescription && <Box maxWidth={600}>
        <Typography>{mission.meta.dashboardDescription}</Typography>
    </Box>}
    <Box>
      {!course.meta.hideAnswerKeys && <>
      <Box display="flex" flexDirection="row" style={{gap:"5"}}>
        <Typography variant="subtitle1" paragraph>Answer Key</Typography>
        {isOpen ? (<ExpandLess style={{marginTop:2}} onClick={() => setIsOpen(false)}/>) : (<ExpandMore style={{marginTop:2}} onClick={() => setIsOpen(true)}/>)}
      </Box>
      {isOpen && (mission.nodes?.length || 0) > 0 && mission.nodes.map((lessonID, i) => {
        const lesson = course.configuration.lessonNodes?.find(({ nodeID }) => nodeID === lessonID);
        return <>{lesson && <LessonGameContent key={i} lesson={lesson} name={i == mission.nodes!.length - 1 ? "Assessment": `Lesson ${i+1}`}/>}</>
      })}
      </>}
    </Box>
  </Box>
}
const LessonGameContent: React.VFC<{ lesson: ILessonNode, name: string }> = ({ lesson, name }) => {
    const { data: levels } = useSWR<ILevelData[]>(endpoints.allBasicsLevels);
  
    const lessonLevels = useMemo(() => {
      return lesson.levels?.map(levelId => levels?.find(({ id }) => id === levelId)!).filter(Boolean)
    }, [lesson, levels]);
  
    const [showLevelSolutionModalForLevel, setShowLevelSolutionModalForLevel] = useState<ILevelData | undefined>();
    const sharedClasses = useSharedStyles();
  
    return <Box pt={2}>
      <LevelSolutionModal open={!!showLevelSolutionModalForLevel} level={showLevelSolutionModalForLevel} onClose={() => setShowLevelSolutionModalForLevel(undefined)} />
      {(lessonLevels?.length || 0) > 0 && <Box>
        <Typography variant="subtitle2" paragraph>{name}</Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between" maxWidth={(lessonLevels?.length || 0) * 200}>
          {lessonLevels?.map(level => {
            return <Box key={level.id} display="flex" mx={1} flexDirection="column" alignItems="center" onClick={() => setShowLevelSolutionModalForLevel(level)} className={sharedClasses.hoverCursorPointer}>
            <ReactImageFallback
              style={{ maxHeight: 128 }}
              src={level?.thumbnail_url || undefined}
              fallbackImage="/images/student-creations-page/no-maze.png"
            />
              <Typography variant="body2">{level.id}</Typography>
          </Box>})}
        </Box>
      </Box>}
    </Box>
}

export default MissionGameContent;