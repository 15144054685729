import React, { useEffect, useRef, useState } from "react"
import { Box, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core"
import { useCleverDistrictRenewedNeedsDisconnect } from "context/CleverDistrictNeedsDisconnectRenewedProvider"
import { ICleverDistrictResponse } from "../Organizations/CleverDistricts/CleverDistrictsIndex"
import useDialogState, { DialogStateProps } from "hooks/useDialogState"
import endpoints from "endpoints"
import { justFetch } from "mutations/mutate"
import { useAlert } from "context/AlertProvider"
import Button from "components/ui/buttons/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faCopy, faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useSharedStyles from "components/useSharedStyles"

const SalesCleverActionsQueue: React.FC = () => {
    return (
        <Box>
            <SalesCleverNeedsDisconnectQueue />
            <SalesCleverRenewedSubscriptionsQueue />
        </Box>
    )
}

const SalesCleverNeedsDisconnectQueue: React.FC = () => {
    const { needs_disconnect, loading } = useCleverDistrictRenewedNeedsDisconnect()

    return (
        <Box mb={(!loading && needs_disconnect?.length > 0) ? 6 : 0}>
            <Typography variant="h6"><strong>Need Disconnect</strong></Typography>
            {loading &&
                <Box display="flex" alignItems="center" justifyContent="center" p={4}>
                    <CircularProgress />
                </Box>
            }
            {!loading && needs_disconnect?.length === 0 &&
                <Box display="flex" alignItems="center" justifyContent="center" p={4}>
                    <Typography variant="body1">No needs_disconnect subscriptions.</Typography>
                </Box>
            }
            {needs_disconnect?.length > 0 &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Subscription ID</TableCell>
                                <TableCell>Organization Name</TableCell>
                                <TableCell>Expiration Date</TableCell>
                                <TableCell>Reason</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {needs_disconnect.map((district, index) => {
                                return <SalesCleverNeedsDisconnectRow district={district} key={index} />
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>
    )
}

const SalesCleverNeedsDisconnectRow: React.FC<{ district: ICleverDistrictResponse }> = ({ district }) => {
    const dialogState = useDialogState()

    return (
        <TableRow>
            <TableCell>{district?.subscription_id}</TableCell>
            <TableCell>{district?.subscription_school_name}</TableCell>
            <TableCell>{district?.subscription_end}</TableCell>
            <TableCell>{district?.subscription_has_curriculum ? 'Churned' : 'Sub Tier Downgrade'}</TableCell>
            <TableCell>
                <SalesCleverNeedsDisconnectDialog district={district} dialogState={dialogState}/>
                <Button
                    onClick={() => dialogState.handleOpen()}
                    color="red"
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                >
                    Delete
                </Button>
            </TableCell>
        </TableRow>
    )
}

const SalesCleverNeedsDisconnectDialog: React.FC<{ district: ICleverDistrictResponse, dialogState: DialogStateProps }> = ({ district, dialogState }) => {
    const sharedClasses = useSharedStyles()
    const alert = useAlert()
    const { mutate } = useCleverDistrictRenewedNeedsDisconnect()
    const [stepOne, setStepOne] = useState(false)
    const [stepTwo, setStepTwo] = useState(false)
    const [stepThree, setStepThree] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const dialogRef = useRef<HTMLDivElement>(null)

    const handleStepOne = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStepOne(event?.target?.checked)
    }

    const handleStepTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStepTwo(event?.target?.checked)
    }

    const handleStepThree = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStepThree(event?.target?.checked)
    }

    const handleDelete = async () => {
        if (submitting) {
            return
        }
        setSubmitting(true)

        justFetch(endpoints.cleverDistrictDelete(district?.clever_id), 'DELETE')
        .then((res) => {
            if (res.ok) {
                mutate()
                dialogState.onClose()
                alert.success('Clever District successfully deleted!')
                setSubmitting(false)
            } else {
                throw new Error('Unable to delete district')
            }
        })
        .catch((error) => {
            console.log(error)
            alert.error('Unable to delete district', error)
            setSubmitting(false)
        })
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                dialogState.onClose()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dialogState])

    return (
        <Dialog open={dialogState?.open}>
            <div ref={dialogRef}>
                <DialogTitle>Disconnect Subscription from Clever</DialogTitle>
                <DialogContent className={sharedClasses?.vspacing2}>
                    <Typography align="center">Follow these 3 steps to disconnect your subscription from Clever. Check the box when each step is complete</Typography>
                    <FormGroup className={sharedClasses?.vspacing2}>
                        <Typography variant="h6" align="center">Steps in Clever</Typography>
                        <FormControlLabel 
                            control={
                                <Checkbox value={stepOne} onChange={handleStepOne} />
                            }
                            label={
                                <Typography><strong>Step 1: </strong><a style={{color: '#27a9e1'}} href={`https://apps.clever.com/kodable/district/${district?.clever_id}/overview`} target="_blank" rel="noreferrer">{`[Click Here]`}</a> to view the customer's overview page in Clever</Typography>
                            }
                        />
                        <FormControlLabel
                            control={<Checkbox value={stepTwo} onChange={handleStepTwo} />}
                            label={
                                <Typography><strong>Step 2: </strong>Click the <Button variant="outlined" color="purple">Disconnect</Button> button on the Clever page</Typography>
                            }
                        />
                        <Divider />
                        <Typography variant="h6" align="center">Steps in Godmode</Typography>
                        <FormControlLabel
                            control={<Checkbox value={stepThree} onChange={handleStepThree} />}
                            label={
                                <Typography><strong>Step 3: </strong>Check this box to confirm you'd like to remove this Clever District from our system, and click "Confirm" below</Typography>
                            }
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{width: "100%"}}>
                        <Button
                            variant="contained"
                            color="red"
                            onClick={handleDelete}
                            disabled={!stepOne || !stepTwo || !stepThree || submitting}
                        >
                            Confirm Delete
                        </Button>
                        <Typography>I confirm that I have completed all 3 steps listed above</Typography>
                    </Box>
                </DialogActions>
            </div>
        </Dialog>
    )
}

const SalesCleverRenewedSubscriptionsQueue: React.FC = () => {
    const { renewed, loading } = useCleverDistrictRenewedNeedsDisconnect()

    return (
        <Box>
            <Typography variant="h6"><strong>Renewed Subscriptions</strong></Typography>
            {loading &&
                <Box display="flex" alignItems="center" justifyContent="center" p={4}>
                    <CircularProgress />
                </Box>
            }
            {!loading && renewed?.length === 0 &&
                <Box display="flex" alignItems="center" justifyContent="center" p={4}>
                    <Typography variant="body1">No renewed subscriptions to manage.</Typography>
                </Box>
            }
            {renewed?.length > 0 &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Subscription ID</TableCell>
                                <TableCell>Organization Name</TableCell>
                                <TableCell>New Expiration Date</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renewed.map((district) => {
                                return <SalesCleverActionsRenewedRow key={district?.id} district={district} />
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>
    )
}

const SalesCleverActionsRenewedRow: React.FC<{ district: ICleverDistrictResponse }> = ({ district }) => {
    const dialogState = useDialogState()

    return (
        <TableRow>
            <SalesCleverActionsRenewedDialog
                district={district}
                dialogState={dialogState}
            />
            <TableCell>{district?.subscription_id}</TableCell>
            <TableCell>{district?.subscription_school_name}</TableCell>
            <TableCell>{district?.subscription_end}</TableCell>
            <TableCell>
                <Button
                    onClick={() => dialogState.handleOpen()}
                    color="blue"
                    variant="contained"
                >
                    Update
                </Button>
            </TableCell>
        </TableRow>
    )
} 

const SalesCleverActionsRenewedDialog: React.FC<{ district: ICleverDistrictResponse, dialogState: DialogStateProps }> = ({ district, dialogState }) => {
    const sharedClasses = useSharedStyles()
    const alert = useAlert()
    const { mutate } = useCleverDistrictRenewedNeedsDisconnect()
    const [copying, setCopying] = useState(false)
    const [stepOne, setStepOne] = useState(false)
    const [stepTwo, setStepTwo] = useState(false)
    const [stepThree, setStepThree] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const dialogRef = useRef<HTMLDivElement>(null)

    const handleStepOne = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStepOne(event?.target?.checked)
    }

    const handleStepTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStepTwo(event?.target?.checked)
    }

    const handleStepThree = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStepThree(event?.target?.checked)
    }

    const handleCopy = () => {
        setCopying(true)
        navigator.clipboard.writeText(district?.subscription_end)
        setTimeout(() => {
            setCopying(false)
        }, 1500)
    }

    const handleConfirm = () => {
        if (submitting) {
            return
        }
        setSubmitting(true)
        justFetch(endpoints?.godmode?.salesCleverDistrictsExpirationOutdated, 'POST', {
            clever_id: district?.clever_id,
            clever_expiration_outdated: false
        })
        .then((res) => {
            setSubmitting(false)
            if (res.ok) {
                dialogState.onClose()
                alert.success('Expiration date updated successfully!')
                mutate()
                return
            }
            throw new Error('Unable to update expiration date.')
        })
        .catch((error) => {
            setSubmitting(false)
            console.log(error)
            alert.error('Unable to update expiration date', error)
        })
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                dialogState.onClose()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dialogState])

    return (
        <Dialog open={dialogState?.open}>
            <div ref={dialogRef}>
                <DialogTitle>Edit Expiration Date in Clever</DialogTitle>
                <DialogContent className={sharedClasses?.vspacing2}>
                    <Typography align="center">
                        Follow these 3 steps to update the expiration date in Clever. Check the box when each step is complete
                    </Typography>
                    <Typography variant="h6">Steps in Clever</Typography>
                    <FormGroup className={sharedClasses?.vspacing2}>
                        <FormControlLabel 
                            control={
                                <Checkbox value={stepOne} onChange={handleStepOne} />
                            }
                            label={
                                <Typography><strong>Step 1: </strong><a style={{color: "#27a9e1"}} href={`https://apps.clever.com/kodable/district/${district?.clever_id}/overview`} target="_blank" rel="noreferrer">{`[Click Here]`}</a> to view the customer's overview page in Clever</Typography>
                            }
                        />
                        <FormControlLabel
                            control={<Checkbox value={stepTwo} onChange={handleStepTwo} />}
                            label={
                                <Typography><strong>Step 2: </strong>Click the <FontAwesomeIcon icon={faPencil} /> {`(pencil icon)`} next to "Shared Schools" to edit</Typography>
                            }
                        />
                        <FormControlLabel
                            control={<Checkbox value={stepThree} onChange={handleStepThree} />}
                            label={
                                <Typography><strong>Step 3: </strong>Edit the expiration date to their new subscription end date {`(below)`}</Typography>
                            }
                        />
                    </FormGroup>
                    <Box display="flex" alignItems="center" justifyContent="center" paddingBottom="1rem">
                        <Button
                            variant="outlined"
                            color="blue"
                            endIcon={<FontAwesomeIcon icon={copying ? faCheckCircle : faCopy} />}
                            onClick={handleCopy}
                        >
                            {copying ? "Copied!" : district?.subscription_end}
                        </Button>
                    </Box>
                    <Divider />
                </DialogContent>
                <DialogActions>
                    <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{width: "100%"}}>
                        <Button
                            variant="contained"
                            color="blue"
                            onClick={handleConfirm}
                            disabled={!stepOne || !stepTwo || !stepThree || submitting}
                        >
                            Confirm
                        </Button>
                        <Typography>I confirm that I have completed all 3 steps listed above</Typography>
                    </Box>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default SalesCleverActionsQueue