import { Box } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import endpoints from "endpoints";
import { ISalesProfile } from "pages/settings/teacher/ISalesProfile";
import { PlanHistory } from "pages/settings/teacher/PlanHistory";
import { useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import React from "react";

export const SubscriptionPage: React.FC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const { data: salesProfile } = useSWR<ISalesProfile>(endpoints.salesProfile(params.teacherId));

  return <Box display="flex">
    <Box width="50%">
      {salesProfile && <PlanHistory salesProfile={salesProfile} godmode />}
    </Box>
  </Box>
}
