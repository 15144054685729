import { makeStyles } from "@material-ui/core"

export const useSalesInitialSelectionStyles = makeStyles({
    siteTypeContainer: {
        display: 'flex',
        width: '48%',
        height: '150px',
        flexDirection: 'column',
        textAlign: 'center',
    },
    siteTypeBox: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        background: '#27a9e1',
        color: '#fff',
        padding: '1rem',
        userSelect: 'none',
        cursor: 'pointer',
        borderRadius: '2rem'
    },
    optionBox: {
        display: 'flex',
        width: '48%',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#27a9e1',
        color: '#fff',
        padding: '1rem',
        userSelect: 'none',
        cursor: 'pointer',
        borderRadius: '2rem'
    },
    optionBoxContent: {
        display: "flex",
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    optionBoxIcon: {
        height: '24px',
        width: 'auto',
        marginRight: '1rem'
    },
    optionBoxText: {
        fontSize: '1.25rem'
    },
    optionBoxHelpText: {
        marginTop: '10px',
        fontSize: '0.9rem'
    },
    schoolAddLink: {
        cursor: 'pointer',
        fontWeight: 'bold'
    }
})

