import React from "react";
import {GameTitle} from "../../../enums/GameTitle";
import useSharedStyles from "../../useSharedStyles";
import {Box, Typography} from "@material-ui/core";

const GameHorizontalCard: React.FC<{ game: GameTitle }> = ({game}) => {
  const sharedClasses = useSharedStyles();

  return (
    <Box className={sharedClasses?.hspacing4} display="flex" alignItems="stretch" justifyContent="center" p={2}>
      <Box
        style={{
          width: '200px',
          height: '200px',
          flex: '0 0 200px',
          position: 'relative',
        }}
      >
        <img
          style={{
            display: 'block',
            borderRadius: 28,
            overflow: 'hidden',
          }}
          width={200}
          height={200}
          alt={`Kodable ${game} App Icon`}
          src={`/images/onboarding/${game}-app-icon.png`}
        />
      </Box>
      <Box>
        <Box className={sharedClasses?.vspacing2}>
          <img
            width={242}
            height={game === GameTitle.BASICS ? 35 : 31}
            alt={`Kodable ${game} Logo`}
            src={`/images/onboarding/${game}-logo-inline.svg`}
            style={{
              width: 242,
              height: game === GameTitle.BASICS ? 35 : 31,
            }}
          />
          <Typography variant="h2"><strong>{game === GameTitle.BASICS ? 'Grades K+' : 'Grades 3+'}</strong></Typography>
          <Typography variant="body1">
            {game === GameTitle.BASICS &&
              <>Use block-based to introduce students to the fundamentals of computer science.</>
            }
            {game === GameTitle.CREATOR &&
              <>Assisted by your AI tutor CatBot, help students grow from block-based coding to using real code to
                create their own games.</>
            }
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default GameHorizontalCard