import React from "react";
import {useRouteMatch} from "react-router-dom";
import {IRouteParams} from "../../../AppRoutes";
import {Box, Typography} from "@material-ui/core";
import {RecommendedForYou} from "./RecommendedForYou";
import {ClassRecentActivity} from "./sidebar/RecentActivity";

export const SidebarContent: React.VFC = () => {
  const {params} = useRouteMatch<IRouteParams>();

  return <Box width={400} bgcolor="#F4F7FF" borderLeft="1px solid rgba(0,0,0,0.12)" p={4}
              maxHeight="calc(100vh - 156px)" overflow="scroll">
    <RecommendedForYou klass_id={params.classId}/>
    <Box mt={4}>
      <Typography variant="h2" style={{opacity: 0.75}}>Recent Activity</Typography>
      <ClassRecentActivity
        klass_id={params.classId}
      />
    </Box>
  </Box>
}