import SectionContainer from "./SectionContainer";
import React from "react";

const CheckUnderstanding: React.FC<{title: string, description: string, duration: string}> = ({title, description, duration}) => {
  return (
    <SectionContainer
      title={title}
      duration={duration}
      icon={'/images/curriculum_images/CCSSHandout.png'}
      iconColor={'#00a79d'}
      innerHtml={description}
    />
  )
}

export default CheckUnderstanding