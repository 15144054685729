import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

// Routes
import SmeeborgAnswerKeysPage from './SmeeborgAnswerKeysPage';
import AsteroidiaAnswerKeysPage from './AsteroidiaAnswerKeysPage';

const AnswerKeysIndexPage: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={SmeeborgAnswerKeysPage} path={`${path}/smeeborg`} />
      <Route component={AsteroidiaAnswerKeysPage} path={`${path}/asteroidia`} />
      <Redirect to={`${path}/smeeborg`} />
    </Switch>
  );
}

export default AnswerKeysIndexPage;
