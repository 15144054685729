import React, { useMemo, useState} from 'react';
import clsx from 'clsx';
import {justFetch} from "mutations/mutate";
import {
  Box,
  CircularProgress, Dialog, FormControl, LinearProgress,
  makeStyles, MenuItem,
  Paper, Select,
  Tab,
  Tabs, Typography,
  useTheme
} from "@material-ui/core";
import {DataGrid, GridCellParams, GridColDef, GridValueFormatterParams} from "@material-ui/data-grid";
import {accountTrackedDate} from "pages/godmode/utils/time";
import {Link, } from "react-router-dom";
import {routes} from "routes";
import {ISubscription} from "../../../../types/ISubscription";
import {AccountManagementIndexTabOptions} from "./AccountManagementIndexTabs";
import {TabContext} from "@material-ui/lab";
import {format} from "date-fns";
import {DatePicker} from "@material-ui/pickers";
import endpoints from "../../../../endpoints";
import {useAlert} from "../../../../context/AlertProvider";

const columns: GridColDef[] = [
  { field: 'display_name', headerName: 'Org Name', width: 250 },
  { field: 'status', headerName: 'Status', width: 150},
  { field: 'implementation_date', headerName: 'Implementation Date', width: 200, type: 'date' },
  { field: 'current_subscription_start_date', headerName: 'Start Date', width: 150, type: 'date' },
  { field: 'current_subscription_end_date', headerName: 'End Date', width: 150, type: 'date' },
  { field: 'administrator_name', headerName: 'Admin Name', width: 200 },
  { field: 'administrator_email', headerName: 'Admin Email', width: 200 },
  { field: 'billing_contact_name', headerName: 'Billing Name', width: 200 },
  { field: 'billing_contact_email', headerName: 'Billing Email', width: 200 },
  { field: 'billing_contact_phone', headerName: 'Billing Phone', width: 200 },
]

const useStyles = makeStyles((theme) => ({
  root: {
    '& .no_attempt, & .contact_made, & .legacy, & .attempted_contact': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'capitalize'
    },
    '& .no_attempt': {
      backgroundColor: theme.palette.red.main
    },
    '& .contact_made': {
      backgroundColor: theme.palette.green.main,
    },
    '& .legacy': {
      backgroundColor: theme.palette.warning.main,
    },
    '& .attempted_contact': {
      backgroundColor: theme.palette.blue.main,
    }
  },
}));

interface AccountManagementTableProps {
  allSubscriptions?: ISubscription[],
  dealSize: AccountManagementIndexTabOptions
  updateSubscriptions: () => Promise<void>
}
type LargeDealIndexTabOptions = 'pending_setup' | 'post_implementation' | 'renewal_call';
type SmallDealIndexTabOptions = 'inactive' | 'non_implemented' | 'implemented';

const AccountManagementTable: React.VFC<AccountManagementTableProps> = ({allSubscriptions, dealSize, updateSubscriptions}) => {
  const [selectedLargeDealStatus, setSelectedLargeDealStatus] = useState<LargeDealIndexTabOptions>('pending_setup');
  const [selectedSmallDealStatus, setSelectedSmallDealStatus] = useState<SmallDealIndexTabOptions>('inactive');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const rowStyles = useStyles();
  const theme = useTheme()
  const alert = useAlert();
  

  const handleChangeLargeDeal = (_event: React.ChangeEvent<{}>, newValue: LargeDealIndexTabOptions) => {
    setSelectedLargeDealStatus(newValue);
  }
  const handleChangeSmallDeal = (_event: React.ChangeEvent<{}>, newValue: SmallDealIndexTabOptions) => {
    setSelectedSmallDealStatus(newValue);
  }
  
  const selectionSummary = useMemo(() => {

    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    if (dealSize == 'large_deals') {
      switch (selectedLargeDealStatus) {
        case "pending_setup":
          return "Large deals without an Implementation Date."
        case "post_implementation":
          month -= 2;
          if (month < 0) {
            month += 12;
            year -= 1;
          }
          return `Large deals with an Implementation Date in ${month}/${year}.`;
        case "renewal_call":
          month += 2;
          if (month > 12) {
            month -= 12;
            year += 1;
          }
          return `Large deals with subscription ending in ${month}/${year}.`;
        default: return "Unknown State";
      }
    } else if (dealSize == 'small_deals'){
      switch (selectedSmallDealStatus){
        case "inactive":
          month -= 3;
          if (month < 0) {
            month += 12;
            year -= 1;
          }
          return `Small deals with no implementation date whose sub started in ${month}/${year} or earlier.`;
        case "non_implemented":
          month -= 2;
          if (month < 0) {
            month += 12;
            year -= 1;
          }
          return `Small deals who have not hit their implementation date, or don't have one and whose sub started in ${month}/${year} or later.`;
        case "implemented":
          return `Small deals who are passed their implementation date.`;
        default: return "Unknown State";

      }
    }
  },[dealSize, selectedSmallDealStatus, selectedLargeDealStatus]);
  
  function isXMonthsAhead(months: number, lateDate: Date, earlyDate: Date) {
    const monthDelta = lateDate.getMonth() - earlyDate.getMonth();
    const yearDelta = lateDate.getFullYear() - earlyDate.getFullYear();
    return yearDelta === 0 && monthDelta === months || yearDelta === 1 && monthDelta === (months - 12);
  }
  const filteredSubscriptions = useMemo(() => {
    if (!allSubscriptions) {
      return []
    }
    setLoading(false)
    let filteredData = allSubscriptions.map((subscription) =>
    {
      return {
        id: subscription.id,
        display_name: subscription.organization_profile?.display_name ?? '',
        administrator_name: subscription.teacher?.name ?? '',
        administrator_email: subscription.teacher?.username ?? '',
        administrator_id: subscription.teacher?.id ?? 0,
        billing_contact_name: subscription.organization_profile?.billing_contact_name ?? '',
        billing_contact_email: subscription.organization_profile?.billing_contact_email ?? '',
        billing_contact_phone: subscription.organization_profile?.billing_contact_phone ?? '',
        current_subscription_start_date: subscription.start_date,
        current_subscription_end_date: subscription.end_date,
        implementation_date: subscription.implementation_date,
        status: subscription.management_state,
        organization_id: subscription.organization_profile?.id ?? 0,
        is_large_deal: subscription.is_large_deal ?? false
      }})
    const currentDate = new Date();
    return filteredData.filter((data) => {
      if (dealSize == 'large_deals' && data.is_large_deal) {
        switch (selectedLargeDealStatus) {
          case "pending_setup":
            return data.implementation_date === null;
          case "post_implementation":
            // if implementation_date.month is exactly current_month - 2
            if (data.implementation_date === null) {
              return false;
            }
            let implementationDate = new Date(data.implementation_date!);
            implementationDate = new Date(implementationDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              timeZone: 'UTC',
            }));
            return isXMonthsAhead(2, currentDate, implementationDate);
          case "renewal_call":
            // if end_date.month is exactly current_month + 2
            let subEndDate = new Date(data.current_subscription_end_date);
            subEndDate = new Date(subEndDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              timeZone: 'UTC',
            }));
            return isXMonthsAhead(2, subEndDate, currentDate);
          default: return false;
        }
      } else if (dealSize == 'small_deals' && !data.is_large_deal){

        const startDate = new Date(data.current_subscription_start_date);
        const startDateDeltaMonth = currentDate.getMonth() - startDate.getMonth();
        const startDateDeltaYear = currentDate.getFullYear() - startDate.getFullYear();
        switch (selectedSmallDealStatus){
          case "inactive":
            // no implementation date + no usage data for 3 months
            if (data.implementation_date !== null) {
              return false;
            }
            return startDateDeltaMonth >= 3 || startDateDeltaYear >= 1;
          case "non_implemented":
            // Small deals that have not hit their implementation date (if it exists) or 
            // have no implementation date but haven't hit inactive status yet
            if (data.implementation_date !== null) {
              // return true if implementation_date is in the future
              return currentDate < new Date(data.implementation_date!);
            }
            console.log(startDateDeltaMonth, startDateDeltaYear)
            return startDateDeltaMonth < 3 && startDateDeltaYear < 1 ||
              startDateDeltaMonth < 0 && startDateDeltaYear === 1;
          case "implemented":
            if (data.implementation_date !== null) {
              // return true if implementation_date has passed
              return new Date(data.implementation_date!) <= currentDate;
            }
            return false
          default: return false;
          
        }
      }
      return false;
    })
  }, [allSubscriptions, selectedLargeDealStatus, selectedSmallDealStatus, dealSize]);
  
  async function updateSubscription(subscriptionId: number, managementState?: string, implementationDate?: string) {
    let body = {};
    if (managementState) {
      if (dealSize == 'small_deals') {
        managementState = `${selectedSmallDealStatus}:${managementState}`
      } else {
        managementState = `${selectedLargeDealStatus}:${managementState}`
      }
      body = {management_state: managementState};
    }
    if (implementationDate) {
      body = {...body, implementation_date: implementationDate};
    }
    setSubmitting(true);
    const response = await justFetch(endpoints.godmode.subscriptions.edit(subscriptionId), 'PUT',body);
    if (!response.ok) {
      var responseBody = await response.json();
      alert.error(responseBody.message || responseBody.error || 'An unknown error occurred');
    }else{
      await updateSubscriptions();
      alert.success('Subscription updated successfully');
    }
    setSubmitting(false);
  }
  
  function getCurrentStatus(lastStatus?: string | null){
    if (lastStatus === undefined || lastStatus === null) {
      return 'legacy';
    }
    if (lastStatus === 'legacy') {
      return 'legacy';
    }
    let status = 'no_attempt';
    
    const splitValue = (lastStatus).split(':');
    status = splitValue.length >= 2 ? splitValue[1] : 'no_attempt';
    let currentBucket = dealSize == 'small_deals' ? selectedSmallDealStatus as string : selectedLargeDealStatus as string;
    if (splitValue.length > 1 && splitValue[0] != currentBucket) {
      status = 'no_attempt';
    }
    
    return status;
  }
  const addColumnFormatters = (columns: GridColDef[]) => {
    return columns.map((column) => {
      if (column.type && column.type === 'date') {
        if (column.field == 'implementation_date'){
          column.renderCell = (params: GridValueFormatterParams) => {
            return (
              <DatePicker
                onChange={(date) => {
                  updateSubscription(params.row.id, undefined, format(date as Date, 'yyyy-MM-dd'));
                }}
                name="new_implementation_date"
                id="new_implementation_date"
                value={params.value ? accountTrackedDate(params.value as string) : null}
                format="MM/dd/yyyy"
                inputVariant="outlined"
                label="Enter Date"
              />
            )
          }
        }else {
          column.valueFormatter = (params: GridValueFormatterParams) => {
            if (!params.value) {
              return 'N/A';
            }
            return accountTrackedDate(params.value as string);
          }
        }
      }
      if (column.field === 'display_name') {
        column.renderCell = (params: GridValueFormatterParams) => {
          return (
            <Link
              style={{color: theme.palette.blue.main}}
              to={routes.godmode.organizationProfiles.view(params.row.organization_id)}>{params.value}</Link>
          )
        }
      }
      if (column.field === 'administrator_name') {
        column.renderCell = (params: GridValueFormatterParams) => {
          return (
            <Link
              style={{color: theme.palette.blue.main}}
              to={routes.godmode.user.index(params.row.administrator_id)}
            >
              {params.value}
            </Link>
          )
        }
      }
      if (column.field === 'status') {
        column.cellClassName = (params: GridCellParams) => {
          const status = getCurrentStatus(params.value as string);
          return clsx({
            no_attempt: status === 'no_attempt',
            attempted_contact: status === 'attempted_contact',
            contact_made: status === 'contact_made',
            legacy: status === 'legacy'
          })
        }
        column.renderCell = (params: GridValueFormatterParams) => {

          return (
            <FormControl>
              <Select
                value={getCurrentStatus(params.value as string)}
                onChange={(event) => updateSubscription(params.row.id, event.target.value as string)}
              >
                <MenuItem value={'no_attempt'}>No Attempt</MenuItem>
                <MenuItem value={'attempted_contact'}>Attempted Contact</MenuItem>
                <MenuItem value={'contact_made'}>Contact Made</MenuItem>
                
              </Select>
            </FormControl>
          )
        }
      }
      return column
    })
  }

  return (
    <div style={{width: '100%' }}>
      <Dialog open={submitting}>
        <Box display="flex" flexDirection="column">
          <Typography>Updating Subscription...</Typography>
          <LinearProgress style={{ visibility: 'visible' }} />
        </Box>
      </Dialog>
      {dealSize == 'large_deals' ?
      <TabContext value={selectedLargeDealStatus}>
        <Tabs value={selectedLargeDealStatus} onChange={handleChangeLargeDeal} style={{backgroundColor: 'transparent'}}>
            <Tab label="Pending Setup" value="pending_setup"/>
            <Tab label="1 Month Check-in" value="post_implementation"/>
            <Tab label="Renewal Call" value="renewal_call"/>
        </Tabs>
      </TabContext> : <TabContext value={selectedSmallDealStatus}>
          <Tabs value={selectedSmallDealStatus} onChange={handleChangeSmallDeal} style={{backgroundColor: 'transparent'}}>
            <Tab label="Inactive" value="inactive"/>
            <Tab label="Non-Implemented" value="non_implemented"/>
            <Tab label="Implemented" value="implemented"/>
          </Tabs>
        </TabContext>}
      <Typography style={{paddingTop: 5, paddingBottom: 5}}>{selectionSummary}</Typography>
      {loading &&
          <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2rem'
              }}
          >
              <CircularProgress />
          </Box>
      }
      {!loading &&
          <Paper>
              <div className={rowStyles.root}>
                <DataGrid
                      rows={filteredSubscriptions}
                      columns={addColumnFormatters(columns)}
                      autoHeight
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      paginationMode="client"
                      
                  />
              </div>
          </Paper>
      }
    </div>
  )
}

export default AccountManagementTable;