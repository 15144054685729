import { Link } from "react-router-dom";
import Amplitude from 'utils/Amplitude';
import React from "react";
import useSWR from 'swr';
import { IParentSubscriptionPricing } from "types/ISubscription";
import endpoints from "endpoints";

const Promo: React.VFC<{ setShowPromo: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setShowPromo }) => {
  const { data: pricing } = useSWR<IParentSubscriptionPricing>(endpoints?.parentSubscriptionPricing)
  
  return (
    <div className="flex-1 items-end flex relative -mt-3">
      <div className="px-1 md:px-2 overflow-x-hidden">
        <div className="bg-orange-100 py-4 px-2 lg:px-3 rounded-xl mt-12 relative">
          <button
            onClick={() => setShowPromo(false)}
            className="absolute -top-3 -left-1 text-gray-800">
            <svg
              className="w-8 h-8 absolute text-white z-10"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <svg
              className="w-8 h-8 z-20 absolute"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div className="flex">
            <h5 className="font-bold text-sm lg:text-lg text-gray-800 leading-5">
              Get more with a Pro Membership!
            </h5>
            <img
              className="w-24 h-24 -mt-8 -ml-4 hidden lg:block"
              src="/images/parents/upgrade/promo.png"
              alt="fuzz"
            />
          </div>
          <div className="flex justify-center items-center my-1">
            <Link
              to={{ pathname: "/parent/upgrade/info", state: { showUI: true } }}
              onClick={() => {
                Amplitude.track('Clicked Nav Membership Ad');
              }}
              className="relative text-xs lg:text-base  bg-kodable-orange text-white px-2 lg:px-6 py-2 rounded hover:bg-kodable-orange-dark active:bg-kodable-orange-darker">
              Learn More
            </Link>
          </div>
          <p className="hidden lg:block lg:text-sm">
            Hundreds of activities {pricing ? `for less than $${Math.ceil((pricing as IParentSubscriptionPricing).yearly/12)}` : " with a great deal"} on an annual plan.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Promo;