import { IRouteParams } from "AppRoutes";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import { ConfirmationDialog } from "components/dialogs/base/ConfirmationDialog";
import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { mutate } from "swr";

export const DowngradeSubscriptionDialog: React.FC<DialogStateProps & { teacherId: number }> = ({ open, onClose, teacherId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const alert = useAlert();
  const { params } = useRouteMatch<IRouteParams>();
  const handleConfirm = () => {
    setIsSubmitting(true);
    setError(undefined);
    justFetch(endpoints.godmode.downgrade(teacherId), 'POST')
      .then(res => {
        setIsSubmitting(false);
        if (res.ok) {
          alert.success('Downgraded');
          onClose();

          mutate(endpoints.salesProfile(params.teacherId), undefined, true)
          mutate(endpoints.godmode.teacher(params.teacherId), undefined, true)
        } else {
          res.json().then(body => setError(body.message || body.error || 'Unknown error'));
        }
      })
      .catch(() => setError('Unknown error'));
  }

  return <ConfirmationDialog open={open} onClose={onClose} title={"Downgrade?"} onConfirm={handleConfirm} submitting={isSubmitting} error={error} danger>
  </ConfirmationDialog>;
}