import { Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress, CircularProgress, Grid, Box, Paper, Typography, Link } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import React, { useState, useMemo, useEffect } from "react";
import useSWR, { mutate } from "swr";
import { IResource } from "types/IResource";
import { ICourseData } from "../../../pages/courses/ICourseData";

const AddMaterialDialog: React.VFC<DialogStateProps & { course: ICourseData, add: 'materials' | 'courseCertificate', onAddCourseCertificate?: (resourceUrl: string) => void }> = ({ open = false, onClose = () => { }, course, add, onAddCourseCertificate }) => {
  const { data: resources } = useSWR<IResource[]>(endpoints.resources);
  const [selectedResource, setSelectedResource] = useState<IResource | undefined>();
  const [resourceFilter, setResourceFilter] = useState('');
  const filteredResources = useMemo(() => resources?.filter(({ id, name }) => name.toLowerCase().includes(resourceFilter.toLowerCase())), [resources, resourceFilter])
  const sharedClasses = useSharedStyles();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  useEffect(() => {
    if (open) {
      setSubmitError(false);
      setSubmitting(false);
    }
  }, [open]);

  const handleAddMaterial = () => {
    if (add === 'courseCertificate') {
      onAddCourseCertificate?.(selectedResource!.resource_url);
      setSelectedResource(undefined);
      onClose();
      return;
    }

    setSubmitting(true);
    justFetch(endpoints.course(course.id), 'PUT', {
      meta: {
        ...course.meta,
        resources: (course.meta?.resources || []).concat(selectedResource!.id)
      }
    })
      .then(res => {
        setSubmitting(false);
        if (!res.ok) {
          throw new Error();
        }

        mutate(endpoints.allBasicsCourses);
        mutate(endpoints.courseDetails(course.id));
        setSelectedResource(undefined);
        onClose();
      })
      .catch(() => { setSubmitError(true); setSubmitting(false); })
  }

  return <>
    <Dialog open={submitError}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>There was an error linking this material to this course. Try again (or go bug fabgreg about it)</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setSubmitError(false)}
        >Close</Button>
      </DialogActions>
    </Dialog>
    <Dialog open={open}>
      <LinearProgress style={{ visibility: submitting ? 'visible' : 'hidden' }} />
      <DialogTitle>Add Material From Resources</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <TextField
          placeholder="Filter resources"
          onChange={e => setResourceFilter(e.target.value)}
          value={resourceFilter}
        />

        {!resources && <CircularProgress />}

        <Grid container spacing={2}>
          {filteredResources?.map(resource => <Grid key={resource.id} item style={{ maxWidth: '100%' }}>
            <Box component={Paper} {...{ variant: 'outlined' }} p={2}>
              <Typography>{resource.name}</Typography>
              <Box overflow="hidden" textOverflow="ellipsis"><Link href={resource.resource_url} target="_blank">{resource.resource_url}</Link></Box>
              <Box mt={2}>
                {!course.meta?.resources?.includes(resource.id)
                  ? <Button
                    color="primary"
                    variant={resource.id === selectedResource?.id ? 'contained' : 'outlined'}
                    onClick={() => setSelectedResource(resource)}
                  >
                    {resource.id === selectedResource?.id ? 'Selected' : 'Select'}
                  </Button>
                  : <>Resource already added</>}
              </Box>
            </Box>
          </Grid>)}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={submitting}
        >Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={submitting || !selectedResource}
          onClick={handleAddMaterial}
        >Add</Button>
      </DialogActions>
    </Dialog>
  </>
}

export default AddMaterialDialog;