import useSharedStyles from "components/useSharedStyles";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import { IOrderRequest } from "../IOrderRequest";

export const useSubmitOrderFormHooks = ({ validateFields }: { validateFields?: (keyof IOrderRequest)[] }) => {
  const form = useFormikContext<IOrderRequest>();
  const allowNext = useMemo(() => !validateFields || (Object.keys(form.errors) as (keyof IOrderRequest)[]).every(errorField => !validateFields.includes(errorField)), [validateFields, form.errors]);
  const sharedClasses = useSharedStyles();

  return { form, allowNext, sharedClasses };
}