import amplitude from 'amplitude-js';

export enum AmplitudeProject {
  parent = 'parent',
  teacher = 'teacher'
}
let _project: AmplitudeProject = AmplitudeProject.parent;

amplitude.getInstance(AmplitudeProject.parent).init(process.env.REACT_APP_AMPLITUDE_TOKEN_PARENT!);
amplitude.getInstance(AmplitudeProject.teacher).init(process.env.REACT_APP_AMPLITUDE_TOKEN_TEACHER!);


const actions = {
  identify: (id: any, project: AmplitudeProject) => {
    _project = project;

    amplitude.getInstance(_project).setUserId(id);
  },
  track: (name: string, props: {} = {}) => {
    amplitude.getInstance(_project).logEvent(name, props);
  },
  register: (props: {}) => {
    
  },
  people: {
    set: (props: {}) => {
      amplitude.getInstance(_project).setUserProperties(props);
    },
  },
};

export default actions;
