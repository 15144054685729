import { Box, Collapse, Typography } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import classNames from "classnames";
import useSharedStyles from "components/useSharedStyles";
import React, { useState } from "react";

export const UICollapsingBlock: React.FC<{ color: string, title: string, startOpen?: boolean }> = ({ startOpen, color, title, children }) => {
  const [collapsed, setCollapsed] = useState(!startOpen);
  const sharedClasses = useSharedStyles();

  return <Box borderRadius={6} borderColor={color} border="2px solid">
    <Box bgcolor={color} p={1} display="flex" justifyContent="center" alignItems="center" className={classNames(sharedClasses.hspacing1, sharedClasses.hoverCursorPointer)} onClick={() => setCollapsed(!collapsed)}>
      <Typography variant="h5" style={{ color: 'white' }}>{title}</Typography>
      <KeyboardArrowRight htmlColor="white" fontSize="large" style={{ marginTop: 3, transform: !collapsed ? 'rotate(90deg)' : undefined }} />
    </Box>
    <Collapse in={!collapsed}>
      {children}
    </Collapse>
  </Box>;
}