import React, { Fragment, useState, useEffect } from 'react';
import TopNav from './shared/TopNav';
import { Section } from './guides/shared/scrollspy';
import Amplitude from 'utils/Amplitude';

interface ActiveNavProps {
  section: string;
  activeSection: string;
  children: React.ReactNode;
}

const ActiveNav: React.FC<ActiveNavProps> = ({ section, activeSection, children }) => {
  return (
    <li
      className={`${
        activeSection && activeSection.includes(section)
          ? 'bg-kodable-blue text-white py-2 px-4'
          : 'py-2 px-4'
      }`}>
      <a href={`#${section}`}>{children}</a>
    </li>
  );
}

const LearningPathPage: React.FC = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    Amplitude.track('Viewed Learning Path');
  }, []);

  return (
    <Fragment>
      <TopNav
        title="Learning Path"
        backLink="/learning-tools"
        navLinks={[
          { title: 'Learning Tools', to: '/learning-tools' },
          { title: 'Learning Path', to: '/learning-tools/learning-path' },
        ]}
      />
      <div
        style={{ backgroundColor: '#00a79d' }}
        className="bg-opacity-75 pt-4">
        <div className="max-w-6xl flex justify-around items-center mx-auto">
          <img
            className="w-full hidden md:block max-w-xs xl:max-w-lg"
            src="/images/learning-tools/header-splat.png"
            alt="splat"
          />
          <div className="relative flex justify-center max-w-xs xl:max-w-md items-center z-20">
            <img
              className="w-full -mt-6 -mb-16"
              src="/images/learning-tools/header-circle.png"
              alt="circle"
            />
            <img
              className="absolute w-56 xl:w-80"
              src="/images/learning-tools/Kodableondark.png"
              alt="logo"
            />
            <p className="uppercase absolute w-48 xl:w-72 text-white bottom-6 xl:bottom-12 font-semibold text-center text-sm xl:text-2xl">
              Self Guided Learning Path for Parents
            </p>
          </div>
        </div>
      </div>
      <div className="flex min-h-full">
        <div className="flex-1 mt-16 px-2 md:px-12">
          <Section
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            id="intro"
            className="m-4 py-12 flex flex-col sm:flex-row justify-center items-center">
            <div className="mb-6 sm:mb-0 sm:self-start pr-4">
              <img
                className="w-28"
                src="/images/learning-tools/how-to.png"
                alt="How to"
              />
            </div>
            <div className="flex-1">
              <h2 className="text-2xl uppercase font-medium">
                How to guide their learning
              </h2>
              <p className="my-2">
                Use this Guide as a reference, but feel free to let your child
                explore and play at their own pace and in any order. Every child
                is different. If they need more or less time in one area, that
                is okay. Play based learning is about exploration and
                experimentation.
              </p>
              <img
                src="/images/learning-tools/how-to-graphics.png"
                alt="how to graphics"
              />
            </div>
          </Section>
          <Section
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            id="beginner"
            className="m-4 pt-6 flex flex-col sm:flex-row justify-center items-center">
            <div className="mb-6 sm:mb-0 sm:self-start pr-4">
              <img
                className="w-28"
                src="/images/learning-tools/beginner-icon.png"
                alt="How to"
              />
            </div>
            <div className="flex-1">
              <h2 className="text-2xl uppercase font-medium">
                Beginner: Foundations
              </h2>
              <p className="my-2">
                Kodable starts by building skills used in every programming
                language, as well as important logic and problem solving skills
                your child needs for their future.
              </p>
            </div>
          </Section>
          <Section
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            id="beginner-path"
            className="m-4 py-6 flex flex-col justify-center">
            <div className="ml-3 mb-12">
              <span className="uppercase font-bold text-gray-700">
                Beginner Learning Path
              </span>
              <span className="ml-12 italic text-gray-600 text-sm">
                Recommended for Grades K-5
              </span>
            </div>
            <img
              className="ml-12"
              src="/images/learning-tools/LearningPath_Beginner.png"
              alt="how to graphics"
            />
          </Section>
          <div className="m-4 py-6 flex flex-col justify-center">
            <Section
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              id="beginner-prerequisites">
              <h5 className="uppercase font-bold text-gray-700">
                Pre-requisites
              </h5>
              <p className="mt-4">
                Based on common core standards, child development research,
                executive function research.
              </p>
              <ul className="list-disc ml-6 mt-4">
                <li>
                  Child understands concept of numbers and can represent/compare
                  whole numbers.
                </li>
                <li>
                  Child is familiar with directionality (up, down, right, left)
                  and an understanding that arrows represent direction.
                </li>
                <li>
                  Child can understand commands with three separate
                  instructions.
                </li>
                <li>Child can identify visual patterns.</li>
              </ul>
              <div className="flex flex-col sm:flex-row justify-between mt-8 mb-16">
                <div>
                  <h5 className="uppercase font-bold text-gray-700 mb-2">
                    Learning Objectives
                  </h5>
                  <p>In this unit, learners will be able to:</p>
                  <ul className="list-disc ml-6">
                    <li>Create and solve programming problems</li>
                    <li>Visually represent programming problems</li>
                    <li>Explain programming processes</li>
                    <li>Test and debug code</li>
                  </ul>
                </div>
                <div className="flex-1 mt-6 md:mt-0 md:ml-6">
                  <h5 className="uppercase font-bold text-gray-700 mb-2">
                    Mindsets
                  </h5>
                  <ul className="list-disc ml-6">
                    <li>
                      Make sense of problems and persevere in solving them
                      (CCSS)
                    </li>
                    <li>Critical Thinking</li>
                    <li>Logical Reasoning</li>
                    <li>Design Thinking</li>
                  </ul>
                </div>
              </div>
            </Section>
            <Section
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              id="beginner-descriptions">
              <h5 className="uppercase font-bold text-gray-700">
                Course Descriptions
              </h5>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  1 - Sequence Sector - Sequencing and Algorithms
                </h6>
                <p>
                  Sequence, also known as an order of events, is a set of
                  instructions that are read and executed by a computer exactly
                  as they are written. It is the first of three flow control
                  structures your child will learn.{' '}
                  <span className="font-bold">*Note</span>: Your child will be
                  given the option to move on after completing a series of basic
                  levels. They can choose to continue or advance to conditions.{' '}
                </p>
                <div className="mt-4 text-gray-700 font-light italic">
                  Optional Video Lessons
                </div>
                <div className="flex flex-col sm:flex-row justify-start md:items-center mt-4 font-bold text-lg md:ml-12">
                  <div className="mb-4 md:mb-0">
                    <div className="mb-2">What Is Sequence?</div>
                    <div>Meet the Fuzz Family</div>
                  </div>
                  <div className="md:ml-24">
                    <div className="mb-2">
                      What are computer programs? Ages 4-6
                    </div>
                    <div>What are computer programs? Ages 7-10</div>
                  </div>
                </div>
              </div>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  2 - Conditions Canyon - Introduction to Logical Expressions
                </h6>
                <p>
                  Sequence, also known as an order of events, is a set of
                  instructions that are read and executed by a computer exactly
                  as they are written. It is the first of three flow control
                  structures your child will learn.{' '}
                  <span className="font-bold">*Note</span>: Your child will be
                  given the option to move on after completing a series of basic
                  levels. They can choose to continue or advance to conditions.
                </p>
                <div className="mt-4 text-gray-700 font-light italic">
                  Optional Video Lessons
                </div>
                <div className="flex justify-start items-center mt-4 font-bold text-lg md:ml-12">
                  <div>
                    <div className="mb-2">What Are Conditions?</div>
                    <div>Conditions in Kodable</div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  3 - Beach Cleanup Easy - Combining Skills
                </h6>
                <p>
                  Sequence, also known as an order of events, is a set of
                  instructions that are read and executed by a computer exactly
                  as they are written. It is the first of three flow control
                  structures your child will learn.{' '}
                  <span className="font-bold">*Note</span>: Your child will be
                  given the option to move on after completing a series of basic
                  levels. They can choose to continue or advance to conditions.
                </p>
                <div className="mt-4 text-gray-700 font-light italic">
                  Optional Video Lessons
                </div>
                <div className="flex justify-start items-center mt-4 font-bold text-lg md:ml-12">
                  <div>
                    <div className="mb-2">
                      How to Play: Beach Cleanup with Kodable (Easy)
                    </div>
                    <div>Beach Baby Rescue - Coding Mission 8</div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  4 - Loopy Lagoon - Introduction to Loops
                </h6>
                <p>
                  Sequence, also known as an order of events, is a set of
                  instructions that are read and executed by a computer exactly
                  as they are written. It is the first of three flow control
                  structures your child will learn.{' '}
                  <span className="font-bold">*Note</span>: Your child will be
                  given the option to move on after completing a series of basic
                  levels. They can choose to continue or advance to conditions.
                </p>
                <div className="mt-4 text-gray-700 font-light italic">
                  Optional Video Lessons
                </div>
                <div className="flex justify-start items-center mt-4 font-bold text-lg md:ml-12">
                  <div>
                    <div className="mb-2">What are Loops?</div>
                    <div>Flex Your Loops Skills! Coding Mission No.4</div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  5 - Beach Cleanup Medium - Combining Skills
                </h6>
                <p>
                  Sequence, also known as an order of events, is a set of
                  instructions that are read and executed by a computer exactly
                  as they are written. It is the first of three flow control
                  structures your child will learn.{' '}
                  <span className="font-bold">*Note</span>: Your child will be
                  given the option to move on after completing a series of basic
                  levels. They can choose to continue or advance to conditions.
                </p>
                <div className="mt-4 text-gray-700 font-light italic">
                  Optional Video Lessons
                </div>
                <div className="flex justify-start items-center mt-4 font-bold text-lg md:ml-12">
                  <div>
                    <div className="mb-2">
                      How to Play: Beach Cleanup with Kodable (Medium)
                    </div>
                    <div>Beach Baby Rescue - Coding Mission 8</div>
                  </div>
                  <div></div>
                </div>
              </div>
            </Section>
            <Section
              id={'applied-knowledge'}
              activeSection={activeSection}
              setActiveSection={setActiveSection}>
              <h5 className="uppercase font-bold text-gray-700 mt-8">
                Applied Knowledge{' '}
                <span className="normal-case text-sm font-medium">
                  (Optional, but encouraged)
                </span>
              </h5>
              <p className="mb-8 mt-2 max-w-3xl">
                Kodable starts by building skills used in every programming
                language, as well as important logic and problem solving skills
                your child needs for their future.
              </p>
              <div className="ml-8">
                <div className="mb-8">
                  <h6 className="font-bold underline mt-4">
                    Fuzz Builder - Unlocks at Code Rank 5
                  </h6>
                  <p>
                    In Smeeborg, children learn that sequence is a series of
                    events executed step-by-step. Children apply this concept of
                    sequence in Fuzz builder, where they can also add items to a
                    fuzz step by step.
                  </p>
                  <div className="mt-4 text-gray-700 font-light italic">
                    Optional Video Lessons
                  </div>
                  <div className="flex justify-start items-center mt-4 font-bold text-lg md:ml-12">
                    <div>
                      <div className="mb-2">Fuzz Builder</div>
                      <div>Fashionable Functions! Coding Mission No.5</div>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div className="mb-8">
                  <h6 className="font-bold underline mt-4">
                    Maze Maker - Unlocks at Code Rank 10
                  </h6>
                  <p>
                    In Smeeborg, children learn how to write a program that will
                    move their fuzz through a maze. Children apply this
                    knowledge by constructing the maze level themselves, and
                    then writing the code that will move their fuzz through
                    their unique maze design.
                  </p>
                  <div className="mt-4 text-gray-700 font-light italic">
                    Optional Video Lessons
                  </div>
                  <div className="flex justify-start items-center mt-4 font-bold text-lg md:ml-12">
                    <div>
                      <div className="mb-2">Maze Maker</div>
                      <div>Make Mazes with Math! Coding Mission No.3</div>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div className="mb-24">
                  <h6 className="font-bold underline mt-4">
                    Game Designer- Unlocks at Code Rank 15
                  </h6>
                  <p>
                    In Asteroidia, children learn that variables store values
                    that can change. Children apply this knowledge in Game
                    designer, where they see how modifying
                    values/variables/properties can change the output of a
                    program (or what the user sees).
                  </p>
                </div>
              </div>
            </Section>
            <Section
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              id="intermediate"
              className="m-4 pt-6 flex flex-col sm:flex-row justify-center items-center">
              <div className="mb-6 sm:mb-0 sm:self-start pr-4">
                <img
                  className="w-28"
                  src="/images/learning-tools/LearningPath_IntermediateIcon.png"
                  alt="How to"
                />
              </div>
              <div className="flex-1">
                <h2 className="text-2xl uppercase font-medium">
                  INTERMEDIATE: PLAY TO LEARN
                </h2>
                <p className="my-2">
                  Kodable gradually transitions your child to written code with
                  engaging games. It is encouraged for kids to play at their own
                  pace and find what interests them.
                </p>
              </div>
            </Section>
            <Section
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              id="intermediate-path"
              className="m-4 py-6 flex flex-col justify-center">
              <div className="ml-3 mb-12">
                <span className="uppercase font-bold text-gray-700">
                  Intermediate Learning Path
                </span>
                <span className="ml-12 italic text-gray-600 text-sm">
                  Recommended for grades 2-5
                </span>
              </div>
              <img
                className="ml-12"
                src="/images/learning-tools/LearningPath_Intermediate.png"
                alt="how to graphics"
              />
            </Section>
            <Section
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              id="intermediate-prerequisites">
              <h5 className="uppercase font-bold text-gray-700">
                Pre-requisites
              </h5>
              <p className="mt-4">
                Based on common core standards, child development research,
                executive function research.
              </p>
              <ul className="list-disc ml-6 mt-4">
                <li>
                  Can has the ability to break down a problem and plan ahead
                </li>
                <li>Child can read (english) (Optimal, not required)</li>
              </ul>
              <div className="flex flex-col sm:flex-row justify-between mt-8 mb-16">
                <div>
                  <h5 className="uppercase font-bold text-gray-700 mb-2">
                    Learning Objectives
                  </h5>
                  <p>In this unit, learners will be able to:</p>
                  <ul className="list-disc ml-6">
                    <li>Identify String, Integer, and Array variables.</li>
                    <li>
                      Understand the difference between values and variables
                    </li>
                    <li>Differentiate between variable types.</li>
                    <li>Understand the role of variables in programming.</li>
                  </ul>
                </div>
                <div className="flex-1 mt-6 md:mt-0 md:ml-6">
                  <h5 className="uppercase font-bold text-gray-700 mb-2">
                    Mindsets
                  </h5>
                  <ul className="list-disc ml-6">
                    <li>
                      Make sense of problems and persevere in solving them
                      (CCSS)
                    </li>
                    <li>Systems Thinking</li>
                    <li>Cause and Effect</li>
                  </ul>
                </div>
              </div>
            </Section>
            <Section
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              id="intermediate-descriptions">
              <h5 className="uppercase font-bold text-gray-700">
                Course Descriptions
              </h5>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  1 - Function Junction - Introduction to Functions
                </h6>
                <p>
                  A function is a segment of code that a programmer can easily
                  call over and over again to perform a single specific action
                  in a computer program.{' '}
                  <span className="font-bold">*Note</span>: Your child will be
                  given the option to move on after completing a series of basic
                  levels. They can choose to continue or advance to strings.
                </p>
              </div>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  2 - Beach Cleanup Hard - Combining Skills
                </h6>
                <p>
                  After completing Function Junction, your child can practice
                  their design thinking skills. Draw a maze to collect the
                  trash, write an algorithm using functions, loops, and
                  conditions skills learned previously. There's unlimited
                  levels, so your child can repeat, or skip as many times as
                  necessary and move on when they're ready.
                </p>
              </div>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  3 - Celestial Strings - Introduction to Variables
                </h6>
                <p>
                  A string is a type of value that can be stored in a variable.
                  A string is made up of characters, and can include letters,
                  words, phrases, or symbols.{' '}
                  <span className="font-bold">*Note</span>: Your child will be
                  given the option to move on after completing a series of basic
                  levels. They can choose to continue or advance to integers.
                </p>
              </div>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  4 - Interstellar Ints - Integers and Value Types
                </h6>
                <p>
                  An array is an ordered list of related items that can include
                  any type of value, including Strings and Integers.{' '}
                  <span className="font-bold">*Note</span>: Your child will be
                  given the option to move on after completing a series of basic
                  levels. They can choose to continue or advance to classes.
                </p>
              </div>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  5 - Ordinal Arrays - List Type Variables
                </h6>
                <p>
                  An array is an ordered list of related items that can include
                  any type of value, including Strings and Integers.{' '}
                  <span className="font-bold">*Note</span>: Your child will be
                  given the option to move on after completing a series of basic
                  levels. They can choose to continue or advance to classes.
                </p>
              </div>
            </Section>
            <Section
              id={'applied-knowledge'}
              activeSection={activeSection}
              setActiveSection={setActiveSection}>
              <h5 className="uppercase font-bold text-gray-700 mt-8">
                Applied Knowledge{' '}
                <span className="normal-case text-sm font-medium">
                  (Optional, but encouraged)
                </span>
              </h5>
              <p className="mb-8 mt-2 max-w-3xl">
                Kodable starts by building skills used in every programming
                language, as well as important logic and problem solving skills
                your child needs for their future.
              </p>
              <div className="ml-8">
                <div className="mb-8">
                  <h6 className="font-bold underline mt-4">
                    Fuzz Builder - Unlocks at Code Rank 5
                  </h6>
                  <p>
                    In Smeeborg, children learn that sequence is a series of
                    events executed step-by-step. Children apply this concept of
                    sequence in Fuzz builder, where they can also add items to a
                    fuzz step by step.
                  </p>
                </div>
                <div className="mb-8">
                  <h6 className="font-bold underline mt-4">
                    Maze Maker - Unlocks at Code Rank 10
                  </h6>
                  <p>
                    In Smeeborg, children learn how to write a program that will
                    move their fuzz through a maze. Children apply this
                    knowledge by constructing the maze level themselves, and
                    then writing the code that will move their fuzz through
                    their unique maze design.
                  </p>
                </div>
                <div className="mb-24">
                  <h6 className="font-bold underline mt-4">
                    Game Designer- Unlocks at Code Rank 15
                  </h6>
                  <p>
                    In Asteroidia, children learn that variables store values
                    that can change. Children apply this knowledge in Game
                    designer, where they see how modifying
                    values/variables/properties can change the output of a
                    program (or what the user sees).
                  </p>
                </div>
              </div>
            </Section>
            <Section
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              id="advanced"
              className="m-4 pt-6 flex flex-col sm:flex-row justify-center items-center">
              <div className="mb-6 sm:mb-0 sm:self-start pr-4">
                <img
                  className="w-28"
                  src="/images/learning-tools/LearningPath_AdvancedIcon.png"
                  alt="How to"
                />
              </div>
              <div className="flex-1">
                <h2 className="text-2xl uppercase font-medium">
                  ADVANCED: BUILDING FOR THE FUTURE
                </h2>
                <p className="my-2">
                  Kodable gives your child their first introduction to written
                  code with JavaScript. JavaScript is an object-oriented
                  programming language used to help create many of the websites
                  that you use everyday.
                </p>
              </div>
            </Section>
            <Section
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              id="advanced-path"
              className="m-4 py-6 flex flex-col justify-center">
              <div className="ml-3 mb-12">
                <span className="uppercase font-bold text-gray-700">
                  Advanced Learning Path
                </span>
                <span className="ml-12 italic text-gray-600 text-sm">
                  Recommended for grades 4-5
                </span>
              </div>
              <img
                className="ml-12"
                src="/images/learning-tools/LearningPath_Advanced.png"
                alt="how to graphics"
              />
            </Section>
            <Section
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              id="advanced-prerequisites">
              <h5 className="uppercase font-bold text-gray-700">
                Pre-requisites
              </h5>
              <p className="mt-4">
                Based on common core standards, child development research,
                executive function research.
              </p>
              <ul className="list-disc ml-6 mt-4">
                <li>Child can read (english)</li>
                <li>
                  Mental flexibility - Child has the ability to multi task and
                  switch efficiently between multiple subjects/stimuli.
                </li>
                <li>
                  Developed working memory - Child can remember and apply game
                  information/rules throughout play.
                </li>
              </ul>
              <div className="flex flex-col sm:flex-row justify-between mt-8 mb-16">
                <div>
                  <h5 className="uppercase font-bold text-gray-700 mb-2">
                    CONCEPTS COVERED
                  </h5>
                  <p>In this unit, learners will be introduced to:</p>
                  <ul className="list-disc ml-6">
                    <li>Object-Oriented Programming</li>
                    <li>Classes</li>
                    <li>Properties</li>
                    <li>Functions</li>
                    <li>JavaScript</li>
                  </ul>
                </div>
                <div className="flex-1 mt-6 md:mt-0 md:ml-6">
                  <h5 className="uppercase font-bold text-gray-700 mb-2">
                    Mindsets
                  </h5>
                  <ul className="list-disc ml-6">
                    <li>
                      Make sense of problems and persevere in solving them
                      (CCSS)
                    </li>
                    <li>Working Memory</li>
                    <li>Mental Flexibility</li>
                    <li>Critical Thinking</li>
                    <li>Decision Making</li>
                  </ul>
                </div>
              </div>
            </Section>
            <Section
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              id="advanced-descriptions">
              <h5 className="uppercase font-bold text-gray-700">
                Course Descriptions
              </h5>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  1 - Instance Station - Introduction to Object Oriented
                  Programming
                </h6>
                <p>
                  An introduction to object-oriented programming, style of
                  programming useful for structuring code into logical,
                  self-contained objects. Your child will be able to create new
                  objects from pre-made classes.{' '}
                  <span className="font-bold">*Note</span>: When they're ready
                  they can move on to properties.
                </p>
              </div>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  2 - Property Point - Introduction to Modifying Properties
                </h6>
                <p>
                  Each tower object has default properties from the class it was
                  created from. Learners unlock the ability to modify objects by
                  changing property values, like strength and speed.{' '}
                  <span className="font-bold">*Note</span>: When they're ready
                  they can move on to Functions.
                </p>
              </div>
              <div className="mb-8">
                <h6 className="font-bold underline mt-4">
                  3 - Function Island - Introduction to Functions in JavaScript
                </h6>
                <p>
                  In OOP, functions are specific actions that can be performed
                  by objects. Code inside a JavaScript function will execute
                  when something invokes (calls) the function. Your child will
                  unlock pre-written functions and apply those functions in a
                  strategic way.
                </p>
              </div>
            </Section>
            <Section
              id={'applied-knowledge'}
              activeSection={activeSection}
              setActiveSection={setActiveSection}>
              <h5 className="uppercase font-bold text-gray-700 mt-8">
                Applied Knowledge{' '}
                <span className="normal-case text-sm font-medium">
                  (Optional, but encouraged)
                </span>
              </h5>
              <p className="mb-8 mt-2 max-w-3xl">
                Kodable starts by building skills used in every programming
                language, as well as important logic and problem solving skills
                your child needs for their future.
              </p>
              <div className="ml-8">
                <div className="mb-8">
                  <h6 className="font-bold underline mt-4">
                    Fuzz Builder - Unlocks at Code Rank 5
                  </h6>
                  <p>
                    In Smeeborg, children learn that sequence is a series of
                    events executed step-by-step. Children apply this concept of
                    sequence in Fuzz builder, where they can also add items to a
                    fuzz step by step.
                  </p>
                </div>
                <div className="mb-8">
                  <h6 className="font-bold underline mt-4">
                    Maze Maker - Unlocks at Code Rank 10
                  </h6>
                  <p>
                    In Smeeborg, children learn how to write a program that will
                    move their fuzz through a maze. Children apply this
                    knowledge by constructing the maze level themselves, and
                    then writing the code that will move their fuzz through
                    their unique maze design.
                  </p>
                </div>
                <div className="mb-24">
                  <h6 className="font-bold underline mt-4">
                    Game Designer- Unlocks at Code Rank 15
                  </h6>
                  <p>
                    In Asteroidia, children learn that variables store values
                    that can change. Children apply this knowledge in Game
                    designer, where they see how modifying
                    values/variables/properties can change the output of a
                    program (or what the user sees).
                  </p>
                </div>
              </div>
            </Section>
          </div>
        </div>
        <div className="hidden w-full max-w-xs xl:flex flex-1 flex-shrink-0 border-l relative">
          <nav className="mt-20 sticky items-start top-56 h-80 w-full">
            <ul className="text-lg font-light">
              <ActiveNav activeSection={activeSection} section="intro">
                Introduction
              </ActiveNav>
              <ActiveNav activeSection={activeSection} section="beginner">
                Beginner: Foundations
              </ActiveNav>
              <ul
                className={`ml-6 ${
                  activeSection && activeSection.includes('beginner')
                    ? 'block'
                    : 'hidden'
                }`}>
                <ActiveNav
                  activeSection={activeSection}
                  section="beginner-path">
                  Learning Path
                </ActiveNav>
                <ActiveNav
                  activeSection={activeSection}
                  section="beginner-prerequisites">
                  Pre-requisites
                </ActiveNav>
                <ActiveNav
                  activeSection={activeSection}
                  section="beginner-descriptions">
                  Course Descriptions
                </ActiveNav>
              </ul>
              <ActiveNav activeSection={activeSection} section="intermediate">
                Intermediate: Play to Learn
              </ActiveNav>
              <ul
                className={`ml-6 ${
                  activeSection && activeSection.includes('intermediate')
                    ? 'block'
                    : 'hidden'
                }`}>
                <ActiveNav
                  activeSection={activeSection}
                  section="intermediate-path">
                  Learning Path
                </ActiveNav>
                <ActiveNav
                  activeSection={activeSection}
                  section="intermediate-prerequisites">
                  Pre-requisites
                </ActiveNav>
                <ActiveNav
                  activeSection={activeSection}
                  section="intermediate-descriptions">
                  Course Descriptions
                </ActiveNav>
              </ul>
              <ActiveNav activeSection={activeSection} section="advanced">
                Advanced: Build for the Future
              </ActiveNav>
              <ul
                className={`ml-6 ${
                  activeSection && activeSection.includes('advanced')
                    ? 'block'
                    : 'hidden'
                }`}>
                <ActiveNav
                  activeSection={activeSection}
                  section="advanced-path">
                  Learning Path
                </ActiveNav>
                <ActiveNav
                  activeSection={activeSection}
                  section="advanced-prerequisites">
                  Pre-requisites
                </ActiveNav>
                <ActiveNav
                  activeSection={activeSection}
                  section="advanced-descriptions">
                  Course Descriptions
                </ActiveNav>
              </ul>
            </ul>
          </nav>
        </div>
      </div>
    </Fragment>
  );
}

export default LearningPathPage;
