import React, { Fragment } from 'react';
import ShareButton from 'pages/videos/shared/ShareButton';
import { Link } from 'react-router-dom';
import {IResource} from "../../../types/IResource";


const videos: IResource[] = [
  {
    created_at: '2020-02-06T19:26:19.095Z',
    description:
      'What are computer programs and how to they impact the world around us? What is coding? Students ages 7-10 learn all about computer science basics.',
    file_type: 'video',
    id: 495,
    name: 'What are Computer Programs? Ages 7-10',
    resource_thumb_url: 'https://img.youtube.com/vi/m-cgqh_Ii04/mqdefault.jpg',
    resource_type: 'coding_concept_video',
    resource_url: 'https://youtu.be/m-cgqh_Ii04',
    unit_id: 36,
    updated_at: '2020-07-07T16:18:45.832Z',
    video_id: 'm-cgqh_Ii04',
  },
  {
    created_at: '2020-02-06T19:21:55.302Z',
    description:
      'What are computer programs and how to they impact the world around us? What is coding? Students ages 4-6 learn all about computer science basics.',
    file_type: 'video',
    id: 492,
    name: 'What are Computer Programs? Ages 4-6',
    resource_thumb_url: 'https://img.youtube.com/vi/J12LblSKz-0/mqdefault.jpg',
    resource_type: 'coding_concept_video',
    resource_url: 'https://youtu.be/J12LblSKz-0',
    unit_id: 36,
    updated_at: '2020-06-04T23:05:40.369Z',
    video_id: 'J12LblSKz-0',
  },
];

const WhatIsCodingSection: React.FC = () => {
  return (
    <Fragment>
      <div className="bg-kodable-teal bg-opacity-80 text-white py-4 px-4 font-bold">
        What is Coding?
      </div>
      <div className="relative mt-10 mx-auto max-w-screen-lg">
        {videos.map((video) => {
          return (
            <div
              className="flex px-8 pb-16 md:flex-row flex-col"
              key={video.id}>
              <div className="flex-1 relative">
                <Link
                  to={`/videos/coding-concepts/${video.id}`}
                  className="relative group hover:ring-4 rounded-2xl ring-kodable-orange focus:outline-none focus:ring-4 block">
                  <div className="absolute w-full h-full flex justify-center items-center cursor-pointer">
                    <svg
                      className="w-32 h-32 text-white opacity-75 group-hover:opacity-100 relative"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  {video.resource_thumb_url &&
                    <img
                      className="w-full rounded-2xl"
                      width="432"
                      height="243"
                      src={video.resource_thumb_url}
                      alt={video.name}
                    />
                  }
                </Link>
              </div>
              <div className="flex-1 pt-4 md:pt-0 md:px-4">
                <h4 className="text-3xl font-bold text-gray-700">
                  {video.name}
                </h4>
                <p className="text-gray-700 mt-2">{video.description}</p>
                <div className="flex justify-start mt-4">
                  <ShareButton video={video} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default WhatIsCodingSection;
