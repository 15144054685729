import { ISchool, SchoolsProvider, useSchoolsContext } from "context/SchoolsProvider"
import { GodmodePageWrapper } from "pages/godmode/GodmodePageWrapper"
import { Form, FormikProvider, useFormik } from "formik"
import { validationSchema, SchoolsIndexHeaderSearch } from "./SchoolsIndex"
import { Box, CircularProgress, Tab, Tabs, Typography } from "@material-ui/core"
import endpoints from "endpoints"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAddressCard, faChain, faEnvelope, faFastForward, faPlusCircle, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useSharedStyles from "components/useSharedStyles"
import Button from "components/ui/buttons/Button"
import { justFetch } from "mutations/mutate"
import { useAlert } from "context/AlertProvider"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { TabContext, TabPanel } from "@material-ui/lab"
import TextField from "components/ui/TextField"
import { ISchoolRequestsMatch, SchoolsRequestProvider, useSchoolRequestContext } from "context/SchoolsRequestProvider"

type SchoolRequestExistingSchoolCardProps = {
    school: ISchool | ISchoolRequestsMatch;
    linking: boolean;
    setLinking: Dispatch<SetStateAction<boolean>>;
}

export const SchoolsRequestsPage: React.FC = () => {
    return (
        <GodmodePageWrapper title="Add School Request" loading={false}>
            <SchoolsProvider>
                <SchoolsRequestsPageBody />
            </SchoolsProvider>
        </GodmodePageWrapper>
    )
}

const SchoolsRequestsPageBody: React.FC = () => {
    const { setSearch, setPage  } = useSchoolsContext()

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            search: "",
            page: 1,
            per_page: 100
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                if (values.search && values.search.length > 0) {
                    setPage(1)
                    setSearch(values.search)
                } else {
                    setSearch(null)
                }
            } catch (e) {
                
            }
        }
    })

    return (
        <FormikProvider value={form}>
            <SchoolsRequestProvider>
                <Box display="flex">
                    <Box width="50%" style={{paddingRight: '1rem'}}>
                        <SchoolsRequestsPageRequest />
                    </Box>
                    <Box width="50%">
                        <SchoolsRequestSearchTabs />
                    </Box>
                </Box>
            </SchoolsRequestProvider>
        </FormikProvider>
    )
}

const SchoolsRequestSearchTabs: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<string>('0')
    const sharedClasses = useSharedStyles()

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setSelectedTab(newValue);
    }
    
    return (
        <TabContext value={selectedTab}>
            <Tabs value={selectedTab} onChange={handleChange}>
                <Tab value="0" label="Suggested Schools" />
                <Tab value="1" label="All Schools" />
            </Tabs>
            <TabPanel value="0">
                <SchoolsRequestSuggestedSchools />
            </TabPanel>
            <TabPanel value="1" className={sharedClasses.vspacing2}>
                <Form>
                    <SchoolsIndexHeaderSearch />
                </Form>
                <SchoolsRequestsExisingSchools />
            </TabPanel>
        </TabContext>
            
    )
}

const SchoolsRequestSuggestedSchools: React.FC = () => {
    const { schoolRequest, loading } = useSchoolRequestContext()
    const sharedClasses = useSharedStyles()
    const [linking, setLinking] = useState<boolean>(false)
    const [search, setSearch] = useState<string>()
    const [filteredMatches, setFilteredMatches] = useState<ISchoolRequestsMatch[]>([])

    useEffect(() => {
        // This will reset the form any time the school request changes (from a skip, link, or delete, etc.)
        setSearch("")
    }, [schoolRequest?.matches])

    useEffect(() => {
        if (!schoolRequest?.matches) {
            return
        }

        // If there's a search, filter the results client-side
        if (search && search?.length > 0) {
            const filtered = schoolRequest?.matches?.filter((match) => {
                const matchValues = [match?.school_name, match?.school_location_address, match?.school_location_city, match?.school_location_state, match?.school_location_zip]
                return matchValues.some((value) => value?.toString().toLowerCase().includes(search.toLowerCase()))
            })
            setFilteredMatches(filtered)
            return
        }
        // Otherwise, just set the results unfiltered
        setFilteredMatches(schoolRequest?.matches)
    }, [schoolRequest?.matches, search])

    return (
        <Box className={sharedClasses.vspacing2}>
            <TextField
                name="suggested_schools_search"
                id="suggested_schools_search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                placeholder="Filter by name or address"
                label="Filter suggested schools"
                fullWidth={true}
                disabled={loading}
            />
            {loading &&
                <Box display="flex" alignItems="center" justifyContent="center" p={2} width="100%">
                    <CircularProgress />
                </Box>
            }
            {(!loading && filteredMatches && filteredMatches.length > 0) &&
                <>
                {filteredMatches?.map((match) => (
                    <SchoolRequestExistingSchoolCard 
                        key={match?.id}
                        school={match}
                        linking={linking}
                        setLinking={setLinking}
                    />
                ))}
                </>
            }
            {(!loading && filteredMatches && filteredMatches.length < 1) &&
                <Box display="flex" alignItems="center" justifyContent="center" p={2} width="100%">
                    <Typography variant="body1">No suggested schools found. Try searching in "All Schools"</Typography>
                </Box>
            }
        </Box>
    )
}

const SchoolsRequestsExisingSchools: React.FC = () => {
    const { schools, loading } = useSchoolsContext()
    const sharedClasses = useSharedStyles()
    const [linking, setLinking] = useState<boolean>(false)

    return (
        <Box className={sharedClasses.vspacing2}>
            {(schools.length < 1 || loading) &&
                <Box display="flex" alignItems="center" justifyContent="center" p={2} width="100%">
                    <CircularProgress />
                </Box>
            }
            {(schools.length > 0 && !loading) &&
                <>
                {schools?.map((school) => (
                    <SchoolRequestExistingSchoolCard
                        key={school?.id}
                        school={school}
                        linking={linking}
                        setLinking={setLinking}
                    />
                ))}
                </>
            }
        </Box>
    )
}

const SchoolRequestExistingSchoolCard: React.FC<SchoolRequestExistingSchoolCardProps> = ({school, linking, setLinking}) => {
    const { setSearch } = useSchoolsContext()
    const { schoolRequest, setShouldMutate } = useSchoolRequestContext()
    const sharedClasses = useSharedStyles()
    const alert = useAlert()
    
    const handleLink = () => {
        if (!schoolRequest) {
            return
        }
        setLinking(true)
        justFetch(endpoints.godmode.schoolRequestLink(schoolRequest?.school?.id), 'POST', {
            school_id: school?.id,
            teacher_id: schoolRequest?.school_teacher?.id
        })
        .then((res) => {
            if (res.ok) {
                alert.success("School linked successfully!")
                setShouldMutate(true)
                setSearch(null)
            } else {
                alert.error("School could not be linked")
            }
            setLinking(false)
        })
        .catch((e) => {
            console.log(e)
            alert.error("School could not be linked")
            setLinking(false)
        })
    }

    return (
        <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="white"
            p={2}
            border="1px solid #eee"
            borderRadius={4}
        >
            <Box className={sharedClasses.vspacing1} width="50%">
                <Typography variant="h2">{school?.school_name}</Typography>
                <Typography variant="body1">{school?.school_location_address}</Typography>
                <Typography variant="body1">
                    {school?.school_location_city}{school?.school_location_city ? ", " : ""}{school?.school_location_state} {(school?.school_location_zip && school?.school_location_zip > 0) ? school?.school_location_zip : ""}
                </Typography>
            </Box>
            <Box display="flex" width="50%" height="100%" alignItems="center" justifyContent="center">
                <Button
                    startIcon={<FontAwesomeIcon icon={faChain} />}
                    variant="contained"
                    color="blue"
                    onClick={handleLink}
                    disabled={linking}
                >
                    Link School
                </Button>
            </Box>
        </Box>
    )
}

const SchoolsRequestsPageRequest: React.FC = () => {
    const {schoolRequest, error, setShouldMutate, loading} = useSchoolRequestContext()
    const sharedClasses = useSharedStyles()
    const alert = useAlert()

    const handleDeleteSchoolRequest = () => {
        if (!schoolRequest?.school?.id) {
            return
        }
        justFetch(endpoints.godmode.schoolRequestDelete(schoolRequest?.school?.id), 'DELETE')
        .then((res) => {
            if (res.ok) {
                alert.success('School request deleted')
                setShouldMutate(true)
            } else {
                alert.error('School request could not be deleted')
            }
        })
        .catch((e) => {
            console.log(e)
            alert.error('School request could not be deleted')
        })
    }

    const handleSkipSchoolRequest = async () => {
        console.log("handleSkipSchoolRequest 1")
        if (!schoolRequest?.school?.id) {
            console.log("handleSkipSchoolRequest 2")
            return
        }
        console.log("handleSkipSchoolRequest 3")
        try
        {
            const res = await justFetch(endpoints.godmode.schoolRequestUpdate(schoolRequest?.school?.id), 'POST', {skip: true})
            if (!res.ok)
                throw new Error("bad school request update response")
            alert.success('School request marked skipped')
            setShouldMutate(true)
        }
        catch(e)
        {
            console.log(e)
            alert.error('School request could not be marked skipped')
        }
    }

    return (
        <Box>
        {error &&
            <Box display="flex" alignItems="center" justifyContent="center" p={2} width="100%">
                <Typography variant="body1">There was an error loading school requests</Typography>
            </Box>
        }
        {loading &&
            <Box display="flex" alignItems="center" justifyContent="center" p={2} width="100%">
                <CircularProgress />
            </Box>
        }
        {(schoolRequest && !error && !loading) && 
            <Box className={sharedClasses.vspacing2}>
                <Typography variant="h1">{schoolRequest?.school?.school_name}</Typography>
                <Box display="flex" alignItems="center" className={sharedClasses?.hspacing1}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <Typography variant="body1"><strong>Email:</strong> {schoolRequest?.school_teacher?.username}</Typography>
                </Box>
                <Box display="flex" alignItems="center" className={sharedClasses?.hspacing1}>
                    <FontAwesomeIcon icon={faAddressCard} />
                    <Typography variant="body1"><strong>Address:</strong> {schoolRequest?.school?.city}{schoolRequest?.school?.city ? ', ' : ''}{schoolRequest?.school?.state}</Typography>
                </Box>
                <Box display="flex" alignItems="center" className={sharedClasses?.hspacing1}>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                        variant="contained"
                        color="red"
                        onClick={handleDeleteSchoolRequest}
                    >
                        Delete
                    </Button>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faFastForward} />}
                        variant="contained"
                        color="orange"
                        onClick={() => {
                            //setShouldMutate(true)
                            handleSkipSchoolRequest();
                        }}
                    >
                        Skip
                    </Button>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                        variant="contained"
                        color="green"
                        onClick={() => {
                            setShouldMutate(true)
                        }}
                    >
                        Add New School
                    </Button>
                </Box>
            </Box>
            
        }
        {(!schoolRequest && !error && !loading) &&
            <Typography variant="body1">No school requests. All done! {`:)`}</Typography>
        }
        </Box>
    )
}