import { FormikProps } from "formik"
import useCurrentUser from "loaders/useCurrentUser"
import IAddNewClassRequest from "types/IAddNewClassRequest"
import React, { createContext, useContext, useEffect, useState } from "react"

export const AddClassFormContext = createContext<{ 
    addNewClassForm: FormikProps<IAddNewClassRequest>,
    canUseCleverImport: boolean,
    setCanUseCleverImport: React.Dispatch<React.SetStateAction<boolean>>,
    didImportCleverClasses: boolean,
    setDidImportCleverClasses: React.Dispatch<React.SetStateAction<boolean>> 
}>(null!)

export const AddClassFormProvider: React.FC<{addNewClassForm: FormikProps<IAddNewClassRequest>, children: React.ReactNode}> = ({ addNewClassForm, children }) => {
    const { currentUser } = useCurrentUser()
    const [canUseCleverImport, setCanUseCleverImport] = useState(false)
    const [didImportCleverClasses, setDidImportCleverClasses] = useState(false)

    useEffect(() => {
        if (!currentUser) {
            return
        }
        // All users with a School plan can use Clever import
        if (currentUser?.plan === 'School') {
            setCanUseCleverImport(true)
            return
        }
        // If they are a free user, they can still use Clever sync if their account was created before December 24, 2023, UTC
        const createdAt = new Date(currentUser?.created_at)
        const comparisonDate = new Date(Date.UTC(2023, 11, 24))
        if (createdAt < comparisonDate) {
            setCanUseCleverImport(true)
        }
    }, [currentUser])

    return (
        <AddClassFormContext.Provider value={{
            addNewClassForm: addNewClassForm,
            canUseCleverImport,
            setCanUseCleverImport: setCanUseCleverImport,
            didImportCleverClasses,
            setDidImportCleverClasses: setDidImportCleverClasses
        }}>
            {children}
        </AddClassFormContext.Provider>
    )
}

export const useAddClassFormContext = () => {
    const context = useContext(AddClassFormContext)
    if (!context) {
        throw new Error('useAddClassForm must be used within a AddClassFormProvider')
    }
    return context
}