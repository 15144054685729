import { Box, Typography } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import { SubmitOrderPageActions } from "./SubmitOrderPageActions";
import { SubmitOrderTextField } from "./SubmitOrderTextField";
import { useSubmitOrderFormHooks } from "./useSubmitOrderFormHooks";
import React from "react";

export const AddBillingContact: React.VFC = () => {
  const { allowNext } = useSubmitOrderFormHooks({
    validateFields: ['billing_contact_first_name', 'billing_contact_last_name', 'billing_contact_address_street', 'billing_contact_address_street2', 'billing_contact_address_city', 'billing_contact_address_province', 'billing_contact_address_code', 'billing_contact_address_country', 'billing_contact_email', 'billing_contact_phone']
  })

  return <>
    <Typography>Who is paying the invoice?</Typography>
    <BillingForm />
    <SubmitOrderPageActions allowPrev allowNext={allowNext} />
  </>
}

export const BillingForm: React.VFC = () => {
  const sharedClasses = useSharedStyles();

  return <Box className={sharedClasses.vspacing2}>
    <Box display="flex" className={sharedClasses.hspacing2} pb={2}>
      <Box flexGrow={1}>
        <SubmitOrderTextField
          field="billing_contact_first_name"
          label="First Name"
        />
      </Box>

      <Box flexGrow={1}>
        <SubmitOrderTextField
          field="billing_contact_last_name"
          label="Last Name"
        />
      </Box>
    </Box>
    <Box display="flex" className={sharedClasses.hspacing2} pb={2}>
      <Box flexGrow={1}>
        <SubmitOrderTextField
          field="billing_contact_email"
          label="Email"
        />
      </Box>
      <SubmitOrderTextField
        field="billing_contact_phone"
        label="Phone"
      />
    </Box>
    <SubmitOrderTextField
      field="billing_contact_address_street"
      placeholder="Line 1"
      label="Billing Address"
    />
    <SubmitOrderTextField
      field="billing_contact_address_street2"
      placeholder="Line 2"
    />
    <Box display="flex" className={sharedClasses.hspacing2}>
      <Box flexGrow={1}>
        <SubmitOrderTextField
          field="billing_contact_address_city"
          label="City"
        />
      </Box>
      <SubmitOrderTextField
        field="billing_contact_address_province"
        label="State/Province"
      />
      <SubmitOrderTextField
        field="billing_contact_address_code"
        label="Postal Code"
        size={8}
      />
    </Box>
    <SubmitOrderTextField
      field="billing_contact_address_country"
      label="Country"
    />
  </Box>
}