import {TableCell, TableRow, useTheme} from "@material-ui/core";
import {Link} from "react-router-dom";
import {routes} from "routes";
import {formatCurrency} from "pages/godmode/utils/numbers";
import UpcomingRenewalStatus from "./UpcomingRenewalStatus";
import { ISubscriptionChurned } from "./UpcomingRenewalsTable";
import React, { useMemo, useState } from "react";


const UpcomingRenewalsTableRow: React.FC<{subscription: ISubscriptionChurned, mutateRenewals: () => Promise<any>}> = ({subscription, mutateRenewals}) => {
  const [color, setColor] = useState<'green' | 'red' | 'orange' | 'blue' | 'transparent'>('transparent')
  const theme = useTheme()

  useMemo(() => {
    switch (subscription?.last_purchase_process?.state) {
      case 'renewal_commit':
        setColor('green')
        break
      case 'renewal_deciding':
        setColor('orange')
        break
      case 'renewal_declined':
        setColor('red')
        break
      case 'renewal_contacted':
        setColor('blue')
        break
      default:
        setColor('transparent')
    }
  }, [subscription])

  return (
    <TableRow
      style={{
        backgroundColor: color !== 'transparent' ? theme.palette[color].main : 'transparent'
      }}
    >
      <TableCell>
        <Link
          style={{color: theme.palette.blue.main}}
          to={routes?.godmode?.organizationProfiles.view(subscription?.organization_profile?.id)}
        >{subscription?.organization_profile?.display_name}
        </Link>
      </TableCell>
      <TableCell>{formatCurrency(subscription?.expired?.contract_amount)}</TableCell>
      <TableCell>{subscription?.teacher?.username}</TableCell>
      <TableCell>{subscription?.teacher?.name}</TableCell>
      <TableCell>{subscription?.organization_profile?.billing_contact_email}</TableCell>
      <TableCell>{subscription?.organization_profile?.billing_contact_name}</TableCell>
      <TableCell>
        <UpcomingRenewalStatus
          mutateRenewals={mutateRenewals}
          purchaseProcess={subscription?.last_purchase_process}
        />
      </TableCell>
    </TableRow>
  )
}

export default UpcomingRenewalsTableRow