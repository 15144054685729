import useDialogState from 'hooks/useDialogState'
import { SubscriptionChangeDatesDialog } from './SubscriptionChangeDatesDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/ui/buttons/Button'
import React from "react";

export const SubscriptionChangeDatesButton: React.FC<{subscriptionId: number, dateType: 'start' | 'end', onSuccess: (subscriptionId: number, newDate: string) => void}> = ({onSuccess, subscriptionId, dateType}) => {
    const dialogState = useDialogState()
    
    return (
        <>
            <SubscriptionChangeDatesDialog onSuccess={onSuccess} subscriptionId={subscriptionId} dateType={dateType} {...dialogState} />
            <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => dialogState.handleOpen()}
            >
                <FontAwesomeIcon icon={faPencil} />
            </Button>
        </>
    )
}