import { Box, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import PageHeader from "components/ui/PageHeader";
import useSharedStyles from "components/useSharedStyles";
import { useTracking } from "context/TrackingProvider";
import useDialogState from "hooks/useDialogState";
import ViewAsStudentDialog from "components/dialogs/student/ViewAsStudentDialog";
import React, { useEffect, useState } from "react"
import { useIsSafari } from "./isSafari";


const DemoInstructionsIndex: React.VFC = () => {
  const viewAsStudentDialogState = useDialogState();
  const isSafari = useIsSafari();
  const [tracked, setTracked] = useState<boolean>(false)
  const { track } = useTracking()

  useEffect(() => {
    if (tracked) {
      return
    }
    track('Viewed Student Demo', {});
    setTracked(true)
  }, [])

  return <Box p={4} height="100%" display="flex" flexDirection="column" alignItems="center">
    <ViewAsStudentDialog
      {...viewAsStudentDialogState}
      title="Teacher Demo Mode"
      href={`${process.env.REACT_APP_GAME_ENDPOINT}/teacher-demo`}
    />
    <img src="/images/teacher_demo_mode.png" alt="" style={{ maxHeight: 200 }} />
    <DemoInstructions />
    <Box mt={2}>
      <Button
        variant="contained"
        color="primary"
        href={isSafari ? `${process.env.REACT_APP_GAME_ENDPOINT}/teacher-demo` : undefined}
        onClick={isSafari ? undefined : viewAsStudentDialogState.handleOpen}
        {...{
          target: '_blank'
        }}
      >Play!</Button>
    </Box>
  </Box>
}

export const DemoInstructions: React.VFC = () => {
  const sharedClasses = useSharedStyles();

  return <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" alignItems="center">
    <PageHeader title="Teacher Demo Mode"></PageHeader>
    <Typography paragraph>This mode is for evaluation and planning purposes.</Typography>
    <ul className="list-disc list-inside">
      <li>All content is unlocked</li>
      <li>Progress will not be saved</li>
    </ul>
  </Box>
}

export default DemoInstructionsIndex;