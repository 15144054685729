import useSharedStyles from "../../../components/useSharedStyles";
import {Box, Grid, Paper, Typography} from "@material-ui/core";
import ReactImageFallback from "react-image-fallback";
import React from "react";

interface StudentWorkCardProps {
  cardImageSrc: string;
  cardImageFallbackSrc: string;
  cardTitle?: string;
  cardContent?: string;
  maxWidth?: number;
  onClick: () => void;
  handleFuzzLoaded?: (loaded: boolean) => void;
}

const StudentWorkCard: React.VFC<StudentWorkCardProps> = ({
                                                                   cardImageSrc,
                                                                   cardImageFallbackSrc,
                                                                   cardTitle,
                                                                   cardContent,
                                                                   maxWidth,
                                                                   onClick,
                                                                   handleFuzzLoaded
                                                                 }) => {
  const sharedClasses = useSharedStyles();

  return <Box component={Grid} {...{item: true}} width={280} display="flex" flexDirection="column" alignItems="center" onClick={onClick}>
    <Paper variant="outlined" style={{width: '100%'}} className={sharedClasses.hoverCursorPointer}>
      <Box overflow="hidden" maxHeight={212}>
        <Box display="flex" justifyContent="center">
          <ReactImageFallback
            src={cardImageSrc}
            fallbackImage={cardImageFallbackSrc}
            onError={() => {
              if (typeof handleFuzzLoaded === 'undefined') {
                return
              }
              handleFuzzLoaded(false)
            }}
            style={{
              width: '100%',
              maxWidth
            }}
          />
        </Box>
      </Box>
      <Box p={2} textAlign="center">
        <Typography variant="subtitle1">{cardTitle}</Typography>
        <Typography variant="body2">{cardContent}</Typography>
      </Box>
    </Paper>
  </Box>
}

export default StudentWorkCard