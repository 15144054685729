import React, { Fragment } from 'react';
import TopNav from '../shared/TopNav';
import {
  AsteroidiaProject,
  FuzzBuilderProject,
  MazeMakerProject,
} from './shared/Projects';

const SmeeborgCreativeProjects: React.FC = () => {
  return (
    <Fragment>
      <TopNav
        title="Smeeborg"
        navTitle="Creative Projects"
        guideImage="/images/teacher-guides/smeeborg/Smeeborg.jpg"
        navLinks={[
          { title: 'Learning Tools', to: '/learning-tools' },
          { title: 'Smeeborg', to: '/learning-tools/smeeborg' },
          {
            title: 'Creative Projects',
            to: '/learning-tools/creative-projects/smeeborg',
          },
        ]}
      />
      <div className="flex flex-col justify-center items-center w-full">
        <div className="my-8 w-full max-w-screen-lg px-2">
          <MazeMakerProject />
          <FuzzBuilderProject />
          <AsteroidiaProject />
        </div>
      </div>
    </Fragment>
  );
};

export default SmeeborgCreativeProjects;
