import {Box} from "@material-ui/core";
import React from "react";

const OnboardingHeader: React.VFC = () => {
  return (
    <Box display={'flex'} style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      paddingTop: 20,
      backgroundColor: 'white',
      boxShadow: '0 0 4px 4px rgba(0,0,0,0.2)'
    }} justifyContent={'center'} alignContent={'center'}>
      <img src={'/images/logos/kodable-edu-color.svg'} width={350} style={{marginBottom: 20}}/>
    </Box>
  )
}

export default OnboardingHeader