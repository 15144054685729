import React, {useLayoutEffect, useState} from "react";
import {IGodmodeRevenueStats} from "types/IGodmodeRevenueStats";
import {justFetch} from "mutations/mutate";
import endpoints from "endpoints";
import {Box, Tab, Tabs, Typography, useTheme} from "@material-ui/core";
import {formatCurrency} from "pages/godmode/utils/numbers";
import {TabContext} from "@material-ui/lab";
import UpcomingRenewalsTable from "pages/godmode/Subscriptions/upcoming-renewals/UpcomingRenewalsTable";
import OrganizationProfilesTable from "./OrganizationProfilesTable";

type OrganizationProfilesIndexTabOptions = 'organization_profiles' | 'upcoming';

const OrganizationProfilesIndexTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<OrganizationProfilesIndexTabOptions>('organization_profiles')
  const [revenueStats, setRevenueStats] = useState<IGodmodeRevenueStats>();
  const theme = useTheme()
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: OrganizationProfilesIndexTabOptions) => {
    setSelectedTab(newValue);
  }

  useLayoutEffect(() => {
    const getData = async () => {
      const data = await justFetch(endpoints.godmode.revenueStats, 'GET');
      const payload: IGodmodeRevenueStats = await data.json();
      setRevenueStats(payload);
    }

    getData();
  }, [])

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
        {revenueStats?.subscriptions &&
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h1"
              style={{color: theme.palette.green.main}}
            >
              <strong>{revenueStats?.subscriptions.school.total} Total Subscriptions</strong>
            </Typography>
            <Typography>{revenueStats?.subscriptions.school.premium} Premium</Typography>
            <Typography>{revenueStats?.subscriptions.school.premium_plus} Premium+</Typography>
          </Box>
        }
        {revenueStats?.arr &&
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h1"
              style={{color: theme.palette.green.main}}
            >
              <strong>{revenueStats?.arr.total ? formatCurrency(revenueStats?.arr.total) : '$0'}</strong>
            </Typography>
            <Typography>Annual Recurring Revenue</Typography>
          </Box>
        }
        {revenueStats?.current_revenue &&
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
                variant="h1"
                style={{color: theme.palette.green.main}}
            >
              <strong>{revenueStats?.current_revenue.monthly.total ? formatCurrency(revenueStats?.current_revenue.monthly.total) : '$0'}</strong>
            </Typography>
            <Typography>Monthly Recurring Revenue</Typography>
          </Box>
        }
      </Box>
      <TabContext value={selectedTab}>
        <Tabs value={selectedTab} onChange={handleChange} style={{backgroundColor: 'transparent'}}>
          <Tab label="Organizations" value="organization_profiles"/>
          <Tab label="Upcoming Renewals" value="upcoming"/>
        </Tabs>
      </TabContext>
      {selectedTab === 'organization_profiles' && < OrganizationProfilesTable/>}
      {selectedTab === 'upcoming' && <UpcomingRenewalsTable/>}
    </>
  )
}

export default OrganizationProfilesIndexTabs