import './index.css';

import ReactDOM from 'react-dom';

import AppContexts from 'AppContexts';
import AppRoutes from 'AppRoutes';

import queryString from 'query-string';
import Cookies from 'universal-cookie';

if (window.location.protocol === 'http:' && !process.env.REACT_APP_LOCAL) {
    const secureURL = window.location.href.replace('http:', 'https:');
    window.location.href = secureURL;
}

const parsedQueryParams = queryString.parse(window.location.search);
const session_token = parsedQueryParams?.session_token

if (session_token) {
  delete parsedQueryParams.session_token;
  const newUrl = `${window.location.origin}${window.location.pathname}?${queryString.stringify(parsedQueryParams)}`;
  window.location.href = newUrl

  const domainURL= process.env.REACT_APP_LOCAL? 'localhost': '.kodable.com';

  new Cookies().set('kodable_kode', session_token, {
    domain: domainURL,
    path: '/'
  });
}

ReactDOM.render(
  <AppContexts>
    <AppRoutes />
  </AppContexts>,
  document.getElementById('react-root')
);