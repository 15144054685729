import { Button as MatButton, ButtonProps as MatButtonProps, makeStyles, PropTypes } from '@material-ui/core';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import {customColors, CustomColorType} from 'context/DashboardThemeProvider';
import React, { forwardRef } from 'react';

type ButtonBaseProps = Pick<MatButtonProps,
  'variant' | 'disableElevation' | 'disableRipple' | 'disableFocusRipple' |
  'ref' | 'onClick' | 'size' | 'disabled' | 'style' | 'type' | 'startIcon' |
  'endIcon' | 'href' | 'classes' | 'className' | 'formTarget' | 'fullWidth' |
  'children'
>

type ButtonColorType = PropTypes.Color | CustomColorType

export interface ButtonProps extends ButtonBaseProps {
  /**
   * The color to use.
   * Colors specified in DashboardThemeProvider
   */
  color?: ButtonColorType
  /**
   * The target to use.
   */
  target?: '_blank' | '_self' | '_parent' | '_top';
}

const useContainedButtonStyles = makeStyles(theme => {
  const styles: { [key in CustomColorType]: CSSProperties } = {} as any;
  for (let color of customColors) {
    const colorOptions = theme.palette[color as keyof Palette] as PaletteColor;

    styles[color] = {
      color: '#ffffff',
      backgroundColor: colorOptions.main,
      '&:hover': {
        backgroundColor: colorOptions.dark
      }
    };
  }
  return styles;
});

const useOutlinedButtonStyles = makeStyles(theme => {
  const styles: { [key in CustomColorType]: CSSProperties } = {} as any;
  for (let color of customColors) {
    const colorOptions = theme.palette[color as keyof Palette] as PaletteColor;

    styles[color] = {
      color: colorOptions.main,
      borderColor: colorOptions.main
    };
  }
  return styles;
});

const Button: React.FC<ButtonProps> = forwardRef(({ children, color='default', ...props }, ref) => {
  const containedClasses = useContainedButtonStyles(props);
  const outlinedClasses = useOutlinedButtonStyles(props);
  let colorClassname;

  if (customColors.includes(color as CustomColorType)) {
    if (props.variant === 'contained') {
      colorClassname = containedClasses[color as CustomColorType];
    } else if (props.variant === 'outlined') {
      colorClassname = outlinedClasses[color as CustomColorType];
    }
  }

  return (
    <MatButton
      ref={ref}
      {...props}
      color={colorClassname === undefined ? color as MatButtonProps['color'] : undefined}
      className={classNames(props.className, colorClassname)}
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      {children}
    </MatButton>
  );
});

export default Button;