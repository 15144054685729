import { Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import { Alert } from "@material-ui/lab";
import classNames from "classnames";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";

import { useFormik } from 'formik';
import { justFetch } from "mutations/mutate";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { DialogStateProps } from "hooks/useDialogState";
import { ITeacher } from "types/ITeacher";
import { useAlert } from "context/AlertProvider";

const EditPasswordDialog: React.FC<DialogStateProps  & { teacher?: ITeacher }> = ({ open = false, onClose = () => {}, teacher }) => {
  const sharedClasses = useSharedStyles();
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const alert = useAlert();

  const form = useFormik({
    initialValues: {
      password: '',
      password2: ''
    },
    validationSchema: Yup.object({
      password: Yup
        .string()
        .required('Please enter a password with at least 8 characters')
        .min(8, 'Please enter a password with at least 8 characters'),
      password2: Yup
        .string()
        .required('Please enter your password again.')
        .equals([Yup.ref('password')], "The password you entered doesn't match the one you entered above.")
    }),
    onSubmit: values => {
      return justFetch(teacher ? endpoints.teacher(teacher.id) : endpoints.password, teacher ? 'PUT' : 'POST', { password: values.password })
        .then(async res => {
          const body = await res.json();
          if (!res.ok) {
            if (res.status === 422) {
              form.setFieldError('username', body.message);
            } else {
              setSubmitError(true);
            }
          } else {
            onClose();
            alert.success('Password Updated');
          }
        })
        .catch(() => setSubmitError(true))
    }
  });

  useEffect(() => {
    if (open) {
      form.handleReset(null);
      setShowValidationMessage(false);
      setSubmitError(false);
    }
  // eslint-disable-next-line
  }, [open]);

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent className={classNames(sharedClasses.vspacing2)}>
        <label htmlFor="name">
          <Typography
            variant="subtitle1"
          >New Password</Typography>
        </label>
        <TextField
          value={form.values.password}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={form.errors.password}
          error={!!form.errors.password}
          disabled={form.isSubmitting}
          id="password"
          type="password"
          variant="outlined"
          fullWidth
          margin="dense"
        />

        <label htmlFor="email">
          <Typography
            variant="subtitle1"
          >Confirm New Password</Typography>
        </label>
        <TextField
          value={form.values.password2}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={form.touched.password2 ? form.errors.password2 : ''}
          error={form.touched.password2 && !!form.errors.password2}
          disabled={form.isSubmitting}
          id="password2"
          type="password"
          variant="outlined"
          fullWidth
          margin="dense"
        />
        {!form.isValid && showValidationMessage && <Alert severity="error">Please correct the errors above to continue.</Alert>}
        {submitError && <Alert severity="error" action={
          <Button
            color="inherit"
            size="small"
            onClick={() => form.submitForm()}
          >Try again</Button>
        } >There was an error trying to submit this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={onClose}
            type="reset"
            disabled={form.isSubmitting}
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => { setShowValidationMessage(true); }}
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            disabled={form.isSubmitting}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </form>
  </Dialog>
}

export default EditPasswordDialog;