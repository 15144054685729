import {Box} from "@material-ui/core";
import RoundIcon from "../RoundIcon";
import React from "react";

const LessonSubSection: React.VFC<{icon: string, iconColor: string, title: string, body?: string}> = ({icon, iconColor, body, title}) => {
  return (
    <Box display={'flex'} style={{marginTop: 20, marginBottom: 20}}>
      {(icon && iconColor) &&
        <Box marginRight={2}>
          <RoundIcon color={iconColor} imageUrl={icon} size={40}/>
        </Box>
      }
      <Box>
        <Box width={'100%'}>
          <div dangerouslySetInnerHTML={{__html: title || ""}} style={{fontWeight: 600, fontSize: 16, marginTop: 10}}/>
          <div dangerouslySetInnerHTML={{__html: body || ""}} className={'curriculum'}/>
        </Box>
      </Box>
    </Box>
  )
}
export default LessonSubSection