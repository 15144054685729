import { useState } from 'react';
import { Link } from 'react-router-dom';
import {IUserProfile} from "../../types/IUserProfile";
import useSWR from 'swr';
import endpoints from "endpoints";

interface HomePromotionBannerProps {
  currentUser: IUserProfile;
}

export default function HomePromotionBanner({ currentUser }: HomePromotionBannerProps) {
  const [dismissed, setDismissed] = useState(false);
  const { data: pricing } = useSWR(endpoints?.parentSubscriptionPricing)

  if (
    dismissed ||
    currentUser?.plan !== 'Free' ||
    localStorage.getItem(`kodable:${currentUser.id}:homeBannerDismissed`)
  ) {
    return null;
  }

  return (
    <div
      style={{ backgroundColor: '#CAE9E7' }}
      className="flex md:flex-row flex-col-reverse mb-12 rounded-xl text-gray-800 relative">
      <button
        onClick={() => {
          setDismissed(true);
          localStorage.setItem(
            `kodable:${currentUser.id}:homeBannerDismissed`,
            'true'
          );
        }}
        className="absolute top-1 left-1 p-2 z-20">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          className="w-4 h-4"
          fill="currentColor">
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div className="flex-1 pt-8 px-4 lg:px-8">
        <h3 className="text-lg font-bold">
          Help your child continue learning with Kodable Pro
        </h3>
        <p className="my-4">
          Access hundreds of activities and games that complement their learning
          at school. You'll get tools to inspire a love of problem solving and
          creativity.
        </p>
        <div className="flex justify-between items-center mt-8 lg:mt-12 pb-4">
          <p className="text-xs flex-1">
            Get started {pricing? "for less than $" + Math.ceil(pricing.yearly / 12) + "/month" : " with a great deal"}
          </p>
          <div className="flex-1 flex justify-end items-center">
            <Link
              className="bg-kodable-purple text-white rounded px-4 py-2 text-sm"
              to={{ pathname: "/parent/upgrade/info", state: { showUI: true } }}
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>
      <img
        alt="smeeborg_map"
        className="relative w-full object-cover h-48 rounded-t-xl md:rounded-t-none  md:rounded-r-xl md:max-w-xs md:h-full"
        src="/images/parents/smeeborg_map.png"
      />
    </div>
  );
}
