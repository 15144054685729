import mutate from './mutate';
import {IUserProfile} from "../types/IUserProfile";
import {PaymentMethod} from "@stripe/stripe-js";
import endpoints from "../endpoints";

type config = {
  payment_method_id?: string | PaymentMethod,
  payment_intent_id?: string,
  captcha_token: string,
  user: IUserProfile,
  amount: number,
  plan: string,
  coupon: string,
  platform: string
}

const usePurchaseParentSubscription = (userId: number) => {
  return (args: config) => {
    return mutate(
      endpoints.purchaseParentSubscription(userId),
      'POST',
      args
    );
  };
}

export default usePurchaseParentSubscription
