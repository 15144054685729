import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography, useTheme } from "@material-ui/core"
import { IProcessedDetailsMonth } from "./UpcomingRenewalsTable"
import UpcomingRenewalsTableRow from "./UpcomingRenewalsTableRow"
import { formatCurrency } from "pages/godmode/utils/numbers"
import useSharedStyles from "components/useSharedStyles"
import Button from "components/ui/buttons/Button"
import React, { useState } from "react"
import UpcomingRenewalsRenewedTableRow from "./UpcomingRenewalsRenewedTableRow"

const UpcomingRenewalsTableMonthTable: React.FC<{month: IProcessedDetailsMonth, mutateRenewals: () => Promise<any>}> = ({month, mutateRenewals}) => {
    const [showRenewed, setShowRenewed] = useState(false)
    const sharedClasses = useSharedStyles()
    const theme = useTheme()

    const handleToggleRenewed = () => {
        setShowRenewed(!showRenewed)
    }
    
    return (
        <Box className={sharedClasses?.vspacing2}>
            <Typography variant="h5">{month?.prettyDate} Churned Subscriptions</Typography>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Box>
                    <Typography
                        variant="h5"
                        style={{color: theme.palette.green.main}}
                    >
                        <strong>{month?.churnedPercent}% {`(${month?.churnCount} / ${month?.expiringSubs})`}</strong>
                    </Typography>
                    <Typography variant="body2">Subscriptions Churned</Typography>
                </Box>
                <Box
                    style={{
                        textAlign: 'right'
                    }}
                >
                    <Typography
                        variant="h5"
                        style={{color: theme.palette.green.main}}
                    >
                        <strong>{month?.churnedDollarsPercent}% {`(${formatCurrency(month?.churnAmount)} / ${formatCurrency(month?.expiringAmount)})`}</strong>
                    </Typography>
                    <Typography variant="body2">
                        <span>Dollars Churned </span>
                        <span>{`(${month?.churnedDollarsPercentWithRenewalIncrease}% with renewal increase, ${formatCurrency(month?.churnedDollarsWithRenewalIncrease)} churned)`}</span>
                    </Typography>
                </Box>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell>Organization Name</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Admin Email</TableCell>
                        <TableCell>Admin Name</TableCell>
                        <TableCell>Billing Contact Email</TableCell>
                        <TableCell>Billing Contact Name</TableCell>
                        <TableCell>Renewal Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {month?.churned.map((subscription, index) => {
                            return (
                            <UpcomingRenewalsTableRow
                                subscription={subscription}
                                mutateRenewals={mutateRenewals}
                                key={index}
                            />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                variant="contained"
                color="blue"
                onClick={() => handleToggleRenewed()}
            >
                {showRenewed ? "Hide" : "Show"} Renewed Subs
            </Button>
            {showRenewed &&
                <Box className={sharedClasses?.vspacing2}>
                    <Typography variant="h5">{month?.prettyDate} Renewed Subscriptions</Typography>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '2rem'
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                style={{color: theme.palette.green.main}}
                            >
                                <strong>{formatCurrency(month.renewalIncreaseAmount)}</strong>
                            </Typography>
                            <Typography variant="body2">Renewal Increase</Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h5"
                                style={{color: theme.palette.newOrange.main}}
                            >
                                <strong>{formatCurrency(month.unpaidAmount)}</strong>
                            </Typography>
                            <Typography variant="body2">Delinquent Amount</Typography>
                        </Box>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Paid Access</TableCell>
                                    <TableCell>Old License Count</TableCell>
                                    <TableCell>Old Type</TableCell>
                                    <TableCell>Old Amount</TableCell>
                                    <TableCell>New License Count</TableCell>
                                    <TableCell>New Type</TableCell>
                                    <TableCell>New Amount</TableCell>
                                    <TableCell>Admin</TableCell>
                                    <TableCell>Admin Last Active</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {month?.renewals.map((subscription, index) => {
                                    return (
                                    <UpcomingRenewalsRenewedTableRow
                                        subscription={subscription}
                                        key={index}
                                    />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            }
      </Box>
    )
}

export default UpcomingRenewalsTableMonthTable