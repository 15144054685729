import { FormControl, FormHelperText, Select as MatSelect, SelectProps as MatSelectProps, Typography } from "@material-ui/core";
import {omit} from "../../utils/omit";
import React from "react";

const Select: React.FC<MatSelectProps & { helperText?: string | undefined }> = ({ children, label, ...props }) => {
  return <FormControl
    error={props.error}
    fullWidth={props.fullWidth}
  >
    {label && <label htmlFor={props.id}>
      <Typography
        variant="subtitle1"
      >{label}</Typography>
    </label>}
    <MatSelect
      variant="outlined"
      margin="dense"
      {...omit(props, ['helperText'])}
    >
      {children}
    </MatSelect>
    {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
  </FormControl>
}

export default Select