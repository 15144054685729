import React, { useState } from 'react';
import Modal from 'components/modal/Modal';
import { useAlertDispatch } from 'context/AlertProvider';
import { useHistory } from 'react-router-dom';
import useCancelSubscription from 'mutations/useCancelSubscription';
import useSubscription from 'loaders/useSubscription';

interface ModalConfirmCancellationProps {
  reason: string;
  closeModal: () => void;
  currentUser: { id: number };
}

const ModalConfirmCancellation: React.FC<ModalConfirmCancellationProps> = ({ reason, closeModal, currentUser }) => {
  const [cancelling, setCancelling] = useState(false);
  const history = useHistory();
  const dispatch = useAlertDispatch();
  const cancelSubscription = useCancelSubscription(currentUser.id);
  const { mutate } = useSubscription();

  return (
    <Modal closeModal={closeModal}>
      <div className="flex flex-col items-center pb-4 pt-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          className="text-yellow-300 w-48 h-48"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <div className="text-3xl font-bold text-gray-800">
          Confirm Cancellation
        </div>
        <div className="font-light py-6 text-gray-600 text-center max-w-sm">
          Please confirm that you would like to cancel your subscription. You
          will still have access till the end of your current subscription.
        </div>
        <div className="flex justify-center items-center">
          <button
            onClick={closeModal}
            type="button"
            className="flex items-center justify-center text-gray-500 border border-gray-200 bg-white hover:bg-gray-100 active:bg-gray-200 px-4 py-2 rounded px-6 py-3 text-lg rounded mr-6">
            Cancel
          </button>
          <button
            onClick={() => {
              setCancelling(true);
              cancelSubscription({ reason })
                .then((data) => {
                  if (data.id) {
                    dispatch({
                      id: 'cancelSubscription',
                      type: 'success',
                      title: 'Subscription Cancelled!',
                      message: 'Subscription was cancelled successfully.',
                    });
                    setTimeout(() => {
                      dispatch({
                        type: 'removeAlert',
                        id: 'cancelSubscription',
                      });
                    }, 3000);
                    mutate().then(() => {
                      setCancelling(false);
                      closeModal();
                      history.push('/settings');
                    });
                  }
                })
                .catch((err) => {
                  setCancelling(false);
                  dispatch({
                    id: 'subscription_cancel_error',
                    type: 'error',
                    title: 'Subscription was not Cancelled!',
                    message: 'There was an error cancelling your subscription.',
                  });
                });
            }}
            disabled={cancelling}
            type="button"
            className="bg-kodable-red hover:bg-kodable-red-dark active:bg-kodable-red-darker px-6 py-3 text-lg rounded text-white disabled:opacity-50 disabled:cursor-not-allowed">
            {cancelling ? 'Cancelling...' : 'Cancel Subscription'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirmCancellation;
