import { useAlert } from "context/AlertProvider"
import endpoints from "endpoints"
import { useFormik, FormikProvider } from "formik"
import { justFetch } from "mutations/mutate"
import { GodmodePageWrapper } from "pages/godmode/GodmodePageWrapper"
import { validationSchema } from "./SchoolsAddPage"
import { SchoolsForm } from "./SchoolsAddPage"
import useSWR from "swr"
import {useHistory, useRouteMatch} from "react-router-dom"
import { IRouteParams } from "AppRoutes"
import {routes} from "../../../../routes";
import React from "react";

export interface ISchoolEditRequest {
    id: number;
    agency_name?: string;
    school_nces_code?: number;
    school_nces_status?: number;
    ansi_state_code?: string;
    lea_id?: string;
    state_agency_id?: string;
    nces_id: string;
    nces_school_id: number;
    state_school_id: string;
    school_name: string;
    school_phone: string;
    school_mailing_address: string;
    school_mailing_city: string;
    school_mailing_state: string;
    school_mailing_zip: number;
    school_mailing_zip4: number;
    school_location_address: string;
    school_location_city: string;
    school_location_state: string;
    school_location_zip: number;
    school_location_zip4: number;
    nces_locale_code: string | null;
    school_latitude: string;
    school_longitude: string;
    school_ansi_county_num: string | null;
    school_county_name: string | null;
    school_low_grade: number;
    school_high_grade: number;
    school_grade_level: string;
    is_charter: boolean;
    is_public: boolean;
    created_at: string;
    updated_at: string;
    latitude: string;
    longitude: string;
    ll_point: string;
    district_id: number;
}


export const SchoolsEditPage: React.FC = () => {
    const alert = useAlert()
    const { params } = useRouteMatch<IRouteParams>();
    const {data: school, isValidating } = useSWR<ISchoolEditRequest>(endpoints.godmode.schoolById(params?.schoolId))
    const history = useHistory()

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: school?.id ?? 0,
            agency_name: school?.agency_name ?? '',
            school_nces_code: school?.nces_locale_code ?? '',
            school_nces_status: school?.school_nces_status ?? '',
            ansi_state_code: school?.school_nces_code ?? '',
            lea_id: school?.lea_id ?? '',
            state_agency_id: school?.state_agency_id ?? '',
            nces_id: school?.nces_id ?? '',
            nces_school_id: school?.nces_school_id ?? '',
            state_school_id: school?.state_school_id ?? '',
            school_name: school?.school_name ?? '',
            school_phone: school?.school_phone ?? '',
            school_mailing_address: school?.school_mailing_address ?? '',
            school_mailing_city: school?.school_mailing_city ?? '',
            school_mailing_state: school?.school_mailing_state ?? '',
            school_mailing_zip: school?.school_mailing_zip ?? '',
            school_mailing_zip4: school?.school_mailing_zip4 ?? '',
            school_location_address: school?.school_location_address ?? '',
            school_location_city: school?.school_location_city ?? '',
            school_location_state: school?.school_location_state ?? '',
            school_location_zip: school?.school_location_zip ?? '',
            school_location_zip4: school?.school_location_zip4 ?? '',
            nces_locale_code: school?.nces_locale_code ?? '',
            school_latitude: school?.school_latitude ?? '',
            school_longitude: school?.school_longitude ?? '',
            school_ansi_county_num: school?.school_ansi_county_num ?? '',
            school_county_name: school?.school_county_name ?? '',
            school_low_grade: school?.school_low_grade ?? '',
            school_high_grade: school?.school_high_grade ?? '',
            school_grade_level: school?.school_grade_level ?? '',
            is_charter: school?.is_charter ?? false,
            is_public: school?.is_public ?? true,
            created_at: school?.created_at ?? '',
            updated_at: school?.updated_at ?? '',
            latitude: school?.latitude ?? '',
            longitude: school?.longitude ?? '',
            ll_point: school?.ll_point ?? '',
            district_id: school?.district_id ?? '',
            mailing_address_different: school?.school_location_address !== school?.school_mailing_address
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                actions.setSubmitting(true)
                if (!school?.id) {
                    return
                }
                justFetch(endpoints.godmode.schoolById(school?.id), 'PUT', {...values})
                .then((res) => {
                    if (res.ok) {
                        alert.success("School updated successfully!")
                        return
                    }
                    alert.error("School could not be updated due to an error")
                    actions.setSubmitting(false)
                })
            } catch (e) {
                console.log(e)
                alert.error("School could not be updated due to an error.")
                actions.setSubmitting(false)
            }
        }
    })
    return (
        <GodmodePageWrapper title={`Edit School - ${school?.school_name}`} loading={isValidating}>
            <FormikProvider value={form}>
                <SchoolsForm isEditing={true} onCancel={() => history.push(routes.godmode.schools.index)} />
            </FormikProvider>
        </GodmodePageWrapper>
    )
}