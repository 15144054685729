import { IQuote } from "../IQuote";

const RENUMBER_CUT_OVER_THRESHOLD = 1121;

export const getQuoteNumber = (quote: IQuote | undefined) => {
  if (!quote) {
    return '';
  }

  if (quote.id >= RENUMBER_CUT_OVER_THRESHOLD) {
    return `${quote.id.toString()[0]}K-${quote.id.toString().slice(1)}`
  }

  return `${quote!.id + 10000}`;
}