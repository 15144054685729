import {faPlusCircle, faSearch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, CircularProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import classNames from "classnames";
import Button from "components/ui/buttons/Button";
import MenuItem from "components/ui/MenuItem";
import PageContainer from "components/ui/PageContainer";
import PageHeader from "components/ui/PageHeader";
import Select from "components/ui/Select";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { format } from "date-fns";
import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { IUserProfile } from "types/IUserProfile";
import AddTeacherDialog from "./AddTeacherDialog";
import useDialogState from "hooks/useDialogState";

export const TeachersIndex: React.FC = () => {
  const [query, setQuery] = useState(new URL(window.location.href).searchParams.get('query') || '');
  const [searchColumn, setSearchColumn] = useState<'username' | 'name'>(new URL(window.location.href).searchParams.get('searchColumn') as any || 'username');
  const [results, setResults] = useState<IUserProfile[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const addTeacherDialogState = useDialogState();
  
  const handleSearch = async () => {
    setLoading(true);
    setError(undefined);
    const url = new URL(window.location.href);
    url.searchParams.set('query', query);
    url.searchParams.set('searchColumn', searchColumn);
    if (url.toString() !== window.location.href) {
      window.history.pushState(null, '', url.toString());
    }
    try {
      const res = await justFetch(endpoints.godmode.teachers(`%${query}%`, searchColumn), 'GET');
      const parsed = await res.json();
      setLoading(false);
      if (res.ok) {
        setResults(parsed);
      } else {
        setError(parsed.error || parsed.message || parsed || `An unknown error occurred: ${res.status}`)
      }
    } catch (e) {
      console.log(e);
      setError('A network error occurred. Are you connected to the internet?')
    }
  }

  useEffect(() => {
    handleSearch();
  }, []);

  return <PageContainer variant="full">
    <PageHeader inAppBar title="Teachers" />
    <Box
        display="flex"
        flexDirection="row-reverse"
        mb={1}
    >
      <Box>
        <Button
            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            variant="contained"
            color="green"
            onClick={() => {
              addTeacherDialogState.handleOpen()
            }}
        >
          Add New Teacher
        </Button>
      </Box>
    </Box>
    <form onSubmit={e => { e.preventDefault(); handleSearch() }}>
      <Box
        display="flex"
        alignItems="center"
        mb={1}
      >
        <Box width={175} mr={1}>
          <Select
            value={searchColumn}
            onChange={e => setSearchColumn(e.target.value as any)}
            fullWidth
          >
            <MenuItem value="username">Search by Email</MenuItem>
            <MenuItem value="name">Search by Name</MenuItem>
          </Select>
        </Box>
        <Box flexGrow={1}>
          <TextField
            value={query}
            onChange={e => setQuery(e.target.value)}
            placeholder="Search"
            fullWidth
          />
        </Box>
        <Box ml={1}>
          <Button
            color="green"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faSearch} />}
            size="large"
            type="submit"
          >Search</Button>
        </Box>
      </Box>
    </form>
    <AddTeacherDialog
        open={addTeacherDialogState.open}
        onClose={() => addTeacherDialogState.onClose()}
        refreshData={() => handleSearch()}
    />
    {loading && <Box display="flex" justifyContent="center" width="100%">
      <CircularProgress />
    </Box>}
    {!!error && <Alert severity="error">{error}</Alert>}
    {!loading && !error && results.length > 0 && <UserTable rows={results} />}
    {!loading && !error && results.length === 0 && <Alert severity="info">No results found.</Alert>}
  </PageContainer>;
}


function descendingComparator(a: IUserProfile, b: IUserProfile, orderBy: keyof IUserProfile) {
  if (orderBy === 'updated_at') {
    const _a = new Date(a[orderBy]), _b = new Date(b[orderBy]);
    if (_b < _a) {
      return -1;
    }
    if (_b > _a) {
      return 1;
    }
  }
  else if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    return (a[orderBy] as string).localeCompare(b[orderBy] as string)
  } else {
    if (b[orderBy]! < a[orderBy]!) {
      return -1;
    }
    if (b[orderBy]! > a[orderBy]!) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order: 'asc' | 'desc', orderBy: keyof IUserProfile) {
  return order === 'desc'
    ? (a: IUserProfile, b: IUserProfile) => descendingComparator(a, b, orderBy)
    : (a: IUserProfile, b: IUserProfile) => -descendingComparator(a, b, orderBy);
}

const headCells: { id: keyof IUserProfile, label: string }[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'occupation',
    label: 'Occupation',
  },
  {
    id: 'username',
    label: 'Email',
  },
  {
    id: 'plan',
    label: 'Plan',
  },
  {
    id: 'is_administrator',
    label: 'Account Admin',
  },
  {
    id: 'student_limit',
    label: 'Student Limit',
  },
  {
    id: 'updated_at',
    label: 'Last Active',
  }
];

const UserTableHeader: React.FC<{ order: 'asc' | 'desc', orderBy: keyof IUserProfile, onRequestSort: (property: keyof IUserProfile) => void }> = ({ order, orderBy, onRequestSort }) => {
  const createSortHandler = (property: keyof IUserProfile) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useTableStyles = makeStyles(() => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9'
    }
  }
}))

const UserTable: React.FC<{ rows: IUserProfile[] }> = ({ rows }) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof IUserProfile>('updated_at');
  const tableClasses = useTableStyles();
  const sharedClasses = useSharedStyles();
  const history = useHistory();

  const sortedRows = useMemo(() => {
    return rows.slice().sort(getComparator(order, orderBy));
  }, [order, orderBy, rows])

  const handleRequestSort = (property: keyof IUserProfile) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return <Paper variant="outlined">
    <Box>
      <TableContainer>
        <Table size="small">
          <UserTableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {sortedRows.map((row, i) => {
              return <TableRow
                hover
                key={row.id}
                className={classNames(tableClasses.row, sharedClasses.hoverCursorPointer)}
                onClick={() => {
                  history.push(routes.godmode.user.account(row.id))
                }}
              >
                <TableCell><Typography variant="body2">{row.name}</Typography></TableCell>
                <TableCell><Typography variant="body2">{row.occupation}</Typography></TableCell>
                <TableCell><Typography variant="body2">{row.username}</Typography></TableCell>
                <TableCell>
                  {row.plan === 'Free' && <Chip label="Free" />}
                  {row.plan === 'School' && <Chip style={{ background: 'rgb(0, 167, 157)', color: 'white' }} label={`School${row.has_curriculum ? ' + Curriculum' : ''}`} />}
                </TableCell>
                <TableCell>
                  {row.is_administrator ? <Check /> : null}
                </TableCell>
                <TableCell>
                  <Chip label={row.student_limit || '?'} />
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{format(new Date(row.updated_at), 'PP')}</Typography>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Paper>
}