import {ILessonStandard} from "../../../types/ILessonStandard";
import {Box, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import React from "react";

const LessonStandards: React.VFC<{standards: ILessonStandard[]}> = ({standards}) => {
  const getStandardColor = (standardShort: string, index: number) => {
    if (standardShort === "ct") {
      return index % 2 === 1 ? '#F0E6F1' : '#E2CDE4'
    }
    if (standardShort === "pm") {
      return index % 2 === 1 ? '#E9F5F5' : '#D2ECEB'
    }
    if (standardShort === "pc") {
      return index % 2 === 1 ? '#EDF4DB' : '#D2ECEB'
    }
    if (standardShort === "pi") {
      return index % 2 === 1 ? '#EBF6FB' : '#D9EDF8'
    }
    if (standardShort === "ec") {
      return index % 2 === 1 ? '#FDF6EC' : '#FBEED9'
    }
    if (standardShort === "sel") {
      return index % 2 === 1 ? '#FAEDEF' : '#F5DBDF'
    }
  }

  return (
    <Box width={725} marginTop={1} marginLeft={10}>
      <Typography variant={'h6'}>Standards</Typography>
      <Table>
        <TableBody>
          {standards.map((standard, index) => {
            const color = getStandardColor(standard.codeLower, index);
            return (
              <TableRow key={index} style={{backgroundColor: color}}>
                <TableCell style={{fontWeight: 700, fontSize: 14, padding: '5px 10px'}}>{standard.id}</TableCell>
                <TableCell style={{fontSize: 14, padding: '5px 10px'}}>{standard.description}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default LessonStandards