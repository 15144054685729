import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import LevelModal from 'components/modal/LevelModal';
import Loading from 'components/loading/Loading';
import {getDescription, getDisplay} from './progressHelpers';
import Amplitude from 'utils/Amplitude';
import useV2StudentProgress, {IPlaylist} from 'loaders/useV2StudentProgress';
import {faRecycle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ClassProgressBar from "../../reports/ClassProgressBar";
import {Box, CircularProgress} from '@material-ui/core';
import {generateStudentsProgressReportView, IProgressReportRawData} from "../../reports/reportUtilities";
import useCurrentUser from "../../../loaders/useCurrentUser";
import {useParams} from "react-router-dom";
import useSWR from "swr";
import {ICourseData, ILevelData} from "../../courses/ICourseData";
import endpoints from "../../../endpoints";
import useOrderedCourses from "../../../hooks/useOrderedCourses";
import useParentInit from "../../../loaders/useParentInit";
import {IndividualProgressView} from "../../reports/StudentsProgress";
import {GameTitle} from "../../../enums/GameTitle";
import {Alert} from "@material-ui/lab";

interface LevelProps {
  level: { fullsize_url: string; order: number, studentData?: { created_at?: string; attempts?: number; star_count?: number; number_of_wins?: number } };
  unit: any;
}

const Level: React.FC<LevelProps> = ({ level, unit }) => {
  const [open, setOpen] = useState(false);
  const starCount = level.studentData?.star_count || 0;

  return (
    <Fragment>
      {open && (
        <LevelModal
          unit={unit}
          level={level}
          closeModal={() => setOpen(false)}
        />
      )}
      <button
        onClick={() => setOpen(true)}
        style={{ height: '3.5rem' }}
        className={`unit-shadow unit-shadow-${starCount}-star m-4 ${starCount > 0 && starCount < 3 && 'bg-kodable-orange text-white'
        } cursor relative w-16 ${starCount === 3 && 'bg-kodable-green text-white'
        } ${starCount === 0 && 'bg-gray-100'
        } rounded z-1 flex justify-center items-center`}>
        <div className="flex flex-col">
          <div className="flex justify-center items-center">
            {starCount === 0 && (
              <div className="relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  style={{ left: '-4px', top: '-4px' }}
                  className="w-6 h-6 absolute"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="w-4 h-4"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  />
                </svg>
              </div>
            )}
            {starCount > 0 &&
              Array(starCount)
                .fill(null)
                .map((_, i) => (
                  <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
          </div>
          <p className="font-bold text-center">{getDisplay(unit, level)}</p>
        </div>
      </button>
    </Fragment>
  );
};

interface UnitProps {
  unit: {
    id: number;
    name: string;
    img_name: string;
    levels: any[];
    order: number;
  };
  unitIndex: number;
}

const Unit: React.FC<UnitProps> = ({ unit, unitIndex }) => {
  const [isOpen, setIsOpen] = useState(unit.name === 'Sequence');

  const toggleOpen = () => setIsOpen(!isOpen);

  if (unit.id === 7) {
    return null;
  }

  return (
    <div className="mb-12">
      <button
        onClick={toggleOpen}
        className="flex w-full justify-between items-center px-4 py-4 bg-gray-100 border">
        <div className="flex">
          <div>
            <img
              alt={unit.name}
              width="30px"
              src={`images/students/curriculum_images/UnitIcon_${unit.img_name}.png`}
            />
          </div>
          <span className="text-lg ml-2">{unit.name}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="w-4 h-4 text-gray-800"
          stroke="currentColor">
          {isOpen ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          )}
        </svg>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            style={{ overflow: 'hidden' }}
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}>
            <div className="mt-8 mx-2">
              <div className="flex justify-between items-center">
                <p className="text-gray-700 text-sm lg:text-base flex-1 italic">
                  {getDescription(unit)}
                </p>
              </div>
              <div className="my-4 flex flex-wrap">
                {unit.levels.map((level, index) => (
                  <Level
                    key={`${unitIndex}.${index + 1}`}
                    unit={unit}
                    level={level}
                  />
                ))}
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

interface HomeUnitsSectionProps {
  playlist: IPlaylist[];
}

const HomeV2Progress: React.FC = () => {
    const { currentUser } = useCurrentUser();
    const { studentId } = useParams<{ studentId: string }>();
    const { data: courseData, error: courseDataError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
    const { data: reportData, error: reportDataError } = useSWR<IProgressReportRawData>(endpoints.studentLevelCompletions(studentId));
    const { students } = useParentInit();

    const {data: creatorCourseData, error: creatorCourseDataError} = useSWR<ICourseData[]>(endpoints.allCreatorCourses);
    
    const {
        data: legacyCourseData,
        error: legacyCourseDataError
    } = useSWR<ICourseData[]>(endpoints.allBasicsCoursesLegacy);
    const {data: levelData, error: levelDataError} = useSWR<ILevelData[]>(endpoints.allBasicsLevels);
    
    const reportView = useMemo(() => {
        if (!students || !courseData || !creatorCourseData || !legacyCourseData || !reportData || !levelData) {
            return null;
        }
        return generateStudentsProgressReportView(
            courseData!,
            creatorCourseData!,
            legacyCourseData!,
            levelData!,
            students,
            reportData,
            currentUser.plan === 'School',
            false)
    }, [courseData, students, reportData, levelData, currentUser]);


    const loading =  (!courseData && !courseDataError) || (!creatorCourseData && !creatorCourseDataError) || (!legacyCourseData && !legacyCourseDataError) || (!reportData && !reportDataError) || (!levelData && !levelDataError);
    const error = courseDataError || creatorCourseDataError || legacyCourseDataError || reportDataError;
    useEffect(() => {
    Amplitude.track('Viewed Home Progress');
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-32 h-64">
          <CircularProgress/>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center mt-32 h-64">
          <Alert severity="error">There was an error generating this report.</Alert>
      </div>
    );
  }

  return (
    <div>
      <IndividualProgressView classIds={[]} selectedStudentIndex={0} students={students!}
                                displayState={"all"} reportView={reportView} selectedGame={GameTitle.BASICS} assignments={undefined}/>
    </div>
  );
};

export default HomeV2Progress;
