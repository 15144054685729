import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, makeStyles, Typography, withStyles } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import classNames from "classnames";
import Button from '../../components/ui/buttons/Button';
import useSharedStyles from "../../components/useSharedStyles";
import React, { useRef, useState } from "react";
import ClassCodeInput from "./ClassCodeInput";
import LoginInput from "./LoginInput";
import queryString from 'query-string';
import { useEffect } from "react";
import Cookies from 'universal-cookie';
import { useMemo } from "react";
import ISession from "../../types/ISession";
import { justFetch } from "../../mutations/mutate";
import endpoints from "endpoints";
import TextField from "components/ui/TextField";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FuzzBuzz } from "pages/teacher-onboarding/FuzzBuzz";
import { motion } from "framer-motion";
const cookies = new Cookies();

export const useUniversalLoginStyles = makeStyles(() => ({
  container: {
    transition: 'left 0.5s',
    position: 'relative',
    left: 0
  },
  hasLoginState: {
    left: '-100vw !important'
  },
  parentTeacherButton: {
    background: 'white',
    transition: 'background 0.25s, color 0.25s',
    '&:hover': {
      background: '#00ADED',
      color: 'white !important'
    }
  },
  primaryButton: {
    borderWidth: 3,
    background: '#00ADED',
    transition: 'background 0.25s, color 0.25s',
    color: 'white',
    '&:hover': {
      background: 'white',
      color: '#00ADED',
      borderWidth: 3,
      borderColor: '#00ADED !important'
    }
  },
}))

const UniversalLogin: React.VFC = () => {
  const modeFlag = queryString.parse(window.location.search)?.user;
  const codeFlag = queryString.parse(window.location.search)?.code;
  const tryClassCode = queryString.parse(window.location.search)?.tryClassCode;

  const [showLoginInput, setShowLoginInput] = useState<boolean>((!!modeFlag || !!codeFlag));
  const [loginMode, setLoginMode] = useState<'home' | 'school' | 'teacher'>(modeFlag as any);
  const sharedClasses = useSharedStyles();
  const loginClasses = useUniversalLoginStyles();
  const kodable_kode = useMemo(() => cookies.get('kodable_kode'), []);
  const [session, setSession] = useState<ISession | undefined>();
  const [showTeacherLoggedIntoHomeWarning, setShowTeacherLoggedIntoHomeWarning] = useState(false);

  const next = queryString.parse(window.location.search)?.next;

  useEffect(() => {
    if (showLoginInput && session && (
      (loginMode === 'home' && session.user.is_parent) ||
      (loginMode === 'teacher'))
    ) {
      setDidLogin(session);
    }

  }, [showLoginInput, session]);

  useEffect(() => {
    if (kodable_kode) {
      justFetch(endpoints.session, 'GET', undefined, kodable_kode)
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          return res.json();
        })
        .then(setSession)
        .catch(() => { });
    }
  }, [kodable_kode]);

  const [didLogin, setDidLogin] = useState<undefined | ISession>();

  useEffect(() => {
    const session = didLogin;

    if (!session) {
      return;
    }

    if (loginMode === 'home') {
      if (session.user.is_parent) {
        (window as any).dataLayer?.push({ event: 'student-login' });

        const hoc = queryString.parse(window.location.search)?.hoc;
        const passed_game_url = queryString.parse(window.location.search)?.game_url;
        const game_url = passed_game_url || (window as any).GAME_URL || process.env.REACT_APP_GAME_ENDPOINT;
        window.location.href = game_url + '/play?user=n' + (hoc ? `&hoc=${hoc}` : '') + `&session_token=${session.session_token}`;
      } else {
        setShowTeacherLoggedIntoHomeWarning(true);
      }
    } else if (loginMode === 'teacher') {
      (window as any).dataLayer?.push({ event: 'adult-login' });

      if (queryString.parse(window.location.search)?.event === 'valentines2023') {
        window.location.href = `${process.env.REACT_APP_KODABLE_WEBSITE}/view-valentine?student_code=${queryString.parse(window.location.search)?.import_student_code || queryString.parse(window.location.search)?.student_code}`
      } else {
        window.location.href = process.env.REACT_APP_DASHBOARD_ENDPOINT! + (next ? next : '');
      }
    }
  }, [didLogin]);
  const playAtSchoolButtonRef = useRef<HTMLImageElement | null>(null);
  const [playAtSchoolImageLocation, setPlayAtSchoolImageLocation] = useState<DOMRect>();
  
  const updatePlayAtSchoolImageLocation = () => {
    const rect = playAtSchoolButtonRef.current?.getBoundingClientRect();
    if (rect && rect.top !== playAtSchoolImageLocation?.top && rect.right !== playAtSchoolImageLocation?.right) {
      setPlayAtSchoolImageLocation(rect);
    }
  }

  useEffect(() => {
    const listener = () => updatePlayAtSchoolImageLocation()
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  useMemo(updatePlayAtSchoolImageLocation, [playAtSchoolButtonRef.current]);

  const gameLogin = (window as any).gameLogin;

  return (
    <GoogleOAuthProvider clientId="174894017176-0agntgejqqasph3ptcnoclvhahe00pv9.apps.googleusercontent.com">
      <Dialog open={showTeacherLoggedIntoHomeWarning}>
        <DialogTitle>Oops! You’re using a teacher profile.</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            It looks like you’re trying to login with a teacher account. To login as a student, Select the School option and use your class code.
          </Typography>
          <Typography>
            Suggested Help Article: <Link href="http://support.kodable.com/en/articles/417326-how-do-i-see-what-my-students-see-in-the-game">How do I see what my students will see in the game?</Link>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box></Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowTeacherLoggedIntoHomeWarning(false)}
          >Close</Button>
        </DialogActions>
      </Dialog>
      <Box
        className={classNames(loginClasses.container, {
          [loginClasses.hasLoginState]: showLoginInput,
        })}
        display="flex"
        flexDirection="row"
        height="100%"
        width="200vw"
        alignItems="center"
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100vw"
        >
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" width="100%">
            <Box
              style={{ maxWidth: 400, width: '20%' }}
              tabIndex={0} aria-label="Play at Home"
              onClick={() => {
                setLoginMode('home')
                setShowLoginInput(true);
              }}
            >
              <img
                width={'100%'}
                src={`${process.env.REACT_APP_DASHBOARD_ENDPOINT}/images/login_button_pro.png`}
                className={classNames(sharedClasses.hoverCursorPointer, sharedClasses.hoverEnlarge2)}
              />
            </Box>
            <Box width="40px"></Box>
            <Box
              style={{ maxWidth: 400, width: '20%', zIndex: 7 }}
              tabIndex={0} aria-label="Play at School"
              onClick={() => {
                setLoginMode('school')
                setShowLoginInput(true);
              }}
            >
              <img
                width={'100%'}
                ref={playAtSchoolButtonRef}
                onLoad={e => setTimeout(updatePlayAtSchoolImageLocation, 500)}
                src={`${process.env.REACT_APP_DASHBOARD_ENDPOINT}/images/login_button_school.png`}
                className={classNames(sharedClasses.hoverCursorPointer, sharedClasses.hoverEnlarge2)}
              />
            </Box>
          </Box>
          {!gameLogin && <Box mt={2} display="flex" flexDirection="row" justifyContent="center">
            <Box
              tabIndex={0}
              aria-label="Parent and Teacher Login"
              onClick={() => {
                setLoginMode('teacher')
                setShowLoginInput(true);
              }}
            >
              <CssButton
                variant="outlined"
                style={{
                  borderColor: '#00ADED',
                  color: '#00ADED',
                }}
                classes={{
                  root: loginClasses.parentTeacherButton
                }}
              >
                <div style={{ textTransform: 'none' }}>
                  Parent and Teacher Login
                </div>
              </CssButton>
            </Box>
          </Box>}
        </Box>
        {showLoginInput &&
          <Box
            width="100vw"
            height="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
          >
            <Box display="flex" flexDirection="column" justifyContent="center" paddingLeft="10%" paddingRight={2}>
              <IconButton
                style={{
                  background: 'white'
                }}
                onClick={() => setShowLoginInput(false)}
              ><ChevronLeft style={{ color: 'black', transform: 'scale(2)' }} /></IconButton>
            </Box>

            <Box
              style={{ display: loginMode === 'school' ? 'none' : 'initial' }}
            >
              <LoginInput mode={loginMode} setLoginMode={setLoginMode} setShowLoginInput={setShowLoginInput} handleRedirectAfterLogin={setDidLogin} />
            </Box>
            {loginMode === 'school' && <ClassCodeInput />}

            <Box display="flex" flexDirection="column" justifyContent="center" paddingLeft="10%" paddingRight={2}>
              <IconButton
                style={{
                  visibility: 'hidden',
                  transform: 'scale(2)'
                }}
                onClick={() => setShowLoginInput(false)}
              ><ChevronLeft /></IconButton>
            </Box>
          </Box>
        }
      </Box>
      {!!tryClassCode && !showLoginInput && <MotionBox position="absolute" top={0} left={0} bottom={0} right={0} bgcolor="rgba(0,0,0,0.75)" zIndex={6} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 2 }}>&nbsp;</MotionBox>}
      {!!tryClassCode && playAtSchoolImageLocation &&
        <MotionBox position="absolute" top={`calc(${playAtSchoolImageLocation.top}px - 20%)`} left={playAtSchoolImageLocation.right} zIndex={7} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 2 }}>
          <FuzzBuzz
            fuzzBuzzDirection="left"
            say={
              !showLoginInput
                ? "Welcome to Kodable! Click 'Play at School' to get started"
                : <>Here is where your students will enter their class code. As a reminder, it's <span style={{ fontWeight: 'bold' }}>{tryClassCode}</span><br /><br />Let's try that now</>}
          />
        </MotionBox>
      }
    </GoogleOAuthProvider >
  )
}

export const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& input': {
        color: 'white',
        textAlign: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 15,
        fontWeight: 'bold',
        fontSize: 22
      },
      '& fieldset': {
        borderColor: 'white',
        borderWidth: 3,
        borderRadius: 15,
        boxShadow: '6px 6px 10px rgba(0,0,0,0.25)',
        color: 'white',
        transition: 'border-color 0.25s'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0,0,0,0.1)',
        borderWidth: 3
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00ADED',
        borderWidth: 3
      },
    },
  },
})(TextField);

export const CssButton = withStyles({
  root: {
    fontSize: '20px',
    lineHeight: '32px',
    borderRadius: '100px',
    padding: '22px 70px',
    borderWidth: 3,
    fontWeight: 'bold'
  },
})(Button);

const MotionBox = motion.custom(Box)

export default UniversalLogin;