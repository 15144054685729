import React, { Fragment } from 'react';
import TopNav from '../shared/TopNav';
import jsonVocab from '../../../json/vocabulary.json';

interface Vocabulary {
  word: string;
  definition: string;
  image?: string;
  outline?: boolean;
}

const AsteroidiaVocabularyPage: React.FC = () => {
  const worldVocabulary: Vocabulary[] = jsonVocab['asteroidia'].vocabulary;

  return (
    <Fragment>
      <TopNav
        navTitle="Vocabulary"
        title="Asteroidia"
        guideImage="/images/teacher-guides/asteroidia/asteroidiaThumb.jpg"
        navLinks={[
          { title: 'Learning Tools', to: '/learning-tools' },
          { title: 'Asteroidia', to: '/learning-tools/asteroidia' },
          { title: 'Vocabulary', to: '/learning-tools/vocabulary/asteroidia' },
        ]}
      />
      <div className="flex flex-col justify-center items-center w-full">
        <div className="my-8 w-full max-w-screen-lg px-2">
          <div className="bg-kodable-purple text-white flex justify-between items-center px-4 py-3">
            <h1 className="font-bold text-lg">Vocabulary</h1>
            <div>
              <img
                className="w-24"
                src="/images/teacher-guides/Kodable.png"
                alt="logo"
              />
            </div>
          </div>
          <div className="px-12">
            <div className="relative font-bold text-3xl border-2 rounded-2xl border-kodable-purple text-center px-4 py-3 my-24 text-gray-700">
              <img
                src="/images/teacher-guides/ColeFuzz.png"
                alt="Kodable Cole Fuzz"
                className="-left-12 -top-6 w-24 absolute"
              />
              Vocabulary
            </div>

            {worldVocabulary.map((vocab, index) => (
              <div
                key={index}
                className={`flex flex-col lg:flex-row justify-between lg:items-center my-12 p-4 ${
                  vocab.outline ? 'border-2 border-kodable-purple' : ''
                }`}
              >
                <div className="text-kodable-purple font-bold w-40 mb-4 lg:mb-0">
                  {vocab.word}
                </div>
                <p className="flex-1 lg:px-8 text-gray-700">
                  {vocab.definition}
                </p>
                {vocab.image && (
                  <img
                    className="w-40 self-center my-4 lg:my-0"
                    src={vocab.image}
                    alt={vocab.word}
                  />
                )}
              </div>
            ))}
            <div className="flex flex-row justify-between lg:items-center my-12">
              <div className="text-yellow-500 mr-2 font-bold w-20 h-20 border-yellow-500 border-2 rounded-full flex justify-center items-center">
                Try this!
              </div>
              <p className="flex-1 lg:px-8 text-gray-700">
                Before giving learners a definition, try asking them what they
                already know! Getting a sense of prior knowledge can help you
                gauge how much detail you will need to cover to help a learner
                grasp the concept.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AsteroidiaVocabularyPage;
