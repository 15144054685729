import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { ISubscription } from 'types/ISubscription';
import { SubscriptionsActiveIndicator } from './SubscriptionsActiveIndicator';
import useSharedStyles from 'components/useSharedStyles';
import { accountTrackedDate } from 'pages/godmode/utils/time';
import Button from 'components/ui/buttons/Button';
import {formatCurrency} from "pages/godmode/utils/numbers";

export const SubscriptionStateCard: React.FC<{subscription: ISubscription}> = ({subscription}) => {
    const sharedClasses = useSharedStyles()

    if (!subscription) {
      return null
    }

    return (
        <Box className={sharedClasses?.vspacing2} p={2} style={{background: 'white', borderRadius: 4, border: '1px solid #eee', padding: '1rem'}}>
            <Box className={sharedClasses?.hspacing2} display="flex" alignItems="center">
                <SubscriptionsActiveIndicator subscription={subscription} />
                <Typography variant="h2">Subscription {subscription?.id}</Typography>
            </Box>
            <Box>
                <Typography><strong>Payment Status:</strong> <span style={{textTransform: 'capitalize'}}>{subscription?.purchase_process?.state?.replaceAll('_', ' ')}</span></Typography>
                <Typography><strong>Invoice Issue Date:</strong> {subscription?.purchase_process?.created_at ? accountTrackedDate(subscription?.purchase_process?.created_at) : ''}</Typography>
                <Typography><strong>Payment Due Date:</strong> {subscription?.purchase_process?.payment_due ? accountTrackedDate(subscription?.purchase_process?.payment_due) : ''}</Typography>
                <Typography><strong>Amount:</strong> {formatCurrency(subscription?.contract_amount)}</Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                {subscription?.quickbooks_invoice_link &&
                    <Button
                        variant="outlined"
                        color="primary"
                        href={subscription.quickbooks_invoice_link}
                    >
                        View Invoice
                    </Button>
                }
                {subscription?.quickbooks_invoice_doc_number &&
                    <Typography variant="body1">Invoice #{subscription.quickbooks_invoice_doc_number}</Typography>
                }
            </Box>
        </Box>
    )
}