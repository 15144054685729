import useSharedStyles from "components/useSharedStyles";
import {Box, Grid, Typography} from "@material-ui/core";
import React, {ReactNode} from "react";

interface RevenueIndexTopBarItemProps {
  heading?: string | number;
  children?: ReactNode;
  name: string;
}

const RevenueScoreCardItem: React.FC<RevenueIndexTopBarItemProps> = ({heading, children, name}) => {
  const sharedClasses = useSharedStyles()
  return (
    <Grid item xs={12} sm={6} md={4} xl={2} style={{textAlign: "center"}}>
      {heading &&
        <Typography variant="h2">{heading}</Typography>
      }
      {children &&
        <Box className={sharedClasses.hspacing2} display="flex" alignItems="center" justifyContent="center"
             width="100%">
          {children}
        </Box>
      }
      <Typography variant="body1"><strong>{name}</strong></Typography>
    </Grid>
  )
}

export default RevenueScoreCardItem