import { Box } from '@material-ui/core'
import useSharedStyles from 'components/useSharedStyles'
import React, { useEffect, useState } from 'react'
import { IOrganizationProfile } from 'types/IOrganizationProfile'
import { ISubscription } from 'types/ISubscription'
import { SubscriptionStateCard } from './SubscriptionStateCard'

export const SubscriptionStateCardsListing: React.FC<{organizationProfile: IOrganizationProfile | null}> = ({organizationProfile}) => {
    const sharedClasses = useSharedStyles()
    const [subscriptions, setSubscriptions] = useState<ISubscription[]>([])

    useEffect(() => {
        if (!organizationProfile || !organizationProfile?.subscriptions) {
          return
        }

        const sortedSubscriptions = organizationProfile.subscriptions.sort((a, b) => {
            return new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
        })

        setSubscriptions(sortedSubscriptions)
    }, [organizationProfile])

    if (!subscriptions) {
      return null
    }
    
    return (
        <Box className={sharedClasses?.vspacing2}>
            {subscriptions?.map((subscription) => {
                return (
                    <SubscriptionStateCard
                        subscription={subscription}
                        key={subscription?.id}
                    />
                )
            })}
        </Box>
    )
}