import SectionContainer from "./SectionContainer";
import React from "react";

const DirectInstruction: React.FC<{title: string, body: string, duration: string}> = ({title, body, duration}) => {
  return (
    <SectionContainer
      title={title}
      duration={duration}
      icon={'/images/curriculum_images/Teacher.png'}
      iconColor={'#27a9e1'}
      innerHtml={body}
    />
  )
}

export default DirectInstruction