import React from 'react';
import Youtube from 'react-youtube';

interface ProjectProps {
  title: string;
  description: string;
  videoId: string;
}

const Project: React.FC<ProjectProps> = ({ title, description, videoId }) => {
  return (
    <div>
      <h2 className="bg-kodable-teal bg-opacity-80 text-white py-4 px-4 font-bold">
        {title}
      </h2>

      <div className="px-4">
        <div className="mt-4 font-bold text-gray-700">{title}</div>
        <p className="text-gray-700 pt-2 pb-4">{description}</p>
        <div className="max-w-2xl">
          <Youtube videoId={videoId} containerClassName="youtubeContainer" />
        </div>
      </div>
    </div>
  );
};

export const AsteroidiaProject: React.FC = () => {
  return (
    <Project
      title="NEW in Asteroidia!"
      description="Become a master game designer! Students design their own Asteroidia games by modifying properties of their code."
      videoId="SIMUVV3SqmE"
    />
  );
};

export const FuzzBuilderProject: React.FC = () => {
  return (
    <Project
      title="Fuzz Builder"
      description="Students meet the Fuzz Family of Kodable! Learn who the characters are, why they are exploring the universe, and how we can use coding to help!"
      videoId="nCIf0_0aobk"
    />
  );
};

export const MazeMakerProject: React.FC = () => {
  return (
    <Project
      title="Maze Maker"
      description="Students meet the Fuzz Family of Kodable! Learn who the characters are, why they are exploring the universe, and how we can use coding to help!"
      videoId="MZIUf1SZ2HA"
    />
  );
};

export default Project;
