import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, FormHelperText, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import { useOrganizationProfile } from "context/OrganizationProfileProvider";
import endpoints from "endpoints";
import { useFormik } from "formik";
import { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import React, { useState } from "react";
import * as yup from 'yup';

export const SubscriptionChangeAdminDialog: React.FC<DialogStateProps> = ({ open, onClose }) => {
  const [error, setError] = useState<string>();
  const [toTeacherNotExists, setToTeacherNotExists] = useState<boolean>(false);
  const alert = useAlert();
  const sharedClasses = useSharedStyles();
  const { organization_profile, mutate } = useOrganizationProfile()

  const form = useFormik({
    initialValues: {
      transfer_subscription: true,
      transfer_klasses: false,
      transfer_to_teacher_email: '',
      transfer_to_teacher_name: '',
      confirm_create_to_teacher: false
    },
    validationSchema: yup.object({
      transfer_to_teacher_email: yup.string().email().required(),
      transfer_to_teacher_name: yup.string().when([], () => (
        toTeacherNotExists
          ? yup.string().required('New administrator name is required to create their account.').min(1)
          : yup.string()
      )),
      confirm_create_to_teacher: yup.boolean().when([], () => (
        toTeacherNotExists
          ? yup.boolean().oneOf([true], 'Confirm create new teacher account is required to create their account.')
          : yup.boolean()
      ))
    }),
    onSubmit: values => {
        if (!organization_profile) {
          return
        }

        // Reformat data to be sent to the API
        const data = {
            transfer_subscription: values.transfer_subscription,
            transfer_klasses: values.transfer_klasses,
            transfer_to_teacher: {
            username: values.transfer_to_teacher_email,
            name: values.transfer_to_teacher_name
            },
            confirm_create_to_teacher: values.confirm_create_to_teacher
        }
        return justFetch(endpoints.godmode.transfer(organization_profile?.administrator_id), 'PUT', data)
            .then(res => {
            if (res.ok) {
                setError(undefined);
                const successMessage = toTeacherNotExists ? 'Created new Teacher account and transferred Admin' : 'Transferred Admin to existing account';
                alert.success(successMessage);
                setToTeacherNotExists(false)
                onClose();
                mutate()
                form.resetForm();
            } else {
                if (res.status === 406) {
                setError(undefined)
                setToTeacherNotExists(true)
                return
                }
                res.json().then(body => setError(body.message || body.error || 'Unknown error'));
            }
            })
            .catch(() => setError('Unknown error'))
        }
  })

  if (!organization_profile) {
    return null
  }

  return <Dialog open={open}>
    <DialogTitle>Transfer Account from {organization_profile?.administrator?.name}</DialogTitle>
    <DialogContent>
      <Box mb={1} className={sharedClasses?.vspacing2}>
        {toTeacherNotExists &&
          <Typography variant="body1">New administrator email does not have an associated account. Please enter a name for them, and confirm that we should create an account for them.</Typography>
        }
        <TextField
          label={<>New administrator email<sup style={{color: 'red'}}>*</sup></>}
          name="transfer_to_teacher_email"
          value={form.values.transfer_to_teacher_email}
          onChange={form.handleChange}
          helperText={form.errors.transfer_to_teacher_email}
          error={!!form.errors.transfer_to_teacher_email}
        />
        {toTeacherNotExists &&
        <>
          <TextField
            label={<>New administrator name<sup style={{color: 'red'}}>*</sup></>}
            name="transfer_to_teacher_name"
            value={form.values.transfer_to_teacher_name}
            onChange={form.handleChange}
            helperText={form.errors.transfer_to_teacher_name}
            error={!!form.errors.transfer_to_teacher_name}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.values.confirm_create_to_teacher}
                  onChange={form.handleChange}
                  name="confirm_create_to_teacher"
                  color={form.touched.confirm_create_to_teacher && form.errors.confirm_create_to_teacher ? "secondary" : "primary"}
                />
              }
              label={<>Confirm create new teacher account<sup style={{color: 'red'}}>*</sup></>}
            />
            {form.touched.confirm_create_to_teacher && form.errors.confirm_create_to_teacher && (
              <FormHelperText error>
                {form.errors.confirm_create_to_teacher}
              </FormHelperText>
            )}
          </FormGroup>
        </>
        }
      </Box>

      {error && <Alert severity="error">{error}</Alert>}
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        onClick={() => {
          form.resetForm();
          onClose();
          setError(undefined);
          setToTeacherNotExists(false)
        }}
        disabled={form.isSubmitting}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={form.isSubmitting || !form.isValid}
        onClick={form.submitForm}
      >
        Transfer
      </Button>
    </DialogActions>
  </Dialog>;
}