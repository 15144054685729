import endpoints from 'endpoints';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

export interface ISchool {
    id: number;
    nces_id: string;
    nces_school_id: number;
    state_school_id: string;
    school_name: string;
    school_phone: string;
    school_mailing_address: string;
    school_mailing_city: string;
    school_mailing_state: string;
    school_mailing_zip: number;
    school_mailing_zip4: number;
    school_location_address: string;
    school_location_city: string;
    school_location_state: string;
    school_location_zip: number;
    school_location_zip4: number;
    school_nces_code: number;
    school_nces_status: number;
    nces_locale_code: null | number;
    school_latitude: string;
    school_longitude: string;
    school_ansi_county_num: null | number;
    school_county_name: null | string;
    school_low_grade: number;
    school_high_grade: number;
    school_grade_level: string;
    is_charter: boolean;
    is_public: boolean;
    created_at: string;
    updated_at: string;
    latitude: string;
    longitude: string;
    ll_point: string;
    district_id: number;
    school_id: null | number;
    count: null | number;
    teacher_count: null | number;
}  

interface SchoolsContextProps {
    schools: ISchool[];
    page: number;
    loading: boolean;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    search: string | null;
    setSearch: React.Dispatch<React.SetStateAction<string | null>>;
    error: any;
    mutate: () => void;
}

const SchoolsContext = createContext<SchoolsContextProps>({
  schools: [],
  page: 1,
  loading: true,
  setPage: () => {},
  search: null,
  setSearch: () => {},
  error: null,
  mutate: () => {}
});

interface SchoolsProviderProps {
  children: ReactNode;
}

export const SchoolsProvider: React.FC<SchoolsProviderProps> = ({ children }) => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string | null>(null)
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  
  const { data, mutate, error } = useSWR(endpoints.godmode.schools(page, search));
  
  useEffect(() => {
    if (data) {
      setSchools(data);
    }
    setLoading(false)
  }, [data]);

  // Mutate (re-request) each time the page/search changes
  useEffect(() => {
    setLoading(true)
    mutate().then(() => setLoading(false))
  }, [page, search])
  
  return (
    <SchoolsContext.Provider value={{ schools, page, loading, setPage, search, setSearch, error, mutate }}>
      {children}
    </SchoolsContext.Provider>
  );
};

export const useSchoolsContext = () => useContext(SchoolsContext);
