import { faCheck, faDownload, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogTitle, Box, DialogContent, Typography, TextField, InputAdornment, Paper, ListItem, ListItemIcon, Checkbox, ListItemText, DialogActions, CircularProgress, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import { AnimatePresence, motion } from "framer-motion";
import { DialogStateProps } from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import {downloadPrintout, savePrintout, savePrintoutFromBlob} from "components/dialogs/PrintDialog";
import React, {useState, useMemo, useEffect, ReactNode, useRef, ReactElement} from "react";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import grades, { gradesArray } from "types/Grades";
import { IKlass } from "types/IKlass";
import ParentInvites, { TrackParentInviteMethod, TrackParentInviteMethodLocation } from "utils/ParentInvites";
import ReactPDF, {BlobProvider, renderToFile} from "@react-pdf/renderer";
import DocumentProps = ReactPDF.DocumentProps;

/*
 This component either uses server resources to print a url to a pdf, or uses the client to render a pdf from a React element.
 Moving forward, it should only be used to print React elements to pdf by passing it a handout prop. The handoutUrl prop is deprecated
 and remains only for backwards compatibility with existing dashboard features.
 */
export const MultiClassPrintoutDialog: React.VFC<DialogStateProps & { title?: ReactNode, helperText?: ReactNode, handoutUrl?: (klassId: number)=> string, handout?: (klassId: number) => ReactElement<DocumentProps, string | React.JSXElementConstructor<any>>,  v2: boolean, filename: (klass: IKlass) => string, trackingLocation?: TrackParentInviteMethodLocation, selectKlass?: number }> = ({ title, helperText, open, onClose, handoutUrl, handout, v2, filename, trackingLocation, selectKlass}) => {
  const [selectedClasses, setSelectedClasses] = useState<Set<number>>(new Set());
  const [classNameFilter, setClassNameFilter] = useState('');
  const { teacherData } = useTeacherInit();

  const sharedClasses = useSharedStyles();

  const classesByGrade = useMemo(() => {
    const classes = teacherData?.klasses || [];

    return gradesArray.reduce((memo, cur) => {
      return {
        ...memo,
        [cur.key]: classes.filter(klass => klass.grade === cur.key && klass.klass_name.toLowerCase().includes(classNameFilter.toLowerCase())).sort((klassA, klassB) => klassA.klass_name.localeCompare(klassB.klass_name))
      }
    }, {} as { [key in keyof typeof grades]: IKlass[] });
  }, [teacherData, classNameFilter]);

  const totalResults = useMemo(() => {
    return Object.values(classesByGrade).reduce((memo, arr) => memo + arr.length, 0);
  }, [classesByGrade]);

  const history = useHistory();

  useEffect(() => {
    if (open) {
      setSelectedClasses(new Set(selectKlass ? [selectKlass] : []));
      setGenerating(false);
    }
  }, [open]);

  const initialClassRef = useRef<HTMLElement>();
  useEffect(() => {
    setTimeout(() => {
      initialClassRef.current?.scrollIntoView();
    }, 500)
  }, [initialClassRef.current, open]);

  const [generating, setGenerating] = useState(false);

  const klasses = useMemo(() => {
    return teacherData?.klasses.filter(({ id }) => selectedClasses.has(id)) || []
  }, [teacherData?.klasses, selectedClasses]);

  const handleToggleClass = (classId: number, selected: boolean) =>
    setSelectedClasses(selectedClasses => {
      const newSelectedClasses = new Set(selectedClasses);

      if (selected) {
        newSelectedClasses.add(classId);
      } else {
        newSelectedClasses.delete(classId);
      }

      return newSelectedClasses;
    });

  return <Dialog fullWidth open={open} scroll="paper">
    {!generating && <DialogTitle>{title}</DialogTitle>}
    {generating && <DialogTitle>Generating...</DialogTitle>}
    {(teacherData?.klasses?.length || 0) > 0 && !generating && <Box component={DialogContent} className={sharedClasses.vspacing2} display="flex" flexDirection="column">
      <Typography align="center">{helperText}</Typography>
      <TextField
        placeholder="Search Classes"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faSearch} />
            </InputAdornment>
          ),
        }}
        value={classNameFilter}
        onChange={e => setClassNameFilter(e.target.value)}
      />
      {totalResults === 0 && <Alert severity="info">Your search returned no results.</Alert>}
      {totalResults > 0 && <Box component={Paper} {...{ variant: 'outlined' }} px={2} pb={2} overflow="scroll">
        {gradesArray.map(grade => {
          const klasses = classesByGrade[grade.key];

          if (klasses.length === 0) {
            return null;
          }

          return <React.Fragment key={grade.key}>
            <Box color={grade.color} borderBottom={`2px solid ${grade.color}`} mt={2} mb={1}>
              <Typography variant="subtitle1">{grade.name}</Typography>
            </Box>

            <AnimatePresence>
              {klasses.map(klass => {
                const selected = selectedClasses.has(klass.id);
                const hasStudents = teacherData?.students.some(student => student.klasses.includes(klass.id));

                return <motion.div
                  key={klass.id}
                  initial={{ opacity: 0, maxHeight: 0, overflow: 'hidden' }}
                  animate={{ opacity: 1, maxHeight: 125, overflow: 'visible' }}
                  exit={{ opacity: 0, maxHeight: 0, overflow: 'hidden' }}
                  transition={{ duration: 0.25 }}
                >
                  <ListItem
                    button {...{ disableRipple: true }}
                    selected={selected} onClick={() => handleToggleClass(klass.id, !selected)}
                    disabled={!hasStudents}
                    innerRef={selectKlass && selectKlass === klass.id ? initialClassRef : undefined}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={selected}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={klass.klass_name}
                      secondary={!hasStudents ? 'No students in this class' : undefined}
                    />
                  </ListItem>
                </motion.div>
              })}
            </AnimatePresence>
          </React.Fragment>
        })}
      </Box>}
    </Box>}
    {teacherData?.klasses?.length === 0 && !generating && <Alert severity="info" action={<Button color="inherit" size="small" onClick={() => history.push(routes.classes.addNew.index)}>Add a class</Button>}>You don't have any classes to generate reports.</Alert>}
    {generating && <Box component={DialogContent} className={sharedClasses.vspacing2} display="flex" flexDirection="column">
      <Typography variant="subtitle1">Please be patient, if you selected a lot of classes this might take a few moments</Typography>
      {klasses.map(klass => {
        return <GeneratingRow key={klass.id} klass={klass} handoutUrl={handoutUrl} handout={handout} v2={v2} filename={filename} trackingLocation={trackingLocation} />
      })}
    </Box>}
    <DialogActions>
      <Box width="100%" display="flex" justifyContent={!generating ? 'space-between' : 'flex-end'} alignItems="end">
        <Button
          variant="outlined"
          onClick={onClose}
        >Close</Button>
        {!generating && <Box display="flex" flexDirection="column" alignItems="end" className={sharedClasses.vspacing1}>
          <Typography variant="subtitle1" style={{ visibility: selectedClasses.size > 0 ? 'visible' : 'hidden' }}>{selectedClasses.size} {selectedClasses.size > 1 ? 'classes' : 'class'} selected</Typography>
          <Button
            variant="contained"
            color="green"
            disabled={selectedClasses.size === 0}
            onClick={() => {
              setGenerating(true);
            }}
          >
            Generate Downloads
          </Button>
        </Box>}
      </Box>
    </DialogActions>
  </Dialog>
}

export const GeneratingRow: React.VFC<{ klass: IKlass, handoutUrl?: (klassId: number) => string, handout?: (klassId: number) => ReactElement<DocumentProps, string | React.JSXElementConstructor<any>>, v2: boolean, filename: (klass: IKlass) => string, trackingLocation?: TrackParentInviteMethodLocation }> = ({ klass, handoutUrl, handout, v2, filename, trackingLocation }) => {
  const [generating, setGenerating] = useState(false);
  const [printoutJson, setPrintoutJson] = useState<{ pdf: string }>();
  const alert = useAlert()

  useEffect(() => {
      setGenerating(true);
      if (handoutUrl) {
        downloadPrintout(handoutUrl(klass.id), v2)
          .then((res) => {
            setPrintoutJson(res);
            setGenerating(false);
          })
          .catch((error) => {
            alert.error('Could not generate Printout', 'Please try again later')
            setGenerating(false)
          })
      }
  }, []);

  const handleDownloadGeneratedPrintout = () => {
    // Track in database / intercom / amplitude
    ParentInvites.track(klass, TrackParentInviteMethod.download, trackingLocation)
    // Download Pre-Generated Printout
    if (printoutJson) {
      savePrintout(filename(klass), printoutJson)
    }
  }

  const theme = useTheme();

  return <Box display="flex" alignItems="center">
    {handout ? <BlobProvider document={handout(klass.id)}>
      {({blob, url, loading, error}) => {
      return <>
        {loading && <CircularProgress size="2rem" />}
        {!loading && !error && <FontAwesomeIcon icon={faCheck} size="2x" color={theme.palette.green.main} />}
        {!loading && error && <Typography color={"error"}>X</Typography>}
        <Box ml={2} mr={2}>
          <Typography variant="subtitle1">{klass.klass_name}</Typography>
        </Box>
        {!loading && !error && blob &&
            <Button variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faDownload} />} 
                    onClick={() => {
                      ParentInvites.track(klass, TrackParentInviteMethod.download, trackingLocation)
                      savePrintoutFromBlob(filename(klass), { pdf: blob })
                    } }>Download</Button>
        }
      </>
    }}
    </BlobProvider> : <>
      {generating && <CircularProgress size="2rem" />}
      {!generating && <FontAwesomeIcon icon={faCheck} size="2x" color={theme.palette.green.main} />}
      <Box ml={2} mr={2}>
        <Typography variant="subtitle1">{klass.klass_name}</Typography>
      </Box>
      {!generating && <Button variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faDownload} />} onClick={() => handleDownloadGeneratedPrintout() }>Download</Button>}
      </>}
    </Box>
}