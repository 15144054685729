import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";

export const handleCaptchaValidation = async (recaptchaValue : string | null) => {
    try {
      const response = await justFetch(endpoints.recaptcha, 'POST', {
        recaptcha_token: recaptchaValue
      });
  
      if (!response.ok) {
        throw new Error('Recaptcha token invalid');
      }
  
      const result = await response.json();
  
      if (!result?.valid) {
        throw new Error('Recaptcha token invalid');
      }
  
      return result?.valid;
  
    } catch (error) {
      return false
    }
}