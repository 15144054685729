import { Box, Dialog, DialogActions, DialogContent, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { DialogStateProps } from "hooks/useDialogState";
import React, { useEffect } from "react";
import useCurrentUser from "loaders/useCurrentUser";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { useTracking } from "context/TrackingProvider";

interface PaywallDialogStateProps extends DialogStateProps {
  plan: 'Premium' | 'Premium+';
  copy: 'Tool' | 'Content'
}

interface PaywallDialogContentProps extends PaywallDialogStateProps {
  trackClickedPaywall: (cta: 'White paper' | 'Quote') => void;
}

const usePaywallDialogStyles = makeStyles(() => ({
  header: {
    '&:after': {
      content: "'_'",
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 0 15px 1000px',
      borderColor: 'transparent transparent white transparent'
    }
  },
  paper: {
    overflow: 'hidden'
  }
}));

const TeacherPaywallDialog: React.VFC<PaywallDialogStateProps> = ({ open, onClose, plan, copy }) => {
  const paywallDialogClasses = usePaywallDialogStyles();
  const { currentUser } = useCurrentUser()
  const { track } = useTracking();
  const history = useHistory();

  useEffect(() => {
    track('Viewed Paywall', {
      'Paywall Type': copy,
      'Paywall Plan': plan
    }, ['amplitude', 'intercom']);
  }, []);

  const trackClickedPaywall = (cta: 'White paper' | 'Quote') => {
    track('Clicked on paywall', {
      'CTA': cta,
      'Paywall Type': copy,
      'Paywall Plan': plan
    }, ['amplitude', 'intercom']);
  }

  return <Dialog fullWidth open={open} classes={{ paper: paywallDialogClasses.paper }}>
    <Box width="100%" bgcolor={plan === "Premium" ? "#00AEED" : "#c22e96"}>
      <Box position="relative" width="100%" height="100%" px={4} pt={4} pb={5} display="flex" alignItems="center" justifyContent="space-between" style={{
        backgroundImage: "radial-gradient(circle at center, rgba(0, 0, 0, 0.1) 0.15rem, transparent 0), radial-gradient(circle at center, rgba(0, 0, 0, 0.1) 0.15rem, transparent 0)",
        backgroundSize: '1.3rem 1.3rem',
        backgroundPosition: '0 0, 0.65rem 0.65rem'
      }}
        className={paywallDialogClasses.header}
      >
        <img style={{ height: 40 }} src="/images/paywall/logo.png" />
        <Box display="flex" alignItems="center">
          <img style={{ height: 50 }} src={plan === "Premium" ? "/images/paywall/premium_badge.png" : "/images/paywall/premium_plus_badge.png"} />
          <Box color="white" fontFamily="Bjola" fontSize={22} ml={2} mt={2}>
            Premium{plan === 'Premium' ? '' : '+'} Plan{plan === 'Premium' ? 's' : ''}
          </Box>
        </Box>
      </Box>
    </Box>
    <IconButton
      style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}
      onClick={onClose}
    ><Close /></IconButton>
    <DialogContent style={{ overflowX: 'hidden' }}>
      {!currentUser?.over_student_limit &&
        <TeacherPaywallDialogContent {...{ open, onClose, plan, copy, trackClickedPaywall }} />
      }
      {currentUser?.over_student_limit &&
        <TeacherOverStudentLimitDialogContent {...{ open, onClose, plan, copy, trackClickedPaywall }} />
      }
    </DialogContent>
    <DialogActions>
      <Box display="flex" justifyContent="center" width="100%">
        {!currentUser?.over_student_limit &&
            
              <Button
                variant="outlined"
                color={plan === 'Premium' ? 'primary' : 'newPink'}
                href="https://s3.us-west-2.amazonaws.com/resources.kodable.com/other/KodableInformation.pdf"
                {...{ target: '_blank' }}
                onClick={() => trackClickedPaywall('Quote')}
              >Download Details</Button>
            
        }
        {currentUser?.over_student_limit &&
          <Button
            style={{
              margin: '0 auto',
              display: 'block'
            }}
            variant="contained"
            color="blue"
            onClick={() => {
              history.push(routes.classes.index)
            } }
          >
            Manage My Classes
          </Button>
        }
      </Box>
    </DialogActions>
    
  </Dialog>;
}

const TeacherPaywallDialogContent: React.VFC<PaywallDialogContentProps> = ({ open, onClose, plan, copy, trackClickedPaywall }) => {
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
      <Box textAlign="center" fontFamily="Bjola" fontSize={24} mb="22px">
        {copy === "Content" && `Boost your school's Digital Literacy with Kodable Premium${plan === 'Premium' ? '' : '+'}!`}
        {copy === "Tool" && `Manage rostering and direct student learning with Kodable Premium${plan === 'Premium' ? '' : '+'}!`}
      </Box>
      <Box textAlign="center" fontSize={14} mb={3} width="70%">
        {copy === "Content" && "Level up your instruction with Kodable's extended curriculum and powerful teaching resources."}
        {copy === "Tool" && "Tools for everything from account setup to managing student access, for your entire school or district."}
      </Box>
      <Box mb={3}>
        <Button
          variant="contained"
          color={plan === 'Premium' ? 'primary' : 'newPink'}
          onClick={() => { onClose();  trackClickedPaywall('Quote'); history.push(routes.sales.quotes.create.new) }}
        >Get a Quote</Button>
      </Box>
      <Box bgcolor="#F5F5F5" display="flex" alignItems="center" position="relative" right={-24} mb={4} textAlign="right" width="calc(100% + 12px)">
        {plan === 'Premium' && copy === 'Content' && <>
          <img style={{ height: 150, marginLeft: 16, marginRight: 16 }} src="/images/paywall/martino.png" />
          <Box fontSize={13} pr={3} py={2}>
            <Box mb={0.5}>"I use Kodable to teach coding concepts from Kindergarten through Fifth Grade. I love the user-friendly interface for both the teacher and students. I mostly love the excited faces I see when I tell the students that we are going onto Kodable today to learn a new concept."</Box>
            <Box>&mdash; Christopher Martino, PS 171, Manhattan, NY</Box>
          </Box>
        </>}
        {plan === 'Premium+' && copy === 'Content' && <>
          <img style={{ height: 150, marginLeft: 16, marginRight: 16 }} src="/images/paywall/selikoff.png" />
          <Box fontSize={13} pr={3} py={2}>
            <Box mb={0.5}>"Kodable has been a great introduction and a way to get students hooked on coding at a young age! Students always ask to work on Kodable and the site lives in our 'early finisher' sites."</Box>
            <Box>&mdash; Samantha Selikoff, Technology/STEM at Thomas Jefferson Middle School</Box>
          </Box>
        </>}
        {copy === 'Tool' && <>
          <img style={{ height: 150, marginLeft: 16, marginRight: 16 }} src="/images/paywall/gardiner.png" />
          <Box fontSize={13} pr={3} py={2}>
            <Box mb={0.5}>"Kodable is a game changer for our elementary school students. It is a fun way for our students as young as Kindergarten to engage and understand coding. Our students can't wait to use Kodable in their classes. Kodable is an outstanding way to make coding accessible for all students!"</Box>
            <Box>&mdash; Sean Gardiner, Director of Curriculum and Instruction for STEM Education, Upper Merion Area School District</Box>
          </Box>
        </>}
      </Box>
      {copy === 'Content' && <Box display="flex" alignItems="center" width="100%" justifyContent="space-around" textAlign="center">
        <Box display="flex" alignItems="center" flexDirection="column" maxWidth={140}>
          <img src="/images/paywall/icon1.png" style={{ height: 70, filter: plan === 'Premium+' ? 'hue-rotate(120deg) saturate(1.5) brightness(0.8)' : undefined }} />
          <Box mt={2} fontFamily="Bjola">Access every game level, no limitations.</Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column" maxWidth={140}>
          <img src="/images/paywall/icon2.png" style={{ height: 70, filter: plan === 'Premium+' ? 'hue-rotate(120deg) saturate(1.5) brightness(0.8)' : undefined }} />
          <Box mt={2} fontFamily="Bjola">Unplugged activities for every course.</Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column" maxWidth={140}>
          <img src="/images/paywall/icon3.png" style={{ height: 70, filter: plan === 'Premium+' ? 'hue-rotate(120deg) saturate(1.5) brightness(0.8)' : undefined }} />
          <Box mt={2} fontFamily="Bjola">Learn with 30+ video lessons.</Box>
        </Box>
      </Box>}
      {copy === 'Tool' && <Box display="flex" alignItems="center" width="100%" justifyContent="space-around" textAlign="center">
        <Box display="flex" alignItems="center" flexDirection="column" maxWidth={150}>
          <img src="/images/paywall/icon4.png" style={{ height: 70, filter: plan === 'Premium+' ? 'hue-rotate(120deg) saturate(1.5) brightness(0.8)' : undefined }} />
          <Box mt={2} fontFamily="Bjola">Customize assignments with teacher tools.</Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column" maxWidth={140}>
          <img src="/images/paywall/icon5.png" style={{ height: 70, filter: plan === 'Premium+' ? 'hue-rotate(120deg) saturate(1.5) brightness(0.8)' : undefined }} />
          <Box mt={2} fontFamily="Bjola">Track student or class usage with reports.</Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column" maxWidth={170}>
          <img src="/images/paywall/icon6.png" style={{ height: 70, filter: plan === 'Premium+' ? 'hue-rotate(120deg) saturate(1.5) brightness(0.8)' : undefined }} />
          <Box mt={2} fontFamily="Bjola">Manage license access, so every teacher can join!</Box>
        </Box>
      </Box>}
    </Box>
  )
}

const TeacherOverStudentLimitDialogContent: React.VFC<PaywallDialogContentProps> = ({ open, onClose, plan, copy, trackClickedPaywall }) => {
  const { currentUser } = useCurrentUser()
  const sharedClasses = useSharedStyles()

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
      <Box textAlign="center" fontFamily="Bjola" fontSize={24} mb="22px">
        You are over your student limit!
      </Box>
      <Box className={sharedClasses?.vspacing2}>
        <Typography>You currently have <strong>{currentUser?.total_student_count}</strong> students, but your subscription has a limit of <strong>{currentUser?.student_limit}</strong>.</Typography>
        <Typography>Your access to premium features has been limited until you resolve this. Please remove the extra students.</Typography>
      </Box>
    </Box>
  )
}

export default TeacherPaywallDialog;