import {DialogStateProps} from "hooks/useDialogState";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import {justFetch} from "../../../../mutations/mutate";
import endpoints from "../../../../endpoints";
import React from "react";

const DeleteQuoteDialog: React.VFC<DialogStateProps & {quoteId: string|undefined, secret: string|undefined, onSuccess: () => void}> = ({quoteId, secret, onSuccess, open, onClose, handleOpen}) => {

  const deleteQuote = async () => {
    if (!secret) {
      return
    }
    try {
      const resp = await justFetch(endpoints.deleteQuote(secret), 'DELETE')
      if (resp.ok) {
        onSuccess()
        onClose()
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Quote {quoteId}?</DialogTitle>
      <DialogContent>Are you sure you want to delete this quote? This cannot be undone.</DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={onClose}>Close</Button>
        <Button variant={"contained"} color={'red'} onClick={deleteQuote}>Delete it!</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteQuoteDialog