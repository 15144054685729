import {Box, CircularProgress, Divider, Grid} from "@material-ui/core"
import {GodmodePageWrapper} from "pages/godmode/GodmodePageWrapper"
import {RevenueStatsProvider, useRevenueStatsContext} from "context/RevenueStatsProvider";
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import SchoolChurnChart from "./charts/SchoolChurnChart";
import ParentChurnChart from "./charts/ParentChurnChart";
import RevenueChart from "./charts/RevenueChart";
import RevenueScoreCards from "./RevenueScoreCards";
import React from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export type RevenueIndexIntervals = 'daily' | 'weekly' | 'monthly' | 'quarterly';

export const RevenueIndex: React.FC = () => {
    return (
        <GodmodePageWrapper title="Revenue" loading={false}>
            <RevenueStatsProvider>
                <RevenueIndexContent />
            </RevenueStatsProvider>
        </GodmodePageWrapper>
    )
}

const RevenueIndexContent: React.FC = () => {
    const { loading } = useRevenueStatsContext()

    if (loading) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" p={4}>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Grid container>
            <RevenueScoreCards />
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid container item xs={12} justifyContent={"center"}>
                <RevenueChart />
                <ParentChurnChart />
                <SchoolChurnChart />
            </Grid>
        </Grid>
    )
}

