import ShareModal from "../../../components/modal/ShareModal";
import React from "react";

const ShareLessonDialog: React.VFC<{open: boolean, onClose: () => void, lessonUrl: string, lessonDescription: string}> = ({open, onClose, lessonUrl, lessonDescription}) => {
  const emailConfig = {
    body: lessonDescription,
    subject: "Get this free coding lesson from @kodable"
  }
  return (
    <ShareModal
      shareUrl={lessonUrl}
      email={emailConfig}
      onClose={onClose}
      open={open}
      title={'Share Lesson'}
    />
  )
}

export default ShareLessonDialog