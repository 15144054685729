import Cookies from 'universal-cookie';
import { saveAs } from 'file-saver';

export default function usePrintFuzzPdf(studentId: number, fuzzId: number) {
  const cookies = new Cookies();

  const handoutUrl = `creative/fuzz/student/${studentId}?fuzzId=${fuzzId}`;

  const url = `${process.env.REACT_APP_HANDOUT_URL}?kodable_token=${cookies.get(
    'kodable_kode'
  )}&handout=${handoutUrl}&env=${
    process.env.REACT_APP_HANDOUT_ENV
  }&response=json`;

  return (args?: any): Promise<void> => {
    return fetch(url)
      .then((res) => res.json())
      .then((data) => {
        const base64str = data.pdf;

        // decode base64 string, remove space for IE compatibility
        const binary = atob(base64str.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }

        saveAs(new Blob([view], { type: 'application/pdf' }), 'CustomFuzz.pdf');
      });
  };
}
