import { Route, useRouteMatch } from 'react-router-dom';
import { Switch, Redirect } from 'react-router-dom';
import StudentHomeProgress from './shared/StudentHomeProgress';

export default function StudentProgressPage() {
  const { path } = useRouteMatch();


  return (
    <div className="max-w-screen-lg mt-8 mx-8 mb-24">
      <Switch>
        <Route exact path={`${path}/home`}>
          <StudentHomeProgress />
        </Route>
        <Redirect to={`${path}/home`} />
      </Switch>
    </div>
  );
}
