import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'components/modal/Modal';
import useCreateStudent from 'mutations/useCreateStudent';
import useImportStudent from 'mutations/useImportStudent';
import useParentInit, { reload } from 'loaders/useParentInit';
import useCurrentUser from 'loaders/useCurrentUser';
import { motion, AnimatePresence } from 'framer-motion';
import { useAlertDispatch } from 'context/AlertProvider';
import find from 'lodash/find';

export interface NewStudentModalProps {
  closeModal: () => void;
  defaultStep?: string;
}

const steps = {
  INITIAL: 'INITIAL',
  ADD_NEW_PLAYER: 'ADD_NEW_PLAYER',
  USE_PARENT_CODE: 'USE_PARENT_CODE',
};

const alertId = () => '_' + Math.random().toString(36).substr(2, 9);

const CreateStudent: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const [name, setName] = useState('');
  const createStudent = useCreateStudent();
  const dispatch = useAlertDispatch();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const autoHideAlert = (id: string) => setTimeout(() => {
    dispatch({ type: 'removeAlert', id: id });
  }, 3000);

  return (
    <motion.div
      className="w-full flex-shrink-0"
      key={steps.ADD_NEW_PLAYER}
      initial={{ zIndex: 1, x: 1000, opacity: 0 }}
      animate={{ zIndex: 1, x: 0, opacity: 1 }}
      exit={{ zIndex: 0, x: -1000, opacity: 0 }}
      transition={{
        default: { duration: 0.4 },
      }}>
      <div className="flex flex-col justify-center items-center">
        <div className="relative font-bold text-3xl text-white w-full p-6 bg-kodable-green text-center mx-auto flex flex-col justify-center items-center">
          <svg
            aria-hidden="true"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            className="h-12 w-12">
            <path
              fill="currentColor"
              d="M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
          </svg>
          Creating Student
        </div>
        <form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
            if (name === '') {
              let id = alertId();
              dispatch({
                id: id,
                type: 'error',
                title: 'Student Not Created!',
                message: 'Name cannot be empty.',
                });
              autoHideAlert(id);
              return;
            }

            setSubmitting(true);

            createStudent({ name }).then((student) => {
              if (student.id) {
                reload()
                  .then(() => {
                    dispatch({
                      id: 'addStudent',
                      type: 'success',
                      title: 'Student Created!',
                      message: 'Student was successfully created.',
                    });
                    setTimeout(() => {
                      dispatch({ type: 'removeAlert', id: 'addStudent' });
                    }, 3000);
                    history.push(`/students/${student.id}/overview`);
                    closeModal();
                  })
                  .catch(() => {
                    dispatch({
                      id: 'student_not_created_error',
                      type: 'error',
                      title: 'Student Not Created!',
                      message: 'There was an error creating this student.',
                    });
                  });
              } else {
                dispatch({
                  id: 'student_not_created_error',
                  type: 'error',
                  title: 'Student Not Created!',
                  message: 'There was an error creating this student.',
                });
              }
            })
              .finally(() => setSubmitting(false));
          }}>
          <div className="bg-gray-100 py-10 px-4 border-b border-gray-300">
            <label
              htmlFor="name"
              className="block font-bold text-gray-700 mb-2">
              Name
            </label>
            <input
              placeholder="Student Name"
              onChange={(e) => setName(e.target.value)}
              autoComplete="false"
              name="name"
              id="name"
              type="text"
              className="border rounded p-2 w-full"
            />
          </div>

          <div className="flex justify-end items-center my-4 px-4">
            <button
              type="button"
              onClick={closeModal}
              className="flex items-center justify-center text-gray-500 border border-gray-200 bg-white hover:bg-gray-100 active:bg-gray-200 px-4 py-2 rounded">
              Cancel
            </button>
            <button
              className="flex items-center justify-center bg-kodable-green border border-kodable-green hover:border-kodable-green  hover:bg-kodable-green-dark active:bg-kodable-green-darker active:border-kodable-green px-4 py-2 rounded text-white ml-6"
              disabled={submitting}
            >
              Create Student
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
}

const ImportStudent: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const [code, setCode] = useState('');
  const { currentUser } = useCurrentUser();
  const { students } = useParentInit();
  const importStudent = useImportStudent();
  const dispatch = useAlertDispatch();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const autoHideAlert = (id: string) => setTimeout(() => {
    dispatch({ type: 'removeAlert', id: id });
  }, 3000);

  return (
    <motion.div
      className="w-full flex-shrink-0"
      key={steps.USE_PARENT_CODE}
      initial={{ x: 1000 }}
      animate={{ zIndex: 1, x: 0 }}
      exit={{ zIndex: 0, x: -1000 }}
      transition={{
        default: { duration: 0.4 },
      }}>
      <div className="flex flex-col justify-center items-center">
        <div className="relative text-white w-full p-6 bg-kodable-blue text-center">
          <div className="font-bold text-3xl">Import an existing student</div>
          <p className="">
            You can import an existing student to your account using their
            unique Parent Code
          </p>
        </div>
        <form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
            if (code === '') {
              let id = alertId();
              dispatch({
                id: id,
                type: 'error',
                title: 'Student Not Created!',
                message: 'Student code cannot be empty.',
                });
              autoHideAlert(id);
              return;
            }

            const existingStudent = students?.find(student => student.student_code.toLowerCase() === code.toLowerCase() + '_pi');

            if (existingStudent) {
              reload()
                .then(() => {
                  dispatch({
                    id: 'addStudent',
                    type: 'success',
                    title: 'Student Imported!',
                    message: `${existingStudent.name}’s profile is linked.`,
                  });
                  setTimeout(() => {
                    dispatch({ type: 'removeAlert', id: 'addStudent' });
                  }, 3000);
                  closeModal();

                  history.push(
                    `/students/${
                      existingStudent.id
                    }/overview`
                  );
                });
              return;
            }

            setSubmitting(true);

            importStudent({
              student_codes: [code],
              teacher_id: currentUser.id,
            }).then((student) => {
              if (!student.error) {
                if (
                  student.message ===
                  'This profile already imported by another user' ||
                  student.error_type === 'KodableStandardError'
                ) {
                  let id = alertId();
                  dispatch({
                    id: id,
                    type: 'error',
                    title: 'Student Not Imported!',
                    message: student.message,
                  });
                  autoHideAlert(id);
                  return;
                }

                reload()
                  .then((result) => {
                    dispatch({
                      id: 'addStudent',
                      type: 'success',
                      title: 'Student Imported!',
                      message: 'Student was successfully imported.',
                    });
                    setTimeout(() => {
                      dispatch({ type: 'removeAlert', id: 'addStudent' });
                    }, 3000);
                    closeModal();

                    history.push(
                      `/students/${
                        find(student, { student_code: code.toLowerCase() }).id
                      }/overview`
                    );
                  })
                  .catch(() => {
                    let id = alertId();
                    dispatch({
                      id: id,
                      type: 'error',
                      title: 'Student Not Imported!',
                      message: 'There was an error importing this student.',
                    });
                    autoHideAlert(id);
                  });
              } else {
                let id = alertId();
                dispatch({
                  id: id,
                  type: 'error',
                  title: 'Student Not Imported!',
                  message: 'Student code is incorrect.',
                });
                autoHideAlert(id);
              }
            })
              .finally(() => setSubmitting(false));
          }}>
          <div className="bg-gray-100 py-10 px-4 border-b border-gray-300">
            <label
              htmlFor="name"
              className="block font-bold text-gray-700 mb-2">
              Parent Code
            </label>
            <input
              onChange={(e) => setCode(e.target.value.toLowerCase().trim())}
              value={code}
              autoComplete="false"
              placeholder="Parent Code"
              name="parent_code"
              id="parent_code"
              type="text"
              className="border rounded p-2 w-full"
            />
            <p className="text-center mt-2 text-gray-600">
              Get a parent code from your child's teacher.
            </p>
          </div>

          <div className="flex justify-end items-center my-4 px-4">
            <button
              type="button"
              onClick={closeModal}
              className="flex items-center justify-center text-gray-500 border border-gray-200 bg-white hover:bg-gray-100 active:bg-gray-200 px-4 py-2 rounded">
              Cancel
            </button>
            <button
              className="flex items-center justify-center bg-kodable-blue border border-kodable-blue hover:border-kodable-blue  hover:bg-kodable-blue-dark active:bg-kodable-blue-darker active:border-kodable-blue px-4 py-2 rounded text-white ml-6"
              disabled={submitting}
            >
              Import Player
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
}

const NewStudentModal: React.FC<NewStudentModalProps> = ({ closeModal, defaultStep = steps.INITIAL }) => {
  const [step, setStep] = useState(defaultStep);

  return (
    <Modal closeModal={closeModal}>
      <button
        type="button"
        onClick={closeModal}
        className={`${
          step === steps.INITIAL ? 'text-gray-800' : 'text-white'
        } absolute right-0 top-0 p-2 lg:p-4 rounded z-50`}>
        <svg
          className="h-6 w-6 lg:h-8 lg:w-8"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 111.414 1.414L11.414 10l4.293 4.293a1 1 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 01-1.414-1.414L8.586 10 4.293 5.707a1 1 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div className="relative flex overflow-hidden">
        <AnimatePresence initial={false}>
          {step === steps.INITIAL && (
            <motion.div
              className="w-full flex-shrink-0"
              key={steps.INITIAL}
              initial={{ zIndex: 0, x: 1000, opacity: 1 }}
              animate={{ zIndex: 1, x: 0, opacity: 1 }}
              exit={{ zIndex: 0, x: -1000, opacity: 0, width: 0 }}
              transition={{
                default: { duration: 0.4 },
              }}>
              <div className="flex flex-col justify-center items-center m-12">
                <button
                  type="button"
                  onClick={() => setStep(steps.ADD_NEW_PLAYER)}
                  className="flex items-center justify-center bg-kodable-green hover:bg-kodable-green-dark active:bg-kodable-green-darker px-6 py-3 text-lg rounded text-white mr-6 mb-8">
                  <svg
                    aria-hidden="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="w-6 h-6 mr-4">
                    <path
                      fill="currentColor"
                      d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                  Add New Player
                </button>
                <button
                  type="button"
                  onClick={() => setStep(steps.USE_PARENT_CODE)}
                  className="flex items-center justify-center bg-kodable-blue hover:bg-kodable-blue-dark active:bg-kodable-blue-darker px-6 py-3 text-lg rounded text-white mr-6">
                  <svg
                    aria-hidden="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    className="w-6 h-6 mr-4">
                    <path
                      fill="currentColor"
                      d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path>
                  </svg>
                  Use Parent Code
                </button>
              </div>
            </motion.div>
          )}
          {step === steps.ADD_NEW_PLAYER && (
            <CreateStudent closeModal={closeModal} />
          )}
          {step === steps.USE_PARENT_CODE && (
            <ImportStudent closeModal={closeModal} />
          )}
        </AnimatePresence>
      </div>
    </Modal>
  );
};

export default NewStudentModal;
