import { Box, BoxProps, Typography } from "@material-ui/core";
import React from "react";

interface KeyValuePairProps {
  title: string | number;
  value: React.ReactNode;
}

const KeyValuePair: React.VFC<BoxProps & KeyValuePairProps> = ({ title, value, ...boxProps }) => {
  return <Box {...boxProps}>
    <Typography variant="subtitle1">{title}</Typography>
    <Typography>{value}</Typography>
  </Box>
}

export default KeyValuePair;