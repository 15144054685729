import { Box, CircularProgress, Typography } from "@material-ui/core"
import useSharedStyles from "components/useSharedStyles"
import { IOrganizationProfileNote } from "types/IOrganizationProfile"
import OrganizationProfileActivityItem from "./OrganizationProfileActivityItem"
import React from "react";

const OrganizationProfileActivityTimeline: React.FC<{notes: IOrganizationProfileNote[] | undefined, isValidatingNotes: boolean, notesError: any, mutateNotes: () => void}> = ({notes, isValidatingNotes, notesError, mutateNotes}) => {
    const sharedClasses = useSharedStyles()

    if (isValidatingNotes) {
        return (
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '2rem',
                    width: '100%'
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    if (notesError) {
        return (
          <Box
              style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%'
              }}
          >
              <Typography>An unknown error occurred when loading notes.</Typography>
          </Box>
        )
    }

    if (!notes) {
        return (
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%'
                }}
            >
                <Typography variant="body1">There are no notes to display.</Typography>
            </Box>
        )
    }

    return (
        <Box className={sharedClasses?.vspacing4}>
            {notes.sort((a, b) => {
                const dateA = new Date(a.created_at).getTime();
                const dateB = new Date(b.created_at).getTime();
                return dateB - dateA; 
            }).map((note, index) => {
                return (
                    <OrganizationProfileActivityItem key={index} note={note} mutateNotes={mutateNotes} />
                )
            })}
        </Box>
    )
}

export default OrganizationProfileActivityTimeline;