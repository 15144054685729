import React from "react";
import useDialogState from "../../../../hooks/useDialogState";
import useSharedStyles from "../../../../components/useSharedStyles";
import {Box, Paper, Typography, useTheme} from "@material-ui/core";
import AddStudentsDialog from "../../../../components/dialogs/class/AddStudentsDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../components/ui/buttons/Button";

export const NoStudentsWidget: React.VFC<{ klass_id: string | number }> = ({klass_id}) => {
  const addStudentsDialog = useDialogState();
  const sharedClasses = useSharedStyles();
  const theme = useTheme();

  return <Box
    component={Paper} {...{variant: 'outlined'}}
    p={4}
    display="flex" flexDirection="row" alignItems="center" justifyContent="center"
  >
    <AddStudentsDialog
      {...addStudentsDialog}
      targetClassId={klass_id.toString()}
    />
    <Box p={2} mr={4}>
      <FontAwesomeIcon icon={faExclamationTriangle} color={theme.palette.red.main} size="2x"/>
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      className={sharedClasses.vspacing2}
    >
      <Typography variant="h2">
        Your class has no students
      </Typography>

      <Typography>Add some now to start coding.</Typography>
      <Box>
        <Button
          variant="contained"
          color="green"
          onClick={addStudentsDialog.handleOpen}
        >Add my students</Button>
      </Box>

    </Box>
  </Box>;
}