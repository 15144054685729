import {useLocation} from 'react-router-dom';
import {isPublicRoute} from 'routes';
import {SWRConfig} from 'swr';

import Cookies from 'universal-cookie';
import {useRedirectToLogin} from './AuthProvider';
import React from "react";
import {addBreadcrumb, Severity} from "@sentry/react";

const cookies = new Cookies();


const SWRProvider: React.FC = ({ children }) => {
  const { redirectToLogin } = useRedirectToLogin();
  const location = useLocation();

  const swrConfig = {
    revalidateOnFocus: false,
    fetcher: (url: string) => {
      return fetch(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, {
        headers: { 'KODABLE-API-TOKEN': cookies.get('kodable_kode') }
      })
        .then(async (res) => {
          if (!res.ok) {
            if (res.status === 401 && !isPublicRoute(location)) {
              redirectToLogin();
            }

            throw await res.json();
          }

          return res.json();
        })
    },
    onError: (error: any, key: string) => {
      if (error["sentry_issue_id"]){
        addBreadcrumb({
          message: `API Error: ${error.message}`,
          category: 'error',
          type: 'api',
          level: Severity.Error,
          data: {
            api_sentry_id: error["sentry_issue_id"]
          }
        })
      }
    }
  }

  return <SWRConfig value={swrConfig}>{children}</SWRConfig>
}

export default SWRProvider;