import React, { Fragment } from 'react';
import TopNav from './shared/TopNav';
import { saveAs } from 'file-saver';
import Amplitude from 'utils/Amplitude';

const WorksheetsPage: React.FC = () => {
  const downloadPacket = (url: string, grade: string) => {
    const fileName = `kodable-packets-${grade}.pdf`;

    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        Amplitude.track('Downloaded Covid Resources Packets', {
          packet: fileName,
        });

        saveAs(new Blob([blob]), fileName);
      });
  }

  return (
    <Fragment>
      <TopNav
        title="Worksheets"
        navLinks={[
          { title: 'Learning Tools', to: '/learning-tools' },
          { title: 'Worksheets', to: '/learning-tools/worksheets' },
        ]}
      />
      <div className="flex flex-col justify-center items-center w-full">
        <div className="my-8 w-full max-w-screen-lg px-2 mb-24">
          <div>
            <button
              className="bg-kodable-teal text-white rounded px-6 py-2 flex justify-between items-center mt-4 mb-4"
              onClick={() =>
                downloadPacket(
                  'https://s3-us-west-2.amazonaws.com/resources.kodable.com/public/activities/kodable-packets-K1-5_small.pdf',
                  'K1-5'
                )
              }>
              Download All (35 Pages)
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
            <div className="flex flex-col justify-center items-center">
              <h4 className="font-bold text-gray-700 my-2">Kindergarten Packets</h4>
              <img
                src="/images/virtual-learing/packets/1.png"
                alt="All Packets"
                className="w-full max-w-sm"
                width="314"
                height="407"
              />
              <button
                className="bg-kodable-teal text-white rounded px-6 py-2 flex justify-between items-center mt-4 mb-4"
                onClick={() =>
                  downloadPacket(
                    'https://s3-us-west-2.amazonaws.com/resources.kodable.com/public/activities/kodable-packets-K-1stgrade.pdf',
                    'Kindergarten'
                  )
                }>
                Download
              </button>
            </div>
            <div className="flex flex-col justify-center items-center">
              <h4 className="font-bold text-gray-700 my-2">1st Grade Packets</h4>
              <img
                src="/images/virtual-learing/packets/2.png"
                alt="1st Grade Packets"
                className="w-full max-w-sm"
                width="314"
                height="407"
              />
              <button
                className="bg-kodable-teal text-white rounded px-6 py-2 flex justify-between items-center mt-4 mb-4"
                onClick={() =>
                  downloadPacket(
                    'https://s3-us-west-2.amazonaws.com/resources.kodable.com/public/activities/kodable-packets-K-1stgrade.pdf',
                    '1st Grade'
                  )
                }>
                Download
              </button>
            </div>
            <div className="flex flex-col justify-center items-center">
              <h4 className="font-bold text-gray-700 my-2">2nd Grade Packets</h4>
              <img
                src="/images/virtual-learing/packets/3.png"
                alt="2nd Grade Packets"
                className="w-full max-w-sm"
                width="314"
                height="407"
              />
              <button
                className="bg-kodable-teal text-white rounded px-6 py-2 flex justify-between items-center mt-4 mb-4"
                onClick={() =>
                  downloadPacket(
                    'https://s3-us-west-2.amazonaws.com/resources.kodable.com/public/activities/kodable-packets-2-3rdGrade.pdf',
                    '2nd Grade'
                  )
                }>
                Download
              </button>
            </div>
            <div className="flex flex-col justify-center items-center">
              <h4 className="font-bold text-gray-700 my-2">3rd Grade Packets</h4>
              <img
                src="/images/virtual-learing/packets/4.png"
                alt="3rd Grade Packets"
                className="w-full max-w-sm"
                width="314"
                height="407"
              />
              <button
                className="bg-kodable-teal text-white rounded px-6 py-2 flex justify-between items-center mt-4 mb-4"
                onClick={() =>
                  downloadPacket(
                    'https://s3-us-west-2.amazonaws.com/resources.kodable.com/public/activities/kodable-packets-2-3rdGrade.pdf',
                    '3rd Grade'
                  )
                }>
                Download
              </button>
            </div>
            <div className="flex flex-col justify-center items-center">
              <h4 className="font-bold text-gray-700 my-2">4th Grade Packets</h4>
              <img
                src="/images/virtual-learing/packets/5.png"
                alt="4th Grade Packets"
                className="w-full max-w-sm"
                width="314"
                height="407"
              />
              <button
                className="bg-kodable-teal text-white rounded px-6 py-2 flex justify-between items-center mt-4 mb-4"
                onClick={() =>
                  downloadPacket(
                    'https://s3-us-west-2.amazonaws.com/resources.kodable.com/public/activities/kodable-packets-4-5thGrade.pdf',
                    '4th Grade'
                  )
                }>
                Download
              </button>
            </div>
            <div className="flex flex-col justify-center items-center">
              <h4 className="font-bold text-gray-700 my-2">5th Grade Packets</h4>
              <img
                src="/images/virtual-learing/packets/6.png"
                alt="5th Grade Packets"
                className="w-full max-w-sm"
                width="314"
                height="407"
              />
              <button
                className="bg-kodable-teal text-white rounded px-6 py-2 flex justify-between items-center mt-4 mb-4"
                onClick={() =>
                  downloadPacket(
                    'https://s3-us-west-2.amazonaws.com/resources.kodable.com/public/activities/kodable-packets-4-5thGrade.pdf',
                    '5th Grade'
                  )
                }>
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default WorksheetsPage;