import {ISubscriptionTerm} from "../../types/ISubscription";
import {getPlanName} from "../../utils/planHelpers";
import {Box, makeStyles, Paper, Typography} from "@material-ui/core";
import Button from "../ui/buttons/Button";
import {faPencil} from "@fortawesome/free-solid-svg-icons/faPencil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

type AccountInformationBoxProps = {
  subscription: {
    plan: string | null,
    term: ISubscriptionTerm
  },
  onEditAccount: () => void,
  onDeleteAccount: () => void,
  name: string,
  email: string
}


const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 10,
    fontWeight: 500
  },
  field: {
    '& > *': {
      // Adding margin to all direct child elements
      marginRight: 4,
      marginBottom: 4
    },
  },
  bold: {
    fontWeight: 'bold'
  }
}))

const AccountInformationBox: React.VFC<AccountInformationBoxProps> = ({subscription, name, email, onDeleteAccount, onEditAccount}) => {
  const classNames = useStyles()

  return (
    <Box component={Paper} display={'flex'} flexDirection={'column'} mb={4} pb={2} pt={2} px={2} maxWidth={990}>
      <Box mb={4}>
        <Typography variant={'h4'} className={classNames.title}>Account Information</Typography>
        <Box display={'flex'} flexDirection={'row'} className={classNames.field}>
          <Typography className={classNames.bold}>Your Plan: </Typography>
          <Typography>{getPlanName(subscription.term)} {subscription.plan !== 'Free' ? 'Subscription' : ''}</Typography>
        </Box>
        <Box display={'flex'} flexDirection={'row'} className={classNames.field}>
          <Typography className={classNames.bold}>Name: </Typography>
          <Typography>{name}</Typography>
        </Box>
        <Box display={'flex'} flexDirection={'row'} className={classNames.field}>
          <Typography className={classNames.bold}>Email: </Typography>
          <Typography>{email}</Typography>
        </Box>
      </Box>
      <Box>
        <Box display={'flex'} justifyContent={'flex-end'} >
          <Button
            color={'primary'}
            onClick={onEditAccount}
            variant={'contained'}
            startIcon={<FontAwesomeIcon icon={faPencil} />}
            style={{marginRight: 10}}
          >
            Edit Account
          </Button>
          <Button
            color={'red'}
            onClick={onDeleteAccount}
            variant={'contained'}
            startIcon={<FontAwesomeIcon icon={faTrash} />}
          >
            Delete Account
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AccountInformationBox