import { Box, CircularProgress, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import queryString from 'query-string';
import { justFetch } from "mutations/mutate";
import endpoints from "endpoints";
import { routes } from "routes";
import { useRedirectToLogin } from "context/AuthProvider";


export const QuoteRenewal: React.VFC = () => {
    const { redirectToLogin } = useRedirectToLogin();
    const [quoteLoading, setQuoteLoading] = useState<boolean>(true)
    const [quoteSecretCode, setQuoteSecretCode] = useState<string>('')
    const [quoteError, setQuoteError] = useState<boolean>(false)
    const queryParams = queryString.parse(window.location.search);

    useEffect(() => {
        if (!!queryParams?.subscription_id && typeof queryParams?.subscription_id === 'string') {
            // fetch the quote
            justFetch(endpoints.renewQuoteBySubId(queryParams?.subscription_id), 'GET')
            .then(res => {
                if (!res.ok) {
                    throw new Error()
                }
                return res.json()
            })
            .then(secretCode => {
                setQuoteSecretCode(secretCode)
                setQuoteLoading(false)
            })
            .catch(() => {
                setQuoteError(true)
                setQuoteLoading(false)
            })
        }
    }, [queryParams?.subscription_id])

    useEffect(() => {
        if (quoteSecretCode && quoteSecretCode.length > 0) {
            // redirect to the quote
            window.location.href = routes.sales.quotes.view(quoteSecretCode)
        }
    }, [quoteSecretCode])

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="300px">
            {quoteLoading && !quoteError &&
                <>
                    <Typography style={{marginBottom: '2rem'}} variant="h1">Renewal Quote Loading...</Typography>
                    <CircularProgress />
                    <button
                        onClick={() => redirectToLogin()}
                        className="bg-kodable-green px-4 py-2 rounded text-white mt-8"
                    >
                        Not Loading?
                    </button>
                </>
            }
            {!quoteLoading && quoteError &&
                <>
                    <Typography style={{marginBottom: '2rem'}} variant="h1">We could not find your quote</Typography>
                    <Typography>Please check the link and try again, or access it from your Dashboard</Typography>
                    <button
                        onClick={() => redirectToLogin()}
                        className="bg-kodable-green px-4 py-2 rounded text-white mt-8"
                    >
                        Back to Login
                    </button>
                </>
            }
        </Box>
    )
}