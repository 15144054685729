import { Link, Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, LinearProgress, MenuItem, Radio, RadioGroup, Select, TextField, Typography, Collapse } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import { useTracking } from "context/TrackingProvider";
import { useFormik } from "formik";
import useCurrentUser from "loaders/useCurrentUser";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "routes";
import * as Yup from 'yup';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import states from "states-us/dist";
import { Alert } from "@material-ui/lab";
import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";
import { mutate } from "swr";
import Button from "components/ui/buttons/Button";
import useTeacherInit from "loaders/useTeacherInit";

export const TeacherOnboardingAddSchoolSearch: React.VFC = () => {
  const { track } = useTracking();

  useEffect(() => {
    track('Viewed Onboarding Page', {
      'Page Name': 'Add School Search'
    });
  }, [track]);

  return <SearchForm />
}

export const SearchForm: React.VFC = () => {
  const { state } = useLocation<{ [key in 'zipcode' | 'city' | 'state' | 'searchOption']: string }>();
  const history = useHistory();
  const sharedClasses = useSharedStyles();
  const [showNotInUSDialog, setShowNotInUSDialog] = useState(false);

  const form = useFormik({
    initialValues: {
      zipcode: state?.zipcode || "",
      searchOption: state?.searchOption || "zipcode",
      city: state?.city || "",
      state: state?.state || "",
    },
    validationSchema: Yup.object({
      searchOption: Yup.string().oneOf(["zipcode", "citystate"]),
      zipcode: Yup.string().when("searchOption", {
        is: "zipcode",
        then: Yup.string()
          .required("Please enter a zip code.")
          .matches(/^[0-9]{5}$/, "Please enter a valid zip code."),
      }),
      city: Yup.string().when("searchOption", {
        is: "citystate",
        then: Yup.string().required("Please enter a value."),
      }),
      state: Yup.string().when("searchOption", {
        is: "citystate",
        then: Yup.string().required("Please select a state."),
      }),
    }),
    onSubmit: async ({ searchOption, zipcode, city, state }) => {
      history.replace({ state: { searchOption, zipcode, city, state } });

      if (searchOption === 'zipcode') {
        history.push(routes.teacherOnboarding.addSchool.results, { searchOption, zipcode });
      } else if (searchOption === 'citystate') {
        history.push(routes.teacherOnboarding.addSchool.results, { searchOption, city, state });
      }
    },
  });

  return <form onSubmit={form.handleSubmit} style={{ height: '100%' }}>
    <NotInUSDialog open={showNotInUSDialog} onCancel={() => setShowNotInUSDialog(false)}></NotInUSDialog>
    <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2} height="100%">
      <Box flexGrow={1}>
        <RadioGroup
          id="searchOption"
          onChange={(e) =>
            form.setFieldValue("searchOption", e.target.value)
          }
          value={form.values.searchOption}
        >
          <FormControlLabel
            value="zipcode"
            control={<Radio />}
            label="Search by Zip Code"
          />
          <Collapse in={form.values.searchOption === "zipcode"}>
            <Box mb={1} display="flex" alignItems="start" className={sharedClasses.hspacing2}>
              <TextField
                autoFocus
                onChange={form.handleChange}
                value={form.values.zipcode}
                id="zipcode"
                variant="outlined"
                placeholder="Enter a zip code"
                helperText={form.submitCount > 0 && form.errors.zipcode}
                error={form.submitCount > 0 && !!form.errors.zipcode}
                margin="dense"
                style={{ background: 'white' }}
              />
            </Box>
          </Collapse>
          <FormControlLabel
            value="citystate"
            control={<Radio />}
            label="Search by City and State"
          />
          <Collapse in={form.values.searchOption === "citystate"}>
            <Box display="flex" alignItems="start" className={sharedClasses.hspacing2}>
              <Box mr={2} flex={1} display="flex">
                <TextField
                  autoFocus
                  onChange={form.handleChange}
                  value={form.values.city}
                  id="city"
                  variant="outlined"
                  placeholder="Enter a town or city"
                  fullWidth
                  helperText={form.submitCount > 0 && form.errors.city}
                  error={form.submitCount > 0 && !!form.errors.city}
                  margin="dense"
                  style={{ background: 'white' }}
                />
              </Box>
              <Box>
                <FormControl
                  error={form.submitCount > 0 && !!form.errors.state}
                >
                  <Select
                    variant="outlined"
                    fullWidth
                    displayEmpty
                    onChange={(e) =>
                      form.setFieldValue("state", e.target.value)
                    }
                    value={form.values.state}
                    id="state"
                    margin="dense"
                    style={{ background: 'white' }}
                  >
                    <MenuItem disabled value="">
                      Select your state
                    </MenuItem>
                    {states
                      .filter(({ territory }) => !territory)
                      .map(({ name }) => (
                        <MenuItem key={name} value={name}>{name}</MenuItem>
                      ))}
                  </Select>
                  {form.submitCount > 0 && (
                    <FormHelperText>{form.errors.state}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Box>
          </Collapse>
        </RadioGroup>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Link href="#" onClick={e => {
          e.preventDefault();
          setShowNotInUSDialog(true);
        }}>
          I am outside the United States
        </Link>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
          endIcon={<FontAwesomeIcon icon={faArrowRight} />}
        >
          Search
        </Button>
      </Box>
    </Box>
  </form>;
}

const NotInUSDialog: React.VFC<{ open: boolean, onCancel: (...args: any[]) => any }> = ({ open, onCancel }) => {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { teacherData } = useTeacherInit();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const { track } = useTracking();

  const handleSubmit = () => {
    setSubmitting(true);

    justFetch(endpoints.user(currentUser.id), 'PUT', {
      school_id: null,
      is_non_usa: true
    })
      .then(res => {
        setSubmitting(false);

        if (!res.ok) {
          throw new Error();
        }

        return mutate(endpoints.session, {
          user: {
            ...currentUser,
            is_non_usa: true
          }
        }, true);
      }).then(() => {
        if (teacherData?.has_clever_account) {
          history.push(routes.teacherOnboarding.addClassClever.index);
        } else {
          history.push(routes.teacherOnboarding.addClassManual.index);
        }
        track('Add School Completed', {
          result: 'non usa'
        });
      })
      .catch(() => { setSubmitting(false); setSubmitError(true) });
  }

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: submitting ? 'visible' : 'hidden' }} />
    <DialogTitle>Not in the United States?</DialogTitle>
    <DialogContent>
      <Typography>Our school database doesn't have schools outside the United States yet.</Typography>
      {submitError && <Alert severity="error" action={<Button size="small" color="inherit" onClick={handleSubmit}>Try again</Button>}>There was an error submitting this form.</Alert>}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onCancel}
        variant="outlined"
        disabled={submitting}
      >
        Back to search
      </Button>
      <Button
        onClick={handleSubmit}
        color="primary"
        variant="contained"
        disableElevation
        disabled={submitting}
      >
        Continue without adding a school
      </Button>
    </DialogActions>
  </Dialog>
}