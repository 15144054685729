import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table, TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {format} from "date-fns";
import Button from "components/ui/buttons/Button";
import {ITeacherPayments} from "pages/godmode/Users/Teachers/ViewUser/IGodmodeUserProfile";
import {formatCurrency} from "pages/godmode/utils/numbers";
import React from "react";


type PaymentsDialogProps = {
  open: boolean,
  onClose: () => void,
  payments: ITeacherPayments[]
}

const PaymentsDialog: React.VFC<PaymentsDialogProps> = ({open, onClose, payments}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Payments</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{formatCurrency(payment.amount)}</TableCell>
                  <TableCell>{format(new Date(payment.created_at), 'PPP')}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PaymentsDialog
