import React from "react";

const DogEar: React.VFC<{imageUrl: string, color: string}> = ({imageUrl, color}) => {
  return (
    <div style={{
      width: 100,
      height: 100,
      backgroundColor: color,
      position: "relative",
      float: 'right',
      top: -50,
      left: 50,
      transform: 'rotate(45deg)',
      display: 'unset'
    }}>
      <div style={{
        transform: 'rotate(-45deg)'
      }}>
      <img src={imageUrl}
           style={{
             position: "relative",
             width: 32,
             height: 32,
             top: 44,
             left: -10
      }} />
      </div>
    </div>
  )
}

export default DogEar