import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from "@material-ui/core";
import classNames from "classnames";
import Button from "../../ui/buttons/Button";
import useSharedStyles from "../../useSharedStyles";
import { DialogStateProps } from "../../../hooks/useDialogState";
import React from "react";

const LoginTroubleDialog: React.VFC<DialogStateProps> = ({ open, onClose }) => {
  const sharedClasses = useSharedStyles();

  return <Dialog open={open} fullWidth>
    <DialogTitle>
      Can't Log In
    </DialogTitle>
    <DialogContent className={sharedClasses.vspacing2}>
      <Typography>
        It looks like we're having trouble connecting to our servers. You can try the steps below to resolve the issue:
      </Typography>
      <Typography variant="h2">
        Troubleshooting
      </Typography>
      <ol className={classNames('list-decimal', sharedClasses.vspacing2)} style={{ marginLeft: '1rem' }}>
        <li>
          <Link href="https://api.kodable.com/v1/status" target="blank">Click here to check our server's status.</Link> If the page says "Operating Normally", then our servers are running.
        </li>
        <li>
          <Typography paragraph>
            Something on your computer may be blocking the connection. Try disabling any firewalls you have and trying again.
          </Typography>

          <Typography>
            If this fixes the problem, then you will need to whitelist these URLs with your firewall:
            <ul className="list-disc list-inside">
              <li>
                https://api.kodable.com/
              </li>
              <li>
                https://dashboard.kodable.com/
              </li>
            </ul>
          </Typography>
        </li>
        <li>
          If you have tried these steps and are still having problems, please <Link href="mailto:support@kodable.com">Contact Support</Link>
        </li>
      </ol>
    </DialogContent>
    <DialogActions>
      <Box></Box>
      <Button
        color="primary"
        variant="contained"
        onClick={onClose}
      >Close</Button>
    </DialogActions>
  </Dialog>
}

export default LoginTroubleDialog;