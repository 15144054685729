import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core"
import classNames from "classnames";
import React from "react";

interface ClassProgressBarProps {
  state?: 'fullyCompleted' | 'partiallyCompleted' | 'inProgress' | 'noProgress';
  progress?: number;
  showProgressIndicator?: boolean;
}

const stateToColorMap: { [key in Required<ClassProgressBarProps>['state']]: string } = {
  fullyCompleted: '#8cc63e',
  partiallyCompleted: '#fbaf3f',
  inProgress: '#27a9e1',
  noProgress: 'purple'
}

const ClassProgressBar: React.FC<ClassProgressBarProps> = ({ children, state, progress = 0, showProgressIndicator = false }) => {
  const progressColor = state ? stateToColorMap[state] : 'rgba(0,0,0,0)';
  const borderColor = progress === 1 ? progressColor : '#979797'

  return <Box
    height={32} width="100%"
    borderRadius={7} border={`1px solid ${borderColor}`}
    style={{ backgroundColor: 'rgb(250, 250, 250)' }}
    color="white"
    fontSize={10}
    display="flex"
  >
    <Box borderRadius={6} height="100%" width={`${progress*100}%`} style={{ backgroundColor: progressColor }} position="relative" className={classNames({ 'barber_pole': showProgressIndicator })}>
      {progress > 0.5 && children}
      {showProgressIndicator && <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'black', position: 'absolute', fontSize: 32, right: -12, top: -8 }} />}
    </Box>
    {progress <= 0.5 && children}
  </Box>
}

export default ClassProgressBar;