import React, { useEffect, useRef } from 'react'
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useOrganizationProfile } from 'context/OrganizationProfileProvider'
import { format, parse } from 'date-fns'
import { Form, FormikProvider, useFormik } from 'formik'
import { DialogStateProps } from 'hooks/useDialogState'
import { ISubscription } from 'types/ISubscription'
import DateFnsUtils from '@date-io/date-fns'
import useSharedStyles from 'components/useSharedStyles'
import {justFetch} from "../../../../mutations/mutate";
import endpoints from "../../../../endpoints";
import {useAlert} from "../../../../context/AlertProvider";

export const SubscriptionChangeDatesDialog: React.FC<DialogStateProps & {subscriptionId: number, dateType: 'start' | 'end', onSuccess: (subscriptionId: number, newDate: string) => void}> = ({ onSuccess, open, onClose, subscriptionId, dateType }) => {
    const { organization_profile } = useOrganizationProfile()
    const [subscription, setSubscription] = React.useState<ISubscription>()
    const sharedClasses = useSharedStyles()
    const dialogRef = useRef<HTMLDivElement>(null)
    const alert = useAlert()
    const form = useFormik({
        initialValues: {
            start_date: subscription?.start_date,
            end_date: subscription?.end_date
        },
        enableReinitialize: true,
        onSubmit: async values => {
            let payload;
            if (dateType === 'start') {
                payload = {new_start_date: values.start_date}
            } else {
                payload = {new_end_date: values.end_date}
            }
            try {
                const resp = await justFetch(endpoints.godmode.subscriptions.edit(subscriptionId), 'PUT', payload)
                if (resp.ok) {
                    if (dateType && (values.start_date || values.end_date)) {
                        const newDate = (dateType === 'start' ? values.start_date : values.end_date)
                        if (newDate) {
                            onSuccess(subscriptionId, newDate)
                            onClose()
                        }
                    }
                } else {
                    alert.error('An error occurred while updating the subscription date!')
                }
            } catch (e) {
                alert.error('An error occurred while updating the subscription date!')
            }
        }
      });

    useEffect(() => {
        setSubscription(organization_profile?.subscriptions?.find((sub) => sub?.id === subscriptionId))
    }, [organization_profile, subscriptionId])

    if (!organization_profile || !subscription) {
      return null
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Change <span style={{textTransform: 'capitalize'}}>{dateType}</span> Date</DialogTitle>
            <DialogContent>
                <div ref={dialogRef}>
                    <FormikProvider value={form}>
                        <Form onSubmit={form.handleSubmit}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Box style={{marginBottom: '2rem'}} className={sharedClasses?.hspacing2} display="flex" alignItems="flex-end" justifyContent="center">
                                    <Box>
                                        <Typography variant="subtitle1">Subscription <span style={{textTransform: 'capitalize'}}>{dateType}</span> Date</Typography>
                                        <DatePicker
                                            onChange={date => { form.setFieldValue(`${dateType}_date`, format(date as Date, 'yyyy-MM-dd')); }}
                                            name={`${dateType}_date`}
                                            id={`${dateType}_date`}
                                            value={parse(form.values[`${dateType}_date`]!, 'yyyy-MM-dd', new Date())}
                                            format="MM/dd/yyyy"
                                            inputVariant="outlined"
                                            InputProps={{
                                                margin: 'dense'
                                            }}
                                        />
                                    </Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </MuiPickersUtilsProvider>
                        </Form>
                    </FormikProvider>
                </div>
            </DialogContent>
        </Dialog>
    )
}