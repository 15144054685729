import { faPlusCircle, faSave, faTrashCan, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core"
import Button from "components/ui/buttons/Button";
import { Alert } from "@material-ui/lab";
import PageContainer from "components/ui/PageContainer"
import endpoints from "endpoints";
import { Form, FormikProvider, useFormik, useFormikContext } from "formik";
import useDialogState, { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import * as Yup from 'yup';
import { useAlert } from "context/AlertProvider";
import { GodmodePageWrapper } from "pages/godmode/GodmodePageWrapper";

export interface ICleverDistrictResponse {
    id: number;
    clever_token: string;
    clever_id: string;
    subscription_id: number;
    subscription_end: string;
    subscription_school_name: string;
    subscription_has_curriculum: boolean;
    created_at: string;
    updated_at: string;
    last_sync: string;
    clever_expiration_outdated: boolean;
}

interface ICleverDistrictSubStateProps {
    active: number;
    expired: number;
}

interface ICleverDistrictManagementFormValues {
    clever_id: string;
    clever_token: string;
    subscription_id: string;
}

export const CleverDistrictsIndex: React.VFC = () => {
    const { data, error } = useSWR<ICleverDistrictResponse[]>(endpoints.cleverDistricts);
    const [subStateCounts, setSubStateCounts] = useState<ICleverDistrictSubStateProps>({ active: 0, expired: 0 });
    const [changed, setChanged] = useState(false)

    useEffect(() => {
        if (!data) {
            return
        }
        let active = 0
        let expired = 0
        data.forEach((district) => {
            const [year, month, day] = district?.subscription_end?.split("-");
            const end_date = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day)));
            end_date.getTime() > Date.now() ? active++ : expired++;
        })
        setSubStateCounts({ active, expired })

        return () => {
            setSubStateCounts({ active: 0, expired: 0 })
        }
    }, [data])

    // This handles causing a mutation on the SWR endpoint that will cause a re-fetch of Clever Districts to 
    // update the list
    useEffect(() => {
        if (!changed) {
            return
        }
        mutate(endpoints.cleverDistricts)
        setChanged(false)
    }, [changed])

    if (error) {
    return <PageContainer>
        <Alert severity="error">Could not load districts.</Alert>
    </PageContainer>
    }

    return (
        <GodmodePageWrapper title="Clever Districts" loading={!data}>
            <TableContainer>
                <CleverDistrictManagementBar
                    setChanged={setChanged}
                    subStateCounts={subStateCounts}
                />
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Expiration</TableCell>
                        <TableCell>School Name</TableCell>
                        <TableCell>Subscription ID</TableCell>
                        <TableCell>Clever ID</TableCell>
                        <TableCell>Clever Token</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((district, index) =>
                        <CleverDistrictRow
                            key={index}
                            setChanged={setChanged}
                            district={district} 
                        /> 
                    )}
                </TableBody>
            </TableContainer>
        </GodmodePageWrapper>
    )
}

const CleverDistrictRow: React.FC<{ 
        district: ICleverDistrictResponse,
        setChanged: Dispatch<SetStateAction<boolean>> 
    }> = ({ 
        district,
        setChanged 
    }) => {
    const deleteDialogState = useDialogState()
    const alert = useAlert()
    const [submitting, setSubmitting] = useState(false)
    const [confirmInput, setConfirmInput] = useState<string>("")

    const handleConfirmInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmInput(e.target.value)
    }

    const handleDelete = () => {
        if (confirmInput !== 'DELETE' || submitting) {
            return
        }
        setSubmitting(true)

        justFetch(endpoints.cleverDistrictDelete(district?.clever_id), 'DELETE')
        .then((res) => {
            if (res.ok) {
                deleteDialogState.onClose()
                alert.success('Clever District successfully deleted!')
                setChanged(true)
                setSubmitting(false)
            } else {
                throw new Error('Unable to delete district')
            }
        })
        .catch((error) => {
            console.log(error)
            alert.error('Unable to delete district', error)
            setSubmitting(false)
        })
    }

    return (
        <TableRow key={district?.id}>
            <Dialog open={deleteDialogState?.open}>
                <DialogTitle>Delete Clever District {district?.clever_id}</DialogTitle>
                <DialogContent style={{textAlign: 'center'}}>
                    <Typography>You are attempting to delete the Clever District with the ID {district?.clever_id}</Typography>
                    <br />
                    <Typography variant="h2">This action cannot be undone. Proceed with caution.</Typography>
                    <br />
                    <TextField
                        onChange={handleConfirmInputChange}
                        label="Type 'DELETE' to confirm deletion"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <Button
                            color="green"
                            variant="contained"
                            startIcon={<FontAwesomeIcon icon={faXmarkCircle} />}
                            onClick={() => deleteDialogState.onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="red"
                            variant="contained"
                            startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                            onClick={handleDelete}
                            disabled={confirmInput !== 'DELETE' || submitting}
                        >
                            Confirm Deletion
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <TableCell>
                <Box>
                    <Button
                        onClick={() => deleteDialogState.handleOpen()}
                        color="red"
                        variant="contained"
                        startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                        disabled={submitting}
                    >
                        Delete
                    </Button>
                </Box>
            </TableCell>
            <TableCell>{district?.subscription_end}</TableCell>
            <TableCell>{district?.subscription_school_name}</TableCell>
            <TableCell>{district?.subscription_id}</TableCell>
            <TableCell>
                <Button
                    color="green"
                    variant="contained"
                    size="small"
                    href={`https://apps.clever.com/kodable/district/${district?.clever_id}/overview`}
                >
                    {district?.clever_id}
                </Button>
            </TableCell>
            <TableCell>{district?.clever_token}</TableCell>
        </TableRow>
    )
}

const CleverDistrictManagementBar = (props: {subStateCounts: ICleverDistrictSubStateProps, setChanged: Dispatch<SetStateAction<boolean>>}) => {
    const {subStateCounts, setChanged} = props
    const editEventDialogState = useDialogState();
    
    return (
        <>
        <CleverDistrictManagementDialog
            setChanged={setChanged}
            editEventDialogState={editEventDialogState}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
                Active: ({subStateCounts?.active}) Expired: ({subStateCounts?.expired})
            </Box>
            <Box>
                <Button
                    color="green"
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                    onClick={() => editEventDialogState.handleOpen()}
                >
                    Add Clever District
                </Button>
            </Box>
        </Box>
        </>
    )
}

const CleverDistrictManagementDialog: React.FC<{
    setChanged: Dispatch<SetStateAction<boolean>>,
    editEventDialogState: DialogStateProps
    }> = ({
        setChanged,
        editEventDialogState
    }) => {
    const alert = useAlert()
    const validationSchema = Yup.object().shape({
        clever_id: Yup.string().required('You must provide a Clever ID'),
        clever_token: Yup.string().required('You must provide a Clever Token'),
        subscription_id: Yup.number().required('You must provide a Subscription ID')
    })
    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            clever_id: "",
            clever_token: "",
            subscription_id: ""
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                const res = await justFetch(endpoints.cleverDistricts, 'POST', values);
                if (res.ok) {
                    editEventDialogState.onClose();
                    setChanged(true)
                    alert.success('Clever District successfully created!');
                } else {
                    const error = await res.json()
                    throw new Error(error)
                }
            } catch (e) {
                alert.error('Unable to add district', (typeof e === 'string' ? e : ''))
            }
        }
    })

    return (
        <Dialog open={editEventDialogState.open}>
            <DialogTitle>Add Clever District</DialogTitle>
            <DialogContent>
                <FormikProvider value={form}>
                    <CleverDistrictManagementForm />
                </FormikProvider>
            </DialogContent>
            <DialogActions>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Button
                        startIcon={<FontAwesomeIcon icon={faXmarkCircle} />}
                        color="orange"
                        variant="contained"
                        onClick={() => {
                            form.resetForm()
                            editEventDialogState.onClose()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faSave} />}
                        color="green"
                        variant="contained"
                        onClick={() => form.submitForm()}
                        disabled={form?.isSubmitting}
                    >
                        Save
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

const CleverDistrictManagementForm: React.FC = () => {
    const { values, touched, errors, handleChange } = useFormikContext<ICleverDistrictManagementFormValues>();
    return (
        <Form>
            <Typography variant="subtitle1">Clever ID</Typography>
            <TextField
                label="Clever ID"
                name="clever_id"
                fullWidth
                margin="normal"
                variant="outlined"
                required
                value={values.clever_id}
                onChange={handleChange}
                error={touched.clever_id && Boolean(errors.clever_id)}
                helperText={touched.clever_id && errors.clever_id}
            />
            <Typography variant="subtitle1">Clever Token</Typography>
            <TextField
                label="Clever Token"
                name="clever_token"
                fullWidth
                margin="normal"
                variant="outlined"
                required
                value={values.clever_token}
                onChange={handleChange}
                error={touched.clever_token && Boolean(errors.clever_token)}
                helperText={touched.clever_token && errors.clever_token}
            />
            <Typography variant="subtitle1">Subscription ID</Typography>
            <TextField
                label="Subscription ID"
                name="subscription_id"
                fullWidth
                margin="normal"
                variant="outlined"
                required
                value={values.subscription_id}
                onChange={handleChange}
                error={touched.subscription_id && Boolean(errors.subscription_id)}
                helperText={touched.subscription_id && errors.subscription_id}
            />
        </Form>
    )
}