import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Box, Link } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import React from "react";

const CoppaDialog: React.VFC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>
        Student Privacy
      </DialogTitle>
      <DialogContent>
        <Typography paragraph>
          Pursuant to COPPA regulations and our <Link href="https://www.kodable.com/privacy" target="_blank">Terms of Service</Link>, do not use any Personally Identifiable Information when creating student profiles.
        </Typography>

        <Typography>
          COPPA-compliant profile identifiers include:
        </Typography>

        <ul style={{ listStyle: 'inside' }}>
          <li>
            First name
          </li>
          <li>
            First name + last initial
          </li>
          <li>
            Any unique, anonymous identifier
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Box></Box>
        <Button disableElevation color="primary" variant="contained" onClick={onClose}>
          okay
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CoppaDialog;