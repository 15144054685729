import {Box, Grid, Link, Typography} from "@material-ui/core";
import Button from "../../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faShareFromSquare} from "@fortawesome/free-solid-svg-icons";
import useDialogState from "../../../hooks/useDialogState";
import ShareLessonDialog from "./ShareLessonDialog";
import React from "react";

const AboutTheLesson: React.FC<{shortDescription: string, downloadUrl: string, shareUrl: string}> = ({shortDescription, downloadUrl, shareUrl}) => {
  const shareDialogState = useDialogState();
  return (
    <>
      <ShareLessonDialog open={shareDialogState.open} onClose={shareDialogState.onClose} lessonUrl={shareUrl} lessonDescription={shortDescription} />
      <Grid container item
            direction="row"
            justifyContent="center"
            alignItems="center" xs={10}>
        <Grid item xs={7}>
          <Typography variant={'h6'}>About the Lesson</Typography>
          <div dangerouslySetInnerHTML={{__html: shortDescription || ""}} className={'curriculum'}/>
        </Grid>
        <Box display={'flex'} flexDirection={'column'} width={185} justifyContent={'space-between'}>
          <Link component={Button}
                href={downloadUrl}
                target={"_blank"}
                underline={'none'}
                startIcon={<FontAwesomeIcon icon={faDownload}/>}
                style={{borderColor: '#27a9e1', border: '1px solid', padding: '5px 15px', marginBottom: 5}}
          >
            Download Lesson
          </Link>
          <Button startIcon={<FontAwesomeIcon icon={faShareFromSquare}/>}
                  variant={'outlined'}
                  color={'purple'}
                  onClick={shareDialogState.handleOpen}
          >Share Lesson</Button>
        </Box>
      </Grid>
    </>
  )
}

export default AboutTheLesson