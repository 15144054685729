import {GodmodePageWrapper} from "pages/godmode/GodmodePageWrapper";
import OrganizationProfilesIndexTabs from "pages/godmode/Sales/Organizations/OrganizationProfilesIndexTabs";
import React from "react";

const OrganizationProfilesIndex: React.FC = () => {
  return (
    <GodmodePageWrapper loading={false} title="Organization Profiles">
      <OrganizationProfilesIndexTabs />
    </GodmodePageWrapper>
  )
}

export default OrganizationProfilesIndex

