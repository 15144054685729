import { faArrowRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { IRouteParams } from "AppRoutes";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { DialogStateProps } from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { mutate } from "swr";
import {routes} from "routes";

export const BulkDeleteKlassesDialog: React.FC<DialogStateProps> = (props) => {
  const [klassesToDelete, setKlassesToDelete] = useState(new Set<number>());
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletedAllSuccess, setDeletedAllSuccess] = useState<boolean>();
  const [deleteError, setDeleteError] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [deletedKlasses, setDeletedKlasses] = useState<Set<number>>(new Set());
  const { teacherData } = useTeacherInit();
  const filteredKlasses = useMemo(() => {
    return teacherData?.klasses?.filter(klass => !klass.clever_id).sort(({ klass_name: A}, { klass_name: B}) => A.localeCompare(B))
  }, [teacherData?.klasses])
  const sharedClasses = useSharedStyles();
  const history = useHistory();
  const { classId } = useParams<IRouteParams>();
  const alert = useAlert();

  const filteredKlassesToDelete = useMemo(() => {
    return teacherData?.klasses?.filter(klass => !klass.clever_id && klassesToDelete.has(klass.id)).sort(({ klass_name: A}, { klass_name: B}) => A.localeCompare(B))
  }, [teacherData?.klasses, klassesToDelete])

  useEffect(() => {
    if (props.open) {
      setKlassesToDelete(new Set());
      setIsDeleting(false);
      setDeletedAllSuccess(undefined);
      setDeleteError(false);
      setIsConfirming(false);
    }
  }, [props.open]);

  const handleToggleClass = (classId: number, selected: boolean) =>
    setKlassesToDelete(klassesToDelete => {
      const newSelectedClasses = new Set(klassesToDelete);

      if (selected) {
        newSelectedClasses.add(classId);
      } else {
        newSelectedClasses.delete(classId);
      }

      return newSelectedClasses;
    });


  const submit = () => {
    setIsDeleting(true);
    justFetch(endpoints.bulkDeleteKlasses, 'POST', {
      klass_id: Array.from(klassesToDelete)
    })
      .then(res => {
        setIsDeleting(false);

        if (res.status !== 201) {
          throw new Error();
        }

        return res.json();
      })
      .then(res => {
        const _deletedKlasses = new Set(Object.keys(res).filter(klassId => res[klassId]).map(klassId => parseInt(klassId)));
        setDeletedKlasses(_deletedKlasses);
        const _deletedAllSuccess = Object.values(res).every(didDelete => didDelete);
        setDeletedAllSuccess(_deletedAllSuccess);

        if (_deletedAllSuccess) {
          cont(_deletedKlasses);
          alert.success(`Deleted ${_deletedKlasses.size} class${_deletedKlasses.size > 1 ? 'es' : '' }`)
        }
      })
      .catch(() => { setIsDeleting(false); setDeleteError(true) })
  }

  const cont = (_deletedKlasses = deletedKlasses) => {
    if (_deletedKlasses.has(parseInt(classId))) {
      history.push(routes.classes.index);
    }

    props.onClose();

    setTimeout(() => {
      mutate(endpoints.teacherInit, {
        ...teacherData,
        klasses: teacherData?.klasses.filter(({ id }) => !_deletedKlasses.has(id))
      })
    }, 500);
  }

  return <Dialog open={props.open} fullWidth scroll="paper">
    <LinearProgress style={{ visibility: isDeleting ? 'visible' : 'hidden' }} />
    <DialogTitle>Delete Multiple Classes</DialogTitle>
    <DialogContent>
      {!filteredKlasses && <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>}
      {!isConfirming && deletedAllSuccess === undefined && !deleteError && <Box className={sharedClasses.vspacing2}>
        <Typography>Select the classes you would like to delete.</Typography>
        {teacherData?.has_clever_klasses && <Alert severity="info">Classes synced from Clever cannot be deleted.</Alert>}
        {filteredKlasses?.length !== 0 && <Box component={Paper} {...{ variant: 'outlined' }} px={2} py={2} overflow="scroll">
          {filteredKlasses?.map((klass, index) => {
            const selected = klassesToDelete.has(klass.id);

            return <ListItem
              key={index}
              button {...{ disableRipple: true }}
              selected={selected} onClick={() => handleToggleClass(klass.id, !selected)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={selected}
                />
              </ListItemIcon>
              <ListItemText
                primary={klass.klass_name}
              />
            </ListItem>
          })}
        </Box>}
      </Box>}
      {isConfirming && deletedAllSuccess === undefined && !deleteError && <Box className={sharedClasses.vspacing2}>
        <Typography>The following classes will be deleted:</Typography>
        <Box component={Paper} {...{ variant: 'outlined' }} px={2} py={2} overflow="scroll">
          {filteredKlassesToDelete?.map((klass, index) => {
            return <ListItem
              key={index}
            >
              <ListItemText
                primary={klass.klass_name}
              />
            </ListItem>
          })}
        </Box>
      </Box>}
      {(deletedAllSuccess === false || deleteError) && <>
        <Alert severity="error">Some classes couldn't be deleted. Please contact support@kodable.com if you continue to see this issue.</Alert>
      </>}
    </DialogContent>
    <DialogActions>
      <Box display="flex" flexDirection="column" className={sharedClasses.vspacing4} width="100%">
        {isConfirming && deletedAllSuccess === undefined && !deleteError && <Alert severity="warning">Deleting a class is permanent and these classes cannot be recovered.</Alert>}
        <Box display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            disabled={isDeleting}
            onClick={props.onClose}
          >Cancel</Button>
          {!isConfirming && deletedAllSuccess === undefined && !deleteError && <Button
            variant="contained"
            color="primary"
            disabled={klassesToDelete.size === 0}
            endIcon={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={() => setIsConfirming(true)}
          >Next</Button>}
          {isConfirming && deletedAllSuccess === undefined && !deleteError && <Button
            variant="contained"
            color="red"
            disabled={isDeleting}
            onClick={submit}
            startIcon={<FontAwesomeIcon icon={faTrash} />}
          >{`Delete ${klassesToDelete.size} class${klassesToDelete.size > 1 ? 'es' : ''}`}</Button>}
          {(deletedAllSuccess !== undefined || deleteError) && <Button
            variant="contained"
            color="primary"
            onClick={() => cont()}
          >Continue</Button>}
        </Box>
      </Box>
    </DialogActions>
  </Dialog>;
}