import React, { useEffect, useRef, useState } from 'react';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import useUpdateSubscriptionBilling from 'mutations/useUpdateSubscriptionBilling';
import useSubscriptionBilling from 'loaders/useSubscriptionBilling';
import {useAlert} from 'context/AlertProvider';
import { useHistory } from 'react-router-dom';
import Loading from 'components/loading/Loading';
import stripePromise from 'utils/Stripe';
import useCurrentUser from 'loaders/useCurrentUser';
import { handleCaptchaValidation } from 'utils/captchaValidation';
import ReCAPTCHA from 'react-google-recaptcha';
import {IUserProfile} from "../../types/IUserProfile";
import PageContainer from "../../components/ui/PageContainer";
import {Box, Divider, Typography} from "@material-ui/core";
import Button from "../../components/ui/buttons/Button";

const UpdateBillingPage = () => {
  const { currentUser } = useCurrentUser();

  return (
    <Elements stripe={stripePromise}>
      <BillingForm currentUser={currentUser} />
    </Elements>
  );
}

const BillingForm: React.FC<{currentUser: IUserProfile}> = ({ currentUser }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [disabled, setDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const updateSubscriptionBilling = useUpdateSubscriptionBilling(
    currentUser.id
  );
  const { subscriptionBilling, loading } = useSubscriptionBilling();
  const alert = useAlert();
  const history = useHistory();

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  useEffect(() => {
    async function handlePostSubmit() {
      if (recaptchaValue === null) {
        return
      }
      const valid = await handleCaptchaValidation(recaptchaValue)

      if (!valid) {
        return
      }

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      setSubmitting(true);

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        return;
      }

      // Use your card Element with other Stripe.js APIs
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        alert.error('Card not updated!');
        setSubmitting(false);
      } else {
        try {
          const result = await updateSubscriptionBilling({ token: token.id })
          if (result.error) {
            alert.error('Card not Updated!');
            setSubmitting(false);
          } else {
            alert.success('Your credit card was successfully updated.');
            setSubmitting(false);
            history.push('/settings');
          }
        } catch (error) {
          alert.error('Card not Updated!');
          setSubmitting(false);
        }
      }
    }
    handlePostSubmit()
  }, [recaptchaValue]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    recaptchaRef.current?.execute()
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-32 h-64">
        <Loading/>
      </div>
    );
  }

  return (
    <>
      <PageContainer variant={'centered'}>
        <Box textAlign={'center'} marginBottom={3}>
          <Typography variant={'h1'}>Your Payment Details</Typography>
          <Typography variant={'caption'}>All transactions are secure and encrypted.</Typography>
        </Box>
        <Divider/>
        <Box textAlign={'center'} marginTop={3}>
          {subscriptionBilling && (
            <Typography>
              Your current payment method is a{' '}
              <span className="font-semibold">
                {subscriptionBilling?.brand}
              </span>{' '}
              ending in{' '}
              <span className="font-semibold">
                {subscriptionBilling?.last_four}
              </span>
            </Typography>
            )}
        </Box>
        <Box maxWidth={400} marginLeft={11} marginTop={3}>
          <Box textAlign={'left'}>
            <form
              className="flex flex-col justify-center"
              onSubmit={handleSubmit}>
              <label htmlFor="cardnumber" className="font-semibold text-gray-800">
                Credit or debit card
              </label>
              <ReCAPTCHA
                ref={recaptchaRef}
                onChange={value => setRecaptchaValue(value)}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                size="invisible"
              />
              <CardElement
                id="cardnumber"
                className="border py-2 px-4 mt-1 rounded text-gray-700"
                onChange={(value) => {
                  if (value.complete) {
                    setDisabled(false);
                  } else {
                    setDisabled(true);
                  }
                }}
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
              <Box textAlign={'right'} marginTop={3}>
                <Button
                  disabled={!stripe || disabled || submitting}
                  color={'newOrange'}
                  variant={'contained'}
                  type="submit">
                  {submitting ? 'Updating Card...' : 'Update Card'}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </PageContainer>
    </>
  );
}

export default UpdateBillingPage