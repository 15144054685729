import {useLayoutEffect, useState} from "react";
import {Box, CircularProgress, makeStyles, Paper, useTheme} from "@material-ui/core";
import {PaginationData} from "../Organizations/OrganizationProfilesTable";
import {justFetch} from "../../../../mutations/mutate";
import endpoints from "../../../../endpoints";
import {DataGrid, GridCellParams, GridColDef, GridRowData, GridValueFormatterParams} from "@material-ui/data-grid";
import {accountTrackedDate} from "../../utils/time";
import {formatCurrency} from "../../utils/numbers";
import {Link} from "react-router-dom";
import {routes} from "../../../../routes";
import clsx from "clsx";
import TextField from "../../../../components/ui/TextField";
import Button from "../../../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {getQuoteNumber} from "../../../sales/quotes/getQuoteNumber";
import {IQuote} from "../../../sales/IQuote";


const columns: GridColDef[] = [
  { field: 'quoteNumber', headerName: 'Quote #', width: 90, sortable: false, headerAlign: 'center' },
  { field: 'num_sites', headerName: '# of Sites', width: 100, sortable: false },
  { field: 'plan', headerName: 'Plan', width: 100, type: 'string', sortable: false },
  { field: 'total', headerName: 'Amount', width: 100, type: 'currency' },
  { field: 'created_at', headerName: 'Created', width: 120, type: 'date' },
  { field: 'expiration_date', headerName: 'Expires', width: 120, type: 'date' },
  { field: 'contact_name', headerName: 'Contact Name', width: 220 },
  { field: 'contact_email', headerName: 'Contact Email', width: 275 },
  { field: 'organization_name', headerName: 'Organization Name', width: 400 },
  { field: 'state', headerName: 'Quote State', width: 150 },
]

export type QuotesPayload = {
  quotes: IQuote[],
  current_page: number,
  page_count: number,
  total_records: number
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .created, & .expired, & .ordered': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'capitalize'
    },
    '& .created': {
      backgroundColor: theme.palette.green.main,
    },
    '& .expired': {
      backgroundColor: theme.palette.warning.main,
    },
    '& .ordered': {
      backgroundColor: theme.palette.blue.main,
    }
  },
}));

const QuotesTable = () => {
  const [pageSize, setPageSize] = useState(50);
  const [quoteData, setQuoteData] = useState<QuotesPayload>();
  const [quotes, setQuotes] = useState<IQuote[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData>();
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const rowStyles = useStyles();
  const theme = useTheme()

  const getPage = async (page: number) => {
    setLoading(true)
    const data = await justFetch(endpoints.godmode.quotes.all(
        page,
        pageSize,
        searchQuery
      ),
      'GET'
    )
    const payload: QuotesPayload = await data.json();
    setQuoteData(payload);
  }

  useLayoutEffect(() => {
    if (!quoteData) {
      return;
    }
    setPaginationData({
      currentPage: quoteData.current_page,
      pageCount: quoteData.page_count,
      totalRecords: quoteData.total_records
    });
    setQuotes(quoteData.quotes.map(quote => {
      const today = new Date()
      today.setHours(0, 0, 0, 0) // Set time to 00:00:00 for accurate comparison
      const parsedExpirationDate = new Date(quote?.expiration_date)
      const isExpired = parsedExpirationDate < today
      const quoteState = isExpired ? 'Expired' : (quote?.purchase_process ? 'Ordered' : 'Created')
      return {
        ...quote,
        plan: quote.has_curriculum ? 'Premium+' : 'Premium',
        total: (quote.num_sites * quote.price_per_site) - (quote.num_sites * quote.discount_per_site),
        quoteNumber: getQuoteNumber(quote),
        state: quoteState
      }
    }));
    setLoading(false);
  }, [quoteData]);

  const addColumnFormatters = (columns: GridColDef[]) => {
    return columns.map((column) => {
      if (column.type && column.type === 'date') {
        column.valueFormatter = (params: GridValueFormatterParams) => {
          if (!params.value) {
            return 'N/A';
          }
          return accountTrackedDate(params.value as string);
        }
      }
      if (column.type && column.type === 'currency') {
        column.valueFormatter = (params: GridValueFormatterParams) => {
          if (!params.value) {
            return 'N/A'
          }
          return formatCurrency(Number(params.value as number));
        }
      }
      if (column.field === 'quoteNumber') {
        column.renderCell = (params: GridValueFormatterParams) => {
          return (
            <Link
              style={{color: theme.palette.blue.main}}
              to={routes.sales.quotes.view(params.row.secret_code)}
            >{params.value}</Link>
          )
        }
      }
      if (column.field === 'contact_name' || column.field === 'contact_email') {
        column.renderCell = (params: GridValueFormatterParams) => {
          if (params.row.teacher_id) {
            return (
              <Link
                style={{color: theme.palette.blue.main}}
                to={routes.godmode.user.index(params.row.teacher_id)}
              >{params.value}</Link>
            )
          } else {
            return params.value;
          }
        }
      }
      if (column.field === 'organization_name') {
        column.renderCell = (params: GridValueFormatterParams) => {
          if (params.row.organization_profile_id) {
            return (
              <Link
                style={{color: theme.palette.blue.main}}
                to={routes.godmode.organizationProfiles.view(params.row.organization_profile_id)}
              >{params.value}</Link>
            )
          } else {
            return params.value;
          }
        }
      }
      if (column.field === 'state') {
        column.cellClassName = (params: GridCellParams) => {
          return clsx({
            expired: params.value === 'Expired',
            created: params.value === 'Created',
            ordered: params.value === 'Ordered',
          })
        }
      }
      return column
    })
  }

  useLayoutEffect(() => {
    getPage(1);
  }, [])

  return (
    <div style={{width: '100%' }}>
      <Box display="flex" alignItems="center" mb={1} mt={1}>
        <Box flexGrow={1}>
          <TextField
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                getPage(1)
                ev.preventDefault();
              }
            }}
            name="query"
            id="query"
            placeholder={"Search"}
            fullWidth
          />
        </Box>
        <Box ml={1}>
          <Button
            color="green"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faSearch} />}
            size="large"
            type="submit"
            onClick={() => getPage(1)}
          >
            Search
          </Button>
        </Box>
      </Box>
      {loading &&
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem'
          }}
        >
          <CircularProgress />
        </Box>
      }
      {!loading &&
        <Paper>
          <div className={rowStyles.root}>
            <DataGrid
              rows={quotes as GridRowData[] || []}
              columns={addColumnFormatters(columns)}
              pageSize={pageSize}
              autoHeight
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableExtendRowFullWidth
              rowsPerPageOptions={[10, 25, 50, 100]}
              scrollbarSize={15}
              page={paginationData?.currentPage ? paginationData?.currentPage - 1 : 0}
              onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize);
              }}
              paginationMode="server"
              onPageChange={async (newPage) => {
                if (newPage === 0) {
                  return;
                }
                if (paginationData?.currentPage && newPage < paginationData?.currentPage) {
                  await getPage(newPage - 1)
                  return;
                }
                await getPage(newPage + 1)
              }}
              pagination
              rowCount={paginationData?.totalRecords}
            />
          </div>
        </Paper>
      }
    </div>
  )
}

export default QuotesTable