import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { IStudentsDialogProps } from "../../../pages/students/IStudentsDialogProps";
import * as Yup from 'yup';
import StudentActionConfirmationList from "../../../pages/students/StudentActionConfirmationList";
import useSharedStyles from "components/useSharedStyles";
import useTeacherInit from "loaders/useTeacherInit";
import React, {useEffect, useState} from "react";
import Button from "components/ui/buttons/Button";
import {Alert, Autocomplete} from "@material-ui/lab";
import { justFetch } from "mutations/mutate";
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import { mutate } from "swr";
import { useAlert } from "context/AlertProvider";
import {useRouteMatch} from "react-router-dom";
import {IRouteParams} from "../../../AppRoutes";
import TextField from "../../ui/TextField";
import {IKlass} from "../../../types/IKlass";
import {IStudentRowData} from "../../../pages/students/IStudentRowData";

const TransferStudentsDialog: React.VFC<IStudentsDialogProps & { didMoveStudents: () => void }> = ({ open, students, onClose = (...args: any[]) => { }, didMoveStudents }) => {
  const { currentUser } = useCurrentUser();
  const { teacherData } = useTeacherInit();
  const { params } = useRouteMatch<IRouteParams>();
  const [klasses, setKlasses] = useState<IKlass[]>([]);
  const [selectedKlass, setSelectedKlass] = useState<IKlass>({id: 0, klass_name: 'Select a class'} as IKlass);
  const [submitError, setSubmitError] = useState(false);
  const alert = useAlert();

  interface GroupedStudents {
    [klassNumber: number]: IStudentRowData[];
  }

  const groupStudentsByClass = (students: IStudentRowData[]): GroupedStudents => {
    return students.reduce((acc: GroupedStudents, student: IStudentRowData) => {
      // TODO: This only supports one klass
      const klassNumber = student.klasses[0];

      if (!acc[klassNumber]) {
        acc[klassNumber] = [];
      }

      acc[klassNumber].push(student);
      return acc;
    }, {});
  }

  const form = useFormik({
    initialValues: {
      klass_id: undefined,
    },
    onSubmit: async values => {
      const groupedStudents: GroupedStudents = groupStudentsByClass(students);

      for (const klassNumber in groupedStudents) {
        if (groupedStudents.hasOwnProperty(klassNumber)) {
          const studentsArray = groupedStudents[klassNumber];
          try {
            const res = await justFetch(endpoints.moveStudentsToClass, 'PUT', {
              student_codes: studentsArray.map(({student_code}) => student_code),
              teacher_id: currentUser.id,
              old_klass_id: params.classId ? params.classId : klassNumber,
              new_klass_id: values.klass_id
            });
            if (!res.ok) {
              throw new Error();
            }
          } catch {
            setSubmitError(true);
          }
        }
      }

      await mutate(endpoints.teacherInit);
      alert.success(`Student${students.length > 1 ? 's' : ''} Moved`);
      didMoveStudents();
      onClose();
    },
    validationSchema: Yup.object({
      klass_id: Yup.number().required('Select a class to move these students')
    })
  });

  const sharedClasses = useSharedStyles();

  useEffect(() => {
    if (teacherData) {
      setKlasses([{id: 0, klass_name: 'Select a class'} as IKlass, ...teacherData.klasses.filter((klass) => klass.clever_id == null).sort((a, b) => a.klass_name.localeCompare(b.klass_name))]);
    }
  }, [teacherData]);

  useEffect(() => {
    if (open) {
      form.resetForm();
      setSubmitError(false);
    }
  // eslint-disable-next-line
  }, [open]);

  return <Dialog fullWidth open={open} onClose={onClose}>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>Move students to another class</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <Autocomplete
          fullWidth={true}
          autoHighlight
          disableClearable={true}
          options={klasses}
          getOptionLabel={(klass) => klass.klass_name}
          value={selectedKlass}
          defaultValue={{id: 0, klass_name: 'Select a class'} as IKlass}
          getOptionDisabled={(option) => option === klasses[0]}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedKlass(newValue);
              form.setFieldValue('klass_id', newValue.id);
            }
          }}
          renderInput={(params) => <TextField {...params} label="Move Students To Class:" variant="outlined" />}
        />
        <Typography>
          This will move {students.length > 1 ? `these ${students.length} students:` : `this student:`}
        </Typography>
        <StudentActionConfirmationList students={students} showCurrentClass />

        {!form.isValid && form.submitCount > 0 && <Alert severity="error">Please correct the errors above to continue.</Alert>}

        {submitError && <Alert severity="error" action={
          <Button
            color="inherit"
            size="small"
            onClick={() => form.submitForm()}
          >Try again</Button>
        } >There was an error trying to submit this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          type="reset"
          disabled={form.isSubmitting}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disableElevation
          disabled={form.isSubmitting || selectedKlass.id === 0}
        >
          Move students
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default TransferStudentsDialog;