import React from 'react';
import {Box, makeStyles, Typography, useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    maxWidth: 1100,
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
  },
  progressContainer: {
    display: "flex",
    justifyContent: 'flex-start',
    marginBottom: 15,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
  },
  stepContainer: {
    minHeight: 450,
    display: 'flex',
    flexDirection: 'column',
  },
  stepTitle: {
    fontWeight: 700,
    fontSize: 34,
    lineHeight: 1.3,
    marginBottom: 15,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      width: 400,
      margin: 'auto',
      marginBottom: 15
    },
  },
  stepImageContainer: {
    display: 'flex'
  }
}));

const OnboardingLayout: React.VFC<{imgSrc: string, imgName: string, children: any, title: string, activeSteps: number, hideImageOnMobile?: boolean}> = ({
  imgSrc,
  imgName,
  children,
  title,
  activeSteps,
  hideImageOnMobile = true
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      { isSmallScreen && hideImageOnMobile ? <></> :
        <Box className={classes.stepImageContainer}>
          <img style={{alignSelf: 'center'}} width="600" src={imgSrc} alt={imgName}/>
        </Box>
      }
      <Box className={classes.stepContainer}>
        <Box className={classes.progressContainer}>
          {Array(5)
            .fill(0)
            .map((_, i) => {
              return (
                <div
                  key={i}
                  className={`w-4 h-4 rounded-full mr-2 ${
                    i >= activeSteps
                      ? 'bg-gray-300'
                      : 'bg-kodable-orange hover:bg-kodable-orange-dark active:bg-kodable-orange-darker'
                  }`}></div>
              );
            })}
        </Box>
        <Typography className={classes.stepTitle}>{title}</Typography>
        {children}
      </Box>
    </Box>
  );
}

export default OnboardingLayout