import {Box, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import FacebookShareButton from "../ui/buttons/FacebookShareButton";
import TwitterShareButton from "../ui/buttons/TwitterShareButton";
import EmailShareButton from "../ui/buttons/EmailShareButton";
import CopyLinkButton from "../ui/buttons/CopyLinkButton";
import Button from "../ui/buttons/Button";
import React from "react";

export type EmailConfig = {
  subject: string,
  body: string
}

const ShareModal: React.VFC<{open: boolean, onClose: () => void, title: string, shareUrl: string, email?: EmailConfig}> = ({open, onClose, title, shareUrl, email}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
          <FacebookShareButton shareUrl={shareUrl} />
          <TwitterShareButton shareUrl={shareUrl} />
          {email &&
            <EmailShareButton subject={email.subject}
                              body={`${email.body}\n\n${shareUrl}`} />
          }
          <CopyLinkButton shareUrl={shareUrl}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <Button variant={"outlined"} onClick={onClose}>Close</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ShareModal