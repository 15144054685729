import { Box, makeStyles } from "@material-ui/core"
import React from "react";

const borderWidth = 1;
const bubbleRadius = 15;
const bubbleBg = 'hsl(244deg 29% 91%)';
const bubbleBorderColor = 'rgba(230, 230, 230, 0)';
const bubblePadding = 20;

const useChatBubbleStyles = makeStyles({
  bubble: {
    background: bubbleBg,
    position: 'relative',
    textAlign: 'center',
    margin: '0px auto 5px auto',
    border: `${borderWidth}px solid ${bubbleBorderColor}`,
    borderRadius: bubbleRadius,
    padding: bubblePadding,
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before, &:after': {
      content: "' '",
      position: 'absolute',
      bottom: 0,
      height: 25,
    },
    '&:before': {
      left: -7,
      width: 20,
      backgroundColor: bubbleBg,
      borderBottomRightRadius: 16
    },
    '&:after': {
      left: -27,
      width: 26,
      backgroundColor: 'rgb(244, 247, 255)',
      borderBottomRightRadius: 10
    }
  }
});

export const ChatBubble: React.FC = ({ children }) => {
  const chatBubbleClasses = useChatBubbleStyles();
  return <Box className={chatBubbleClasses.bubble}>
    {children}
  </Box>
}