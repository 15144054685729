import {Box} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faClock, faPhone, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import {IPurchaseProcess, IPurchaseProcessState} from "pages/sales/IQuote";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { justFetch } from "mutations/mutate";
import endpoints from "endpoints";
import { useAlert } from "context/AlertProvider";
import React from "react";

const UpcomingRenewalStatus: React.FC<{ purchaseProcess: IPurchaseProcess, mutateRenewals: () => Promise<any> }> = ({purchaseProcess, mutateRenewals}) => {
  const sharedClasses = useSharedStyles()
  const alert = useAlert()

  const handleChangeStatus = async(state: IPurchaseProcessState) => {
    const statusResponse = await justFetch(endpoints.godmode.renewals.purchaseProcessState(purchaseProcess.id), 'POST', {
      state: state
    })

    if (statusResponse.ok) {
      alert.success('Status updated successfully!')
      mutateRenewals()
      return
    }

    alert.error('Failed to update Purchase Process status')
  }

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        gap: '1rem'
      }}
    >
      <Box className={sharedClasses?.vspacing2}>
        {purchaseProcess?.state !== 'renewal_commit' && 
          <Button
            variant="contained"
            color="green"
            onClick={() => handleChangeStatus('renewal_commit')}
          >
            <FontAwesomeIcon icon={faCheckCircle} />
          </Button>
        }
        {purchaseProcess?.state !== 'renewal_deciding' &&
          <Button
            variant="contained"
            color="orange"
            onClick={() => handleChangeStatus('renewal_deciding')}
          >
            <FontAwesomeIcon icon={faClock} />
          </Button>
        }
      </Box>
      <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column">
        {purchaseProcess?.state !== 'renewal_declined' &&
          <Button
            variant="contained"
            color="red"
            onClick={() => handleChangeStatus('renewal_declined')}
          >
            <FontAwesomeIcon icon={faXmarkCircle} />
          </Button>
        }
        {purchaseProcess?.state !== 'renewal_contacted' &&
          <Button
            variant="contained"
            color="blue"
            onClick={() => handleChangeStatus('renewal_contacted')}
          >
            <FontAwesomeIcon icon={faPhone} />
          </Button>
        }
      </Box>
    </Box>
  )
}

export default UpcomingRenewalStatus