import {Box, makeStyles, Paper, Typography} from "@material-ui/core";
import {GameTitle} from "../../enums/GameTitle";
import React from "react";

export type CardGameProps = {
  game: GameTitle.CREATOR | GameTitle.BASICS
  enabled: boolean
  onClick: () => void
}

const GameCard: React.VFC<CardGameProps> = ({game, onClick, enabled}) => {
  const useCardStyles = makeStyles(() => ({
    classCard: {
      transition: 'transform 0.25s',
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.01)'
      }
    }
  }))
  const getCardConfig = (game: GameTitle) => {
    if (game === GameTitle.BASICS) {
      return {
        icon: '/images/onboarding/basics-app-icon.png',
        color: "#ffb40f",
        name: "BASICS",
        grades: 'GRADES K-2',
      }
    } else {
      return {
        icon: '/images/onboarding/creator-app-icon.png',
        color: "#C32F96",
        name: "CREATOR",
        grades: 'GRADES 3-5',
      }
    }
  }
  const classes = useCardStyles();
  const config = getCardConfig(game);

  return (
    <Box component={Paper} p={2} pb={1} width={220} m={3} onClick={onClick}
         className={classes.classCard}
         flexDirection={'column'} display={'flex'} alignItems={'center'}
         data-testid={'gamecard'}
         style={{
           border: `0.4rem solid ${config.color}`,
           borderRadius: 15,
           filter: `grayscale(${enabled ? 0 : 0.9})`,
           opacity: `${enabled ? 1 : 0.5}`,
           cursor: "pointer"
         }}
    >
      <img src={config.icon} width={125} alt={config.name}
           style={{
             borderRadius: 15,
             userSelect: 'none'
           }}
      />
      <img src={'/images/logos/kodable-logo.svg'} width={125} alt={'Kodable Logo'}
           style={{
             paddingTop: 10,
             userSelect: 'none'
           }}
      />
      <Typography
        style={{
          fontFamily: 'Bjola',
          fontSize: '1.2rem',
          paddingTop: '.25rem',
          color: config.color
        }}
      >
        {config.name}
      </Typography>
      <hr color={config.color} style={{height: 3, width: '90%'}}/>
      <Typography
        style={{
          fontFamily: 'Bjola',
          fontSize: '0.9rem',
          paddingTop: '.75rem',
          color: config.color
        }}>{config.grades}</Typography>
    </Box>
  )
}

export default GameCard;