import useSWR from 'swr';
import endpoints from "../endpoints";

export default function useVideoAssignments(studentCode: string) {
  const { data, error, mutate, isValidating } = useSWR(
    endpoints.video.assignments.byStudentCode(studentCode),
    {
      dedupingInterval: 200000,
    }
  );

  return {
    videoAssignments: data,
    error: error,
    loading: isValidating,
    mutate,
  };
}
