import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Box, Button, Link, Paper, Typography} from "@material-ui/core"
import useSharedStyles from "components/useSharedStyles";
import { IGodmodeUserProfile } from "../IGodmodeUserProfile";
import React, {useState} from "react";
import {justFetch} from "../../../../../../mutations/mutate";
import endpoints from "../../../../../../endpoints";
import {useAlert} from "../../../../../../context/AlertProvider";
import {GodmodeConfirmDialog} from "../../../../shared/GodmodeConfirmDialog";

export const CleverDetailsBlock: React.FC<{ teacher: IGodmodeUserProfile }> = ({ teacher }) => {
  const sharedClasses = useSharedStyles();
  const [error, setError] = useState<string>('');
  const [confirmationState, setConfirmationState] = useState<boolean>(false);
  const alert = useAlert();

  const cleverUnlink = async () => {
    const res = await justFetch(endpoints.godmode.cleverUnlink.teacher(teacher.id), 'POST')
    setConfirmationState(false);
    if (!res.ok) {
      setError(res.statusText);
      alert.error(`${res.status}: ${res.statusText}`)
    }else {
      alert.success(`Teacher has been unlinked from clever`);
    }
  }
  
  return <Paper variant="outlined">
      <GodmodeConfirmDialog
        dialogState={{
          open: confirmationState,
          onClose: () => {
            setConfirmationState(false);
          }
        }}
        variant="danger"
        dialogTitle={`Are you sure you want to unlink this teacher from Clever?`}
        dialogContent={`All associated classes and students will be unlinked as well. This action cannot be undone.`}
        onConfirm={cleverUnlink}
      />
    <Box p={2} display="flex" flexDirection="column" alignItems="center" className={sharedClasses.vspacing2}>
      <Typography variant="h5">Clever Details</Typography>
      {teacher.clever_district && <Link target="_blank" href={`https://apps.clever.com/kodable/district/${teacher.clever_district.clever_id}`}>View organization details in Clever</Link>}
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Box display="flex" alignItems="center" mr={2}>
          Signed up with Clever&nbsp;{teacher.has_clever_account ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimesCircle} />}
        </Box>
        <Box display="flex" alignItems="center" ml={2}>
          Imported classes with Clever&nbsp;{teacher.has_clever_klasses ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimesCircle} />}
        </Box>
      </Box>
      {(!teacher.clever_district && teacher.has_clever_account) && <>
          <Button 
          variant="contained" 
          color="primary"
          onClick={() => {
            setConfirmationState(true);
            setError('');
          }}
          >Unlink From Clever</Button>
          {error && <Typography color="error">An error occurred. Talk to a web dev. ({error})</Typography>}
      </>}
    </Box>
  </Paper>
}