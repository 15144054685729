import React, { Fragment } from 'react';
import Youtube from 'react-youtube';
import { saveAs } from 'file-saver';

const WelcomeSection: React.FC = () => {
  const download = () => {
    fetch('https://s3-us-west-2.amazonaws.com/resources.kodable.com/kodable-field-guide.pdf')
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, 'kodable-field-guide.pdf');
      });
  };

  return (
    <Fragment>
      <div className="bg-kodable-teal bg-opacity-80 text-white py-4 px-4 font-bold">
        Welcome to Fuzztopia
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1 relative mt-12">
          <div className="relative">
            <Youtube
              videoId="BWBttweZBEE"
              containerClassName="youtubeContainer"
            />
            <div className="absolute bg-white bottom-1 left-1 right-1 rounded-b-lg py-2 lg:py-4 px-3">
              <h4 className="font-medium mb-1">Learn About Fuzztopia</h4>
              <p className="font-light text-sm">
                The fuzzFamily lives on planet FUZZTOPIA. Welcome! Come take a
                tour and learn all about their home
              </p>
            </div>
          </div>
        </div>
        <div className="lg:ml-12 lg:my-12 mb-6 flex lg:flex-col flex-col md:flex-row justify-center items-center">
          <img
            width="818"
            className="w-64 rounded-2xl"
            src="/images/curriculum_images/teacher_guide_thumbnail.png"
            alt="guide"
          />
          <div>
            <button
              onClick={download}
              className="md:ml-6 lg:ml-0 mt-4 w-full flex items-center justify-center bg-kodable-green border border-kodable-green hover:border-kodable-green  hover:bg-kodable-green-dark active:bg-kodable-green-darker active:border-kodable-green px-4 py-2 rounded text-white">
              Download Parent Guide
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WelcomeSection;
