import { Typography, TextField as MatTextField, TextFieldProps as MatTextFieldProps, Box, makeStyles } from "@material-ui/core";
import { ClassesProp } from "./ClassesProp";
import React from "react";

const useTextFieldStyles = makeStyles({
  wrapper: {

  }
})

const TextField: React.VFC<MatTextFieldProps & ClassesProp<typeof useTextFieldStyles>> = ({ label, ...props }) => {
  const classes = useTextFieldStyles(props);
  const { wrapper, ...classesWithoutWrapper } = props.classes || {};

  return <Box {...(props.fullWidth ? { width: '100%' } : {})} className={classes.wrapper}>
    {label && <label htmlFor={props.id}>
      <Typography
        variant="subtitle1"
      >{label}</Typography>
    </label>}
    <MatTextField
      variant="outlined"
      fullWidth
      margin="dense"
      {...props}
      classes={classesWithoutWrapper}
    />
  </Box>
}

export default TextField;