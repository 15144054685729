import { Box, CircularProgress, Tab, Tabs } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { IRouteParams } from "AppRoutes";
import PageContainer from "components/ui/PageContainer";
import PageHeader from "components/ui/PageHeader";
import endpoints from "endpoints";
import { ISalesProfile } from "pages/settings/teacher/ISalesProfile";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import { AccountPage } from "./account/AccountPage";
import { IGodmodeUserProfile } from "./IGodmodeUserProfile";
import { SubscriptionPage } from "./subscription/SubscriptionPage";
import {routes} from "../../../../../routes";
import React from "react";

export const ViewUserPage: React.FC = () => {
  const { path, url, params } = useRouteMatch<IRouteParams>();
  const { data: teacher, error } = useSWR<IGodmodeUserProfile>(endpoints.godmode.teacher(params.teacherId));
  const { data: salesProfile } = useSWR<ISalesProfile>(endpoints.salesProfile(params.teacherId));
  const { pathname } = useLocation();
  const history = useHistory();

  if (error) {
    return <PageContainer variant="full">
      <PageHeader inAppBar title="View Teacher" />
      <Alert severity="error">There was an error loading this teacher.</Alert>
    </PageContainer>
  }

  if (!teacher || !salesProfile) {
    return <PageContainer variant="full">
      <PageHeader inAppBar title="View Teacher" />
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    </PageContainer>
  }

  return <Box>
    <Box pt={2} mb={2} bgcolor="rgba(245,245,245,1)">
      <Tabs
        value={pathname}
        onChange={(ev, newPage) => history.push(newPage)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Account" value={routes.godmode.user.account(undefined, url)} disableRipple />
        {salesProfile?.plan_history.length > 0 && <Tab label="Subscription" value={routes.godmode.user.subscription(undefined, url)} disableRipple />}
      </Tabs>
    </Box>
    <Box p={2}>
      <Switch>
        <Route
          component={AccountPage}
          path={`${path}/account`}
          exact
        />
        <Route
          component={SubscriptionPage}
          path={`${path}/subscription`}
          exact
        />
        <Redirect to={`${path}/account`} />
      </Switch>
    </Box>
  </Box>
}