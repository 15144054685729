import useSWR from 'swr';
import endpoints from "../endpoints";
import {IResource} from "../types/IResource";

export default function useVideos() {
  const { data, error, mutate, isValidating } = useSWR(
    endpoints.resourcesVideos,
    {
      dedupingInterval: 200000,
    }
  );

  return {
    videos: data as IResource[],
    error: error,
    loading: isValidating,
    mutate,
  };
}
