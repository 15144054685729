import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import CodingConceptVideosPage from './CodingConceptVideosPage';
import CreateActivityVideosPage from './CreateActivityVideosPage';

//Routes
import VideosPage from './VideosPage';
import VideoPage from './VideoPage';

export default function LearningIndexPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={VideosPage} exact path={path} />
      <Route
        component={CodingConceptVideosPage}
        exact
        path={`${path}/coding-concepts`}
      />
      <Route
        component={CreateActivityVideosPage}
        exact
        path={`${path}/creative-activities`}
      />
      <Route component={VideoPage} exact path={`${path}/:category/:id`} />
      <Redirect to={path} />
    </Switch>
  );
}
