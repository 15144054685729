export const missionColorMap: Record<string, string> ={
    '0': '#01b343',
    '1': '#108cab',
    '2': '#8b4acf',
    '3': '#da4d55'
}

export const missionNameMap: Record<string, string> ={
    '0': 'Beginner',
    '1': 'Intermediate',
    '2': 'Advanced',
    '3': 'Mastery'
}

export const majorBadgeOuterSprites: Record<string, string> ={
    '0': '/images/badges/outer/badge_major_stageOne.png',
    '1': '/images/badges/outer/badge_major_stageTwo.png',
    '2': '/images/badges/outer/badge_major_stageThree.png',
    '3': '/images/badges/outer/badge_major_stageFour.png'
}