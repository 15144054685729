import { Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { useFormik } from "formik";
import { justFetch } from "mutations/mutate";
import React, { useEffect } from "react";
import { useState } from "react";
import { mutate } from "swr";
import { IStudentSchoolProfile } from "types/IStudentSchoolProfile";
import * as Yup from 'yup';

interface EditStudentDialogProps {
  open: boolean;
  onClose?: (...args: any[]) => any;
  studentToEdit?: IStudentSchoolProfile;
}

const EditStudentDialog: React.VFC<EditStudentDialogProps> = ({ open, onClose = () => { }, studentToEdit }) => {
  const alert = useAlert();
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: studentToEdit?.name
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Enter the student's name")
    }),
    onSubmit: values => {
      if (studentToEdit) {
        return justFetch(endpoints.studentById(studentToEdit?.id), 'PUT', values)
          .then(res => {
            if (!res.ok) {
              throw new Error();
            }

            onClose();
            alert.success('Student Saved');
            mutate(endpoints.teacherInit);
          })
          .catch(() => setSubmitError(true));
      }
    }
  });

  useEffect(() => {
    if (open) {
      form.resetForm();
      setSubmitError(false);
    }
  // eslint-disable-next-line
  }, [open]);

  const [submitError, setSubmitError] = useState(false);
  const sharedClasses = useSharedStyles();

  return <Dialog fullWidth open={open}>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>Edit student</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <TextField
          label="Name"
          id="name"
          value={form.values.name}
          onChange={form.handleChange}
          error={!!form.errors.name}
          helperText={form.errors.name}
        />
        
        {
          !form.isValid && form.submitCount > 0 && <Box><Alert severity="error">Please correct the errors above to continue.</Alert></Box>
        }

        {submitError && <Alert severity="error" action={
          <Button
            color="inherit"
            size="small"
            onClick={() => form.submitForm()}
          >Try again</Button>
        } >There was an error trying to submit this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="reset"
          onClick={onClose}
          disabled={form.isSubmitting}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={form.isSubmitting}
        >
          Save
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default EditStudentDialog;