import {ConfirmationDialog} from "components/dialogs/base/ConfirmationDialog";
import {justFetch} from "mutations/mutate";
import endpoints from "endpoints";
import React, {useState} from "react";
import {IParentSubscription} from "pages/godmode/Users/Teachers/ViewUser/IGodmodeUserProfile";

type CancelStripeSubscriptionDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  teacherId: number
  subscription: IParentSubscription | undefined
}

const CancelStripeSubscriptionDialog: React.VFC<CancelStripeSubscriptionDialogProps> =
  ({teacherId, open, onClose, onSuccess}) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  const cancelSubscription = async () => {
    setSubmitting(true);
    const res = await justFetch(endpoints.godmode.cancelSubscription(teacherId), 'POST');
    setSubmitting(false);

    if (res.ok) {
      onSuccess();
    } else {
      const body = await res.json();
      setError(body.error || body.message || 'An unknown error occurred.');
    }
  }

  return (
    <ConfirmationDialog
      open={open}
      title="Cancel parent subscription?"
      subtitle="Cancel this subscription at the end of the current period."
      verb="Cancel Subscription"
      onClose={onClose}
      onConfirm={cancelSubscription}
      danger
      submitting={submitting}
      error={error}
    />
  )
}

export default CancelStripeSubscriptionDialog;
