import React, { Fragment } from 'react';
import TopNav from '../shared/TopNav';
import {
  AsteroidiaProject,
  FuzzBuilderProject,
  MazeMakerProject,
} from './shared/Projects';

const BugWorldCreativeProjects: React.FC = () => {
  return (
    <Fragment>
      <TopNav
        title="Bug World"
        navTitle="Creative Projects"
        guideImage="/images/curriculum_images/gameuniverse_bugworld.jpg"
        navLinks={[
          { title: 'Learning Tools', to: '/learning-tools' },
          {
            title: 'Bug World',
            to: '/learning-tools/bugworld',
          },
          {
            title: 'Creative Projects',
            to: '/learning-tools/creative-projects/bugworld',
          },
        ]}
      />
      <div className="flex flex-col justify-center items-center w-full">
        <div className="my-8 w-full max-w-screen-lg px-2">
          <FuzzBuilderProject />
          <MazeMakerProject />
          <AsteroidiaProject />
        </div>
      </div>
    </Fragment>
  );
};

export default BugWorldCreativeProjects;
