import useCurrentUser from "loaders/useCurrentUser";
import React, { useMemo, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Amplitude, { AmplitudeProject } from 'utils/Amplitude';
import Cookies from "universal-cookie";

const cookies = new Cookies();

type ITrackingProviders = ('amplitude' | 'intercom')[];
type ITrackingEvent = { name: string, props: { [key: string]: any }, providers?: ITrackingProviders };
interface ITrackingContext {
  track: (name: string, props: { [key: string]: any }, providers?: ITrackingProviders) => void
}

const TrackingContext = React.createContext<ITrackingContext>(null!);


const TrackingProvider: React.FC = ({ children }) => {
  const { currentUser } = useCurrentUser();
  const [eventQueue, setEventQueue] = useState<ITrackingEvent[]>([]);
  const [didIdentifyAmplitude, setDidIdentifyAmplitude] = useState(false);
  const [didIdentifyIntercom, setDidIdentifyIntercom] = useState(false);

  useEffect(() => {
    setDidIdentifyAmplitude(false);
    setDidIdentifyIntercom(false);
  }, [currentUser?.id]);

  useEffect(() => {
    if (currentUser?.id && !didIdentifyAmplitude) {
      Amplitude.identify(currentUser.id, currentUser.is_parent ? AmplitudeProject.parent : AmplitudeProject.teacher);

      Amplitude.people.set({
        $email: currentUser.username,
        $name: currentUser.name,
        Plan: currentUser.plan,
        Occupation: currentUser.occupation,
      });

      Amplitude.register({
        Occupation: currentUser.occupation,
        'Account Type': currentUser.is_parent ? 'Parent' : 'Teacher',
      });

      setDidIdentifyAmplitude(true);
    }
  }, [didIdentifyAmplitude, setDidIdentifyAmplitude, currentUser]);

  useEffect(() => {
    if (!process.env.REACT_APP_INTERCOM_ID) {
      return;
    }

    if (currentUser?.id && !didIdentifyIntercom) {
      const numbersRegex = /^[0-9]+$/;
      const filteredEmailsRegex = /.*(student|kodable|stu\.).*/

      const noOccupation = !currentUser.occupation;
      const filteredEmail = !!currentUser.username.match(filteredEmailsRegex);
      const numbersEmail = currentUser.plan === 'Free' && currentUser?.username.split('@')[0].match(numbersRegex);

      const noBoot = noOccupation || filteredEmail || numbersEmail || !!cookies.get('admin_kodable_kode');

      if (!noBoot) {
        (window as any).Intercom('boot', {
          hide_default_launcher: false,
          app_id: process.env.REACT_APP_INTERCOM_ID,
          email: currentUser?.username,
          user_id: currentUser?.id.toString(),
          user_hash: currentUser.intercom_hash,
          created_at: new Date(currentUser.created_at),
          name: currentUser.name,
          Plan: currentUser.plan,
          Occupation: currentUser.occupation,
        });

        setDidIdentifyIntercom(true);
      }
    }
  }, [currentUser, didIdentifyIntercom, setDidIdentifyIntercom]);

  useEffect(() => {
    let didChange = false;

    const unprocessedEvents: ITrackingEvent[] = eventQueue.map(event => {
      const providers = event.providers || ['amplitude'];
      const unprocessedProviders: ITrackingProviders = [];

      if (providers.includes('amplitude')) {
        if (didIdentifyAmplitude) {
          Amplitude.track(event.name, event.props);
        } else {
          unprocessedProviders.push('amplitude');
        }
      }

      if (providers.includes('intercom')) {
        if (didIdentifyIntercom) {
          (window as any).Intercom('trackEvent', event.name, event.props);
        } else {
          unprocessedProviders.push('intercom');
        }
      }

      if (unprocessedProviders.length > 0) {
        if (unprocessedProviders.length !== providers.length) {
          didChange = true;
        }

        return { ...event, providers: unprocessedProviders }
      }

      didChange = true;
      return false as any;
    }).filter(Boolean);

    if (didChange) {
      setEventQueue(unprocessedEvents);
    }
  }, [eventQueue, setEventQueue, didIdentifyAmplitude, didIdentifyIntercom]);

  const track = useMemo(() => (name: string, props: { [key: string]: any }, providers?: ITrackingProviders) => {
    setEventQueue(queue => queue.concat([{ name, props, providers }]))
  }, [setEventQueue])

  return <TrackingContext.Provider value={{ track }}>{children}</TrackingContext.Provider>;
}

export default TrackingProvider;

export const useTracking = () => useContext(TrackingContext);