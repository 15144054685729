import {useRevenueStatsContext} from "context/RevenueStatsProvider";
import {Grid} from "@material-ui/core";
import {formatCurrency, formatThousandsCommas} from "pages/godmode/utils/numbers";
import {faPerson, faSchool, faUser, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import RevenueScoreCardIcon from "./RevenueScoreCardIcon";
import RevenueScoreCardItem from "./RevenueScoreCardItem";
import React from "react";

const RevenueScoreCards: React.VFC = () => {
    const {revenue_stats} = useRevenueStatsContext()

    return (
        <Grid container spacing={4} style={{marginBottom: "10px"}} justifyContent={"center"}>
            <RevenueScoreCardItem
                heading={formatCurrency(revenue_stats?.current_revenue?.monthly?.total)}
                name="Current Month Revenue"
            >
                <RevenueScoreCardIcon icon={faSchool}
                                      text={formatCurrency(revenue_stats?.current_revenue?.monthly?.school)}/>
                <RevenueScoreCardIcon icon={faPerson}
                                      text={formatCurrency(revenue_stats?.current_revenue?.monthly?.parent)}/>
            </RevenueScoreCardItem>
            <RevenueScoreCardItem
                heading={formatCurrency(revenue_stats?.current_revenue?.weekly?.total)}
                name="Current Week Revenue"
            >
                <RevenueScoreCardIcon icon={faSchool}
                                      text={formatCurrency(revenue_stats?.current_revenue?.weekly?.school)}/>
                <RevenueScoreCardIcon icon={faPerson}
                                      text={formatCurrency(revenue_stats?.current_revenue?.weekly?.parent)}/>
            </RevenueScoreCardItem>
            <RevenueScoreCardItem
                heading={formatCurrency(revenue_stats?.arr?.total)}
                name="Annual Recurring Revenue"
            >
                <RevenueScoreCardIcon icon={faSchool} text={formatCurrency(revenue_stats?.arr?.school)}/>
                <RevenueScoreCardIcon icon={faPerson} text={formatCurrency(revenue_stats?.arr?.parent)}/>
            </RevenueScoreCardItem>
            <RevenueScoreCardItem
                heading={formatThousandsCommas(revenue_stats?.subscriptions?.parent)}
                name="Total Parent Subscriptions"
            />
            <RevenueScoreCardItem
                heading={formatThousandsCommas(revenue_stats?.subscriptions?.school?.total)}
                name="Total School Subscriptions"
            >
                <RevenueScoreCardIcon
                    icon={faUser}
                    text={`${formatThousandsCommas(revenue_stats?.subscriptions?.school?.premium)} Premium`}
                />
                <RevenueScoreCardIcon
                    icon={faUserPlus}
                    text={`${formatThousandsCommas(revenue_stats?.subscriptions?.school?.premium_plus)} Premium+`}
                />
            </RevenueScoreCardItem>
            <RevenueScoreCardItem
                heading={formatCurrency(revenue_stats?.lifetime_arr)}
                name="Lifetime Annual Recurring Revenue"
            >
            </RevenueScoreCardItem>
        </Grid>
    )
}

export default RevenueScoreCards