import { Box, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, LinearProgress, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useFormik } from "formik";
import useDialogState from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useState } from "react";
import { ILicense } from "types/ILicense";
import { ITeacher } from "types/ITeacher";
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import SearchSchoolDialog from "pages/godmode/Sales/Orders/components/SearchSchoolDialog";
import { SchoolInfoType } from "pages/godmode/Sales/Orders/components/SiteToSchoolBox";
import { useAlert } from "context/AlertProvider";

// style to make Existing Teacher text field the same style as Email text field
const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  selectField: {
    margin: theme.spacing(1, 0),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },
    '& .MuiSelect-select': {
      padding: '12px 12px',
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

interface AssignLicenseDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any, setError: React.Dispatch<React.SetStateAction<string | null>>) => void;
  initialValues: { license_id: number; site_name: string };
  teachers: ITeacher[] | undefined;
}

const AssignLicenseDialog: React.FC<AssignLicenseDialogProps> = ({ open, onClose, onSubmit, initialValues, teachers }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const [selectedTeacher, setSelectedTeacher] = useState<ITeacher|undefined>(undefined);

  const assignLicenseForm = useFormik({
    initialValues: {
      license_id: initialValues.license_id,
      site_name: initialValues.site_name,
      email: ''
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Enter a valid email address.').test('email-or-teacher', 'You must select an existing teacher or enter an email.', function (value) {
        if (selectedTeacher || value) {
          return true;
        }
        return false;
      })
    }),
    onSubmit: values => {

      const submissionData = {
        license_id: initialValues.license_id,
        site_name: initialValues.site_name,
        email: selectedTeacher ? selectedTeacher.username : values.email,
      };

      onSubmit(submissionData, (error)=>
      {
        setAssignLicenseSubmitError(error);
        assignLicenseForm.setSubmitting(false);
      }
      );
    }
  });
  const [assignLicenseSubmitError, setAssignLicenseSubmitError] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      setSelectedTeacher(undefined);
      setAssignLicenseSubmitError(null);
      assignLicenseForm.resetForm({
        values: {
          license_id: initialValues.license_id,
          site_name: initialValues.site_name,
          email: '',
        }
      });
    }
  }, [open, initialValues]);

  useEffect(()=>{
    assignLicenseForm.setFieldValue('email', '');
    assignLicenseForm.validateForm();
  },[selectedTeacher]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <LinearProgress style={{ visibility: assignLicenseForm.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
      <form onSubmit={assignLicenseForm.handleSubmit}>
        <DialogTitle>Assign Administrator for {assignLicenseForm.values.site_name}</DialogTitle>
        <DialogContent className={sharedClasses.vspacing2}>
          <Typography>
            This will set the person below as an administrator to your subscription for <b>{assignLicenseForm.values.site_name}</b>. They will be able to invite other teachers to be a part of their school license.
          </Typography>

          <FormControl
            variant="outlined"
            fullWidth
            disabled={assignLicenseForm.isSubmitting}
          >
          {teachers && (
            <>
          <TextField
            id="teacher"
            name="teacher"
            label="Assign existing member of your organization:"
            variant="outlined"
            select
            fullWidth
            margin="normal"
            className={classes.selectField}
            value={selectedTeacher ? String(selectedTeacher.id) : ''}
            onChange={(e) => {
              const teacherId = e.target.value as string;
              setSelectedTeacher(
                teacherId ? teachers.find((teacher) => String(teacher.id) === teacherId) : undefined
              );
            }}
            disabled={assignLicenseForm.isSubmitting}
            SelectProps={{
              displayEmpty: true,
              renderValue: (value) => {
                if (value === '') {
                  return (
                    <em style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                      {teachers.length > 0
                        ? 'Select existing teacher'
                        : 'No teachers available. You must enter email below'}
                    </em>
                  );
                }
                const selected = teachers.find((teacher) => String(teacher.id) === value);
                return selected ? `${selected.name} (${selected.username})` : '';
              },
            }}
          >
            <MenuItem value="">
              <em>
                {teachers.length > 0
                  ? 'Select existing teacher'
                  : 'No teachers available. You must enter email below'}
              </em>
            </MenuItem>
            {teachers.map((teacher) => (
              <MenuItem key={teacher.id} value={String(teacher.id)}>
                {`${teacher.name} (${teacher.username})`}
              </MenuItem>
            ))}
          </TextField>

              <Typography style={{ marginTop: '16px'}}>
              </Typography>
            </>
          )}
          <TextField
            id="email"
            name="email"
            label="Or invite a new teacher via email:"
            variant="outlined"
            value={assignLicenseForm.values.email}
            onChange={(e) => {
              setSelectedTeacher(undefined);
              assignLicenseForm.setFieldValue('email', e.target.value.trim());
            }}
            error={assignLicenseForm.submitCount > 0 && !!assignLicenseForm.errors.email}
            helperText={assignLicenseForm.submitCount > 0 ? assignLicenseForm.errors.email : undefined}
            disabled={assignLicenseForm.isSubmitting}
            onBlur={assignLicenseForm.handleBlur}
          />

          {assignLicenseSubmitError !== null && <Alert severity="error">{assignLicenseSubmitError}</Alert>}

          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={assignLicenseForm.isSubmitting}
          >Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={assignLicenseForm.isSubmitting}
          >Assign</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

interface UnassignLicenseDialogProps {
  open: boolean;
  onClose: () => void;
  teacherBeingUnassigned?: ITeacher;
  licenseBeingUnassigned?: ILicense;
  onUnassign: () => void;
  unassignLicenseError: boolean;
}

const UnassignLicenseDialog: React.FC<UnassignLicenseDialogProps> = ({ open, onClose, teacherBeingUnassigned, licenseBeingUnassigned, onUnassign, unassignLicenseError }) => {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Unassign License?</DialogTitle>
      <DialogContent>
        <Typography>
          This will remove the person below as an admin to your subscription for the selected site.
        </Typography>
        <Typography>
          {"\u00A0"}
        </Typography>
        <TextField
          id="site_name"
          label="Site Name"
          value={licenseBeingUnassigned?.site_name}
          disabled={true}
        />
        <TextField
          id="email"
          label="Email"
          value={teacherBeingUnassigned?.username}
          disabled={true}
        />
        <Typography>
          {"\u00A0"}
        </Typography>
        <Typography>
          {teacherBeingUnassigned?.name} ({teacherBeingUnassigned?.username}) and the teachers connected to their account will no longer have access to your subscription.
        </Typography>

        {unassignLicenseError && <Alert severity="error">There was an error submitting this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onUnassign}
        >Unassign</Button>
      </DialogActions>
    </Dialog>
  );
};

const LicensesTable: React.VFC = () => {
  const sharedClasses = useSharedStyles();

  const { teacherData, isValidating, mutate } = useTeacherInit();

  const assignLicenseDialogState = useDialogState();
  const unassignLicenseDialogState = useDialogState();

  const searchSchoolDialogState = useDialogState();
  const [searchSchoolLicense, setSearchSchoolLicense] = useState<ILicense>();

  const [teacherBeingUnassigned, setTeacherBeingUnassigned] = useState<ITeacher>();
  const [licenseBeingUnassigned, setLicenseBeingUnassigned] = useState<ILicense>();
  const [unassignLicenseError, setUnassignLicenseError] = useState(false);
  
  const [initialValues, setInitialValues] = useState({ license_id: 0, site_name: '' });
  const alert = useAlert();

  useEffect(() => {
    if (teacherBeingUnassigned) {
      setUnassignLicenseError(false);
    }
  }, [teacherBeingUnassigned])

  const handleAssignLicense = (values: any, setError: React.Dispatch<React.SetStateAction<string | null>>) => {
    setError(null);
    const body = {
      email: values.email,
      subscription_id: teacherData!.subscription.id,
      secret_key: teacherData!.subscription.secret_key,
      license_id: values.license_id,
      send_mail: true
    };
    return justFetch(endpoints.assignLicense(teacherData!.subscription.purchase_process_id), 'POST', body)
      .then(async res => {
        if (!res.ok) {
          const error = await res.json();
          throw new Error(error?.message || error?.error || 'An unknown error occurred.');
        }
        alert.success("Administrator Assigned!");
        mutate();
        assignLicenseDialogState.onClose();
      })
      .catch((error) => {
        console.log("ERROR", error, error?.message, error?.error);
        setError(error?.message || 'An unknown error occurred.');
        alert.error("Failed Assigning Administrator: ", error?.message || 'An unknown error occurred.')
      });
  };

  const handleUnassignLicense = () => {
    justFetch(endpoints.unassignLicense(teacherData!.subscription.purchase_process_id), 'PUT', {
      subscription_id: teacherData!.subscription.id,
      teacher_id: teacherBeingUnassigned!.id,
      license_id: licenseBeingUnassigned!.id
    })
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        mutate();
        setTeacherBeingUnassigned(undefined);
        setLicenseBeingUnassigned(undefined);
        unassignLicenseDialogState.onClose();
      })
      .catch(() => setUnassignLicenseError(true));
  };

  const licenseTeacher = (teacher_id: number) => {
    return teacherData?.teachers.find(teacher => teacher.id === teacher_id);
  };

  const assignedLicenses = () => {
    return licenses.filter(license => license.teacher_id);
  };

  const sortedLicenses = () => {
    return licenses.sort((a, b) => {
      const aAssigned = !!a.teacher_id;
      const bAssigned = !!b.teacher_id;
      if (aAssigned && !bAssigned) {
        return 1;
      } else if (!aAssigned && bAssigned) {
        return -1;
      } else {
        return (a?.site_name ?? '').localeCompare(b?.site_name ?? '');
      }
    });
  };

  const updateSchoolInfo = (schoolInfo: SchoolInfoType, nonTraditionalSchoolName?: string) => {

    const body = {
      license_id: searchSchoolLicense?.id,
      school_id: schoolInfo?.id,
      site_name: schoolInfo?.id? schoolInfo?.school_name : (nonTraditionalSchoolName || "School not set")
    };
    return justFetch(endpoints.assignSchool(teacherData!.subscription.purchase_process_id), 'POST', body)
      .then(async res => {
        if (!res.ok) {
          const error = await res.json();
          throw new Error(error?.message || error?.error || 'An unknown error occurred.');
        }
        mutate();
        searchSchoolDialogState.onClose();
        alert.success('School Assigned!');
      })
      .catch((error) => {
        console.log("ERROR", error, error?.message, error?.error);
        alert.error('Failed assigning school: ' + error?.message || 'An unknown error occurred.');
      });

  }

  if (!teacherData) {
    return <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  }
  const licenses = teacherData?.subscription?.licenses;
  return <>
    <SearchSchoolDialog
      open={searchSchoolDialogState.open}
      onClose={searchSchoolDialogState.onClose}
      searchOptions={{/*zipcode: zipCode, state: selectedSchool.state, city: selectedSchool.city*/ nonTraditionalSchoolName: searchSchoolLicense?.site_name || ''}}
      onSuccess={updateSchoolInfo}
      enableSetName={true}
    />

    <AssignLicenseDialog
      open={assignLicenseDialogState.open}
      onClose={assignLicenseDialogState.onClose}
      onSubmit={handleAssignLicense}
      initialValues={initialValues}
      teachers={teacherData.teachers}
    />
    <UnassignLicenseDialog 
      open={unassignLicenseDialogState.open}
      onClose={unassignLicenseDialogState.onClose}
      teacherBeingUnassigned={teacherBeingUnassigned}
      licenseBeingUnassigned={licenseBeingUnassigned}
      onUnassign={handleUnassignLicense}
      unassignLicenseError={unassignLicenseError}
    />
    <Box pb={2} className={sharedClasses.hspacing2}>
      <Chip
        label={`${assignedLicenses().length}/${teacherData?.subscription.licenses.length} licenses assigned`}
      />
    </Box>

    {isValidating && <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}

    {!isValidating && <TableContainer component={Paper} {...{ variant: 'outlined' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>School</b>
            </TableCell>
            <TableCell>
            </TableCell>
            <TableCell>
              Administrator Name
            </TableCell>
            <TableCell>
              Administrator Email
            </TableCell>
            <TableCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedLicenses()?.map(license => (
            <TableRow key={license.teacher_id}>
              <TableCell>
                <b>{license.site_name || 'None Selected'}</b>
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  color="primary"                 
                  onClick={() => {
                    setSearchSchoolLicense(license);
                    searchSchoolDialogState.handleOpen();
                  }}
                >
                  Change School
                </Button>
              </TableCell>
              <TableCell>
                {licenseTeacher(license.teacher_id)?.name}
              </TableCell>
              <TableCell>
                {licenseTeacher(license.teacher_id)?.username}
              </TableCell>
              <TableCell>
                <Button
                  variant={license.teacher_id? "outlined" : "contained"}
                  color="primary"
                  onClick={() => {
                    setInitialValues({ license_id: license.id, site_name: license.site_name });
                    assignLicenseDialogState.handleOpen();
                  }}
                >
                  {license.teacher_id ? "Re-":""}Assign Administrator
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
  </>
}

export default LicensesTable;
export { AssignLicenseDialog, UnassignLicenseDialog };
