import {Box, CircularProgress} from "@material-ui/core";
import PageContainer from "components/ui/PageContainer";
import PageHeader from "components/ui/PageHeader";
import React from "react";

export const GodmodePageWrapper: React.FC<{
  title: string;
  loading: boolean;
  showCleverIcon?: boolean;
  children: React.ReactNode
}> = ({
        title,
        loading,
        showCleverIcon,
        children
      }) => {

  if (loading) {
    return (
      <PageContainer>
        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
          <CircularProgress/>
        </Box>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={title} inAppBar showCleverIcon={showCleverIcon}/>
      {children}
    </PageContainer>
  )
}