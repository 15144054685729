import endpoints from 'endpoints';
import React, { createContext, useContext } from 'react';
import useSWR from 'swr';
import { IOrganizationProfile, IOrganizationProfileValidInclude } from 'types/IOrganizationProfile';

export interface IOrganizationProfileContext {
    organization_profile: IOrganizationProfile | null;
    mutate: () => Promise<any>;
}

const OrganizationProfileContext = createContext<IOrganizationProfileContext | null>(null);

export type OrganizationProfileProviderProps = {
    organizationProfileId?: string | number | null;
    subscriptionId?: string | number | null;
    teacherId?: string | number | null;
    includes?: IOrganizationProfileValidInclude[];
    children: React.ReactNode;
}

export const OrganizationProfileProvider: React.FC<OrganizationProfileProviderProps> = ({ 
        organizationProfileId,
        subscriptionId,
        teacherId,
        includes = ['administrator', 'teachers', 'subscriptions', 'licenses', 'clever', 'payment', 'purchase_process', 'current_subscription', 'quote_request_v2'],
        children 
    }) => {
    let endpoint = ''
    
    if (subscriptionId) {
        endpoint = endpoints?.godmode?.organizationProfile?.bySubscriptionId(subscriptionId, includes);
    } else if (organizationProfileId) {
        endpoint = endpoints?.godmode?.organizationProfile?.byId(organizationProfileId, includes);
    } else if (teacherId) {
        endpoint = endpoints?.godmode?.organizationProfile?.byAdministratorId(teacherId, includes);
    } else {
        throw new Error('OrganizationProfileProvider must be used with either organizationProfileId, subscriptionId, or teacherId');
    }

    const { data, mutate } = useSWR<IOrganizationProfile>(endpoint);

    return (
        <OrganizationProfileContext.Provider value={{ organization_profile: data || null, mutate }}>
            {children}
        </OrganizationProfileContext.Provider>
    );
};

export const useOrganizationProfile = (): IOrganizationProfileContext => {
    const context = useContext(OrganizationProfileContext);
    if (!context) {
        throw new Error('useOrganizationProfile must be used within an OrganizationProfileProvider');
    }
    return context;
}
