import {ConfirmationDialog} from "components/dialogs/base/ConfirmationDialog";
import {justFetch} from "mutations/mutate";
import endpoints from "endpoints";
import React, {useState} from "react";

type UpgradeLifetimeDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  teacherId: number
}

const UpgradeLifetimeDialog: React.VFC<UpgradeLifetimeDialogProps> =
  ({open, onSuccess, onClose, teacherId}) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  const upgradeSubscription = async () => {
    setSubmitting(true);
    const res = await justFetch(endpoints.godmode.upgrade(teacherId), 'POST', {
      id: teacherId
    });
    setSubmitting(false);

    if (res.ok) {
      onSuccess();
    } else {
      const body = await res.json();
      setError(body.error || body.message || 'An unknown error occurred.');
    }
  }

  return (
    <ConfirmationDialog
      open={open}
      title="Upgrade Parent Account"
      subtitle="Upgrade this Parent account to a Forever plan?"
      verb="Upgrade"
      onClose={onClose}
      onConfirm={upgradeSubscription}
      submitting={submitting}
      error={error}
    />
  )
}

export default UpgradeLifetimeDialog;
