import {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import endpoints from "../../../endpoints";
import React from "react";

export interface IStudentFuzz {
  id: number
  clientId: number
  studentId: number
  fuzzName: string
  createdAt: string
  updatedAt: string
  fuzzProperties: {
    body: string
    eyes: string
    mouth: string
  }
  dressFunctions: {
    type: string
    value: string
  }[]
}

const FuzzPrintout: React.VFC<{studentCode: string, fuzz: IStudentFuzz}> = ({studentCode, fuzz}) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={'/images/kodable-logo-dark.png'}/>
          <Text style={styles.title}>Education</Text>
        </View>
        <View style={styles.fuzzImageSection}>
          {fuzz?.clientId && <Image
            src={endpoints.studentWork('fuzz', 'fullsize', `${studentCode.slice(0, 5)}${fuzz?.clientId}`)}
            style={styles.fuzzImage} />
          }
        </View>
        <View style={styles.fuzzNameSection}>
          <Text style={styles.fuzzNameWriteIn}>Fuzz Name: </Text>
          <Text style={styles.fuzzNameWriteInLine}>_____________________________</Text>
        </View>
        <View style={styles.fuzzNameContainer}>
          <Text style={styles.fuzzName}>{fuzz?.fuzzName}.js</Text>
        </View>
        <View style={styles.codeBlock}>
          <View style={styles.codeBlockRow}>
            <Text>class <Text style={{color: '#e84461'}}>{fuzz?.fuzzName}</Text> extends <Text style={{color: '#e84461'}}>Fuzz</Text> {"{"}</Text>
          </View>
          <View style={styles.indent}>
            <View style={styles.codeBlockRow}>
              <Image style={styles.codeImageFunction} src={'/images/students/fuzz_code/function.png'}/>
              <Text><Text style={{color: '#f7931d'}}>constructor</Text>( ) {"{"}</Text>
            </View>
            <View style={styles.indent}>
              <View style={styles.codeBlockRow}>
                <Image style={styles.codeImage} src={'/images/students/fuzz_code/body.png'}/>
                <Text>this.<Text style={{color: '#27a9e1'}}>body</Text> = new <Text style={{color: '#e84461'}}>Body</Text>("{fuzz?.fuzzProperties.body}");</Text>
              </View>
              <View style={styles.codeBlockRow}>
                <Image style={styles.codeImage} src={'/images/students/fuzz_code/eyes.png'}/>
                <Text>this.<Text style={{color: '#27a9e1'}}>eyes</Text> = new <Text style={{color: '#e84461'}}>Eyes</Text>("{fuzz?.fuzzProperties.eyes}");</Text>
              </View>
              <View style={styles.codeBlockRow}>
                <Image style={styles.codeImage} src={'/images/students/fuzz_code/mouth.png'}/>
                <Text>this.<Text style={{color: '#27a9e1'}}>mouth</Text> = new <Text style={{color: '#e84461'}}>Mouth</Text>("{fuzz?.fuzzProperties.mouth}");</Text>
              </View>
            </View>
            <View style={styles.codeBlockRow}>
              <Text>{"}"}</Text>
            </View>
            <View style={styles.codeBlockRow}>
              <Image style={styles.codeImageFunction} src={'/images/students/fuzz_code/function.png'}/>
              <Text><Text style={{color: '#f7931d'}}>getDressed</Text>( ) {"{"}</Text>
            </View>
            <View style={styles.indent}>
              {fuzz?.dressFunctions.map((dress, index) => {
                return (
                  <View style={styles.codeBlockRow} key={index}>
                    <Image style={styles.codeImage} src={'/images/students/fuzz_code/item.png'}/>
                    <Text>this.<Text style={{color: '#f7931d'}}>addItem</Text>("{dress.value}");</Text>
                  </View>
                )
              })}
            </View>
            <View style={styles.codeBlockRow}>
              <Text>{"}"}</Text>
            </View>
          </View>
          <View style={styles.codeBlockRow}>
            <Text>{"}"}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

Font.register({
  family: 'Bjola',
  src: '/fonts/bjola.ttf'
});
Font.register({
  family: 'OpenSans',
  src: '/fonts/OpenSans-Regular.ttf'
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    height: 450
  },
  logo: {
    width: 120,
    height: 29
  },
  fuzzImage: {
    width: 230,
    height: 268,
    // overflow: "hidden"
  },
  fuzzNameWriteIn: {
    fontFamily: 'Bjola',
    fontSize: 28,
  },
  fuzzNameContainer: {
    position: "relative",
    right: -240,
    top: -130,
    textAlign: "center",
    width: 300
  },
  fuzzNameWriteInLine: {
    paddingTop: 10,
  },
  fuzzName: {
    fontSize: 36,
  },
  fuzzNameSection: {
    margin: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    position: "relative",
    top: -60
  },
  title: {
    fontSize: 36,
    textAlign: 'center',
    fontFamily: 'Bjola',
    paddingTop: 20,
    paddingLeft: 2
  },
  fuzzImageSection: {
    marginTop: 0,
    position: "relative",
    top: -40,
    justifyContent: "center",
    alignItems: "center"
  },
  codeBlock: {
    letterSpacing: 1,
    position: "relative",
    top: -100,
    marginLeft: 60,
    width: 500,
    height: 380,
    padding: 10,
    borderStyle: "solid",
    borderColor: "#EEE",
    borderWidth: 2,
    borderRadius: 4
  },
  codeBlockRow: {
    flexDirection: "row",
    marginBottom: 7,
    fontFamily: "OpenSans",
    fontSize: 16
  },
  codeImageFunction: {
    height: 20,
    width: 20,
    marginRight: 10,
  },
  codeImage: {
    height: 20,
    width: 20,
    marginRight: 5,
  },
  header: {
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20
  },
  indent: {
    marginLeft: 10,
  },
});


export default FuzzPrintout