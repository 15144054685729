import {IRevenueStatsRecord, useRevenueStatsContext} from "../../../../../context/RevenueStatsProvider";
import React, {useState} from "react";
import {formatRevenueStatsLabel, parseUTCDate} from "../../../utils/time";
import {Box, Divider, Grid, Tab, Tabs, Typography} from "@material-ui/core";
import {TabContext} from "@material-ui/lab";
import GenericChart from "./GenericChart";
import {RevenueIndexIntervals} from "../RevenueIndex";

export const RevenueChart: React.VFC = () => {
    const {revenue_stats} = useRevenueStatsContext();
    const [interval, setInterval] = useState<RevenueIndexIntervals>('weekly');

    const relevantData = revenue_stats?.historic_revenue?.[interval]?.filter((record) => {
        if (interval === 'weekly') {
            const currentDate = new Date()
            const oneYearAgo = new Date(currentDate)
            oneYearAgo.setFullYear(currentDate.getFullYear() - 1)
            const recordDate = new Date(parseUTCDate(record.created_at))
            return recordDate >= oneYearAgo
        }
        return true
    })

    const revenueStatsParsed = relevantData?.map((record: IRevenueStatsRecord) => ({
        ...record,
        created_at: parseUTCDate(record.created_at),
    }));

    const labels = revenueStatsParsed?.map((record) => {
        const date = new Date(record.created_at)
        return formatRevenueStatsLabel(interval, date)
    }) || [];

    const dataSets = [
        {
            label: 'School Revenue',
            data: revenueStatsParsed?.map((record) => record.s_revenue) || [],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            fill: true,
        },
        {
            label: 'Parent Revenue',
            data: revenueStatsParsed?.map((record) => record.p_revenue) || [],
            borderColor: 'rgb(255, 205, 86)',
            backgroundColor: 'rgba(255, 205, 86, 0.5)',
            fill: true,
        },
        {
            label: 'Total Revenue',
            data: revenueStatsParsed?.map((record) => record.revenue) || [],
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
            fill: true,
        }
    ]

    const handleChange = (event: React.ChangeEvent<{}>, newValue: RevenueIndexIntervals) => {
        setInterval(newValue);
    }

    return (
        <Grid item xs={12} style={{marginTop: "20px"}}>
            <Typography variant="h1" align="center">Revenue</Typography>
            <Box>
                <TabContext value={interval}>
                    <Tabs value={interval} onChange={handleChange} style={{backgroundColor: 'transparent'}}>
                        <Tab value="weekly" label="Weekly"/>
                        <Tab value="monthly" label="Monthly"/>
                        <Tab value="quarterly" label="Quarterly"/>
                    </Tabs>
                </TabContext>
                <GenericChart
                    optionsLabel={`Revenue Chart (${interval})`}
                    labels={labels}
                    dataSets={dataSets}
                />
            </Box>
            <Divider style={{marginTop: "20px"}} />
        </Grid>
    );
}

export default RevenueChart