import React, { useContext, useState } from "react";
import { useMemo } from "react";
import queryString from 'query-string';

const queryParams = queryString.parse(window.location.search);

interface IPromoContext {
  code?: string;
}

const PromoContext = React.createContext<IPromoContext>({} as any);

const PromoProvider: React.FC = ({ children }) => {
  const [code, setCode] = useState<string>(queryParams.promotion as string);

  const promoContextValue = useMemo(() => ({
    code
  }), [code]);

  return <PromoContext.Provider value={promoContextValue}>{children}</PromoContext.Provider>;
}

export const usePromo = () => {
  const { code } = useContext(PromoContext);

  if (code === 'c9t7xmeq') {
    return { code, promo: 'retrial' as const };
  }

  if (code === 'gatgehx3') {
    return { code, promo: 'dollar-first-month' as const };
  }

  return { code }
};

export default PromoProvider;