import endpoints from 'endpoints';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

export interface IRevenueStatsBasicBreakdown {
    total: number;
    parent: number;
    school: number;
}

export interface IRevenueStatsSubscriptionsBreakdown {
    trial: number;
    parent: number;
    school: {
      total: number;
      premium: number;
      premium_plus: number;
    }
}

export interface IRevenueStatsRecord {
  created_at: string;
  revenue: number;
  s_revenue: number;
  p_revenue: number;
  p_churn: number;
}

export interface ISimpleRevenueStats {
  arr: number;
  game_access_plan_count: number;
  mrr: number;
  total_accounts: number;
}

export type RevenueStatsHistoricSchoolChurnItem = {
  percentage_revenue_churned: number;
  percentage_subscriptions_churned: number;
  subscription_churn: number;
  subscription_expiring: number;
  subscription_revenue_churn: number;
  subscription_revenue_expiring: number;
}

export type RevenueStatsHistoricSchoolChurn = {
  [key: string]: RevenueStatsHistoricSchoolChurnItem
};

export interface IRevenueStats {
    arr: IRevenueStatsBasicBreakdown;
    lifetime_arr: number;
    subscriptions: IRevenueStatsSubscriptionsBreakdown;
    current_revenue: {
        weekly: IRevenueStatsBasicBreakdown;
        monthly: IRevenueStatsBasicBreakdown;
    },
    historic_revenue: {
      daily: IRevenueStatsRecord[];
      weekly: IRevenueStatsRecord[];
      monthly: IRevenueStatsRecord[];
      quarterly: IRevenueStatsRecord[];
    },
    historic_school_churn: RevenueStatsHistoricSchoolChurn
}

export interface RevenueStatsContextProps {
    revenue_stats: IRevenueStats | undefined;
    loading: boolean;
    error: any;
    mutate: () => void;
}

const RevenueStatsContext = createContext<RevenueStatsContextProps>({
  revenue_stats: {
    arr: {
        total: 0,
        parent: 0,
        school: 0,
    },
    lifetime_arr: 0,
    subscriptions: {
        trial: 0,
        parent: 0,
        school: {
          total: 0,
          premium: 0,
          premium_plus: 0
        }
    },
    current_revenue: {
        weekly: {
            total: 0,
            parent: 0,
            school: 0,
        },
        monthly: {
            total: 0,
            parent: 0,
            school: 0,
        },
    },
    historic_revenue: {
      daily: [],
      weekly: [],
      monthly: [],
      quarterly: [],
    },
    historic_school_churn: {}
  },
  loading: true,
  error: null,
  mutate: () => {}
});

interface RevenueProviderProps {
  children: ReactNode;
}

export const RevenueStatsProvider: React.FC<RevenueProviderProps> = ({ children }) => {
  const [revenueStats, setRevenueStats] = useState<IRevenueStats>();
  const [loading, setLoading] = useState<boolean>(true);
  
  const { data, mutate, error, isValidating } = useSWR(endpoints.godmode.revenueStats);

  
  useEffect(() => {
    if (data) {
      setRevenueStats(data)
      setLoading(false)
    }
    
  }, [data]);

  useEffect(() => {
    if (isValidating) {
      setLoading(true)
      return
    }
    setLoading(false)
  }, [isValidating])
  
  return (
    <RevenueStatsContext.Provider value={{ revenue_stats: revenueStats, loading, error, mutate }}>
      {children}
    </RevenueStatsContext.Provider>
  );
};

export const useRevenueStatsContext = () => useContext(RevenueStatsContext);
