import React, { createContext, useContext, useState } from 'react';
import { IOrganizationProfile } from 'types/IOrganizationProfile';

type OrganizationSelectionSiteType = 'school' | 'district' | 'other' | null;

interface OrganizationSelectionContextProps {
    isOutsideUS: boolean | null;
    setIsOutsideUS: (value: boolean | null) => void;
    zipCode: number | null;
    setZipCode: (value: number | null) => void;
    city: string | null;
    setCity: (value: string | null) => void;
    state: string | null;
    setState: (value: string | null) => void;
    schoolId: number | null;
    setSchoolId: (value: number | null) => void;
    districtId: number | null;
    setDistrictId: (value: number | null) => void;
    siteType: OrganizationSelectionSiteType;
    setSiteType: (value: OrganizationSelectionSiteType) => void;
    displayName: string;
    setDisplayName: (value: string) => void;
    isNewOrganization: boolean | null;
    setIsNewOrganization: (value: boolean | null) => void;
    organizationProfile: IOrganizationProfile | null;
    setOrganizationProfile: (value: IOrganizationProfile | null) => void;
    hasCurriculum: boolean;
    setHasCurriculum: (value: boolean) => void;
    isLocationSet: boolean; // only used for orders to redirect from plan to select-location if needed, not used in quotes. remove if siteType could be used instead for the same purpose
    setIsLocationSet: (value: boolean) => void;
}

const OrganizationSelectionContext = createContext<OrganizationSelectionContextProps | undefined>(undefined);

export const useOrganizationSelection = (): OrganizationSelectionContextProps => {
    const context = useContext(OrganizationSelectionContext);
    if (!context) {
        throw new Error('useOrganizationSelection must be used within an OrganizationSelectionProvider');
    }
    return context;
};

export const OrganizationSelectionProvider: React.FC = ({ children }) => {
    // Do currentUser stuff in here, so it's easy to reset the context when we need to change the flow

    const [isOutsideUS, setIsOutsideUS] = useState<boolean | null>(false)
    const [schoolId, setSchoolId] = useState<number | null>(null)
    const [districtId, setDistrictId] = useState<number | null>(null)
    const [siteType, setSiteType] = useState<OrganizationSelectionSiteType>(null)
    const [zipCode, setZipCode] = useState<number | null>(null)
    const [city, setCity] = useState<string | null>(null)
    const [state, setState] = useState<string | null>(null)
    const [displayName, setDisplayName] = useState<string>('')
    const [organizationProfile, setOrganizationProfile] = useState<IOrganizationProfile | null>(null)
    const [isNewOrganization, setIsNewOrganization] = useState<boolean | null>(null)
    const [hasCurriculum, setHasCurriculum] = useState<boolean>(false)
    const [isLocationSet, setIsLocationSet] = useState<boolean>(false)

    return (
        <OrganizationSelectionContext.Provider
            value={{
                isOutsideUS,
                setIsOutsideUS,
                zipCode,
                setZipCode,
                city,
                setCity,
                state,
                setState,
                schoolId,
                setSchoolId,
                districtId,
                setDistrictId,
                displayName,
                setDisplayName,
                siteType,
                setSiteType,
                isNewOrganization,
                setIsNewOrganization,
                organizationProfile,
                setOrganizationProfile,
                hasCurriculum,
                setHasCurriculum,
                isLocationSet,
                setIsLocationSet
            }}
        >
            {children}
        </OrganizationSelectionContext.Provider>
    );
};
