import { Box } from "@material-ui/core"
import ReferralForm from "components/form/ReferralForm"
import PageContainer from "components/ui/PageContainer"
import { useState } from "react"

const ReferralPage = () => {
    const [isInviting, setIsInviting] = useState<boolean>(false)
    
    return (
        <PageContainer>
            <Box display="flex" justifyContent="center">
                <ReferralForm inviting={[isInviting, setIsInviting]} />
            </Box>
        </PageContainer>
    )
}

export default ReferralPage