import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

const worlds = [
  {
    name: 'Smeeborg',
    link: 'smeeborg',
    color: 'bg-kodable-green',
  },
  {
    name: 'Asteroidia',
    link: 'asteroidia',
    color: 'bg-kodable-purple',
  },
  {
    name: 'Bug World',
    link: 'bugworld',
    color: 'bg-kodable-pink',
  },
];

interface NavLink {
  title: string;
  to: string;
}

interface TopNavProps {
  title: string;
  guideImage?: string;
  navTitle?: string;
  backLink?: string;
  navLinks: NavLink[];
  answerKeys?: boolean;
}

const TopNav: React.FC<TopNavProps> = ({
                                         title,
                                         guideImage,
                                         navTitle = 'Parent Guides',
                                         backLink = '/learning-tools',
                                         navLinks,
                                         answerKeys,
                                       }) => {
  const location = useLocation();

  let paths = location.pathname.split('/');
  paths.pop();
  const path = paths.join('/');

  return (
    <nav className="flex pt-16 sm:pt-12 px-4 pb-8 border-b sticky top-0 bg-white z-30">
      <div>
        <Link
          to={backLink}
          className="text-white bg-kodable-green rounded py-2 px-4 mr-4 md:mr-8 block">
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </Link>
      </div>
      <div className="flex flex-col text-gray-800">
        <h1 className="text-2xl md:text-3xl font-light">{navTitle}</h1>
        <div className="flex mt-2 text-sm md:text-base">
          {navLinks[0] && <Link to={navLinks[0].to}>{navLinks[0].title}</Link>}
          {navLinks[1] && (
            <Fragment>
              <span className="mx-2">/</span>
              <Link to={navLinks[1].to}>{navLinks[1].title}</Link>
            </Fragment>
          )}
          {navLinks[2] && (
            <Fragment>
              <span className="mx-2">/</span>
              <Link to={navLinks[2].to}>{navLinks[2].title}</Link>
            </Fragment>
          )}
        </div>
      </div>
      {guideImage && (
        <div className="flex-1 justify-end items-center -mt-10 hidden md:flex">
          {worlds
            .filter((w) => {
              return !(answerKeys && w.name === 'Bug World');

            })
            .map((w) => {
              if (w.name === title) {
                return (
                  <img
                    key="image"
                    className="w-48 hidden xl:block lg:mx-4"
                    src={guideImage}
                    alt={title}
                  />
                );
              }

              return (
                <Link
                  to={`${path}/${w.link}`}
                  key={w.name}
                  className={`px-3 mx-1 lg:mx-4 py-2 lg:px-8 lg:py-2 rounded text-white ${w.color}`}>
                  {w.name}
                </Link>
              );
            })}
        </div>
      )}
    </nav>
  );
};

export default TopNav;
