import React, {useEffect, useMemo, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {IRouteParams} from "AppRoutes";
import {useOrganizationProfile} from "context/OrganizationProfileProvider";
import {ISubscription} from "types/ISubscription";
import {Box, CircularProgress, Tab, Tabs} from "@material-ui/core";
import {TabContext} from "@material-ui/lab";
import { OrganizationProfileDetailSubscriptionTab } from "./details/OrganizationProfileDetailSubscriptionTab";
import { OrganizationProfileDetailHistoryTab } from "./details/OrganizationProfileDetailHistoryTab";
import { OrganizationProfileDetailLicensesTab } from "./details/OrganizationProfileDetailLicensesTab";
import { OrganizationProfileDetailTeachersTab } from "./details/OrganizationProfileDetailTeachersTab";
import { OrganizationProfileDetailBillingTab } from "./details/OrganizationProfileDetailBillingTab";
import {OrganizationProfileDetailCleverTab} from "./details/OrganizationProfileDetailCleverTab";

export type OrganizationProfileDetailTabOptions = 'subscription' | 'history' | 'licenses' | 'teachers' | 'billing' | 'clever';

const OrganizationDetailTabs: React.FC = () => {
  const {params} = useRouteMatch<IRouteParams>();
  const {organization_profile} = useOrganizationProfile();
  const queryParam = useMemo(() => new URLSearchParams(window.location.search), []);
  const defaultTab = queryParam.get('tab') as OrganizationProfileDetailTabOptions || 'subscription';
  const [selectedTab, setSelectedTab] = useState<OrganizationProfileDetailTabOptions>(defaultTab);
  const history = useHistory();
  const [subscription, setSubscription] = useState<ISubscription>()

  const handleChange = (event: React.ChangeEvent<{}>, newValue: OrganizationProfileDetailTabOptions) => {
    console.log("TAB ", newValue);
    setSelectedTab(newValue);
  }

  useEffect(() => {
    if (organization_profile?.current_subscription) {
      const currentSub = organization_profile?.subscriptions?.find((sub) => sub?.id === organization_profile?.current_subscription?.id)
      setSubscription(currentSub)
      return
    }

    const sortedSubs = organization_profile?.subscriptions?.sort((a, b) => {
      return new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
    })
    if (!sortedSubs || sortedSubs?.length < 1) {
      return
    }
    // Set the latest subscription as the "current" one
    setSubscription(sortedSubs[0])

    return () => {
      setSubscription(undefined)
    }
  }, [organization_profile, params])

  /*
  useEffect(() => {
    setSelectedTab('subscription')
    history.push({
      search: `?tab=${selectedTab}`
    })
  }, [subscription])
*/

  useEffect(() => {
    if (queryParam.get('tab') === selectedTab) {
      return
    }
    history.push({
      search: `?tab=${selectedTab}`
    })
  }, [selectedTab, queryParam])

  if (!organization_profile) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" m={4}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <>
      <TabContext value={selectedTab}>
        <Tabs value={selectedTab} onChange={handleChange} style={{backgroundColor: 'transparent'}}>
          <Tab label="Current Subscription" value="subscription"/>
          <Tab label="History" value="history"/>
          {(subscription?.licenses && subscription?.licenses?.length > 1) &&
            <Tab label="Licenses" value="licenses"/>
          }
          {(organization_profile?.teachers && organization_profile?.teachers?.length > 0) &&
            <Tab label="Teachers" value="teachers"/>
          }
          <Tab label="Billing" value="billing"/>
          {organization_profile?.clever && <Tab label="Clever" value="clever"/>}
        </Tabs>
      </TabContext>
      <Box mt={4} mb={2}>
        {organization_profile?.id && selectedTab === 'subscription' &&
          <OrganizationProfileDetailSubscriptionTab organizationProfileId={organization_profile?.id}/>
        }
        {organization_profile?.administrator_id && selectedTab === 'history' &&
          <OrganizationProfileDetailHistoryTab/>
        }
        {selectedTab === 'licenses' &&
          <OrganizationProfileDetailLicensesTab/>
        }
        {selectedTab === 'teachers' &&
          <OrganizationProfileDetailTeachersTab/>
        }
        {selectedTab === 'billing' &&
          <OrganizationProfileDetailBillingTab/>
        }
        {selectedTab === 'clever' &&
            <OrganizationProfileDetailCleverTab/>
        }
      </Box>
    </>
  )
}

export default OrganizationDetailTabs;