import mutate from './mutate';
import endpoints from "../endpoints";

type config = {
  token: string
}

const useUpdateSubscriptionBilling = (userId: number) => {
  return (args: config) => {
    return mutate(endpoints.users.subscription.billing(userId), 'PUT', args);
  };
}

export default useUpdateSubscriptionBilling