import useSWR from 'swr';
import useCurrentUser from 'loaders/useCurrentUser';

import { IUserProfile } from "types/IUserProfile";
import {
  ISubscription,
  ISubscriptionSource,
  ISubscriptionTerm
} from 'types/ISubscription';
import endpoints from "../endpoints";

function getSubscriptionTerm(currentUser: IUserProfile, term?: string): ISubscriptionTerm {
  if (!currentUser) {
    return ISubscriptionTerm.None;
  }

  if (!term && currentUser.plan === 'School') {
    return ISubscriptionTerm.Lifetime;
  }

  if (term === 'lifetime' && currentUser.plan === 'School') {
    return ISubscriptionTerm.Lifetime;
  }

  if (term === 'monthly') {
    return ISubscriptionTerm.Monthly;
  }

  if (term === 'yearly') {
    return ISubscriptionTerm.Yearly;
  }

  if (term === 'Gift 6 Months') {
    return ISubscriptionTerm.Gift6;
  }

  if (term === 'Gift 12 Months') {
    return ISubscriptionTerm.Gift12;
  }

  if (term === 'Gift Lifetime') {
    return ISubscriptionTerm.GiftLifetime;
  }

  if (currentUser.plan === 'Free') {
    return ISubscriptionTerm.None;
  }

  return ISubscriptionTerm.None;
}

function getSubscriptionSource(source?: string): ISubscriptionSource {
  if(source === 'APPLE') {
    return ISubscriptionSource.Apple;
  }

  if (source === 'STRIPE') {
    return ISubscriptionSource.Stripe;
  }

  return ISubscriptionSource.None;
}

export default function useSubscription() {
  const { currentUser } = useCurrentUser();

  const {
    data,
    error,
    mutate: subscriptionMutate,
    isValidating: subscriptionValidation,
  } = useSWR(
    endpoints.subscription(currentUser.id),
    {
      dedupingInterval: 200000,
    }
  );

  const subscription: ISubscription = {
    ...data,
    term: getSubscriptionTerm(currentUser!, data?.term),
    source: getSubscriptionSource(data?.source)
  };

  const {
    data: invoiceData,
    error: invoiceError,
    mutate: invoiceMutate,
    isValidating: invoiceValidation,
  } = useSWR(endpoints.stripeUpcomingInvoice(currentUser.id),
    {
      dedupingInterval: 200000,
    }
  );

  const mutate = () => {
    subscriptionMutate();
    return invoiceMutate();
  };

  return {
    subscription,
    error: error || invoiceError,
    invoice: invoiceData,
    loading: subscriptionValidation || invoiceValidation,
    mutate,
  };
}
