import SectionContainer from "./SectionContainer";
import React from "react";

const GuidedPractice: React.FC<{title: string, activity: string, description: string, duration: string}> = ({title, duration, activity, description}) => {
  return (
    <SectionContainer
      title={title}
      duration={duration}
      icon={'/images/curriculum_images/Homepage_Results.png'}
      iconColor={'#ed2a7b'}
      innerHtml={description}
      subsectionTitle={'Activity'}
      subsectionInnerHtml={activity}
    />
  )
}

export default GuidedPractice