import PageContainer from "components/ui/PageContainer";
import React, { useState } from "react";
import AddStudentsDialog from "../../components/dialogs/class/AddStudentsDialog";
import StudentsTable from "./StudentsTable";

const StudentsIndexPage: React.VFC = () => {
  const [showAddStudentsDialog, setShowAddStudentsDialog] = useState(false);
  const [targetClassIdForAddStudentsDialog, setTargetClassIdForAddStudentsDialog] = useState<string>();

  return <PageContainer>
    <AddStudentsDialog
      targetClassId={targetClassIdForAddStudentsDialog}
      open={showAddStudentsDialog}
      onClose={() => {
        setShowAddStudentsDialog(false);
        setTargetClassIdForAddStudentsDialog(undefined);
      }}
    />
    {/* <PageHeader inAppBar title="My Students" /> */}
    <StudentsTable onAddStudents={(targetClassId?: number) => {
      setTargetClassIdForAddStudentsDialog(targetClassId?.toString());
      setShowAddStudentsDialog(true);
    }} />
  </PageContainer>
}

export default StudentsIndexPage;