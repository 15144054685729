import { faArrowCircleLeft, faCheckCircle, faCopy, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useAlert } from "context/AlertProvider";
import useSharedStyles from 'components/useSharedStyles'
import { justFetch } from 'mutations/mutate'
import endpoints from 'endpoints'
import useCurrentUser from 'loaders/useCurrentUser'
import { IUserProfile } from 'types/IUserProfile'

const useReferralFormStyles = makeStyles((theme) => ({
    referralFormWrapper: {
        maxWidth: '552px',
        margin: '0 auto',
        textAlign: 'center'
    },
    defaultTitle: {
        marginBottom: '1rem'
    },
    buttonIcon: {
        marginRight: '.5rem'
    },
    copyLinkWrapper: {
        display: 'flex',
        borderRadius: '3px',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: '#eeeeee',
        padding: '.75rem 1rem',
        '&:hover': {
            cursor: 'pointer',
            '& $copyLinkText': {
                textDecoration: 'underline',
            },
        },
        marginBottom: '1rem'
    },
    copyLinkText: {
        fontWeight: 500
    },
    invitingTextArea: {
        background: '#fff',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
}));

type ReferralFormDefaultProps = {
    setIsInviting: Dispatch<SetStateAction<boolean>>
    currentUser: IUserProfile
}

enum ReferralFormInviteMethod {
    EMAIL = 'email',
    LINK = 'link',
    CLICK = 'click'
}

interface ReferralFormTrackInviteProps {
    teacherId: number,
    inviteMethod: ReferralFormInviteMethod.EMAIL | ReferralFormInviteMethod.LINK | ReferralFormInviteMethod.CLICK,
    teacherEmails?: string[]
}

type ReferralFormInvitingProps = {
    submitTriggered?: boolean
    onClose?: () => void
    setSubmitTriggered?: Dispatch<SetStateAction<boolean>>
    setIsInviting: Dispatch<SetStateAction<boolean>>
    currentUser: IUserProfile
    isModal: boolean
}

const handleReferralFormTrackInvites = async (props: ReferralFormTrackInviteProps) : Promise<string | string[]> => {
    const { teacherId, inviteMethod, teacherEmails } = props
    const defaultError = inviteMethod !== ReferralFormInviteMethod.EMAIL ? '' : 'Could not send invites'
    return new Promise((resolve, reject) => {
        let requestBody : {invite_method: string, teacher_emails?: string[]} = {
            'invite_method': inviteMethod
        }
        if (inviteMethod === ReferralFormInviteMethod.EMAIL && (!teacherEmails || teacherEmails?.length === 0)) {
            resolve('Must include emails when inviting by email')
        }
        if (!!teacherEmails) {
            requestBody['teacher_emails'] = teacherEmails
        }
        justFetch(endpoints?.teacherReferral(teacherId), 'POST', requestBody)
        .then(async (response) => {
            if (response.ok) {
                const body = await response.json()
                if (body && body?.invalid_emails && body?.invalid_emails?.length > 0) {
                    resolve(body?.invalid_emails)
                    return
                }
                resolve('Success')
                return
            }
            resolve(defaultError)
        })
        .catch((error) => {
            resolve(defaultError)
        })
    })
}

export const ReferralFormCopyLink = (props: {currentUser: IUserProfile}) => {
    const { currentUser } = props
    const classes = useReferralFormStyles()
    const [isCopied, setIsCopied] = useState<boolean>(false)
    const alert = useAlert();
    const link = `${process.env.REACT_APP_KODABLE_WEBSITE}/teachers?referral_code=${currentUser?.referral_code}`;

    const handleClick = () => {
        navigator.clipboard.writeText(`${link}&type=teacher`)
        setIsCopied(true)
        alert.success('Link copied to clipboard!')
        setTimeout(() => {
            setIsCopied(false)
        }, 3000)
        // This will fail silently if the link copy tracking didn't work, which doesn't matter
        // for the purposes of tracking referrals
        handleReferralFormTrackInvites({
            teacherId: currentUser?.id,
            inviteMethod: ReferralFormInviteMethod.LINK
        })
        .then((result) => {
            if (typeof result == 'string' && result !== 'Success' && result.length > 0) {
                alert.error(result, 'Please contact support for assistance.')
            }
        })
    }

    return (
        <Box onClick={handleClick} display="flex" className={classes?.copyLinkWrapper}>
            <Typography color="primary" className={classes?.copyLinkText}>{link}</Typography>
            <Button color="primary" variant="outlined">
                <FontAwesomeIcon
                    className={classes?.buttonIcon}
                    icon={isCopied ? faCheckCircle : faCopy}
                />
                <span>{isCopied ? 'Copied' : 'Copy'}</span>
            </Button>
        </Box>
    )
}

const ReferralFormDefault = (props: ReferralFormDefaultProps) => {
    const { setIsInviting, currentUser } = props
    const classes = useReferralFormStyles()
    const sharedClasses = useSharedStyles()

    return (
        <Box className={sharedClasses?.vspacing2}>
            <Typography variant="h2" className={classes?.defaultTitle}>Share Kodable with another teacher</Typography>
            <ReferralFormCopyLink currentUser={currentUser} />
            <Typography>Share your link with another teacher or click <strong>Invite</strong> to send Kodable straight to their inbox.</Typography>
            <Button onClick={() => setIsInviting(true)} color="primary" variant="contained">
                <FontAwesomeIcon className={classes?.buttonIcon} icon={faPaperPlane} />
                <span>Invite</span>
            </Button>
        </Box>
    )
}

const ReferralFormInviting = (props: ReferralFormInvitingProps) => {
    const { submitTriggered = undefined, setSubmitTriggered = undefined, setIsInviting, currentUser, isModal, onClose = () => {} } = props
    const [teacherEmails, setTeacherEmails] = useState<string>("")
    const [submitting, setSubmitting] = useState<boolean>(false)
    const classes = useReferralFormStyles()
    const sharedClasses = useSharedStyles()
    const alert = useAlert();

    const handleSubmit = () => {
        handleReferralFormTrackInvites({
            teacherId: currentUser?.id,
            inviteMethod: ReferralFormInviteMethod.EMAIL,
            teacherEmails: teacherEmails.split('\n')
        })
        .then((result) => {
            if (typeof result !== 'string') {
                alert.error('Some emails were invalid', 'Please check your list and try again')
                return
            }
            if (result !== 'Success') {
                alert.error(result, 'Please contact support for assistance.')
                return
            }
            setTeacherEmails("")
            setSubmitting(false)
            onClose()
            alert.success('Email invites sent successfully!')
        })
    }

    useEffect(() => {
        if (!isModal || !submitTriggered || !setSubmitTriggered) {
            return
        }
        handleSubmit()
        setSubmitTriggered(false)
    }, [isModal, submitTriggered, setSubmitTriggered])
    
    return (
        <Box textAlign="left" className={sharedClasses?.vspacing2}>
            <Box>
                <Typography>Enter Teacher email addresses below to share Kodable.</Typography>
            </Box>
            <TextField
                className={classes?.invitingTextArea}
                multiline
                minRows={4}
                placeholder="Enter one teacher email per line"
                variant="outlined"
                value={teacherEmails}
                onChange={e => setTeacherEmails(e.target.value)}
                disabled={submitting}
            />
            {!isModal &&
                <Box display="flex" justifyContent="space-between">
                    <Button onClick={() => setIsInviting(false)} variant="outlined">
                        <FontAwesomeIcon className={classes?.buttonIcon} icon={faArrowCircleLeft} />
                        <span>Back</span>
                    </Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained">
                        <FontAwesomeIcon className={classes?.buttonIcon} icon={faPaperPlane} />
                        <span>Invite</span>
                    </Button>
                </Box>
            }
        </Box>
    )
}

const ReferralForm = (props: {
        isModal?: boolean,
        inviting: [boolean, Dispatch<SetStateAction<boolean>>]
        triggered?: [boolean, Dispatch<SetStateAction<boolean>>],
        onClose?: () => void
    }) => {
    const { isModal = false, inviting, triggered = undefined, onClose = () => {}} = props
    const { currentUser } = useCurrentUser();
    const [isInviting, setIsInviting] = inviting
    const [submitTriggered, setSubmitTriggered] = triggered || [undefined, undefined]
    const classes = useReferralFormStyles()
    
    useMemo(() => {
        if (isModal) {
            handleReferralFormTrackInvites({
                teacherId: currentUser?.id,
                inviteMethod: ReferralFormInviteMethod.CLICK,
            })
            .catch((err) => {})
        }
    }, [currentUser, isModal])

    return (
        <Box
            display="flex"
            flexDirection="column"
            className={classes?.referralFormWrapper}
        >
            <img
                src="/images/referral/referral-splash.png"
                alt="Share Kodable with another teacher"
            />
            {!isInviting &&
                <ReferralFormDefault
                    setIsInviting={setIsInviting}
                    currentUser={currentUser}
                />
            }
            {isInviting &&
                <ReferralFormInviting
                    onClose={onClose}
                    submitTriggered={submitTriggered}
                    setSubmitTriggered={setSubmitTriggered}
                    setIsInviting={setIsInviting}
                    isModal={isModal}
                    currentUser={currentUser}
                />
            }
        </Box>
    )
}

export default ReferralForm