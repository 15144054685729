import {Box, Dialog, DialogActions, DialogTitle, Typography, useTheme} from "@material-ui/core";
import Button from "../../ui/buttons/Button";
import React from "react";
import {useHistory} from "react-router-dom";
import {routes} from "../../../routes";

const PaywallManageLearningDialog: React.VFC<{open: boolean, onClose: () => void}> = ({open, onClose}) => {
  const theme = useTheme()
  const history = useHistory()

  const DogEar: React.VFC<{imageUrl: string, color: string}> = ({imageUrl, color}) => (
    <div style={{
      width: 150,
      height: 150,
      backgroundColor: color,
      position: "relative",
      float: 'right',
      top: -75,
      left: 550,
      transform: 'rotate(45deg)',
    }}>
      <div style={{
        transform: 'rotate(-45deg)'
      }}>
        <img src={imageUrl}
             style={{
               position: "relative",
               width: 35,
               height: 35,
               top: 65,
               left: -30
             }} />
      </div>
    </div>
  )

  const Benefit: React.VFC<{image: string, subtext: string}> = ({image, subtext}) => (
    <Box width={150}>
      <img src={image} style={{margin: "auto", width: 80, paddingBottom: 15}} />
      <Typography>{subtext}</Typography>
    </Box>
  )

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" PaperProps={{ style: { overflow: 'hidden', overflowY: 'hidden'}}}>
      <DogEar imageUrl={'/images/badges/premium-badge-100.png'} color={theme.palette.blue.main} />
      <DialogTitle style={{
        position: "relative",
        top: -120,
      }}>
        <Typography style={{
          fontFamily: 'Bjola',
          fontWeight: 400,
          fontSize: 28,
          lineHeight: '1rem'
        }}>
          Manage Learning with Premium
        </Typography>
      </DialogTitle>
      <Box display={'flex'} flexDirection={'column'} textAlign={'center'} m={5} style={{height: 120, position: 'relative', top: -150}}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} marginTop={5}>
          <Benefit image={'/images/paywall/meeting_room.png'} subtext={'Co-teachers'} />
          <Benefit image={'/images/paywall/internet.png'} subtext={'Usage reports'} />
          <Benefit image={'/images/paywall/training.png'} subtext={'Customize lessons'} />
        </Box>
        <Box display={'flex'} justifyContent={'center'} alignContent={'center'} style={{marginTop: 60}}>
          <Button variant={"contained"}
                  color={'blue'}
                  onClick={() => {
                    history.push(routes.sales.quotes.create.selectLocation)
                    onClose();
                  }}
                  style={{width: 200, height: 70, borderRadius: 10, fontSize: 22, fontWeight: 500}}
          >Get Quote</Button>
        </Box>
      </Box>
      <DialogActions>
        <Button variant={"outlined"} onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PaywallManageLearningDialog