import { Box, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";

export const FuzzBuzz: React.VFC<{ say?: React.ReactNode, fuzzBuzzDirection?: 'left' | 'right' }> = ({ say, fuzzBuzzDirection = 'right' }) => {
  const classes = useFuzzBuzzStyles();

  return <Box width={225}>
    <FuzzBuzzChatBubble text={say} />
    <div style={{ transform: fuzzBuzzDirection === 'left' ? 'rotateY(180deg)' : '' }}>
      <img src={`${process.env.REACT_APP_DASHBOARD_ENDPOINT}/images/fuzzbuzz/fuzzbuzz_1.png`} className={classes.fuzzBuzz} />
    </div>
  </Box>;
}

const borderWidth = 1;
const bubbleRadius = 15;
const bubbleBg = 'white';
const bubbleBorderColor = 'rgba(230, 230, 230, 1)';
const bubblePadding = 20;

const arrowLength = 30;
const arrowWidth = 30;

export const useChatBubbleStyles = makeStyles({
  bubble: {
    background: bubbleBg,
    position: 'relative',
    textAlign: 'center',
    margin: '40px auto',
    border: `${borderWidth}px solid ${bubbleBorderColor}`,
    borderRadius: bubbleRadius,
    padding: bubblePadding,
    filter: 'drop-shadow(6px 6px 2px rgba(0,0,0,.2))',
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before, &:after': {
      content: "' '",
      position: 'absolute',
      width: 0,
      height: 0,
    },
    '&:before': {
      right: 45,
      bottom: -arrowLength,
      border: `${arrowLength / 2}px solid`,
      borderColor: `${bubbleBorderColor} transparent transparent ${bubbleBorderColor}`,
      transform: `scaleX(${arrowLength / arrowWidth})`
    },
    '&:after': {
      right: 45 + borderWidth,
      bottom: -arrowLength + borderWidth * 2 + 1,
      border: `${arrowLength / 2 - borderWidth}px solid`,
      borderColor: `${bubbleBg} transparent transparent ${bubbleBg}`
    }
  }
});

const useFuzzBuzzStyles = makeStyles(theme => ({
  '@keyframes floaty': {
    '0%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-5px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  fuzzBuzz: {
    width: 150,
    margin: '0 auto',
    animation: '$floaty',
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out'
  }
}));

export const FuzzBuzzChatBubble: React.VFC<{ text?: React.ReactNode }> = ({ text }) => {
  const chatBubbleClasses = useChatBubbleStyles();

  return <motion.div
    initial={{ opacity: 0 }}
    variants={{
      noText: {
        opacity: 0
      },
      hasText: {
        opacity: 1
      }
    }}
    animate={!!text ? 'hasText' : 'noText'}
    transition={{ duration: !!text ? 0.25 : 0.1 }}
    className={chatBubbleClasses.bubble}
  >
    <Box>
      {text}
    </Box>
  </motion.div>
}