import { faDownload, faLink, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Dialog, DialogActions, DialogContent, PropTypes, Typography } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { KlassSetupModalState } from "types/KlassSetupBannerModalState";

export interface KlassSetupModalProps {
    open: boolean,
    setModalDisplayState: Dispatch<SetStateAction<KlassSetupModalState>>,
    onClose?: (...args: any[]) => any,
}

export interface KlassSetupModalButtonProps {
    icon: IconDefinition,
    title: string,
    subtitle: string,
    variant?: 'text' | 'outlined' | 'contained' | undefined,
    color?: PropTypes.Color,
    onClick?: (...args: any[]) => any
}

const KlassSetupModalButton: React.FC<KlassSetupModalButtonProps> = ({
    icon,
    title,
    subtitle,
    variant,
    color,
    onClick = () => {}
}) => {
    return (
        <Button
            variant={variant}
            color={color}
            onClick={onClick}
            style={{marginBottom: '1.5rem'}}
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <FontAwesomeIcon
                        icon={icon}
                        style={{width: 'auto', height: '20px', marginRight: '.5rem'}}
                    />
                    <Typography variant="subtitle1">{title}</Typography>
                </Box>
                <Typography style={{textTransform: 'none'}}>{subtitle}</Typography>
            </Box>
        </Button>
    )
}

export const KlassSetupModalContent: React.FC<{setState: Dispatch<SetStateAction<KlassSetupModalState>>}> = ({setState}) => {
    return (
        <>
        <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img
                src="/images/onboarding/home.png"
                style={{width: '220px', marginBottom: '1rem'}}
                width={220}
                height={286}
            />
            <KlassSetupModalButton
                icon={faDownload}
                title="Print Invites"
                subtitle="Download and print handouts"
                variant="contained"
                color="primary"
                onClick={() => setState('print')}
            />
            <KlassSetupModalButton
                icon={faLink}
                title="Invite digitally"
                subtitle="Email or copy class links"
                variant="outlined"
                color="primary"
                onClick={() => setState('share')}
            />
            <a target="_blank" href="http://support.kodable.com/en/articles/7730812-what-happens-after-i-invite-families" rel="noreferrer">
                <Typography
                    variant="body1"
                    align="center"
                    style={{
                        fontFamily: 'Bjola',
                        textDecoration: 'underline'
                    }}
                >
                    What happens after I send an invite?
                </Typography>
            </a>
        </Box>
        </>
    )
}

const KlassSetupModal: React.FC<KlassSetupModalProps> = ({
        open,
        setModalDisplayState,
        onClose = () => {}
    }) => {
    
    const ref = useRef<HTMLDivElement>(null)

    // Allows clicking outside to close the Dialog
    useEffect(() => {
        function handleClickOutside({target}: MouseEvent) : void {
            if (ref.current && !ref.current.contains(target as Node)) {
                onClose()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [ref])

    return (
        <Dialog maxWidth='sm' open={open}>
            <div ref={ref}>
                <Typography
                    variant="h1"
                    style={{fontFamily: 'Bjola', margin: '3rem auto 1rem', textAlign: 'center'}}
                >
                    Invite Families
                </Typography>
                <DialogContent>
                    <Typography
                        align="center"
                        style={{marginBottom: '1rem'}}
                    >Send invites to families to unlock free levels and progress updates at Home!</Typography>
                    <KlassSetupModalContent setState={setModalDisplayState} />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>Close</Button>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default KlassSetupModal
