import useSWR from 'swr';
import endpoints from "../endpoints";

export default function useStudentMazeSettings(studentCode: string) {
  const {
    data,
    error,
  } = useSWR(
    endpoints.mazesByStudentCode(studentCode),
    { dedupingInterval: 200000 }
  );

  return { loading: !data, mazeSettings: data?.error ? [] : data, error };
}
