import React, {useState} from 'react';
import {
    Box,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Divider, FormControl,
    FormControlLabel, FormHelperText,
    Switch
} from "@material-ui/core";
import TextField from "components/ui/TextField";
import {Form, FormikProvider, useFormik} from "formik";
import useSharedStyles from "components/useSharedStyles";
import * as Yup from "yup";
import Select from "components/ui/Select";
import MenuItem from "components/ui/MenuItem";
import {DialogStateProps} from "hooks/useDialogState";
import Button from "components/ui/buttons/Button";
import endpoints from "endpoints";
import {justFetch} from "mutations/mutate";
import {useAlert} from "context/AlertProvider";
import {Alert} from "@material-ui/lab";


const TeacherOccupations = [
    "Classroom Teacher",
    "Technology Teacher or Facilitator",
    "Principal",
    "Director of Curriculum",
    "Director of Technology",
    "Other"
]

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the Teacher\'s name'),
    occupation: Yup.string().oneOf(TeacherOccupations).required('Please select their occupation'),
    password: Yup.string().notRequired(),
    email: Yup.string().email().required('Please enter a email address'),
});

type AddTeacherPayload = {
    email_instructions: boolean,
    name: string,
    occupation: string,
    reset_required: boolean,
    username: string,
    password?: string
}

const AddTeacherDialog: React.FC<DialogStateProps & { refreshData: Function }> = ({open, onClose, refreshData}) => {
    const sharedClasses = useSharedStyles();
    const [error, setError] = useState<string>();
    const alert = useAlert();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            email_instructions: true,
            name: '',
            occupation: '',
            reset_required: true,
            email: '',
            password: ''
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            let reqData: AddTeacherPayload = {
                email_instructions: values.email_instructions,
                name: values.name,
                occupation: values.occupation,
                reset_required: values.reset_required,
                username: values.email
            }
            if (values.password !== '') {
                reqData.password = values.password;
            }

            const res = await justFetch(endpoints.godmode.addTeacher, 'POST', reqData);

            if (res.ok) {
                onClose();
                refreshData();
                actions.resetForm();
                alert.success('Teacher successfully created!');
            } else {
                const parsed = await res.json();
                setError(parsed.message || parsed.error || 'An error occurred.');
            }
        }
    });
    return (
        <Dialog open={open}>
            <DialogTitle>Create Teacher</DialogTitle>
            <Divider />
            <DialogContent>
                <FormikProvider value={form}>
                    <Form onSubmit={form.handleSubmit}>
                        <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2}>
                            {!!error && <Alert severity="error">{error}</Alert>}
                            <TextField
                                error={Boolean(form.touched.name && form.errors.name)}
                                value={form.values.name}
                                helperText={form.errors.name}
                                label="Name"
                                name="name"
                                id="name"
                                onChange={form.handleChange}
                            />
                            <TextField
                                error={Boolean(form.touched.email && form.errors.email)}
                                value={form.values.email}
                                helperText={form.errors.email}
                                label="Email"
                                name="email"
                                id="email"
                                onChange={form.handleChange}
                            />
                            <TextField
                                error={Boolean(form.touched.password && form.errors.password)}
                                value={form.values.password}
                                type="password"
                                label="Password"
                                name="password"
                                id="password"
                                onChange={form.handleChange}
                                helperText="Leave this blank to auto-generate a password"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={form.values.reset_required}
                                        onChange={form.handleChange}
                                        value={form.values.reset_required}
                                        name="reset_required"
                                        color="primary"
                                        id="reset_required"
                                    />
                                }
                                label="Require Password Reset? (recommended)"
                            />
                            <FormControl>
                                <Select
                                    error={Boolean(form.touched.occupation && form.errors.occupation)}
                                    id="occupation"
                                    name="occupation"
                                    value={form.values.occupation}
                                    onChange={form.handleChange}
                                    label="Occupation"
                                >
                                    <MenuItem value="" key="">
                                        <em>None</em>
                                    </MenuItem>
                                    {TeacherOccupations.map((val) => (
                                        <MenuItem value={val} key={val}>{val}</MenuItem>
                                    ))}
                                </Select>
                                {form.errors.occupation ? (
                                    <FormHelperText error={true} margin={'dense'}>{form.errors.occupation}</FormHelperText>
                                ) : null}
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={form.values.email_instructions}
                                            onChange={form.handleChange}
                                            value={form.values.email_instructions}
                                            name="email_instructions"
                                            color="primary"
                                        />
                                    }
                                    label={'Email Sign-In Instructions?'} />
                                <FormHelperText>Teacher will be emailed their password and instructions on logging in</FormHelperText>
                            </FormControl>
                        </Box>
                    </Form>
                </FormikProvider>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => {
                        form.resetForm();
                        onClose();
                    }}
                    disabled={form.isSubmitting}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="green"
                    onClick={() => form.handleSubmit()}
                    disabled={form.isSubmitting}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddTeacherDialog;