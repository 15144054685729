import { Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import { DialogStateProps } from "hooks/useDialogState";
import React from "react";

export const ConfirmationDialog: React.FC<DialogStateProps & { submitting?: boolean, title: string, subtitle?: string, verb?: string, onConfirm: () => void, danger?: boolean, error?: string }> = ({ open, onClose, onConfirm, submitting, title, subtitle, verb, danger, error }) => {
  return <Dialog open={open}>
    <LinearProgress style={{ visibility: submitting ? 'visible' : 'hidden' }} />
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {subtitle && <Box mb={2} textAlign="center">
        {subtitle}
      </Box>}
      {!!error && <Alert severity="error">{error}</Alert>}
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        onClick={onClose}
        disabled={submitting}
      >Nevermind</Button>
      <Button
        variant="contained"
        onClick={onConfirm}
        disabled={submitting}
        color={danger ? 'red' : 'primary'}
      >{verb || 'Confirm'}</Button>
    </DialogActions>
  </Dialog>;
}