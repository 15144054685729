import { routes } from "routes";
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import LoadingPage from "pages/LoadingPage";
import LoginInput from "pages/login/LoginInput";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { ITeacherData } from "types/ITeacherData";
import queryString from 'query-string';
import { isPublicRoute } from "routes";
import Cookies from "universal-cookie";

interface INavContext {
  navPortal: React.MutableRefObject<HTMLElement | null>;
  drawerState: [boolean, React.Dispatch<boolean>];
}

export const NavContext = React.createContext<INavContext>(null!);

const NavProvider: React.FC = ({ children }) => {
  const { currentUser, loading: currentUserLoading } = useCurrentUser();
  const { data: parentInitData, error: parentInitError } = useSWR(currentUser?.is_parent ? endpoints.parent_init : null);
  const { data: teacherData, error: teacherInitError } = useSWR<ITeacherData>(currentUser?.is_parent === false ? endpoints.teacherInit : null);
  const parentInitLoading = currentUser?.is_parent && !parentInitData && !parentInitError;
  const teacherInitLoading = currentUser?.is_parent === false && !teacherData && !teacherInitError;
  const hasUnassignedLicenses = teacherData?.subscription?.licenses?.some(license=>!license.teacher_id);
  const hasMultipleLicenses = (teacherData?.subscription?.licenses?.length || 0) > 1;
  const navPortal = useRef<HTMLElement>(null);
  const drawerState = useState(false);
  const navContextValue = useMemo(() => {
    return {
      drawerState,
      navPortal,
    }
  }, [drawerState, navPortal]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (['curriculum', 'videos', 'kids', 'godmode', 'reset?id'].some(path => window.location.hash.includes(path))) {
      window.location.href = 'v1/' + window.location.hash;
    }
  }, [window.location.hash]);

  useEffect(() => {
    if (!currentUser || !teacherData) {
      return;
    }

    if (currentUser?.temp_password && !location.pathname.includes(routes.password.reset)) {
      history.push(routes.password.reset);
      return;
    }
    if (currentUser?.temp_password) {
      return;
    }

    if (currentUser?.is_parent) {
      if (!parentInitData) {
        return;
      }

      const needsOnboarding = currentUser?.is_registered_via_website && !currentUser?.is_dashboard_onboarded;

      if (
        needsOnboarding &&
        !location.pathname.includes(routes.onboarding.index) &&
        !location.pathname.includes(routes.upgrade.index)
      ) {
        if (parentInitData?.students?.length === 0) {
          history.push(routes.onboarding.index);
          return;
        } else {
          const to = routes.onboarding.intro;

          history.push(to);
          return;
        }
      }
    } else {
      if (!teacherData) {
        return;
      }

      if (currentUser?.user_role == 'super_admin' && (!currentUser?.is_dashboard_onboarded || hasUnassignedLicenses) && hasMultipleLicenses) {
        const adminIgnoreLicensesAssignment = new Cookies().get('admin_ignore_licenses_assignment');
        if (!adminIgnoreLicensesAssignment)
        {
          if (!location.pathname.includes(routes.teacherOnboarding.assignLicenses))
          {
            history.push(routes.teacherOnboarding.assignLicenses);
          }
          return;
        }
      }

      if (currentUser?.is_dashboard_onboarded) {
        return;
      }

      if (location.pathname.includes('add-school') || location.pathname.includes('add-class') || location.pathname.includes('import-class')) {
        return;
      }

      const hasOrg = currentUser?.user_role == 'super_admin' || currentUser?.user_role == 'admin' || currentUser?.user_role == 'member';

      if ( !currentUser?.school_id && !currentUser?.is_non_usa && !hasOrg && !location.pathname.includes('add-school')) {
        history.push(routes.teacherOnboarding.addSchool.index);
        return;
      }
    }
  }, [location, parentInitData, currentUser, teacherData]);

  if (currentUserLoading || parentInitLoading || teacherInitLoading) {
    return <LoadingPage />;
  }

  if (!currentUser && !isPublicRoute(location)) {
    const next = queryString.parse(window.location.search)?.next;

    return <LoginInput inApp mode="teacher" handleRedirectAfterLogin={() => {
      const nextLocation = next ? next as string : routes.classes.index;
      if (next?.includes('v1')) {
        window.location.href = nextLocation
      } else {
        history.push(next ? decodeURIComponent(next as string) : routes.classes.index)
      }
    }} />;
  }

  return <NavContext.Provider value={navContextValue}>{children}</NavContext.Provider>;
}

export default NavProvider;