import OnboardingLayout from './shared/OnboardingLayout';
import { Link } from 'react-router-dom';
import useParentInit from 'loaders/useParentInit';
import Amplitude from 'utils/Amplitude';
import { useEffect } from 'react';
import { routes } from 'routes';

const PlayToLearnPage = () => {
  const { students, loading } = useParentInit();

  useEffect(() => {
    Amplitude.track('(Parent Onboarding) Viewed Play To Learn Page');
  }, []);

  if (loading) {
    return null;
  }

  return (
    <OnboardingLayout
      imgName="balloon"
      imgSrc="/images/onboarding/balloon.jpg"
      title="Let’s Play to Learn"
      activeSteps={3}
    >
      <div className={`flex justify-center align-center block lg:hidden`} style={{padding: '1rem 0'}}>
        <img style={{alignSelf: 'center', maxWidth: 250}} width="250" src={"/images/onboarding/balloon.jpg"} alt={'balloon'} />
      </div>
      <p className="mt-4 text-center lg:text-left" style={{ flexGrow: 1 }}>
        Play based learning allows{' '}
        {students && students[0] ? `${students[0].name}'s` : "Child's"} creativity and
        problem solving skills to grow.
      </p>
      <div className="flex items-center justify-center lg:justify-end mt-12 lg:mt-48">
        <Link
          className="bg-kodable-orange hover:bg-kodable-orange-dark active:bg-kodable-orange-darker text-white items-center font-semibold text-lg px-4 flex py-2 rounded shadow-kodable text-center w-32"
          to={routes.parentOnboarding.learning}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="w-6 h-6 mr-4">
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
          </svg>
          Next
        </Link>
      </div>
    </OnboardingLayout>
  );
}

export default PlayToLearnPage