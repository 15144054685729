import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

// Routes
import SmeeborgGuidePage from './SmeeborgGuidePage';
import BugWorldGuidePage from './BugWorldGuidePage';
import AsteroidiaGuidePage from './AsteroidiaGuidePage';

const GuidesIndexPage: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={SmeeborgGuidePage} path={`${path}/smeeborg`} />
      <Route component={AsteroidiaGuidePage} path={`${path}/asteroidia`} />
      <Route component={BugWorldGuidePage} path={`${path}/bugworld`} />
      <Redirect to={`${path}/smeeborg`} />
    </Switch>
  );
};

export default GuidesIndexPage;
