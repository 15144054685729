import endpoints from "../../endpoints";
import useQuery from "../../hooks/useQuery";
import {useLayoutEffect} from "react";
import {setSessionTokenCookie} from "../login/loginUtils";
import {mutate} from "swr";
import {justFetch} from "../../mutations/mutate";
import {useHistory} from "react-router-dom";
import {routes} from "../../routes";

const ResetTokenPage = () => {
  let query = useQuery();
  const history = useHistory();

  useLayoutEffect(() => {
    const auth = async () => {
      const complete = await justFetch(endpoints.resetPassword.complete, 'POST', {id: query.get("id")})
      const session = await complete.json()
      if (session) {
        setSessionTokenCookie(session.session_token, false, session?.access_token);
        try {
          await mutate(endpoints.session, session);
          history.push(routes.password.reset)
        } catch (e) {}
      }
    }
    auth();
  }, [])

  return (<></>)
}

export default ResetTokenPage