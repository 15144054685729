import {ISubscription} from "types/ISubscription";
import React, {useEffect, useState} from "react";
import {Box, Divider, Typography, useTheme} from "@material-ui/core";
import {formatCurrency} from "pages/godmode/utils/numbers";
import useSWR from "swr";
import endpoints from "endpoints";
import { IPurchaseProcess } from "pages/sales/IQuote";
import { ITeacher } from "types/ITeacher";
import { groupBy, sumBy, map as lodashMap, keys as lodashKeys } from 'lodash';
import UpcomingRenewalsTableMonthTable from "./UpcomingRenewalsMonthTable";
import { IOrganizationProfile } from "types/IOrganizationProfile";
import useSharedStyles from "components/useSharedStyles";

export interface ISubscriptionChurned {
  expired: ISubscription;
  last_purchase_process: IPurchaseProcess;
  teacher: ITeacher;
  organization_profile: IOrganizationProfile;
}

export interface ISubscriptionRenewal extends ISubscriptionChurned {
  renewal: ISubscription;
}

interface ISixMonthChurnDetails {
  churnedPercent: number;
  expiringSubs: number;
  churnedSubs: number;
  churnAmount: number;
  expiringAmount: number;
  totalSubs: number;
  totalDollars: number;
}

export interface IProcessedDetailsMonth {
  id: string;
  prettyDate: string;
  churnedPercent: number;
  churnedDollarsPercent: number;
  churnedDollarsPercentWithRenewalIncrease: number;
  churnedDollarsWithRenewalIncrease: number;
  churned: ISubscriptionChurned[];
  renewals: ISubscriptionRenewal[];
  churnCount: number;
  renewalCount: number;
  expiringSubs: number;
  churnAmount: number;
  expiringAmount: number;
  renewalIncreaseAmount: number;
  unpaidAmount: number;
}

interface IProcessedDetails {
  months: IProcessedDetailsMonth[];
  sixMonthTotals: ISixMonthChurnDetails;
}

const UpcomingRenewalsTable: React.FC = () => {
  const { data: responseData, mutate: mutateRenewals } = useSWR(endpoints?.godmode?.churnedSubDetails);
  const [processedDetails, setProcessedDetails] = useState<IProcessedDetails>();
  const theme = useTheme()
  const sharedClasses = useSharedStyles()

  useEffect(() => {
    if (!responseData) {
      return;
    }

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const detailedChurnData = groupBy(responseData.churned, sub => sub.expired.end_date.substring(0, 7));
    const detailedRenewData = groupBy(responseData.renewed, renewData => renewData.expired.end_date.substring(0, 7));

    const months = lodashMap(lodashKeys(detailedChurnData).sort(), dateStr => {
      const date = new Date(dateStr + '-02'); // Using '-02' to ensure a valid date

      const churned = detailedChurnData[dateStr].sort((a, b) => b.expired.contract_amount - a.expired.contract_amount);
      const churnCount = churned.length;
      const churnAmount = sumBy(churned, sub => sub.expired.contract_amount);

      const renewals = detailedRenewData[dateStr]?.sort((a, b) => b.renewal.contract_amount - a.renewal.contract_amount) || [];
      const renewalCount = renewals.length;
      const renewalAmount = sumBy(renewals, sub => sub.expired.contract_amount);
      const renewalIncreaseAmount = sumBy(renewals, sub => sub.renewal.contract_amount - sub.expired.contract_amount);
      const unpaidAmount = sumBy(renewals, sub => sub.teacher.plan !== 'Free' ? 0 : sub.renewal.contract_amount);

      return {
        id: dateStr,
        prettyDate: monthNames[date.getMonth()] + ' ' + date.getFullYear(),
        churnedPercent: Math.round((churnCount / (churnCount + renewalCount)) * 100),
        churnedDollarsPercent: Math.round((churnAmount / (churnAmount + renewalAmount)) * 100),
        churnedDollarsPercentWithRenewalIncrease: Math.round(((churnAmount - renewalIncreaseAmount) / (churnAmount + renewalAmount)) * 100),
        churnedDollarsWithRenewalIncrease: churnAmount - renewalIncreaseAmount,
        churned,
        renewals,
        churnCount,
        renewalCount,
        expiringSubs: churnCount + renewalCount,
        churnAmount,
        expiringAmount: churnAmount + renewalAmount,
        renewalIncreaseAmount,
        unpaidAmount,
      };
    });

    const sixMonthTotals = {
      churnedPercent: Math.round((sumBy(months, 'churnCount') / (sumBy(months, 'churnCount') + sumBy(months, 'renewalCount'))) * 100),
      expiringSubs: sumBy(months, 'churnCount') + sumBy(months, 'renewalCount'),
      churnedSubs: sumBy(months, 'churnCount'),
      churnAmount: sumBy(months, 'churnAmount'),
      expiringAmount: sumBy(months, 'expiringAmount'),
      totalSubs: sumBy(months, 'churnCount') + sumBy(months, 'renewalCount'),
      totalDollars: sumBy(months, 'expiringAmount')
    };

    // Assuming you want both the detailed monthly data and the aggregate six-month totals in your state
    setProcessedDetails({ months, sixMonthTotals });
  }, [responseData]);

  if (!processedDetails) {
    return null
  }

  return (
    <Box className={sharedClasses?.vspacing4} p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Six Month Churn Details</Typography>
        <Box>
          <Typography
            variant="h4"
            style={{color: theme.palette.green.main}}
          >
            <strong>{processedDetails?.sixMonthTotals?.churnedPercent}%</strong>
          </Typography>
          <Typography>Subscriptions Churned</Typography>
        </Box>
        <Box>
          <Typography
            variant="h4"
            style={{color: theme.palette.green.main}}
          >
            <strong>{processedDetails?.sixMonthTotals?.churnedSubs} / {processedDetails?.sixMonthTotals?.totalSubs}</strong>
          </Typography>
          <Typography>Subscriptions Churned</Typography>
        </Box>
        <Box>
          <Typography
            variant="h4"
            style={{color: theme.palette.green.main}}
          >
            <strong>{formatCurrency(processedDetails?.sixMonthTotals?.churnAmount)} / {formatCurrency(processedDetails?.sixMonthTotals?.totalDollars)}</strong>
          </Typography>
          <Typography>Dollars Churned</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className={sharedClasses?.vspacing4}>
        {processedDetails?.months?.map((month, index) => {
          return <UpcomingRenewalsTableMonthTable month={month} mutateRenewals={mutateRenewals} key={index} />
        })}
      </Box>
    </Box>
  )
}

export default UpcomingRenewalsTable;