import {Document, Font, Page, StyleSheet, Text, View, Svg, Line, Link, Image} from "@react-pdf/renderer";
import React from "react";
import {IAnonymousQuoteResponse} from "../IQuote";
import {IOrderRequest} from "./IOrderRequest";
import {submitOrderSteps} from "./submit-order/SubmitOrderIndex";

const ConfirmOrderPDF: React.VFC<{order: IOrderRequest, quote?: IAnonymousQuoteResponse, signature: string}> = ({order, quote, signature}) => {
  
  const terms = "Upon signature by Customer and submission to Kodable, " +
    "this Order Form is legally binding unless this Order Form is rejected by Kodable " +
    "for any of the following: (1) the signatory below does not have the authority to bind " +
    "Customer to this Order Form, (2) the requested  order information or signature is " +
    "incomplete or does not match our records or the rest of this Order Form. Orders are " +
    "non-refundable. This Order Form is governed by the Kodable Terms of Service found at"
  const sectionHeader = (index: number, override?: string) => {
    return (
      <View>
        <Text style={styles.sectionHeader}>{override ?? submitOrderSteps[index].label}</Text>
        <Svg height="10" width="612">
          <Line x1="0" y1="5" x2="552" y2="5" strokeWidth="2" stroke="#f0f0f0" />
        </Svg>
      </View>
    )
  }
  
  const dataRow = (label: string, value: string) => {
    return (
      <View style={styles.infoRow}>
        <Text style={styles.infoLabel}>{label}</Text>
        <Text style={styles.infoBox}>{value}</Text>
      </View>
    )
  }
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.address}>Surfscore, Inc</Text>
          <Text style={styles.address}>434 W. Cedar St. #200, San Diego, CA 92101</Text>
          <Text style={styles.address}>Phone: 415-993-1020</Text>
        </View>
        <View fixed={true} style={styles.footer}>
          <Text style={styles.footerText}>SurfScore, Inc. {new Date().getFullYear()}</Text>
        </View>
        <View style={styles.spacer}>{/* spacer */}</View>
        <View style={styles.section}>
          {sectionHeader(1)}
          {dataRow("Organization Name:", order.organization_name)}
          {dataRow("Type:", `Premium${order.has_curriculum ? '+' : ''}`)}
          {dataRow("Term:", `${order.term_months} months`)}
          {dataRow("Number of Schools:", order.num_sites.toString())}
          {quote && <>
            {dataRow("Price Each:", `$${(quote.list_price_per_site).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`)}
            {dataRow("Subtotal:", `$${(quote.list_price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`)}
            {quote.discount && dataRow("Volume Discount:", `$${(quote.discount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`)}
            {order.custom_discount && dataRow("Additional Discount:", `$${(order.custom_discount || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`)}
            {dataRow("Total Price:", `$${(quote.net_price - (order.custom_discount || 0)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`)}
          </>}
        </View>
        <View style={styles.section}>
          {sectionHeader(2)}
          {dataRow("Contact Name:", `${order.billing_contact_first_name} ${order.billing_contact_last_name}`)}
          {dataRow("Contact Email:", order.billing_contact_email)}
          {order.billing_contact_phone?.length > 0 &&
            dataRow("Contact Phone:", order.billing_contact_phone)}
          {dataRow("Address Line 1:", order.billing_contact_address_street)}
          {order.billing_contact_address_street2 && dataRow("Address Line 2:", order.billing_contact_address_street2)}
          {dataRow("City:", order.billing_contact_address_city)}
          {order.billing_contact_address_province?.length > 0 && 
            dataRow("State/Province:", order.billing_contact_address_province)}
          {dataRow("Postal Code:", order.billing_contact_address_code)}
          {dataRow("Country:", order.billing_contact_address_country)}
        </View>
        <View style={styles.section}>
          {sectionHeader(3)}
          {dataRow("Uploaded purchase order?", order.purchase_order_file_id ? 'Yes' : 'No')}
          {dataRow("Purchase Order Number:", order.purchase_order_number?.toString() || 'None')}
        </View>
        <View style={styles.section}>
          {sectionHeader(4)}
          {dataRow("Name:", order.license_holder_name)}
          {dataRow("Email:", order.license_holder_email)}
        </View>
        <View style={styles.spacer}>{/* spacer */}</View>
        <View style={styles.section}>
          {sectionHeader(-1, "Terms")}
          <Text>{terms} <Link src="https://www.kodable.com/privacy">https://www.kodable.com/privacy</Link></Text>
          <View style={styles.spacer}>{/* spacer */}</View>
          <View style={styles.signatureBlock}>
            <Text>Signature:</Text>
            <Image style={styles.signature} src={signature}/>
            <Text>Date: {new Date().toLocaleDateString()}</Text>
          </View>
        </View>
        
      </Page>
    </Document>
  )
}

Font.register({
  family: 'OpenSans',
  src: '/fonts/OpenSans-Regular.ttf'
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: 'OpenSans',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    fontSize: 10,
  },
  section: {
    width: '100%',
    marginTop: 8,
  },
  spacer: {
    height: 8
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    bottom: 10,
    left: 50,
  },
  footerText:{
    fontSize: 8,
    textAlign: 'center',
    width: '100%',
  },
  address: {
    textAlign: 'left',
  },
  sectionHeader: {
    fontSize: 16,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  infoRow:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '10',
    alignContent: 'center',
  },
  infoLabel:{
    minWidth: '25%',
    maxWidth: '25%',
  },
  infoBox:{
    flexGrow: 1,
    textAlign: 'left',
  },
  signatureBlock:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '5',
  },
  signature:{
    height: 50,
    width: 'auto',
    flex: '0 0 auto',
  }
});


export default ConfirmOrderPDF;