import mutate from './mutate';
import endpoints from "../endpoints";

type config = {
  reason: string
}

const useCancelSubscription = (userId: number) => {
  return (args: config) => {
    if (args.reason) {
      mutate(endpoints.users.subscription.cancelReason(userId), 'POST', {
        reason_id: args.reason,
      });
    }

    return mutate(endpoints.users.subscription.cancel(userId), 'POST', {});
  };
}

export default useCancelSubscription