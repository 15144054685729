import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CardContent, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import PageContainer from "components/ui/PageContainer";
import useSharedStyles from "components/useSharedStyles";
import { AnimatePresence, motion } from "framer-motion";
import useDialogState from "hooks/useDialogState";
import { MultiClassPrintoutDialog } from "pages/printouts/MultiClassPrintoutDialog";
import React, { ReactNode } from "react";
import YouTube from "react-youtube";
import { routes } from "routes";
import { IKlass } from "types/IKlass";

const useYtStyles = makeStyles({
  responsive: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
    borderRadius: 20,

    '& iframe': {
      width: '100%',
      height: '100%',
      position: 'absolute'
    }
  }
})

export const HocLandingPage: React.FC = () => {
  const sharedClasses = useSharedStyles();
  const ytClasses = useYtStyles();
  const printoutDialogState = useDialogState()
  const studentLoginDialogState = useDialogState()

  return <PageContainer>
    <MultiClassPrintoutDialog
      {...printoutDialogState}
      title="Hour of Code Certificates"
      helperText="We'll generate certificates for every class you select"
      handoutUrl={(klassId: number) => routes.handouts.hocCertificate(klassId).slice(1)}
      v2
      filename={(klass: IKlass) => `HOC Certificates - ${klass.klass_name}`}
    />
    <MultiClassPrintoutDialog
      title="Get logged in to Kodable"
      helperText="Print or digitally send code cards to get students logged in at school and at home"
      open={studentLoginDialogState.open}
      onClose={() => {
        studentLoginDialogState.onClose()
      }}
      handoutUrl={(klassId) => routes.handouts.studentHandouts(klassId).slice(1)}
      filename={(klass) => `Kodable Student Handouts - ${klass.klass_name || "New Class"}`}
      v2
    />
    <Box display="flex" justifyContent="center" mb={12}>
      <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2} maxWidth={1120} width="100%" alignItems={{ xs: 'center', lg: 'initial' }}>
        <Typography align="center" variant="h2" style={{ fontFamily: 'Bjola', fontSize: 36 }}>Hour of Code with Kodable</Typography>
        <Box>
          <Typography align="center">Everything you need to set up your class for the Hour of Code with Kodable.</Typography>
          <Typography align="center">Just one hour is a great way to start teaching your kids about Computer Science.</Typography>
        </Box>
        <Box mt={4}>
          <Box maxWidth={600} margin="auto" mb={2}>
            <YouTube videoId="N8E1UWtGY2Y" containerClassName={ytClasses.responsive} />
          </Box>
        </Box>
        <Box display="flex" mt={2}>
          <Box width="960px" display="flex" justifyContent={{xs: 'center', lg: 'space-between'}} alignItems={{xs: 'center', lg: 'stretch'}} flexDirection={{ xs: 'column', lg: 'row' }} margin="0 auto">
            <InstructionCard
              num="1."
              title="Pre-load Kodable"
              text="Make sure you white-list our URLs, have the most up-to-date app version, and do a test run before the big day!"
              buttonText="Details"
              href="http://support.kodable.com/en/articles/417307-why-isn-t-kodable-loading"
            />
            <InstructionCard
              num="2."
              title="Print Login Instructions"
              text="Prepare login cards for students, which can be distributed for classroom use or continued learning at home."
              buttonText="Get Login Instructions"
              onClick={studentLoginDialogState.handleOpen}
            />
            <InstructionCard
              num="3."
              title="Print Student Certificates"
              text="Print certificates to celebrate your student's hard work!"
              buttonText="Download"
              onClick={printoutDialogState.handleOpen}
            />
          </Box>
        </Box>
      </Box>
    </Box>
    <HocActivities />
  </PageContainer>;
}

const InstructionCard: React.FC<{ num?: ReactNode, title?: ReactNode, text?: ReactNode, buttonText?: ReactNode, onClick?: () => void, href?: string }> = ({ num, title, text, buttonText, onClick, href }) => {
  const sharedClasses = useSharedStyles();
  return (
    <Box border={"1px solid #ddd"} borderRadius="3px" boxShadow="0 0 2px #eee" width="100%" maxWidth="300px" mb={{xs: '1rem', lg: '0'}}>
      <CardContent className={sharedClasses?.vspacing2} style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: "space-between", alignItems: "space-between"}}>
        <Typography align="center" variant="h6" style={{ fontWeight: 'bold', fontSize: 18, marginBottom: '1rem' }}>{num} {title}</Typography>
        <Typography align="center" style={{ fontSize: 14 }}>{text}</Typography>
        <Button onClick={onClick} color="blue" variant="contained" style={{ whiteSpace: 'nowrap', fontWeight: 'bold'}} href={href} {...{ target: '_blank' }}>
          {buttonText}
        </Button>
      </CardContent>
    </Box>
  )
}

interface HocActivityInfo {
  thumbnail_src: string;
  title: string;
  grades: string;
  href: string;
  type: 'game' | 'lesson' | 'worksheet';
}

const activities: HocActivityInfo[] = [
  {
    thumbnail_src: 'robots_and_puppies.png',
    title: 'Robots and Puppies',
    grades: 'K-5',
    href: 'robots-and-puppies',
    type: 'game'
  },
  {
    thumbnail_src: 'make_shapes_with_code.png',
    title: 'Make Shapes with Code',
    grades: 'K-1 (Pre-Reader)',
    href: 'make-shapes-with-code',
    type: 'game'
  },
  {
    thumbnail_src: 'build_your_own_fuzz.png',
    title: 'Build Your Own Kodable Fuzz',
    grades: 'K-5',
    href: 'fuzz-builder',
    type: 'game'
  },
  {
    thumbnail_src: 'intro_to_programming.png',
    title: 'Intro to Programming (Pre-readers welcome)',
    grades: 'K-5',
    href: 'self-guided',
    type: 'game'
  },
  {
    thumbnail_src: 'beach_cleanup.png',
    title: 'Beach Cleanup',
    grades: 'K-5',
    href: 'beach-cleanup',
    type: 'game'
  }
]

export const HocActivities = () => {
  return <Box maxWidth={1100} margin="0 auto" mt={4} display="flex" flexDirection="column" alignItems="center">
    <Typography variant="h2" style={{ fontFamily: 'Bjola', fontSize: 36 }}>Free Hour of Code Activities</Typography>
    <Box maxWidth={700} marginBottom={4}>
      <Typography variant="subtitle1" align="center">Our Hour of Code activities are free, easy to implement, and have been used by millions of teachers and students around the world.</Typography>
    </Box>
    <Grid container spacing={8}>
      <AnimatePresence>
        {activities.map(activity => (<HocActivity key={activity.thumbnail_src} {...activity} />))}
      </AnimatePresence>
    </Grid>
  </Box>
}

const HocActivity: React.VFC<HocActivityInfo> = ({ thumbnail_src, title, grades, href }) => {
  const _href = `${process.env.REACT_APP_KODABLE_WEBSITE}/hour-of-code/${href}`
  return <MotionGrid item xs={12} sm={6} md={4} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.15 }}>
    <Box component="a" {...{ href: _href, target: '_blank' }}>
      <img src={`/images/hoc-thumbnails/${thumbnail_src}`} style={{ borderRadius: 12, marginBottom: 8 }} />
      <Typography variant="subtitle1">{title}</Typography>
      <Typography>Grades {grades}</Typography>
      <Box mt={2}>
        <Typography><Link href={_href} target="_blank">View <FontAwesomeIcon icon={faArrowCircleRight} /></Link></Typography>
      </Box>
    </Box>
  </MotionGrid>
}

const MotionGrid = motion.custom(Grid);