import Modal from 'components/modal/Modal';
import { useAlertDispatch } from 'context/AlertProvider';
import useUpdateAccount from 'mutations/useUpdateAccount';
import { mutate } from 'loaders/useCurrentUser';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from "react";

interface EditAccountModalProps {
  currentUser: { id: number; name: string; username: string };
  closeModal: () => void;
}

const EditAccountModal: React.FC<EditAccountModalProps> = ({ currentUser, closeModal }) => {
  const dispatch = useAlertDispatch();
  const updateAccount = useUpdateAccount(currentUser.id);

  const formik = useFormik({
    initialValues: {
      name: currentUser.name,
      username: currentUser.username,
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      username: Yup.string()
        .email('Invalid email address')
        .required('Required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Required'),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), ''],
        'Passwords must match'
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      updateAccount(values).then((data) => {
        if (data.status === 422) {
          dispatch({
            id: 'account_not_updated_error',
            type: 'error',
            title: 'Account not Updated!',
            message: data.message,
          });
        } else {
          dispatch({
            id: 'updateAccount',
            type: 'success',
            title: 'Account Updated!',
            message: 'Your account was successfully updated.',
          });
          closeModal();
          setTimeout(() => {
            dispatch({ type: 'removeAlert', id: 'updateAccount' });
          }, 3000);
          mutate();
        }
      });
    },
  });

  return (
    <Modal closeModal={closeModal}>
      <div className="w-full flex-shrink-0 flex flex-col justify-center items-center">
        <div className="relative font-bold text-3xl text-white w-full p-6 bg-kodable-blue text-center mx-auto flex flex-col justify-center items-center">
          <svg
            className="h-12 w-12"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
            <path
              fillRule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clipRule="evenodd"
            />
          </svg>
          Edit Your Account
        </div>

        <form className="w-full" onSubmit={formik.handleSubmit}>
          <div className="bg-gray-100 py-10 px-4 border-b border-gray-300">
            <div>
              <label
                htmlFor="name"
                className="block font-bold text-gray-700 mb-2">
                Name{' '}
                {formik.errors.name && (
                  <span className="ml-2 text-kodable-red text-sm font-thin">
                    *{formik.errors.name}
                  </span>
                )}
              </label>
              <input
                placeholder="Name"
                onChange={formik.handleChange}
                value={formik.values.name}
                autoComplete="false"
                name="name"
                id="name"
                type="text"
                className="border rounded p-2 w-full"
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="username"
                className="block font-bold text-gray-700 mb-2">
                Email{' '}
                {formik.errors.username && (
                  <span className="ml-2 text-kodable-red text-sm font-thin">
                    *{formik.errors.username}
                  </span>
                )}
              </label>
              <input
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.username}
                autoComplete="false"
                name="username"
                id="username"
                type="text"
                className="border rounded p-2 w-full"
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="password"
                className="block font-bold text-gray-700 mb-2">
                Password
                {formik.errors.password && (
                  <span className="ml-2 text-kodable-red text-sm font-thin">
                    *{formik.errors.password}
                  </span>
                )}
              </label>
              <input
                placeholder="Password"
                onChange={formik.handleChange}
                value={formik.values.password}
                autoComplete="false"
                name="password"
                id="password"
                type="password"
                className="border rounded p-2 w-full"
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="confirmPassword"
                className="block font-bold text-gray-700 mb-2">
                Confirm Password{' '}
                {formik.errors.confirmPassword && (
                  <span className="ml-2 text-kodable-red text-sm font-thin">
                    *{formik.errors.confirmPassword}
                  </span>
                )}
              </label>
              <input
                placeholder="Confirm Password"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                autoComplete="false"
                name="confirmPassword"
                id="confirmPassword"
                type="password"
                className="border rounded p-2 w-full"
              />
            </div>
          </div>
          <div className="flex justify-end items-center my-4 px-4">
            <button
              type="button"
              onClick={closeModal}
              className="flex items-center justify-center text-gray-500 border border-gray-200 bg-white hover:bg-gray-100 active:bg-gray-200 px-4 py-2 rounded">
              Cancel
            </button>
            <button
              disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
              type="submit"
              className="flex items-center justify-center bg-kodable-green border border-kodable-green hover:border-kodable-green  hover:bg-kodable-green-dark active:bg-kodable-green-darker active:border-kodable-green px-4 py-2 rounded text-white ml-6 disabled:opacity-50 disabled:cursor-not-allowed">
              {formik.isSubmitting ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditAccountModal;
