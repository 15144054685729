import {ILesson} from "../../types/ILesson";
import {Box} from "@material-ui/core";
import LessonSummary from "./components/paywall/LessonSummary";
import LessonOverview from "./components/paywall/LessonOverview";
import LessonFooter from "./components/paywall/LessonFooter";
import LessonAppBar from "./components/paywall/LessonAppBar";
import React from "react";

const CurriculumPaywall: React.VFC<{lesson: ILesson}> = ({lesson}) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <LessonAppBar/>
      <img src={lesson.thumbnail_url} style={{
        width: '100%',
        height: 150,
        objectFit: 'cover',
        position: "absolute",
        top: 75,
        left: 0
      }} alt={''} />
      <Box display={'flex'} flexDirection={'row'} style={{marginTop: 250}}>
        <LessonSummary lesson={lesson}/>
        <LessonOverview lesson={lesson}/>
      </Box>
      <Box>
        <LessonFooter unitId={lesson.unit_id}/>
      </Box>
    </Box>
  )
}

export default CurriculumPaywall