import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSearch, faUniversity, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { routes } from "routes";
import Button from "components/ui/buttons/Button";
import PageHeader from "components/ui/PageHeader";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { AnimatePresence, motion } from "framer-motion";
import useDialogState, { DialogStateProps } from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { Route } from "react-router-dom";
import grades, { gradesArray } from "types/Grades";
import { IKlass } from "types/IKlass";
import MultiClassReport from "./MultiClassReport";
import SchoolReport from "./SchoolReport";

const ReportsIndex: React.VFC = () => {  
  return <>
    <Route
      component={ReportSelect}
      path={routes.reports.index}
      exact
    />
    <Route
      component={MultiClassReport}
      path={routes.reports.multiClass}
      exact
    />
    <Route
      component={SchoolReport}
      path={routes.reports.school}
      exact
    />
    <Redirect
      to={routes.reports.index}
    />
  </>
}

const ReportSelect: React.VFC = () => {
  const theme = useTheme();
  const sharedClasses = useSharedStyles();
  const selectClassesDialogState = useDialogState();
  const history = useHistory();

  return <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
    <SelectClassesDialog {...selectClassesDialogState} />
    <PageHeader inAppBar title="Reports" />
    <Box display="flex" flexDirection="row" justifyContent="center" width="100%" p={8} className={sharedClasses.hspacing4}>
      <ReportCard
        color={theme.palette.teal.main}
        icon={faUniversity}
        primaryText="School Progress"
        secondaryText="See progress for your entire school in one place."
        onClick={() => history.push(routes.reports.school)}
      />
      <ReportCard
        color={theme.palette.orange.main}
        icon={faUsers}
        primaryText="Class Progress"
        secondaryText="Single and multiple class progress reports"
        onClick={selectClassesDialogState.handleOpen}
      />
    </Box>
  </Box>;
}

const ReportCard: React.VFC<{ color: string, icon: IconProp, primaryText: string, secondaryText: string, onClick: () => void }> = ({ color, icon, primaryText, secondaryText, onClick }) => {
  const sharedClasses = useSharedStyles();

  return <Box
    p={4} flexBasis="40%" borderRadius={6}
    display="flex" flexDirection="column" alignItems="center"
    className={sharedClasses.hoverEnlarge} bgcolor={color}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} color="white" size="5x" />
    <Box textAlign="center" color="white" py={4} className={sharedClasses.vspacing1}>
      <Typography variant="h1">{primaryText}</Typography>
      <Typography>{secondaryText}</Typography>
    </Box>
    <Button
      variant="outlined"
      style={{
        background: 'white'
      }}
    >
      View
    </Button>
  </Box>
}

const SelectClassesDialog: React.VFC<DialogStateProps> = ({ open, onClose }) => {
  const [selectedClasses, setSelectedClasses] = useState<Set<number>>(new Set());
  const [classNameFilter, setClassNameFilter] = useState('');
  const { teacherData } = useTeacherInit();

  const sharedClasses = useSharedStyles();

  const classesByGrade = useMemo(() => {
    const classes = teacherData?.klasses || [];

    return gradesArray.reduce((memo, cur) => {
      return {
        ...memo,
        [cur.key]: classes.filter(klass => klass.grade === cur.key && klass.klass_name.toLowerCase().includes(classNameFilter.toLowerCase())).sort((klassA, klassB) => klassA.klass_name.localeCompare(klassB.klass_name))
      }
    }, {} as { [key in keyof typeof grades]: IKlass[] });
  }, [teacherData, classNameFilter]);

  const totalResults = useMemo(() => {
    return Object.values(classesByGrade).reduce((memo, arr) => memo + arr.length, 0);
  }, [classesByGrade]);

  const history = useHistory();

  useEffect(() => {
    if (open) {
      setSelectedClasses(new Set());
    }
  }, [open])

  const handleToggleClass = (classId: number, selected: boolean) =>
    setSelectedClasses(selectedClasses => {
      const newSelectedClasses = new Set(selectedClasses);

      if (selected) {
        newSelectedClasses.add(classId);
      } else {
        newSelectedClasses.delete(classId);
      }

      return newSelectedClasses;
    });

  return <Dialog fullWidth open={open} scroll="paper">
    <DialogTitle>Report Creator</DialogTitle>
    {(teacherData?.klasses?.length || 0) > 0 && <Box component={DialogContent} className={sharedClasses.vspacing2} display="flex" flexDirection="column">
      <Typography align="center">We'll generate a single progress report for all of classes that you select.</Typography>
      <TextField
        placeholder="Search Classes"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faSearch} />
            </InputAdornment>
          ),
        }}
        value={classNameFilter}
        onChange={e => setClassNameFilter(e.target.value)}
      />
      {totalResults === 0 && <Alert severity="info">Your search returned no results.</Alert>}
      {totalResults > 0 && <Box component={Paper} {...{ variant: 'outlined' }} px={2} pb={2} overflow="scroll">
        {gradesArray.map(grade => {
          const klasses = classesByGrade[grade.key];

          if (klasses.length === 0) {
            return null;
          }

          return <React.Fragment key={grade.key}>
            <Box color={grade.color} borderBottom={`2px solid ${grade.color}`} mt={2} mb={1}>
              <Typography variant="subtitle1">{grade.name}</Typography>
            </Box>

            <AnimatePresence>
              {klasses.map(klass => {
                const selected = selectedClasses.has(klass.id);

                return <motion.div
                  key={klass.id}
                  initial={{ opacity: 0, maxHeight: 0, overflow: 'hidden' }}
                  animate={{ opacity: 1, maxHeight: 125, overflow: 'visible' }}
                  exit={{ opacity: 0, maxHeight: 0, overflow: 'hidden' }}
                  transition={{ duration: 0.25 }}
                >
                  <ListItem
                    button {...{ disableRipple: true }}
                    selected={selected} onClick={() => handleToggleClass(klass.id, !selected)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={selected}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={klass.klass_name}
                    />
                  </ListItem>
                </motion.div>
              })}
            </AnimatePresence>
          </React.Fragment>
        })}
      </Box>}
    </Box>}
    {teacherData?.klasses?.length === 0 && <Alert severity="info" action={<Button color="inherit" size="small" onClick={() => history.push(routes.classes.addNew.index)}>Add a class</Button>}>You don't have any classes to generate reports.</Alert>}
    <DialogActions>
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="end">
        <Button
          variant="outlined"
          onClick={onClose}
        >Cancel</Button>
        <Box display="flex" flexDirection="column" alignItems="end" className={sharedClasses.vspacing1}>
          <Typography variant="subtitle1" style={{ visibility: selectedClasses.size > 0 ? 'visible' : 'hidden' }}>{selectedClasses.size} {selectedClasses.size > 1 ? 'classes' : 'class'} selected</Typography>
          <Button
            variant="contained"
            color="green"
            disabled={selectedClasses.size === 0}
            onClick={() => {
              history.push(routes.reports.multiClass, {
                classIds: Array.from(selectedClasses)
              })
            }}
          >
            Generate Report
          </Button>
        </Box>
      </Box>
    </DialogActions>
  </Dialog>
}

export default ReportsIndex;