import {Box, Typography} from "@material-ui/core";
import RoundIcon from "../RoundIcon";
import React from "react";

const LessonOverviewSection: React.VFC<{title: string, icon: string, iconColor: string, body?: string, duration?: string}> = ({title, icon, iconColor, body, duration}) => {
  return (
    <Box marginTop={5}>
      <Box display={'flex'}>
        {(icon && iconColor) &&
          <Box marginRight={2}>
            <RoundIcon color={iconColor} imageUrl={icon}/>
          </Box>
        }
        <Box>
          <Box width={'100%'}>
            <Typography variant={'h6'}>{title} {duration && <span style={{fontSize: '65%', fontWeight: 400}}>{duration} minutes</span>}</Typography>
            <div dangerouslySetInnerHTML={{__html: body || ""}} className={'curriculum'} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default LessonOverviewSection