import endpoints from 'endpoints';
import React, { createContext, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'

export interface GiftCode {
  id: number;
  email: string;
  intercom_lead_id: string;
  redemption_code: string;
  redemption_length: string;
  is_redeemed: boolean;
  applied_subscription_id: number;
  created_at: string;
  updated_at: string;
  shopify_order_id: string;
}

export interface GiftCodesResponse extends Array<GiftCode> {}

export type GiftCodeRedemptionLength = '6 Months' | '12 Months' | 'Lifetime'

interface GiftCodesContextProps {
    giftCodes: GiftCodesResponse;
    filter: (search: string) => void;
    mutate: () => void;
    error: any;
}

const GiftCodesContext = createContext<GiftCodesContextProps>({
    giftCodes: [],
    filter: () => {},
    mutate: () => {},
    error: null
})

export const useGiftCodesContext = () => useContext(GiftCodesContext)

const GiftCodesProvider: React.FC = ({ children }) => {
    const {data, error, mutate} = useSWR<GiftCodesResponse>(endpoints?.godmode?.giftCodes)
    const [giftCodes, setGiftCodes] = useState<GiftCodesResponse>([])

    useEffect(() => {
        handleSetSorted()
    }, [data])

    const handleSetSorted = () => {
        if (!data) {
          return
        }
        const sorted = data.sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        })
        setGiftCodes(sorted)
    }

    const filter = (search: string | null) => {
        if (!search || search.length === 0) {
            handleSetSorted()
            return
        }

        const filtered = giftCodes.filter((giftCode) => giftCode.email.includes(search) || giftCode.redemption_code.includes(search))
        setGiftCodes(filtered)
    }

    return (
    <GiftCodesContext.Provider value={{ giftCodes, mutate, filter, error }}>
        {children}
    </GiftCodesContext.Provider>
    );
};

export default GiftCodesProvider;
