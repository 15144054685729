import {Grid, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import FuzzCard from "../../../components/cards/FuzzCard";
import CardUserCreatedContent from "./CardUserCreatedContent";
import {IUserCreatedContent} from "../../../types/IUserCreatedContent";
import {IStudentWorkData} from "./ClassStudentWork";
import MazeCard from "../../../components/cards/MazeCard";
import {IMazeData} from "../../../types/IMazeData";
import React from "react";

interface StudentWorkProps {
  student: IStudentWorkData;
}

export const StudentWork: React.VFC<StudentWorkProps> = ({student}) => {
  return <>
    <Typography variant="h2">{student.name}</Typography>
    {(student?.fuzzes.length === 0 && student?.content.length === 0) &&
      <Alert severity="info">This student hasn't created any content yet.</Alert>}
    <Grid container spacing={2}>
      {student?.fuzzes.map(data =>
        <FuzzCard key={data.id} student={student} fuzz={data} />
      )}
      {student?.content.map(data => {
        if (data.category === "Maze") {
          return <MazeCard key={data.id} maze={data as unknown as IMazeData} student={student} />
        } else {
          return <CardUserCreatedContent key={data.id} student={student} {...(data as IUserCreatedContent)} />
        }
      })}
    </Grid>
  </>
}

export default StudentWork