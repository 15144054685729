import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { DialogStateProps } from "hooks/useDialogState";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props}>{props.children as any}</Slide>;
});

const ViewAsStudentDialog: React.VFC<DialogStateProps & { title: string, href: string }> = ({ open = false, onClose = () => { }, title, href }) => {
  const theme = useTheme();

  return <Dialog open={open} fullScreen TransitionComponent={Transition as any} transitionDuration={500}>
    <DialogTitle style={{
      background: theme.palette.blue.main,
      color: 'white'
    }}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box>
          {title}
        </Box>
        <IconButton
          onClick={onClose}
          style={{
            color: 'white',
          }}
        >
          <Close style={{ transform: 'scale(1.5)' }} />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent style={{ marginTop: 0, paddingTop: 0 }}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" height="100%" width="100%">
        <Box mt={1} flexGrow={1} alignSelf="stretch">
          <iframe
            src={href}
            style={{
              height: '100%',
              width: '100%'
            }}
          ></iframe>
        </Box>
      </Box>
    </DialogContent>
  </Dialog>
}

export default ViewAsStudentDialog;