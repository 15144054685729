import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Typography, useTheme } from "@material-ui/core"
import Button from "components/ui/buttons/Button"
import useSharedStyles from "components/useSharedStyles"
import { useOrganizationProfile } from "context/OrganizationProfileProvider"
import useDialogState from "hooks/useDialogState"
import { formatDateToUSLocale } from "pages/godmode/utils/time"
import { Link } from "react-router-dom"
import { routes } from "routes"
import { IOrganizationProfileNote } from "types/IOrganizationProfile"
import OrganizationProfileNoteDialog from "./dialogs/OrganizationProfileNoteDialog"
import { justFetch } from "mutations/mutate"
import React, { useState } from "react"
import endpoints from "endpoints"
import { useAlert } from "context/AlertProvider"
import { ConfirmationDialog } from "components/dialogs/base/ConfirmationDialog"

const OrganizationProfileActivityItem: React.FC<{note: IOrganizationProfileNote, mutateNotes: () => void}> = ({note, mutateNotes}) => {
    const { organization_profile } = useOrganizationProfile()
    const theme = useTheme()
    const sharedClasses = useSharedStyles()
    const createdAtDate = new Date(note?.created_at);
    const isCurrentSubscription = organization_profile?.current_subscription?.id === note?.subscription_id
    const noteDialogState = useDialogState()
    const deleteNoteDialogState = useDialogState()
    const [submitting, setSubmitting] = useState(false);
    const alert = useAlert()

    const handleDelete = async () => {
        setSubmitting(true)
        const res = await justFetch(endpoints.godmode.organizationProfile.notes.editNote(note?.id), 'PUT', {
            ...note,
            deleted: true
        })

        if (res.ok) {
            alert.success("Successfully deleted note!")
            mutateNotes()
            setSubmitting(false)
            return
        }

        setSubmitting(false)
        const error = await res.json();
        alert.error(error?.message || "Could not delete note")
    }

    return (
        <Box
            className={sharedClasses?.vspacing1}
            style={{
                borderBottom: '1px solid '+theme.palette.grey[400],
                paddingBottom: '1rem'
            }}
        >
            <OrganizationProfileNoteDialog dialogState={noteDialogState} note={note} mutateNotes={mutateNotes} />
            <ConfirmationDialog
                open={deleteNoteDialogState?.open}
                onClose={() => deleteNoteDialogState?.onClose()}
                title="Are you sure you want to delete this note?"
                subtitle={note?.note}
                submitting={submitting}
                onConfirm={() => handleDelete()}
                danger={true}
            />
            <Typography className={sharedClasses?.hspacing1} variant="body2">
                <strong>Note - {formatDateToUSLocale(createdAtDate)}</strong>
                
                {note?.creator &&
                    <>
                        <span>&#8226;</span>
                        <Box
                            style={{
                                display: 'inline'
                            }}
                        >
                            <span>Created by </span>
                            <strong>
                                <Link
                                    style={{
                                        color: theme.palette.blue.main
                                    }}
                                    to={routes.godmode.user.account(note?.creator?.id)}
                                >
                                    {note?.creator?.name}
                                </Link>
                            </strong>
                        </Box>
                    </>
                }
            </Typography>
            <Box
                style={{
                    padding: '.75rem',
                    background: 'white',
                    border: '1px solid #eee',
                    borderRadius: 3
                }}
            >
                <Typography variant="body1">{note?.note}</Typography>
            </Box>
            <Box className={sharedClasses?.vspacing2}>
                {note?.subscription_id &&
                    <Typography variant="body2">Subscription ID: {note?.subscription_id} {isCurrentSubscription ? '(Current Subscription)' : '(Expired Subscription)'}</Typography>
                }
                <Box
                    className={sharedClasses?.hspacing2}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        startIcon={<FontAwesomeIcon size="xs" icon={faTrashCan} />}
                        size="small"
                        color="red"
                        variant="outlined"
                        onClick={() => deleteNoteDialogState?.handleOpen()}
                    >
                        Delete
                    </Button>
                    <Button
                        startIcon={<FontAwesomeIcon size="xs" icon={faPencil} />}
                        size="small"
                        color="blue"
                        variant="outlined"
                        onClick={() => noteDialogState?.handleOpen()}
                    >
                        Edit
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default OrganizationProfileActivityItem;