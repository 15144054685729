import React from 'react';
import { Link } from 'react-router-dom';
import useStudent from 'loaders/useStudent';
import useProjects from 'loaders/useProjects';
import FuzzImage from 'components/misc/FuzzImage';
import MazeImage from 'components/misc/MazeImage';
import Loading from 'components/loading/Loading';
import { format } from 'date-fns';
import useV2StudentProgress from 'loaders/useV2StudentProgress';

const rankBadgeInfo: { [key: number]: number[] } = {
  1: [50, 51, 52, 53, 54, 55, 56, 57, 58, 59],
  2: [60, 61, 62, 63, 64, 65, 66, 67, 68, 69],
  3: [70],
  4: [0, 1, 2, 3, 4],
  5: [5, 6, 7, 8, 9],
  6: [10, 11, 12, 13, 14],
  7: [15, 16, 17, 18, 19],
  8: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
  9: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39],
  10: [40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
};

const StudentOverviewPage: React.FC = () => {
  const student = useStudent();
  const { projects, loading, error } = useProjects();
  const { completedLessons, totalLessons, threeStarLessons } = useV2StudentProgress();

  const circumference = 90 * 2 * Math.PI;
  const progress = (threeStarLessons / totalLessons) * 100;

  function splitRankDigits() {
    return student.kode_rank?.toString()?.split('') || ['0'];
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-32 h-64">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center mt-32 h-64">
        <Loading />
      </div>
    );
  }

  const rankBadgeImageNumber = (student.kode_rank && student?.kode_rank < 71)
    ? Object.entries(rankBadgeInfo).find((entry) => entry?.[1].indexOf(student?.kode_rank || 1) > -1)?.[0] || "3"
    : "3";

  const rankBadgeImage = 'rank_badge00' + (Number(rankBadgeImageNumber) < 10 ? '0' : '') + rankBadgeImageNumber;

  return (
    <div className="max-w-screen-lg my-8 mx-8">
      <div className="flex mb-16">
        <div className="flex flex-col space-y-12 lg:flex-row justify-between items-center w-full lg:space-y-0 lg:space-x-8">
          <div className="lg:min-h-full max-w-lg w-full py-6 border-3 border-kodable-teal rounded flex justify-center items-center flex-1 bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="w-16 h-16 text-kodable-teal mr-4"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
              />
            </svg>
            <div>
              <h4 className="text-kodable-teal text-3xl font-bold">
                {completedLessons}
              </h4>
              <p className="font-bold text-lg text-gray-900">
                Programs Written
              </p>
            </div>
          </div>
          <div className="lg:min-h-full max-w-lg w-full border-3 border-kodable-blue rounded flex justify-center items-center flex-1 bg-white">
            <div
              className="relative py-8 mr-4"
              style={{
                backgroundImage:
                  'url(images/students/rank_badges/badges/rank_badge_reference.png)',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}>
              <img
                alt="badge"
                className="h-32 w-32"
                src={`images/students/rank_badges/badges/${rankBadgeImage}.png`}
              />
              <span className="absolute h-full top-0 flex justify-center items-center left-0 right-0">
                {splitRankDigits()[0] && student && (
                  <img
                    alt="rank_digit"
                    className="w-8 h-auto"
                    src={`images/students/rank_badges/numbers/badge_digit_${splitRankDigits()[0]}.png`} />
                )}
                {splitRankDigits()[1] && (
                  <img
                    alt="rank_digit"
                    className="w-8 -ml-1"
                    src={`images/students/rank_badges/numbers/badge_digit_${splitRankDigits()[1]}.png`} />
                )}
              </span>
            </div>
            <div>
              <p className="font-bold text-lg text-gray-900">Kode Rank</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex mb-16">
        <div className="flex flex-col space-y-12 lg:flex-row justify-between items-center w-full lg:space-x-8 lg:space-y-0">
          <div className="lg:min-h-full w-full max-w-lg rounded flex justify-center items-start flex-1 shadow-md border bg-white">
            <div className="flex flex-col m-6 w-full">
              <div className="flex justify-between items-center w-full">
                <p className="font-bold text-md lg:text-base xl:text-lg text-gray-900">
                  Recent Projects
                </p>
                <Link
                  to={`/parent/students/${student.id}/projects`}
                  className="flex justify-center items-center font-bold text-sm lg:text-base xl:text-lg text-left text-kodable-orange border-2 border-kodable-orange py-1 px-2 lg:py-2 lg:px-2 xl:px-4 rounded hover:text-white hover:bg-kodable-orange-dark active:text-white active:bg-kodable-orange-darker active:border-kodable-orange">
                  <svg
                    className="h-4 w-4 lg:w-6 lg:h-6 lg:mr-4 mr-2"
                    aria-hidden="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path
                      fill="currentColor"
                      d="M365.99 33.1L194.11 289.51l78 65 218.81-221.52C564.15 52.52 427.95-55.3 365.99 33.1zM167.87 309.29c-40.45 2.41-77.23 17.53-98.03 72.35-2.35 6.21-8 9.98-14.59 9.98-11.11 0-45.46-27.67-55.25-34.35C0 439.62 37.93 512 128 512c75.86 0 128-43.77 128-120.19 0-3.39-.68-6.64-1.06-9.96l-87.07-72.56z"></path>
                  </svg>
                  View Projects
                </Link>
              </div>
              <div className="mt-8 space-y-6">
                {projects.length > 0 ? (
                  projects.slice(0, 2).map((project) => (
                    <div
                      key={project.id}
                      className="flex justify-center items-center">
                      {project?.category === 'maze' ? (
                        <>
                          <MazeImage
                            className="w-48"
                            mazeId={project?.id}
                            studentCode={student.student_code}
                          />
                          <p className="flex-1 px-4">
                            {`Maze created ${format(new Date(project.created_at), 'MMMM dd, yyyy')}`}
                          </p>
                        </>
                      ) : (
                        <FuzzImage
                          className="w-48"
                          fuzzId={project.id}
                          studentCode={student.student_code}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div>{student.name} hasn't created any projects yet.</div>
                )}
              </div>
            </div>
          </div>
          <div className="lg:min-h-full w-full max-w-lg rounded flex justify-center items-start flex-1 shadow-md border bg-white">
            <div className="flex flex-col m-6 w-full">
              <div className="flex justify-between items-center w-full">
                <p className="font-bold text-md lg:text-base xl:text-lg text-gray-900">
                  Home Progress
                </p>
                <Link
                  to={`/parent/students/${student.id}/progress`}
                  className="flex justify-center items-center font-bold text-sm lg:text-base xl:text-lg text-left text-kodable-teal border-2 border-kodable-teal py-1 px-2 lg:py-2 lg:px-2 xl:px-4 rounded hover:text-white hover:bg-kodable-teal-dark active:text-white active:bg-kodable-teal-darker active:border-kodable-teal">
                  <svg
                    aria-hidden="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="h-4 w-4 lg:w-6 lg:h-6 lg:mr-4 mr-2">
                    <path
                      fill="currentColor"
                      d="M117.65 277.65c6.25 6.25 16.38 6.25 22.63 0L192 225.94l84.69 84.69c6.25 6.25 16.38 6.25 22.63 0L409.54 200.4l29.49 29.5c15.12 15.12 40.97 4.41 40.97-16.97V112c0-8.84-7.16-16-16-16H363.07c-21.38 0-32.09 25.85-16.97 40.97l29.5 29.49-87.6 87.6-84.69-84.69c-6.25-6.25-16.38-6.25-22.63 0l-74.34 74.34c-6.25 6.25-6.25 16.38 0 22.63l11.31 11.31zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16z"></path>
                  </svg>
                  View All Progress
                </Link>
              </div>
              <div className="relative flex justify-center my-24">
                <svg
                  width="200"
                  height="200"
                  className="text-gray-300 absolute">
                  <circle
                    style={{
                      transition: '0.35s stroke-dashoffset',
                      transform: 'rotate(-90deg)',
                      transformOrigin: '50% 50%',
                    }}
                    stroke="currentColor"
                    strokeWidth="20"
                    fill="transparent"
                    r="90"
                    cx="100"
                    cy="100"
                  />
                </svg>
                <svg
                  width="200"
                  height="200"
                  className="text-kodable-teal absolute">
                  <circle
                    style={{
                      transition: '0.35s stroke-dashoffset',
                      transform: 'rotate(-90deg)',
                      transformOrigin: '50% 50%',
                      strokeDasharray: `${circumference} ${circumference}`,
                      strokeDashoffset: Number.isNaN(circumference - (progress / 100) * circumference) ? 0 : circumference - (progress / 100) * circumference,
                    }}
                    stroke="currentColor"
                    strokeWidth="20"
                    fill="transparent"
                    r="90"
                    cx="100"
                    cy="100"
                  />
                </svg>
                <div
                  style={{ height: '180px' }}
                  className="flex justify-center items-center z-10 text-gray-600 font-bold text-5xl mt-2">
                  {parseInt(progress.toFixed(0), 10)}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentOverviewPage;
