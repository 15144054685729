import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useRedirectToLogin } from "context/AuthProvider";
import {useHistory} from "react-router-dom";
import {routes} from "../../routes";
import React from "react";

export const SalesPromptLogin: React.FC = () => {
    const sharedClasses = useSharedStyles()
    const { redirectToLogin } = useRedirectToLogin();
    const history = useHistory();
  
    return (
      <Box className={sharedClasses?.vspacing4}>
        <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h1" align="center">Create a Quote for your School or District</Typography>
          <img width="300" height="274" src="/images/gracie-with-books.png" alt="A Fuzz Character, gracie, standing next to a pile of books" />
          <Typography variant="body1" align="center">If you already have an account with us, please log in to ensure your quote is associated with the correct Organization.</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
          <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="50%" borderRight="1px solid #eee">
            <Typography variant="body1">Don't have an account?</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push(routes.sales.quotes.create.selectLocation)
              }}
            >
              Continue as Guest
            </Button>
          </Box>
          <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="50%">
            <Typography variant="body1">Already have an account?</Typography>
            <Box>
              <Button
                startIcon={<FontAwesomeIcon icon={faUser} />}
                variant="contained"
                color="primary"
                onClick={() => redirectToLogin()}
              >
                Log in to Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }