import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Link, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { routes } from "routes";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useAlertDispatch } from "context/AlertProvider";
import endpoints from "endpoints";
import useDialogState from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import React, { useState } from "react";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { mutate } from "swr";
import { BulkDeleteKlassesDialog } from "./BulkDeleteKlassesDialog";
import { IClassDialogProps } from "../../../pages/classes/IClassDialogProps";

const DeleteClassDialog: React.VFC<IClassDialogProps> = ({ open, onClose = () => { }, classId }) => {
  const { teacherData } = useTeacherInit();
  const classData = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id === classId)!;
  }, [teacherData, classId]);
  const sharedClasses = useSharedStyles();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState<string | false>(false);
  const history = useHistory();
  const dispatchAlert = useAlertDispatch();
  const bulkDeleteKlassesDialogState = useDialogState();

  const handleDelete = () => {
    setIsDeleting(true);
    justFetch(endpoints.classById(classId), 'DELETE')
      .then(res => {
        setIsDeleting(false);
        if (!res.ok) {
          res.json().then(body => setDeleteError(body.message || body.error || 'An unknown error occurred.'))
          throw new Error();
        } else {
          const alertId = Math.random();
          dispatchAlert({
            id: alertId,
            type: 'success',
            title: 'Class Deleted'
          });

          setTimeout(() => {
            dispatchAlert({
              id: alertId,
              type: 'removeAlert'
            })
          }, 3000);

          setTimeout(() => {
            mutate(endpoints.teacherInit, {
              ...teacherData,
              klasses: teacherData?.klasses.filter(({ id }) => id !== classId)
            })
          }, 500);
        }
      })
      .then(() => history.push(routes.classes.index))
      .catch(() => setDeleteError('An unknown error occurred.'));
  }

  return <>
    <BulkDeleteKlassesDialog open={bulkDeleteKlassesDialogState.open} onClose={() => { bulkDeleteKlassesDialogState.onClose(); onClose(); }} />
    <Dialog open={open && !bulkDeleteKlassesDialogState.open} fullWidth>
      <LinearProgress style={{ visibility: isDeleting ? 'visible' : 'hidden' }}></LinearProgress>
      <DialogTitle>Delete class?</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <Typography>
          You are deleting the class <span style={{fontWeight: 600}}>{classData?.klass_name}</span>.
        </Typography>

        <Alert severity="warning">Deleting a class is permanent and this class cannot be recovered.</Alert>

        <Link
          onClick={bulkDeleteKlassesDialogState.handleOpen}
          className={sharedClasses.hoverCursorPointer}
        >Delete multiple classes</Link>

        {deleteError && <Alert severity="error" action={<Button color="inherit" size="small" onClick={handleDelete} />}>{deleteError}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="red"
          startIcon={<FontAwesomeIcon icon={faTrash} />}
          onClick={() => handleDelete()}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  </>
}

export default DeleteClassDialog;