import mutate from './mutate';

type config = {
  name: string
  id: number
}

const useUpdateStudent = (studentId: number) => {
  return (args: config) => {
    return mutate(`/v2/students/${studentId}`, 'PUT', args);
  };
}

export default useUpdateStudent