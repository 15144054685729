import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import { useAlert } from "context/AlertProvider";
import { useOrganizationProfile } from "context/OrganizationProfileProvider";
import endpoints from "endpoints";
import { Form, FormikProvider, useFormik } from "formik";
import useDialogState from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import * as Yup from 'yup';
import React from "react";

const OrganizationProfileBillingDetailsModal: React.FC = () => {
    const alert = useAlert()
    const dialogState = useDialogState()
    const {organization_profile, mutate} = useOrganizationProfile()

    const form = useFormik({
        initialValues: {
            display_name: organization_profile?.display_name,
            billing_contact_name: organization_profile?.billing_contact_name,
            billing_contact_email: organization_profile?.billing_contact_email,
            billing_contact_phone: organization_profile?.billing_contact_phone,
            billing_contact_address_street: organization_profile?.billing_contact_address_street,
            billing_contact_address_street2: organization_profile?.billing_contact_address_street2,
            billing_contact_address_city: organization_profile?.billing_contact_address_city,
            billing_contact_address_province: organization_profile?.billing_contact_address_province,
            billing_contact_address_zip: organization_profile?.billing_contact_address_zip,
            billing_contact_address_country: organization_profile?.billing_contact_address_country,
        },
        validationSchema: Yup.object({
            billing_contact_email: Yup.string()
                .email('Enter a valid email address.')
                .required("Please enter the Billing Contact Email")
        }),
        onSubmit: async (values) => {
            if (!organization_profile) {
                return;
            }

            const res = await justFetch(endpoints.godmode.organizationProfile.update(organization_profile?.id), 'PUT', values)

            if (res?.ok) {
                mutate()
                alert.success('Organization profile updated successfully')
                dialogState.onClose()
                return
            }

            const error = await res.json()
            
            alert.error(error.message || "The organization could not be updated")
        }
    })

    return (
        <>
        <Dialog open={dialogState.open}>
            <DialogTitle>Edit Organization {organization_profile?.display_name}</DialogTitle>
            <DialogContent>
                <FormikProvider value={form}>
                    <Form>
                        <TextField
                            name="display_name"
                            label="Organization Name"
                            fullWidth
                            value={form.values.display_name}
                            onChange={form.handleChange}
                        />
                        <TextField
                            name="billing_contact_name"
                            label="Billing Contact Name"
                            fullWidth
                            value={form.values.billing_contact_name}
                            onChange={form.handleChange}
                        />
                        <TextField
                            name="billing_contact_email"
                            label="Billing Contact Email"
                            value={form.values.billing_contact_email}
                            onChange={form.handleChange}
                        />
                        <TextField
                            name="billing_contact_phone"
                            label="Billing Contact Phone"
                            value={form.values.billing_contact_phone}
                            onChange={form.handleChange}
                        />
                        <TextField
                            name="billing_contact_address_street"
                            label="Billing Contact Address"
                            fullWidth
                            value={form.values.billing_contact_address_street}
                            onChange={form.handleChange}
                        />
                        <TextField
                            name="billing_contact_address_street2"
                            label="Billing Contact Address Line 2"
                            fullWidth
                            value={form.values.billing_contact_address_street2}
                            onChange={form.handleChange}
                        />
                        <Box
                            style={{
                                display: 'flex',
                                gap: '1rem',
                                alignItems: 'center'
                            }}
                        >
                            <TextField
                                name="billing_contact_address_city"
                                label="City"
                                value={form.values.billing_contact_address_city}
                                onChange={form.handleChange}
                                fullWidth
                            />
                            <TextField
                                name="billing_contact_address_province"
                                label="Province"
                                value={form.values.billing_contact_address_province}
                                onChange={form.handleChange}
                                fullWidth
                            />
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                gap: '1rem',
                                alignItems: 'center'
                            }}
                        >
                            <TextField
                                name="billing_contact_address_zip"
                                label="Zip"
                                fullWidth
                                value={form.values.billing_contact_address_zip}
                                onChange={form.handleChange}
                            />
                            <TextField
                                name="billing_contact_address_country"
                                label="Country"
                                fullWidth
                                value={form.values.billing_contact_address_country}
                                onChange={form.handleChange}
                            />
                        </Box>
                    </Form>
                </FormikProvider>
            </DialogContent>
            <DialogActions>
                <Box
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        color="newOrange"
                        onClick={() => dialogState.onClose()}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="blue"
                        type="submit"
                        variant="contained"
                        onClick={form.submitForm}
                        disabled={form.isSubmitting}
                    >
                        Save
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
        <Button
            variant="contained"
            color="blue"
            onClick={() => dialogState.handleOpen()}
            fullWidth

        >
            Edit
        </Button>
        </>
    )
}

export default OrganizationProfileBillingDetailsModal