import { Box, Link, Typography } from "@material-ui/core";
import React, { useMemo } from "react";

const blackoutStyle = { filter: 'brightness(0%)' };
const weeks = [
  +new Date(2022, 8, 9),
  +new Date(2022, 8, 19),
  +new Date(2022, 8, 26),
  +new Date(2022, 9, 3)
];

export const BackToSchoolLandingPage: React.VFC = () => {
  const curDate = useMemo(() => {
    return +new Date();
  }, []);

  return <Box display="flex" flexDirection="column" alignItems="center" p={2}>
    <Box display="flex" flexDirection="column" alignItems="center" maxWidth={1100}>
      <Box>
        <img src="/images/promo/back-to-school/header.png" />
      </Box>
      <Box maxWidth="80%" pt={2}>
        <Typography variant="subtitle1" align="center" style={{ fontSize: '2rem' }}>
          Four Weeks ⭐️ Four Challenges
        </Typography>
        <Typography>
          It's a bird... It's a plane... It's a coding adventure to start the new year!  Help your students unlock their superhero coding powers through exciting weekly in-game challenges starting September 11th.
        </Typography>
        <br />
        <Typography>
          Students earn a puzzle piece for each challenge they complete and will unlock epic superhero-themed Fuzz costumes after collecting all four!
        </Typography>
      </Box>
      <Box pt={1}>
        <Typography variant="subtitle1" style={{ fontSize: '1.15rem' }}>
          Your iPads must be updated to the latest version of Kodable to participate in this event
        </Typography>
      </Box>
      <Box pt={2} style={{marginBottom: '6rem'}}>
        <Box display="flex" flexDirection="row" alignItems="flex-start" height={350}>
          <Box style={{ paddingTop: 5 }} width="50%">
            <img src="/images/promo/back-to-school/week-1.png" style={curDate < weeks[0] ? blackoutStyle : undefined} />
            <Typography paragraph>
              Head to Fuzz Builder for your first quest! Create a new Fuzz or edit an existing Fuzz to earn a piece of the puzzle.
            </Typography>
            <Typography>
              <Typography display="inline" style={{ fontWeight: 'bold' }}>Student Objective:</Typography> Using the Kode editor, students will customize their own Fuzz characters by modifying functions and properties within the code.
            </Typography>
            <br />
            <Link target="_blank" href="http://support.kodable.com/en/articles/1652894-how-to-use-fuzz-builder">Learn more about Fuzzbuilder</Link>
          </Box>
          <Box width={32}>&nbsp;</Box>
          <Box style={{ paddingTop: 0 }} width="50%">
            <img src="/images/promo/back-to-school/week-2.png" style={curDate < weeks[1] ? blackoutStyle : undefined} />
            <Box mt={1.5}>
              <Typography paragraph>
                The second challenge will lead you to Pet Land. Care for your fuzzy friends to unlock and program two paw-some robot companions for your pups.
              </Typography>
              <Typography>
                <Typography display="inline" style={{ fontWeight: 'bold' }}>Student Objective:</Typography> Students will apply their understanding of conditions and functions to program their Carebot.
              </Typography>
              <br />
              <Link target="_blank" href="http://support.kodable.com/en/articles/6395705-what-is-kodable-pets-and-how-do-i-access-it">Learn more about Pets</Link>
            </Box>
          </Box>
        </Box>
        <Box pt={2} display="flex" flexDirection="row" alignItems="flex-start" height={350}>
          <Box style={{ paddingTop: 6 }} width="50%">
            <img src="/images/promo/back-to-school/week-3.png" style={curDate < weeks[2] ? blackoutStyle : undefined} />
            <Box mt={1.5}>
              <Typography paragraph>
                Embark on your 3rd adventure as you journey out to the galaxy and collect 15 stars on any planet.
              </Typography>
              <Typography>
                <Typography display="inline" style={{ fontWeight: 'bold' }}>Student Objective:</Typography> Students will practice various fundamental concepts related to computer programming and computational thinking.
              </Typography>
              <br />
              <Link target="_blank" href="http://support.kodable.com/en/articles/5650473-what-courses-does-kodable-offer">Learn more about our planets and courses</Link>
            </Box>
          </Box>
          <Box width={32}>&nbsp;</Box>
          <Box style={{ paddingTop: 0 }} width="50%">
            <img src="/images/promo/back-to-school/week-4.png" style={curDate < weeks[3] ? blackoutStyle : undefined} />
            <Box mt={1.5}>
              <Typography paragraph>
                Unleash your creativity and become a super-powered maze master in your final mission. Design an epic superhero level and share it with your classmates to take on the challenge together!
              </Typography>
              <Typography>
                <Typography display="inline" style={{ fontWeight: 'bold' }}>Student Objective:</Typography> Students will apply what they have learned to design, create, and solve their own maze.
              </Typography>
              <br />
              <Link target="_blank" href="http://support.kodable.com/en/articles/6381239-how-to-use-maze-maker">Learn more about Maze Maker</Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box marginBottom="4rem">
        <Typography variant="h1" align="center" style={{marginBottom: '4rem', fontWeight: 'bold'}}>Frequently Asked Questions</Typography>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box display="flex" width="48%" flexDirection="column">
            <Box marginBottom="2rem">
              <Typography variant="subtitle1" style={{marginBottom: '.5rem'}}>How do I prepare for the event?</Typography>
              <Typography>The event is available to all students and will <strong>automatically</strong> show up on their accounts. All student iPads must be updated to the latest version of Kodable to participate in this event.</Typography>
            </Box>
            <Box marginBottom="2rem">
              <Typography variant="subtitle1" style={{marginBottom: '.5rem'}}>Who is the event for?</Typography>
              <Typography>The event primarily targets students in grades 1 - 3, but it is open to all students. No prior coding experience is required for students to participate in the event, making it accessible to beginners.</Typography>
            </Box>
            <Box marginBottom="2rem">
              <Typography variant="subtitle1" style={{marginBottom: '.5rem'}}>Why should my students participate in this event?</Typography>
              <Typography>Events offer students a unique opportunity to explore the world of Kodable. They are designed to be "hands-off" for teachers, enabling students to showcase their creativity, problem-solving skills, and active participation through enjoyable and interactive themed, challenges.</Typography>
            </Box>
          </Box>
          <Box display="flex" width="48%" flexDirection="column">
            <Box marginBottom="2rem">
              <Typography variant="subtitle1" style={{marginBottom: '.5rem'}}>What do students earn at the end of the event?</Typography>
              <Typography>Students will earn a superhero themed costume bundle to customize their Fuzz characters as they play through the game. Print out <Link target="_blank" href="http://resources.kodable.com/public/certificates/SuperCoder.png">completion certificates</Link> for an extra fun surprise!</Typography>
            </Box>
            <Box marginBottom="2rem">
              <Typography variant="subtitle1" style={{marginBottom: '.5rem'}}>Can I turn the event off, hide it or save it for another time?</Typography>
              <Typography>Participation in events cannot be disabled for students. We strongly encourage all teachers to allow their students to take part in events as it expands their comprehension of the coding concepts introduced in Kodable. Please note that this specific event will be available for participation from 9/11 to 10/06 and will not be accessible before or after those dates.</Typography>
            </Box>
            <Box marginBottom="2rem">
              <Typography variant="subtitle1" style={{marginBottom: '.5rem'}}>Can I track student event progress on my teacher dashboard?</Typography>
              <Typography>While we don't have this functionality yet, it is in the works. Students will be able to see their own progress within the game. Stay tuned for exciting event updates over the next few months!</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>;
}