import React from 'react';
import FuzzImage from 'components/misc/FuzzImage';
import FuzzCodeBlock, {makeFuzzCodeBlockConfigFromFuzzConfig} from '../../../modal/FuzzCodeBlock';
import {IFuzzData} from "../../../../types/IFuzzData";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  useTheme
} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";
import Button from "../../../ui/buttons/Button";
import {faDownload, faTrash} from "@fortawesome/free-solid-svg-icons";
import {PDFDownloadLink} from "@react-pdf/renderer";
import FuzzPrintout from "../../../../pages/printouts/student-creations/FuzzPrintout";
import Amplitude from "../../../../utils/Amplitude";

export interface DialogFuzzDetailProps {
  student: { student_code: string, name: string }
  onClose: () => void
  onDelete: () => void
  fuzz: IFuzzData
  open: boolean
}

const useStyles = makeStyles((theme) => ({
  name: {
    fontFamily: 'monospace',
    marginBottom: 15
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const FuzzDetailsDialog: React.FC<DialogFuzzDetailProps> = ({ open, student, onClose, onDelete, fuzz}) => {
  const classes = useStyles()
  const theme = useTheme()
  const {config} = fuzz
  const createPDFFuzzData = () => {
    return {
      id: fuzz.id,
      clientId: fuzz.client_id,
      studentId: fuzz.student_id,
      fuzzName: fuzz.config.clazzName,
      fuzzProperties: {
        body: fuzz.config.properties.body.value.default,
        eyes: fuzz.config.properties.eyes.value.default,
        mouth: fuzz.config.properties.mouth.value.default
      },
      dressFunctions: fuzz.config.dressFunctions.map((func) => {
        return {
          type: func.name,
          value: func.value.default
        }
      }),
      createdAt: fuzz.created_at,
      updatedAt: fuzz.updated_at
    }
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <FontAwesomeIcon icon={faXmark} />
      </IconButton>
      <DialogContent>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
          {config.id &&
            <FuzzImage
              fuzzId={config.id}
              studentCode={student.student_code}
            />
          }
          <Typography variant={'h1'} className={classes.name}>{config.clazzName}.js</Typography>
          <FuzzCodeBlock {...makeFuzzCodeBlockConfigFromFuzzConfig(config)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button variant={'outlined'} color={'blue'} startIcon={<FontAwesomeIcon icon={faDownload} />} style={{marginRight: 20}}>
            <PDFDownloadLink
              style={{textDecoration: 'none', color: theme.palette.primary.main}}
              document={
                <FuzzPrintout studentCode={student.student_code} fuzz={createPDFFuzzData()}/>
              }
              fileName={`Fuzz - ${config.clazzName} by ${student.name}`}
            >
            {({loading}) => {
                Amplitude.track('Generated Fuzz Handout');
                return (loading ? 'Loading...' : 'Download')
              }
            }
          </PDFDownloadLink>
          </Button>
          <Button variant={'outlined'} color={'red'} startIcon={<FontAwesomeIcon icon={faTrash} />} onClick={onDelete}>Delete</Button>
        </Box>
        <Button variant={'contained'} color={'primary'} onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
};

export default FuzzDetailsDialog;
