import Button from 'components/ui/buttons/Button'
import useDialogState from 'hooks/useDialogState'
import { GodmodeConfirmDialog } from 'pages/godmode/shared/GodmodeConfirmDialog'
import { useOrganizationProfile } from 'context/OrganizationProfileProvider'
import { justFetch } from 'mutations/mutate'
import endpoints from 'endpoints'
import { useAlert } from 'context/AlertProvider'
import React from "react";
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'

export const SubscriptionDeleteButton: React.FC<{subscriptionId: number}> = ({subscriptionId}) => {
    const dialogState = useDialogState()
    const { mutate } = useOrganizationProfile()
    const alert = useAlert()
    const history = useHistory();

    return (
        <>
            <GodmodeConfirmDialog
                dialogState={dialogState}
                dialogTitle="Delete Subscription"
                dialogContent={`Are you sure you want to delete subscription ${subscriptionId}?`}
                confirmInputPlaceholder="Type DELETE to confirm"
                confirmInputMatch="DELETE"
                variant="danger"
                onConfirm={async () => {
                        return justFetch(endpoints?.godmode?.deleteSubscription(subscriptionId), 'DELETE')
                        .then((res) => {
                            if (res.ok) {
                                mutate()
                                alert.success(`Successfully deleted subscription ${subscriptionId}`)
                                dialogState?.onClose()
                                history.push(routes.godmode.organizationProfiles.index);
                                return
                            }
                            if (res.status === 422) {
                                throw new Error('Cannot delete subscription: ' + res.text)
                            }
                            throw new Error('Failed to delete subscription')
                        })
                        .catch((error) => {
                            alert.error(error.message)
                            dialogState?.onClose()
                        })
                    }
                }   
            />
            <Button
                variant="contained"
                color="red"
                size="medium"
                onClick={() => dialogState?.handleOpen()}
            >
                Delete
            </Button>
        </>
    )
}