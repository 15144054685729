import { faEmpire } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogTitle, DialogContent, Box, TextField, DialogActions, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useFormik } from "formik";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import { ILevelData } from "pages/courses/ICourseData";
import React, { useState, useEffect } from "react";
import ReactImageFallback from "react-image-fallback";
import { mutate } from "swr";

const LevelSolutionModal: React.VFC<{ open?: boolean, onClose?: (...args: any[]) => void, level: ILevelData | undefined }> = ({ open = false, onClose = () => { }, level }) => {
    const { currentUser } = useCurrentUser();
    const [submitError, setSubmitError] = useState(false);
    const sharedClasses = useSharedStyles();
  
    const form = useFormik({
      enableReinitialize: true,
      initialValues: level?.meta || {},
      onSubmit: values => {
        if (level?.id === undefined) {
          return;
        }
  
        return justFetch(endpoints.basicsLevelById(level?.id), 'PUT', {
          ...level,
          meta: values
        }).then(res => {
          if (!res.ok) {
            throw new Error();
          }
  
          mutate(endpoints.allBasicsLevels);
          onClose();
        }).catch(() => setSubmitError(false))
      }
    });
  
    useEffect(() => {
      if (open) {
        setSubmitError(false);
      }
    }, [open])
  
    return <Dialog open={open} fullWidth>
      <form onSubmit={form.handleSubmit}>
        <DialogTitle>{level?.meta?.dashboardTitle || level?.name}</DialogTitle>
        <DialogContent>
          <ReactImageFallback
            src={level?.fullsize_url || undefined}
            fallbackImage="/images/student-creations-page/no-maze.png"
          />
          {currentUser.is_god && <Box display="flex" flexDirection="row" mt={2} alignItems="center" className={sharedClasses.hspacing2}>
            <FontAwesomeIcon icon={faEmpire} />
            <Box flexGrow={1}>
              <TextField
                fullWidth
                id="dashboardTitle"
                name="dashboardTitle"
                label="Dashboard Level Name"
                placeholder={`Default: ${level?.name}`}
                value={form.values.dashboardTitle}
                onChange={form.handleChange}
                disabled={form.isSubmitting}
              />
            </Box>
          </Box>}
          {submitError && <Alert severity="error">There was an error submitting this form.</Alert>}
        </DialogContent>
        <DialogActions>
          <Box></Box>
          <Box className={sharedClasses.hspacing2}>
            <Button
              variant="outlined"
              onClick={onClose}
              disabled={form.isSubmitting}
            >Close</Button>
            {currentUser.is_god && <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={form.isSubmitting}
            >
              Save and close
            </Button>}
          </Box>
        </DialogActions>
      </form>
    </Dialog>
}

export default LevelSolutionModal;