import React, {Dispatch, SetStateAction} from "react";
import {GameTitle} from "../../../enums/GameTitle";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import GameHorizontalCard from "./GameHorizontalCard";

const GameCheckboxHorizontalCard: React.FC<{
  checked: boolean,
  setChecked: Dispatch<SetStateAction<boolean>>,
  game: GameTitle,
  color: string
}> = ({checked, setChecked, game, color}) => {
  return (
    <FormControlLabel
      style={{
        border: `0.3rem solid ${color}`,
        borderRadius: 15,
        filter: `grayscale(${checked ? 0 : 0.9})`,
        opacity: `${checked ? 1 : 0.5}`,
        cursor: "pointer",
        userSelect: 'none',
        padding: '1rem',
        position: 'relative',
        maxWidth: 700,
        width: '100%',
        marginRight: 0,
        marginLeft: 0,
      }}
      control={
        <Checkbox
          size="medium"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
          style={{
            position: 'absolute',
            left: -20,
            padding: '0.5rem',
            background: 'white',
            borderRadius: 4,
            border: '1px solid #eee'
          }}
        />
      }
      label={<GameHorizontalCard game={game}/>}
    />
  )
}

export default GameCheckboxHorizontalCard