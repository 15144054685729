import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { IEvent } from "types/IEvent";
import React from "react";

export interface IDialogProps {
  open: boolean;
  onClose?: (...args: any[]) => any;
}

const DeleteEventDialog: React.VFC<IDialogProps & {
  event: IEvent;
  onDelete: Function;
}> = ({ open, onClose = () => { }, event, onDelete }) => {
  const sharedClasses = useSharedStyles();

  const handleDelete = () => {
    onDelete(event.id)
  }  

  return <Dialog fullWidth open={open}>
    <DialogTitle>Delete students?</DialogTitle>
    <DialogContent className={sharedClasses.vspacing2}>
      <Typography>
        This will delete the event: {`${event.event_name}`}
      </Typography>      
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        variant="outlined"
      >
        Cancel
      </Button>
      <Button
        onClick={handleDelete}
        variant="contained"
        color="red"
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
}

export default DeleteEventDialog;