import React from "react"
import {GodmodePageWrapper} from "pages/godmode/GodmodePageWrapper"
import AccountManagementIndexTabs from "./AccountManagementIndexTabs";

export const AccountManagementIndex: React.FC = () => {
  return (
    <GodmodePageWrapper title="Account Management" loading={false}>
      <AccountManagementIndexTabs />
    </GodmodePageWrapper>
  )
}

