import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import UnlinkAccountDialog from "components/dialogs/settings/UnlinkAccountDialog";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "routes";
import { mutate } from "swr";
import { IGodmodeUserProfile } from "../IGodmodeUserProfile";
import { UICollapsingBlock } from "../UICollapsingBlock";

export const AssignedAdminsBlock: React.FC<{ teacher: IGodmodeUserProfile }> = ({ teacher }) => {
  const [filterString, setFilterString] = useState('');
  const sharedClasses = useSharedStyles();
  const teachers = teacher.teachers?.filter((t) => t.user_role === 'admin'
      && teacher.organization_profile
      && t.id !== teacher.organization_profile.administrator_id
      && t.id !== teacher.id) || [];
  const [userIdToUnlink, setUserIdToUnlink] = useState<number>();

  return <UICollapsingBlock color="rgb(101, 44, 179)" title={`Assigned Admins (${teachers.length}/${teacher.subscription?.license_count || 0})`}>
    <UnlinkAccountDialog userId={userIdToUnlink} open={!!userIdToUnlink} onClose={() => {
      mutate(endpoints.godmode.teacher(teacher.id), {
        ...teacher,
        teachers: teacher.teachers.filter(teacher => teacher.id !== userIdToUnlink)
      });
      setUserIdToUnlink(undefined);
    }} />
    <Box className={sharedClasses.vspacing2} p={2}>
      <Box>
        <TextField
          placeholder="Search"
          value={filterString}
          onChange={e => setFilterString(e.target.value)}
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "rgb(101, 44, 179)" }}>Name</TableCell>
              <TableCell style={{ color: "rgb(101, 44, 179)" }}>Email</TableCell>
              <TableCell style={{ color: "rgb(101, 44, 179)" }}>School</TableCell>
              <TableCell style={{ color: "rgb(101, 44, 179)" }}>Unassign</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.map((teacher, i) => {
              return <TableRow key={i}>
                <TableCell>
                  <Box display="flex" alignItems="center" className={sharedClasses.hspacing1}>
                    <FontAwesomeIcon icon={faUser} />
                    <Link style={{ fontWeight: 'normal' }} component={RouterLink} to={routes.godmode.user.index(teacher.id)}>{teacher.name}</Link>
                  </Box>
                </TableCell>
                <TableCell><Link component={RouterLink} style={{ fontWeight: 'normal' }} to={routes.godmode.user.index(teacher.id)}>{teacher.username}</Link></TableCell>
                <TableCell>{teacher.school?.school_name || 'None'}</TableCell>
                <TableCell>
                  <Button
                    startIcon={<Delete />}
                    onClick={() => setUserIdToUnlink(teacher.id)}
                  >Unassign</Button>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {teachers.length === 0 && <Alert severity="info">No teachers to show.</Alert>}
    </Box>
  </UICollapsingBlock>;
}