import SectionContainer from "./SectionContainer";
import React from "react";

const LessonMaterials: React.FC<{materials: string, resourceUrl: string}> = ({materials, resourceUrl}) => {
  return (
    <SectionContainer
      title={'Lesson Materials'}
      icon={'/images/curriculum_images/Materials.png'}
      iconColor={'#fbaf3f'}
      innerHtml={materials}
      downloadResourceUrl={resourceUrl}
    />
  )
}

export default LessonMaterials