import OnboardingLayout from './shared/OnboardingLayout';
import { Link } from 'react-router-dom';
import useCurrentUser from 'loaders/useCurrentUser';
import Amplitude from 'utils/Amplitude';
import { useEffect } from 'react';
import { routes } from 'routes';

const AddPlayerPage = () => {
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    Amplitude.track('(Parent Onboarding) Viewed Landing Page');
  }, []);

  return (
    <OnboardingLayout
      imgName="spaceship"
      imgSrc="/images/onboarding/space-ship.jpg"
      title="Welcome to Kodable! Let’s go!"
      activeSteps={1}
    >
      <p className="mt-4">
        Add your first learner below. You can add up to four players.
      </p>
      <div className="flex items-center mt-24">
        <Link
          className="bg-kodable-blue hover:bg-kodable-blue-dark active:bg-kodable-blue-darker mr-6 text-white justify-center items-center font-semibold text-lg flex px-8 py-2 rounded shadow-kodable text-center flex-1"
          to={routes.parentOnboarding.addPlayer}>
          Create a<br /> new profile
        </Link>
        {currentUser.from_school ? (
          <Link
            className="bg-kodable-blue hover:bg-kodable-blue-dark active:bg-kodable-blue-darker text-white font-semibold text-lg flex px-8 py-2 rounded shadow-kodable text-center flex-1"
            to={routes.parentOnboarding.linkPlayer}>
            Use a Parent Code from school
          </Link>
        ) : (
          <div className="flex-1"></div>
        )}
      </div>
    </OnboardingLayout>
  );
}

export default AddPlayerPage