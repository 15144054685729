import React, { Fragment, useState } from 'react';
import TopNav from '../shared/TopNav';
import parentList from '../../../json/parent_playlist.json';
import find from 'lodash/find';
import AnswerKey from './shared/AnswerKey';
import Dropdown from './shared/Dropdown';
import {IParentPlaylistEpisode, IParentPlaylistLevel} from "../../../types/IParentPlaylist";

interface ILevel extends IParentPlaylistLevel {
  levelName: string
}

const AsteroidiaAnswerKeysPage: React.FC = () => {
  const [filter, setFilter] = useState('All Resources');

  const asteroidiaConcepts = find(parentList, {
    name: 'Asteroidia',
  })?.episodes.map((episode, index: number) => {
    return {
      ...episode,
      levels: episode.levels.map((level) => ({
        ...level,
        levelName: `${index + 14}.${level.order + 1}`,
      } as ILevel)),
    };
  });

  const levels = asteroidiaConcepts
    ?.filter((episode) => {
      if (filter === 'All Resources') {
        return true;
      } else {
        return filter === episode.name;
      }
    })
    .reduce((acc: any, episode: IParentPlaylistEpisode) => {
      return [...acc, ...episode.levels];
    }, []);

  return (
    <Fragment>
      <TopNav
        answerKeys
        title="Asteroidia"
        navTitle="Answer Keys"
        guideImage="/images/teacher-guides/asteroidia/asteroidiaThumb.jpg"
        navLinks={[
          { title: 'Learning Tools', to: '/learning-tools' },
          { title: 'Asteroidia', to: '/learning-tools/asteroidia' },
          {
            title: 'Answer Keys',
            to: '/learning-tools/answer-keys/asteroidia',
          },
        ]}
      />
      <div className="flex flex-col justify-center items-center w-full">
        <div className="my-8 w-full max-w-lg px-2">
          <h4 className="text font-bold text-gray-600 mb-1 text-center">
            Filter Solutions by Concept
          </h4>
          {asteroidiaConcepts &&
            <Dropdown
              filter={filter}
              setFilter={setFilter}
              values={[
                { id: 1, name: 'All Resources' },
                ...asteroidiaConcepts,
              ]}
            ></Dropdown>
          }
        </div>
        <div className="w-full max-w-screen-lg px-4 mb-24">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
            {levels?.map((level) => (
              <AnswerKey
                video={`https://s3-us-west-2.amazonaws.com/resources.kodable.com/asteroidia-solution-videos/${
                  level.episode
                }.${level.order + 1}.mov`}
                key={level.levelName}
                levelName={level.levelName}
                levelImage={`/images/students/solution_images/asteroidia_parent/${level.level_number}.jpg`}
              />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AsteroidiaAnswerKeysPage;
