import React, { Fragment } from 'react';
import { useAlertDispatch } from 'context/AlertProvider';
import { motion, AnimatePresence } from 'framer-motion';
import IAlert from './IAlert';

const Alert: React.FC<{ alerts: IAlert[] }> = ({ alerts }) => {
  const dispatch = useAlertDispatch();

  return (
    <div
      id="alertTarget"
      className="fixed right-0 left-0 md:left-auto md:right-0 mr-10 mt-4"
      style={{ zIndex: 2147483006 }}
    >
      <AnimatePresence>
        {alerts.map((alert) => (
          <motion.div
            key={alert.id}
            initial={{ opacity: 0, x: 1000 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.4 }}
            exit={{ opacity: 1, x: 1000 }}>
            <div className="flex justify-center items-center mt-3">
              <div className="w-96 relative flex flex-col items-start justify-between bg-white shadow-lg border border-gray-300 text-gray-800 py-4 px-4 rounded-md">
                <div className="flex justify-center items-start pr-6">
                  <Fragment>
                    <svg
                      className={`w-6 h-6 mr-2 ${
                        alert.type === 'success' && 'text-kodable-green'
                      } ${alert.type === 'error' && 'text-kodable-red'}`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d={`${
                          alert.type === 'success' ? 'M5 13l4 4L19 7' : ''
                        }${
                          alert.type === 'error'
                            ? 'M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                            : ''
                        }`}
                      />
                    </svg>
                    <div>
                      <p className="font-semibold">{alert.title}</p>
                      <p className="text-gray-600 font-light">
                        {alert.message}
                      </p>
                    </div>
                  </Fragment>
                </div>
                <button
                  className="absolute top-0 right-0 m-2"
                  onClick={() => {
                    dispatch({ type: 'removeAlert', id: alert.id });
                  }}>
                  <svg
                    className="w-5 h-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}

export default Alert;