import { Box } from "@material-ui/core";
import { routes } from "routes";
import PageHeader from "components/ui/PageHeader";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import StudentsProgress from "./StudentsProgress";

const MultiClassReport: React.VFC = () => {
  const { state } = useLocation<{ classIds: number[] }>();

  const history = useHistory();

  useEffect(() => {
    if (!state?.classIds) {
      history.push(routes.reports.index);
    }
  }, [state]);

  if (!state?.classIds) {
    return null;
  }

  return <Box p={4}>
    <PageHeader inAppBar title="Kodable Learning Report" />
    <StudentsProgress classIds={state.classIds} />
  </Box>
}

export default MultiClassReport;