import React, { useEffect } from 'react';
import OnboardingLayout from './shared/OnboardingLayout';
import { Link } from 'react-router-dom';
import useParentInit from 'loaders/useParentInit';
import Amplitude from 'utils/Amplitude';
import { routes } from 'routes';
import {Box, List, ListItem, makeStyles, Typography, useTheme} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare} from "@fortawesome/free-solid-svg-icons/faCheckSquare";

const Checkmark = () => {
  const theme = useTheme()
  return (
    <FontAwesomeIcon icon={faCheckSquare} color={theme.palette.green.main} size={"lg"} />
  );
}

const GameLogo: React.VFC<{logoUrl: string, appName?: string, appNameColor?: string, appNameImage?: string, showKodableLogo: boolean}> =
  ({logoUrl, appName, appNameColor, appNameImage, showKodableLogo}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignContent={'center'} alignItems={'center'} textAlign={'center'} width={120}>
      <img width={100} src={logoUrl} style={{borderRadius: 10, marginBottom: 10}}/>
      {showKodableLogo && <img width={100} src={'/images/logos/kodable-logo.svg'} /> }
      {!appNameImage && <Typography style={{
        fontWeight: 'normal',
        fontFamily: 'Bjola',
        color: appNameColor
      }}>{appName}</Typography>}
      {appNameImage && <img width={120} src={'/images/logos/kodable-bugworld-color.svg'} />}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    maxWidth: 1100,
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  appContainer: {
    width: 450,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  navigationLink: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      marginTop: 50,
      justifyContent: 'center'
    },
  }
}));

export default function IntroPage() {
  const { students, loading } = useParentInit();
  const theme = useTheme()
  const classes = useStyles()

  useEffect(() => {
    Amplitude.track('(Parent Onboarding) Viewed Kodable Home Intro');
  }, []);

  if (loading) {
    return null;
  }

  return (
    <OnboardingLayout
      imgName="intro"
      imgSrc="/images/onboarding/intro.png"
      title={`Help ${students?.[0]?.name || 'your child'} Explore the Kodable Universe`}
      activeSteps={2}
    >
      <Box width={450}>
        <Box className={classes.appContainer}>
          <GameLogo
            logoUrl={'/images/appicons/app_icon_kodable_basics.png'}
            appName={'BASICS'}
            appNameColor={theme.palette.newOrange.main}
            showKodableLogo={true}
          />
          <GameLogo
            logoUrl={'/images/appicons/app_icon_kodable_creator.png'}
            appName={'CREATOR'}
            appNameColor={theme.palette.newPink.main}
            showKodableLogo={true}
          />
          <GameLogo
            logoUrl={'/images/appicons/app_icon_kodable_bugworld.png'}
            showKodableLogo={false}
            appNameImage={'/images/logos/kodable-bugworld-color.svg'}
          />
        </Box>
        <List style={{fontSize: 26, width: 350, marginLeft: 65}}>
          <ListItem>
            <Checkmark />
            <Typography style={{marginLeft: 15, fontWeight: 600, fontSize: 18}}>Multiple apps that grow with your child's coding ability</Typography>
          </ListItem>
          <ListItem>
            <Checkmark />
            <Typography style={{marginLeft: 15, fontWeight: 600, fontSize: 18}}>Guided learning progression from block to text based coding</Typography>
          </ListItem>
          <ListItem>
            <Checkmark />
            <Typography style={{marginLeft: 15, fontWeight: 600, fontSize: 18}}>Personalized AI tutor</Typography>
          </ListItem>
        </List>
      </Box>
      <Box className={classes.navigationLink}>
        <Link
          className="bg-kodable-orange hover:bg-kodable-orange-dark active:bg-kodable-orange-darker text-white items-center font-semibold text-lg px-4 flex py-2 rounded shadow-kodable text-center w-48"
          to={routes.parentOnboarding.learning}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="w-6 h-6 mr-4">
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
          </svg>
          Learn More
        </Link>
      </Box>
    </OnboardingLayout>
  );
}
