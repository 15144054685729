import React from "react";

const RoundIcon: React.FC<{color: string, imageUrl: string, size?: number}> = ({color, imageUrl, size = 60}) => {
  return (
    <span style={{
      display: 'inline-block',
      position: 'relative',
      width: size,
      height: size,
      borderRadius: 50,
      background: color,
      clipPath: 'inset(0 0 0 0 round 100px)',
    }}>
      <img src={imageUrl} style={{
        position: 'absolute',
        width: '60%',
        margin: 'auto',
        top: 4,
        bottom: 0,
        right: 0,
        left: 0
      }}/>
    </span>
  )
}

export default RoundIcon