import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomePromotionBanner from 'components/banners/HomePromotionBanner';
import useCurrentUser from 'loaders/useCurrentUser';

interface ButtonLinkProps {
  children: React.ReactNode;
  to: string;
  icon: React.ReactNode;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({ children, to, icon }) => {
  const { pathname } = useLocation();

  return (
    <Link
      to={`${pathname}${to}`}
      className="transition duration-500 transform hover:scale-105 focus:scale-105 mx-2 mb-4 flex text-lg justify-center items-center py-1 px-4 border-2 border-kodable-pink text-kodable-pink focus:bg-kodable-pink-dark focus:text-white hover:bg-kodable-pink-dark hover:text-white active:bg-kodable-pink-darker active:border-kodable-pink shadow-lg rounded-sm">
      {icon}
      {children}
    </Link>
  );
}

const LearningPage: React.FC = () => {
  const { currentUser } = useCurrentUser();

  return (
    <div className="mx-4">
      <div className="relative mt-10 mx-auto max-w-screen-lg pb-32">
        <HomePromotionBanner currentUser={currentUser} />
        <Link
          to="/parent/learning-tools/learning-path"
          style={{ backgroundImage: 'url(images/landing/banner.jpg)' }}
          className="bg-cover bg-left bg-gray-300 h-32 lg:h-48 rounded-lg flex flex-col justify-end items-end">
          <div className="bg-kodable-orange hover:bg-kodable-orange-dark active:bg-kodable-orange-darker text-white px-6 py-2 rounded-sm mr-4 mb-4 font-bold flex pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="w-6 h-6 mr-6"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
            View
          </div>
        </Link>

        <h2 className="my-6 text-2xl font-bold text-gray-800">Ways to Learn</h2>
        <div className="flex flex-wrap justify-start lg:justify-between mb-6">
          <ButtonLink
            icon={
              <svg
                className="w-8 h-8 mr-4"
                aria-hidden="true"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path
                  fill="currentColor"
                  d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
              </svg>
            }
            to="/worksheets">
            Worksheets
          </ButtonLink>
          <ButtonLink
            icon={
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512">
                <path
                  fill="currentColor"
                  d="M575.33 456.43L399 8.02C397.28 3.1 392.61 0 387.65 0c-3.01 0-4.97 1.03-11.49 3.31-6.46 2.26-9.82 8.24-6.27 18.38-16.46 9.69-59.15 24.09-75.5 26.42-1.33-3.78-1.97-6.62-6.4-9.23V32c0-17.67-14.33-32-32-32h-96c-5.96 0-11.22 2.07-16 4.9C139.22 2.07 133.96 0 128 0H32C14.33 0 0 14.33 0 32v448c0 17.67 14.33 32 32 32h96c5.96 0 11.22-2.07 16-4.9 4.78 2.84 10.04 4.9 16 4.9h96c17.67 0 32-14.33 32-32V118.88l151.43 385.1c1.73 4.92 6.4 8.02 11.35 8.02 3 0 4.96-1.03 11.49-3.31 6.44-2.25 9.83-8.23 6.27-18.38 16.46-9.69 59.15-24.09 75.5-26.42 3.65 10.4 10.13 12.65 16.38 10.46l7.55-2.64c6.23-2.19 9.54-9.07 7.36-15.28zM128 480H32v-64h96v64zm0-96H32V128h96v256zm0-288H32V32h96v64zm128 384h-96v-64h96v64zm0-96h-96V128h96v256zm0-288h-96V32h96v64zm203.15 367.54L303.79 74.88c25.22-4.74 64.01-20.33 75.5-26.42l155.36 388.65c-25.23 4.75-64.01 20.33-75.5 26.43z"></path>
              </svg>
            }
            to="/guides">
            Guides
          </ButtonLink>
          <ButtonLink
            icon={
              <svg
                aria-hidden="true"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="w-8 h-8 mr-4">
                <path
                  fill="currentColor"
                  d="M328.84 109.75l48.57 24.32 24.28 48.63a16 16 0 0 0 28.62 0l24.28-48.63 48.57-24.32a16 16 0 0 0 0-28.66l-48.57-24.32-24.28-48.63c-5.43-10.85-23.19-10.85-28.62 0l-24.28 48.63-48.57 24.32a16 16 0 0 0 0 28.66zM396.5 83a16.1 16.1 0 0 0 7.16-7.16L416 51.14l12.34 24.74A16.1 16.1 0 0 0 435.5 83l24.72 12.38-24.72 12.42a16.2 16.2 0 0 0-7.16 7.16L416 139.7 403.66 115a16.2 16.2 0 0 0-7.16-7.16l-24.72-12.42zm106.66 318.53l-48.57-24.31-24.28-48.63c-5.43-10.86-23.19-10.86-28.62 0l-24.28 48.63-48.57 24.31a16 16 0 0 0 0 28.67l48.57 24.31 24.28 48.63a16 16 0 0 0 28.62 0l24.28-48.63 48.57-24.31a16 16 0 0 0 0-28.67zm-67.66 26.71a16.17 16.17 0 0 0-7.16 7.17L416 460.15l-12.34-24.74a16.17 16.17 0 0 0-7.16-7.17l-24.72-12.37 24.72-12.38a16.17 16.17 0 0 0 7.16-7.17L416 371.59l12.34 24.73a16.17 16.17 0 0 0 7.16 7.17l24.72 12.38zM384 255.64a16.06 16.06 0 0 0-8.84-14.33L262.59 185 206.31 72.23c-5.43-10.86-23.19-10.86-28.62 0L121.41 185 8.84 241.31a16 16 0 0 0 0 28.67l112.57 56.36 56.28 112.71a16 16 0 0 0 28.62 0l56.28-112.71L375.16 270a16.09 16.09 0 0 0 8.84-14.36zm-140.5 44.43a16.1 16.1 0 0 0-7.16 7.16L192 396.06l-44.34-88.83a16.1 16.1 0 0 0-7.16-7.16l-88.72-44.43 88.72-44.42a16.2 16.2 0 0 0 7.16-7.16L192 115.23l44.34 88.83a16.2 16.2 0 0 0 7.16 7.16l88.72 44.42z"></path>
              </svg>
            }
            to="/creative-projects">
            Get Creative
          </ButtonLink>
          <ButtonLink
            icon={
              <svg
                aria-hidden="true"
                role="img"
                className="w-8 h-8 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path
                  fill="currentColor"
                  d="M444.09 166.99l-27.39-28.37c-2.6-1.96-5.53-2.93-8.8-2.93-3.27 0-5.87.98-7.82 2.93L142.81 396.86l-94.88-94.88c-1.96-2.61-4.55-3.91-7.82-3.91-3.27 0-6.21 1.3-8.8 3.91l-27.4 27.38c-2.6 2.61-3.91 5.55-3.91 8.8s1.31 5.87 3.91 7.82l130.1 131.07c2.6 1.96 5.53 2.94 8.8 2.94 3.27 0 5.87-.98 7.82-2.94L444.08 183.6c2.6-2.61 3.91-5.55 3.91-8.8.01-3.24-1.3-5.86-3.9-7.81zM131.88 285.04c2.62 1.97 5.58 2.96 8.88 2.96s5.92-.99 7.89-2.96L353.34 80.35c2.62-2.64 3.95-5.6 3.95-8.88 0-3.28-1.33-5.92-3.95-7.89l-27.63-28.62c-2.62-1.97-5.58-2.96-8.88-2.96s-5.92.99-7.89 2.96L140.76 204.12l-60.41-60.41c-1.97-2.64-4.59-3.95-7.89-3.95s-6.26 1.31-8.88 3.95l-27.63 27.63c-2.62 2.64-3.95 5.6-3.95 8.88 0 3.29 1.33 5.92 3.95 7.89l95.93 96.93z"></path>
              </svg>
            }
            to="/answer-keys">
            Answer Keys
          </ButtonLink>
          <ButtonLink
            icon={
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512">
                <path
                  fill="currentColor"
                  d="M624 160h-16a16 16 0 0 0-16 16v12.82C570 171.07 542.44 160 512 160a128 128 0 0 0-128 128v32a128 128 0 0 0 128 128c30.44 0 58-11.07 80-28.82V432a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V176a16 16 0 0 0-16-16zm-32 160a80 80 0 0 1-160 0v-32a80 80 0 0 1 160 0zM212.5 74.35a16 16 0 0 0-15-10.35h-43.03a16 16 0 0 0-15 10.35L1 426.35A16 16 0 0 0 16 448h17.14a16 16 0 0 0 15-10.35L88.22 336h175.56l40.11 101.65a16 16 0 0 0 15 10.35H336a16 16 0 0 0 15-21.65zM107.16 288L176 113.58 244.84 288z"></path>
              </svg>
            }
            to="/vocabulary">
            Vocabulary
          </ButtonLink>
        </div>

        <h2 className="my-6 text-2xl font-bold text-gray-800">
          Get to know Kodable
        </h2>
        <div className="flex justify-between space-y-4 md:space-y-0 flex-col md:flex-row md:space-x-12">
          <Link
            to="/parent/learning-tools/get-to-know-kodable"
            style={{
              backgroundImage: 'url(images/landing/welcome_to_fuzztopia.jpg)',
            }}
            className="transition duration-500 transform hover:scale-105 ease-in-out bg-cover bg-center bg-gray-300 h-32 lg:h-48 rounded-lg w-full"></Link>
          <Link
            to="/parent/learning-tools/what-is-coding"
            style={{
              backgroundImage: 'url(images/landing/what_is_coding.jpg)',
            }}
            className="transition duration-500 transform hover:scale-105 bg-cover bg-bottom bg-gray-300 h-32 lg:h-48 rounded-lg w-full"></Link>
        </div>
        <h2 className="my-6 text-2xl font-bold text-gray-800">
          Browse Resources by Kodable World
        </h2>
        <div className="flex justify-between space-y-4 md:space-y-0 flex-col md:flex-row md:space-x-6">
          <Link
            to="/parent/learning-tools/smeeborg"
            style={{
              backgroundImage: 'url(images/landing/gameuniverse_smeeborg.jpg)',
            }}
            className="transition duration-500 transform hover:scale-105 bg-cover bg-top bg-gray-300 h-32 lg:h-40 rounded-lg w-full flex flex-col justify-end items-end">
            <p className="bg-kodable-green text-white px-2 lg:px-4 py-2 rounded-lg rounded-tr-none">
              Kindergarten and up
            </p>
          </Link>
          <Link
            to="/parent/learning-tools/asteroidia"
            style={{
              backgroundImage:
                'url(images/landing/gameuniverse_asteroidia.jpg)',
            }}
            className="transition duration-500 transform hover:scale-105 bg-cover bg-top bg-gray-300 h-32 lg:h-40 rounded-lg w-full flex flex-col justify-end items-end">
            <p className="bg-kodable-purple text-white px-2 lg:px-4 py-2 rounded-lg rounded-tr-none">
              3rd Grade and up
            </p>
          </Link>
          <Link
            to="/parent/learning-tools/bugworld"
            style={{
              backgroundImage: 'url(images/landing/gameuniverse_bugworld.jpg)',
            }}
            className="transition duration-500 transform hover:scale-105 bg-cover bg-top bg-gray-300 h-32 lg:h-40 rounded-lg w-full flex flex-col justify-end items-end">
            <p className="bg-kodable-pink text-white px-2 lg:px-4 py-2 rounded-lg rounded-tr-none">
              4th Grade and up
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LearningPage;
