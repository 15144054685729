import React, {useEffect, useState} from "react";
import {RevenueStatsHistoricSchoolChurnItem, useRevenueStatsContext} from "../../../../../context/RevenueStatsProvider";
import GenericChart, {IRevenueIndexGenericChartDataSets} from "./GenericChart";
import {Box, Divider, Grid, Tab, Tabs, Typography} from "@material-ui/core";
import {TabContext} from "@material-ui/lab";

type RevenueIndexSchoolChurnCategories =
    'revenue'
    | 'subscriptions'
    | 'percentage-revenue'
    | 'percentage-subscriptions'

const SchoolChurnChart: React.FC = () => {
    const [tab, setTab] = useState<RevenueIndexSchoolChurnCategories>('revenue');
    const {revenue_stats} = useRevenueStatsContext();
    const [relevantData, setRelevantData] = useState<IRevenueIndexGenericChartDataSets[]>([]);
    const [labels, setLabels] = useState<string[]>([]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: RevenueIndexSchoolChurnCategories) => {
        setTab(newValue);
    }

    const handleSetRelevantData = (dataSets: {
        data: keyof RevenueStatsHistoricSchoolChurnItem,
        label: string
    }[]) => {
        if (revenue_stats === undefined) {
            return
        }

        const dataArr = []

        if (dataSets[0]) {
            dataArr.push({
                label: dataSets[0].label,
                data: Object.keys(revenue_stats.historic_school_churn).map((item) => {
                    return revenue_stats.historic_school_churn[item][dataSets[0].data]
                }),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: true,
            })
        }

        if (dataSets[1]) {
            dataArr.push({
                label: dataSets[1].label,
                data: Object.keys(revenue_stats.historic_school_churn).map((item) => {
                    return revenue_stats.historic_school_churn[item][dataSets[1].data]
                }),
                borderColor: 'rgb(255, 205, 86)',
                backgroundColor: 'rgba(255, 205, 86, 0.5)',
                fill: true,
            })
        }

        setRelevantData(dataArr)
    }

    useEffect(() => {
        if (revenue_stats === undefined) {
            return
        }

        setLabels(Object.keys(revenue_stats.historic_school_churn))

        if (tab === 'revenue') {
            handleSetRelevantData([
                {
                    data: 'subscription_revenue_churn',
                    label: 'Subscription Revenue Churn'
                },
                {
                    data: 'subscription_revenue_expiring',
                    label: 'Subscription Revenue Expiring'
                }
            ])
        } else if (tab === 'subscriptions') {
            handleSetRelevantData([
                {
                    data: 'subscription_churn',
                    label: 'Subscription Churn'
                },
                {
                    data: 'subscription_expiring',
                    label: 'Subscription Expiring'
                }
            ])
        } else if (tab === 'percentage-revenue') {
            handleSetRelevantData([
                {
                    data: 'percentage_revenue_churned',
                    label: 'Percentage Revenue Churned'
                }
            ])
        } else if (tab === 'percentage-subscriptions') {
            handleSetRelevantData([
                {
                    data: 'percentage_subscriptions_churned',
                    label: 'Percentage Subscriptions Churned'
                }
            ])
        }
    }, [tab, revenue_stats])

    return (
        <Grid item xs={12} style={{marginTop: "20px"}}>
            <Typography variant="h1" align="center">School Subscription Churn</Typography>
            <Box>
                <TabContext value={tab}>
                    <Tabs value={tab} onChange={handleChange} style={{backgroundColor: 'transparent'}}>
                        <Tab value="revenue" label="Revenue"/>
                        <Tab value="subscriptions" label="Subscriptions"/>
                        <Tab value="percentage-revenue" label="% Revenue"/>
                        <Tab value="percentage-subscriptions" label="% Subscriptions"/>
                    </Tabs>
                </TabContext>
                <GenericChart
                    optionsLabel={``}
                    labels={labels}
                    dataSets={relevantData}
                />
            </Box>
            <Divider style={{marginTop: "20px"}} />
        </Grid>
    )
}

export default SchoolChurnChart