import { useFormik, FormikProvider, Form, useFormikContext } from "formik";
import { GodmodePageWrapper } from "pages/godmode/GodmodePageWrapper"
import * as Yup from 'yup'
import { ResourcePublishStatus, ResourceType, ResourceTypeMap, ResourcesProvider, useResourcesContext } from "context/ResourcesProvider";
import TextField from "components/ui/TextField";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { IResource } from "types/IResource";
import Button from "components/ui/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faCheckCircle, faCopy, faDownload, faEye, faEyeSlash, faFileCircleQuestion, faPencil, faPlusCircle, faRedoAlt, faSave, faTrashCan, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import useSharedStyles from "components/useSharedStyles";
import Select from "components/ui/Select";
import { GodmodeConfirmDialog } from "pages/godmode/shared/GodmodeConfirmDialog";
import useDialogState from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import UploadButton from "components/form/UploadButton";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";

interface IResourcesIndexFormValues {
    search: string;
    filter_unit?: string;
    filter_type?: ResourceType;
    filter_published?: string;
}

const validationSchema = Yup.object().shape<IResourcesIndexFormValues>({
    search: Yup.string().required('School name is required')
});

const resourceTypeMap : ResourceTypeMap = {
    'all': 'All',
    'activities': 'Handouts/Activities',
    'materials': 'Classroom Materials',
    'teacher_video': 'Teacher Video',
    'student_video': 'Student Video',
    'planning': 'Planning Materials',
    'certificates': 'Certificates',
    'creative_project_videos': 'Creative Project Videos',
    'coding_concept_video': 'Coding Concept Video',
    'other': 'Other',
    'v2_course_materials': 'V2 Course Materials'
}

export const ResourcesIndex: React.FC = () => {
    const sharedClasses = useSharedStyles()
    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            search: "",
            filter_unit: "all",
            filter_type: "all",
            filter_published: "Published"
        },
        validationSchema,
        onSubmit: async () => {
            // Do nothing, we will handle updating the table based onChange values via the ResourcesProvider context
            // We do this because filtering does not actually create another request, but simply filters data we've
            // already gotten from a previous request. Because of this, we don't need to limit updates to the table 
            // to only when the form is submitted, or even create a submit button for it
        }
    })

    return (
        <GodmodePageWrapper title="Resources" loading={false}>
            <ResourcesProvider>
                <Box className={sharedClasses?.vspacing2} display="flex" alignItems="flex-start" flexDirection="row">
                    <Box width="75%">
                        <FormikProvider value={form}>
                            <ResourcesIndexForm />
                        </FormikProvider>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="flex-end" width="25%">
                        <ResourcesIndexAddNew />
                    </Box>
                </Box>
                <ResourcesIndexTable resetForm={() => form.resetForm()} />
            </ResourcesProvider>
        </GodmodePageWrapper>
    )
}

const ResourcesIndexAddNew: React.FC = () => {
    const defaultResource = {
        id: 0,
        name: '',
        description: '',
        resource_type: null,
        unit_id: '',
        resource_url: '',
        resource_thumb_url: '',
        is_archived: false
    }
    return (
        <>
            <ResourceIndexResourceDialog resource={defaultResource} />
        </>
    )
}

const ResourcesIndexForm: React.FC = () => {
    const sharedClasses = useSharedStyles()
    const {values, touched, errors, handleChange} = useFormikContext<IResourcesIndexFormValues>()
    const { setSearch, filters, setFilters} = useResourcesContext()
    
    return (
        <Form className={sharedClasses?.vspacing2}>
            <Box>
                <TextField
                    value={values.search}
                    onChange={(e) => {
                        handleChange(e)
                        setSearch(e.target.value)
                    }}
                    error={touched.search && !!errors.search}
                    helperText={touched.search && errors.search}
                    name="search"
                    id="search"
                    placeholder="Search for a Resource"
                    fullWidth
                />
            </Box>
            <Box className={sharedClasses?.hspacing2} display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Select
                    value={values.filter_unit}
                    onChange={(e) => {
                        handleChange(e)
                        // Use existing values, then update only filter_unit. Using as string here is safe
                        // As the select will ALWAYS set a string value, and we default to 'all'
                        setFilters({...filters, filter_unit: e.target.value as string }) 
                    }}
                    error={touched.filter_unit && !!errors.filter_unit}
                    name="filter_unit"
                    id="filter_unit"
                    label="Filter by Unit"
                    fullWidth
                >
                    <MenuItem selected value="all">All</MenuItem>
                </Select>
                <Select
                    value={values.filter_type}
                    onChange={(e) => {
                        handleChange(e)
                        // Use existing values, then update only filter_type
                        setFilters({...filters, filter_type: e.target.value as ResourceType })
                    }}
                    error={touched.filter_type && !!errors.filter_type}
                    name="filter_type"
                    id="filter_type"
                    label="Filter by Type"
                    fullWidth
                >
                    {Object.keys(resourceTypeMap).map((key) => {
                        return <MenuItem key={key} value={key} selected={values.filter_type === key}>{resourceTypeMap[key as ResourceType]}</MenuItem>
                    })}
                </Select>
                <Select
                    value={values.filter_published}
                    onChange={(e) => {
                        handleChange(e)
                        setFilters({...filters, filter_published: e.target.value as ResourcePublishStatus })
                    }}
                    error={touched.filter_published && !!errors.filter_published}
                    name="filter_published"
                    id="filter_published"
                    label="Filter by Publish Status"
                    fullWidth
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem selected value="Published">Published</MenuItem>
                    <MenuItem value="Archived">Archived</MenuItem>
                </Select>
            </Box>
        </Form>
    )
}

const ResourcesIndexTable: React.FC<{resetForm: () => void}> = ({resetForm}) => {
    const sharedClasses = useSharedStyles()
    const { resources, loading, error, resetFilters, emptyAfterFilter } = useResourcesContext()

    if (error) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" p={4}>
                <Typography variant="body1">Unable to load resources. Refresh the page to try again.</Typography>
            </Box>
        )
    }

    if (emptyAfterFilter) {
        return (
            <Box className={sharedClasses?.vspacing4} display="flex" alignItems="center" justifyContent="center" width="100%" p={4} flexDirection="column">
                <Typography variant="body1">No resources match your search or filters.</Typography>
                <Button
                    variant="contained"
                    color="blue"
                    startIcon={<FontAwesomeIcon icon={faRedoAlt} />}
                    onClick={() => {
                        resetForm()
                        resetFilters()
                    }}
                >
                    Reset Filters
                </Button>
            </Box>
        )
    }

    if (!emptyAfterFilter && (!resources || resources.length < 1 || loading)) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" p={4}>
                <CircularProgress />
            </Box>
        )
    }
    
    return (
        <Box>
            <TableContainer>
                <TableHead>
                    <TableCell>Thumbnail</TableCell>
                    <TableCell>Resource Name</TableCell>
                    <TableCell>Resource Type</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Actions</TableCell>
                </TableHead>
                <TableBody>
                    {resources?.map((resource, index) => <ResourcesIndexTableItem key={index} {...resource} />)}
                </TableBody>
            </TableContainer>
        </Box>
    )
}

const ResourcesIndexTableItem: React.FC<IResource> = (resource) => {
    return (
        <TableRow>
            <TableCell>
                <Box display="flex" alignItems="center" justifyContent="center">
                {resource?.resource_thumb_url &&
                    <img loading="lazy" style={{maxWidth: 50}} width="50" src={resource?.resource_thumb_url} alt={resource?.name} />
                }
                {!resource?.resource_thumb_url &&
                    <FontAwesomeIcon icon={faFileCircleQuestion} />
                }
                </Box>
            </TableCell>
            <TableCell>
                <ResourceIndexTableItemNameSection {...resource} />
            </TableCell>
            <TableCell>{resource?.resource_type && resourceTypeMap[resource?.resource_type]}</TableCell>
            <TableCell>{resource?.unit_id}</TableCell>
            <TableCell><ResourcesIndexTableItemActions {...resource} /></TableCell>
        </TableRow>
    )
}

const ResourceIndexTableItemNameSection: React.FC<IResource> = (resource) => {
    const sharedClasses = useSharedStyles()
    const [copied, setCopied] = useState<boolean>(false)
    const [showDetails, setShowDetails] = useState<boolean>(false)
    
    useEffect(() => {
        if (!copied) {
          return
        }
        setTimeout(() => {
            setCopied(false)
        }, 5000)
    }, [copied])
    
    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(resource?.resource_url)
        .then(() => {
            setCopied(true)
        })
        .catch((err) => {
            console.error(err);
        })
    }

    return (
        <Box className={sharedClasses.vspacing2}>
            <Typography variant="body1">{resource?.name}</Typography>
            <Box className={sharedClasses.hspacing2}>
                {resource?.resource_url &&
                    <Button
                        onClick={handleCopyToClipboard}
                        startIcon={<FontAwesomeIcon icon={copied ? faCheckCircle : faCopy} />}
                        size="small"
                        variant="outlined"
                        color="blue"
                    >
                        {copied ? 'Copied!' : 'Copy'}
                    </Button>
                }
                <Button
                    onClick={() => {
                        setShowDetails(currentValue => !currentValue)
                    }}
                    startIcon={<FontAwesomeIcon icon={showDetails ? faEyeSlash : faEye} />}
                    size="small"
                    variant="outlined"
                    color="orange"
                >
                    {showDetails ? 'Hide Details' : 'Show Details'}
                </Button>
            </Box>
            {showDetails &&
                <Box className={sharedClasses.vspacing2}>
                    <Box className={sharedClasses.vspacing1}>
                        <Typography variant="body1"><strong>Description:</strong></Typography>
                        <Typography variant="body1">{resource?.description}</Typography>
                    </Box>
                    <Box className={sharedClasses.vspacing1}>
                        <Typography variant="body1"><strong>Public Link:</strong></Typography>
                        <Button
                            variant="text"
                            target="_blank"
                            href={resource?.resource_url}
                        >
                            {resource?.resource_url}
                        </Button>
                    </Box>
                </Box>
            }
        </Box>
    )
}

const ResourcesIndexTableItemActions: React.FC<IResource> = (resource) => {
    const sharedClasses = useSharedStyles()
    return (
        <Box display="flex" alignItems="center" flexDirection="row" className={sharedClasses.hspacing1}>
            <Box className={sharedClasses.vspacing1}>
                <Button
                    startIcon={<FontAwesomeIcon icon={faDownload} />}
                    color="blue"
                    variant="contained"
                    href={resource?.resource_url}
                    target="_blank"
                    fullWidth
                >
                    Download
                </Button>
                <ResourceIndexResourceDialog resource={resource} isEditing={true} />
            </Box>
            <Box className={sharedClasses.vspacing1}>
                <ResourceIndexTableItemArchive {...resource} />
                <ResourceIndexTableItemDelete {...resource} />
            </Box>
        </Box>
    )
}

const ResourceIndexResourceDialog: React.FC<{resource: IResource, isEditing?: boolean}> = ({resource, isEditing = false}) => {
    const dialogState = useDialogState()
    const alert = useAlert()
    const { mutate } = useResourcesContext()

    const form = useFormik({
        enableReinitialize: true,
        initialValues: resource,
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Name is required'),
            description: Yup.string().required('Description is required'),
            resource_type: Yup.string().required('Resource type is required'),
            resource_url: Yup.string().required('Resource URL is required')
        }),
        onSubmit: async () => {
            if (isEditing) {
                handleEditSubmit()
                return
            }
            // If we're not editing an existing resource (isEdit = true) then we'll add a new one on form submit
            handleAddSubmit()
        }
    })

    const handleEditSubmit = () => {
        // Here we submit the values of the original resource using the spread operator, and overwrite any
        // updated values with form?.values
        justFetch(endpoints.resourceById(resource?.id), 'PUT', {
            ...resource,
            ...form?.values,
            resource_type: resourceTypeMap[form?.values?.resource_type as ResourceType]
        })
        .then((res) => {
            if (res.ok) {
                mutate()
                dialogState.onClose()
                alert.success('Resource updated successfully!')
                form.resetForm()
                return
            }
            alert.error('Unable to update resource. Please try again')
        })
        .catch((error) => {
            console.log(error)
            alert.error('Unable to update resource. Please try again.')
        })
    }

    const handleAddSubmit = () => {
        justFetch(endpoints.resources, 'POST', {
            ...form?.values,
            resource_type: resourceTypeMap[form?.values?.resource_type as ResourceType]
        })
        .then((res) => {
            if (res.ok) {
                mutate()
                dialogState.onClose()
                alert.success('Resource added successfully!')
                form.resetForm()
                return
            }
            
            alert.error('Unable to add Resource. Please try again')
        })
        .catch((error) => {
            console.log(error)
            alert.error('Unable to add Resource. Please try again.')
        })
    }

    return (
        <>
            <Dialog open={dialogState.open}>
                <DialogTitle>{isEditing ? `Edit ${resource?.name}` : 'Add New Resource'}</DialogTitle>
                <DialogContent>
                    <FormikProvider value={form}>
                        <ResourcesForm isEditing={isEditing} />
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <Button
                            startIcon={<FontAwesomeIcon icon={faXmarkCircle} />}
                            variant="contained"
                            color="orange"
                            onClick={() => {
                                dialogState.onClose()
                                form.resetForm()
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            startIcon={<FontAwesomeIcon icon={faSave} />}
                            variant="contained"
                            color="green"
                            onClick={() => {
                                form.submitForm()
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Button
                startIcon={<FontAwesomeIcon icon={isEditing ? faPencil : faPlusCircle} />}
                color="green"
                variant="contained"
                onClick={dialogState.handleOpen}
                fullWidth={isEditing}
            >
                {isEditing ? 'Edit' : 'Add New Resource'}
            </Button>
        </>
    )
}

const ResourcesForm: React.FC<{isEditing?: boolean}> = ({isEditing = false}) => {
    const sharedClasses = useSharedStyles()
    const { values, touched, errors, handleChange, isSubmitting } = useFormikContext<IResource>()

    return (
        <Form className={sharedClasses.vspacing2}>
            <TextField
                value={values.name}
                onChange={handleChange}
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                name="name"
                id="name"
                placeholder="Resource Name"
                label="Resource Name"
                fullWidth
                required
                disabled={isSubmitting}
            />
            <TextField
                value={values.description}
                onChange={handleChange}
                error={touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                name="description"
                id="description"
                placeholder="Resource Description"
                label="Resource Description"
                minRows={5}
                multiline={true}
                disabled={isSubmitting}
            />
            <Select
                value={values.unit_id}
                onChange={handleChange}
                error={touched.unit_id && !!errors.unit_id}
                name="unit_id"
                id="unit_id"
                label="Resource Unit"
                placeholder="Select a Unit for this resource"
                fullWidth
                disabled={isSubmitting || true}
            >   
                <MenuItem selected disabled>Select a Unit for this resource</MenuItem>
                <MenuItem value="all">All</MenuItem>
            </Select>
            <Select
                value={values.resource_type}
                onChange={handleChange}
                error={touched.resource_type && !!errors.resource_type}
                name="resource_type"
                id="resource_type"
                label="Resource Type"
                placeholder="Select a Resource Type"
                fullWidth
                required
                disabled={isSubmitting}
            >
                <MenuItem selected disabled>Select a Type for this resource</MenuItem>
                {Object.keys(resourceTypeMap).map((key) => {
                    if (key === 'all') {
                      return null
                    }
                    return <MenuItem key={key} value={key} selected={values.resource_type === key}>{resourceTypeMap[key as ResourceType]}</MenuItem>
                })}
            </Select>
            {isEditing &&
                <Alert severity="warning">Uploading a new Resource or Thumbnail will replace the current one for this resource</Alert>
            }
            <ResourceIndexTableItemEditFormUpload fieldName="resource_url" fieldLabel="Resource URL" disabled={!values?.resource_type || values?.resource_type.length < 1} />
            {(!values?.resource_type || values?.resource_type.length < 1) &&
                <Alert severity="warning">You must select a resource type before uploading a resource</Alert>
            }
            <ResourceIndexTableItemEditFormUpload fieldName="resource_thumb_url" fieldLabel="Resource Thumbnail URL" />
        </Form>
    )
}

const ResourceIndexTableItemEditFormUpload: React.FC<{fieldName: string, fieldLabel: string, disabled?: boolean}> = ({fieldName, fieldLabel, disabled}) => {
    const sharedClasses = useSharedStyles()
    const { values, setFieldValue, touched, errors, handleChange, isSubmitting } = useFormikContext<IResource>()

    return (
        <Box className={sharedClasses.hspacing2} display="flex" alignItems="flex-end" justifyContent="space-between" width="100%">
            <TextField
                value={values[fieldName as keyof IResource]}
                onChange={handleChange}
                error={touched[fieldName as keyof IResource] && !!errors[fieldName as keyof IResource]}
                helperText={touched[fieldName as keyof IResource] && errors[fieldName as keyof IResource]}
                name={fieldName}
                id={fieldName}
                placeholder={fieldLabel}
                label={fieldLabel}
                fullWidth
                disabled={isSubmitting || disabled}
            />
            <Box mb="2px">
                <UploadButton
                    id={fieldName}
                    maxFileSize={10000000}
                    allowedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'text/csv']}
                    buttonText="Upload"
                    setImageURL={(url) => setFieldValue(fieldName, url)}
                    uploadPath={fieldName === 'resource_thumb_url' ? 'thumbnail' : values?.resource_type}
                    disabled={isSubmitting || disabled}
                />
            </Box>
        </Box>
    )
}

const ResourceIndexTableItemArchive: React.FC<IResource> = (resource) => {
    const alert = useAlert()
    const { mutate } = useResourcesContext()
    const archiveString = resource?.is_archived ? 'Unarchive' : 'Archive'
    
    const handleArchive = async () => {
        justFetch(endpoints.resourceArchive, 'POST', {
            resource_id: resource?.id,
            is_archived: !resource?.is_archived
        })
        .then(async (res) => {
            if (res.ok) {
                alert.success(`Successfully ${archiveString.toLocaleLowerCase()}d resource!`)
                mutate()
                return
            }
            alert.error(`Unable to ${archiveString.toLocaleLowerCase()} resource. Please try again`)
            console.log(await res.json())
        })
        .catch((error) => {
            alert.error(`Unable to ${archiveString.toLocaleLowerCase()} resource. Please try again.`)
            console.log(error)
        })
    }

    return (
        <Button
            startIcon={<FontAwesomeIcon icon={faArchive} />}
            color="orange"
            variant="contained"
            onClick={handleArchive}
            fullWidth
        >
            {archiveString}
        </Button>
    )
}

const ResourceIndexTableItemDelete: React.FC<IResource> = (resource) => {
    const dialogState = useDialogState()
    const alert = useAlert()
    const { mutate } = useResourcesContext()

    const handleConfirm = async () => {
       justFetch(endpoints.resourceById(resource?.id), 'DELETE')
       .then(async (res) => {
            if (res.ok) {
                mutate()
                alert.success('Resource deleted successfully!')
                dialogState.onClose()
                return
            }
            alert.error('Resource could not be deleted. Please try again')
            console.log(await res.json())
       })
       .catch((error) => {
            alert.error('Resource could not be deleted. Please try again.')
            console.log(error)
       })
    }

    return (
        <>
            <GodmodeConfirmDialog
                dialogState={dialogState}
                dialogTitle={
                    <Typography variant="h2">Are you sure you want to delete this resource?</Typography>
                }
                dialogContent={
                    <Typography variant="body1">You are attempting to delete resource <strong>"{resource?.name}"</strong>. This action cannot be undone.</Typography>
                }
                onConfirm={handleConfirm}
                variant="danger"
            />
            <Button
                startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                color="red"
                variant="contained"
                onClick={() => dialogState.handleOpen()}
                fullWidth
            >
                Delete
            </Button>
        </>
    )
}