import { useState, Fragment } from 'react';
import OnboardingLayout from './shared/OnboardingLayout';
import { useHistory, useParams } from 'react-router-dom';
import useImportStudent from 'mutations/useImportStudent';
import useParentInit, { reload } from 'loaders/useParentInit';
import useCurrentUser from 'loaders/useCurrentUser';
import { useAlertDispatch } from 'context/AlertProvider';
import find from 'lodash/find';
import Amplitude from 'utils/Amplitude';
import { useEffect } from 'react';
import { useRedirectToLogin } from 'context/AuthProvider';
import { routes } from 'routes';
import { IStudent } from 'types/IStudent';

interface RouteParams {
  studentCode: string;
}

export default function LinkPlayerPage() {
  const { studentCode: studentCodeFromURL } = useParams<RouteParams>();
  const [ codeToAdd, setCodeToAdd] = useState('');
  const [ submitting, setSubmitting] = useState(false);
  const { students, loading } = useParentInit();
  const { currentUser } = useCurrentUser();
  const importStudent = useImportStudent();
  const dispatch = useAlertDispatch();
  const history = useHistory();
  const { redirectToLogin } = useRedirectToLogin();

  useEffect(() => {
    Amplitude.track('(Parent Onboarding) Viewed Link Player Page');
  }, []);

  useEffect(() => {
    if (!currentUser.is_parent && !loading) {
      redirectToLogin();
    }
  }, [currentUser, loading]);

  useEffect(() => {
    if (studentCodeFromURL){
      if (findExistingStudent(studentCodeFromURL) === undefined){
        //setCodeToAdd(studentCodeFromURL);
        submitForm(studentCodeFromURL);
      }
    }
  }, [studentCodeFromURL]);  

  // AB testing for intro page.
  // This A/B test exists in AddPlayer and BaseAuthRoute as well.
  const to = routes.parentOnboarding.intro

  const alertId = () => '_' + Math.random().toString(36).substr(2, 9);
  const autoHideAlert = (id: string) => setTimeout(() => {
    dispatch({ type: 'removeAlert', id: id });
  }, 3000);

  const findExistingStudent = (studentCode: string) : IStudent | undefined => {
    return students?.find(student => student.student_code.toLowerCase().replace('_pi','') === studentCode.toLowerCase());
  }

  const addLearner = async (codeParam: string) => {
    setSubmitting(true);
    
    const existingStudent = findExistingStudent(codeParam);

    if (existingStudent) {
      try {
        await reload()
        dispatch({
          id: 'addStudent',
          type: 'success',
          title: 'Student Imported!',
          message: `${existingStudent.name}’s profile is linked.`,
        });
        setTimeout(() => {
          dispatch({ type: 'removeAlert', id: 'addStudent' });
        }, 3000);
        setCodeToAdd('');
        return true
      } catch (err) {

      }
      setSubmitting(false);
      return false;
    }

    try {
      const student = await importStudent({
        student_codes: [codeParam],
        teacher_id: currentUser.id,
      })

      if (!student.error) {
        if (
          student.message === 'This profile already imported by another user' ||
          student.error_type === 'KodableStandardError'
        ) {
          let id = alertId();
          dispatch({
            id: id,
            type: 'error',
            title: 'Student Not Imported!',
            message: student.message,
          });
          autoHideAlert(id);
          setSubmitting(false);
          return false;
        }

        try {
          await reload()
          dispatch({
            id: 'addStudent',
            type: 'success',
            title: 'Student Imported!',
            message: `${
              find(student, {student_code: codeParam.toLowerCase()}).name
            }’s profile is linked.`,
          });
          setTimeout(() => {
            dispatch({type: 'removeAlert', id: 'addStudent'});
          }, 3000);
          setSubmitting(false);
          setCodeToAdd('');
          return true
        } catch (err) {
          setSubmitting(false);
          let id = alertId();
          dispatch({
            id: id,
            type: 'error',
            title: 'Student Not Imported!',
            message: 'There was an error importing this student.',
          });
          autoHideAlert(id);
          return false
        }
      } else {
        setSubmitting(false);
        let id = alertId();
        dispatch({
          id: id,
          type: 'error',
          title: 'Student Not Imported!',
          message: 'Student code is incorrect.',
        });
        autoHideAlert(id);
        return false
      }
    } catch (err) {

    }
    return false
  }

  const submitForm = async (codeParam: string) => {
    // just skip to next page if at max students
    if (students && students.length === 4) {
      history.push(to);
      return;
    }

    if (codeParam !== '') {
      const success = await addLearner(codeParam)
      if (success) {
        history.push(to);
      }
      return;
    }

    if (codeParam === '' && students && students.length > 0) {
      history.push(to);
      return;
    }

    if (codeParam === '') {
      return;
    }
  }

  if (loading) {
    return null;
  }

  return (
    <OnboardingLayout
      imgName="spaceship"
      imgSrc="/images/onboarding/link.jpg"
      title="Link with School Profile"
      activeSteps={1}>
      {students && students.length > 0 ? (
        <div className="mt-8 grid grid-rows-2 gap-y-4 ml-4">
          {students.map((student) => (
            <div
              className="flex items-center font-bold text-gray-600 mr-16"
              key={student.id}>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="w-5 h-5 text-kodable-green mr-2">
                <path
                  fill="currentColor"
                  d="M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z"></path>
              </svg>{' '}
              {student.name}
            </div>
          ))}
        </div>
      ) : (
        <Fragment>
          <p className="mt-4">See their school projects and progress</p>
          <p>Prizes earned at school will show up in their Home Account too!</p>
        </Fragment>
      )}
      {students && students.length <= 3 && (
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <div className="mt-8 flex flex-col space-y-2 max-w-xs ml-2">
            <input
              className="border rounded text-gray-600 border-gray-400 text-lg px-3 py-2 bg-gray-50 block mb-4"
              type="text"
              placeholder="Enter Your Child's Parent Code"
              name="code"
              value={codeToAdd}
              onChange={(e) => {
                setCodeToAdd(e.target.value);
              }}
            />
          </div>

          <p className="mt-4">
            You can get the Parent Code from your child’s teacher
          </p>
        </form>
      )}
      <div className="flex items-center mt-24">
        {students && students.length <= 3 ? (
          <button
            disabled={codeToAdd === '' || submitting}
            onClick={()=>addLearner(codeToAdd)}
            className="bg-kodable-blue hover:bg-kodable-blue-dark active:bg-kodable-blue-darker mr-6 text-white justify-center items-center font-semibold text-lg flex px-12 py-2 rounded shadow-kodable text-center disabled:opacity-50 disabled:cursor-not-allowed">
            Add another learner
          </button>
        ) : (
          <div className="w-64"></div>
        )}
        <button
          disabled={submitting || (students?.length === 0 && codeToAdd === '')}
          className="bg-kodable-orange hover:bg-kodable-orange-dark active:bg-kodable-orange-darker text-white items-center font-semibold text-lg px-4 flex py-2 rounded shadow-kodable text-center w-32 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={()=>submitForm(codeToAdd)}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="w-6 h-6 mr-4">
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
          </svg>
          Next
        </button>
      </div>
    </OnboardingLayout>
  );
}
