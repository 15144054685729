import useSWR from 'swr';
import useCurrentUser from 'loaders/useCurrentUser';
import endpoints from "../endpoints";

export default function useSubscriptionBilling() {
  const { currentUser } = useCurrentUser();

  const {
    data,
    error,
    mutate,
    isValidating: subscriptionBillingValidation,
  } = useSWR(
    endpoints.users.subscription.billing(currentUser.id),
    {
      dedupingInterval: 200000,
    }
  );

  return {
    subscriptionBilling: data,
    error: error,
    loading: subscriptionBillingValidation,
    mutate,
  };
}
