import React, {useMemo} from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  useTheme
} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../../ui/buttons/Button";
import {faDownload, faTrash} from "@fortawesome/free-solid-svg-icons";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Amplitude from "../../../../utils/Amplitude";
import {IMazeData} from "../../../../types/IMazeData";
import MazeImage from "../../../misc/MazeImage";
import MazePrintout from "../../../../pages/printouts/student-creations/MazePrintout";
import {format} from "date-fns";

export interface DialogMazeDetailProps {
  student: { student_code: string, name: string }
  onClose: () => void
  onDelete: () => void
  maze: IMazeData
  open: boolean
}

const useStyles = makeStyles((theme) => ({
  name: {
    fontFamily: 'monospace',
    marginBottom: 15
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const MazeDetailsDialog: React.FC<DialogMazeDetailProps> = ({ open, student, onClose, onDelete, maze}) => {
  const theme = useTheme()
  const today = new Date();
  const dateStr = useMemo(() => {
    const date = new Date(maze.created_at);

    return format(date, date.getFullYear() !== today.getFullYear() ? 'MMMM d, yyyy' : 'MMMM d')
  }, [maze.created_at]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} marginBottom={2}>
          {maze.id &&
            <MazeImage
              width={'100%'}
              mazeId={maze.id}
              studentCode={student.student_code}
            />
          }
        </Box>
        <Typography>Maze created {dateStr} by {student.name}</Typography>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button variant={'outlined'} color={'blue'} startIcon={<FontAwesomeIcon icon={faDownload} />} style={{marginRight: 20}}>
            <PDFDownloadLink
              style={{textDecoration: 'none', color: theme.palette.primary.main}}
              document={
                <MazePrintout mazeId={maze.id} name={maze.title} createdAt={maze.created_at}/>
              }
              fileName={`Maze - ${maze.title} by ${student.name}`}
            >
            {({loading}) => {
                Amplitude.track('Generated Maze Handout');
                return (loading ? 'Loading...' : 'Download')
              }
            }
          </PDFDownloadLink>
          </Button>
          <Button variant={'outlined'} color={'red'} startIcon={<FontAwesomeIcon icon={faTrash} />} onClick={onDelete}>Delete</Button>
        </Box>
        <Button variant={'contained'} color={'primary'} onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
};

export default MazeDetailsDialog;
