import { makeStyles } from "@material-ui/core";

const useSharedStyles = makeStyles(theme => ({
  MuiAlertFullWidth: {
    '& > .CoursesListAlertMessage': {
      width: '100%'
    }
  },
  vspacing1: {
    '& > *': {
      marginBottom: `${theme.spacing(1)}px !important`
    },
    '& > *:last-child': {
      marginBottom: `0 !important`
    }
  },
  vspacing2: {
    '& > *': {
      marginBottom: `${theme.spacing(2)}px !important`
    },
    '& > *:last-child': {
      marginBottom: `0 !important`
    }
  },
  vspacing4: {
    '& > *': {
      marginBottom: `${theme.spacing(4)}px !important`
    },
    '& > *:last-child': {
      marginBottom: `0 !important`
    }
  },
  hspacing1: {
    '& > *': {
      marginRight: `${theme.spacing(1)}px !important`
    },
    '& > *:last-child': {
      marginRight: `0 !important`
    }
  },
  hspacing2: {
    '& > *': {
      marginRight: `${theme.spacing(2)}px !important`
    },
    '& > *:last-child': {
      marginRight: `0 !important`
    }
  },
  hspacing4: {
    '& > *': {
      marginRight: `${theme.spacing(4)}px !important`
    },
    '& > *:last-child': {
      marginRight: `0 !important`
    }
  },
  hmargin2: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  vmargin2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  simpleMaxWidth: {
    maxWidth: 600
  },
  dangerButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  hoverCursorPointer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  hoverEnlarge: {
    transition: 'transform 0.25s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.01)'
    }
  },
  hoverEnlarge2: {
    transition: 'transform 0.25s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.02)'
    }
  },
  tableRowHoverButton: {
    '& button': {
      visibility: 'hidden'
    },
    '&:hover': {
      cursor: 'pointer'
    },
    '&:hover button': {
      visibility: 'visible'
    }
  },
  tableRowHoverButtonOpenMenu: {
    '& button': {
      visibility: 'visible'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  highlightColor: {
    color: '#27a9e1 !important'
  },
  highlightBgColor: {
    backgroundColor: '#27a9e1 !important'
  },
  bgColorWhite: {
    backgroundColor: 'white'
  },
  hide: {
    display: 'none !important'
  }
}));

export default useSharedStyles;