import { Box, Grid } from "@material-ui/core"
import { IRouteParams } from "AppRoutes"
import PageHeader from "components/ui/PageHeader"
import useSharedStyles from "components/useSharedStyles"
import endpoints from "endpoints"
import { useRouteMatch } from "react-router-dom"
import useSWR from "swr"
import { AccountDetailsBlock } from "./AccountDetailsBlock"
import { AssignedAdminsBlock } from "./AssignedAdminsBlock"
import { BillingContactBlock } from "./BillingContactBlock"
import { ClassesBlock } from "./ClassesBlock"
import { CleverDetailsBlock } from "./CleverDetailsBlock"
import { IGodmodeUserProfile } from "../IGodmodeUserProfile"
import { LinkedTeachersBlock } from "./LinkedTeachersBlock"
import { QuotesBlock } from "./QuotesBlock"
import { StudentsBlock } from "./StudentsBlock"
import { SubscriptionDetailsBlock } from "./SubscriptionDetailsBlock"
import {AmbassadorDetailsBlock} from "./AmbassadorDetailsBlock";
import React from "react";

export const AccountPage: React.FC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const sharedClasses = useSharedStyles();
  const { data: teacher } = useSWR<IGodmodeUserProfile>(endpoints.godmode.teacher(params.teacherId));

  if (!teacher) {
    return null;
  }

  return <Box>
    <PageHeader inAppBar title="View Teacher" />
    <Box className={sharedClasses.vspacing2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AccountDetailsBlock teacher={teacher} />
        </Grid>
        <Grid item xs={6}>
          {teacher.is_parent ? <SubscriptionDetailsBlock teacher={teacher} /> : <BillingContactBlock teacher={teacher} />}
        </Grid>
      </Grid>
      <QuotesBlock teacher={teacher} />
      {teacher.referrals?.is_ambassador && <AmbassadorDetailsBlock teacher={teacher} />}
      {!teacher.is_parent && <CleverDetailsBlock teacher={teacher} />}
      {!teacher.is_parent
          && teacher.organization_profile?.administrator_id === teacher.id
          && <AssignedAdminsBlock teacher={teacher} />}
      {!teacher.is_parent && teacher.is_administrator && <LinkedTeachersBlock teacher={teacher} />}
      {!teacher.is_parent && <ClassesBlock teacher={teacher} />}
      <StudentsBlock teacher={teacher} />
    </Box>
  </Box>;
}