import React, { useState, useEffect } from 'react';
import { assembleMazeUri } from './FuzzImage';

interface MazeImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  mazeId: string;
  studentCode?: string;
}

export default function MazeImage({ mazeId, studentCode, ...rest }: MazeImageProps) {
  const [imageSrc, setImageSrc] = useState<string>(assembleMazeUri(mazeId));

  useEffect(() => {
    setImageSrc(assembleMazeUri(mazeId));
  }, [mazeId, studentCode]);

  return <img alt="maze" {...rest} src={imageSrc} />;
}
