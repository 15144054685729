import Modal from 'components/modal/Modal';
import React from 'react';
import ReactPlayer from 'react-player';

export interface SolutionModalProps {
  closeModal: () => void;
  imgSrc: string;
  alt: string;
  video?: string;
}

const SolutionModal: React.FC<SolutionModalProps> = ({ closeModal, imgSrc, alt, video }) => {
  return (
    <Modal closeModal={closeModal}>
      <div className="pb-4">
        <div className="">
          {video ? (
            <ReactPlayer url={video} controls width="100%" height="100%" />
          ) : (
            <img src={imgSrc} alt={alt} className="object-cover rounded-t-lg" />
          )}
        </div>
        <div className="flex justify-between items-center">
          <div className="m-4">
            <div className="text-gray-700 font-bold text-lg">{alt}</div>
            <p className="text-gray-600 text-sm">Solution for level {alt}</p>
          </div>
          <div className="m-4">
            <button
              onClick={closeModal}
              className="flex items-center justify-center bg-kodable-blue hover:bg-kodable-blue-dark active:bg-kodable-blue-darker px-3 py-2 text-sm rounded text-white">
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SolutionModal;
