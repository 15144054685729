import endpoints from 'endpoints';
import useSWR, { mutate } from 'swr';
import {IStudent} from "../types/IStudent";

const useParentInit = () => {
  const { data, mutate } = useSWR<{students: IStudent[]}>(endpoints.parent_init);
  
  return {
    data,
    loading: !data,
    students: data?.students,
    mutate
  };
}

export function reload() {
  return mutate(endpoints.parent_init);
}

export default useParentInit