import Modal from 'components/modal/Modal';
import React from 'react';
import Loading from 'components/loading/Loading';

export interface PrintModalProps {
  type: string;
  closeModal?: () => void
}

const PrintModal: React.FC<PrintModalProps> = ({ type, closeModal = () => {} }) => {
  return (
    <Modal closeModal={closeModal}>
      <div className="pb-4 pt-5">
        <div className="my-8">
          <Loading />
          <p className="text-xl text-center">Generating {type}</p>
          <p className="text-sm text-center">This may take a minute...</p>
        </div>
      </div>
    </Modal>
  );
};

export default PrintModal;
