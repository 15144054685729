export const formatCurrency = (value?: number) => {
    if (!value || value === 0) {
        return '$0.00'
    }
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export const formatThousandsCommas = (value?: number) => {
    if (!value || value === 0) {
        return '0'
    }
    return value.toLocaleString('en-US')
}