import {TableCell, TableRow } from "@material-ui/core";
import {formatCurrency} from "pages/godmode/utils/numbers";
import { ISubscriptionRenewal } from "./UpcomingRenewalsTable";
import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { formatDateToUSLocale } from "pages/godmode/utils/time";


const UpcomingRenewalsRenewedTableRow: React.FC<{subscription: ISubscriptionRenewal}> = ({subscription}) => {
  const updatedAtDate = useMemo(() => {
    if (subscription?.teacher?.updated_at) {
      const date = new Date(subscription?.teacher?.updated_at)
      return date;
    }
    return null
  }, [subscription])

  return (
    <TableRow>
      <TableCell>{subscription?.teacher?.plan !== 'Free' ? <FontAwesomeIcon color="green" icon={faCheckCircle} /> : <FontAwesomeIcon color="red" icon={faXmarkCircle} />}</TableCell>
      <TableCell>{subscription?.expired?.license_count}</TableCell>
      <TableCell>Premium {subscription?.expired?.has_curriculum ? "+" : ""}</TableCell>
      <TableCell>{formatCurrency(subscription?.expired?.contract_amount)}</TableCell>
      <TableCell>{subscription?.renewal?.license_count}</TableCell>
      <TableCell>Premium {subscription?.renewal?.has_curriculum ? "+" : ""}</TableCell>
      <TableCell>{formatCurrency(subscription?.renewal?.contract_amount)}</TableCell>
      <TableCell>{subscription?.teacher?.name} {`(${subscription?.teacher?.username})`}</TableCell>
      <TableCell>{updatedAtDate !== null ? formatDateToUSLocale(updatedAtDate) : ''}</TableCell>
    </TableRow>
  )
}

export default UpcomingRenewalsRenewedTableRow