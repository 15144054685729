import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme
} from "@material-ui/core"
import Cookies from 'universal-cookie';
import { saveAs } from 'file-saver';
import React, {ReactElement, useEffect, useState} from "react";
import Loading from "components/loading/Loading";
import Button from "components/ui/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faCheckCircle, faDownload} from "@fortawesome/free-solid-svg-icons";
import { Buffer } from 'buffer';
import ParentInvites, { TrackParentInviteMethod, TrackParentInviteMethodLocation } from "utils/ParentInvites";
import { IKlass } from "types/IKlass";
import ReactPDF, {BlobProvider, renderToFile} from "@react-pdf/renderer";
import DocumentProps = ReactPDF.DocumentProps;

const cookies = new Cookies();

interface PrintDialogProps {
  open?: boolean;
  onClose?: (...args: any[]) => any;
  handoutUrl?: string;
  handout?: ReactElement<DocumentProps, string | React.JSXElementConstructor<any>>;
  filename: string;
  v2?: boolean;
  isParentLetters?: boolean;
  klass?: IKlass | null;
  location?: TrackParentInviteMethodLocation;
}

export const handoutPdfUrl = (handoutUrl: string, v2: boolean) => `${process.env.REACT_APP_HANDOUT_URL}?`
  + `&kodable_token=${cookies.get('kodable_kode')}`
  + (v2 ? `&react_handout=${handoutUrl}` : `&handout=${handoutUrl}`)
  + `&env=${process.env.REACT_APP_HANDOUT_ENV}`
  + `&response=json`;

export const downloadPrintout = (handoutUrl: string, v2: boolean) => fetch(handoutPdfUrl(handoutUrl, v2))
    .then(res => res.json());

export const savePrintout = (filename: string, { pdf }: { pdf: string }) => saveAs(new Blob([Buffer.from(pdf.replace(/\s/g, ''), 'base64')], { type: 'application/pdf' }), `${filename}.pdf`);
export const savePrintoutFromBlob = (filename: string, { pdf }: { pdf: Blob }) => saveAs(pdf, `${filename}.pdf`);

/*
 This component either uses server resources to print a url to a pdf, or uses the client to render a pdf from a React element.
 Moving forward, it should only be used to print React elements to pdf by passing it a handout prop. The handoutUrl prop is deprecated
 and remains only for backwards compatibility with existing dashboard features.
 */
const PrintDialog: React.VFC<PrintDialogProps> = ({ open = false, onClose = () => { }, handoutUrl, handout, filename, v2 = true, isParentLetters = false, klass = null, location }) => {
  const [generating, setGenerating] = useState(false);
  const [printoutJson, setPrintoutJson] = useState<{ pdf: string }>();

  useEffect(() => {
    if (open) {
      setGenerating(true);
      if (handoutUrl) {
        downloadPrintout(handoutUrl, v2)
          .then((res) => {
            setPrintoutJson(res);
            setGenerating(false);
          });
      }

      ParentInvites.track(klass, TrackParentInviteMethod.clicked, location)
    }
  }, [open]);

  const handleDownloadGeneratedPrintout = () => {
    if (isParentLetters && klass) {
      ParentInvites.track(klass, TrackParentInviteMethod.download, location)
    }
    savePrintout(filename, printoutJson!)
    onClose()
  }

  const theme = useTheme();

  return <Dialog open={open}>
    {handout ? <BlobProvider document={handout}>
        {({blob, url, loading, error}) => {
          return (<>
            <DialogTitle>{loading ? "Generating printout..." : "Printouts ready!"}</DialogTitle>
            <DialogContent>
              {loading && <Box display="flex" alignItems="center" justifyContent="center">
                  <Loading />
              </Box>}
              {!loading && !error && <Box display="flex" justifyContent="center">
                  <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.green.main} size="7x" />
              </Box>}
              {!loading && error && <Typography color={"error"}>X</Typography>}
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
              {loading && <Button variant="outlined" onClick={onClose}>Cancel</Button>}
              {!loading && !error && blob && <Button variant="contained" color="primary" onClick={() => {
                ParentInvites.track(klass, TrackParentInviteMethod.download, location)
                savePrintoutFromBlob(filename, { pdf: blob })
                onClose()
              } }>Download</Button>}
            </DialogActions></>)
        }}
      </BlobProvider> : (<>
    <DialogTitle>{generating ? "Generating printout..." : "Printouts ready!"}</DialogTitle>
    <DialogContent>
      {generating && <Box display="flex" alignItems="center" justifyContent="center">
        <Loading />
      </Box>}
      {!generating && <Box display="flex" justifyContent="center">
        <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.green.main} size="7x" />
      </Box>}
    </DialogContent>
    <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
      {generating && <Button variant="outlined" onClick={onClose}>Cancel</Button>}
      {!generating && <Button variant="contained" color="primary" onClick={() => handleDownloadGeneratedPrintout()}>Download</Button>}
    </DialogActions></>)}
  </Dialog>
  // return open && generating ? <PrintModal type={`${filename}.pdf`} /> : null;
}

export default PrintDialog