import {IFuzzConfig} from "../../types/IFuzzData";
import {Box, makeStyles} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    width: 500,
    padding: 15
  },
  pre: {
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: 'pre',
    fontSize: '1rem',
    color: '#1F2937',
    overflowX: 'scroll',
  },
  clazzName: {
    color: '#E53E3E',
    marginTop: -5
  },
  constructor: {
    marginLeft: 9,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end"
  },
  constructorImg: {
    width: 28,
    height: 28,
    marginRight: 8
  },
  variable: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 5
  },
  functionContainer: {
    textAlign: "left",
    marginLeft: 15
  },
  functionContent: {
    marginLeft: 15,
    marginTop: 5
  },
  functionImg: {
    position: "relative",
    width: 25,
    height: 25,
  },
  item: {
    marginLeft: 35,
  },
  textOrange: {
    color: '#DD6B20',
  },
  textBlue: {
    color: '#3182CE',
  },
}));

export type FuzzCodeBlockItemOption = {
  type: string, // item?.name
  value: string // item?.value?.default
}

export interface FuzzCodeBlockConfig {
  name: string,
  body: string,
  eyes: string,
  mouth: string,
  items: FuzzCodeBlockItemOption[]
}

export const makeFuzzCodeBlockConfigFromFuzzConfig = (config: IFuzzConfig): FuzzCodeBlockConfig => {
  const result = {} as FuzzCodeBlockConfig

  result.name = config.clazzName
  result.body = config.properties.body.value.default
  result.eyes = config.properties.eyes.value.default
  result.mouth = config.properties.mouth.value.default

  result.items = config.dressFunctions.map(item => {
    return {
      type: item.name,
      value: item.value.default
    }
  })

  return result
}

const FuzzCodeBlock: React.VFC<FuzzCodeBlockConfig> = ({ name, eyes, body, mouth, items }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <pre className={classes.pre}>
        <div>class <span className={classes.clazzName}>{name}</span> extends <span
          className={classes.clazzName}>Fuzz</span> {'{'}
        </div>
        <div className={classes.functionContainer}>
          <div className={classes.variable}>
            <img alt='function' className={classes.constructorImg} src='/images/students/fuzz_code/function.png'/><span
            className={classes.textOrange}>constructor</span>() {'{'}
          </div>
          <div className={classes.functionContent}>
            <div className={classes.variable}>
              <img alt='function' className={classes.functionImg} src='/images/students/fuzz_code/body.png'/> this.<span
              className={classes.textBlue}>body</span> = new <span
              className={classes.clazzName}>Body</span>("{body}");
            </div>
            <div className={classes.variable}>
              <img alt='function' className={classes.functionImg} src='/images/students/fuzz_code/eyes.png'/> this.<span
              className={classes.textBlue}>eyes</span> = new <span
              className={classes.clazzName}>Eyes</span>("{eyes}");
            </div>
            <div className={classes.variable}>
              <img alt='function' className={classes.functionImg} src='/images/students/fuzz_code/mouth.png'/> this.<span
              className={classes.textBlue}>mouth</span> = new <span
              className={classes.clazzName}>Mouth</span>("{mouth}");
            </div>
          </div>
          <div className={classes.variable}>
            {'}'}
          </div>
        </div>
        <div className={classes.functionContainer}>
          <div className={classes.variable}>
            <img alt='function' className={classes.constructorImg} src='/images/students/fuzz_code/function.png'/><span
            className={classes.textOrange}>getDressed</span>() {'{'}
          </div>
          <div className={classes.functionContent}>
            {items.map((item) => (
              <div key={item.value} className={classes.variable}>
                <img alt={item.value} className={classes.functionImg}
                     src={`/images/students/fuzz_code/${item.type}.png`}/> this.<span
                className={classes.textOrange}>{item.type === 'hat' ? 'addHat' : 'addItem'}</span>("{item.value}");
              </div>
            ))}
          </div>
          <div>{'}'}</div>
        </div>
        <div>{'}'}</div>
      </pre>
    </Box>
  );
}
export default FuzzCodeBlock;