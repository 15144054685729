import React, { useEffect, useState } from "react"
import Modal from 'components/modal/Modal';
import { useLocalStorage } from 'hooks/useLocalStorage';

interface ParentOverStudentLimitProps {
    isOverLimit: boolean | undefined;
}

export default function ParentOverStudentLimit({ isOverLimit = false }: ParentOverStudentLimitProps) {
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [didShowOverStudentLimitModal, setDidShowOverStudentLimitModal] = useLocalStorage('did-show-student-limit-modal', false);

    function closeModal() {
        setInfoModalOpen(false);
    }

    useEffect(() => {
        if (isOverLimit && !didShowOverStudentLimitModal) {
            setInfoModalOpen(true);
            setDidShowOverStudentLimitModal(true);
        }

        if (!isOverLimit) {
            setDidShowOverStudentLimitModal(false);
        }
        // eslint-disable-next-line
    }, [isOverLimit]);

    return (
      <React.Fragment>
          {infoModalOpen && <Modal closeModal={closeModal}>
              <div className="flex flex-col items-center pb-4 pt-5 px-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    className="text-yellow-300 w-48 h-48"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                  </svg>
                  <div className="text-3xl font-bold text-gray-800">
                      Over Student Limit
                  </div>
                  <div className="font-light py-6 text-gray-600 text-center max-w-sm">
                      Kodable Pro Accounts may have up to four students added. Please remove students in order to restore full functionality.
                  </div>
                  <div className="text-xl font-bold text-gray-800">
                      How to Remove Students
                  </div>
                  <div className="font-light py-6 text-gray-600 text-center max-w-sm">
                      Click on the "manage" tab under a student's profile to find the delete button.
                  </div>
                  <img src="/images/how-to-delete-student.jpg" style={{ border: '1px solid black' }} alt="Screenshot that shows what button to press to delete a student" className="mb-4" />
                  <div className="flex justify-center items-center">
                      <button
                        onClick={closeModal}
                        type="button"
                        className="flex items-center justify-center text-gray-500 border border-gray-200 bg-white hover:bg-gray-100 active:bg-gray-200 px-4 py-2 rounded px-6 py-3 text-lg rounded mr-6">
                          Okay
                      </button>
                  </div>
              </div>
          </Modal>}
          { isOverLimit && <div role="button" onClick={() => setInfoModalOpen(true)} className="flex items-center bg-red-100 text-red-700 text-sm px-4 py-3 fixed w-full h-15" style={{ zIndex: 1500, top: 0}}>
              <p><span className="font-bold">You are over your limit of four students.&nbsp;</span>Some features may not work until you remove some students from your account.</p>
          </div> }
      </React.Fragment>
    )
}
