import React from "react";
import {useOrganizationProfile} from "context/OrganizationProfileProvider";
import {Box} from "@material-ui/core";
import {SubscriptionStateCardsListing} from "pages/godmode/Subscriptions/SubscriptionStateCardsListing";

export const OrganizationProfileDetailHistoryTab: React.FC = () => {
  const {organization_profile} = useOrganizationProfile();

  if (!organization_profile) {
    return null
  }

  return (
    <Box width="50%">
      <SubscriptionStateCardsListing organizationProfile={organization_profile}/>
    </Box>
  )
}