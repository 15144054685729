import useCurrentUser from "loaders/useCurrentUser";
import { TeacherValentineClassList } from "./TeacherValentineClassList";
import React from "react";

export const ValentinesIndex: React.VFC = () => {
  const { currentUser } = useCurrentUser();

  if (!currentUser) {
    return null;
  }

  if (!currentUser.is_parent) {
    return <TeacherValentineClassList />
  }
  
  return null;
}