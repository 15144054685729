import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import useSharedStyles from "components/useSharedStyles";
import { DialogStateProps } from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import React, { useMemo } from "react";
import { IKlass } from "types/IKlass";
import { ELeadState } from "types/IStudentSchoolProfile";
import { ConnectWithFamiliesButton } from "../../ui/buttons/ConnectWithFamiliesButton";

export const ConnectWithFamiliesDialog: React.FC<DialogStateProps & { klass?: IKlass, small?: boolean, event?: 'valentines' }> = (props) => {
  const { teacherData } = useTeacherInit();

  const numFamiliesLinked = useMemo(() => {
    return teacherData?.students.reduce((numLinked, student) => {
      return numLinked + (student.lead?.state === ELeadState.linked ? 1 : 0);
    }, 0) || 0;
  }, [teacherData]);

  const sharedClasses = useSharedStyles();

  return <Dialog open={props.open} fullScreen={!props.small} fullWidth>
    <DialogTitle style={props.small ? { position: 'absolute', width: '100%' } : undefined}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          onClick={props.onClose}
        ><Close /></IconButton>
      </Box>
    </DialogTitle>
    <DialogContent>
      <Box display="flex" flexDirection="column" alignItems="center" className={sharedClasses.vspacing4} flexGrow={1} height="100%" overflow="scroll">
        {numFamiliesLinked === 0 && <>
          <Box width={{ xs: 350, lg: 400 }} mt={4}>
            <img src="/images/class/share-with-parents/no_linked_families.png" />
          </Box>
          <Typography variant="h1" style={{ fontWeight: 'bold' }} align="center">Your students are learning &mdash; tell their families!</Typography>
          <Box maxWidth={500} textAlign="center">
            <Typography>Want to involve your student's family in their child's learning? We've made that quick and simple.</Typography>
          </Box>
        </>}
        {numFamiliesLinked > 0 && <>
          <Box width={{ xs: 350, lg: 400 }} mt={4}>
            <img src="/images/class/share-with-parents/linked_families.png" />
          </Box>
          <Box maxWidth={600} textAlign="center">
            <Box component="span" style={{ fontFamily: 'Kodable', fontSize: 28 }} color="#27a9e1">{numFamiliesLinked}</Box>&nbsp;&nbsp;<Typography variant="h1" display="inline" style={{ fontWeight: 'bold' }}>{numFamiliesLinked === 1 ? "family is" : "families are"} following their students' projects at home!</Typography>
          </Box>
          <Box maxWidth={500} textAlign="center">
            <Typography>Let others know how they can see their child's projects.</Typography>
          </Box>
        </>}
        <Box pb={2}>
          <ConnectWithFamiliesButton klass={props.klass} onClose={props.onClose} event={props.event} />
        </Box>
      </Box>
    </DialogContent>
  </Dialog>
}