import { faEmpire } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Paper, Typography, Button } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import useCurrentUser from "loaders/useCurrentUser";
import React, {useMemo, useState} from "react";
import {ICourseData, IMissionNode} from "../ICourseData";
import EditMissionModal from "../../../components/modal/EditMissionModal";
import {majorBadgeOuterSprites, missionColorMap, missionNameMap} from "./defaultMissionConfigurations";
import {badgeData} from "../BadgeData";
import MissionGameContent from "./MissionGameContent";

const MissionCard: React.VFC<{ course: ICourseData, mission: IMissionNode, order: number }> = ({ course, mission, order }) => {
    const { currentUser } = useCurrentUser();
    const sharedClasses = useSharedStyles();
    const premiumLesson = mission.isPaywalled;
    const [isEditingMission, setIsEditingMission] = useState(false);
  
    const courseBadgeData = useMemo(() => {
    return badgeData.find(b => b.courseId === course.id);
  }, [course])
  
  return <>
    {<EditMissionModal open={isEditingMission} course={course} mission={mission} onClose={() => setIsEditingMission(false)} />}
      <Box component={Paper} {...{ variant: 'outlined' }} p={3}>
        <Box display="flex" flexDirection="row" alignContent="flexStart" height={40} className={sharedClasses.hspacing2}>
          <Box style={{maxHeight: 80, minHeight: 80, width: 40}}>
              <img src={majorBadgeOuterSprites[mission.missionType]}
                   style={{position: "absolute", marginTop: -35, marginLeft: -25,  height: "80px", width: "auto"}}/>

              <img src={`/images/badges/inner/major/${courseBadgeData!.icon}.png`}
                   style={{position: "absolute", marginTop: -35, marginLeft: -25, height: "80px", width: "auto"}}/>
          </Box>
          <Typography variant="h2" style={{"color":missionColorMap[mission.missionType]}}>{course.meta.dashboardSubtitle}{course.meta.unlimitedLevels ? "" : ` ${missionNameMap[mission.missionType]}`}</Typography>
          

          {currentUser.is_god && <Box ml={2}>
              <Button
                  variant="outlined"
                  onClick={e => { e.stopPropagation(); setIsEditingMission(true); }}
                  startIcon={<FontAwesomeIcon icon={faEmpire} />}
              >Edit mission</Button>
          </Box>}
        </Box>

        <Box
          style={{
            maxHeight: `${1000}px`,
            overflow: 'hidden',
            transition: 'max-height 0.5s'
          }}
        >
          {premiumLesson && <Box display="flex" flexDirection="row" ml={6} height={30} alignItems="center" style={{gap:5}}>
              <img src="/images/courses/kodable_home.png" style={{maxHeight:30}} />
              <Typography variant="body2" style={{ color: '#666', fontStyle: 'italic' }}>This lesson is available with Kodable Premium</Typography>
          </Box>}
           <MissionGameContent mission={mission} course={course} />
        </Box>
      </Box>
    </>
}

export default MissionCard;