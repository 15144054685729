import useSubscription from 'loaders/useSubscription';
import HomePromotionBanner from 'components/banners/HomePromotionBanner';
import Loading from 'components/loading/Loading';
import useCurrentUser from 'loaders/useCurrentUser';
import AccountInformationBox from "../../components/settings/AccountInformationBox";
import BillingInformationBox from "../../components/settings/BillingInformationBox";
import EditAccountModal from "../../components/dialogs/settings/EditAccountModal";
import useDialogState from "../../hooks/useDialogState";
import DeleteAccountDialog from "../../components/dialogs/settings/DeleteAccountDialog";

export default function Settings() {
  const { currentUser: _currentUser } = useCurrentUser();
  const currentUser = _currentUser!;
  const { subscription, invoice, loading } = useSubscription();
  const editAccountDialogState = useDialogState()
  const deleteAccountDialogState = useDialogState()

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-32 h-64">
        <Loading />
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }} className="max-w-screen-lg my-8 mx-auto px-4">
      {editAccountDialogState.open &&
        <EditAccountModal
          closeModal={editAccountDialogState.onClose}
          currentUser={currentUser}
        />
      }
      <DeleteAccountDialog
        open={deleteAccountDialogState.open}
        onClose={deleteAccountDialogState.onClose}
      />
      <HomePromotionBanner currentUser={currentUser} />
      <AccountInformationBox
        subscription={{term: subscription.term, plan: subscription.plan}}
        name={currentUser.name}
        email={currentUser.username}
        onDeleteAccount={deleteAccountDialogState.handleOpen}
        onEditAccount={editAccountDialogState.handleOpen}
      />
      <BillingInformationBox subscription={subscription} invoice={invoice} />
    </div>
  );
}
