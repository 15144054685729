import Button from 'components/ui/buttons/Button'
import useDialogState from 'hooks/useDialogState'
import { SubscriptionChangeAdminDialog } from './SubscriptionChangeAdminDialog'
import React from "react";

export const SubscriptionChangeAdminButton: React.FC = () => {
    const dialogState = useDialogState()

    return (
        <>
            <SubscriptionChangeAdminDialog {...dialogState} />
            <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => dialogState?.handleOpen()}
            >
                Change
            </Button>
        </>
    )
}