import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useFormik } from "formik";
import { justFetch } from "mutations/mutate";
import {ICourseData, IMissionNode} from "pages/courses/ICourseData";
import React, { useEffect } from "react";
import { useState } from "react";
import { mutate } from "swr";

const EditMissionModal: React.VFC<{ open: boolean, onClose: () => void, course: ICourseData, mission: IMissionNode }> = ({ open, onClose, course, mission }) => {
  const sharedClasses = useSharedStyles();
  const [submitError, setSubmitError] = useState(false);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: mission.meta || {},
    onSubmit: values => {
      return justFetch(endpoints.courseDetails(course.id), 'GET')
        .then(async (res) => {
          if (!res.ok) {
            throw new Error();
          }
          return await res.json() as Promise<ICourseData>;
        }).then(course => {
          const res = justFetch(endpoints.course(course.id), 'PUT', {
            ...course,
            configuration: {
              ...course.configuration,
              missionNodes: course.configuration.missionNodes?.map(missionNode => missionNode.nodeID === mission.nodeID ? {
                ...missionNode,
                meta: values
              } : missionNode)
            }
          });
          
          return res;
        }).then(res => {
          if (!res.ok) {
            throw new Error();
          }
          mutate(endpoints.allBasicsCourses);
          mutate(endpoints.courseDetails(course.id));
          onClose();
        })
        .catch((e) => {
          console.log(e);
          setSubmitError(true);
        })
    }
  });

  useEffect(() => {
    if (open) {
      setSubmitError(false);
      form.resetForm();
    }
  }, [open]);

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>Edit Mission</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <TextField
          label="Description"
          id="dashboardDescription"
          name="dashboardDescription"
          value={form.values.dashboardDescription}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
        />
        {submitError && <Alert severity="error">There was an error submitting this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="reset"
          onClick={onClose}
          disabled={form.isSubmitting}
        >Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={form.isSubmitting}
        >Save</Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default EditMissionModal;