import {Box, CircularProgress, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import {format} from "date-fns";
import {motion} from "framer-motion";
import {ChatBubble} from "components/ui/ChatBubble";
import React, {useMemo, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {routes} from "routes";
import {useInView} from 'react-intersection-observer';
import ReactImageFallback from "react-image-fallback";
import {RecentActivityItem, useRecentActivity} from "../../../../hooks/useRecentActivity";

export const ClassRecentActivity: React.VFC<{ klass_id: string | number }> = ({ klass_id }) => {
  const sharedClasses = useSharedStyles();
  const { recentActivity, loading, error } = useRecentActivity(klass_id);
  const { inView, ref: buttonRef } = useInView();

  if (error) {
    return <Box>
      <Alert severity="warning">We couldn't load your recent activity.</Alert>
    </Box>
  }

  if (loading) {
    return <Box display="flex" justifyContent="center" mt={2}>
      <CircularProgress />
    </Box>
  }

  if (recentActivity.length === 0) {
    return <Box className={sharedClasses.vspacing4}>
      <Box mt={2}>
        <Typography variant="body2">Your students' activity will show up here.</Typography>
      </Box>
      <Box>
        <ChatBubble>
          <Box height={100} display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h1" style={{ opacity: 0.5, fontWeight: 'bold', fontSize: 70, color: 'rgb(180 184 194)' }}>?</Typography>
          </Box>
        </ChatBubble>
        <Typography>Gracie Fuzz</Typography>
      </Box>

      <Box>
        <ChatBubble>
          <Box height={100} display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h1" style={{ opacity: 0.5, fontWeight: 'bold', fontSize: 70, color: 'rgb(180 184 194)' }}>?</Typography>
          </Box>
        </ChatBubble>
        <Typography>Simon Fuzz</Typography>
      </Box>
    </Box>
  }

  return <>
    {recentActivity.map((bin, index) => {
      return <RecentActivityBin key={index} {...bin} />
    })}
    <Box mt={2} mb={12} display="flex" flexDirection="column" alignItems="center">
      <MotionButton
        ref={buttonRef}
        size='small'
        variant='outlined'
        initial={{ transform: 'scale(0.1)' }}
        animate={inView ? { transform: 'scale(1)' } : { transform: 'scale(0.1)' }}
        transition={{ duration: 0.5, ease: 'backInOut' }}
        {...{
          component: RouterLink, to: routes.class.studentWork(klass_id.toString())
        }}
      >View More Projects</MotionButton>
    </Box>
  </>
}

const MotionButton = motion.custom(Button)

const today = new Date();

export const RecentActivityBin: React.VFC<{ date: string, activities: RecentActivityItem[] }> = ({ date, activities }) => {
  const dateStr = useMemo(() => {
    const _date = new Date(date);

    return format(_date, _date.getFullYear() !== today.getFullYear() ? 'MMMM d, yyyy' : 'MMMM d')
  }, [date]);

  return <Box>
    <Box mt={2} mb={3}>
      <Typography style={{ opacity: 0.5 }} variant="body2" align="center">{dateStr}</Typography>
    </Box>
    {activities.map((activity, index) => <ClassRecentActivityCard key={index} item={activity} />)}
  </Box>;
}

const ClassRecentActivityCard: React.VFC<{ item: RecentActivityItem }> = ({ item }) => {
  const [fuzzLoaded, setFuzzLoaded] = useState<boolean>(true)

  if (!fuzzLoaded) {
    return null
  }

  return <Box mb={4}>
    <ChatBubble>
      <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <ReactImageFallback
          src={item.thumbnail}
          fallbackImage="/images/student-creations-page/no-fuzz.png"
          onError={() => {
            setFuzzLoaded(false)
          }}
          style={{ maxHeight: 200, borderRadius: 3 }}
        />
      </Box>
    </ChatBubble>
    <Box display="flex">
      <Typography>{item.studentName}</Typography>&nbsp;
    </Box>
  </Box>
}