import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core"
import { GodmodePageWrapper } from "pages/godmode/GodmodePageWrapper"
import useSharedStyles from "components/useSharedStyles"
import Button from "components/ui/buttons/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRotateBackward, faInfoCircle, faPencil, faPlus, faQuestionCircle, faSearch, faTrash, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { Form, FormikProvider, useFormik, useFormikContext } from "formik"
import * as Yup from 'yup'
import TextField from "components/ui/TextField"
import { SchoolsProvider, useSchoolsContext, ISchool } from "context/SchoolsProvider"
import Select from "components/ui/Select"
import useDialogState, { DialogStateProps } from "hooks/useDialogState"
import React, { useEffect, useState } from "react"
import { justFetch } from "mutations/mutate"
import endpoints from "endpoints"
import { useAlert } from "context/AlertProvider"
import useSWR from "swr"
import { accountDateToTimeAgo } from "pages/godmode/utils/time"
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import { IRouteParams } from "AppRoutes"
import { SchoolsRequestsPage } from "./SchoolsRequestsPage"
import { SchoolsAddPage } from "./SchoolsAddPage"
import { SchoolsEditPage } from "./SchoolsEditPage"
import {routes} from "../../../../routes";

interface ISchoolsSearch {
    search: string;
    page: number;
    per_page: number;
}

interface ISchoolTeacher {
    id: number;
    name: string;
    username: string;
    updated_at?: string;
}

export const validationSchema = Yup.object().shape({
    page: Yup.number()
    .min(1, 'Page number must be greater than 0')
    .required('You must provide a page number'),
    per_page: Yup.number().required('You must provide a value for per_page')
})

export const SchoolsIndex: React.FC = () => {
    const { isExact, path } = useRouteMatch<IRouteParams>();
    return (
        <>
        {isExact &&
            <GodmodePageWrapper title={`Schools`} loading={false}>
                <SchoolsProvider>
                    <SchoolsIndexBody />
                </SchoolsProvider>
            </GodmodePageWrapper>
        }
        <Switch>
            <Route
                component={SchoolsRequestsPage}
                path={routes.godmode.schools.schoolRequests}
            />
            <Route
                component={SchoolsAddPage}
                path={routes.godmode.schools.schoolAdd}
            />
            <Route
                component={SchoolsEditPage}
                path={`${path}/edit/:schoolId`}
            />
        </Switch>
        </>
    )
}

const SchoolsIndexBody: React.FC = () => {
    const { setSearch, setPage } = useSchoolsContext()
    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            search: "",
            page: 1,
            per_page: 100
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                if (values.search && values.search.length > 0) {
                    setPage(1)
                    setSearch(values.search)
                } else {
                    setSearch(null)
                }
            } catch (e) {
                
            }
        }
    })
    return (
        <>
            <FormikProvider value={form}>
                <SchoolsIndexForm />
            </FormikProvider>
            <SchoolsIndexTable />
        </>
    )
}

const SchoolsIndexForm: React.FC = () => {
    return (
        <Form>
            <SchoolsIndexHeader />
        </Form>
    )
}

const SchoolsIndexHeader: React.FC = () => {
    const sharedClasses = useSharedStyles()
    const history = useHistory()
    
    return (
        <Box className={sharedClasses.vspacing2}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" className={sharedClasses.hspacing2}>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
                        variant="contained"
                        color="orange"
                        onClick={() => {
                            history.push(routes.godmode.schools.schoolRequests)
                        }}
                    >
                        Add School Requests
                    </Button>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faPlus} />}
                        variant="contained"
                        color="green"
                        onClick={() => {
                            history.push(routes.godmode.schools.schoolAdd)
                        }}
                    >
                        Add School
                    </Button>
                </Box>
                <Box width="50%">
                    <SchoolsIndexHeaderSearch />
                </Box>
            </Box>
            <SchoolsIndexHeaderFilters />
        </Box>
    )
}

export const SchoolsIndexHeaderSearch: React.FC = () => {
    const { values, touched, errors, isSubmitting, handleChange } = useFormikContext<ISchoolsSearch>();
    const sharedClasses = useSharedStyles()
    const { loading  } = useSchoolsContext()

    return (
        <Box display="flex" alignItems="center" className={sharedClasses.hspacing1}>
            <TextField
                value={values.search}
                onChange={handleChange}
                error={touched.search && !!errors.search}
                helperText={touched.search && errors.search}
                name="search"
                id="search"
                placeholder="Search all schools in database"
                fullWidth
                disabled={isSubmitting || loading}
            />
            <Button
                startIcon={<FontAwesomeIcon icon={faSearch} />}
                type="submit"
                variant="contained"
                color="green"
                disabled={isSubmitting || loading}
            >
                Search
            </Button>
        </Box>
    )
}

const SchoolsIndexHeaderFilters: React.FC = () => {
    const { values, touched, errors, isSubmitting, handleChange } = useFormikContext<ISchoolsSearch>();
    const { setPage, loading  } = useSchoolsContext()
    
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <TextField
                type="number"
                onChange={(e) => {
                 if (parseInt(e.target.value) > 0) {
                    handleChange(e)
                    setPage(parseInt(e.target.value))
                 }   
                }}
                error={touched.page && !!errors.page}
                helperText={touched.page && errors.page}
                label="Page"
                name="page"
                id="page"
                value={values.page}
                disabled={isSubmitting || loading}
            />
            <Select
                value={values.per_page}
                onChange={handleChange}
                label={`Show ${values.per_page} results per page`}
                error={touched.per_page && !!errors.per_page}
                name="per_page"
                id="per_page"
                disabled={isSubmitting}
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100} selected>100</MenuItem>
            </Select>
        </Box>
    )
}

const SchoolsIndexTable: React.FC = () => {
    const { schools, loading, error } = useSchoolsContext()

    if (error) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center">
                Error: Could not get schools
            </Box>
        )
    }

    if (loading) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell>School Name</TableCell>
                        <TableCell>Teachers</TableCell>
                        <TableCell>Grade Level</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>Zip</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableHead>
                    <TableBody>
                        {schools?.map((school, index) => {
                            return (
                                <SchoolsIndexTableItem key={index} {...school} />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const SchoolsIndexTableItem: React.FC<ISchool> = (props: ISchool) => {
    const { id, school_name, teacher_count, school_high_grade, school_location_address, school_location_state, school_location_city, school_location_zip } = props
    const sharedClasses = useSharedStyles()
    
    return (
        <TableRow key={id}>
            <TableCell>{school_name}</TableCell>
            <TableCell>{(teacher_count && teacher_count > 0) ? teacher_count : 0}</TableCell>
            <TableCell>{school_high_grade}</TableCell>
            <TableCell>{school_location_address}</TableCell>
            <TableCell>{school_location_state}</TableCell>
            <TableCell>{school_location_city}</TableCell>
            <TableCell>{school_location_zip}</TableCell>
            <TableCell className={`${sharedClasses.vspacing1} ${sharedClasses.hspacing1}`}>
                <SchoolsIndexTableItemActions {...props} />
            </TableCell>
        </TableRow>
    )
}

const SchoolsIndexTableItemActions: React.FC<ISchool> = (props: ISchool) => {
    const [dialogType, setDialogType] = useState<"info" | "delete">()
    const dialogState = useDialogState()
    const history = useHistory()

    return (
        <>
            <Dialog open={dialogState.open} maxWidth={(dialogType === 'info' ? 'xl' : 'sm')}>
                {dialogType === "info" && <SchoolsIndexTableItemInfoDialog school={props} dialogState={dialogState} />}
                {dialogType === "delete" && <SchoolsIndexTableItemDeleteDialog school={props} dialogState={dialogState} />}
            </Dialog>
            <Button
                variant="contained"
                color="blue"
                startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
                onClick={() => {
                    setDialogType("info")
                    dialogState.handleOpen()
                }}
            >
                Info
            </Button>
            <Button
                variant="contained"
                color="green"
                startIcon={<FontAwesomeIcon icon={faPencil} />}
                onClick={() => {
                    history.push(routes.godmode.schools.schoolEdit(props?.id))
                }}
            >
                Edit
            </Button>
            <Button
                variant="contained"
                color="red"
                startIcon={<FontAwesomeIcon icon={faTrash} />}
                onClick={() => {
                    setDialogType("delete")
                    dialogState.handleOpen()
                }}
            >
                Delete
            </Button>
        </>
    )
}

const SchoolsIndexTableItemInfoDialog: React.FC<{school: ISchool, dialogState: DialogStateProps}> = (props: {school: ISchool, dialogState: DialogStateProps}) => {
    const { school_name, school_location_state, school_location_city, school_location_zip } = props?.school
    const { onClose } = props?.dialogState
    const sharedClasses = useSharedStyles()

    return (
        <>
            <DialogTitle>{school_name}</DialogTitle>
            <DialogContent className={sharedClasses.vspacing2}>
                <Box>
                    <Box display="flex" alignItems="center" className={sharedClasses.hspacing1}>
                        <Typography variant="subtitle1">School Name:</Typography>
                        <Typography variant="body1">{school_name}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className={sharedClasses.hspacing1}>
                        <Typography variant="subtitle1">State:</Typography>
                        <Typography variant="body1">{school_location_state}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className={sharedClasses.hspacing1}>
                        <Typography variant="subtitle1">City:</Typography>
                        <Typography variant="body1">{school_location_city}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className={sharedClasses.hspacing1}>
                        <Typography variant="subtitle1">Zip:</Typography>
                        <Typography variant="body1">{school_location_zip}</Typography>
                    </Box>
                </Box>
                <Divider />
                <SchoolsIndexTableItemInfoTeachers {...props?.school} />
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="orange"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </Box>
            </DialogActions>
        </>
    )
}

const SchoolsIndexTableItemInfoTeachers: React.FC<ISchool> = (props: ISchool) => {
    const { id } = props
    const { data: teachers, error } = useSWR<ISchoolTeacher[]>(endpoints.godmode.schoolsTeachers(id))
    const [teachersFiltered, setTeachersFiltered] = useState<ISchoolTeacher[]>()
    const [teachersSearch, setTeachersSearch] = useState<string>()
    const sharedClasses = useSharedStyles()

    useEffect(() => {
        if (!teachersSearch) {
            setTeachersFiltered(teachers)
            return
        }

        const filtered = teachers?.filter((teacher) => {
            return teacher?.name?.toLowerCase()?.includes(teachersSearch?.toLowerCase()) || teacher?.username?.toLowerCase()?.includes(teachersSearch?.toLowerCase())
        })

        setTeachersFiltered(filtered)
    }, [teachers, teachersSearch])

    if (error) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" p={2}>
                <Typography>Could not load teacher data for this school</Typography>
            </Box>
        )
    }

    if (!teachers) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" p={2}>
                <CircularProgress />
            </Box>
        )
    }

    if (teachers && teachers.length < 1) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" p={2}>
                <Typography>This school has no teachers</Typography>
            </Box>
        )
    }

    return (
        <Box className={sharedClasses.vspacing2}>
            <Typography variant="h1" align="center">Teachers</Typography>
            <Box>
                <TextField
                    label="Search teachers"
                    name="search_teachers"
                    id="search_teachers"
                    placeholder="Search teachers by name or email"
                    value={teachersSearch}
                    onChange={(e) => setTeachersSearch(e.target.value)}
                />
            </Box>
            {(teachersFiltered && teachersFiltered.length > 0) &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableCell>Name</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Last Updated</TableCell>
                        </TableHead>
                        <TableBody>
                            {teachersFiltered?.map((teacher) => {
                                return (
                                    <TableRow key={teacher.id}>
                                        <TableCell>{teacher?.name}</TableCell>
                                        <TableCell>{teacher?.id}</TableCell>
                                        <TableCell>{teacher?.username}</TableCell>
                                        <TableCell>
                                            {teacher?.updated_at &&
                                                accountDateToTimeAgo(teacher?.updated_at)
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {(!teachersFiltered || teachersFiltered.length < 1) &&
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    className={sharedClasses.vspacing2}
                    width="100%"
                    p={2}
                >
                    <Typography>No teachers match this search.</Typography>
                    <Button
                        variant="contained"
                        color="green"
                        startIcon={<FontAwesomeIcon icon={faArrowRotateBackward} />}
                        onClick={() => setTeachersSearch("")}
                    >
                        Reset Search
                    </Button>
                </Box>
            }
        </Box>
    )
}

const SchoolsIndexTableItemDeleteDialog: React.FC<{school: ISchool, dialogState: DialogStateProps}> = (props: {school: ISchool, dialogState: DialogStateProps}) => {
    const { id, school_name } = props?.school
    const { onClose } = props?.dialogState
    const [confirmText, setConfirmText] = useState<string>("")
    const sharedClasses = useSharedStyles()
    const alert = useAlert()
    const { mutate } = useSchoolsContext()

    const handleDelete = () => {
        justFetch(endpoints.godmode.schoolDelete(id), 'DELETE')
        .then((res) => {
            if (res.ok) {
                alert.success(`School ${school_name} deleted!`)
                mutate()
            } else {
                alert.error(`Error deleting school ${school_name}`)
            }
            onClose()
        })
        .catch((e) => {
            console.log(e)
            alert.error(`Error deleting school ${school_name}`)
            onClose()
        })
    }

    return (
        <>
            <DialogTitle>Delete {school_name}</DialogTitle>
            <DialogContent>
                <Box className={sharedClasses.vspacing4}>
                    <Typography variant="h2" align="center">
                        Are you sure you want to delete {school_name}?
                    </Typography>
                    <Typography variant="body1">
                        This will <strong>permanently</strong> remove this school from the database; this action cannot be undone.
                    </Typography>
                    <Box>
                        <TextField
                            label="Type CONFIRM to delete"
                            name="confirm"
                            id="confirm"
                            value={confirmText}
                            placeholder="CONFIRM"
                            onChange={(e) => setConfirmText(e.target.value)}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Button
                        startIcon={<FontAwesomeIcon icon={faXmarkCircle} />}
                        variant="contained"
                        color="orange"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faTrash} />}
                        variant="contained"
                        color="red"
                        onClick={handleDelete}
                        disabled={confirmText !== "CONFIRM"}
                    >
                        Confirm Delete
                    </Button>
                </Box>
            </DialogActions>
        </>
    )
}