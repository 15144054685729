import {Box, Divider, Typography, useTheme} from "@material-ui/core";
import React from "react";

const SiteNameBox: React.VFC<{siteName: string, siteNum: number}> = ({siteName, siteNum}) => {
  const theme = useTheme()
  return (
    <Box display={'flex'} flexDirection={'column'}
         style={{
           width: 350,
           height: 275,
           padding: 10,
           borderWidth: 3,
           borderColor: theme.palette.green.main,
           borderRadius: 8,
           flexShrink: 1,
          flexGrow: 0,
         }}>
        <Box display={'flex'} flexDirection={'row'}>
          <Typography variant={'h5'} style={{width: '100%', textAlign: 'center', marginLeft: -15}}>Site #{siteNum}</Typography>
        </Box>
        <Divider/>
        <Box marginTop={5} textAlign={'center'}>
          <Typography variant={'subtitle1'}>User provided school</Typography>
          <Typography variant={'subtitle2'}>{siteName}</Typography>
        </Box>
    </Box>
  )
}

export default SiteNameBox