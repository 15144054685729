import { Box, LinearProgress, LinearProgressProps, makeStyles, Paper } from "@material-ui/core";
import { ClassesProp } from "./ClassesProp";
import classNames from 'classnames';
import React from "react";

const usePageContainerStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(4, 6),
    backgroundColor: theme.palette.background.default,
  },
  wrapperFull: {
  },
  wrapperCentered: {
    display: 'flex',
    justifyContent: 'center'
  },
  contentCentered: {
    width: 700 + theme.spacing(3),
    padding: theme.spacing(3),
  }
}));

interface PageContainerProps {
  variant?: 'full' | 'centered';
  showProgress?: boolean;
  progressProps?: LinearProgressProps;
  centeredFlavorImage?: string;
}

const PageContainer: React.FC<PageContainerProps & ClassesProp<typeof usePageContainerStyles>> = function({ centeredFlavorImage, children, variant = 'full', showProgress, progressProps, ...props }) {
  const classes = usePageContainerStyles(props);

  return (
    <Box className={classNames(variant === 'full' ? classes.wrapperFull : classes.wrapperCentered, classes.wrapper)} height="100%">
      {variant === 'full'
        ? children
        : <Box display={centeredFlavorImage ? 'flex' : ''} alignItems="center" flexDirection="column">
            {centeredFlavorImage && <Box mb={4}><img src={centeredFlavorImage} /></Box>}
            <Paper>
              <LinearProgress {...progressProps} style={{ visibility: showProgress ? 'visible' : 'hidden' }} />
              <Box className={classes.contentCentered}>
                {children}
              </Box>
            </Paper>
          </Box>}
    </Box>
  )
};

export default PageContainer;