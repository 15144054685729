import React, { useState } from 'react';
import endpoints from 'endpoints';

export function assembleUri(type: string, resolution: string, imageIdentifier: string, extension: string): string {
  const s3 = 'https://s3.amazonaws.com';
  const bucket = `kodable${type}${process.env.REACT_APP_AWS_ENV === 'test' ? 'test' : ''}`;
  return [
    s3,
    bucket,
    resolution,
    `${type}_${imageIdentifier}.${extension}`,
  ].join('/');
}

export function assembleMazeUri(mazeId: string): string {
  return endpoints.contentImageById(mazeId);
}

function fuzzUrl(fuzzId: number, studentCode: string): string {
  if (Number(fuzzId) < 1500) {
    return '/images/fuzzes/' + fuzzId + '.png';
  }
  return assembleUri(
    'fuzz',
    'fullsize',
    studentCode?.slice(0, 5) + fuzzId,
    'png'
  );
}

const FALLBACK_IMAGE = '/images/fuzzes/1001.png';

interface FuzzImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  fuzzId: number;
  studentCode: string;
  height?: number;
}

export default function FuzzImage({ fuzzId, studentCode, height = 250 }: FuzzImageProps) {
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  return (
    <>
      {!showPlaceholder ?
        <img
          alt="fuzz"
          src={fuzzUrl(fuzzId, studentCode)}
          style={{ height, objectFit: 'contain' }}
          onError={() => setShowPlaceholder(true)}
        /> : <img
          alt="fuzz"
          src={FALLBACK_IMAGE}
          style={{ height, objectFit: 'contain' }}
        />
      }
    </>
  );
}
