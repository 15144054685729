import React, {useLayoutEffect, useState} from 'react';
import OnboardingLayout from './shared/OnboardingLayout';
import { Link } from 'react-router-dom';
import Amplitude from 'utils/Amplitude';
import { useEffect } from 'react';
import useCurrentUser from 'loaders/useCurrentUser';
import mutate, {justFetch} from 'mutations/mutate';
import { Box, Typography, makeStyles } from '@material-ui/core';
import useSharedStyles from 'components/useSharedStyles';
import endpoints from 'endpoints';
import { getPricingFromPlan } from 'pages/upgrade/UpgradePlanPage';

const useChoosePlanPageStyles = makeStyles((theme) => ({
    boxWrapper: {
        textAlign: 'center',
        borderRadius: '16px',
        border: '2px solid #e5e5e5',
        width: 150,
        overflow: 'hidden',
        margin: 10,
    },
    boxWrapperHighlighted: {
        background: '#06aeed',
        padding: '2px',
        border: '0px'
    },
    boxInterior: {
        padding: '1.5rem 1rem 1rem',
        borderRadius: '14px',
        background: '#fff',
    },
    boxHighlightText: {
        padding: '.25rem',
        background: '#06aeed',
        color: '#fff',
        fontWeight: 'bold'
    },
    boxTitle: {
        fontWeight: 'normal',
        fontFamily: 'Bjola',
        marginBottom: '2.25rem !important',
        [theme.breakpoints.down('md')]: {
            fontSize: 28,
            marginBottom: '1.5rem !important'
        }
    },
    boxPrice: {
        fontFamily: 'Bjola',
        lineHeight: '1px',
        marginBottom: '.75rem !important',
        [theme.breakpoints.down('md')]: {
            fontSize: 22
        },
    },
    boxBillingText: {
        fontSize: 16,
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    }
}))

type ChoosePlanPagePriceBoxProps = {
    title: string;
    price: number;
    billingText: string;
    link: string;
    highlight?: boolean;
    highlightText?: string;
}

const ChoosePlanPagePriceBox: React.FC<ChoosePlanPagePriceBoxProps> = ({title, price, billingText, link, highlight = false, highlightText}) => {
    const sharedClasses = useSharedStyles()
    const choosePlanPageStyles = useChoosePlanPageStyles()
    return (
        <Link to={link} className={`mb-4 sm:mb-0 ${highlight ? `${choosePlanPageStyles?.boxWrapperHighlighted} ${choosePlanPageStyles?.boxWrapper}` : choosePlanPageStyles?.boxWrapper}`}>
            {(highlight && highlightText) &&
                <Typography className={choosePlanPageStyles?.boxHighlightText} variant="body1">{highlightText}</Typography>
            }
            <Box className={`${sharedClasses.vspacing2} ${choosePlanPageStyles?.boxInterior}`}>
                <Typography className={choosePlanPageStyles?.boxTitle} variant="h2">{title}</Typography>
                <Typography className={choosePlanPageStyles?.boxPrice} variant="h1">${price}</Typography>
                <Typography className={choosePlanPageStyles?.boxBillingText} variant="body1">{billingText}</Typography>
            </Box>
        </Link>
    )
}


const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
        [theme.breakpoints.down('md')]: {
            marginBottom: 10
        }
    },
    footer: {
        textAlign: 'center',
        marginTop: 30,
        [theme.breakpoints.down('md')]: {
            marginTop: 20
        }
    }
}))

export default function ChoosePlanPage() {
    const { currentUser } = useCurrentUser();
    const [monthlyPrice, setMonthlyPrice] = useState<number>()
    const [yearlyPrice, setYearlyPrice] = useState<number>()
    const [lifetimePrice, setLifetimePrice] = useState<number>()
    const classes = useStyles()
    const [pricing, setPricing] = useState()

    useLayoutEffect(() => {
        const getPricing = async () => {
            const res = await justFetch(endpoints?.parentSubscriptionPricing, 'GET')
            setPricing(await res.json())
        }
        getPricing();
    }, []);

    useEffect(() => {
        if (!pricing) {
            return
        }
        setMonthlyPrice(getPricingFromPlan('monthly', pricing)?.price)
        setYearlyPrice(getPricingFromPlan('yearly', pricing)?.price)
        setLifetimePrice(getPricingFromPlan('lifetime', pricing)?.price)
      }, [pricing])

    useEffect(() => {
    if (!currentUser.is_dashboard_onboarded) {
        mutate(endpoints.parent.onboarding.finalize(currentUser.id), 'PUT', {
            is_dashboard_onboarded: true,
        });
    }
    }, [currentUser.is_dashboard_onboarded, currentUser.id]);

    useEffect(() => {
        Amplitude.track('(Parent Onboarding) Viewed Choose Plan Page');
    }, []);

    if (!pricing || !monthlyPrice || !yearlyPrice || !lifetimePrice) {
        return null
    }

    return (
        <OnboardingLayout
            imgName="intro"
            imgSrc="/images/onboarding/intro.png"
            title={`Please choose your plan`}
            activeSteps={5}
        >
            <Box className={classes.heading}>
                <Typography>Monthly and Yearly plans billed after 7-day trial. Cancel anytime.</Typography>
            </Box>
            <Box className="flex-col sm:flex-row justify-center sm:justify-between items-center sm:items-end" display="flex">
                <ChoosePlanPagePriceBox
                    title="Monthly"
                    price={monthlyPrice}
                    billingText="Billed monthly"
                    link="/parent/upgrade/plan/monthly"
                />
                <ChoosePlanPagePriceBox
                    title="Yearly"
                    price={yearlyPrice}
                    billingText="Billed annually"
                    link="/parent/upgrade/plan/yearly"
                    highlight={true}
                    highlightText="Save 66%"
                />
                <ChoosePlanPagePriceBox
                    title="Lifetime"
                    price={lifetimePrice}
                    billingText="Pay once"
                    link="/parent/upgrade/plan/lifetime"
                />
            </Box>
            <Box className={classes.footer}>
                <Typography>*All prices in USD</Typography>
            </Box>
        </OnboardingLayout>
  ) ;
}
