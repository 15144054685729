import {IUserCreatedContent} from "../../../types/IUserCreatedContent";
import {IStudentSchoolProfile} from "../../../types/IStudentSchoolProfile";
import useSharedStyles from "../../../components/useSharedStyles";
import React, {useMemo} from "react";
import {format} from "date-fns";
import useDialogState from "../../../hooks/useDialogState";
import endpoints from "../../../endpoints";
import {Box, Typography} from "@material-ui/core";
import ReactImageFallback from "react-image-fallback";
import DeleteStudentWorkDialog from "../../../components/dialogs/student/DeleteStudentWorkDialog";
import StudentWorkCard from "./StudentWorkCard";
import StudentWorkGameCard from "./StudentWorkGameCard";


const today = new Date();

const CardUserCreatedContent: React.VFC<IUserCreatedContent & { student: IStudentSchoolProfile }> = (props) => {
  const {id, student, created_at, category} = props;
  const sharedClasses = useSharedStyles();
  const dateStr = useMemo(() => {
    const date = new Date(created_at);

    return format(date, date.getFullYear() !== today.getFullYear() ? 'MMMM d, yyyy' : 'MMMM d')
  }, [created_at]);
  const deleteDialogState = useDialogState();

  return (
    <>
      <DeleteStudentWorkDialog
        {...deleteDialogState}
        student={student}
      />

      {category === "Game" &&
        <StudentWorkGameCard
          gameId={id}
          cardImageSrc={endpoints.contentImageById(id)}
          cardImageFallbackSrc="/images/student-creations-page/no-game.png"
          cardContent={`Created ${dateStr}`}
          onDelete={deleteDialogState.handleOpen}
          dialogContent={<Box display="flex" flexDirection="column" className={sharedClasses.vspacing2}>
            <Box display="flex" justifyContent="center">
              <ReactImageFallback
                src={endpoints.contentImageById(id)}
                fallbackImage="/images/student-creations-page/no-game.png"
                style={{
                  width: '100%',
                }}
              />
            </Box>
            <Typography variant="body2">Game created {dateStr} by {student.name}</Typography>
          </Box>}
        />
      }

      {category === "Maze" &&
        <StudentWorkCard
          cardImageSrc={endpoints.contentImageById(id)}
          cardImageFallbackSrc="/images/student-creations-page/no-maze.png"
          cardContent={`Created ${dateStr}`}
          onClick={deleteDialogState.handleOpen}
        />
      }
    </>
  )
}

export default CardUserCreatedContent