import { faArrowLeft, faDownload, faGamepad } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Hidden, IconButton, Tab, Tabs, Typography, useTheme } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import Button from "components/ui/buttons/Button";
import PageContainer from "components/ui/PageContainer"
import endpoints from "endpoints";
import React, { useEffect, useMemo, useState } from "react";
import { Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import GradeSubwaySign from "../../components/ui/GradeSubwaySign";
import { ICourseData, IGalaxyCourse } from "./ICourseData";
import useSharedStyles from "components/useSharedStyles";
import courseSections, { convertCreativeBuildingsToSectionedCourses } from "./courseSections";
import ViewCourseMissions from "./ViewCourseMissions";
import ViewCourseMaterials from "./ViewCourseMaterials";
import ViewCourseStandards from "./ViewCourseStandards";
import useCurrentUser from "loaders/useCurrentUser";
import EditCourseDialog from "../../components/dialogs/courses/EditCourseDialog";
import { faEmpire } from "@fortawesome/free-brands-svg-icons";
import ViewAsStudentDialog from "../../components/dialogs/student/ViewAsStudentDialog";
import { useIsSafari } from "pages/demo/isSafari";
import { useTracking } from "context/TrackingProvider";
import useDialogState from "hooks/useDialogState";
import TeacherPaywallDialog from "components/dialogs/paywalls/TeacherPaywallDialog";
import {routes} from "../../routes";
import { creativeBuildingsData } from "./CreativeBuildingsData";

const ViewCourse: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const { params: { courseId } } = useRouteMatch<IRouteParams>();
  const [isCreativeBuilding, setIsCreativeBuilding] = useState<boolean>()
  const { data: reqCourseData, error: reqCourseDataError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const [courseData, setCourseData] = useState<ICourseData[]>()
  const [courseDataError, setCourseDataError] = useState()
  const courseDetails = useMemo(() => {
    return courseData?.find(({ id }) => id.toString() === courseId);
  }, [courseId, courseData]);
  const courseDetailsLoading = !courseDetails && !courseDataError;
  const galaxyImage = useMemo(() => {
    return (courseData?.find(({ id }) => id === 1) as IGalaxyCourse)?.configuration.planetNodes.find(planetNode => planetNode.courseID.toString() === courseId)?.image;
  }, [courseData]);
  const sharedClasses = useSharedStyles();
  const [showEditCourseDialog, setShowEditCourseDialog] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation<{ lastLocation?: string }>();
  const { path, url } = useRouteMatch<IRouteParams>();
  const theme = useTheme();
  const [viewAsStudent, setViewAsStudent] = useState(false);
  const isSafari = useIsSafari();
  const { track } = useTracking();
  const [didShowPaywall, setDidShowPaywall] = useState(false);
  const paywallDialogState = useDialogState();

  useEffect(() => {
    setIsCreativeBuilding(parseInt(courseId) > 900)
  }, [courseId])

  useEffect(() => {
    if (isCreativeBuilding && creativeBuildingsData && creativeBuildingsData.length > 0) {
      const convertedBuildings = convertCreativeBuildingsToSectionedCourses(creativeBuildingsData)
      setCourseData(convertedBuildings as ICourseData[])
      setCourseDataError(undefined)
      return
    }
    if (!isCreativeBuilding && reqCourseData && reqCourseData.length > 0) {
      setCourseData(reqCourseData)
      setCourseDataError(reqCourseDataError)
    }

    return () => {
      setCourseData([])
      setCourseDataError(undefined)
    }
    
  }, [isCreativeBuilding, reqCourseData])
  
  useEffect(() => {
    if (courseDetails?.meta.premium && currentUser.plan !== 'School' && !didShowPaywall) {
      setDidShowPaywall(true);
      paywallDialogState.handleOpen();
    }
  }, [didShowPaywall, courseDetails, currentUser])

  useEffect(() => {
    if (courseDetails?.name) {
      track('Viewed Game Courses page', { 'Course': courseDetails.name });
    }
  }, [courseDetails]);

  if (courseDetailsLoading || !courseDetails) {
    return <PageContainer>
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    </PageContainer>
  }

  return <Box>
    <TeacherPaywallDialog copy="Content" plan="Premium" {...paywallDialogState} />
    <EditCourseDialog open={showEditCourseDialog} onClose={() => setShowEditCourseDialog(false)} course={courseDetails} />
    <ViewAsStudentDialog
      open={viewAsStudent}
      onClose={() => setViewAsStudent(false)}
      title={`${courseDetails.meta.dashboardTitle} Student View`}
      href={`${process.env.REACT_APP_GAME_ENDPOINT}/teacher-demo?overrideScene=${courseDetails.meta.overrideScene || 'GalaxyMap'}` + (!courseDetails.meta.overrideScene ? `&overrideCMID=${courseDetails.id}` : '')}
    />
    <Box pt={4} px={6} style={{ backgroundColor: 'rgba(245,245,245)' }}>
      <Box display="flex" alignItems="center">
        <IconButton
          onClick={() => history.goBack()}
        ><FontAwesomeIcon icon={faArrowLeft} /></IconButton>
        <Box mr={2}>
          <img
            src={galaxyImage ? `images/courses/planets/${galaxyImage}.png` : courseDetails?.meta?.iconUrl }
            style={{
              height: 100
            }}
          />
        </Box>
        <Box mr={4}>
          <Typography variant="h2" style={{ fontWeight: 600 }}>{courseDetails.meta.dashboardTitle}</Typography>
          <Typography style={{ opacity: 0.65, marginBottom: 4 }}>{courseDetails.meta.dashboardSubtitle}</Typography>
          {courseDetails?.meta?.grades && 
            <GradeSubwaySign grades={courseDetails.meta.grades || (courseDetails.meta.section ? courseSections[courseDetails.meta.section].grades : [])} />
          }
          <Box display="flex" mt={1}>
            <Hidden lgUp>
              {courseDetails.meta?.showDeepLink && <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={isSafari ? undefined : () => setViewAsStudent(true)}
                  startIcon={<FontAwesomeIcon icon={faGamepad} />}
                  href={isSafari ? (`${process.env.REACT_APP_GAME_ENDPOINT}/teacher-demo?overrideScene=${courseDetails.meta.overrideScene || 'GalaxyMap'}` + (!courseDetails.meta.overrideScene ? `&overrideCMID=${courseDetails.id}` : '')) : undefined}
                  {...{
                    target: '_blank'
                  }}
                >View As Student</Button>
              </Box>}
              {courseDetails.meta?.courseCertificateUrl !== undefined && <Box ml={2}>
                <Button
                  variant="outlined"
                  color="purple"
                  {...{
                    component: 'a',
                    href: courseDetails.meta.courseCertificateUrl,
                    target: '_blank'
                  }}
                  startIcon={<FontAwesomeIcon icon={faDownload} />}
                >Course Certificate</Button>
              </Box>}
              {currentUser.is_god && <Box ml={2}>
                <Button
                  variant="outlined"
                  onClick={() => setShowEditCourseDialog(true)}
                  startIcon={<FontAwesomeIcon icon={faEmpire} />}
                >
                  Edit course
                </Button>
              </Box>}
            </Hidden>
          </Box>
        </Box>
        <Hidden mdDown>
          {courseDetails.meta?.showDeepLink && <Box>
            <Button
              variant="contained"
              color="primary"
              href={isSafari ? (`${process.env.REACT_APP_GAME_ENDPOINT}/teacher-demo?overrideScene=${courseDetails.meta.overrideScene || 'GalaxyMap'}` + (!courseDetails.meta.overrideScene ? `&overrideCMID=${courseDetails.id}` : '')) : undefined}
              onClick={isSafari ? undefined : () => setViewAsStudent(true)}
              startIcon={<FontAwesomeIcon icon={faGamepad} />}
              {...{
                target: '_blank'
              }}
            >View As Student</Button>
          </Box>}
          {courseDetails.meta?.courseCertificateUrl !== undefined && <Box ml={2}>
            <Button
              variant="outlined"
              color="purple"
              {...{
                component: 'a',
                href: courseDetails.meta.courseCertificateUrl,
                target: '_blank'
              }}
              startIcon={<FontAwesomeIcon icon={faDownload} />}
            >Course Certificate</Button>
          </Box>}
          {(currentUser.is_god && !isCreativeBuilding) && <Box ml={2}>
            <Button
              variant="outlined"
              onClick={() => setShowEditCourseDialog(true)}
              startIcon={<FontAwesomeIcon icon={faEmpire} />}
            >
              Edit course
            </Button>
          </Box>}
        </Hidden>
      </Box>
    </Box>
    <Box style={{ backgroundColor: theme.palette.background.default }}>
      <Tabs
        value={pathname}
        onChange={(ev, newPage) => history.push(newPage)}
        indicatorColor="primary"
        textColor="primary"
      >
        {!isCreativeBuilding && <Tab label="Game Lessons" value={url} /> }
        {((courseDetails.meta?.resources?.length || 0) > 0 || currentUser.is_god) && <Tab label="Materials" value={routes.courses.viewCourse.materials(undefined, url)} />}
        {!isCreativeBuilding && <Tab label="Standards" value={routes.courses.viewCourse.standards(undefined, url)} /> }
      </Tabs>
    </Box>
    <Box pt={4} pb={8} px={6} className={sharedClasses.vspacing2}>
      {!isCreativeBuilding && <Route
        component={ViewCourseMissions}
        path={`${path}`}
        exact
      />}
      <Route
        component={ViewCourseMaterials}
        path={routes.courses.viewCourse.materials(undefined, path)}
        exact
      />
      {!isCreativeBuilding && <Route
        component={ViewCourseStandards}
        path={routes.courses.viewCourse.standards(undefined, path)}
        exact
      />}
    </Box>
  </Box>
}

export default ViewCourse;