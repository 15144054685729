import { Box } from "@material-ui/core";
import React, { useMemo } from "react";
import gradeInfo from "types/Grades";

const GradeSubwaySign: React.VFC<{ grades: ReadonlyArray<(keyof typeof gradeInfo)> }> = ({ grades }) => {
  const sortedGrades = useMemo(() => {
    return grades.slice().sort((gradeA, gradeB) => gradeInfo[gradeA].order - gradeInfo[gradeB].order)
  }, [grades]);

  return <Box display="flex" alignItems="center" flexDirection="row">
    <Box display="flex" flexDirection="row">
      {sortedGrades.map(grade => {
        return <Box mr={0.5} key={grade} borderRadius="50%" height={18} width={18} fontSize={12} style={{ backgroundColor: gradeInfo[grade].color, color: 'white' }} display="flex" alignItems="center" justifyContent="center">
          {grade.toLocaleUpperCase()}
        </Box>
      })}
    </Box>
  </Box>;
}

export default GradeSubwaySign;