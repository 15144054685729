import { Box, CircularProgress, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import endpoints from "endpoints";
import useSWR from "swr";
import { IQueuedOrderRequest } from "pages/godmode/IQueuedOrderRequest";
import {useHistory} from "react-router-dom";
import {routes} from "../../../../routes";
import React, { useMemo } from "react";

export const SalesOrderApprovalQueue: React.VFC = () => {
  const { data, error } = useSWR<IQueuedOrderRequest[]>(endpoints.queuedOrders);

  if (!data && !error) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" p={4}>
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Alert severity="error">Could not load orders.</Alert>
    )
  }

  return (
    <TableContainer style={{width: '100%'}}>
      <TableHead style={{width: '100%'}}>
        <TableRow style={{width: '100%'}}>
          <TableCell>#</TableCell>
          <TableCell>Organization Name</TableCell>
          {/*<TableCell>Amount</TableCell>*/  /*commenting out to match QueuedOrderRow below, until we implement calculatedTotalPrice here*/}
          <TableCell>Order Date</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data!.map((queuedOrder, index) => {
          return <QueuedOrderRow queuedOrder={queuedOrder} key={index} />
        })}
      </TableBody>
    </TableContainer>
  )
}

export const QueuedOrderRow: React.VFC<{ queuedOrder: IQueuedOrderRequest }> = ({ queuedOrder }) => {
  const history = useHistory()
  
  return <TableRow style={{background: "#fff", borderRadius: "3px"}}>
    <TableCell>{queuedOrder.id}</TableCell>
    <TableCell>{queuedOrder.order.organization_name}</TableCell>
    {/*<TableCell>${calculatedTotalPrice}</TableCell>*/}
    <TableCell>{new Date(queuedOrder.created_at).toLocaleString()}</TableCell>
    <TableCell>
      <Button variant="contained" color="primary" onClick={() => history.push(routes.godmode.sales.accountSetup(queuedOrder.id))}>
        View/Approve
      </Button>
    </TableCell>
  </TableRow>
}