import React, { Fragment, useState, useEffect } from 'react';
import './shared/styles.css';
import TopNav from '../shared/TopNav';
import { Section } from './shared/scrollspy';
import Amplitude from 'utils/Amplitude';

const SmeeborgGuidePage: React.FC = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    Amplitude.track('Viewed Learning Guide', {
      world: 'Smeeborg',
    });
  }, []);

  return (
    <Fragment>
      <TopNav
        title="Smeeborg"
        guideImage="/images/teacher-guides/smeeborg/Smeeborg.jpg"
        navLinks={[
          { title: 'Learning Tools', to: '/learning-tools' },
          { title: 'Smeeborg', to: '/learning-tools/smeeborg' },
          { title: 'Parent Guides', to: '/learning-tools/guides/smeeborg' },
        ]}
      />
      <div className="guide mx-auto flex" id="guide">
        <div className="flex-1 guide-content">
          <Section
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            id="smeeborg-overview">
            <div className="section-header section-header background-color-modifiers--smeeborg">
              <p>Smeeborg Overview</p>
              <img src="/images/teacher-guides/Kodable.png" alt="Kodable Logo" />
            </div>
            <div className="section-content">
              <h1 className="heading color--smeeborg">
                Everything you need to know about SMEEBORG!
              </h1>
              <div className="row">
                <div className="col-sm-6">
                  <h2 className="heading color--smeeborg">
                    Who is this guide for?
                  </h2>
                  <p>
                    This guide is designed for you! Created by teachers, you'll
                    find information and tips to support Learners' mastery of
                    coding concepts in Smeeborg.
                  </p>
                </div>
                <div className="col-sm-6">
                  <img
                    src="/images/teacher-guides/smeeborgSplash.jpg"
                    alt="Smeeborg Thumbnail"
                    className="img--center"
                  />
                </div>
              </div>
              <h2 className="heading color--smeeborg">
                What does this guide cover?
              </h2>
              <p>After exploring this guide, you will be able to:</p>
              <ul>
                <li>
                  Understand the role of the different variable types -{' '}
                  <span className="code color--smeeborg">Strings</span>,{' '}
                  <span className="code color--magenta">Integers</span>, and{' '}
                  <span className="code color--green">Arrays</span>.
                </li>
                <li>
                  Distinguish between different programming vocabulary words.
                </li>
                <li>Introduce and explain Smeeborg levels to Learners.</li>
                <li>
                  Identify where learning is happening within the Smeeborg
                  levels.
                </li>
                <li>Support Learners if they get stuck on a level.</li>
              </ul>
              <div className="row">
                <div className="col-sm-4">
                  <h2 className="heading color--smeeborg">Key Concepts</h2>
                  <p>This guide covers:</p>
                  <ul>
                    <li>
                      <span className="code color--smeeborg">String</span>{' '}
                      Variables
                    </li>
                    <li>
                      <span className="code color--magenta">Integer</span>{' '}
                      Variables
                    </li>
                    <li>
                      <span className="code color--green">Array</span> Variables
                    </li>
                  </ul>
                </div>
                <div className="col-sm-8">
                  <h2 className="heading color--smeeborg">
                    Learner Objectives
                  </h2>
                  <p>In this unit, Learners will be able to:</p>
                  <ul>
                    <li>
                      Identify{' '}
                      <span className="code color--smeeborg">String</span>,{' '}
                      <span className="code color--magenta">Integer</span>, and{' '}
                      <span className="code color--green">Array</span>{' '}
                      variables.
                    </li>
                    <li>Differentiate between variable types.</li>
                    <li>Understand the role of variables in programming.</li>
                  </ul>
                </div>
              </div>
              <div className="try-2">
                <img
                  src="/images/teacher-guides/Teacher.jpg"
                  alt="Cartoon Teacher"
                  className="hidden-xs"
                />
                <p className="heading color--smeeborg">Support</p>
                <div>
                  <p>Have Questions? Check out these resources:</p>
                  <ul>
                    <li>
                      <a
                        href="http://support.kodable.com/en/"
                        className="link"
                        rel="noreferrer"
                        target="_blank">
                        Resource Center
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://support.kodable.com/en/"
                        rel="noreferrer"
                        className="link"
                        target="_blank">
                        Help Center
                      </a>
                    </li>
                    <li>
                      Email us directly at{' '}
                      <a href="mailto:support@kodable.com" className="link">
                        support@kodable.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Section>
          {/* More sections would follow here */}
        </div>
      </div>
    </Fragment>
  );
};

export default SmeeborgGuidePage;
