import StudentNav from './shared/StudentNav';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import useStudent from 'loaders/useStudent';

import StudentOverviewPage from './StudentOverviewPage';
import StudentProjectsPage from './StudentProjectsPage';
import StudentProgressPage from './StudentProgressPage';
import StudentManagePage from './StudentManagePage';

export default function StudentPage() {
  const { path } = useRouteMatch();
  const student = useStudent();

  if (!student.id) {
    return <Redirect to={`/`} />;
  }

  return (
    <div>
      <StudentNav></StudentNav>
      <Switch>
        <Route
          component={StudentOverviewPage}
          exact
          path={`${path}/overview`}
        />
        <Route
          component={StudentProjectsPage}
          exact
          path={`${path}/projects`}
        />
        <Route component={StudentProgressPage} path={`${path}/progress`} />
        <Route component={StudentManagePage} exact path={`${path}/manage`} />
        <Redirect to={`${path}/overview`} />
      </Switch>
    </div>
  );
}
