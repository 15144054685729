import useTeacherInit from "../loaders/useTeacherInit";
import useSWR from "swr";
import {IFuzzData} from "../types/IFuzzData";
import endpoints from "../endpoints";
import {IMazeData} from "../types/IMazeData";
import {useMemo} from "react";
import {compareDesc} from "date-fns";

export interface RecentActivityItem {
  studentName: string;
  studentId: string | number;
  type: 'fuzz' | 'maze';
  thumbnail: string;
  date: string;
}

export const useRecentActivity = (klass_id: number | string) => {
  const {teacherData} = useTeacherInit();
  const {data: fuzzData, error: fuzzDataError} = useSWR<{
    [key: number]: IFuzzData[]
  }>(endpoints.fuzzesByClass(klass_id));
  const fuzzLoading = !fuzzData && !fuzzDataError;
  const {data: mazeData, error: mazeDataError} = useSWR<{
    [key: number]: IMazeData[]
  }>(endpoints.mazesByClass(klass_id));
  const mazeLoading = !mazeData && !mazeDataError;

  const loading = fuzzLoading || mazeLoading;
  const error = fuzzDataError || mazeDataError;

  const {recentActivity, recentActivityCount}: {
    recentActivity: { date: string, activities: RecentActivityItem[] }[],
    recentActivityCount: number
  } = useMemo(() => {
    if (!fuzzData || !mazeData) {
      return {
        recentActivity: [],
        recentActivityCount: 0
      };
    }

    const latestFuzzes: RecentActivityItem[] = [];
    const latestMazes: RecentActivityItem[] = [];

    Object.keys(fuzzData).forEach((studentId) => {
      const fuzzes = fuzzData[studentId as unknown as number];
      const student = teacherData!.students.find(({id}) => id.toString() === studentId.toString());

      if (!student) {
        return;
      }

      fuzzes.forEach(fuzz => {
        latestFuzzes.push({
          studentName: student.name,
          studentId,
          type: 'fuzz',
          thumbnail: endpoints.studentWork('fuzz', 'fullsize', `${student.student_code.slice(0, 5)}${fuzz.client_id}`),
          date: fuzz.created_at
        })
      })
    });

    Object.keys(mazeData).forEach((studentId) => {
      const mazes = mazeData[studentId as unknown as number];
      const student = teacherData!.students.find(({id}) => id.toString() === studentId.toString());

      if (!student) {
        return;
      }

      mazes.forEach(maze => {
        if (!maze.id) {
          return;
        }

        latestMazes.push({
          studentName: student.name,
          studentId,
          type: 'maze',
          thumbnail: endpoints.contentImageById(maze.id),
          date: maze.created_at
        })
      })
    });

    const sorted = latestFuzzes.concat(latestMazes).sort((activityA, activityB) => {
      return compareDesc(new Date(activityA.date), new Date(activityB.date));
    }).slice(0, 10);

    let curDate = new Date(sorted[0]?.date);
    let curBin: { date: string, activities: RecentActivityItem[] } = {
      date: sorted[0]?.date,
      activities: []
    }
    const res: (typeof curBin)[] = [];

    sorted.forEach(activity => {
      const date = new Date(activity.date);
      if (date.getFullYear() === curDate.getFullYear() && date.getMonth() === curDate.getMonth() && date.getDate() === curDate.getDate()) {
        curBin.activities.push(activity)
      } else {
        res.push(curBin);
        curBin = {
          date: activity.date,
          activities: [activity]
        }
        curDate = new Date(activity.date)
      }
    });

    if (curBin.activities.length > 0) {
      res.push(curBin);
    }

    return {recentActivity: res, recentActivityCount: latestFuzzes.length + latestMazes.length};
  }, [teacherData, fuzzData, mazeData]);

  return {recentActivity, loading, error, recentActivityCount}
}