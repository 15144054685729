import { Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Link, Typography } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import Button from "../../ui/buttons/Button"
import TextField from "../../ui/TextField"
import useSharedStyles from "../../useSharedStyles"
import endpoints from "endpoints"
import { useFormik } from "formik"
import { DialogStateProps } from "../../../hooks/useDialogState"
import { justFetch } from "../../../mutations/mutate"
import React, { useEffect } from "react"
import { useState } from "react"

const ForgotPasswordDialog: React.VFC<DialogStateProps & { email?: string }> = ({ open = false, onClose = () => { }, email = '' }) => {
  const [submitError, setSubmitError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      email
    },
    validate: values => {
      if (values.email) {
        return undefined;
      }

      return {
        email: !values.email && 'Please enter the email address of the account you wish to reset'
      }
    },
    onSubmit: values => {
      return justFetch(endpoints.resetPassword.send, 'POST', values)
        .then(res => {
          if (!res.ok) {
            if (res.status === 404) {
              form.setFieldError('email', "We couldn't find an account with this email address")
              setSubmitError(false);
            } else {
              throw new Error();
            }
          } else {
            setShowSuccess(true);
          }

        })
        .catch(() => {
          setSubmitError(true);
        })
    }
  });

  const sharedClasses = useSharedStyles();

  useEffect(() => {
    if (open) {
      form.resetForm();
      setSubmitError(false);
      setShowSuccess(false);
    }
  }, [open])

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    {!showSuccess && <form onSubmit={form.handleSubmit}>
      <DialogTitle>
        Forgot password
      </DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <Typography>Please enter the email address of the account you wish to reset.</Typography>
        <TextField
          id="email"
          name="email"
          label="Email"
          value={form.values.email}
          error={form.submitCount > 0 && !!form.errors.email}
          helperText={form.submitCount > 0 ? form.errors.email : ''}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
        />
        {form.submitCount > 0 && !form.isValid && <Alert severity="error">Please correct the errors above to continue.</Alert>}
        {submitError && <Alert severity="error">There was an error submitting this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="reset"
          onClick={onClose}
          disabled={form.isSubmitting}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={form.isSubmitting}
        >
          reset password
        </Button>
      </DialogActions>
    </form>}
    {showSuccess && <>
      <DialogTitle>
        Forgot password
      </DialogTitle>
      <DialogContent>
        <Box>
          <Alert severity="success">
            <AlertTitle>
              Emailed Password Reset Instructions
            </AlertTitle>
            If you do not receive an email within a few minutes, please check your spam folder and email security settings.
            School spam filters often block password reset emails. <Link href="http://support.kodable.com/troubleshooting/i-am-not-receiving-kodable-emails">Click here to learn how to fix this.</Link>
          </Alert>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box></Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          close
        </Button>
      </DialogActions>
    </>}
  </Dialog>
}

export default ForgotPasswordDialog;