import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import { SubmitOrderPageActions } from "./SubmitOrderPageActions";
import { SubmitOrderTextField } from "./SubmitOrderTextField";
import { useSubmitOrderFormHooks } from "./useSubmitOrderFormHooks";
import { useFormikContext } from "formik";
import { IOrderRequest } from "../IOrderRequest";
import useCurrentUser from "loaders/useCurrentUser";
import React, { useEffect, useState } from "react";

interface SetupAdministratorProps {
  godModeUpgrade?: boolean;
  planForRenewal?: any;
}
export const SetupAdministrator: React.VFC<SetupAdministratorProps> = ({godModeUpgrade, planForRenewal}) => {
  const { currentUser } = useCurrentUser();
  const form = useFormikContext<IOrderRequest>();
  const { allowNext: formValidated, sharedClasses } = useSubmitOrderFormHooks({
    validateFields: ['license_holder_name', 'license_holder_email']
  });
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    const validateForm = async () => {
      await form.validateForm();
    };
    validateForm();
  }, [form.values.license_holder_name, form.values.license_holder_email]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedOption( event.target.value);

    form?.setFieldValue('license_holder_is_submitter', event.target.value === 'current_user')

    if (event.target.value === 'current_user') {
      if (!currentUser) return

      form?.setFieldValue('license_holder_name', currentUser?.name)
      form?.setFieldValue('license_holder_email', currentUser?.username)
    }
    else if (event.target.value === 'upgraded_teacher' || event.target.value === 'existing_admin') {
      form?.setFieldValue('license_holder_name', form.initialValues.license_holder_name)
      form?.setFieldValue('license_holder_email', form.initialValues.license_holder_email)
    }
    else if (event.target.value === 'billing') {
      form?.setFieldValue('license_holder_name', form.values.billing_contact_first_name + " " + form.values.billing_contact_last_name)
      form?.setFieldValue('license_holder_email', form.values.billing_contact_email)
    }
    else if (event.target.value === 'other')
    {
      // don't change, show whatever previous selection was, if changes are wanted
    }
    else
    {
      form?.setFieldValue('license_holder_name', '')
      form?.setFieldValue('license_holder_email', '')
    }
  }

  return <>
    <Box className={sharedClasses.vspacing2}>
      <Box className={sharedClasses.vspacing2}>
        <Typography variant="h1" align="center"><strong>Who will be the main person setting up {form?.values?.organization_name}'s Kodable account?</strong></Typography>
        <Typography variant="body1" align="center">This process will include adding classes and students to the account, as well as inviting any other teachers to access the Premium{form?.values?.has_curriculum ? '+' : ''} features.</Typography>
      </Box>
      <Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleChange}
          >
            {!!currentUser && <FormControlLabel value={'current_user'} control={<Radio />} label={`I ${currentUser?.is_god ? '(God user) ' : ''}will be the main person setting up Kodable`} />}
            {planForRenewal && <FormControlLabel value={'existing_admin'} control={<Radio />} label="Keep existing account manager" />}
            {currentUser?.is_god && godModeUpgrade && <FormControlLabel value={'upgraded_teacher'} control={<Radio />} label="Upgraded Teacher will be the main point of contact setting up Kodable" />}
            <FormControlLabel value={'billing'} control={<Radio />} label="Billing contact will be the main person setting up Kodable" />
            <FormControlLabel value={'other'} control={<Radio />} label="Someone else will be the main point of contact setting up Kodable" />
          </RadioGroup>
        </FormControl>
      </Box>
      <>
        <br />
        <SubmitOrderTextField
          field="license_holder_name"
          label={form?.values?.license_holder_is_submitter ? "Your Name" : "Point of Contact Name"}
          disabled={selectedOption != 'other'}
        />
        <SubmitOrderTextField
          field="license_holder_email"
          label={form?.values?.license_holder_is_submitter ? "Your Email" : "Point of Contact Email"}
          disabled={selectedOption != 'other'}
        />
      </>
    </Box>
    <SubmitOrderPageActions allowPrev allowNext={formValidated && selectedOption != ''} />
  </>
}