import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import { DialogStateProps } from "hooks/useDialogState";
import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";

const QRCodeDialog: React.VFC<DialogStateProps & { classCode: string }> = ({ open = false, onClose, classCode }) => {
  const qrContainerRef = useRef<HTMLDivElement>(null);
  const [qrCodeSize, setQrCodeSize] = useState(400);

  useEffect(() => {
    setTimeout(() => {
      if (qrContainerRef.current) {
        const dim = qrContainerRef.current.getBoundingClientRect().height;
  
        if (dim < 400) {
          setQrCodeSize(dim - 48);
        } else {
          setQrCodeSize(400);
        }
      }
    }, 100)
  }, [open, qrContainerRef.current])

  return <Dialog fullWidth open={open}>
    <DialogTitle style={{ textAlign: 'center' }}>Your Class QR Code</DialogTitle>
    <DialogContent ref={qrContainerRef}>
      <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <div style={{ maxHeight: '100%', maxWidth: '100%', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
          <QRCode value={classCode} level="H" size={qrCodeSize} />
        </div>
        <Box mt={2} maxWidth={500}>
          <Typography paragraph variant="subtitle1">Your students can scan this QR code when adding a class in the Kodable app</Typography>
          <Typography>You can find more information about QR codes <Link href="http://support.kodable.com/en/articles/1289185-can-my-students-scan-a-qr-code-to-login" target="_blank">here</Link>.</Typography>
        </Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <Box display="flex" justifyContent="center" width="100%">
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
    </DialogActions>
  </Dialog>
}

export default QRCodeDialog;