import Modal from 'components/modal/Modal';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import ReactImageFallback from 'react-image-fallback';

export interface LevelModalProps {
  level: { fullsize_url: string; studentData?: { created_at?: string; attempts?: number; star_count?: number; number_of_wins?: number } };
  unit: string;
  closeModal: () => void;
}

const LevelModal: React.FC<LevelModalProps> = ({ level, closeModal }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Modal closeModal={closeModal}>
      <button
        onClick={closeModal}
        className="text-gray-700 absolute right-0 top-0 p-2 lg:p-4 rounded"
        style={{ zIndex: 10 }}>
        <svg
          className="h-6 w-6 lg:h-8 lg:w-8"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="black"
          width="24"
          height="24">
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 011.414 0L10 8.586l4.293-4.293a1 1 011.414 1.414L11.414 10l4.293 4.293a1 1 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 01-1.414-1.414L8.586 10 4.293 5.707a1 1 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div className="pb-4 pt-5">
        <div className="rounded mx-4 my-4 lg:my-8 p-4">

          <div
            style={{
              width: '100%',
              paddingTop: '66.5%', // Aspect ratio 532/800 = 0.665
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '10px',
              border: '2px solid #E1E3E1',
              boxSizing: 'border-box'
            }}
          >
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: '#555',
                  fontSize: '18px'
                }}
              >
                Loading preview...
              </div>
            )}
            <ReactImageFallback 
              alt="level" 
              className="w-full" 
              src={level.fullsize_url} 
              fallbackImage="/images/students/solution_images/no-level.png" 
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0
              }}
              onLoad={() => setIsLoading(false)}
            />
          </div>

          <div className="flex justify-around mt-4 lg:mt-10 text-center">
            <div className="flex flex-col">
              <div className="mx-auto">
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  className="w-8 h-8 lg:w-12 lg:h-12 text-kodable-pink mx-auto mb-2">
                  <path
                    fill="currentColor"
                    d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
                </svg>
                <p className="text-lg text-gray-800">Played</p>
                <p className="text-sm text-gray-700">
                  {level.studentData?.created_at
                    ? format(
                      new Date(level.studentData.created_at),
                      'MM/dd/yyyy'
                    )
                    : 'Not Played'}
                </p>
              </div>
              <div className="mt-4 lg:mt-10">
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="w-8 h-8 lg:w-12 lg:h-12 text-kodable-teal mx-auto mb-2">
                  <path
                    fill="currentColor"
                    d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path>
                </svg>
                <p className="text-lg text-gray-800">Attempts</p>
                <p className="text-sm text-gray-700">
                  {level.studentData?.attempts || 0}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="w-8 h-8 lg:w-12 lg:h-12 text-yellow-500 mb-2"
                  fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <p className="text-lg text-gray-800">Stars</p>
                <p className="text-sm text-gray-700">
                  {level.studentData?.star_count || 0}
                </p>
              </div>
              <div className="mt-4 lg:mt-10">
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  className="w-8 h-8 lg:w-12 lg:h-12 text-kodable-orange mb-2">
                  <path
                    fill="currentColor"
                    d="M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z"></path>
                </svg>
                <p className="text-lg text-gray-800">Wins</p>
                <p className="text-sm text-gray-700">
                  {level.studentData?.number_of_wins || 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LevelModal;
