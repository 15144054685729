import { faPlus, faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  Box,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useAlert } from "context/AlertProvider";
import classNames from "classnames";
import Button from "components/ui/buttons/Button";
import PageContainer from "components/ui/PageContainer";
import PageHeader from "components/ui/PageHeader";
import { format } from "date-fns";
import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useMemo, useState } from "react";
import { IEvent } from "types/IEvent";
import useDialogState from "hooks/useDialogState";
import { EditTeacherDialog } from "./EditEventDialog";
import DeleteEventDialog from "./DeleteEventDialog";

const defaultEvent = {
  id: 0,
  event_name: '',
  event_config: {
    sidebar_banner_img: '',
    sidebar_banner_link: '',
    event_slug: ''
  },
  start_datetime: '',
  end_datetime: ''
};

export const EventsIndex: React.FC = () => {
  const [results, setResults] = useState<IEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const editEventDialogState = useDialogState();
  const [updateEventList, setUpdateEventList] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<IEvent>(defaultEvent);
  const [currentOperation, setCurrentOperation] = useState('create');
  const [activeOnly, setActiveOnly] = useState(false);
  const alert = useAlert();
  
  const handleSearch = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const eventsURL = `${endpoints.godmode.events}?active_only=${activeOnly}`
      const res = await justFetch(eventsURL, 'GET');
      const parsed = await res.json();
      setLoading(false);
      if (res.ok) {
        setResults(parsed);
      } else {
        setError(parsed.error || parsed.message || parsed || `An unknown error occurred: ${res.status}`)
      }
    } catch (e) {
      console.log(e);
      setError('A network error occurred. Are you connected to the internet?')
    }
  }

  const deleteDialog = (event: IEvent) => {
    setCurrentEvent(event);
    setShowDeleteDialog(true);    
  }

  const editDialogHandler = (event: any) => {
    const parsedEvent = {
      ...event,
      event_config: JSON.parse(event.event_config),
    }
    setCurrentEvent(parsedEvent);
    setCurrentOperation('update')
    editEventDialogState.handleOpen();
  }

  const handleDelete = async(id: number) => {
    try {
      const res = await justFetch(endpoints.godmode.events + `/${id}`, 'DELETE');
      setLoading(false);
      setShowDeleteDialog(false);
      if (res.ok) {
        handleSearch();
        alert.success('Event Deleted');
      } else {
        alert.error(`An unknown error occurred: ${res.status}`);
      }
    } catch (e) {
      console.log(e);
      alert.error('A network error occurred. Are you connected to the internet?')
    }
  }

  const fireEventListUpdate = (data: any) => {
    if (data) {
      setUpdateEventList(data);
    }
  }

  const handleActiveOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveOnly(event.target.checked);
  }

  useEffect(() => {
    handleSearch();
  }, [updateEventList, activeOnly]);

  return <PageContainer variant="full">
    <EditTeacherDialog {...editEventDialogState} event={currentEvent} fireUpdate={fireEventListUpdate} operation={currentOperation} />
    <DeleteEventDialog
      onClose={() => setShowDeleteDialog(false)}
      open={showDeleteDialog}
      event={currentEvent}
      onDelete={handleDelete}
    />
    <PageHeader inAppBar title="Events" />
    <form>
      <Box
        display="flex"
        alignItems="center"
        mb={1}
      >
        <Box ml={1}>
          <Button
            color="blue"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            size="large"
            type="button"
            onClick={() => {setCurrentEvent(defaultEvent); setCurrentOperation('create'); editEventDialogState.handleOpen()}}
          >Add new Event</Button>
        </Box>
        <Box ml={1}>
          <FormControlLabel
            control={
              <Switch
                checked={activeOnly}
                onChange={handleActiveOnly}
                name="activeOnly"
                color="primary"
              />
            }
            label="Active Events only"
          />
        </Box>
      </Box>
    </form>
    {loading && <Box display="flex" justifyContent="center" width="100%">
      <CircularProgress />
    </Box>}
    {!!error && <Alert severity="error">{error}</Alert>}
    {!loading && !error && results.length > 0 && <EventTable rows={results} onDelete={deleteDialog} onEdit={editDialogHandler} />}
    {!loading && !error && results.length === 0 && <Alert severity="info">No results found.</Alert>}
  </PageContainer>;
}


function descendingComparator(a: IEvent, b: IEvent, orderBy: keyof IEvent) {
  if (orderBy === 'start_datetime' || orderBy === 'end_datetime') {
    const _a = new Date(a[orderBy]), _b = new Date(b[orderBy]);
    if (_b < _a) {
      return -1;
    }
    if (_b > _a) {
      return 1;
    }
  }
  else if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    return (a[orderBy] as string).localeCompare(b[orderBy] as string)
  } else {
    if (b[orderBy]! < a[orderBy]!) {
      return -1;
    }
    if (b[orderBy]! > a[orderBy]!) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order: 'asc' | 'desc', orderBy: keyof IEvent) {
  return order === 'desc'
    ? (a: IEvent, b: IEvent) => descendingComparator(a, b, orderBy)
    : (a: IEvent, b: IEvent) => -descendingComparator(a, b, orderBy);
}

const headCells: { id: keyof IEvent, label: string }[] = [
  {
    id: 'event_name',
    label: 'Name',
  },  
  {
    id: 'start_datetime',
    label: 'Start Date',
  },
  {
    id: 'end_datetime',
    label: 'End Date',
  }
];

const EventTableHeader: React.FC<{ order: 'asc' | 'desc', orderBy: keyof IEvent, onRequestSort: (property: keyof IEvent) => void }> = ({ order, orderBy, onRequestSort }) => {
  const createSortHandler = (property: keyof IEvent) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const useTableStyles = makeStyles(() => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9'
    }
  }
}))

const EventTable: React.FC<{ rows: IEvent[], onDelete: Function, onEdit: Function }> = ({ rows, onDelete, onEdit }) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof IEvent>('start_datetime');
  const tableClasses = useTableStyles();

  const sortedRows = useMemo(() => {
    return rows.slice().sort(getComparator(order, orderBy));
  }, [order, orderBy, rows])

  const handleRequestSort = (property: keyof IEvent) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return <Paper variant="outlined">
    <Box>
      <TableContainer>
        <Table size="small">
          <EventTableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {sortedRows.map((row, i) => {
              return <TableRow
                key={row.id}
                className={classNames(tableClasses.row)}
              >
                <TableCell><Typography variant="body2">{row.event_name}</Typography></TableCell>
                <TableCell>
                  <Typography variant="body2">{format(new Date(row.start_datetime), 'PP')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{format(new Date(row.end_datetime), 'PP')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" align="right">
                    <Button
                      variant="contained"
                      color="orange"
                      startIcon={<FontAwesomeIcon icon={faEdit} />}
                      size="small"
                      onClick={() => { onEdit(row) }}
                      style={{marginRight: '1rem'}}
                    >
                      Edit
                    </Button>
                    <Button
                      color="red"
                      variant="contained"
                      startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                      size="small"
                      onClick={() => onDelete(row)}
                    >
                      Delete
                    </Button>
                  </Typography>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Paper>
}