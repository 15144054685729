import SectionContainer from "./SectionContainer";
import React from "react";

const IndependentPractice: React.FC<{title: string, description: string}> = ({title, description}) => {
  return (
    <SectionContainer
      title={title}
      icon={'/images/curriculum_images/GamifiedPractice.png'}
      iconColor={'#ed2a7b'}
      innerHtml={description}
    />
  )
}

export default IndependentPractice