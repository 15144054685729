import { useContext, useEffect } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import useCurrentUser from 'loaders/useCurrentUser';
import LandingPage from './LandingPage';
import AddPlayerPage from './AddPlayerPage';
import LinkPlayerPage from './LinkPlayerPage';
import IntroPage from './IntroPage';
import LearningPage from './LearningPage';
import PaywallPage from './PaywallPage';
import PlayToLearnPage from './PlayToLearnPage';
import ProgressPage from './ProgressPage';
import ChoosePlanPage from './ChoosePlanPage';
import { ABTestsContext } from 'context/ABTestProvider';
import { routes } from 'routes';
import OnboardingHeader from "./shared/OnboardingHeader";
import {Box, makeStyles} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export default function OnboardingIndexPage() {
  const { path } = useRouteMatch();
  const { currentUser } = useCurrentUser();
  const { addABTest, abTests, trackEvent } = useContext(ABTestsContext);
  const testId = 'onboarding-screen-size-nov-2023'
  const classes = useStyles()

  useEffect(() => {
    const size = window.innerWidth <= 600 ? 'small' : window.innerWidth <= 960 ? 'medium' : 'large'
    addABTest({
        id: testId,
        segments: [
            {
                id: 1,
                match: size === 'small'
            },
            {
                id: 2,
                match: size === 'medium'
            },
            {
                id: 3,
                match: size === 'large'
            }
        ]
    })
  }, [])

  useEffect(() => {
    trackEvent(testId, {})
  }, [abTests])

  return (
    <Box className={classes.container}>
      <OnboardingHeader />
      <Switch>
        {currentUser.from_school && (
          <Route component={LandingPage} exact path={routes.parentOnboarding.index} />
        )}
        <Route component={AddPlayerPage} path={routes.parentOnboarding.addPlayer} />
        <Route component={LinkPlayerPage} path={`${routes.parentOnboarding.linkPlayer}/:studentCode`} />
        <Route component={LinkPlayerPage} exact path={routes.parentOnboarding.linkPlayer} />
        <Route component={IntroPage} path={routes.parentOnboarding.intro} />
        <Route component={LearningPage} path={routes.parentOnboarding.learning} />
        <Route component={ChoosePlanPage} path={routes.parentOnboarding.plans} />
        <Route component={PaywallPage} path={routes.parentOnboarding.progressUpgrade} />
        <Route component={PlayToLearnPage} path={routes.parentOnboarding.playToLearn} />
        <Route component={ProgressPage} path={routes.parentOnboarding.progress} />
        <Redirect to={currentUser.from_school ? path : routes.parentOnboarding.addPlayer} />
      </Switch>
    </Box>
  );
}
