import useUpdateAccount from 'mutations/useUpdateAccount';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlertDispatch } from 'context/AlertProvider';
import useCurrentUser from 'loaders/useCurrentUser';
import {Box, LinearProgress, Paper, Typography} from "@material-ui/core";
import TextField from "../../components/ui/TextField";
import Button from "../../components/ui/buttons/Button";

export default function PasswordResetPage() {
  const dispatch = useAlertDispatch();
  const { currentUser } = useCurrentUser();
  const updateAccount = useUpdateAccount(currentUser.id);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Please enter a password with at least 8 characters').min(8, 'Please enter a password with at least 8 characters'),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), ''],
        'Passwords must match'
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      updateAccount(values).then((data) => {
        if (data.status === 422) {
          dispatch({
            id: 'updatePassword',
            type: 'error',
            title: 'Password not Updated!',
            message: data.message,
          });
        } else {
          dispatch({
            id: 'updatePassword',
            type: 'success',
            title: 'Password Updated!',
            message: 'Your password was successfully updated.',
          });
          setTimeout(() => {
            dispatch({ type: 'removeAlert', id: 'updatePassword' });
          }, 3000);
          window.location.href = '/';
        }
      });
    },
  });

  return (

    <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" width="100%">
      <Box component={Paper} m={5} p={5}>
        <LinearProgress style={{ visibility: formik.isSubmitting ? 'visible' : 'hidden' }} />
        <Typography variant={'h1'} style={{marginBottom: 15}}>One last thing...</Typography>
        <Typography variant={'body1'} style={{marginBottom: 15}}>To secure your account, please set a password.</Typography>
        <Box>
          <TextField
            placeholder="Password"
            onChange={formik.handleChange}
            value={formik.values.password}
            helperText={formik.errors.password}
            error={!!formik.errors.password}
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting}
            autoComplete="false"
            label={"Password"}
            name="password"
            id="password"
            type="password"
            style={{marginBottom: 15}}
          />
          <TextField
            placeholder="Confirm Password"
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            helperText={formik.touched.confirmPassword ? formik.errors.confirmPassword : ''}
            error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting}
            autoComplete="false"
            label={"Confirm Password"}
            name="confirmPassword"
            id="confirmPassword"
            type="password"/>
        </Box>
        <Box display="flex" paddingTop={2} justifyContent={'flex-end'}>
          <Button
            variant={'contained'}
            color={'primary'}
            type="submit"
            onClick={() => formik.handleSubmit()}
            disabled={
              formik.isSubmitting || !formik.dirty || !formik.isValid
            }
          >
            {formik.isSubmitting ? 'Updating...' : 'Update'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
