import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Amplitude from 'utils/Amplitude';

const VideosPage: React.FC = () => {
  useEffect(() => {
    Amplitude.track('Viewed Videos Page', { Section: 'Landing' });
  }, []);

  return (
    <div>
      <div className="max-w-screen-lg mx-auto flex items-center space-y-8 flex-col lg:flex-row lg:space-x-12 lg:space-y-0 h-screen px-4 mt-10 lg:mt-0">
        <div>
          <Link
            to="/parent/videos/coding-concepts"
            className="flex flex-col justify-center items-center">
            <img
              alt="explain"
              width="438"
              height="231"
              className="rounded-lg max-w-md w-full transition duration-500 transform hover:scale-110"
              src="/images/videos/explain.jpg"
            />
            <button className="bg-kodable-teal hover:bg-kodable-teal-dark text-white rounded px-6 py-2 flex justify-between items-center mt-4">
              View Concept Videos{' '}
              <svg
                aria-hidden="true"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-6 w-6 ml-6">
                <path
                  fill="currentColor"
                  d="M8 256c0 137 111 248 248 248s248-111 248-248S393 8 256 8 8 119 8 256zM256 40c118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216zm12.5 92.5l115.1 115c4.7 4.7 4.7 12.3 0 17l-115.1 115c-4.7 4.7-12.3 4.7-17 0l-6.9-6.9c-4.7-4.7-4.7-12.5.2-17.1l85.6-82.5H140c-6.6 0-12-5.4-12-12v-10c0-6.6 5.4-12 12-12h190.3l-85.6-82.5c-4.8-4.7-4.9-12.4-.2-17.1l6.9-6.9c4.8-4.7 12.4-4.7 17.1 0z"></path>
              </svg>
            </button>
          </Link>
        </div>
        <div>
          <Link
            to="/parent/videos/creative-activities"
            className="flex flex-col justify-center items-center">
            <img
              alt="create"
              width="438"
              height="231"
              className="rounded-lg max-w-md w-full transition duration-500 transform hover:scale-110"
              src="/images/videos/create.jpg"
            />
            <button className="bg-kodable-pink hover:bg-kodable-pink-dark text-white rounded px-6 py-2 flex justify-between items-center mt-4">
              View Activity Videos{' '}
              <svg
                aria-hidden="true"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-6 w-6 ml-6">
                <path
                  fill="currentColor"
                  d="M8 256c0 137 111 248 248 248s248-111 248-248S393 8 256 8 8 119 8 256zM256 40c118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216zm12.5 92.5l115.1 115c4.7 4.7 4.7 12.3 0 17l-115.1 115c-4.7 4.7-12.3 4.7-17 0l-6.9-6.9c-4.7-4.7-4.7-12.5.2-17.1l85.6-82.5H140c-6.6 0-12-5.4-12-12v-10c0-6.6 5.4-12 12-12h190.3l-85.6-82.5c-4.8-4.7-4.9-12.4-.2-17.1l6.9-6.9c4.8-4.7 12.4-4.7 17.1 0z"></path>
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VideosPage;
