import { Link, Redirect, useLocation } from 'react-router-dom';
import useSubscription from 'loaders/useSubscription';
import Loading from 'components/loading/Loading';
import useCurrentUser from 'loaders/useCurrentUser';
import { ISubscriptionSource } from 'types/ISubscription';

export default function UpgradeInfoPage() {
  const { currentUser } = useCurrentUser();
  const { subscription, loading } = useSubscription();

  const location = useLocation<{showUI: string }>();


  if (loading) {
    return (
      <div className="flex justify-center items-center mt-32 h-64">
        <Loading />
      </div>
    );
  }

  if (subscription.source === ISubscriptionSource.Apple) {
    return <Redirect to="/settings" />;
  }

  return (
    <div className="max-w-screen-lg my-8 px-2 mx-auto">
      <div className="flex flex-col">
        <section
          className="w-full h-56 flex justify-center items-center mb-8"
          style={{
            backgroundImage:
              'url(images/parents/upgrade/Kids-Playing-Kodable-On-Ipad.png)',
          }}>
          <h1 className="text-2xl lg:text-3xl text-white font-weight-bold">
            Kodable Pro Membership
          </h1>
        </section>

        <section>
            <h5 className="text-2xl lg:text-3xl text-gray-700 text-center">
              You encourage them, Kodable can teach them
            </h5>
        </section>


        <p className="lg:text-xl font-light text-center mt-6">
          Check out our{' '}
          <a
            className="underline text-kodable-blue"
            href="https://bit.ly/2P7CVvg"
            target="_blank"
            rel="noopener noreferrer">
            Gifting Options
          </a>
        </p>

        <div className="mt-6 flex justify-center lg:justify-between flex-wrap">
          <div className="max-w-md lg:max-w-xs border-2 border-kodable-teal rounded-lg flex flex-col w-full lg:w-1/3 mx-2 my-4 lg:flex-1 bg-white">
            <h4 className="bg-kodable-teal text-white font-bold text-3xl px-4 pt-4 pb-8  text-center">
              Monthly
            </h4>
            <svg
              className="-mt-8 w-full"
              height="60px"
              id="Layer_1"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 300 100"
              width="300px"
              x="0px"
              xmlns="http://www.w3.org/2000/svg"
              y="0px">
              <path
                className="pricing__wave--1"
                d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                fill="#FFFFFF"
                opacity="0.6"></path>
              <path
                className="pricing__wave--2"
                d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                fill="#FFFFFF"
                opacity="0.6"></path>
              <path
                className="pricing__wave--3"
                d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
	H42.401L43.415,98.342z"
                fill="#FFFFFF"
                opacity="0.7"></path>
              <path
                className="pricing__wave--4"
                d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                fill="#FFFFFF"></path>
            </svg>
            <div className="flex-1 py-8 flex justify-center items-center flex-col">
              <p className="text-sm">
                <span className="text-2xl font-light">$24.99</span>/month
              </p>
              <p className="text-xs">Billed Monthly</p>
            </div>
            <div className="mx-auto p-4">
              <Link
                to={{ pathname: "/parent/upgrade/plan/monthly", state: { showUI: location.state?.showUI } }}
                className="mt-4 relative bg-kodable-orange text-white px-6 py-2 rounded hover:bg-kodable-orange-dark active:bg-kodable-orange-darker">
                {currentUser?.trialed ? 'Subscribe' : 'Start 7 Day Free Trial'}
              </Link>
            </div>
          </div>
          <div className="max-w-md min-w-64 lg:max-w-xs border-2 border-lime-500 rounded-lg flex flex-col w-full lg:w-1/3 mx-2 my-4 lg:flex-1 bg-white">
            <h4 className="bg-lime-500 text-white font-bold text-3xl px-4 pt-4 pb-8 text-center">
              Yearly
            </h4>
            <svg
              className="-mt-8 w-full"
              height="60px"
              id="Layer_1"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 300 100"
              width="300px"
              x="0px"
              xmlns="http://www.w3.org/2000/svg"
              y="0px">
              <path
                className="pricing__wave--1"
                d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                fill="#FFFFFF"
                opacity="0.6"></path>
              <path
                className="pricing__wave--2"
                d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                fill="#FFFFFF"
                opacity="0.6"></path>
              <path
                className="pricing__wave--3"
                d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
	H42.401L43.415,98.342z"
                fill="#FFFFFF"
                opacity="0.7"></path>
              <path
                className="pricing__wave--4"
                d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                fill="#FFFFFF"></path>
            </svg>
            <div className="flex-1 py-8 flex justify-center items-center flex-col">
              <p className="text-sm">
                <span className="text-2xl font-light">$99.99</span>/year
              </p>
              <p className="text-xs">Billed Annually</p>
            </div>
            <div className="mx-auto p-4">
              <Link
                to={{ pathname: "/parent/upgrade/plan/yearly", state: { showUI: location.state?.showUI } }}
                className="mt-4 relative bg-kodable-orange text-white px-6 py-2 rounded hover:bg-kodable-orange-dark active:bg-kodable-orange-darker">
                {currentUser?.trialed ? 'Subscribe' : 'Start 7 Day Free Trial'}
              </Link>
            </div>
          </div>
          <div className="max-w-md min-w-64 lg:max-w-xs border-2 border-purple-800 rounded-lg flex flex-col w-full lg:w-1/3 mx-2 my-4 lg:flex-1 bg-white">
            <h4 className="bg-purple-800 text-white font-bold text-3xl px-4 pt-4 pb-8 text-center">
              Lifetime
            </h4>
            <svg
              className="-mt-8 w-full"
              height="60px"
              id="Layer_1"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 300 100"
              width="300px"
              x="0px"
              xmlns="http://www.w3.org/2000/svg"
              y="0px">
              <path
                className="pricing__wave--1"
                d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                fill="#FFFFFF"
                opacity="0.6"></path>
              <path
                className="pricing__wave--2"
                d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                fill="#FFFFFF"
                opacity="0.6"></path>
              <path
                className="pricing__wave--3"
                d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
	H42.401L43.415,98.342z"
                fill="#FFFFFF"
                opacity="0.7"></path>
              <path
                className="pricing__wave--4"
                d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                fill="#FFFFFF"></path>
            </svg>
            <div className="flex-1 py-8 flex justify-center items-center flex-col">
              <p className="text-sm">
                <span className="text-2xl font-light">$199.99</span>
              </p>
              <p className="text-xs">Billed Once</p>
            </div>
            <div className="mx-auto p-4">
              <Link
                to={{ pathname: "/parent/upgrade/plan/lifetime", state: { showUI: location.state?.showUI } }}
                className="mt-4 relative bg-kodable-green text-white px-6 py-2 rounded hover:bg-kodable-green-dark active:bg-kodable-green-darker">
                Buy Now
              </Link>
            </div>
          </div>
        </div>

      <p className="text-sm text-center text-gray-500 mb-8">*All prices in USD</p>

        <section className="flex flex-col">
          <h2 className="text-2xl lg:text-3xl text-gray-700 pb-8 mx-auto">
            Everything you need to help them succeed
          </h2>
          <div className="flex justify-between relative items-center">
            <div className="flex-1 flex justify-center items-center flex-col">
              <img
                className="w-24 sm:w-32 lg:w-48"
                src="/images/parents/upgrade/badges/Game-Element-Screenshot.jpg"
                alt="Drag and Drop Code Screenshot"
              />
              <h3 className="text-base lg:text-xl text-gray-700 font-semibold text-center mt-4">
                Drag and drop programming
              </h3>
            </div>
            <div className="flex-1 flex justify-center items-center flex-col">
              <img
                className="w-24 sm:w-32 lg:w-48"
                src="/images/parents/upgrade/badges/Custom-Fuzz.jpg"
                alt="Custom Fuzz Example"
              />
              <h3 className="text-base lg:text-xl text-gray-700 font-semibold text-center mt-4">
                Create Characters
              </h3>
            </div>
            <div className="flex-1 flex justify-center items-center flex-col">
              <img
                className="w-24 sm:w-32 lg:w-48"
                src="/images/parents/upgrade/badges/Maze-Maker-Badge.jpg"
                alt="Custom Level Editor Badge"
              />
              <h3 className="text-base lg:text-xl text-gray-700 font-semibold text-center mt-4">
                Build Levels
              </h3>
            </div>
            <div className="flex-1 flex justify-center items-center flex-col h-64">
              <img
                className="w-24 sm:w-32 lg:w-48 my-8 sm:my-12 lg:my-16"
                src="/images/parents/upgrade/badges/web_function.jpg"
                alt="Function Icon"
              />
              <h3 className="text-base lg:text-xl text-gray-700 font-semibold text-center">
                Transition into real code
              </h3>
            </div>
          </div>
          <div className="flex flex-wrap mt-12 justify-around xl:justify-between items-start">
            <div className="max-w-xs w-full lg:w-1/2 xl:w-1/3 mb-12">
              <img
                src="/images/parents/upgrade/webpic_smeeborg.jpg"
                alt="smeeborg"
                className="w-full"
              />
              <h4 className="text-2xl font-light text-gray-700 text-center my-2">
                Smeeborg
              </h4>
              <div className="flex flex-wrap my-2">
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Beginner
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Logic
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Critical Thinking
                </span>
              </div>
              <p className="text-sm text-gray-700">
                Learn foundational skills that are part of every programming
                language. Symbol based code makes it easy for pre-readers and
                new coders alike. Solve puzzles to help the fuzz family roll
                through the world of Smeeborg.
              </p>
            </div>
            <div className="max-w-xs w-full lg:w-1/2 xl:w-1/3 mb-12">
              <img
                src="/images/parents/upgrade/webpic_asteroidia.jpg"
                alt="asteroidia"
                className="w-full"
              />
              <h4 className="text-2xl font-light text-gray-700 text-center my-2">
                Asteroidia
              </h4>
              <div className="flex flex-wrap my-2">
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Intermediate
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Cause & Effect
                </span>
              </div>
              <p className="text-sm text-gray-700">
                Begin your introduction to variables and data types. Learn to
                problem solve in a sequential fashion as you journey into the
                galaxy and navigate the asteroid field of Asteroidia.
              </p>
            </div>
            <div className="max-w-xs w-full lg:w-1/2 xl:w-1/3 mb-12">
              <img
                src="/images/parents/upgrade/webpic_bugworld.jpg"
                alt="bug world"
                className="w-full"
              />
              <h4 className="text-2xl font-light text-gray-700 text-center my-2">
                Bug World
              </h4>
              <div className="flex flex-wrap my-2">
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Advanced
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Strategy
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  JavaScript / Swift
                </span>
              </div>
              <p className="text-sm text-gray-700">
                Easily transition from symbol based code to written code in
                JavaScript or Swift. Learn about Object-Oriented Programming
                languages while you battle the Slimes of Bug World.
              </p>
            </div>

            <div className="max-w-xs w-full lg:w-1/2 xl:w-1/3 mb-12">
              <img
                src="/images/parents/upgrade/webpic_mazemaker.jpg"
                alt="maze maker"
                className="w-full"
              />
              <h4 className="text-2xl font-light text-gray-700 text-center my-2">
                Maze Maker
              </h4>
              <div className="flex flex-wrap my-2">
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Beginner
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Logic
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Creativity
                </span>
              </div>
              <p className="text-sm text-gray-700">
                Practice foundational skills from every programming language as
                you create beautiful mazes to share with family. New skills and
                options unlock as you master new concepts.
              </p>
            </div>
            <div className="max-w-xs w-full lg:w-1/2 xl:w-1/3 mb-12">
              <img
                src="/images/parents/upgrade/webpic_fuzzbuilder.jpg"
                alt="fuzz builder"
                className="w-full"
              />
              <h4 className="text-2xl font-light text-gray-700 text-center my-2">
                Build Your Own Fuzz
              </h4>
              <div className="flex flex-wrap my-2">
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Intermediate
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Programming
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Creativity
                </span>
              </div>
              <p className="text-sm text-gray-700">
                Use creativity and imagination to practice your coding skills
                building your very own fuzzes. Unlock hundreds of costume
                combinations as you explore Kodable.
              </p>
            </div>
            <div className="max-w-xs w-full lg:w-1/2 xl:w-1/3 mb-12">
              <img
                src="/images/parents/upgrade/webpic_gamedesigner.jpg"
                alt="game designer"
                className="w-full"
              />
              <h4 className="text-2xl font-light text-gray-700 text-center my-2">
                Game Designer
              </h4>
              <div className="flex flex-wrap my-2">
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Advanced
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  JavaScript / Swift
                </span>
                <span className="bg-gray-400 text-white text-xs rounded-2xl m-1 py-1 px-2">
                  Iterative Design
                </span>
              </div>
              <p className="text-sm text-gray-700">
                Become a master game designer! Design games by modifying
                properties of their code. Make your games easier or harder by
                modifying properties unlocked as you play.
              </p>
            </div>
          </div>
        </section>
      </div>
      <section>
        <h5 className="text-gray-800 text-3xl text-center mb-8">
          All plans include
        </h5>
        <div className="flex lg:flex-row flex-col mx-auto w-full max-w-xs lg:max-w-full lg:justify-around lg:items-center">
          <ul>
            <li className="flex justify-start items-center text-gray-700 mb-2">
              <svg
                className="h-6 w-6 text-kodable-teal mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Hundreds of coding activities
            </li>
            <li className="flex justify-start items-center text-gray-700 mb-2">
              <svg
                className="h-6 w-6 text-kodable-teal mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Unlimited creative options
            </li>
            <li className="flex justify-start items-center text-gray-700 mb-2">
              <svg
                className="h-6 w-6 text-kodable-teal mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              4 player profiles
            </li>
            <li className="flex justify-start items-center text-gray-700 mb-2">
              <svg
                className="h-6 w-6 text-kodable-teal mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Adaptive hinting system
            </li>
          </ul>
          <ul>
            <li className="flex justify-start items-center text-gray-700 mb-2">
              <svg
                className="h-6 w-6 text-kodable-teal mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Modifiable Kodable games to share
            </li>
            <li className="flex justify-start items-center text-gray-700 mb-2">
              <svg
                className="h-6 w-6 text-kodable-teal mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Challenges covering 11 concepts
            </li>
            <li className="flex justify-start items-center text-gray-700 mb-2">
              <svg
                className="h-6 w-6 text-kodable-teal mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              New challenges released regularly
            </li>
            <li className="flex justify-start items-center text-gray-700 mb-2">
              <svg
                className="h-6 w-6 text-kodable-teal mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Access to exclusive new features
            </li>
          </ul>
        </div>
      </section>
      <section className="bg-kodable-teal text-white p-4 my-8">
        <h5 className="text-2xl lg:text-3xl my-8 text-center font-light">
          Skip Cartoons, Write Code
        </h5>
        <p className="text-center mx-auto font-light px-4">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="quote-left"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-3 h-3 inline-block -mt-4 mr-1">
            <path
              fill="currentColor"
              d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
          </svg>
          My 5th grade daughter has been coding for 2 years and loves it. My 7
          year old daughter has recently expressed interest as well. We attended
          STEM night at their school and Kodable was the program that the
          instructor recommended. She loved it immediately and can't get enough!
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="quote-right"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-3 h-3 inline-block -mt-4 ml-1">
            <path
              fill="currentColor"
              d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
          </svg>
        </p>
        <p className="font-bold my-4 text-center">
          Instead of asking to watch Netflix, she's asking to code.
        </p>
        <p className="font-thin my-4 text-right opacity-75">
          - BreeH, Bellvue, WA
        </p>
        <div className="mx-auto p-4 flex justify-center items-center">
          <Link
            to={{ pathname: "/upgrade/plan/monthly", state: { showUI: location.state?.showUI } }}
            className="relative bg-kodable-orange text-white px-6 py-2 rounded hover:bg-kodable-orange-dark active:bg-kodable-orange-darker">
              {currentUser?.trialed ? 'Subscribe' : 'Start 7 Day Free Trial'}
          </Link>
        </div>
      </section>
      <section>
        <h5 className="text-2xl lg:text-3xl my-8 text-center font-light text-gray-700">
          Recommended by Experts in Education
        </h5>
        <div className="flex justify-around items-center">
          <a
            href="http://www.imaginek12.com"
            target="_blank"
            className="mx-2"
            rel="noopener noreferrer">
            <img
              className="w-full"
              src="/images/parents/upgrade/experts/Imagine-K12-Logo.png"
              alt="Imagine K12"
            />
          </a>
          <a
            href="https://www.bestappsforkids.com/2013/kodable-pro/"
            target="_blank"
            className="mx-2"
            rel="noopener noreferrer">
            <img
              className="w-full"
              src="/images/parents/upgrade/experts/Apps-4-Kids-Logo.png"
              alt="Apps 4 Kids"
            />
          </a>
          <a
            href="https://www.commonsense.org/education/app/kodable"
            target="_blank"
            className="mx-2"
            rel="noopener noreferrer">
            <img
              className="w-full"
              src="/images/parents/upgrade/experts/Common-Sense-Logo.png"
              alt="Common Sense"
            />
          </a>
          <a
            href="https://www.teacherswithapps.com/learn-code-ipad/"
            target="_blank"
            className="mx-2"
            rel="noopener noreferrer">
            <img
              className="w-full"
              src="/images/parents/upgrade/experts/Teachers-With-Apps-Logo.png"
              alt="Teachers With Apps"
            />
          </a>
        </div>
      </section>
      <section className="p-4 my-12">
        <h5 className="text-2xl lg:text-3xl my-4 text-center font-light">
          Kodable builds a strong foundation
        </h5>
        <div className="mt-12 lg:block flex flex-col justify-center items-center">
          <div className="flex flex-col-reverse lg:flex-row mb-8">
            <div className="flex-1 flex flex-col justify-center py-2 lg:px-4">
              <h5 className="text-xl lg:text-2xl font-light mb-2">
                Learn the basics of every language
              </h5>
              <p className="text-gray-700 text-sm lg:text-base max-w-md font-thin">
                Smeeborg introduces kids to the foundational concepts used in
                every programming language. These concepts include algorithms,
                conditions, loops, and functions.
              </p>
            </div>
            <div className="flex-1 max-w-md">
              <img
                src="/images/parents/upgrade/navbar.jpg"
                alt="Generic Navigation Bar"
              />
              <img
                src="/images/parents/upgrade/screenshots/Smeeborg-Maze-Screenshot.png"
                alt="Smeeborg Maze"
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row mb-8">
            <div className="flex-1 max-w-md">
              <img
                src="/images/parents/upgrade/navbar.jpg"
                alt="Generic Navigation Bar"
              />
              <img
                src="/images/parents/upgrade/screenshots/Fuzz-Builder-Screenshot.png"
                alt="Fuzz Builder"
              />
            </div>
            <div className="flex-1 flex flex-col justify-center py-2 lg:pl-12">
              <h5 className="text-xl lg:text-2xl font-light mb-2">
                Language immersion for kids
              </h5>
              <p className="text-gray-700 text-sm lg:text-base max-w-md font-thin">
                Explore JavaScript and Swift with ease in the Kid Optimized
                Developer Environment found only in Kodable. Kids will be free
                to explore programming without the common difficulties that
                often cause kids to stop coding early on. Focusing only on the
                content that matters helps kids foster a love for coding that
                will cary them forward as they grow.
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-row mb-8">
            <div className="flex-1 flex flex-col justify-center py-2 lg:px-4">
              <h5 className="text-xl lg:text-2xl font-light mb-2">
                Explore code for the first time
              </h5>
              <p className="text-gray-700 text-sm lg:text-base max-w-md font-thin">
                Kids learn to code in a friendly and engaging world. Each
                concept is presented with a guided tutorial.
              </p>
            </div>
            <div className="flex-1 max-w-md">
              <img
                src="/images/parents/upgrade/navbar.jpg"
                alt="Generic Navigation Bar"
              />
              <img
                src="/images/parents/upgrade/screenshots/Level-Help-Screenshot.png"
                alt="Level Help"
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row mb-8">
            <div className="flex-1 max-w-md">
              <img
                src="/images/parents/upgrade/navbar.jpg"
                alt="Generic Navigation Bar"
              />
              <img
                src="/images/parents/upgrade/screenshots/Game-Builder-Screenshot.png"
                alt="Game Builder"
              />
            </div>
            <div className="flex-1 flex flex-col justify-center py-2 lg:pl-12">
              <h5 className="text-xl lg:text-2xl font-light mb-2">
                Learn to create games
              </h5>
              <p className="text-gray-700 text-sm lg:text-base max-w-md font-thin">
                Smeeborg introduces kids to the foundational concepts used in
                every programming language. These concepts include algorithms,
                conditions, loops, and functions.
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-row mb-8">
            <div className="flex-1 flex flex-col justify-center py-2 lg:px-4">
              <h5 className="text-xl lg:text-2xl font-light mb-2">
                Hints help when needed
              </h5>
              <p className="text-gray-700 text-sm lg:text-base max-w-md font-thin">
                FuzzBuzz the handy fuzz fairy brings helpful hints when students
                need a suggestion. FuzzBuzz hints adapt to kids' answers and
                number of attempts for perfectly balanced challenges.
              </p>
            </div>
            <div className="flex-1 max-w-md">
              <img
                src="/images/parents/upgrade/navbar.jpg"
                alt="Generic Navigation Bar"
              />
              <img
                src="/images/parents/upgrade/screenshots/Smeeborg-Game-Screenshot.png"
                alt="Smeeborg Game"
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row mb-8">
            <div className="flex-1 max-w-md">
              <img
                src="/images/parents/upgrade/navbar.jpg"
                alt="Generic Navigation Bar"
              />
              <img
                src="/images/parents/upgrade/screenshots/Maze-Builder-Screenshot.png"
                alt="Maze Builder"
              />
            </div>
            <div className="flex-1 flex flex-col justify-center py-2 lg:pl-12">
              <h5 className="text-xl lg:text-2xl font-light mb-2">
                Design levels and challenge others
              </h5>
              <p className="text-gray-700 text-sm lg:text-base max-w-md font-thin">
                Kids can start creating games and levels to test their coding
                skills. Each game area of Kodable also has an editor where kids
                can design and test their own games, then share them with
                others.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-kodable-teal text-white p-4 mt-8 mb-16">
        <h5 className="text-2xl lg:text-3xl my-4 text-center font-light">
          Start teaching your child to code
        </h5>
        <div className="mx-auto p-4 flex justify-center items-center">
          <Link
            to={{ pathname: "/parent/upgrade/plan/monthly", state: { showUI: location.state?.showUI } }}
            className="relative bg-kodable-orange text-white px-6 py-2 rounded hover:bg-kodable-orange-dark active:bg-kodable-orange-darker">
            {currentUser?.trialed ? 'Subscribe' : 'Start 7 Day Free Trial'}
          </Link>
        </div>
      </section>
    </div>
  );
}
