import { Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import { Alert } from "@material-ui/lab";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useFormik } from "formik";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import React, { useState } from "react";
import { useEffect } from "react";
import * as Yup from 'yup';
import { useRedirectToLogin } from "context/AuthProvider";

interface DeleteProfileDialogProps {
  open: boolean;
  onClose: (...args: any[]) => any
}

const DeleteAccountDialog: React.VFC<DeleteProfileDialogProps> = ({ open, onClose }) => {
  const { currentUser } = useCurrentUser();
  const { redirectToLogin } = useRedirectToLogin();
  const [ deletionNotAllowedAlert, setDeletionNotAllowedAlert ] = useState(false);

  const form = useFormik({
    validateOnChange: false,
    validateOnMount: true,
    initialValues: {
      confirm: ''
    },
    validationSchema: Yup.object({
      confirm: Yup.string().required('Please type DELETE').matches(/delete/i, 'Please type DELETE')
    }),
    onSubmit: async () => {
      try {
        const res = await justFetch(endpoints.user(currentUser?.id.toString()), 'DELETE');
        if (!res.ok) {
          setSubmitError(true);
          if (res.status === 422) setDeletionNotAllowedAlert(true);
        } else {
          redirectToLogin(true);
        }
      } catch {
        return setSubmitError(true);
      }
    }
  });
  const [submitError, setSubmitError] = useState(false);
  const sharedClasses = useSharedStyles();

  useEffect(() => {
    if (open) {
      form.handleReset(null);
      setSubmitError(false);
    }
  // eslint-disable-next-line
  }, [open]);

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>Delete account?</DialogTitle>
      <DialogContent>
        <label htmlFor="confirm">
          <Typography
            variant="subtitle1"
          >Type DELETE to confirm that you want to delete your account:</Typography>
        </label>
        <TextField
          value={form.values.confirm}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={form.errors.confirm}
          error={!!form.errors.confirm}
          disabled={form.isSubmitting}
          id="confirm"
          type="text"
          variant="outlined"
          fullWidth
          margin="dense"
        />
        <Box my={2} {...{ component: Alert, severity: 'warning' }}>
          This action cannot be reversed!
        </Box>
        {submitError && <Alert severity="error" action={
          !deletionNotAllowedAlert && <Button
            color="inherit"
            size="small"
            onClick={() => form.submitForm()}
          >Try again</Button>
        }>{deletionNotAllowedAlert ? "Cannot delete this account" : "There was an error trying to delete your account."}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          classes={{ root: sharedClasses.dangerButton }}
          disabled={form.isSubmitting}
          type="submit"
        >
          Delete
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default DeleteAccountDialog;