import {Box, MenuItem, Select, useTheme} from "@material-ui/core"
import useTeacherInit from "loaders/useTeacherInit";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {GameTitle} from "../../enums/GameTitle";

export interface IGameSelectDropdownProps {
  klassId?: number;
  selectedGame: GameTitle;
  setSelectedGame: Dispatch<SetStateAction<GameTitle>>;
}

export const GameSelectDropdown: React.FC<IGameSelectDropdownProps> = ({klassId, selectedGame, setSelectedGame}) => {
  const theme = useTheme()
  const {teacherData} = useTeacherInit()
  const [assignedGames, setAssignedGames] = useState<GameTitle[]>([GameTitle.BASICS, GameTitle.CREATOR])

  useEffect(() => {
    if (!klassId) {
      setSelectedGame(GameTitle.BASICS)
      setAssignedGames([GameTitle.BASICS, GameTitle.CREATOR])
      return
    }
    if (!teacherData || !teacherData?.klasses) {
      return
    }
    const klass = teacherData?.klasses?.find(klass => klass.id === klassId)
    if (!klass || !klass?.assignments?.assigned_games || !klass?.assignments?.assigned_games) {
      setSelectedGame(GameTitle.BASICS)
      setAssignedGames([GameTitle.BASICS, GameTitle.CREATOR])
      return
    }
    // Sort AssignedGames so that basics is always first (if it exists)
    const sortedAssignedGames = klass.assignments?.assigned_games?.sort((a, b) => {
      if (a === GameTitle.BASICS) {
        return -1
      }
      if (b === GameTitle.BASICS) {
        return 1
      }
      return 0
    })
    setSelectedGame(sortedAssignedGames[0])
    setAssignedGames(sortedAssignedGames)
  }, [klassId, teacherData])

  return (
    <Box>
      <Box>
        <Select
          label={<></>}
          value={selectedGame}
          onChange={e => {setSelectedGame(e.target.value as GameTitle)}}
          style={{
            background: selectedGame === GameTitle.BASICS ? theme.palette.newOrange.main : theme.palette.newPink.main,
            padding: '0 .5rem',
            borderRadius: 4
          }}
          disableUnderline
        >
          {assignedGames.map(game => {
            return (
              <MenuItem
                selected={game === selectedGame}
                key={game}
                value={game}
                style={{
                  background: game === GameTitle.BASICS ? theme.palette.newOrange.main : theme.palette.newPink.main,
                }}
              >
                <img
                  height="28"
                  alt={game === GameTitle.BASICS ? "Kodable Basics" : "Kodable Creator"}
                  src={game === GameTitle.BASICS ? "/images/logos/kodable-basics-white-horizontal.svg" : "/images/logos/kodable-creator-white-horizontal.svg"}
                  style={{
                    height: 28
                  }}
                />
              </MenuItem>
            )
          })
          }
        </Select>
      </Box>
    </Box>
  )
}