import React from 'react';
import CategoryVideos from './shared/CategoryVideos';

const CodingConceptVideosPage: React.FC = () => {
  return (
    <CategoryVideos
      title="Coding Concept Videos"
      resourceType="coding_concept_video"
      link="coding-concepts"
    />
  );
}

export default CodingConceptVideosPage;
