import { Box, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import useDialogState from "hooks/useDialogState";
import { GenerateMultipleClassLinksDialog } from "pages/hoc/GenerateMultipleClassLinksDialog";
import { MultiClassPrintoutDialog } from "pages/printouts/MultiClassPrintoutDialog";
import { routes } from "routes";
import { IKlass } from "types/IKlass";
import ParentInvites, { TrackParentInviteMethod, TrackParentInviteMethodLocation } from "utils/ParentInvites";
import ParentConnectLinkDialog from "../../dialogs/parent/ParentConnectLinkDialog";
import React from "react";
import ParentLettersPDF from "../../../pages/printouts/ParentLettersPDF";
import useTeacherInit from "../../../loaders/useTeacherInit";

export const ConnectWithFamiliesButton: React.FC<{ klass?: IKlass, onClose?: () => void, event?: 'valentines' }> = ({ klass, onClose, event }) => {
  const sharedClasses = useSharedStyles();
  const parentConnectLinkDialogState = useDialogState();
  const printParentLettersDialogState = useDialogState();
  const { teacherData } = useTeacherInit();

  return <>
    {teacherData && <MultiClassPrintoutDialog
      title="Generate Parent Letters"
      helperText="Select the classes for which you want to generate parent letters."
      open={printParentLettersDialogState.open}
      onClose={() => {
        printParentLettersDialogState.onClose();
        onClose?.();
      }}
      handoutUrl={event === 'valentines' ? (klassId) => routes.handouts.valentinesHandouts(klassId).slice(1) : undefined}
      handout={event === 'valentines' ? undefined :(klassId) =>  <ParentLettersPDF classId={klassId.toString()} teacherData={teacherData} />}
      filename={klass => event === 'valentines' ? `Kodable Valentines - ${klass.klass_name || "New Class"}` : `Kodable Parent Letters - ${klass.klass_name || "New Class"}`}
      selectKlass={klass?.id}
      trackingLocation={TrackParentInviteMethodLocation.inviteFamiliesButton}
      v2
    />}
    {klass && <ParentConnectLinkDialog
      open={parentConnectLinkDialogState.open}
      onClose={() => {
        parentConnectLinkDialogState.onClose();
        onClose?.()
      }}
      selectedKlassId={klass.id}
      event={event} />
    }
    {!klass && <GenerateMultipleClassLinksDialog open={parentConnectLinkDialogState.open} onClose={() => { parentConnectLinkDialogState.onClose(); onClose?.(); }} event={event} location={TrackParentInviteMethodLocation.inviteFamiliesButton} />}
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={sharedClasses.vspacing2}>
      <Box display="flex" alignItems="center" justifyContent="center" className={sharedClasses.hspacing2}>
        <Button
          variant="contained"
          color="primary"
        >
          <Box
            display="flex"
            flexDirection="column"
            style={{ textTransform: 'initial' }}
            onClick={() => {
              parentConnectLinkDialogState.handleOpen();
              ParentInvites.track(
                klass,
                TrackParentInviteMethod.clicked,
                TrackParentInviteMethodLocation.inviteFamiliesButton
              )
            }}
          >
            <Typography variant="subtitle1">Class Invite Link</Typography>
            <Typography>Get a link to share with parents</Typography>
          </Box>
        </Button>
        <Button
          variant="outlined"
        >
          <Box
            display="flex"
            flexDirection="column"
            style={{ textTransform: 'initial' }}
            onClick={() => {
              printParentLettersDialogState.handleOpen()
              ParentInvites.track(
                klass,
                TrackParentInviteMethod.clicked,
                TrackParentInviteMethodLocation.inviteFamiliesButton
              )
            }}
          >
            <Typography variant="subtitle1">Individual Printouts</Typography>
            <Typography>Get a Parent Code for each student</Typography>
          </Box>
        </Button>
      </Box>
    </Box>
  </>;
}
