import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, makeStyles } from "@material-ui/core";
import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";
import React, { ChangeEvent, useState } from "react";

type UploadButtonProps = {
    id?: string | number;
    maxFileSize?: number;
    buttonText?: string;
    allowedFileTypes: string[];
    setImageURL: (url: string) => void;
    uploadPath?: string | null;
    disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    input: {
      display: 'none',
    },
}));

function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes'
    }
  
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  
    const i = Math.floor(Math.log(bytes) / Math.log(k))
  
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const UploadButton: React.FC<UploadButtonProps> = (props) => {
    const { id = (Math.floor(Math.random() * (1000 - 1)) + 1), maxFileSize = 1000000, allowedFileTypes, buttonText = 'Upload', setImageURL, uploadPath, disabled = false} = props;
    const classes = useStyles();
    const [fileError, setFileError] = useState<string | null>(null);
    const [uploading, setUploading] = useState<boolean>(false)
    const [uploaded, setUploaded] = useState<boolean>(false)

    const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        setUploading(true)
        const file = event.target.files && event.target.files[0];
        if (!file) {
          return
        }

        let { name, type, size } = file

        name = name.replace(/\s/g, '')

        if (!allowedFileTypes.includes(type)) {
            setFileError(`File type must be ${allowedFileTypes.join(', ')}`)
            return
        }

        if (size > maxFileSize) {
            setFileError(`File size must be under ${formatBytes(maxFileSize)}`)
            return
        }

        setFileError(null);

        const reader = new FileReader();

        reader.onload = async (event) => {
            const base64Image = (event.target?.result || '') as string

            justFetch(endpoints.resourcesUpload, "POST", {
                file_name: name,
                file_base64: base64Image,
                file_directory: uploadPath
            })
            .then(async (response) => {
                if (response.ok) {
                    setImageURL(`https://s3.us-west-2.amazonaws.com/resources.kodable.com/${uploadPath ? `${uploadPath}/` : ''}${name}`)
                    setUploading(false)
                    setUploaded(true)
                    return
                }
                setFileError('Error uploading file')
                setUploading(false)
            })
            .catch((error) => {
                setFileError(error)
                setUploading(false)
            })
        }
        
        reader.readAsDataURL(file)
    };
    
    if (uploaded) {
      return null
    }
    
    return (
      <div>
        <input
          accept={allowedFileTypes.join(', ')}
          className={classes.input}
          id={`upload-button-${id}`}
          type="file"
          onChange={handleUpload}
          disabled={uploading}
        />
        <label htmlFor={`upload-button-${id}`}>
            <Button
                startIcon={<FontAwesomeIcon icon={faUpload} />}
                disabled={uploading || disabled}
                variant="contained"
                color={uploading ? 'secondary' : 'primary'}
                component="span"
            >
                {uploading ? "Uploading..." : buttonText}
            </Button>
        </label>
        {fileError && <p>{fileError}</p>}
      </div>
    )
  }
  
  export default UploadButton;