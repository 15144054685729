import useCurrentUser from "loaders/useCurrentUser"
import ParentSettingsPage from "./SettingsPage";
import TeacherProfilePage from "./teacher/TeacherProfilePage";
import React from "react";

const SettingsPage: React.VFC = () => {
  const { currentUser } = useCurrentUser();

  if (currentUser.is_parent) {
    return <ParentSettingsPage />
  }

  return <TeacherProfilePage />;
}

export default SettingsPage;