import {Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core"
import { GodmodePageWrapper } from "pages/godmode/GodmodePageWrapper"
import endpoints from "endpoints"
import useSWR from "swr"
import { useHistory } from "react-router-dom";
import Button from "components/ui/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { routes } from "routes";
import React from "react";

interface IAmbassador {
    name: string;
    teacher_id: number;
    code: string;
    referral_count: number;
}

export const AmbassadorsIndex: React.FC = () => {
    const {data: ambassadors, isValidating } = useSWR<IAmbassador[]>(endpoints.godmode.ambassadors)
    const history = useHistory()

    return (
        <GodmodePageWrapper title={`Ambassadors (${ambassadors?.length})`} loading={isValidating}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Referral Count</TableCell>
                            <TableCell style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div>Link</div>
                                <Button
                                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                                    variant="contained"
                                    color="green"
                                    onClick={() => {
                                        history.push(routes.godmode.userLookup)
                                    }}
                                >
                                    Add New Ambassador
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ambassadors?.map(ambassador => (
                            <TableRow key={ambassador.code}>
                                <TableCell>{ambassador.name}</TableCell>
                                <TableCell>{ambassador.referral_count}</TableCell>
                                <TableCell>
                                    <Link target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_KODABLE_WEBSITE}/teachers?referral_code=${ambassador.code}`}>
                                        {`${process.env.REACT_APP_KODABLE_WEBSITE}/teachers?referral_code=${ambassador.code}`}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </GodmodePageWrapper>
    )
}