import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

//Routes
import SmeeborgVocabularyPage from './SmeeborgVocabularyPage';
import BugWorldVocabularyPage from './BugWorldVocabularyPage';
import AsteroidiaVocabularyPage from './AsteroidiaVocabularyPage';

export default function VocabularyIndexPage() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route component={SmeeborgVocabularyPage} path={`${path}/smeeborg`} />
      <Route component={AsteroidiaVocabularyPage} path={`${path}/asteroidia`} />
      <Route component={BugWorldVocabularyPage} path={`${path}/bugworld`} />
      <Redirect to={`${path}/smeeborg`} />
    </Switch>
  );
}
