import {
  Box,
  Checkbox,
  CircularProgress,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import useSWR from "swr";
import endpoints from "endpoints";
import {ITeacherData} from "types/ITeacherData";
import useSharedStyles from "components/useSharedStyles";
import React, {useLayoutEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faEdit, faLink, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import Button from "components/ui/buttons/Button";
import DeleteStudentsDialog from "../../components/dialogs/class/DeleteStudentsDialog";
import {IStudentRowData} from "./IStudentRowData";
import TransferStudentsDialog from "../../components/dialogs/class/TransferStudentsDialog";
import EditStudentDialog from "../../components/dialogs/class/EditStudentDialog";
import {getStudentPasswordIcon} from "./studentLoginIcons";
import {getMatDirFromSortDir} from "utils/sort";
import PageHeader from "components/ui/PageHeader";
import TextField from "components/ui/TextField";
import {ELeadState} from "types/IStudentSchoolProfile";
import useDialogState from "hooks/useDialogState";
import {useHistory} from "react-router-dom";
import {routes} from "routes";
import {StudentInstructionsDialog} from "../../components/dialogs/StudentInstructionsDialog";
import {IKlass} from "../../types/IKlass";
import Tooltip from "../../components/ui/Tooltip";
import {DeleteStudentsButton} from "./DeleteStudentsButton";
import {TransferStudentsButton} from "./TransferStudentsButton";
import {AddStudentsButton} from "./AddStudentsButton";
import InfoBox from "../../components/ui/InfoBox";
import CleverIcon from "../../components/cards/KlassCard/CleverIcon";
import useSubscription from "../../loaders/useSubscription";

interface StudentsTableProps {
  klassId?: number | undefined;
  embed?: boolean;
  showPasswordPictures?: boolean;
  onAddStudents: (targetClassId?: number) => any
}

const useViewStudentsListStyles = makeStyles(theme => ({
  checkboxRoot: {
    padding: '0 !important'
  }
}));

const StudentsTable: React.VFC<StudentsTableProps> = ({ klassId, onAddStudents, showPasswordPictures = false, embed = false }) => {
  const { data: teacherData, error, mutate } = useSWR<ITeacherData>(endpoints.teacherInit);
  const loading = !teacherData && !error;
  const history = useHistory();
  const sharedClasses = useSharedStyles();
  const moveStudentDialogState = useDialogState();
  const editStudentDialogState = useDialogState();
  const deleteStudentDialogState = useDialogState();
  const [oneStudentTarget, setOneStudentTarget] = useState<IStudentRowData>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const classes = useViewStudentsListStyles();
  const theme = useTheme();
  const [klass, setKlass] = useState<IKlass | undefined>()

  useLayoutEffect(() => {
    if (!teacherData) {
      return;
    }
    setKlass(teacherData.klasses.find((klass) => klass.id === klassId))
  }, [teacherData]);

  const [sort, setSort] = useState<{
    key: string,
    prop: (student: IStudentRowData) => string,
    dir: number
  }>(embed ? {
    key: 'name',
    prop: student => student.name,
    dir: -1
  } : {
    key: 'class',
    prop: student => student.klass?.klass_name || '',
    dir: -1
  });
  const [studentFilter, setStudentFilter] = useState('');

  const classFilteredStudents = useMemo(() => {
    const uniqueStudents = teacherData?.students
      .filter((student) => (!klassId || student.klasses.includes(klassId)))
      .reduce((acc, student) => {
        if (!acc.some(s => s.id === student.id)) {
          acc.push(student);
        }
        return acc;
      }, [] as typeof teacherData.students);

    return uniqueStudents?.map(student => ({
      ...student,
      klassNames: teacherData?.klasses
        .filter(({ id }) => student.klasses.includes(id))
        .map((klass) => klass.klass_name)
        .join(', '),
      teacher: teacherData?.teachers.find((teacher) => teacher.klasses.includes(Number(klassId)))
    }));
  }, [klassId, teacherData, studentFilter]);

  const sortedStudents = useMemo(() => {
    return classFilteredStudents?.slice()
      .sort((studentA, studentB) => (sort.prop(studentB).localeCompare(sort.prop(studentA))) * sort.dir)
      .filter(({ name }) => name.toLocaleLowerCase().includes(studentFilter))
  }, [
    classFilteredStudents,
    sort
  ]);

  const [selectedStudentIds, setSelectedStudentIds] = useState<{ [key: number]: boolean }>({});
  const selectedStudentProfilesArray = useMemo(() => {
    return classFilteredStudents?.filter(({ id }) => selectedStudentIds[id]) || [];
  }, [classFilteredStudents, selectedStudentIds]);
  const currentClass = useMemo(() => {
    return teacherData!.klasses.find(({ id }) => id === klassId)!;
  }, [teacherData, klassId]);
  const studentInstructionsDialogState = useDialogState();

  const handleSelectStudent = (student: IStudentRowData, selected: boolean) => {
    setSelectedStudentIds(ids => ({ ...ids, [student.id]: selected }))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading) {
    return <Box display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  return <Box className={sharedClasses.vspacing2}>
    {!embed && <PageHeader title="My Students" inAppBar></PageHeader>}
    <DeleteStudentsDialog
      open={deleteStudentDialogState.open}
      students={deleteStudentDialogState.open ? (oneStudentTarget ? [oneStudentTarget] : selectedStudentProfilesArray) : []}
      klassId={klassId}
      onClose={() => { deleteStudentDialogState.onClose(); setOneStudentTarget(undefined); }}
    />
    <TransferStudentsDialog
      open={moveStudentDialogState.open}
      students={moveStudentDialogState.open ? (oneStudentTarget ? [oneStudentTarget] : selectedStudentProfilesArray) : []}
      onClose={() => { moveStudentDialogState.onClose(); setOneStudentTarget(undefined); }}
      didMoveStudents={() => setSelectedStudentIds({})}
    />
    <EditStudentDialog
      open={editStudentDialogState.open}
      studentToEdit={oneStudentTarget || selectedStudentProfilesArray[0]}
      onClose={() => { editStudentDialogState.onClose(); setOneStudentTarget(undefined); }}
    />
    {currentClass && <StudentInstructionsDialog
      klass={currentClass}
      {...studentInstructionsDialogState}
    />}
    <Box display="flex" flexDirection="row" pb={1} className={sharedClasses.hspacing2}>
      {klass?.clever_id == null && <AddStudentsButton klass={klass} onAddStudents={onAddStudents} /> }
      {currentClass && <Button
        startIcon={<FontAwesomeIcon icon={faDownload} />}
        variant="contained"
        color="orange"
        onClick={studentInstructionsDialogState.handleOpen}
      >Student Instructions</Button>}
      <Button
        variant="contained"
        color="orange"
        onClick={() => {
          history.push(routes.parentConnect)
        }}
      >
        Invite Families
      </Button>
      {klass?.clever_id == null && <TransferStudentsButton dialogState={moveStudentDialogState} selectedStudents={selectedStudentProfilesArray} /> }
      {klass?.clever_id == null && <DeleteStudentsButton dialogState={deleteStudentDialogState} selectedStudents={selectedStudentProfilesArray} /> }
      {klass && klass?.clever_id !== null && <InfoBox>These students are managed through Clever.</InfoBox>}
    </Box>
    <Box display="flex" flexDirection="row" justifyContent="space-between" pb={1} className={sharedClasses.hspacing2}>
      <TextField
        autoFocus
        placeholder="Search Students"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faSearch} />
            </InputAdornment>
          ),
        }}
        value={studentFilter}
        onChange={e => setStudentFilter(e.target.value.toLocaleLowerCase())}
        style={{
          width: 400
        }}
      />
      <Box style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
        <Typography variant="subtitle1">Total Students: {classFilteredStudents?.length}</Typography>
      </Box>
    </Box>
    {sortedStudents && sortedStudents.length > 0 && <TableContainer {...{ component: Paper, variant: 'outlined' }}>
        <Table>
          <TableHead style={{ backgroundColor: theme.palette.divider }}>
            <TableRow>
              <TableCell style={{ background: 0 }}>
                <Checkbox
                  classes={{ root: classes.checkboxRoot }}
                  onChange={e => {
                    if (e.target.checked) {
                      setSelectedStudentIds(sortedStudents.reduce((prev, cur) => {
                        if (cur.clever_id === null) {
                          return { ...prev, [cur.id]: true };
                        }
                        return prev;
                      }, {}))
                    } else {
                      setSelectedStudentIds({});
                    }
                  }}
                  checked={
                    Object.keys(selectedStudentIds).filter(id =>
                      sortedStudents
                        .filter(student => student.clever_id === null)
                        .map(({ id }) => id)
                        .includes(parseInt(id)) &&
                      selectedStudentIds[parseInt(id)]
                    ).length === sortedStudents.filter(student => student.clever_id === null).length
                    && sortedStudents.filter(student => student.clever_id === null).length !== 0
                  }
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort?.key === 'name'}
                  direction={getMatDirFromSortDir(sort?.dir)}
                  onClick={() => setSort(sort => ({ key: 'name', prop: student => student.name, dir: sort?.key === 'name' ? sort.dir * -1 : 1 }))}
                >
                  <Typography variant="subtitle1">Name</Typography>
                </TableSortLabel>
              </TableCell>
              {showPasswordPictures && <TableCell>Picture Password</TableCell>}
              {embed && <TableCell>Parent Code</TableCell>}
              {!embed && <TableCell>
                <TableSortLabel
                  active={sort?.key === 'class'}
                  direction={getMatDirFromSortDir(sort?.dir)}
                  onClick={() => setSort(sort => ({
                    key: 'class',
                    prop: student => teacherData?.klasses.find((klass) => student.klasses.includes(klass.id))?.klass_name || '',
                    dir: sort?.key === 'class' ? sort.dir * -1 : 1
                  }))}
                >
                  <Typography variant="subtitle1">Class</Typography>
                </TableSortLabel>
              </TableCell>}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedStudents?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(student => <StudentsTableRow
              key={student.id}
              student={student}
              selected={selectedStudentIds[student.id]}
              showClass={!embed}
              showPasswordPictures={showPasswordPictures}
              onSelectStudent={handleSelectStudent}
              showParentCode={embed}
              onDeleteStudent={student => { setOneStudentTarget(student); deleteStudentDialogState.handleOpen(); }}
              onMoveStudent={student => { setOneStudentTarget(student); moveStudentDialogState.handleOpen(); }}
              onEditStudent={student => { setOneStudentTarget(student); editStudentDialogState.handleOpen(); }}
            />
            )}
          </TableBody>
        </Table>
      <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={sortedStudents?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>}

    {classFilteredStudents !== undefined && classFilteredStudents?.length > 0 && sortedStudents?.length === 0 &&
      <Alert
        severity="info"
      >We couldn't find any students with this filter.</Alert>
    }

    {teacherData?.students.length === 0 &&
      <Alert
        severity="info"
        action={<Button color="inherit" size="small" onClick={() => onAddStudents(klassId)}>Add students</Button>}
      >You don't have any students added yet.</Alert>
    }

    {error && <Alert severity="error"
      action={
        <Button
          onClick={() => mutate()}
          color="inherit"
          size="small"
        >Try again</Button>
      }
    >There was an error retrieving your students.</Alert>}
  </Box>
}

interface StudentsTableRowProps {
  student: IStudentRowData;
  selected?: boolean;
  showTeacher?: boolean;
  showClass?: boolean;
  showPasswordPictures?: boolean;
  showParentCode?: boolean;
  onSelectStudent?: (student: IStudentRowData, selected: boolean) => void;
  onDeleteStudent?: (student: IStudentRowData) => void;
  onMoveStudent?: (student: IStudentRowData) => void;
  onEditStudent?: (student: IStudentRowData) => void;
}

const StudentsTableRow: React.VFC<StudentsTableRowProps> = ({
  student,
  selected,
  showTeacher,
  showClass,
  showPasswordPictures,
  showParentCode,
  onSelectStudent = (...args: any[]) => { },
  onDeleteStudent = (...args: any[]) => { },
  onMoveStudent = (...args: any[]) => { },
  onEditStudent = (...args: any[]) => { }
}) => {
  const classes = useViewStudentsListStyles();
  const sharedClasses = useSharedStyles();
  const theme = useTheme();

  return (<>
    <TableRow
      key={student.id}
      selected={selected}
    >
      <TableCell style={{ width: 0 }}>
        {student.clever_id ? (
          <Tooltip title={'This student is managed through Clever'} arrow placement={'top'}>
            <span>
              <CleverIcon style={{opacity: 0.5}} />
            </span>
          </Tooltip>
        ) : (
          <Checkbox
            classes={{ root: classes.checkboxRoot }}
            onChange={() => onSelectStudent(student, !selected)}
            checked={!!selected}
          />
        )}
      </TableCell>

      <TableCell>
        {student.name}
        {student.is_teacher && <Box whiteSpace="nowrap" bgcolor={theme.palette.purple.main} ml={1} color="white" display="inline" borderRadius={16} py={0.5} px={1}>Teacher Profile</Box>}
        {student.lead?.state === ELeadState.linked && <>&nbsp;<Tooltip placement="top" title="This student has a linked home profile"><span><FontAwesomeIcon color={theme.palette.blue.main} icon={faLink} /></span></Tooltip></>}
      </TableCell>

      {showPasswordPictures && <TableCell>
        <img style={{ height: 32 }} src={`images/picture-passwords/${getStudentPasswordIcon(student.student_code)}.png`} alt={getStudentPasswordIcon(student.student_code)} />
      </TableCell>}

      {showParentCode && <TableCell>
        {student.student_code.toLocaleUpperCase()}
      </TableCell>}

      {showTeacher && <TableCell>
        {student.teacher?.name}
      </TableCell>}

      {showClass && <TableCell>
        {student.klassNames}
      </TableCell>}

      <TableCell style={{ width: '1%' }} onClick={e => e.stopPropagation()}>
        <Box display="flex" flexDirection="row" className={sharedClasses.hspacing2}>
          {student.clever_id == null && <Button
              variant="contained"
              color="orange"
              startIcon={<FontAwesomeIcon icon={faEdit} />}
              onClick={() => onEditStudent(student)}
              disabled={student.clever_id !== null}
            >
              Edit
            </Button>
          }
          {student.clever_id == null && <Button
              variant="contained"
              color="red"
              startIcon={<FontAwesomeIcon icon={faTrash} />}
              onClick={() => onDeleteStudent(student)}
              disabled={student.clever_id !== null}
            >
              Delete
            </Button>
          }
        </Box>
      </TableCell>
    </TableRow>
  </>)
}

export default StudentsTable;
