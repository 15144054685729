import React, { useContext, useEffect, useMemo, useState } from "react";

const makeApiMethodOnLoadHook = <C extends React.Context<{ didLoad: boolean }>, A>(context: C, apiProvider: () => A) =>
  <P extends any[], R extends any>(action: (api: A, ...args: P) => R) => {
    const { didLoad } = useContext(context);

    const [head, resolve] = useMemo(() => {
      let resolve: (...args: any) => any;
      const head = new Promise(_resolve => {
        resolve = _resolve;
      });

      return [head, resolve!];
    }, []);
    const [tail, setTail] = useState(head);

    useEffect(() => {
      if (didLoad) {
        resolve();
      }
    }, [didLoad]);

    return (...args: P) => {
      if (didLoad) {
        return new Promise(resolve => resolve(action(apiProvider(), ...args)));
      }

      const newTail = tail.then(() => {
        return action(apiProvider(), ...args);
      });

      setTail(newTail);

      return newTail;
    }
  }

export default makeApiMethodOnLoadHook;