import React, { useState } from 'react';
import { useAlertDispatch } from 'context/AlertProvider';
import useStudent from 'loaders/useStudent';
import useUpdateStudent from 'mutations/useUpdateStudent';
import useDeleteStudent from 'mutations/useDeleteStudent';
import DeleteStudentModal from 'components/modal/DeleteStudentModal';

const StudentManagePage: React.FC = () => {
  const student = useStudent();
  const updateStudent = useUpdateStudent(student.id);
  const deleteStudent = useDeleteStudent(student.id);
  const dispatch = useAlertDispatch();

  const [name, setName] = useState(student.name);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <div className="max-w-screen-lg my-8 mx-8">
      <div className="flex flex-col mb-12 border shadow rounded pb-4 pt-2 px-4 text-gray-800 bg-white">
        <h1 className="text-3xl">Login Instructions</h1>
        <ol className="p-2 text-sm lg:text-base">
          <li>
            1. Go to Kodable on your iOS device or at{' '}
            <a className="text-kodable-blue" href="https://game.kodable.com">
              kodable.com
            </a>
          </li>
          <li>
            2. Login with the email and password associated with this account.
          </li>
          <li>3. Select {student.name} from the player screen.</li>
        </ol>
        <div className="mt-4">
          <a
            href="http://support.kodable.com/en/articles/1389673-how-do-i-start-playing-ipad"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-kodable-blue hover:bg-kodable-blue-dark active:bg-kodable-blue-darker px-4 py-2 mb-2 ml-2 rounded text-white">
            More Login Help
          </a>
        </div>
      </div>
      <div className="flex flex-col mb-12 border shadow rounded pb-4 pt-2 px-4 text-gray-800 bg-white">
        <h1 className="text-3xl">School Details</h1>
        {student.link ? (
          <div className="p-2">
            <p className="text-kodable-green flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="w-6 h-6"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              Connected
            </p>
            <p className="text-gray-600">
              <span className="font-bold">Teacher: </span>
              {student.school_teacher}
            </p>
            <p className="text-gray-600">
              <span className="font-bold">Grade: </span>Grade {student.grade}
            </p>
          </div>
        ) : (
          <div className="p-2">
            <p className="text-kodable-red">Not Connected</p>
          </div>
        )}
      </div>
      <div className="flex flex-col mb-12 border shadow rounded pb-4 pt-2 px-4 text-gray-800 bg-white">
        <h1 className="text-3xl">Player Information</h1>
        <form
          className="p-2 flex flex-col"
          onSubmit={(e) => {
            e.preventDefault();
            if (name === student.name) {
              return;
            }
            updateStudent({ id: student.id, name })
              .then(() => {
                student.mutate().then(() => {
                  dispatch({
                    id: `updatedStudent-${name}`,
                    type: 'success',
                    title: 'Student Updated!',
                    message: 'Student was successfully updated.',
                  });

                  setTimeout(() => {
                    dispatch({
                      type: 'removeAlert',
                      id: `updatedStudent-${name}`,
                    });
                  }, 3000);
                });
              })
              .catch(() => {
                dispatch({
                  id: 'student_not_updated_error',
                  type: 'error',
                  title: 'Student Not Updated!',
                  message: 'There was an error updating the student.',
                });
              });
          }}>
          <label htmlFor="name" className="font-bold mb-1">
            Name {student.link && <span className="font-normal text-xs">*Student linked to a school account can't be changed.</span>}
          </label>
          <input
            autoComplete="false"
            readOnly={!!student.link}
            name="name"
            id="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            type="text"
            className="border rounded p-2"
          />
          <div className="mt-6 self-end">
            <button
              disabled={name === student.name || !!student.link}
              type="submit"
              className="bg-kodable-green hover:bg-kodable-green-dark active:bg-kodable-green-darker px-4 py-2 rounded text-white mr-6 disabled:opacity-50 disabled:cursor-not-allowed">
              Update
            </button>
            {openDeleteModal && (
              <DeleteStudentModal
                closeModal={() => setOpenDeleteModal(false)}
                student={student}
                deleteStudent={deleteStudent}
              />
            )}
            <button
              type="button"
              onClick={() => setOpenDeleteModal(true)}
              className="bg-kodable-red hover:bg-kodable-red-dark active:bg-kodable-red-darker px-4 py-2 rounded text-white">
              Delete
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StudentManagePage;
