import React, {useMemo, useState} from "react";
import {ICourseData, IGalaxyCourse} from "../ICourseData";
import {Box, Paper, Typography} from "@material-ui/core";
import Button from "../../../components/ui/buttons/Button";
import {useCourseCardStyles} from "./CourseCard";
import {routes} from "../../../routes";
import {useHistory} from "react-router-dom";
import useSWR from "swr";
import endpoints from "../../../endpoints";
import useSharedStyles from "../../../components/useSharedStyles";
import {GameTitleChip} from "./GameTitleChip";
import {GameTitle} from "../../../enums/GameTitle";
import EditCourseDialog from "../../../components/dialogs/courses/EditCourseDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEmpire} from "@fortawesome/free-brands-svg-icons";
import useCurrentUser from "../../../loaders/useCurrentUser";

export const CourseCardSelector: React.VFC<{ course: ICourseData }> = ({ course }) => {
    const history = useHistory();
    const { currentUser } = useCurrentUser();
    const courseCardClasses = useCourseCardStyles();
    const { data: courseData } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
    const sharedClasses = useSharedStyles();
    const [showEditCourseDialog, setShowEditCourseDialog] = useState(false);

    const onClickCard = () => {
        if (course.id >= 1000) return
        history.push(routes.courses.viewCourse.materials(course.id), { lastLocation: history.location.pathname })
    }
    const icon = useMemo(() => {
        const galaxyImage = (courseData?.find(({ id }) => id === 1) as IGalaxyCourse)?.configuration.planetNodes.find(planetNode => planetNode.courseID === course.id)?.image;
        if (galaxyImage) return `images/courses/planets/${galaxyImage}.png`;
        return course.meta.iconUrl;
    }, [courseData]);
    
    return <>

        <EditCourseDialog open={showEditCourseDialog} onClose={() => setShowEditCourseDialog(false)} course={course} />
        <Box
        component={Paper}
        {...{
            variant: 'outlined',
        }}
        style={{
            borderWidth:  1,
            transition: 'transform 0.5s, border-color 0.5s',
            borderRadius: '25px',
        }}
        p={2}
        width="600px"
        height="400px"
        flexBasis="max(40%, 600px)"
        display="flex" flexDirection="column" alignItems="flex-start"
        className={courseCardClasses.floaty}
        onClick={onClickCard}
        position="relative"
    >

        <Box alignSelf="flex-end">
            <GameTitleChip  game={course.id > 1000 ? GameTitle.CREATOR : GameTitle.BASICS} />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1} style={{ transition: 'opacity 0.5s', opacity: 1 }}>
            {icon && <Box id="floaty-planet" mr={2} position="relative">
                <img
                    alt={course.meta.dashboardTitle}
                    src={icon}
                    style={{
                        maxHeight: 150,
                        maxWidth: 150,
                    }}
                />
            </Box>}
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography variant="h1" style={{ fontWeight: 600 }}>{course.meta.dashboardTitle}</Typography>
                <Typography style={{ opacity: 0.65 }} paragraph>{course.meta.dashboardSubtitle}</Typography>
            </Box>
        </Box>
        
        <Typography>{course.meta.dashboardDescription ? (course.meta.dashboardDescription) : (<>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Suspendisse malesuada libero diam, id euismod odio suscipit sit amet. 
            Curabitur pretium purus nec nibh ultricies, sed pharetra nisi hendrerit.
        </>)}</Typography>
        <Box flexGrow={1} />
        <Box display="flex" alignSelf="flex-end" flexShrink={0} className={sharedClasses.vspacing2} flexDirection="column" ml={2} minWidth={200}>
            {course.id < 1000 && <Button
                style={{height:"50px"}}
                color={'primary'}
                variant={'contained'}
                onClick={onClickCard}
            >View Course</Button>}
            {(currentUser.is_god && Number(course.id) < 800) && <Button
                variant="outlined"
                onClick={e => { e.stopPropagation(); setShowEditCourseDialog(true); }}
                startIcon={<FontAwesomeIcon icon={faEmpire} />}
            >
                Edit course
            </Button>}
        </Box>
        </Box>
    </>
}
 
export default CourseCardSelector