import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useAlert } from "context/AlertProvider";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { Form, FormikProvider, useFormik } from "formik";
import { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import React, { useState } from "react";
import { IEvent } from "types/IEvent";
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as Yup from 'yup';
import UploadButton from "components/form/UploadButton";

function validateSlug(value: string | null | undefined): boolean {
  const regexExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g;
  return regexExp.test(value || '');
}

const validationSchema = Yup.object().shape({
  event_name: Yup.string().required('Required field'),
  event_config: Yup.object().shape({
    sidebar_banner_img: Yup.string().url('Must be a valid URL').required('Required field'),
    sidebar_banner_link: Yup.string().url('Must be a valid URL').required('Required field'),
    event_slug: Yup.string().test('slug-validator', 'Must be a valid Slug', validateSlug).required('Required field'),
  }),
  start_datetime: Yup.date()
    // eslint-disable-next-line no-template-curly-in-string
                    .typeError('Expected a value of type ${type} but got: ${value}')
                    .required('Required field'),
  end_datetime: Yup.date()
    // eslint-disable-next-line no-template-curly-in-string
                  .typeError('Expected a value of type ${type} but got: ${value}')
                  .required('Required field'),
});


export const EditTeacherDialog: React.FC<DialogStateProps & { event: IEvent, fireUpdate: Function, operation: string }> = ({ open, onClose, event, fireUpdate, operation }) => {
  const sharedClasses = useSharedStyles();
  const [error, setError] = useState<string>();
  const alert = useAlert();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: event,
    validationSchema,
    onSubmit: async (values, actions) => {
      setError(undefined);
      let reqData: {}
      if (operation === 'update') {
        reqData = {
          id: event.id,
          event_name: values.event_name,
          event_config: JSON.stringify(values.event_config),
          start_datetime: values.start_datetime,
          end_datetime: values.end_datetime
        };
      } else {
        reqData = {
          event_name: values.event_name,
          event_config: JSON.stringify(values.event_config),
          start_datetime: values.start_datetime,
          end_datetime: values.end_datetime
        };
      }

      const res = await justFetch(endpoints.godmode.events, 'POST', reqData);

      if (res.ok) {
        onClose();
        fireUpdate(reqData);
        actions.resetForm();
        alert.success('Event successfully created!');
      } else {
        const parsed = await res.json();
        setError(parsed.message || parsed.error || 'An error occurred.');
      }
    }
  });

  const setBannerImageURL = (url: string) => {
    form.setFieldValue('event_config.sidebar_banner_img', url);
  }

  return <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Dialog open={open}>
      <DialogTitle>{(operation === 'create') ? 'Create': 'Edit'} Event</DialogTitle>
      <DialogContent>
        <FormikProvider value={form}>
          <Form onSubmit={form.handleSubmit}>
            <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2}>
              <TextField
                error={Boolean(form.touched.event_name && form.errors.event_name)}
                value={form.values.event_name}
                helperText={form.errors.event_name}
                label="Name"
                name="event_name"
                id="event_name"
                onChange={form.handleChange}
              />
              <TextField
                error={Boolean(form.touched.event_config?.sidebar_banner_img && form.errors.event_config?.sidebar_banner_img)}
                value={form.values.event_config.sidebar_banner_img}
                helperText={form.errors.event_config?.sidebar_banner_img}
                label="Sidebar Banner Image"
                name="event_config.sidebar_banner_img"
                id="event_config.sidebar_banner_img"
                onChange={form.handleChange}
              />
              <UploadButton
                maxFileSize={1000000}
                allowedFileTypes={['image/png', 'image/jpeg']}
                buttonText="Upload Banner Image"
                setImageURL={setBannerImageURL}
                uploadPath="banners"
              />
              <TextField
                error={Boolean(form.touched.event_config?.sidebar_banner_link && form.errors.event_config?.sidebar_banner_link)}
                value={form.values.event_config.sidebar_banner_link}
                helperText={form.errors.event_config?.sidebar_banner_link}
                label="Sidebar Banner Link"
                name="event_config.sidebar_banner_link"
                id="event_config.sidebar_banner_link"
                onChange={form.handleChange}
              />
              <TextField
                error={Boolean(form.touched.event_config?.event_slug && form.errors.event_config?.event_slug)}
                value={form.values.event_config.event_slug}
                helperText={form.errors.event_config?.event_slug}
                label="Event Slug"
                name="event_config.event_slug"
                id="event_config.event_slug"
                onChange={form.handleChange}
              />
              <Typography variant="subtitle1">Start Date</Typography>
              <DateTimePicker
                onChange={(date) => form.setFieldValue('start_datetime', date)}
                value={form.values.start_datetime || null}
                format="MM/dd/yyyy, HH:mm "
                inputVariant="outlined"
                InputProps={{
                  margin: 'dense'
                }}
                placeholder="Click to select a date"
              />
              <Typography variant="subtitle1">End Date</Typography>
              <DateTimePicker
                onChange={(date) => form.setFieldValue('end_datetime', date)}
                value={form.values.end_datetime || null}
                format="MM/dd/yyyy, HH:mm "
                inputVariant="outlined"
                InputProps={{
                  margin: 'dense'
                }}
                placeholder="Click to select a date"
              />
              {!!error && <Alert severity="error">{error}</Alert>}
            </Box>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            form.resetForm();
            onClose();
          }}
          disabled={form.isSubmitting}
        >Cancel</Button>
        <Button
          variant="contained"
          color="green"
          onClick={() => form.handleSubmit()}
          disabled={form.isSubmitting}
        >Save</Button>
      </DialogActions>
    </Dialog>
  </MuiPickersUtilsProvider>;
}