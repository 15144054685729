import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "./Button";
import {faTwitter} from "@fortawesome/free-brands-svg-icons/faTwitter";
import {Link} from "@material-ui/core";
import React from "react";

const TwitterShareButton: React.VFC<{shareUrl: string}> = ({shareUrl}) => {
  const tweetTitle = "Check out this lesson from Kodable!"
  const twitterShareUrl = `https://twitter.com/intent/tweet?hashtags=kidscancode%2Ccs4all&amp;text=${tweetTitle}&amp;tw_p=tweetbutton&amp;url=${shareUrl}`;

  return (
    <Link component={Button}
          href={twitterShareUrl}
          underline="none"
          style={{backgroundColor: '#26c4f1', color: "white", fontSize: 12, paddingLeft: 15, paddingRight: 15, width: 125}}
          startIcon={<FontAwesomeIcon icon={faTwitter} color={'white'} />}
    >
      TWITTER
    </Link>
  )
}

export default TwitterShareButton