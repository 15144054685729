import {ISubscriptionTerm} from "../types/ISubscription";

export function getPlanName(term: ISubscriptionTerm) {
  switch (term) {
    case ISubscriptionTerm.Lifetime:
      return 'Lifetime';
    case ISubscriptionTerm.Yearly:
      return 'Yearly';
    case ISubscriptionTerm.Monthly:
      return 'Monthly';
    case ISubscriptionTerm.Gift6:
      return '6 Month Gift';
    case ISubscriptionTerm.Gift12:
      return '12 Month Gift';
    case ISubscriptionTerm.GiftLifetime:
      return 'Lifetime Gift';
    default:
      return 'Free';
  }
}