import React, {useState} from "react";
import useSharedStyles from "components/useSharedStyles";
import {useOrganizationProfile} from "context/OrganizationProfileProvider";
import {Box, Button, Link, Typography} from "@material-ui/core";
import {SubscriptionStateCardsListing} from "pages/godmode/Subscriptions/SubscriptionStateCardsListing";
import OrganizationProfileBillingDetailsModal from "./OrganizationProfileBillingDetailsModal";
import {useAlert} from "../../../../../context/AlertProvider";
import {justFetch} from "../../../../../mutations/mutate";
import endpoints from "../../../../../endpoints";
import {GodmodeConfirmDialog} from "../../../shared/GodmodeConfirmDialog";

export const OrganizationProfileDetailCleverTab: React.FC = () => {
  const sharedClasses = useSharedStyles()
  const {organization_profile} = useOrganizationProfile()
  const [error, setError] = useState<string>('');
  const [confirmationState, setConfirmationState] = useState<boolean>(false);
  const alert = useAlert();

  const cleverUnlink = async () => {
    if (!organization_profile) return;
    const res = await justFetch(endpoints.godmode.cleverUnlink.organization(organization_profile.id), 'POST')
    setConfirmationState(false);
    if (!res.ok) {
      setError(res.statusText);
      alert.error(`${res.status}: ${res.statusText}`)
    }else {
      alert.success(`Organization has been unlinked from clever`);
    }
  }
  
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >      
      <GodmodeConfirmDialog
      dialogState={{
        open: confirmationState,
        onClose: () => {
          setConfirmationState(false);
        }
      }}
      variant="danger"
      dialogTitle={`Are you sure you want to unlink this organization from Clever?`}
      dialogContent={`All associated teachers, classes and students will be unlinked as well. This action cannot be undone.`}
      onConfirm={cleverUnlink}
    />
      <Box className={sharedClasses?.vspacing4} width="48%">
        <Typography variant="h1">Clever</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setConfirmationState(true);
            setError('');
          }}
        >Unlink From Clever</Button>
        {error && <Typography color="error">An error occurred. Talk to a web dev. ({error})</Typography>}
      </Box>
      
    </Box>
  )
}