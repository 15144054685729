import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from "./Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";

const CopyLinkButton: React.VFC<{shareUrl: string}> = ({shareUrl}) => {
  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard text={shareUrl} onCopy={() => setCopied(true)}>
      <Button style={{backgroundColor: '#df1c31', color: "white", fontSize: 12, paddingLeft: 15, paddingRight: 15, width: 125}}
              startIcon={<FontAwesomeIcon icon={faCopy} color={'white'} />}
      >
        {copied ? 'LINK COPIED' : 'COPY LINK'}
      </Button>
    </CopyToClipboard>
  )
}

export default CopyLinkButton