import {Box, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Button from "components/ui/buttons/Button";
import {justFetch} from "mutations/mutate";
import endpoints from "endpoints";
import {formatDateToUSLocale} from "pages/godmode/utils/time";
import React, {useState} from "react";
import {useAlert} from "context/AlertProvider";

type CreateTrialDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  teacherId: number
}

const CreateTrialDialog: React.VFC<CreateTrialDialogProps> = ({open, onClose, onSuccess, teacherId}) => {
  const nextWeek = new Date();
  nextWeek.setDate(new Date().getDate() + 7);

  const [selectedTrialEndDate, setTrialEndDate] = useState(nextWeek);
  const [submitting, setSubmitting] = useState(false);

  const alert = useAlert();

  const handleTrialEndDateChange = (value: any) => {
    setTrialEndDate(value);
  }

  const createTrialSubscription = async () => {
    setSubmitting(true);
    const res = await justFetch(endpoints.godmode.createTrial(teacherId), 'POST', {
      end_date: formatDateToUSLocale(selectedTrialEndDate)
    });
    setSubmitting(false);

    if (res.ok) {
      alert.success('Trial successfully created!');
      onSuccess();
    } else {
      alert.error('An error occurred while creating a trial!');
    }
    onClose();
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Create Free Trial</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box display="flex" flexDirection="column">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              id="trial-end-date"
              label="Select End Date"
              value={selectedTrialEndDate}
              onChange={handleTrialEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Box>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >Close</Button>
        <Button
          variant="outlined"
          disabled={submitting}
          onClick={createTrialSubscription}
        >Start Trial</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateTrialDialog