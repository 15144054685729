import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import useSharedStyles from "../../../components/useSharedStyles";
import Button from "../../../components/ui/buttons/Button";
import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

const ExistingQuoteDialog: React.VFC<{open: boolean, onClose: () => void, name: string, email: string, quoteSecretCode: string}> = ({open, onClose, quoteSecretCode, name, email}) => {
  const sharedClasses = useSharedStyles()
  const history = useHistory();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>A quote for your organization already exists!</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
          <Typography variant="body1">It was created by:</Typography>
        <Box marginLeft={3}>
          <Typography variant="body1" style={{fontWeight: 700}}>Name: <span style={{fontWeight: 400}}>{name}</span></Typography>
          <Typography variant="body1" style={{fontWeight: 700}}>Email: <span style={{fontWeight: 400}}>{email}</span></Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={onClose}>Close</Button>
        <Button variant={'contained'} color={'primary'} onClick={()=>{
            history.push(routes.sales.quotes.view(quoteSecretCode));
          }}>View Quote</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExistingQuoteDialog