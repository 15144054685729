import {Box, Typography, useTheme} from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import React, {useContext, useEffect, useState} from "react";
import {teacherOnboardingContext} from "./TeacherOnboardingIndex";
import Button from "components/ui/buttons/Button";
import {useAuth} from "context/AuthProvider";
import {GameTitle} from "../../enums/GameTitle";
import GameCheckboxHorizontalCard from "../../components/ui/cards/GameCheckboxHorizontalCard";


export const TeacherOnboardingSelectGameAssignments: React.FC = () => {
    const sharedClasses = useSharedStyles()
    const [basicsChecked, setBasicsChecked] = useState(true)
    const [creatorChecked, setCreatorChecked] = useState(false)
    const { addNewClassForm } = useContext(teacherOnboardingContext);
    const { currentUser } = useAuth()
    const recommendedBasicsGrades = ['all', 'k', '1']
    const recommendedCreatorGrades = ['all', '2', '3', '4', '5']
    const [handledRecommendations, setHandledRecommendations] = useState(false)
    const theme = useTheme();

    // TODO: Update this hack and the one in TeacherOnboardingSelectGameRow to use a single state object.. This was a
    // quick fix to get the checkboxes to work
    useEffect(() => {
        let newValue = []
        if (basicsChecked) {
            newValue.push(GameTitle.BASICS)
        }
        if (creatorChecked) {
            newValue.push(GameTitle.CREATOR)
        }
        addNewClassForm.setFieldValue('assigned_games', newValue)
    }, [basicsChecked, creatorChecked])

    useEffect(() => {
        if (handledRecommendations) {
            return
        }
        if (!currentUser) {
            return
        }
        if (recommendedCreatorGrades.includes(addNewClassForm?.values?.grade)) {
            setCreatorChecked(true)
        }
        if (recommendedBasicsGrades.includes(addNewClassForm?.values?.grade)) {
            setBasicsChecked(true)
        }
        setHandledRecommendations(true)
    }, [recommendedCreatorGrades, currentUser])
    
    return (
        <Box
            className={sharedClasses?.vspacing4}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
        >
            <Box
                className={sharedClasses?.vspacing2}
                display="flex"
                flexDirection="column"
            >
                <GameCheckboxHorizontalCard
                  color={theme.palette.newOrange.main}
                  checked={basicsChecked}
                  setChecked={setBasicsChecked}
                  game={GameTitle.BASICS}
                />
                <GameCheckboxHorizontalCard
                  color={theme.palette.newPink.main}
                  game={GameTitle.CREATOR}
                  checked={creatorChecked}
                  setChecked={setCreatorChecked}
                />
                {(!basicsChecked && !creatorChecked) &&
                    <Box
                        p={2}
                        border={'1px solid #e84461'}
                        color={'red'}
                        style={{
                            backgroundColor: '#fff5f5',
                            borderRadius: 4
                        }}
                    >
                        <Typography variant="body1" color="error">You must select at least one app to continue</Typography>
                    </Box>
                }
            </Box>
            <Button
                color="blue"
                variant="contained"
                disabled={(!basicsChecked && !creatorChecked) || addNewClassForm.isSubmitting}
                onClick={() => {
                    addNewClassForm.submitForm();
                }}
            >
                Continue
            </Button>
        </Box>
    )
}