import { Paper, Box, Typography, Dialog, LinearProgress, DialogTitle, DialogContent, DialogActions, Link, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { FormikProvider, useFormik } from "formik";
import useDialogState from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import { BillingForm } from "pages/sales/orders/submit-order/AddBillingContact";
import { ISalesProfile } from "pages/settings/teacher/ISalesProfile";
import React, { useEffect, useMemo, useState } from "react";
import useSWR, { mutate } from "swr";
import * as Yup from 'yup';
import { IGodmodeUserProfile } from "../IGodmodeUserProfile";

export const billingFromSalesProfile = (salesProfile? : ISalesProfile) =>
{
  const billingKeys = [
    'billing_contact_address_street',
    'billing_contact_address_street2',
    'billing_contact_address_city',
    'billing_contact_address_province',
    'billing_contact_address_zip',
    'billing_contact_address_country'
  ] as const;

  let billing: Partial<Record<typeof billingKeys[number], string>> = {};

  for (let key of billingKeys) {
    billing[key] = (salesProfile && salesProfile[key])? salesProfile[key]: '';
  }
  return billing;
}

export const BillingContactBlock: React.FC<{ teacher: IGodmodeUserProfile }> = ({ teacher }) => {
  const editBillingContactDialogState = useDialogState();
  const { data: salesProfile, error } = useSWR<ISalesProfile>(endpoints.salesProfile(teacher.id));
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const alert = useAlert();
  const sharedClasses = useSharedStyles();

  const initialValues = useMemo(() => {

    let billing = billingFromSalesProfile(salesProfile);

    return {
      ...billing,
      billing_contact_first_name: salesProfile?.billing_contact_name?.split(' ')[0] || '',
      billing_contact_last_name: salesProfile?.billing_contact_name?.split(' ').slice(1).join('') || '',
      billing_contact_email: salesProfile?.billing_contact_email || '',
      billing_contact_phone: salesProfile?.billing_contact_phone || ''
    }
  }, [salesProfile]);

  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      billing_contact_first_name: Yup.string().required('Please enter the name of your billing contact'),
      billing_contact_last_name: Yup.string().required('Please enter the name of your billing contact'),

      billing_contact_address_street: Yup.string().required('Please enter a billing address'),
      billing_contact_address_street2: Yup.string(),
      billing_contact_address_city: Yup.string().required('Please enter a city'),
      billing_contact_address_province: Yup.string(),
      billing_contact_address_zip: Yup.string().required('Please enter a zip code'),
      billing_contact_address_country: Yup.string().required('Please enter a country'),

      billing_contact_email: Yup.string().email().required('Please enter an email for your billing contact'),
      billing_contact_phone: Yup.string(),
    }),
    onSubmit: values => {
      return justFetch(`/v2/sales/profile`, 'POST', values)
        .then(async res => {
          const body = await res.json();
          if (!res.ok) {
            if (res.status === 422) {
              form.setFieldError('username', body.message);
              setSubmitError(false);
            } else {
              form.setFieldError('username', undefined);
              setSubmitError(true);
            }
          } else {
            mutate(endpoints.salesProfile(teacher.id));
            editBillingContactDialogState.onClose();
            alert.success('Billing Information Saved');
          }
        })
        .catch(() => setSubmitError(true))
    }
  })

  useEffect(() => {
    if (editBillingContactDialogState.open) {
      form.handleReset(null);
      setShowValidationMessage(false);
      setSubmitError(false);
    }
  }, [editBillingContactDialogState.open]);

  if (!salesProfile) {
    return <Paper variant="outlined">
      <Box p={2} display="flex" flexDirection="column" alignItems="center">
        <CircularProgress />
      </Box>
    </Paper>;
  }

  return <FormikProvider value={form}>
    <form onSubmit={form.handleSubmit} style={{ height: '100%' }}>
      <Dialog open={editBillingContactDialogState.open}>
        <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
        <DialogTitle>Edit Billing Details</DialogTitle>
        <DialogContent className={sharedClasses.vspacing2}>
          <BillingForm />
          {!form.isValid && showValidationMessage && <Alert severity="error">Please correct the errors above to continue.</Alert>}
          {submitError && <Alert severity="error" action={
            <Button
              color="inherit"
              size="small"
              onClick={() => form.submitForm()}
            >Try again</Button>
          } >There was an error trying to submit this form.</Alert>}
        </DialogContent>
        <DialogActions>
          <Box>
            <Button
              onClick={editBillingContactDialogState.onClose}
              type="reset"
              disabled={form.isSubmitting}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              onClick={form.submitForm}
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              disabled={form.isSubmitting}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Paper variant="outlined" style={{ height: '100%' }}>
        <Box p={2} display="flex" flexDirection="column" height="100%">
          <Typography variant="h5">Billing Contact</Typography>
          <Box flexGrow={1}>
            <Typography variant="h6" paragraph>{salesProfile.billing_contact_name}</Typography>
            <Typography variant="body1" paragraph>{salesProfile.billing_contact_email}</Typography>
            {initialValues.billing_contact_address_street ?
              <>
                <Typography variant="body1">{initialValues.billing_contact_address_street}</Typography>
                {initialValues.billing_contact_address_street2 && <Typography variant="body2">{initialValues.billing_contact_address_street2}</Typography>}
                <Typography variant="body1">{initialValues.billing_contact_address_city}, {initialValues.billing_contact_address_province ? `${initialValues.billing_contact_address_province},` : ''} {initialValues.billing_contact_address_zip}, {initialValues.billing_contact_address_country}</Typography>
              </>
            : <Typography variant="body1">No billing address on file</Typography>}
          </Box>
          {!!teacher.subscription && <Box borderTop="1px solid rgba(0, 0, 0, 0.12)" mt={2} pt={2} display="flex" justifyContent="space-around" className={sharedClasses.hspacing2}>
            <Link onClick={editBillingContactDialogState.handleOpen} className={sharedClasses.hoverCursorPointer}>edit</Link>
          </Box>}
        </Box>
      </Paper>
    </form>
  </FormikProvider>;
}