import React from "react"
import { GodmodePageWrapper } from "pages/godmode/GodmodePageWrapper"
import { Box, makeStyles } from "@material-ui/core"
import { SalesOrderApprovalQueue } from "./Orders/SalesOrderApprovalQueue";
import SalesCleverActionsQueue from "./SalesCleverActionsQueue";
import { CleverDistrictRenewedNeedsDisconnectProvider } from "context/CleverDistrictNeedsDisconnectRenewedProvider";

interface SalesDashboardBoxProps {
    title: string;
    children: React.ReactNode;
}

const useSalesDashboardStyles = makeStyles((theme) => ({
    salesDashboardBox: {
        width: '48.5%',
        border: '1px solid #eee',
        borderRadius: '3px',
        overflow: 'hidden',
        boxShadow: '0px 0px 1px #eee'
    },
    salesDashboardBoxTitle: {
        background: '#fff',
        padding: "0.5rem 1rem",
        fontWeight: "bold",
        fontSize: "1rem"
    },
    salesDashboardBoxContent: {
        padding: "1rem",
        background: "#f5f5f5"
    }
}))

const SalesDashboard: React.FC = () => {
    return (
        <GodmodePageWrapper title="Sales Dashboard" loading={false}>
            <CleverDistrictRenewedNeedsDisconnectProvider>
                <Box display="flex" alignItems="flex-start" justifyContent="space-evenly">
                    <SalesDashboardBox title="Pending Orders">
                        <SalesOrderApprovalQueue />
                    </SalesDashboardBox>
                    <SalesDashboardBox title="Clever Licensing">
                        <SalesCleverActionsQueue />
                    </SalesDashboardBox>
                </Box>
            </CleverDistrictRenewedNeedsDisconnectProvider>
        </GodmodePageWrapper>
    )
}

const SalesDashboardBox: React.FC<SalesDashboardBoxProps> = ({title, children}) => {
    const classes = useSalesDashboardStyles();
    return (
        <Box className={classes?.salesDashboardBox}>
            <Box className={classes?.salesDashboardBoxTitle}>{title}</Box>
            <Box className={classes?.salesDashboardBoxContent}>{children}</Box>
        </Box>
    )
}

export default SalesDashboard