import { useState } from "react"

export const useLocalStorage = (key: string, initialValue: string | number | boolean) => {
    const [internalValue, setInternalValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);

            if (item) {
                return JSON.parse(item);
            }

            window.localStorage.setItem(key, JSON.stringify(initialValue));

            return initialValue;
        } catch (e) {
            return initialValue;
        }
    });

    const setValue = (newValue: string | number | boolean) => {
        setInternalValue(newValue);
        window.localStorage.setItem(key, JSON.stringify(newValue));
    }

    return [internalValue, setValue];
}