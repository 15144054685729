import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { Form, FormikProvider, useFormik } from "formik";
import { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import React, { useState } from "react";
import { mutate } from "swr";
import { IGodmodeUserProfile } from "pages/godmode/Users/Teachers/ViewUser/IGodmodeUserProfile";

export const EditTeacherDialog: React.FC<DialogStateProps & { teacher: IGodmodeUserProfile }> = ({ open, onClose, teacher }) => {
  const sharedClasses = useSharedStyles();
  const [error, setError] = useState<string>();
  const form = useFormik({
    initialValues: teacher,
    onSubmit: async values => {
      setError(undefined);
      const res = await justFetch(endpoints.godmode.teacher(teacher.id), 'PUT', values);

      if (res.ok) {
        await mutate(endpoints.godmode.teacher(teacher.id), await res.json());
        onClose();
      } else {
        const parsed = await res.json();
        setError(parsed.message || parsed.error || 'An error occurred.');
      }
    }
  });

  return <Dialog open={open}>
    <DialogTitle>Edit Teacher</DialogTitle>
    <DialogContent>
      <FormikProvider value={form}>
        <Form onSubmit={form.handleSubmit}>
          <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2}>
            <TextField
              label="Name"
              name="name"
              id="name"
              value={form.values.name}
              onChange={form.handleChange}
            />
            <TextField
              label="Occupation"
              name="occupation"
              id="occupation"
              value={form.values.occupation}
              onChange={form.handleChange}
            />
            <TextField
              label="Email"
              name="username"
              id="username"
              value={form.values.username}
              onChange={form.handleChange}
            />
            <TextField
              label="School ID"
              name="school_id"
              id="school_id"
              value={form.values.school_id}
              onChange={form.handleChange}
            />
            <FormControlLabel
              control={<Checkbox id="is_non_usa" name="is_non_usa" checked={form.values.is_non_usa} onChange={form.handleChange} />}
              label="Is non-USA?"
            />
            {!!error && <Alert severity="error">{error}</Alert>}
          </Box>
        </Form>
      </FormikProvider>
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        onClick={() => {
          form.resetForm();
          onClose();
        }}
        disabled={form.isSubmitting}
      >Cancel</Button>
      <Button
        variant="contained"
        color="green"
        onClick={() => form.handleSubmit()}
        disabled={form.isSubmitting}
      >Save</Button>
    </DialogActions>
  </Dialog>;
}