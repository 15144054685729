import {Canvas, Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import React from "react";
import endpoints from "../../endpoints";
import {useParams} from "react-router-dom";
import {IRouteParams} from "../../AppRoutes";
import useTeacherInit from "../../loaders/useTeacherInit";
import {ITeacherData} from "../../types/ITeacherData";

const ParentLettersPDF: React.VFC<{teacherData: ITeacherData, classId: string | number}> = ({teacherData, classId}) => {
  const students = teacherData.students.filter((student) => student.klasses.includes(Number(classId)));
  return (
    <Document>
      {students && students.map(student => {
        return <Page size="LETTER">
          <View>
            <Text style={styles.title}>{student.name}</Text>
            <Image style={styles.image} src="/images/printouts/parent_letter.png" />
            <View style={styles.studentCodeView}>
              <Text style={styles.studentCode}>{student.student_code.toUpperCase()}</Text>
            </View>
          </View>
        </Page>
      })
      }
    </Document>
  )
}

Font.register({
  family: 'Bjola',
  src: '/fonts/bjola.ttf'
});
Font.register({
  family: 'OpenSans',
  src: '/fonts/OpenSans-Regular.ttf'
});

const styles = StyleSheet.create({
  image: {
    width: '612',
    height: '792',
    position: "absolute",
    marginTop: 0,
    marginLeft: 0,
    zIndex: -1
  },
  title: {
    fontSize: 36,
    textAlign: 'center',
    fontFamily: 'Bjola',
    paddingTop: 20,
    paddingLeft: 2
  },
  studentCodeView: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'OpenSans',
    position: "absolute",
    marginTop: 521,
    marginLeft: 90,
    width: 200,
    height: 50,
    display: "flex",
    alignItems: "center",
    alignContent: "center"
  },
  studentCode: {
    fontSize: 24,
    textAlign: 'center',
  },
});


export default ParentLettersPDF