import SectionContainer from "./SectionContainer";
import React from "react";

const Vocabulary: React.FC<{vocabulary: string}> = ({vocabulary}) => {
  return (
    <SectionContainer
      title={'Vocabulary'}
      icon={'/images/curriculum_images/Vocabulary.png'}
      iconColor={'#8cc63e'}
      innerHtml={vocabulary}
    />
  )
}

export default Vocabulary