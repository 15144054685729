import { faEmpire } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch } from '@material-ui/core';
import { IRouteParams } from 'AppRoutes';
import Button from 'components/ui/buttons/Button';
import useSharedStyles from 'components/useSharedStyles';
import endpoints from 'endpoints';
import _curriculumStandards from 'json/curriculum_standards.json';
import useCurrentUser from 'loaders/useCurrentUser';
import { justFetch } from 'mutations/mutate';
import { useMemo } from 'react';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { ICourseData } from './ICourseData';

const strands = _curriculumStandards as {
  strand: string,
  code: string,
  standards: {
    code_id?: string,
    code_letter?: string,
    code_grade?: string,
    code_grade_start?: string,
    code_grade_end?: string,
    description: string
  }[]
}[];
const colors: { [key: string]: { title: string, eRow: string, oRow: string } } = {
  CT: {
    title: '#800080',
    eRow: '#e6cde5',
    oRow: '#f2e5f2'
  },
  PM: {
    title: '#0aa69d',
    eRow: '#cbedeb',
    oRow: '#e6f5f5'
  },
  PI: {
    title: '#27a9e1',
    eRow: '#d4eef9',
    oRow: '#e9f6fc'
  },
  EC: {
    title: '#FBA537',
    eRow: '#feedd7',
    oRow: '#fff6eb'
  },
  PC: {
    title: '#9cca3c',
    eRow: '#ebf4d8',
    oRow: '#f5faeb'
  },
  SEL: {
    title: '#e84561',
    eRow: '#fadadf',
    oRow: '#fdecef'
  }
}


const ViewCourseStandards = () => {
  const { currentUser } = useCurrentUser();
  const [editView, setEditView] = useState(currentUser.is_god);
  const { params: { courseId } } = useRouteMatch<IRouteParams>();
  const { data: courseData } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const courseDetails = useMemo(() => {
    return courseData?.find(({ id }) => id.toString() === courseId);
  }, [courseId, courseData]);
  const [loadingStandards, setLoadingStandards] = useState<string[]>([]);
  const [updateStandardsError, setUpdateStandardsError] = useState(false);

  const mappedStrands = useMemo(() => {
    return strands.map(strand => {
      const strandStandards = strand.standards.map(standard => {
        const dashboardKey = `${strand.code}.${standard.code_grade || `${standard.code_grade_start}-${standard.code_grade_end}`}.${standard.code_id || standard.code_letter}`

        return {
          ...standard,
          dashboardKey,
          loading: loadingStandards.includes(dashboardKey),
          selected: courseDetails?.meta?.standards?.includes(dashboardKey)
        }
      });

      return {
        ...strand,
        show: strandStandards.filter(({ selected }) => selected).length > 0,
        standards: strandStandards
      }
    })
  }, [strands, loadingStandards, courseDetails]);
  const sharedClasses = useSharedStyles();

  const handleToggleStandard = (code: string, checked: boolean) => {
    setLoadingStandards(loadingStandards => loadingStandards.concat(code));

    const newStandards = checked ? (courseDetails?.meta?.standards || []).concat(code) : (courseDetails?.meta?.standards || []).filter(standardCode => standardCode !== code);

    justFetch(endpoints.course(courseId), 'PUT', {
      meta: {
        ...courseDetails?.meta,
        standards: newStandards
      }
    })
      .then(res => {
        setLoadingStandards(loadingStandards.filter(standardCode => standardCode !== code));

        if (!res.ok) {
          throw new Error();
        }

        mutate(endpoints.allBasicsCourses);
        mutate(endpoints.courseDetails(courseId), {
          ...courseDetails,
          meta: {
            ...courseDetails?.meta,
            standards: newStandards
          }
        });
      })
      .catch(() => { setLoadingStandards(loadingStandards.filter(standardCode => standardCode !== code)); setUpdateStandardsError(true); })
  }

  return <Box>
    <Dialog open={updateStandardsError}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>There was an error updating the standards for this course.</DialogContent>
      <DialogActions>
        <Box></Box>
        <Button
          onClick={() => setUpdateStandardsError(false)}
          variant="outlined"
        >
          well that sucks
        </Button>
      </DialogActions>
    </Dialog>
    {currentUser.is_god && <Box display="flex" flexDirection="row" alignItems="center" className={sharedClasses.hspacing2} mb={2}>
      <FontAwesomeIcon icon={faEmpire} />
      <FormControlLabel
        control={<Switch checked={editView} onChange={e => setEditView(e.target.checked)} color="primary" />}
        label="Edit Mode"
      /></Box>}
    {mappedStrands.map(strand => {
      if (!editView && !strand.show) {
        return null;
      }

      return <Box key={strand.code} mb={2}>
        <Box p={0.5} style={{ color: 'white', backgroundColor: colors[strand.code]?.title }}>{strand.strand}</Box>
        {strand.standards
          .filter(({ selected }) => editView || selected)
          .map((standard, i) => {
            return <Box key={standard.dashboardKey} display="flex" flexDirection="row" style={{ backgroundColor: i % 2 === 0 ? colors[strand.code]?.eRow : colors[strand.code]?.oRow }}>
              {editView && <Box display="flex" alignItems="center" justifyContent="center" width={64} flexShrink={0}>
                {standard.loading
                  ? <CircularProgress size={16} />
                  : <Checkbox
                    onChange={e => handleToggleStandard(standard.dashboardKey, e.target.checked)}
                    checked={standard.selected || false}
                  />
                }
              </Box>}
              <Box minWidth={96} fontWeight="bold" ml={1} display="flex" alignItems="center">
                {standard.dashboardKey}
              </Box>
              <Box p={0.5} display="flex" alignItems="center">
                {standard.description}
              </Box>
            </Box>
          })}
      </Box>
    })}
  </Box>
}

export default ViewCourseStandards;