import React, { useEffect } from 'react';
import VideoNav from './shared/VideoNav';
import useVideos from 'loaders/useVideos';
import Loading from 'components/loading/Loading';
import { useParams, Redirect } from 'react-router-dom';
import find from 'lodash/find';
import YouTube from 'react-youtube';
import ShareButton from './shared/ShareButton';
import Amplitude from 'utils/Amplitude';
import {IResource} from "../../types/IResource";

interface Category {
  title: string;
  link: string;
  backLink: string;
}

interface Categories {
  [key: string]: Category;
}

const categories: Categories = {
  'coding-concepts': {
    title: 'Coding Concept Videos',
    link: 'coding-concepts',
    backLink: '/videos/coding-concepts',
  },
  'creative-activities': {
    title: 'Creative Activity Videos',
    link: 'creative-activities',
    backLink: '/videos/creative-activities',
  },
};


const VideoPage: React.FC = (props) => {
  const { videos } = useVideos();
  const { id, category } = useParams<{ id: string; category: string }>();
  const video = find(videos, { id: Number(id) }) as IResource;
  const chosenCategory = categories[category] || categories['coding-concepts'];

  useEffect(() => {
    if (video) {
      Amplitude.track('Viewed Videos Page', {
        Section: 'Single Video',
        'Video Name': video.name,
      });
    }
  }, [video]);

  if (videos && !video) {
    return <Redirect to={chosenCategory.backLink} />;
  }

  return (
    <div className="relative flex flex-col min-h-full">
      <VideoNav
        title={chosenCategory.title}
        link={chosenCategory.link}
        video={video}
        backLink={chosenCategory.backLink}
      />
      {!videos && (
        <div className="flex justify-center items-center mt-32 h-64">
          <Loading />
        </div>
      )}
      {videos && (
        <div className="flex flex-col max-w-4xl mx-auto px-4 text-gray-700 mt-4">
          <h2 className="font-bold">{video.name}</h2>
          <p className="font-light" style={{ whiteSpace: 'pre-wrap' }}>{video.description}</p>
          <div className="flex justify-end mt-2">
            <ShareButton video={video} />
          </div>
          <div className="mt-4">
            {video.video_id &&
              <YouTube
                videoId={video.video_id}
                containerClassName="youtubeContainer"
              />
            }
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoPage;
