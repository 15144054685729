import {Box, Card, CircularProgress, Grid, makeStyles, Typography, useTheme} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { IRouteParams } from "AppRoutes";
import useSharedStyles from "components/useSharedStyles";
import { useTracking } from "context/TrackingProvider";
import endpoints from "endpoints";
import Button from "components/ui/buttons/Button";
import useDialogState from "hooks/useDialogState";
import useCurrentUser from "loaders/useCurrentUser";
import React, { Children, useEffect } from "react";
import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import { IResource } from "types/IResource";
import { ICourseData } from "./ICourseData";
import queryString from 'query-string';
import { IUserProfile } from "types/IUserProfile";
import DogEar from "components/ui/DogEar";
import PaywallBoostLiteracyDialog from "../../components/dialogs/paywalls/PaywallBoostLiteracyDialog";

const queryParams = queryString.parse(window.location.search);

const useViewCourseMaterialsCardStyles = makeStyles(theme => ({
  viewcoursematerialscard: {
    '&:hover .CourseMaterialsCardTitle': {
      'text-decoration': 'underline'
    }
  }
}))

const ViewCourseMaterials: React.FC = () => {
  const { params: { courseId } } = useRouteMatch<IRouteParams>();
  const { data: materials, error: materialsError } = useSWR<IResource[]>(endpoints.resourcesByCourseId(courseId));
  const sortedMaterials = materials?.sort((a,b) => {
    if (a?.name < b?.name) {
      return -1
    }
    if (a?.name > b?.name) {
      return 1
    }
    return 0
  })
  ?.sort((a,b) => {
    const plans = [undefined, 'Free', 'School', 'Premium+']
    return plans.indexOf(a?.plan) - plans.indexOf(b?.plan)
  })
  const sharedClasses = useSharedStyles();

  const teacherPrepMaterials = useMemo(() => {
    return sortedMaterials?.filter(({ instruction_type }) => instruction_type === 'Teacher Prep') || [];
  }, [sortedMaterials]);

  const guidedMaterials = useMemo(() => {
    return sortedMaterials?.filter(({ instruction_type }) => instruction_type === 'Guided') || [];
  }, [sortedMaterials]);

  const independentMaterials = useMemo(() => {
    return sortedMaterials?.filter(({ instruction_type }) => instruction_type === 'Independent') || [];
  }, [sortedMaterials]);

  const instructorLedMaterials = useMemo(() => {
    return sortedMaterials?.filter(({ instruction_type }) => instruction_type === 'Instructor Led') || [];
  }, [sortedMaterials])

  if (!sortedMaterials && !materialsError) {
    return <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  if (materialsError) {
    return <Alert severity="error">There was an error loading materials for this course.</Alert>
  }

  return <Box>
    {teacherPrepMaterials.length > 0 && <Box className={sharedClasses.vspacing2} mb={4}>
      <CourseMaterialsSectionHeader
        img_src="/images/courses/materials_icons/teacher-prep-icon.png"
        bgcolor="#27a9e1"
        title="Teacher Prep"
        subtitle="Teacher materials to help you you learn about this course"
      />
      <CourseMaterialsSectionContent>
        {teacherPrepMaterials.map((resource, index) => <CourseMaterialsCard key={index} resource={resource} />)}
      </CourseMaterialsSectionContent>
    </Box>}
    {independentMaterials.length > 0 && <Box className={sharedClasses.vspacing2} mb={4}>
      <CourseMaterialsSectionHeader
        img_src="/images/courses/materials_icons/independent.png"
        bgcolor="#ffb40f"
        title="Independent"
        subtitle="Best for small groups or solo learning in centers, all printable materials included."
      />
      <CourseMaterialsSectionContent>
        {independentMaterials.map((resource, index) => <CourseMaterialsCard key={index} resource={resource} />)}
      </CourseMaterialsSectionContent>
    </Box>}
    {guidedMaterials.length > 0 && <Box className={sharedClasses.vspacing2} mb={4}>
      <CourseMaterialsSectionHeader
        img_src="/images/courses/materials_icons/guided.png"
        bgcolor="#27a9e1"
        title="Guided"
        subtitle="Best for large group learning, all printable materials included."
      />
      <CourseMaterialsSectionContent>
        {guidedMaterials.map((resource, index) => <CourseMaterialsCard key={index} resource={resource} />)}
      </CourseMaterialsSectionContent>
    </Box>}
    {instructorLedMaterials.length > 0 && <Box className={sharedClasses.vspacing2}>
      <CourseMaterialsSectionHeader
        img_src="/images/courses/materials_icons/instructor-led.png"
        bgcolor="#ed2a7b"
        title="Teacher-Led"
        subtitle="Instructor required. All printable materials included."
      />
      <CourseMaterialsSectionContent>
        {instructorLedMaterials.map((resource, index) => (
          <CourseMaterialsCard key={index} resource={resource} />
        ))}
      </CourseMaterialsSectionContent>
    </Box>}
  </Box>;
}

const CourseMaterialsSectionHeader: React.FC<{ img_src?: string, title?: string, subtitle?: string, bgcolor?: string }> = ({ img_src, bgcolor, title, subtitle }) => {
  return <Box display="flex" alignItems="center">
    <Box height={60} width={60} display="flex" alignItems="center" justifyContent="center" borderRadius="50%" bgcolor={bgcolor}>
      <img src={img_src} style={{ height: '90%' }} />
    </Box>
    <Box ml={2}>
      <Typography variant="h1" style={{ fontFamily: 'Bjola' }}>{title}</Typography>
      <Typography style={{ fontStyle: 'italic', fontSize: 14 }}>{subtitle}</Typography>
    </Box>
  </Box>;
}

const CourseMaterialsSectionContent: React.FC = ({ children }) => {
  return <Grid container spacing={2}>
    {Children.toArray(children).map((child, index) => <Grid key={index} item>
      {child}
    </Grid>)}
  </Grid>
}

const userCanAccessResource = (resource: IResource, currentUser: IUserProfile) : boolean => {
  // Always allow Premium+ users to access cirriculum, and allow all users to access Free resources
  if (currentUser?.has_curriculum || resource?.plan === 'Free') {
    return true
  }
  // Always allow users with the same plan as required resource plan to access resource
  return currentUser?.plan === resource?.plan;

}

const CourseMaterialsPlanIndicator: React.FC<{ resource: IResource, canAccess: boolean }> = ({ resource, canAccess }) => {
  // If user has access to the resource, don't include an indicator badge
  if (canAccess) {
    return null
  }

  // Otherwise, show them an indicator Premium or Premium+ badge depending on the resource level
  switch (resource?.plan) {
    case 'School':
      return <>
        <Typography style={{ fontSize: 20, fontWeight: 'bold', display: 'inline-block' }}>Premium Lesson</Typography>
      </>
    case 'Premium+':
      return <>
        <Typography style={{ fontSize: 20, fontWeight: 'bold', display: 'inline-block' }}>Premium+ Lesson</Typography>
      </>
    default:
      return null
  }
}

const CourseMaterialsCard: React.FC<{ resource: IResource }> = ({ resource }) => {
  const { track } = useTracking();
  const { currentUser } = useCurrentUser()
  const canAccess = userCanAccessResource(resource, currentUser);
  const courseMaterialsCardClasses = useViewCourseMaterialsCardStyles()
  const teacherPaywallDialogState = useDialogState();
  const theme = useTheme();

  const getDogEarConfig = (plan: string | undefined) => {
    switch (plan) {
      case 'Premium+':
        return {image: '/images/badges/pro-plus-badge-100.png', color: theme.palette.newPink.main}
      case 'School':
        return {image: '/images/badges/premium-badge-100.png', color: theme.palette.blue.main}
    }
    return null
  }

  const handleAccessibleClick = () : void => {
    track('Opened activity', {
      'Resource Name': resource?.name,
      'Course ID': resource?.course_id,
      'Plan': resource?.plan
    })
  }

  const handleInaccessibleClick = () : void => {
    track('Attempted to open Premium activity', {
      'User ID': currentUser?.id
    });
    teacherPaywallDialogState.handleOpen();
  }

  const dogEarConfig = getDogEarConfig(resource.plan);

  return <Card className={courseMaterialsCardClasses.viewcoursematerialscard} variant="outlined"
               style={{maxWidth: 315, height: '100%'}}>
    <PaywallBoostLiteracyDialog open={teacherPaywallDialogState.open} onClose={teacherPaywallDialogState.onClose}/>
    {dogEarConfig && <DogEar imageUrl={dogEarConfig.image} color={dogEarConfig.color}/>}
    <a
      href={canAccess ? resource?.resource_url : undefined}
      target="_blank"
      onClick={canAccess ? handleAccessibleClick : handleInaccessibleClick} rel="noreferrer"
    >
      <Box p={3} height="100%" flexDirection="column" alignItems="flex-start">
        <Box mb={1}>
          <CourseMaterialsPlanIndicator resource={resource} canAccess={canAccess}/>
        </Box>
        <Box mb={2}>
          <Typography className="CourseMaterialsCardTitle" variant="h6">{resource.name}</Typography>
          <Typography style={{color: 'gray'}}>{resource.subtitle}</Typography>
        </Box>
        <Box mb={2}>
          <Typography>{resource.description}</Typography>
        </Box>
        {canAccess && (
          <Button
            variant="outlined"
            color="blue"
            size="small"
            style={{marginTop: '1rem'}}
          >
            <>View Activity</>
          </Button>
        )}
      </Box>
    </a>
  </Card>
}

const VersionSwitch: React.FC = () => {
  const {params: {courseId}} = useRouteMatch<IRouteParams>();
  const {data: courseData} = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const courseDetails = useMemo(() => {
    return courseData?.find(({id}) => id.toString() === courseId);
  }, [courseId, courseData]);
  const {track} = useTracking();
  useEffect(() => {
    if (courseDetails?.name) {
      track('Viewed Course Materials page', {'Course': courseDetails.name, 'Location': queryParams['utm_source']});
    }
  }, [courseDetails]);

  return <ViewCourseMaterials />
}

export default VersionSwitch;