import {Box, Typography} from "@material-ui/core";
import React from "react";

const LessonHeader: React.FC<{thumbnailUrl: string, name: string, niceGrades: string}> = ({thumbnailUrl, name, niceGrades}) => {
  return (
    <Box>
      <img src={thumbnailUrl} style={{
        width: '100%',
        height: 150,
        objectFit: 'cover',
        position: "absolute",
        top: 75,
        left: 0
      }} alt={''}/>
      <Typography variant={'h1'} style={{
        position: "relative",
        textAlign: "center",
        marginTop: 110,
        fontWeight: 500,
        color: "white"
      }}>{name}</Typography>
      <div style={{
          position: "relative",
          textAlign: "center",
          marginTop: 15,
          fontSize: 20,
          fontWeight: 500,
          color: "white"
        }}
           dangerouslySetInnerHTML={{__html: niceGrades || ""}}/>
    </Box>
  )
}

export default LessonHeader