import React, { useMemo } from 'react';
import OnboardingLayout from './shared/OnboardingLayout';
import { Link } from 'react-router-dom';
import useParentInit from 'loaders/useParentInit';
import { saveAs } from 'file-saver';
import Amplitude from 'utils/Amplitude';
import { useEffect } from 'react';
import { routes } from 'routes';
import useCurrentUser from 'loaders/useCurrentUser';
import {Box, List, ListItem, makeStyles, Typography} from "@material-ui/core";


const SparklesIcon: React.VFC<{className: string}> = ({className}) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="sparkles"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
    >
      <path
        fill="currentColor"
        d="M324.42 103.16L384 128l24.84 59.58a8 8 0 0 0 14.32 0L448 128l59.58-24.84a8 8 0 0 0 0-14.32L448 64 423.16 4.42a8 8 0 0 0-14.32 0L384 64l-59.58 24.84a8 8 0 0 0 0 14.32zm183.16 305.68L448 384l-24.84-59.58a8 8 0 0 0-14.32 0L384 384l-59.58 24.84a8 8 0 0 0 0 14.32L384 448l24.84 59.58a8 8 0 0 0 14.32 0L448 448l59.58-24.84a8 8 0 0 0 0-14.32zM384 256a24 24 0 0 0-13.28-21.47l-104.85-52.42-52.4-104.84c-8.13-16.25-34.81-16.25-42.94 0l-52.41 104.84-104.84 52.42a24 24 0 0 0 0 42.94l104.84 52.42 52.41 104.85a24 24 0 0 0 42.94 0l52.4-104.85 104.85-52.42A24 24 0 0 0 384 256zm-146.72 34.53a24 24 0 0 0-10.75 10.74L192 370.33l-34.53-69.06a24 24 0 0 0-10.75-10.74L77.66 256l69.06-34.53a24 24 0 0 0 10.75-10.73L192 141.67l34.53 69.07a24 24 0 0 0 10.75 10.73L306.34 256z"></path>
    </svg>
  )
}

const BookIcon: React.VFC<{className: string}> = ({className}) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="book-heart"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className={className}
    >
      <path
        fill="currentColor"
        d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM149.8 109.1c24-20 59.7-16.4 81.6 5.8l8.6 8.7 8.6-8.7c22-22.2 57.7-25.8 81.6-5.8 27.5 23 28.9 64.2 4.3 89.1l-84.7 85.6c-5.5 5.5-14.3 5.5-19.8 0l-84.7-85.6c-24.5-24.9-23-66.1 4.5-89.1zM381.4 448H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"></path>
    </svg>
  )
}

const ComputerIcon: React.VFC<{className: string}> = ({className}) => {
  return (
    <div className={className}>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="laptop-code"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
      >
        <path
          fill="currentColor"
          d="M255.03 261.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L253.25 192l35.71-35.72c6.25-6.25 6.25-16.38 0-22.63l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0l-58.34 58.34c-6.25 6.25-6.25 16.38 0 22.63l58.35 58.34zm96.01-11.3l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0l58.34-58.34c6.25-6.25 6.25-16.38 0-22.63l-58.34-58.34c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L386.75 192l-35.71 35.72c-6.25 6.25-6.25 16.38 0 22.63zM624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z"></path>
      </svg>
    </div>
  )
}


const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    lineHeight: 20,
    maxWidth: 1100,
    padding: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  benefitList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  sparkleIcon: {
    width: 40,
    height: 40,
  },
  bookIcon: {
    width: 40,
    height: 40,
  },
  computerIcon: {
    width: 40,
    height: 35
  },
  benefitText: {
    marginLeft: 20,
    fontSize: 14,
    width: '85%'
  },
  downloadLink: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  }

}))

const LearningPage = () => {
  const {students, loading} = useParentInit()
  const {currentUser} = useCurrentUser()
  const classes = useStyles()

  const continueLink = useMemo(() => {
    // if the user is a school user, they should be able to continue to the next step without upgrading
    if (currentUser && currentUser.plan === 'School') {
      return routes.parentOnboarding.progress;
    }
    // if there are no students, then we assume the user won't enjoy a linked teacher's plan benefits
    if (!students || students.length === 0) {
      return routes.parentOnboarding.progressUpgrade;
    }
    const someStudentHasPaidTeacher = students.some(student => student.teacher_plan === 'School');
    return someStudentHasPaidTeacher ? routes.parentOnboarding.progress : routes.parentOnboarding.progressUpgrade;
  }, [currentUser, students]);

  useEffect(() => {
    Amplitude.track('(Parent Onboarding) Viewed Learning Path Page');
  }, []);

  function download() {
    fetch(
      'https://s3-us-west-2.amazonaws.com/resources.kodable.com/Learning+Path.pdf'
    )
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, 'kodable-learning-path.pdf');
      });
  }

  if (loading || continueLink.length === 0) {
    return null;
  }

  return (
    <OnboardingLayout
      imgName="abc"
      imgSrc="/images/onboarding/abc.jpg"
      title="Kodable Learning Path"
      activeSteps={3}
    >
      <Typography style={{textAlign: 'center'}}>Play-based learning allows {students && students[0].name}'s creativity and problem solving skills to grow.</Typography>
      <List className={classes.benefitList}>
        <ListItem disableGutters>
          <SparklesIcon className={classes.sparkleIcon} />
          <Typography className={classes.benefitText}>Play based learning is about exploring and experimenting.</Typography>
        </ListItem>
        <ListItem disableGutters>
          <BookIcon className={classes.bookIcon} />
          <Typography className={classes.benefitText}>Support {students && students[0] ? `${students[0].name}'s` : "Child's"} budding
            coding skills with the help of this useful guide.</Typography>
        </ListItem>
        <ListItem disableGutters>
          <ComputerIcon className={classes.computerIcon} />
          <Typography className={classes.benefitText}>Use the Kodable Learning Path as a reference, and remember every child is different.</Typography>
        </ListItem>
      </List>
      <Box className={classes.downloadLink}>
        <button
          onClick={download}
          className="bg-kodable-blue hover:bg-kodable-blue-dark active:bg-kodable-blue-darker mr-6 text-white justify-center items-center font-semibold text-lg flex px-8 py-2 rounded shadow-kodable text-center">
          Download the Learning Path
        </button>
      </Box>
      <div className="flex justify-center lg:justify-end items-center mt-12 lg:mt-24">
        <Link
          className="bg-kodable-orange hover:bg-kodable-orange-dark active:bg-kodable-orange-darker text-white items-center font-semibold text-lg px-4 flex py-2 rounded shadow-kodable text-center w-32"
          to={continueLink}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="w-6 h-6 mr-4">
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
          </svg>
          Next
        </Link>
      </div>
    </OnboardingLayout>
  );
}

export default LearningPage