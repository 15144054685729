import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useMemo, useState } from "react";

export interface IEventConfigParsed {
    sidebar_banner_img: string | null;
    sidebar_banner_link: string | null;
    event_slug: string | null;
}

export interface IEventResponse {
    id: number;
    event_name: string;
    event_config: string | null;
    start_datetime: string | null;
    end_datetime: string | null;
}

export interface IEventsContext {
    events: IEventResponse[];
}

export const EventsContext = React.createContext<IEventsContext>(null!);

const EventsProvider: React.FC = ({ children }) => {
    const [events, setEvents] = useState<IEventResponse[]>([]);

    useEffect(() => {
        const fetchEvents = async () => {
            const response = await justFetch(endpoints.event.allEvents(true), 'GET')
            const data = await response.json();
            setEvents(data);
        }

        fetchEvents();
    }, []);

    const eventsContextValue = useMemo(() => {
        return {
            events,
        }
    }, [events]);

    return (
        <EventsContext.Provider value={eventsContextValue}>
            {children}
        </EventsContext.Provider>
    );
}

export default EventsProvider;
