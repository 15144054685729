import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface KidsSideNavProps {
  studentName: string;
  studentCode: string;
}

const KidsSideNav: React.FC<KidsSideNavProps> = ({ studentName, studentCode }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const location = useLocation();
  const path = location.pathname.split('/');

  return (
    <nav
      className={`z-50 transition-all duration-500 ease-in-out bg-white h-screen max-h-full flex flex-col items-stretch w-32 min-w-32 lg:w-48 lg:min-w-48 sm:ml-0 fixed sm:relative ${
        mobileNavOpen ? 'ml-0' : '-ml-32'
      }`}>
      <button
        onClick={() => {
          setMobileNavOpen(!mobileNavOpen);
        }}
        className="shadow-md absolute sm:hidden top-2 -right-12 border-r border-t border-b border-kodable-blue bg-kodable-blue text-white rounded-r-lg">
        {mobileNavOpen ? (
          <svg
            className="w-12 h-12"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            className="w-12 h-12"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </button>
      <div className="pt-4 py-8 flex flex-col items-center text-center text-xl text-gray-800 font-semibold relative mx-auto border-r w-full">
        <svg
          aria-hidden="true"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496 512"
          className="w-12 h-12 md:w-16 md:h-16">
          <path
            fill="currentColor"
            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path>
        </svg>
        <div className="lg:block hidden relative w-full px-4">
          <div className="flex justify-center items-center">
            <span className="overflow-ellipsis overflow-hidden">
              {studentName}
            </span>
          </div>
        </div>
      </div>
      <div className="relative border-t pt-8 border-r flex-1 font-light overflow-scroll pb-8">
        <Link
          to={`/kids/${studentCode}/videos/all`}
          className={`flex flex-col justify-center items-center py-2 ${
            path[path.length - 1] === 'videos'
              ? 'bg-kodable-blue text-white'
              : 'text-kodable-red'
          }`}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="youtube"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="w-10 h-10">
            <path
              fill="currentColor"
              d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
          </svg>
          <span className="uppercase text-sm font-bold">All Videos</span>
        </Link>
      </div>
    </nav>
  );
}

export default KidsSideNav;
