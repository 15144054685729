import {Box, Grid} from "@material-ui/core";
import {Line} from "react-chartjs-2";
import React from "react";

export interface IRevenueIndexGenericChart {
    optionsLabel: string;
    labels: string[];
    dataSets: IRevenueIndexGenericChartDataSets[];
}

export interface IRevenueIndexGenericChartDataSets {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    fill: boolean;
}

const GenericChart: React.FC<IRevenueIndexGenericChart> = ({optionsLabel, labels, dataSets}) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: optionsLabel,
            },
        },
    }

    const data = {
        labels,
        datasets: dataSets,
    }

    return (
        <Grid item xs={12} style={{marginTop: "20px"}}>
            <Box width="100%" minWidth={700} margin="0 auto">
                <Line options={options} data={data}/>
            </Box>
        </Grid>
    )
}

export default GenericChart