import React, { Fragment, useState } from 'react';
import SolutionModal from 'components/modal/SolutionModal';

interface AnswerKeyProps {
  levelName: string;
  levelImage: string;
  video?: string;
}

const AnswerKey: React.FC<AnswerKeyProps> = ({ levelName, levelImage, video }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      {open && (
        <SolutionModal
          closeModal={() => setOpen(false)}
          imgSrc={levelImage}
          alt={levelName}
          video={video}
        />
      )}
      <div className="rounded-xl shadow-md border transition duration-500 transform hover:scale-105">
        <img
          src={levelImage}
          alt={levelName}
          className="object-cover rounded-t-xl"
        />
        <div className="m-4">
          <div className="text-gray-700 font-bold text-lg">{levelName}</div>
          <p className="text-gray-600 text-sm">
            Solution for level {levelName}
          </p>
        </div>
        <div className="flex justify-end m-4">
          <button
            onClick={() => setOpen(true)}
            className="flex items-center justify-center bg-kodable-blue hover:bg-kodable-blue-dark active:bg-kodable-blue-darker px-3 py-2 text-sm rounded text-white">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="cloud-download"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              className="w-4 h-4 mr-1">
              <path
                fill="currentColor"
                d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-139.9 93L305 412.3c-9.4 9.4-24.6 9.4-33.9 0l-92.7-92.7c-9.4-9.4-9.4-24.6 0-33.9l10.8-10.8c9.6-9.6 25.2-9.3 34.5.5l32.4 34.5V184c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24v125.9l32.4-34.5c9.3-9.9 24.9-10.1 34.5-.5l10.8 10.8c9.2 9.3 9.2 24.5-.1 33.9z"></path>
            </svg>
            View
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default AnswerKey;
