import TextField from "components/ui/TextField";
import { useFormikContext } from "formik";
import { IOrderRequest } from "../IOrderRequest";

export const SubmitOrderTextField = ({ field, label, rows, placeholder, disabled, size, type }: { field: keyof IOrderRequest, label?: string, rows?: number, placeholder?: string, disabled?: boolean, size?: number, type?: HTMLInputElement['type'] }) => {
  const form = useFormikContext<IOrderRequest>();

  return <TextField
    multiline={!!rows}
    id={field}
    name={field}
    label={label}
    value={form.values[field]}
    onChange={form.handleChange}
    onBlur={form.handleBlur}
    error={form.submitCount > 0 && !!form.errors[field]}
    helperText={form.touched[field] ? form.errors[field] : ''}
    placeholder={placeholder}
    disabled={disabled}
    type={type}
    inputProps={{
      size
    }}
  />
}