import {Box} from "@material-ui/core";
import React, {useEffect, useLayoutEffect, useState} from "react";
import useSharedStyles from "../useSharedStyles";
import {Pagination} from "@material-ui/lab";


type PaginationContainerProps = {
    data: any[],
    rowElement: (klass: any) => React.ReactFragment,
    header?: React.ReactFragment
}

const PaginationContainer: React.VFC<PaginationContainerProps> = ({data, rowElement, header}) => {
    const pageSize = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [dataSlice, setDataSlice] = useState(data.slice(0, pageSize));
    const numPages = Math.ceil(data.length / pageSize);
    const sharedClasses = useSharedStyles();

    useEffect(() => {
        setDataSlice(data.slice((currentPage*pageSize)-pageSize, currentPage*pageSize))
    }, [currentPage, data])

    useLayoutEffect(() => {
        setCurrentPage(1);
    }, [data])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value)
    }

    return (
        <Box className={sharedClasses.vspacing2}>
            {header && header}
            {data.length > pageSize && <Pagination
                count={numPages}
                color="primary"
                size="large"
                page={currentPage}
                onChange={handleChange}
            />}
            {dataSlice.map((klass) => rowElement(klass))}
        </Box>
    )
}

export default PaginationContainer