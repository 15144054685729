import { Route } from "react-router-dom";
import { routes } from "routes";
import { OrdersIndex } from "./orders/OrdersIndex";
import { QuotesIndex } from "./quotes/QuotesIndex";
import React from "react";

export const SalesIndex: React.VFC = () => {
  return <>
    <Route
      path={routes.sales.quotes.index}
      component={QuotesIndex}
    />
    <Route
      path={routes.sales.orders.index}
      component={OrdersIndex}
    />
  </>;
}