import useVideoAssignments from 'loaders/useVideoAssignments';
import useVideos from 'loaders/useVideos';
import Loading from 'components/loading/Loading';
import KidsSideNav from './shared/KidsSideNav';
import find from 'lodash/find';
import { Redirect, useParams } from 'react-router-dom';
import Youtube from 'react-youtube';
import Amplitude from 'utils/Amplitude';
import { IRouteParams } from 'AppRoutes';

export default function KidsVideoPage() {
  const { studentCode, id } = useParams<IRouteParams>();
  const { videoAssignments } = useVideoAssignments(studentCode);
  const { videos } = useVideos();

  const selectedVideo = find(videos, { id: Number(id) });

  if (videos && !selectedVideo) {
    return <Redirect to={`/kids/${studentCode}/videos`} />;
  }

  return (
    <div className="flex max-h-screen h-full">
      <KidsSideNav
        studentCode={studentCode}
        studentName={
          videoAssignments && videoAssignments.student
            ? videoAssignments.student.name
            : studentCode
        }
      />
      <div className="relative overflow-y-scroll w-full h-screen pt-16 sm:pt-0">
        <div className="relative flex flex-col items-center min-h-full max-w-screen-lg mx-auto px-4">
          {!videos && (
            <div className="flex justify-center items-center mt-32 h-64">
              <Loading />
            </div>
          )}
          {videos && selectedVideo && (
            <div className="max-w-3xl w-full mt-12">
              <h3 className="font-bold text-gray-700">{selectedVideo.name}</h3>
              <p className="font-light text-gray-700 mb-8">
                {selectedVideo.description}
              </p>
              { selectedVideo.video_id &&
                <Youtube
                  onPlay={() => {
                    Amplitude.track('Viewed Kids Video Page', {
                      Section: 'Single Video',
                      'Video Name': selectedVideo.name,
                    });
                  }}
                  videoId={selectedVideo.video_id}
                  containerClassName="youtubeContainer"
                />
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
