import React from 'react';
import { Box, Link, Paper, Typography } from "@material-ui/core";
import { format } from "date-fns";
import endpoints from "endpoints";
import { getQuoteNumber } from "pages/sales/quotes/getQuoteNumber";
import { ISalesProfile } from "pages/settings/teacher/ISalesProfile";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "routes";
import useSWR from "swr";
import { IGodmodeUserProfile } from "../IGodmodeUserProfile";

export const QuotesBlock: React.FC<{ teacher: IGodmodeUserProfile }> = ({ teacher }) => {
  const { data: salesProfile, error } = useSWR<ISalesProfile>(endpoints.salesProfile(teacher.id));

  if (salesProfile && salesProfile?.quotes.length === 0) {
    return null;
  }

  return <Paper variant="outlined">
    <Box p={2}>
      <Typography variant="h5" align="center" paragraph>Quotes</Typography>
      {salesProfile?.quotes.map((quote, index, { length }) => {
        return (
            <React.Fragment key={index}>
              <Link component={RouterLink} style={{ fontWeight: 'normal' }} to={routes.sales.quotes.view(quote.secret_code)}>
                Premium{quote.has_curriculum ? '+' : ''} x {quote.num_sites} on {format(new Date(quote.created_at), 'PP')} ({getQuoteNumber(quote)})
              </Link>
              {index < length - 1 ? ', ' : ''}
            </React.Fragment>
        )
      })}
    </Box>
  </Paper>;
}