import endpoints from "endpoints";
import { useFormikContext } from "formik";
import { justFetch } from "mutations/mutate";
import { IAnonymousQuoteResponse } from "pages/sales/IQuote";
import { useState, useEffect } from "react";
import { IOrderRequest } from "../IOrderRequest";

export const useQuickQuote = () => {
  const form = useFormikContext<IOrderRequest>();
  const [quote, setQuote] = useState<IAnonymousQuoteResponse>();
  const [quoteError, setQuoteError] = useState<boolean>(false);
  useEffect(() => {
    if (!form.values.num_sites) {
      return;
    }

    setQuote(undefined);
    justFetch(endpoints.quotes, 'POST', {
      has_curriculum: form.values.has_curriculum,
      num_sites: form.values.num_sites,
      term_months: form.values.term_months,
      quote_request_v2_id: form.values.quote_request_v2_id,
      teacher_id: form.values.license_holder_id
    })
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        return res.json();
      })
      .then((body: IAnonymousQuoteResponse) => {
        setQuote(body);
      })
      .catch(e => setQuoteError(true))
  }, [form.values.has_curriculum, form.values.num_sites, form.values.term_months]);

  return {
    quote
  }
}