import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Link, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import { routes } from "routes";
import PageHeader from "components/ui/PageHeader";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import React, { useEffect, useMemo, useState } from "react";
import useSWR, { mutate } from "swr";
import { ITeacherData } from "types/ITeacherData";
import EditPasswordDialog from "../../../components/dialogs/settings/EditPasswordDialog";
import EditProfileDialog from "../../../components/dialogs/settings/EditProfileDialog";
import { Route, useHistory, useLocation } from 'react-router-dom';
import DeleteAccountDialog from "../../../components/dialogs/settings/DeleteAccountDialog";
import useDialogState from "hooks/useDialogState";
import UnlinkAccountDialog from "../../../components/dialogs/settings/UnlinkAccountDialog";
import { ISalesProfile } from "./ISalesProfile";
import { FormikProvider, useFormik } from "formik";
import { justFetch } from "mutations/mutate";
import * as Yup from 'yup';
import { useAlert } from "context/AlertProvider";
import { Alert } from "@material-ui/lab";
import { BillingForm } from "pages/sales/orders/submit-order/AddBillingContact";
import { PlanHistory } from "./PlanHistory";
import { billingFromSalesProfile } from "pages/godmode/Users/Teachers/ViewUser/account/BillingContactBlock";
import TransferAccountDialog from "components/dialogs/settings/TransferAccountDialog";

const TeacherProfilePage: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const { data: salesProfile } = useSWR<ISalesProfile>(endpoints.salesProfile(currentUser.id));

  const { pathname } = useLocation();
  const history = useHistory();
  const sharedClasses = useSharedStyles();

  return (<>
    <PageHeader title="Account" inAppBar />

    {(salesProfile?.plan_history?.length || 0) > 0 && <Tabs
      value={pathname}
      onChange={(ev, newPage) => history.push(newPage)}
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab label="Profile" value={'/settings'} disableRipple />
      <Tab label="Billing" value={`/settings/billing`} disableRipple />
    </Tabs>}
    <Box py={4} px={6} className={sharedClasses.vspacing2}>
      <Route
        component={TeacherProfilePageInfo}
        path={'/settings'}
        exact
      />
      <Route
        component={TeacherProfilePageBilling}
        path={`/settings/billing`}
        exact
      />
    </Box>
  </>)
}

const TeacherProfilePageInfo: React.VFC = () => {
  return <Grid container spacing={2} style={{ maxWidth: 1000 }}>
    <Grid item xs={12} md={6}>
      <MyAccountProfilePageBlock />
    </Grid>
    <Grid item xs={12} md={6}>
      <MyOrganizationProfilePageBlock />
    </Grid>
  </Grid>;
}

const TeacherProfilePageBilling: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const { data: salesProfile } = useSWR<ISalesProfile>(endpoints.salesProfile(currentUser.id));

  return <>
    <ProfilePageBlockTitle>Billing Details</ProfilePageBlockTitle>
    <Box maxWidth={584}>
      <BillingContactBlock />
    </Box>
    <Typography variant="h2">Plan History</Typography>
    {salesProfile && <PlanHistory salesProfile={salesProfile} />}
  </>
}

export const BillingContactBlock: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const editBillingContactDialogState = useDialogState();
  const { data: salesProfile } = useSWR<ISalesProfile>(endpoints.salesProfile(currentUser.id));
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const alert = useAlert();
  const sharedStyles = useSharedStyles();

  const initialValues = useMemo(() => {
    let billing = billingFromSalesProfile(salesProfile);
    return {
      ...billing,
      billing_contact_first_name: salesProfile?.billing_contact_name?.split(' ')[0] || '',
      billing_contact_last_name: salesProfile?.billing_contact_name?.split(' ').slice(1).join('') || '',
      billing_contact_email: salesProfile?.billing_contact_email || '',
      billing_contact_phone: salesProfile?.billing_contact_phone || ''
    }
  }, [salesProfile]);

  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      billing_contact_first_name: Yup.string().required('Please enter the name of your billing contact'),
      billing_contact_last_name: Yup.string().required('Please enter the name of your billing contact'),

      billing_contact_address_street: Yup.string().required('Please enter a billing address'),
      billing_contact_address_street2: Yup.string().nullable(),
      billing_contact_address_city: Yup.string().required('Please enter a city'),
      billing_contact_address_province: Yup.string(),
      billing_contact_address_zip: Yup.string().required('Please enter a zip code'),
      billing_contact_address_country: Yup.string().required('Please enter a country'),

      billing_contact_email: Yup.string().email().required('Please enter an email for your billing contact'),
      billing_contact_phone: Yup.string(),
    }),
    onSubmit: values => {
      return justFetch(`/v2/sales/profile`, 'POST', values)
        .then(async res => {
          const body = await res.json();
          if (!res.ok) {
            if (res.status === 422) {
              form.setFieldError('username', body.message);
              setSubmitError(false);
            } else {
              form.setFieldError('username', undefined);
              setSubmitError(true);
            }
          } else {
            mutate(endpoints.salesProfile(currentUser.id));
            editBillingContactDialogState.onClose();
            alert.success('Billing Information Saved');
          }
        })
        .catch(() => setSubmitError(true))
    }
  })

  useEffect(() => {
    if (editBillingContactDialogState.open) {
      form.handleReset(null);
      setShowValidationMessage(false);
      setSubmitError(false);
    }
  }, [editBillingContactDialogState.open]);

  if (!salesProfile) {
    return null;
  }

  return <FormikProvider value={form}>
    <form onSubmit={form.handleSubmit}>
      <Dialog open={editBillingContactDialogState.open}>
        <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
        <DialogTitle>Edit Billing Details X</DialogTitle>
        <DialogContent className={sharedStyles.vspacing2}>
          {/* <Typography>This is who the invoice will be sent to for payment</Typography> */}
          <BillingForm />
          {!form.isValid && showValidationMessage && <Alert severity="error">Please correct the errors above to continue.</Alert>}
          {submitError && <Alert severity="error" action={
            <Button
              color="inherit"
              size="small"
              onClick={() => form.submitForm()}
            >Try again</Button>
          } >There was an error trying to submit this form.</Alert>}
        </DialogContent>
        <DialogActions>
          <Box>
            <Button
              onClick={editBillingContactDialogState.onClose}
              type="reset"
              disabled={form.isSubmitting}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              onClick={form.submitForm}
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              disabled={form.isSubmitting}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <ProfilePageBlock>
        <ProfilePageBlockContent>
          <ProfilePageBlockEntry>{salesProfile.billing_contact_name} ({salesProfile.billing_contact_email})</ProfilePageBlockEntry>
          {salesProfile.billing_contact_phone && <ProfilePageBlockEntry>Phone: {salesProfile.billing_contact_phone}</ProfilePageBlockEntry>}
          {initialValues.billing_contact_address_street ? <>
            <ProfilePageBlockEntry>{initialValues.billing_contact_address_street}</ProfilePageBlockEntry>
            {initialValues.billing_contact_address_street2 && <ProfilePageBlockEntry>{initialValues.billing_contact_address_street2}</ProfilePageBlockEntry>}
            <ProfilePageBlockEntry>{initialValues.billing_contact_address_city}, {initialValues.billing_contact_address_province ? `${initialValues.billing_contact_address_province},` : ''} {initialValues.billing_contact_address_zip}, {initialValues.billing_contact_address_country}</ProfilePageBlockEntry>
          </> : 'No billing address on file'}
          <Alert severity="info">Invoices will be sent to the billing contact.</Alert>
        </ProfilePageBlockContent>
        <ProfilePageBlockActions>
          <ProfilePageBlockAction onClick={editBillingContactDialogState.handleOpen}>Edit Billing Details</ProfilePageBlockAction>
        </ProfilePageBlockActions>
      </ProfilePageBlock>
    </form>
  </FormikProvider>;
}

/**
 * name (edit)
 * email address (edit)
 * school (edit)
 * plan type: kickstart / premium / premium+
 * 
 * 
 * actions: change password, delete account
 * 
 */
const MyAccountProfilePageBlock: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const editProfileDialogState = useDialogState();
  const editPasswordDialogState = useDialogState();
  const deleteProfileDialogState = useDialogState();
  const transferAccountDialogState = useDialogState();

  const { data: teacherData } = useSWR<ITeacherData>(endpoints.teacherInit);
  const history = useHistory();
  const { data: salesProfile } = useSWR<ISalesProfile>(endpoints.salesProfile(currentUser.id));

  const licenses = teacherData?.subscription?.licenses;
  const myLicenses = () => {
    const result = licenses?.filter(license => license.teacher_id == currentUser.id);
    return result? result : [];
  };

  const isAdmin = currentUser?.user_role == 'admin';
  const isSuperAdmin = currentUser?.user_role == 'super_admin';
  const adminID = teacherData?.organization_profile?.administrator_id || 0;
  const hasSuperAdmin = isAdmin && adminID && adminID != currentUser.id
  const haveAdmin = !isAdmin && adminID && adminID != currentUser.id
  const noOrg = !adminID && !salesProfile?.license_holder;
  const isMember = (currentUser?.user_role == 'member' || !currentUser?.user_role) && !noOrg;


  return <>
    <EditProfileDialog {...editProfileDialogState} />
    <EditPasswordDialog {...editPasswordDialogState} />
    <DeleteAccountDialog {...deleteProfileDialogState} />
    <TransferAccountDialog {...transferAccountDialogState} />
    <ProfilePageBlock>
      <ProfilePageBlockTitle>About Me</ProfilePageBlockTitle>
      <ProfilePageBlockContent>
        <ProfilePageBlockEntry onEdit={editProfileDialogState.handleOpen}>{currentUser.name} ({currentUser.username})</ProfilePageBlockEntry>
        {teacherData && noOrg && <ProfilePageBlockEntry onEdit={() => history.push(routes.settings.updateSchoolSearch)}>{teacherData?.school?.school_name || 'No school selected'}</ProfilePageBlockEntry>}
        {teacherData && isSuperAdmin && <ProfilePageBlockEntry>You are the organization administrator for <Typography>{salesProfile?.organization_name}</Typography></ProfilePageBlockEntry>}

        {teacherData && (hasSuperAdmin || (isSuperAdmin && myLicenses().length > 1)) && (myLicenses().length > 0) && <ProfilePageBlockEntry>
          You are the administrator for {myLicenses().length > 1? "these schools:":""}
          {myLicenses().map((license) => {
            return <Typography>{license.site_name}</Typography>
          })}

        </ProfilePageBlockEntry>}
        {teacherData && isMember && haveAdmin && <ProfilePageBlockEntry>Your school is <Typography>{teacherData?.school?.school_name || teacherData?.teachers?.[0]?.school_name || 'No school selected'}</Typography></ProfilePageBlockEntry>}
      </ProfilePageBlockContent>
      <ProfilePageBlockActions>
        <ProfilePageBlockAction onClick={editPasswordDialogState.handleOpen}>Change Password</ProfilePageBlockAction>
        <ProfilePageBlockAction onClick={deleteProfileDialogState.handleOpen}>Delete Account</ProfilePageBlockAction>
        <ProfilePageBlockAction onClick={transferAccountDialogState.handleOpen}>Transfer Account</ProfilePageBlockAction>
      </ProfilePageBlockActions>
    </ProfilePageBlock>
  </>
}

/**
 * license holder
 * current administrator
 * organization name
 * link/unlink
 */
const MyOrganizationProfilePageBlock: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const { data: salesProfile } = useSWR<ISalesProfile>(endpoints.salesProfile(currentUser.id));
  const unlinkAccountDialogState = useDialogState();

  const noOrg = !salesProfile?.administrator && !salesProfile?.license_holder;
  const sameAdminAndLicenseHolder = salesProfile?.administrator?.username === salesProfile?.license_holder?.username;

  const canLink = !salesProfile?.administrator && !salesProfile?.license_holder;
  const canUnlink = (salesProfile?.administrator && salesProfile.administrator.id !== currentUser.id)
    || (salesProfile?.license_holder && salesProfile.license_holder.id !== currentUser.id);

  return <>
    <UnlinkAccountDialog {...unlinkAccountDialogState} />
    <ProfilePageBlock>
      <ProfilePageBlockTitle>My Organization</ProfilePageBlockTitle>
      <ProfilePageBlockContent>
        {salesProfile?.organization_name && <ProfilePageBlockEntry>{salesProfile.organization_name}</ProfilePageBlockEntry>}
        {salesProfile?.license_holder && <ProfilePageBlockEntry>Managed by {salesProfile.license_holder.name} ({salesProfile?.license_holder?.username})</ProfilePageBlockEntry>}
        {salesProfile?.administrator && !sameAdminAndLicenseHolder && salesProfile.administrator.username !== currentUser.username && <ProfilePageBlockEntry>Your admin is {salesProfile.administrator.name} ({salesProfile?.administrator?.username})</ProfilePageBlockEntry>}
        {noOrg && <ProfilePageBlockEntry>Your account is not linked to an organization</ProfilePageBlockEntry>}
      </ProfilePageBlockContent>
      {(canLink || canUnlink) && <ProfilePageBlockActions>
        {canUnlink && <ProfilePageBlockAction onClick={unlinkAccountDialogState.handleOpen}>Leave Organization</ProfilePageBlockAction>}
      </ProfilePageBlockActions>}
    </ProfilePageBlock>
  </>;
}

const ProfilePageBlock: React.FC = ({ children }) => {
  return <Box component={Paper} {...{ variant: 'outlined' }} p={2} height="100%" display="flex" flexDirection="column">
    {children}
  </Box>;
}

const ProfilePageBlockTitle: React.FC = ({ children }) => {
  return <Typography variant="h2" paragraph>{children}</Typography>
}

const ProfilePageBlockContent: React.FC = ({ children }) => {
  return <Box flexGrow={1}>
    {children}
  </Box>
}

const ProfilePageBlockActions: React.FC = ({ children }) => {
  const sharedClasses = useSharedStyles();

  return <Box borderTop="1px solid rgba(0, 0, 0, 0.12)" mt={2} pt={2} display="flex" justifyContent="space-around" className={sharedClasses.hspacing2}>
    {children}
  </Box>
}

const ProfilePageBlockEntry: React.FC<{ label?: string; onEdit?: () => void }> = ({ label, onEdit, children }) => {
  const sharedClasses = useSharedStyles();

  return <Box display="flex" alignItems="center" pb={1}>
    {label && <Typography style={{ fontWeight: 'bold' }}>{label}:&nbsp;</Typography>}<Typography>{children}&nbsp;{onEdit ? <Link className={sharedClasses.hoverCursorPointer} onClick={onEdit}>edit</Link> : null}</Typography>
  </Box>
}

const ProfilePageBlockAction: React.FC<{ onClick: () => void }> = ({ onClick, children }) => {
  const sharedClasses = useSharedStyles();

  return <Link className={sharedClasses.hoverCursorPointer} onClick={onClick}>{children}</Link>;
}

export default TeacherProfilePage;