import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

interface Video {
  id: number;
  name: string;
}

interface VideoNavProps {
  title: string;
  link: string;
  backLink: string;
  video?: Video;
}

const VideoNav: React.FC<VideoNavProps> = ({ title, link, backLink, video }) => {
  return (
    <nav className="flex pt-16 md:pt-12 px-4 pb-8 border-b sticky top-0 bg-white z-10">
      <div>
        <Link
          to={backLink}
          className="text-white bg-kodable-green rounded py-2 px-4 mr-4 md:mr-8 block">
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </Link>
      </div>
      <div className="flex flex-col text-gray-800">
        <h1 className="text-2xl md:text-3xl font-light">{title}</h1>
        <div className="flex mt-2 text-sm md:text-base">
          <Link to="/parent/videos">Videos</Link>
          <span className="mx-2">/</span>
          <Link to={`/videos/${link}`}>{title}</Link>
          {video && (
            <Fragment>
              <span className="mx-2">/</span>
              <Link to={`/videos/${link}/${video.id}`}>{video.name}</Link>
            </Fragment>
          )}
        </div>
      </div>
    </nav>
  );
}

export default VideoNav;
