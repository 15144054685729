import React from 'react';
import Modal from 'components/modal/Modal';
import { useAlertDispatch } from 'context/AlertProvider';
import { useHistory } from 'react-router-dom';

interface DeleteStudentModalProps {
  student: { name: string; mutate: () => void };
  deleteStudent: () => Promise<void>;
  closeModal: () => void;
}

const DeleteStudentModal: React.FC<DeleteStudentModalProps> = ({
                                                                 student,
                                                                 deleteStudent,
                                                                 closeModal,
                                                               }) => {
  const history = useHistory();
  const dispatch = useAlertDispatch();
  return (
    <Modal closeModal={closeModal}>
      <div className="flex flex-col items-center pb-4 pt-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          className="text-yellow-300 w-48 h-48"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <div className="text-3xl font-bold text-gray-800">
          Delete {student.name}?
        </div>
        <div className="font-light py-6 text-gray-600">
          This cannot be undone!
        </div>
        <div className="flex justify-center items-center">
          <button
            onClick={closeModal}
            type="button"
            className="flex items-center justify-center text-gray-500 border border-gray-200 bg-white hover:bg-gray-100 active:bg-gray-200 px-4 py-2 rounded px-6 py-3 text-lg rounded mr-6">
            Cancel
          </button>
          <button
            onClick={() =>
              deleteStudent()
                .then(() => {
                  history.push('/');
                  student.mutate();
                  dispatch({
                    id: 'deleteStudent',
                    type: 'success',
                    title: 'Student Deleted!',
                    message: 'Student was removed successfully.',
                  });
                  setTimeout(() => {
                    dispatch({ type: 'removeAlert', id: 'deleteStudent' });
                  }, 3000);
                })
                .catch(() => {
                  dispatch({
                    id: 'student_not_deleted_error',
                    type: 'error',
                    title: 'Student Not Deleted!',
                    message: 'There was an error deleting this student.',
                  });
                })
            }
            type="button"
            className="bg-kodable-red hover:bg-kodable-red-dark active:bg-kodable-red-darker px-6 py-3 text-lg rounded text-white">
            Delete Student
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteStudentModal;
