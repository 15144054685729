import mutate from './mutate';

type config = {
  name: string
}

const useCreateStudent = () => {
  return (args: config) => {
    return mutate(`/v2/students`, 'POST', args);
  }
}

export default useCreateStudent