import React, { useEffect, useState } from "react"
import { GodmodePageWrapper } from "pages/godmode/GodmodePageWrapper"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core"
import GiftCodesProvider, { GiftCode, GiftCodeRedemptionLength, useGiftCodesContext } from "context/GiftCodesProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck, faCircleXmark, faCopy, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useDialogState from "hooks/useDialogState"
import { GodmodeConfirmDialog } from "pages/godmode/shared/GodmodeConfirmDialog"
import Button from "components/ui/buttons/Button"
import { justFetch } from "mutations/mutate"
import endpoints from "endpoints"
import { useAlert } from "context/AlertProvider"
import useSharedStyles from "components/useSharedStyles"
import TextField from "components/ui/TextField"

const GiftCodesIndex: React.FC = () => {
    return (
        <GodmodePageWrapper title="Gift Codes" loading={false}>
            <GiftCodesProvider>
                <GiftCodesHeader />
                <GiftCodesSearch />
                <GiftCodesTable />
            </GiftCodesProvider>
        </GodmodePageWrapper>
    )
}

const GiftCodesHeader: React.FC = () => {
    const sharedClasses = useSharedStyles()

    return (
        <Box display="flex" marginBottom="1.5rem">
            <Box className={sharedClasses?.vspacing1} width="50%" paddingRight="1rem">
                <Typography variant="body2">If you have friends, family, or someone in need that you would like to give Kodable access, you can create a gift code for them here. Please use discretion.</Typography>
                <Typography variant="body2">Gift codes can be redeemed at <a href="https://kodable.com/redeem">https://kodable.com/redeem</a></Typography>
            </Box>
            <Box className={sharedClasses?.vspacing1} width="50%" display="flex" alignItems="flex-end" justifyContent="flex-end" flexDirection="column">
                <Typography variant="h6">Create Gift Code</Typography>
                <GiftCodesCreateButtons />
            </Box>
        </Box>
    )
}

const GiftCodesSearch: React.FC = () => {
    const { filter } = useGiftCodesContext()
    const [search, setSearch] = useState<string>('')

    useEffect(() => {
        filter(search)
    }, [search])

    return (
        <Box marginBottom="1rem">
            <TextField
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value)
                }}
                name="search"
                id="search"
                placeholder="Search by email or gift code"
                fullWidth
            />
        </Box>
    )
}

const GiftCodesCreateButtons: React.FC = () => {
    const { mutate } = useGiftCodesContext()
    const sharedClasses = useSharedStyles()
    const dialogState = useDialogState()
    const alert = useAlert()
    const [newGiftCode, setNewGiftCode] = useState<GiftCode>()
    const [isCopying, setIsCopying] = useState<boolean>(false)
    const [isCreating, setIsCreating] = useState<boolean>(false)
    
    const handleCreateGiftCode = (redemption_length: GiftCodeRedemptionLength) => {
        if (isCreating) {
            return
        }
        setIsCreating(true)
        justFetch(endpoints.godmode.giftCodes, 'POST', {
            redemption_length: redemption_length
        })
        .then(async (res) => {
            setIsCreating(false)
            if (res.status === 201) {
                mutate()
                const giftCode = await res.json()
                setNewGiftCode(giftCode)
                dialogState.handleOpen()
                return
            }
            const error = await res.json()
            alert.error('Something went wrong, try again later', error?.error)
        })
        .catch((error) => {
            setIsCreating(false)
            alert.error('Something went wrong, try again later', error)
        })
    }

    const handleCopy = () => {
        if (!newGiftCode) {
            return
        }
        navigator.clipboard.writeText(newGiftCode?.redemption_code)
        setIsCopying(true)

        setTimeout(() => {
            setIsCopying(false)
        }, 2000)
    }

    return (
        <Box>
            <Dialog open={dialogState?.open}>
                <DialogTitle>Gift Code Created</DialogTitle>
                <DialogContent>
                    <Box className={sharedClasses?.vspacing2} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <Typography align="center">Your {newGiftCode?.redemption_length} Subscription Gift Code is:</Typography>
                        <Button
                            startIcon={isCopying ? <FontAwesomeIcon icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCopy} />}
                            variant="contained"
                            color="green"
                            onClick={handleCopy}
                        >
                            {isCopying ? "Copied!" : newGiftCode?.redemption_code}
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleCopy()
                                dialogState?.onClose()
                            }}
                            color="primary"
                        >
                            OK
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" className={sharedClasses?.hspacing1}>
                <Button
                    variant="contained"
                    color="teal"
                    onClick={() => handleCreateGiftCode('6 Months')}
                    disabled={isCreating}
                >
                    6 Months
                </Button>
                <Button
                    variant="contained"
                    color="orange"
                    onClick={() => handleCreateGiftCode('12 Months')}
                    disabled={isCreating}
                >
                    12 Months
                </Button>
                <Button
                    variant="contained"
                    color="green"
                    onClick={() => handleCreateGiftCode('Lifetime')}
                    disabled={isCreating}
                >
                    Lifetime
                </Button>
            </Box>
        </Box>
    )
}

const GiftCodesTable: React.FC = () => {
    const { giftCodes } = useGiftCodesContext()

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Purchase Email</TableCell>
                        <TableCell>Code</TableCell>
                        <TableCell>Redemption Length</TableCell>
                        <TableCell>Redeemed?</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {giftCodes.map((giftCode) => (
                        <GiftCodesRow key={giftCode.id} giftCode={giftCode} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const GiftCodesRow: React.FC<{giftCode: GiftCode}> = ({giftCode}) => {
    return (
        <TableRow>
            <TableCell>{giftCode.email}</TableCell>
            <TableCell>{giftCode.redemption_code}</TableCell>
            <TableCell>{giftCode.redemption_length}</TableCell>
            <TableCell>{giftCode.is_redeemed ? <FontAwesomeIcon color="green" icon={faCircleCheck} /> : <FontAwesomeIcon color="red" icon={faCircleXmark} />}</TableCell>
            <TableCell>{giftCode.created_at}</TableCell>
            <TableCell><GiftCodesRowActions giftCode={giftCode} /></TableCell>
        </TableRow>
    )
}

const GiftCodesRowActions: React.FC<{giftCode: GiftCode}> = ({giftCode}) => {
    const { mutate } = useGiftCodesContext()
    const dialogState = useDialogState()
    const alert = useAlert()
    const sharedClasses = useSharedStyles()

    const handleConfirm = async () => {
        justFetch(`${endpoints.godmode.giftCodes}/${giftCode.id}`, 'DELETE')
        .then(async (res) => {
            dialogState.onClose()
            if (res.status === 200) {
                mutate()
                alert.success('Gift code deleted successfully')
                return
            }
            const error = await res.json()
            alert.error('Something went wrong, try again later', error?.error)
        })
        .catch((error) => {
            console.error(error)
            alert.error('Something went wrong, try again later.', error?.error)
        })
    }

    return (
        <>
            <GodmodeConfirmDialog
                dialogState={dialogState}
                onConfirm={handleConfirm}
                dialogTitle="Delete Gift Code"
                dialogContent={
                    <Box className={sharedClasses?.vspacing2}>
                        <Typography align="center">Are you sure you want to delete this gift code? This action cannot be undone.</Typography>
                        <Typography variant="h6" align="center">{giftCode?.email} - <strong>{giftCode?.redemption_code}</strong></Typography>
                    </Box>
                }
                variant="danger"
                confirmInputMatch="DELETE"
                confirmInputPlaceholder="Type DELETE to confirm"
            />
            <Button
                variant="contained"
                color="red"
                onClick={() => dialogState.handleOpen()}
                disabled={giftCode.is_redeemed}
            >
                <FontAwesomeIcon color="white" icon={faTrashCan} />
            </Button>
        </>
    )
}

export default GiftCodesIndex