import React, {useState} from "react";
import endpoints from "endpoints";
import {Tab, Tabs} from "@material-ui/core";
import {TabContext} from "@material-ui/lab"
import {ISubscription} from "../../../../types/ISubscription";
import useSWR from "swr";
import AccountManagementTable from "./AccountManagementTable";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

export type AccountManagementIndexTabOptions = 'large_deals' | 'small_deals';

interface AllSubscriptionsInterface {
  subscriptions: ISubscription[]

}
const AccountManagementIndexTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<AccountManagementIndexTabOptions>('large_deals')
  const { data, mutate } = useSWR<AllSubscriptionsInterface>(endpoints.godmode.subscriptions.all(true, true));
  
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: AccountManagementIndexTabOptions) => {
    setSelectedTab(newValue);
  }
  
  const updateSubscriptions = async () => {
    await mutate();
  }

  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TabContext value={selectedTab}>
        <Tabs value={selectedTab} onChange={handleChange} style={{backgroundColor: 'transparent'}}>
          <Tab label="Large Deals" value="large_deals"/>
          <Tab label="Small Deals" value="small_deals"/>
        </Tabs>
      </TabContext>
      <AccountManagementTable allSubscriptions={data?.subscriptions} dealSize={selectedTab} updateSubscriptions={updateSubscriptions} />
    </MuiPickersUtilsProvider>
  )
}

export default AccountManagementIndexTabs