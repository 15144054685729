import {
  convertCreativeBuildingsToSectionedCourses,
  getOrderedCoursesBySection,
  orderedSections
} from "pages/courses/courseSections";
import { IClassLessonUnassignment } from "components/dialogs/courses/EditLessonAssignmentsDialog";
import {
  ELessonType,
  generateOrderedLessons,
  generateOrderedMissions,
  ICourseData,
  IGalaxyCourse,
  ILevelCompletion,
  ILevelData
} from "pages/courses/ICourseData";
import { IStudentSchoolProfile } from "../../types/IStudentSchoolProfile";
import { addMinutes } from 'date-fns';
import {creativeBuildingsData} from "../courses/CreativeBuildingsData";
import {IBadgeCompletion, IBadgeCompletionProgress} from "../../types/IBadgeData";
import {badgeData} from "../courses/BadgeData";
import {IStudentSimple} from "../../types/IStudent";

export const levelCompletionMapping: { [key: number]: number } = {
  169: 617,
  171: 618,
  170: 620,
  501: 621,
  102: 622,
  103: 624,
  196: 627,
  189: 628,
  190: 629,
  191: 630
}

export interface IProgressReportRawData {
  level_completions: ILevelCompletion[];
  badge_completions: {
    badges: IBadgeCompletion[][]; // api is returning these case in a length 1 array
    badge_progress: IBadgeCompletionProgress[][]; // api is returning these case in a length 1 array
  }
  course_assignments?: number[];
  lesson_omissions?: IClassLessonUnassignment[];
  hoc_garbage_pickup_count?: {
    student_id: number;
    hoc_garbage_pickup_count: number;
  }[];
}

export interface IProgressReportView {
  orderedCourses: IProgressReportCourse[];
}

// contains the entirety of a classes progress in a given course
export interface IProgressReportCourse{
  courseId: number;

  galaxyImage?: string;
  iconUrl?: string;
  dashboardTitle: string;
  dashboardSubtitle: string;
  emptyCourse: boolean;

  numLevels: number;

  legacyStatus: boolean[];

  orderedProgress: IProgressReportOrderedProgress[];

  orderedMissions?:IProgressReportOrderedMission[]
  orderedLessons?: IProgressReportOrderedLesson[];
  orderedBadges?: IProgressReportOrderedBadge[];
}

// contains a class-wide progress summary for a specific course
interface IProgressReportOrderedProgress{
  completionPercentage: number;
  showAsPercent: boolean;
  completionDate?: Date;
  threeStarredEverything?: boolean;
  currentLocation?: boolean;
  hoc_garbage_pickup_count?: number;
}

// contains progress for all students for a given mission
export interface IProgressReportOrderedMission {
  missionType: string;
  premium: boolean;
  practiceLevels: number[];
  orderedLessons?: {
    lessonIndex: number;
    lessonId: string;
    orderedLevels: {
      levelId: number;
      orderedProgress: {
        stars: number;
      }[]
    }[]
  }[];
}

export interface IProgressReportOrderedBadge{
  badgeId: string;
  name: string;
  image: string;
  size: 'major' | 'minor';
  tiers: IProgressReportOrderedBadgeTier[];
  unit?: string;
  pluralUnit?: string;
}

interface IProgressReportOrderedBadgeTier{
  value: string;
  earned: boolean[];
  progress: number[];
}

// contains progress for all students for a given lesson
interface IProgressReportOrderedLesson{
  lessonId: string;
  type: ELessonType;
  dashboardTitle: string;

  orderedLevels: IProgressReportOrderedLevel[]
}

// contains progress for all students for a given level
interface IProgressReportOrderedLevel{
  levelId: number;

  dashboardTitle: string;
  orderedProgress: {
    stars: number;
  }[]
}

interface ILevelAssessmentCompletion {
  student_id: number;
  course_id: number;
  mission_type: string;
  score: number;
  created_at: string;

}


const defaultIProgressReportView: IProgressReportView = {
  orderedCourses: []
}

export const generateClassProgressReportView = (courseData: ICourseData[], legacyCourseData: ICourseData[], creatorCourseData: ICourseData[], levelData: ILevelData[], orderedStudents: IStudentSchoolProfile[], reportData: { [key: string]: IProgressReportRawData }, paidUser: boolean, showAsPercent: boolean): IProgressReportView => {
  const course_assignments = Object.keys(reportData).reduce((memo: number[], klassId) => Array.from(new Set([...memo, ...reportData[klassId].course_assignments ?? []])), []);

  if (Object.keys(reportData).length === 0) {
    return defaultIProgressReportView;
  }

  return Object.keys(reportData)
    .map(klassId => {
      const filteredOrderedStudents = orderedStudents.filter((student) => student.klasses.includes(Number(klassId)));
      return {
        ...generateStudentsProgressReportView(courseData, creatorCourseData, legacyCourseData, levelData, filteredOrderedStudents, { ...reportData[klassId], course_assignments }, paidUser, showAsPercent),
        klassId
      }
    })
    .sort((a, b) => parseInt(a.klassId) - parseInt(b.klassId))
    .reduce((_memo, klassReportView, _i, arr) => {
      const memo = _i === 1 ? { ..._memo, orderedCourses: _memo.orderedCourses.map((course, i) => ({ ...course, orderedProgress: [classProgressRollup(arr[0], i)] })) } : _memo;

      return {
        ...memo,
        orderedCourses: memo.orderedCourses.map((course, i) => {
          return {
            ...course,
            orderedProgress: course.orderedProgress.concat(classProgressRollup(klassReportView, i))
          }
        })
      }
    })
}

const classProgressRollup = (klassReportView: IProgressReportView, i: number) => {
  return klassReportView.orderedCourses[i].orderedProgress.reduce((memo, progress, _i) => {
    return {
      completionPercentage: (memo.completionPercentage * _i + progress.completionPercentage) / (_i + 1),
      hoc_garbage_pickup_count: klassReportView.orderedCourses[i].courseId === 7 ? (memo.hoc_garbage_pickup_count || 0) + (progress.hoc_garbage_pickup_count || 0) : undefined,
      showAsPercent: true
    }
  }, { completionPercentage: 0, hoc_garbage_pickup_count: 0, showAsPercent: true })
}

export const generateBulkStudentsProgressReportView = (courseData: ICourseData[], creatorCourseData: ICourseData[], legacyCourseData: ICourseData[], levelData: ILevelData[], orderedStudents: IStudentSchoolProfile[], reportData: { [key: string]: IProgressReportRawData }, paidUser: boolean, showAsPercent: boolean): IProgressReportView => {
  const course_assignments = Object.keys(reportData).reduce((memo: number[], klassId) => Array.from(new Set([...memo, ...reportData[klassId].course_assignments!])), []);

  if (Object.keys(reportData).length === 0) {
    return defaultIProgressReportView;
  }

  const result = Object.keys(reportData)
    .map(klassId => {
      const filteredOrderedStudents = orderedStudents.filter((student) => student.klasses.includes(Number(klassId)));
      const generatedClassReport = generateStudentsProgressReportView(courseData, creatorCourseData, legacyCourseData, levelData, filteredOrderedStudents, { ...reportData[klassId], course_assignments }, paidUser, showAsPercent);
      return {
        ...generatedClassReport,
        klassId
      }
    })
    .sort((a, b) => parseInt(a.klassId) - parseInt(b.klassId))
    .reduce((memo, reportView) => {
      return {
        ...memo,
        orderedCourses: memo.orderedCourses.map((course, i) => {
          return {
            ...course,
            orderedProgress: course.orderedProgress.concat(reportView.orderedCourses[i].orderedProgress)
          }
        })
      }
    })
  return result;
}

export const getMajorBadgeProgress = (orderedBadges: IProgressReportOrderedBadge[]) => {
  if (!orderedBadges || orderedBadges.length === 0) {
    return {
      maxBadges: 0,
      earnedBadges: 0,
      totalProgress: 0
    }
  }
  function countEarnedBadges(badge: IProgressReportOrderedBadge) {
    return badge.tiers.reduce((count, tier) =>
      count + tier.earned.filter(Boolean).length, 0);
  }

  const majorBadges = orderedBadges.filter(badge => badge.size === 'major');
  const earnedMajorBadges = majorBadges.reduce((total, badge) =>
    total + countEarnedBadges(badge), 0);

  const studentCount = orderedBadges[0].tiers[0].earned.length;
  const maxMajorBadges = majorBadges.reduce((total, badge) =>
    total + (badge.tiers.length * studentCount), 0);

  return {
    maxBadges: maxMajorBadges,
    earnedBadges: earnedMajorBadges,
    totalProgress: Math.round(earnedMajorBadges/maxMajorBadges * 100)
  }
}

export const generateStudentsProgressReportView = (
  courseData: ICourseData[],
  creatorCourseData: ICourseData[],
  legacyCourseData: ICourseData[] | undefined,
  levelData: ILevelData[],
  orderedStudents: IStudentSimple[],
  reportData: IProgressReportRawData,
  paidUser: boolean,
  showAsPercent: boolean
): IProgressReportView => {

  const { level_completions, badge_completions, course_assignments, lesson_omissions, hoc_garbage_pickup_count } = reportData;

  const levelAssessments: ILevelAssessmentCompletion[] = [];
  // maps legacy ids to new ids
  level_completions.forEach(levelCompletion => {

    if (levelCompletion.level_id === 0) {
      const location = levelCompletion.level_location.split("/");
      if (location.length > 2) {

        const course = courseData?.find(({name}) => name === location[0]);
        const mission = course?.configuration.missionNodes?.find(({missionType}) => missionType === location[1]);
        if (location[2] === "practice"){
          levelAssessments.push({
            course_id: course?.id!,
            mission_type: mission?.missionType!,
            student_id: levelCompletion.student_id,
            score: levelCompletion.score,
            created_at: levelCompletion.created_at
          });
        } else if (location.length === 4) {
          const lesson = course?.configuration.lessonNodes?.find(({nodeID}) => nodeID === mission?.nodes[Number(location[2])]);
          const level = lesson?.levels?.at(Number(location[3]));
          if (level) {
            level_completions.push({
              ...levelCompletion,
              level_id: level
            })
          }
        }
      }
    }
    const mapping = levelCompletionMapping[levelCompletion.level_id];

    if (mapping) {
      level_completions.push({
        ...levelCompletion,
        level_id: mapping
      })
    }
  });
  const galaxyCourse = courseData?.find(({ id }) => id === 1) as IGalaxyCourse;
  const orderedCoursesBySection = getOrderedCoursesBySection([
    ...courseData,
    ...convertCreativeBuildingsToSectionedCourses(creativeBuildingsData) as ICourseData[],
    ...creatorCourseData
  ]);
  const nonLegacyStudents: number[] = [];
  const studentsWithData: number[] = [];
  level_completions.forEach(levelCompletion => {
    if (!studentsWithData.includes(levelCompletion.student_id)) {
      studentsWithData.push(levelCompletion.student_id);
    }
    if (levelCompletion.level_location !== levelCompletion.level_id.toString() && !nonLegacyStudents.includes(levelCompletion.student_id)){
        nonLegacyStudents.push(levelCompletion.student_id);
    }
  });
  const legacyStudents = orderedStudents.map(({ id }) => id).filter(id => !nonLegacyStudents.includes(id) && studentsWithData.includes(id));
  // student id => level id
  const processedLevelCompletions: { [key: number]: { latestUpdate?: { levelId: number, created_at: Date }, completions: { [key: number]: ILevelCompletion[] } } } = level_completions.reduce((processedLevelCompletions: { [key: number]: { latestUpdate?: { levelId: number, created_at: Date }, completions: { [key: number]: ILevelCompletion[] } } }, levelCompletion) => {
    const student = processedLevelCompletions[levelCompletion.student_id] || {
      completions: []
    };

    const currentUpdate = {
      levelId: levelCompletion.level_id,
      created_at: new Date(levelCompletion.created_at)
    };

    let latestUpdate = student.latestUpdate || currentUpdate || "";

    if (currentUpdate.created_at.getTime() - latestUpdate.created_at.getTime() > 0) {
      latestUpdate = currentUpdate;
    }

    const completions = (student.completions[levelCompletion.level_id] || []);

    completions.push(levelCompletion);
    return {
      ...processedLevelCompletions,
      [levelCompletion.student_id]: {
        latestUpdate,
        completions: {
          ...student.completions,
          [levelCompletion.level_id]: completions
        }
      }
    }
  }, {});
  const orderedCourses = orderedSections
    .reduce((acc: ICourseData[], section) => {
      return acc.concat(orderedCoursesBySection[section] || []);
    }, [])
    .map(course => {
      const isBeachCleanup = course.id === 7;

      const legacyCourse = legacyCourseData?.find(({ id }) => id === course.id);
      const orderedLessons = !legacyCourse ? [] : legacyCourse.meta.emptyCourse ? [] : generateOrderedLessons(legacyCourse, true);

      const orderedMissions = course.meta.emptyCourse? [] : generateOrderedMissions(course, true);
      const orderedLevels = orderedLessons
        .reduce((orderedLevels: number[], lesson) => {
          return orderedLevels.concat(lesson.levels || []);
        }, []);

      return {
        courseId: course.id,

        galaxyImage: galaxyCourse.configuration.planetNodes.find(planetNode => planetNode.courseID === course.id)?.image,
        iconUrl: course.meta.iconUrl,
        dashboardTitle: course?.meta?.dashboardTitle!,
        dashboardSubtitle: course?.meta?.dashboardSubtitle!,
        emptyCourse: course.meta.emptyCourse || false,

        numLevels: orderedLevels.length,

        orderedProgress: orderedStudents.map(student => {
          let completedLevels = 0;
          let completedThreeStarLevels = 0;
          let courseCompletionTime = 0;

          const studentLevelStats = orderedLevels.map(levelId => {
            const completions = processedLevelCompletions[student.id]?.completions[levelId] || [];
            const maxStars = completions.reduce((acc, cur) => Math.max(acc, cur.score), 0);
            const levelCompletionTime = completions.reduce((acc, cur) => Math.max(acc, new Date(cur.created_at).getTime()), 0);

            if (maxStars > 0) {
              completedLevels++;
            }

            if (maxStars === 3) {
              completedThreeStarLevels++;
            }

            if (levelCompletionTime > courseCompletionTime) {
              courseCompletionTime = levelCompletionTime;
            }


            return {
              levelId,
              maxStars
            }
          });

          return {
            studentId: student.id,
            name: student.name,
            completionPercentage: completedLevels / orderedLevels.length,
            completionDate: new Date(courseCompletionTime),
            showAsPercent,
            threeStarredEverything: completedThreeStarLevels === orderedLevels.length,
            currentLocation: studentLevelStats.some(({ levelId }) => levelId === processedLevelCompletions[student.id]?.latestUpdate?.levelId),
            hoc_garbage_pickup_count: isBeachCleanup ? hoc_garbage_pickup_count?.find(({ student_id }) => student_id === student.id)?.hoc_garbage_pickup_count : undefined
          }
        }),

        orderedLessons: orderedLessons.map(lesson => {
          return {
            lessonId: lesson.nodeID,
            type: lesson.lessonType,
            dashboardTitle: lesson.meta?.dashboardTitle!,
            orderedLevels: (lesson.levels || [lesson.mediaLevelID!]).map(levelId => {

              const level = levelData.find(({ id }) => id === levelId);
              return {
                levelId,
                dashboardTitle: level?.meta?.dashboardTitle || level?.name!,
                orderedProgress: orderedStudents.map(student => {
                  const completions = processedLevelCompletions[student.id]?.completions[levelId] || [];
                  let stars = completions.reduce((acc, cur) => Math.max(acc, cur.score), 0);

                  if (lesson.lessonType === ELessonType.mediaContent && completions.length > 0) {
                    stars = 3;
                  }

                  return { stars }
                })
              }
            }) || []
          }
        }),
        orderedMissions: orderedMissions.map((mission, missionIndex) => {
            return {
            missionType: mission.missionType,
            premium: mission.isPaywalled,
            practiceLevels: orderedStudents.map(student => {
              return levelAssessments.find(({student_id, course_id, mission_type}) => student_id === student.id && course_id === course.id && mission_type === mission.missionType)?.score || 0;
            }),
            orderedLessons: mission.nodes.map((lessonId, lessonIndex) => {
              const lesson = course.configuration.lessonNodes?.find(lesson => lesson.nodeID === lessonId);
              return {
                lessonId,
                lessonIndex: lessonIndex,

                orderedLevels: lesson?.levels?.map((levelId, levelIndex) => {
                  return {
                    levelId,
                    orderedProgress: orderedStudents.map(student => {

                      // get level-specific completions (legacy)
                      let completions = processedLevelCompletions[student.id]?.completions[levelId] || [];

                      // get mission-based completions if they exist
                      if (processedLevelCompletions[student.id]?.completions[0]?.length) {
                        const levelLocation = course.name + "/" + missionIndex + "/" + lessonIndex + "/" + levelIndex;
                        const missionBasedCompletions = processedLevelCompletions[student.id]?.completions[0].filter(
                          (completion) => completion.level_location === levelLocation
                        );
                        // merge level-based and mission-based completions
                        completions = [...completions, ...missionBasedCompletions];
                      }
                      let stars = completions.reduce((acc, cur) => Math.max(acc, cur.score), 0);
                      return { stars }
                    })
                  }
                }) || []
              }
            }) || []
          }
        }),
        orderedBadges: badgeData.filter(badge => badge.courseId === course.id).map(badge => {
          return {
            badgeId: badge?.id,
            name: badge?.name,
            image: "",
            size: badge?.size,
            unit: badge?.unit,
            pluralUnit: badge?.pluralUnit,
            tiers: badge.tierValues.map((tier, index) => {
              const tierId = badge.tierProgressIds.length > index ? badge.tierProgressIds[index] : badge.tierProgressIds[0];
              return {
                value: tier,
                earned: orderedStudents.map(student => {
                  if (badge_completions.badges.length === 0) {
                    return false;
                  }
                  return badge_completions.badges.some(studentBadges =>
                    studentBadges.some(badgeCompletion =>
                      badgeCompletion.badge_id === badge.id &&
                      badgeCompletion.student_id === student.id &&
                      Number(badgeCompletion.tier) >= index + 1
                    )
                  );
                }),
                progress: orderedStudents.map(student => {
                  if (badge_completions.badge_progress.length === 0) {
                    return 0;
                  }
                  const completion = badge_completions.badge_progress.find(progressArray =>
                    progressArray.some(progress => progress.completion_id === tierId && progress.student_id === student.id)
                  )?.find(progress => progress.completion_id === tierId && progress.student_id === student.id);
                  return completion?.progress ? completion.progress : 0;
                })
              }
            })
          }
        }),

        legacyStatus: orderedStudents.map(student => {
          return legacyStudents.includes(student.id);
        })
      }
    });
  return {
    orderedCourses
  };
}


















export const getBadgeProgress = (course: IProgressReportOrderedBadge[] | undefined) => {
  if (!course) {
    return '--'
  }
  return `${getMajorBadgeProgress(course).totalProgress}%`
}

export const transformReportToCsv = (rowDescriptor: 'student' | 'class', rowTitles: string[], reportView: IProgressReportView) => {
  const columns: string[][] = [
    // header
    [
      rowDescriptor,
      ...rowTitles
    ],
    // each course
    ...reportView.orderedCourses.map(({ dashboardTitle, orderedProgress, courseId, orderedBadges }) => {
      return [
        dashboardTitle,
        ...orderedProgress.map(({ completionPercentage, hoc_garbage_pickup_count }) => {
          if (courseId === 7) {
            return `${hoc_garbage_pickup_count || 0} items`;
          }
          if (courseId === 901 || 902) {
            return getBadgeProgress(orderedBadges);
          }

          return `${completionPercentage * 100}%`;
        })
      ];
    })
  ];

  const rows: string[][] = [
    ...columns[0].map((_, rowIdx) => {
      return columns.map((colData) => {
        return colData[rowIdx] || '';
      })
    })
  ];

  const contents = [
    rows
      .map(rowData =>
        rowData.join(',')
      )
      .join('\n')
  ];

  return new Blob(contents, { type: 'text/csv;charset=utf-8' });
}

export const dateToUTC = (date: Date): Date => {
  return addMinutes(date, date.getTimezoneOffset())
}