import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import MenuItem from 'components/ui/MenuItem';
import Button from "components/ui/buttons/Button";
import PageContainer from "components/ui/PageContainer";
import PageHeader from "components/ui/PageHeader";
import useSharedStyles from "components/useSharedStyles";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import states from "states-us";
import * as Yup from "yup";
import Select from "components/ui/Select";
import { justFetch } from "mutations/mutate";
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import { mutate } from "swr";
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";
import { useTracking } from "context/TrackingProvider";
import {routes} from "../../../routes";

const UpdateSchoolSearchPage: React.VFC = () => {
  const history = useHistory();
  const { state } = useLocation<{ [key in 'zipcode' | 'city' | 'state' | 'searchOption']: string }>();
  const form = useFormik({
    initialValues: {
      zipcode: state?.zipcode || "",
      searchOption: state?.searchOption || "zipcode",
      city: state?.city || "",
      state: state?.state || "",
    },
    validationSchema: Yup.object({
      searchOption: Yup.string().oneOf(["zipcode", "citystate"]),
      zipcode: Yup.string().when("searchOption", {
        is: "zipcode",
        then: Yup.string()
          .required("Please enter a zip code.")
          .matches(/^[0-9]{5}$/, "Please enter a valid zip code."),
      }),
      city: Yup.string().when("searchOption", {
        is: "citystate",
        then: Yup.string().required("Please enter a value."),
      }),
      state: Yup.string().when("searchOption", {
        is: "citystate",
        then: Yup.string().required("Please select a state."),
      }),
    }),
    onSubmit: async ({ searchOption, zipcode, city, state }) => {
      history.replace({ state: { searchOption, zipcode, city, state } });

      if (searchOption === 'zipcode') {
        history.push(routes.settings.updateSchoolResults, { searchOption, zipcode });
      } else if (searchOption === 'citystate') {
        history.push(routes.settings.updateSchoolResults, { searchOption, city, state });
      }
    },
  });
  const sharedClasses = useSharedStyles();
  const [showNotInUSDialog, setShowNotInUSDialog] = useState(false);
  const { currentUser } = useCurrentUser();
  const { track } = useTracking();

  useEffect(() => {
    track('Viewed Onboarding Page', {
      'Page Name': 'Add School Search'
    });
  }, []);

  return (
    <PageContainer variant="centered" centeredFlavorImage={currentUser.needsTeacherOnboarding ? '/images/TeacherTransparent.png' : undefined}>
      <NotInUSDialog open={showNotInUSDialog} onCancel={() => setShowNotInUSDialog(false)}></NotInUSDialog>
      <form onSubmit={form.handleSubmit}>
        <Box className={sharedClasses.vspacing2}>
          <PageHeader title={currentUser.needsTeacherOnboarding  ? "First, let's add your school!" : "Search For Your School"}></PageHeader>
          <Typography variant="body1">
            Find your school to connect with other teachers using Kodable.
          </Typography>
          <Box>
            <RadioGroup
              id="searchOption"
              onChange={(e) =>
                form.setFieldValue("searchOption", e.target.value)
              }
              value={form.values.searchOption}
            >
              <FormControlLabel
                value="zipcode"
                control={<Radio />}
                label="Search by Zip Code"
              />
              {form.values.searchOption === "zipcode" && (
                <Box mb={1} display="flex" alignItems="center">
                  <TextField
                    autoFocus
                    onChange={form.handleChange}
                    value={form.values.zipcode}
                    id="zipcode"
                    variant="outlined"
                    placeholder="Enter a zip code"
                    helperText={form.submitCount > 0 && form.errors.zipcode}
                    error={form.submitCount > 0 && !!form.errors.zipcode}
                    margin="dense"
                  />
                </Box>
              )}
              <FormControlLabel
                value="citystate"
                control={<Radio />}
                label="Search by City and State"
              />
              {form.values.searchOption === "citystate" && (
                <Box display="flex">
                  <Box mr={2} flex={1}>
                    <TextField
                      autoFocus
                      onChange={form.handleChange}
                      value={form.values.city}
                      id="city"
                      variant="outlined"
                      placeholder="Enter a town or city"
                      fullWidth
                      helperText={form.submitCount > 0 && form.errors.city}
                      error={form.submitCount > 0 && !!form.errors.city}
                      margin="dense"
                    />
                  </Box>
                  <Box>
                    <FormControl
                      error={form.submitCount > 0 && !!form.errors.state}
                    >
                      <Select
                        variant="outlined"
                        fullWidth
                        displayEmpty
                        onChange={(e) =>
                          form.setFieldValue("state", e.target.value)
                        }
                        value={form.values.state}
                        id="state"
                        margin="dense"
                      >
                        <MenuItem disabled value="">
                          Select your state
                        </MenuItem>
                        {states
                          .filter(({ territory }) => !territory)
                          .map(({ name }) => (
                            <MenuItem key={name} value={name}>{name}</MenuItem>
                          ))}
                      </Select>
                      {form.submitCount > 0 && (
                        <FormHelperText>{form.errors.state}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Box>
              )}
            </RadioGroup>
          </Box>
          <Box>
            <Link href="#" onClick={e => {
              e.preventDefault();
              setShowNotInUSDialog(true);
            }}>
              I am outside the United States
            </Link>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {(currentUser.school_id || currentUser.is_non_usa) && <Button
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                onClick={() => history.goBack()}
              >
                Go Back
              </Button>}
            </Box>
            <Button
              color="primary"
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faSearch} />}
              disableElevation
              type="submit"
            >
              Search
            </Button>
          </Box>
        </Box>
      </form>
    </PageContainer>
  );
};

const NotInUSDialog: React.VFC<{ open: boolean, onCancel: (...args: any[]) => any }> = ({ open, onCancel }) => {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const { track } = useTracking();

  const handleSubmit = () => {
    setSubmitting(true);

    justFetch(endpoints.user(currentUser.id), 'PUT', {
      school_id: null,
      is_non_usa: true
    })
    .then(res => {
      setSubmitting(false);

      if (!res.ok) {
        throw new Error();
      }

      return mutate(endpoints.session, {
        user: {
          ...currentUser,
          is_non_usa: true
        }
      }, true);
    }).then(() => {
      history.push(routes.classes.index);
      track('Add School Completed', {
        result: 'non usa'
      });
    })
    .catch(() => { setSubmitting(false); setSubmitError(true) });
  }

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: submitting ? 'visible' : 'hidden' }} />
    <DialogTitle>Not in the United States?</DialogTitle>
    <DialogContent>
      <Typography>Our school database doesn't have schools outside the United States yet.</Typography>
      {submitError && <Alert severity="error" action={<Button size="small" color="inherit" onClick={handleSubmit}>Try again</Button>}>There was an error submitting this form.</Alert>}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onCancel}
        variant="outlined"
        disabled={submitting}
      >
        Back to search
      </Button>
      <Button
        onClick={handleSubmit}
        color="primary"
        variant="contained"
        disableElevation
        disabled={submitting}
      >
        Continue without adding a school
      </Button>
    </DialogActions>
  </Dialog>
}

export default UpdateSchoolSearchPage;
