import {Box, useTheme} from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import {useAddClassFormContext} from "context/AddClassFormProvider";
import React, {useEffect, useState} from "react";
import {GameTitle} from "../../../enums/GameTitle";
import GameCheckboxHorizontalCard from "../../../components/ui/cards/GameCheckboxHorizontalCard";


export const AddClassManualFormAssignGames: React.FC = () => {
  const recommendedBasicsGrades = ['all', 'k', '1']
  const recommendedCreatorGrades = ['all', '2', '3', '4', '5']
  const {addNewClassForm} = useAddClassFormContext()
  const sharedClasses = useSharedStyles();
  const [basicsChecked, setBasicsChecked] = useState(recommendedBasicsGrades.includes(addNewClassForm?.values?.grade))
  const [creatorChecked, setCreatorChecked] = useState(recommendedCreatorGrades.includes(addNewClassForm?.values?.grade))
  const theme = useTheme();

  const handleCheck = (value: any, game: GameTitle) => {
    if (game === GameTitle.BASICS) {
      if (!creatorChecked && basicsChecked) {
        setCreatorChecked(true);
      }
      setBasicsChecked(value);
    }
    if (game === GameTitle.CREATOR) {
      if (!basicsChecked && creatorChecked) {
        setBasicsChecked(true);
      }
      setCreatorChecked(value)
    }
  }

  useEffect(() => {
    let newValue = []
    if (basicsChecked) {
      newValue.push(GameTitle.BASICS)
    }
    if (creatorChecked) {
      newValue.push(GameTitle.CREATOR)
    }
    addNewClassForm.setFieldValue('assigned_games', newValue)
  }, [basicsChecked, creatorChecked])

  return (
    <Box className={sharedClasses.vspacing4}>
      <Box
        className={sharedClasses.vspacing2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <GameCheckboxHorizontalCard
          color={theme.palette.newOrange.main}
          game={GameTitle.BASICS}
          checked={basicsChecked}
          setChecked={(value) => handleCheck(value, GameTitle.BASICS)}
        />
        <GameCheckboxHorizontalCard
          color={theme.palette.newPink.main}
          game={GameTitle.CREATOR}
          checked={creatorChecked}
          setChecked={(value) => handleCheck(value, GameTitle.CREATOR)}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          color="blue"
          size="large"
          disableElevation={false}
          onClick={() => addNewClassForm.submitForm()}
          disabled={!addNewClassForm.isValid || addNewClassForm.isSubmitting || addNewClassForm.values.assigned_games.length === 0}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}