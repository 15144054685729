import {Box, Link, Typography} from "@material-ui/core";
import LessonSubSection from "./LessonSubSection";
import Button from "../../../../components/ui/buttons/Button";
import {ILesson} from "../../../../types/ILesson";
import React from "react";

const LessonSummary: React.VFC<{lesson: ILesson}> = ({lesson}) => {
  return (
    <Box width={'35%'} style={{backgroundColor: '#f3f3f4', borderRadius: 15, padding: 30}}>
      <Typography variant={'h1'} style={{fontWeight: 500}}>Lesson Summary</Typography>
      <div dangerouslySetInnerHTML={{ __html: lesson.short_description}} />

      <LessonSubSection
        title={'Objectives'}
        icon={'/images/curriculum_images/Heart.png'}
        iconColor={'#800080'}
        body={lesson.short_objectives}
      />
      <LessonSubSection
        icon={'/images/curriculum_images/Materials.png'}
        iconColor={'#fbaf3f'}
        title={'Printable Materials'}
      />
      <LessonSubSection
        icon={'/images/curriculum_images/Vocabulary.png'}
        iconColor={'#8cc63e'}
        title={`${lesson.niceGrades} Vocabulary Words`}
      />
      <Box display={'flex'} justifyContent={'center'}>
        <Link component={Button}
              href={`${process.env.REACT_APP_KODABLE_WEBSITE}/register?lesson=${lesson.unit_id}`}
              underline={'none'}
              style={{backgroundColor: '#00a79d', color: 'white'}}
        >
          Sign up to view this lesson
        </Link>
      </Box>
    </Box>
  )
}

export default LessonSummary