import {Box, Link} from "@material-ui/core";
import Button from "../../../../components/ui/buttons/Button";

const LessonAppBar = () => {
  return (
    <Box display={'flex'} flexDirection={'row'} style={{position: 'fixed', zIndex: 1000, backgroundColor: 'white', width: '100%'}}>
      <img src={'/images/logos/kodable-logo.svg'} width={160} style={{marginLeft: 20, height: 75}}/>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} style={{width: '100%', height: 35, marginTop: 20, marginRight: 40}}>
        <Link component={Button}
              href={`${process.env.REACT_APP_KODABLE_WEBSITE}/register?lesson=all`}
              underline={'none'}
              style={{backgroundColor: '#fbaf3f', color: 'white', width: 150, marginRight: 10, fontWeight: 600}}
        >
          Get Started
        </Link>
        <Link component={Button}
              href={`${process.env.REACT_APP_DASHBOARD_ENDPOINT}/login`}
              underline={'none'}
              style={{borderColor: '#00a79d', color: '#00a79d', borderRadius: 2, fontWeight: 600, border: '1px solid'}}
        >
          Login
        </Link>
      </Box>
    </Box>
  )
}

export default LessonAppBar