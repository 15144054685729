import {Box, CircularProgress, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import mutate from "../../mutations/mutate";
import endpoints from "../../endpoints"
import {routes} from "../../routes";
import {useHistory} from "react-router-dom";

export default function VerificationIndex() {
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState(false);
  const history = useHistory();
  
  interface IResponse{
    error?: string,
    status?: number
  }

  useEffect(() => {
    const verify = async () => {
      try {
        const email = decodeURIComponent(window.location.search.substring(7)); // strip ?email=
        const response: IResponse = await mutate(endpoints.verify, 'POST', {email: email});
        if (response.status || response.error) {
          setError(true);
          return;
        }
        setVerifying(false);
        setTimeout(() => {
          history.push(`${routes.login}`);
        }, 1500);
      } catch (err) {
        console.log(err);
        setError(true);
      }
    }
    verify();
  }, []);
  return <Box
    width='100%'
    display='flex'
    flexDirection='column'
    alignItems='center'>
    <img
      style={{
        height: 150,
        margin: 25,
        marginTop: 30
      }}
      src="/images/logos/kodable-edu-color-vertical.svg"
      alt="Kodable Logo"
    ></img>
    {error ? <Typography>An error occurred. Please contact Kodable support.</Typography>
    :
    <>
      {verifying ? <>
        <Typography style={{paddingBottom: 10}}>Verifying email...</Typography>
        <CircularProgress />
      </>: <>
        <Typography variant='h1'>Success!</Typography>
        <Typography>You will be redirected shortly...</Typography>
      </>}
    </>
    }
    
  </Box>
}