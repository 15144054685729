import { Form, FormikProvider, useFormik, useFormikContext } from "formik"
import { GodmodePageWrapper } from "pages/godmode/GodmodePageWrapper"
import * as Yup from 'yup'
import { Box, Divider, FormControlLabel, FormGroup, MenuItem, Switch, Typography } from "@material-ui/core"
import TextField from "components/ui/TextField"
import useSharedStyles from "components/useSharedStyles"
import Select from "components/ui/Select"
import Button from "components/ui/buttons/Button"
import { useHistory } from "react-router-dom"
import { routes } from "routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faSave, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { justFetch } from "mutations/mutate"
import endpoints from "endpoints"
import { useAlert } from "context/AlertProvider"
import React from "react";

interface ISchoolsAddPageFormValues {
    school_name: string;
    school_location_address: string;
    school_location_city: string;
    school_location_state: string;
    school_location_zip: string;
    school_phone: string;
    mailing_address_different: boolean;
    school_mailing_address?: string;
    school_mailing_city?: string;
    school_mailing_state?: string;
    school_mailing_zip?: string;
    school_grade_level: "Elementary" | "Secondary" | "Combined";
    school_low_grade: "-1" | "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12";
    school_high_grade: "-1" | "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12";
    is_public: boolean;
    agency_name?: string;
    school_nces_code?: number;
    school_nces_status?: number;
    ansi_state_code?: string;
    lea_id?: string;
    state_agency_id?: string;
}

export const validationSchema = Yup.object().shape<ISchoolsAddPageFormValues>({
    school_name: Yup.string().required('School name is required'),
    school_location_address: Yup.string().required('School location address is required'),
    school_location_city: Yup.string().required('School location city is required'),
    school_location_state: Yup.string()
        .matches(/^[A-Z]{2}$/, 'Must be a valid 2-letter state code')
        .required('School location state is required'),
    school_location_zip: Yup.string().required('School location zip is required'),
    school_phone: Yup.string().required('School phone is required'),
    mailing_address_different: Yup.boolean(),
    school_mailing_address: Yup.string().when('mailing_address_different', {
        is: true,
        then: Yup.string().required('Mailing address is required when mailing address is different'),
    }),
    school_mailing_city: Yup.string().when('mailing_address_different', {
        is: true,
        then: Yup.string().required('Mailing city is required when mailing address is different'),
    }),
    school_mailing_state: Yup.string().when('mailing_address_different', {
        is: true,
        then: Yup.string()
            .matches(/^[A-Z]{2}$/, 'Must be a valid 2-letter state code')
            .required('Mailing state is required when mailing address is different'),
    }),
    school_mailing_zip: Yup.string().when('mailing_address_different', {
        is: true,
        then: Yup.string().required('Mailing zip is required when mailing address is different'),
    }),
    school_grade_level: Yup.string()
        .oneOf(['Elementary', 'Secondary', 'Combined'], 'Invalid grade level')
        .required('Grade level is required'),
    school_low_grade: Yup.string()
        .oneOf(["-1", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"], 'Invalid low grade')
        .required('Low grade is required'),
    school_high_grade: Yup.string()
        .oneOf(["-1", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"], 'Invalid high grade')
        .required('High grade is required')
        .test(
            'is-greater',
            'High grade must be greater than or equal to low grade',
            function (value) {
                const { school_low_grade } = this.parent;
                return parseInt(value!) >= parseInt(school_low_grade);
            }
        ),
    is_public: Yup.boolean().required('Public school status is required'),
});

export const convertStringToInt = (value: string | number): number => {
    if (typeof value === 'number') {
      return value
    }
    const stripped = value.replace(/\D/g, ''); // Remove all non-digit characters
    const asNumber = parseInt(stripped, 10);
    return asNumber
}

export const SchoolsAddPage: React.FC = () => {
    const alert = useAlert()
    const history = useHistory()
    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            school_name: '',
            school_location_address: '',
            school_location_city: '',
            school_location_state: '',
            school_location_zip: '',
            school_phone: '',
            mailing_address_different: false,
            school_mailing_address: '',
            school_mailing_city: '',
            school_mailing_state: '',
            school_mailing_zip: '',
            school_grade_level: '',
            school_low_grade: '',
            school_high_grade: '',
            is_public: true,
            agency_name: '',
            school_nces_code: '',
            school_nces_status: '',
            ansi_state_code: '',
            lea_id: '',
            state_agency_id: ''
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                actions.setSubmitting(true)
                const submitData = {
                    is_public: values?.is_public,
                    school_phone: convertStringToInt(values?.school_phone),
                    diffMailing: values?.mailing_address_different,
                    school_name: values?.school_name,
                    school_location_address: values?.school_location_address,
                    school_location_city: values?.school_location_city,
                    school_location_state: values?.school_location_state,
                    school_location_zip: convertStringToInt(values?.school_location_zip),
                    grade_levels: values?.school_grade_level,
                    school_low_grade: convertStringToInt(values?.school_low_grade),
                    school_high_grade: convertStringToInt(values?.school_high_grade),
                    school_mailing_address: values?.mailing_address_different ? values?.school_mailing_address : values?.school_location_address,
                    school_mailing_city: values?.mailing_address_different ? values?.school_mailing_city : values?.school_location_city,
                    school_mailing_state: values?.mailing_address_different ? values?.school_mailing_state : values?.school_location_state,
                    school_mailing_zip: values?.mailing_address_different ? convertStringToInt(values?.school_mailing_zip) : convertStringToInt(values?.school_location_zip),
                    agency_name: values?.agency_name,
                    school_nces_code: values?.school_nces_code,
                    school_nces_status: values?.school_nces_status,
                    ansi_state_code: values?.ansi_state_code,
                    lea_id: values?.lea_id,
                    state_agency_id: values?.state_agency_id
                }
                justFetch(endpoints.godmode.schoolsPost, 'POST', submitData)
                .then((res) => {
                    if (res.ok) {
                        alert.success("School added successfully!")
                        actions.resetForm()
                        return
                    }
                    alert.error("School could not be added due to an error")
                    actions.setSubmitting(false)
                })
            } catch (e) {
                console.log(e)
                alert.error("School could not be added due to an error.")
                actions.setSubmitting(false)
            }
        }
    })
    return (
        <GodmodePageWrapper title="Add School" loading={false}>
            <FormikProvider value={form}>
                <SchoolsForm onCancel={() => history.push(routes.godmode.schools.index)} />
            </FormikProvider>
        </GodmodePageWrapper>
    )
}

export const SchoolsForm: React.FC<{isEditing?: boolean, onCancel: () => void}> = ({isEditing = false, onCancel}) => {
    const sharedClasses = useSharedStyles()
    const formikContext = useFormikContext<ISchoolsAddPageFormValues>();
    const { isSubmitting, isValid, values } = formikContext

    return (
        <Form className={sharedClasses.vspacing4}>
            <SchoolsAddPageFormBasicInfo />
            <Divider />
            <SchoolsAddPageFormContactInfo />
            <Divider />
            <SchoolsAddPageFormSchoolType />
            {values?.is_public &&
                <>
                <Divider />
                <SchoolsAddPageFormPublicSchoolDetails />
                </>
            }
            <Box display="flex" alignItems="center" className={sharedClasses.hspacing2}>
                <Button
                    startIcon={<FontAwesomeIcon icon={faXmarkCircle} />}
                    variant="contained"
                    color="red"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    startIcon={<FontAwesomeIcon icon={isEditing ? faSave : faCheckCircle} />}
                    type="submit"
                    variant="contained"
                    color="green"
                    disabled={isSubmitting || (!isEditing && !isValid)}
                >
                    {isEditing ? 'Save Changes' : 'Create'}
                </Button>
            </Box>
        </Form>
    )
}

const SchoolsAddPageFormBasicInfo: React.FC = () => {
    const { values, touched, errors, handleChange } = useFormikContext<ISchoolsAddPageFormValues>()
    const sharedClasses = useSharedStyles()
    return (
        <Box className={sharedClasses.vspacing2}>
            <Typography variant="h1">Basic Info</Typography>
            <TextField
                value={values.school_name}
                id="school_name"
                name="school_name"
                label="School Name"
                fullWidth
                error={touched.school_name && !!errors.school_name}
                onChange={handleChange}
            />
            <TextField
                value={values.school_location_address}
                id="school_location_address"
                name="school_location_address"
                label="Address"
                fullWidth
                error={touched.school_location_address && !!errors.school_location_address}
                onChange={handleChange}    
            />
            <TextField
                value={values.school_location_city}
                id="school_location_city"
                name="school_location_city"
                label="City"
                fullWidth
                error={touched.school_location_city && !!errors.school_location_city}
                onChange={handleChange}    
            />
            <TextField
                value={values.school_location_state}
                id="school_location_state"
                name="school_location_state"
                label="State"
                fullWidth
                error={touched.school_location_state && !!errors.school_location_state}
                onChange={handleChange}    
            />
            <TextField
                value={values.school_location_zip}
                id="school_location_zip"
                name="school_location_zip"
                label="Zip Code"
                fullWidth
                error={touched.school_location_zip && !!errors.school_location_zip}
                onChange={handleChange}    
            />
        </Box>
    )
}

const SchoolsAddPageFormContactInfo: React.FC = () => {
    const { values, touched, errors, handleChange } = useFormikContext<ISchoolsAddPageFormValues>()
    const sharedClasses = useSharedStyles()
    return (
        <Box className={sharedClasses.vspacing2}>
            <Typography variant="h1">Contact Info</Typography>
            <TextField
                value={values.school_phone}
                id="school_phone"
                name="school_phone"
                label="Phone Number"
                fullWidth
                error={touched.school_phone && !!errors.school_phone}
                onChange={handleChange}    
            />
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={values.mailing_address_different}
                            id="mailing_address_different"
                            name="mailing_address_different"
                            onChange={handleChange}
                        />
                    }
                    label="Mailing different from location"
                />
            </FormGroup>
            {values.mailing_address_different && 
                <SchoolsAddPageFormContactInfoMailing />
            }
        </Box>
    )
}

const SchoolsAddPageFormContactInfoMailing: React.FC = () => {
    const { values, touched, errors, handleChange } = useFormikContext<ISchoolsAddPageFormValues>()

    return (
        <>
        <TextField
            value={values.school_phone}
            id="school_mailing_address"
            name="school_mailing_address"
            label="Mailing Address"
            fullWidth
            error={touched.school_mailing_address && !!errors.school_mailing_address}
            onChange={handleChange}    
        />
        <TextField
            value={values.school_mailing_city}
            id="school_mailing_city"
            name="school_mailing_city"
            label="Mailing City"
            fullWidth
            error={touched.school_mailing_city && !!errors.school_mailing_city}
            onChange={handleChange}
        />
        <TextField
            value={values.school_mailing_state}
            id="school_mailing_state"
            name="school_mailing_state"
            label="Mailing State"
            fullWidth
            error={touched.school_mailing_state && !!errors.school_mailing_state}
            onChange={handleChange}
        />
        <TextField
            value={values.school_mailing_zip}
            id="school_mailing_zip"
            name="school_mailing_zip"
            label="Mailing Zip Code"
            fullWidth
            error={touched.school_mailing_zip && !!errors.school_mailing_zip}
            onChange={handleChange}
        />
        </>
    )
}

const SchoolsAddPageFormSchoolType: React.FC = () => {
    const { values, touched, errors, handleChange } = useFormikContext<ISchoolsAddPageFormValues>()
    const sharedClasses = useSharedStyles()
    
    
    return (
        <Box className={sharedClasses.vspacing2}>
            <Typography variant="h1">School Type</Typography>
            <Select
                id="school_grade_level"
                name="school_grade_level"
                label="Grade Levels"
                fullWidth
                error={touched.school_grade_level && !!errors.school_grade_level}
                value={values.school_grade_level}
                onChange={handleChange}
            >
                <MenuItem value="Elementary">Elementary</MenuItem>
                <MenuItem value="Secondary">Secondary</MenuItem>
                <MenuItem value="Combined">Combined</MenuItem>
            </Select>
            <SchoolsAddPageFormSchoolTypeGradeSelect
                name="school_low_grade"
                label="Low Grade"
                error={(touched.school_low_grade && !!errors.school_low_grade)}
                value={values.school_low_grade}
            />
            <SchoolsAddPageFormSchoolTypeGradeSelect
                name="school_high_grade"
                label="High Grade"
                error={(touched.school_high_grade && !!errors.school_high_grade)}
                value={values.school_high_grade}
            />
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={values.is_public}
                            id="is_public"
                            name="is_public"
                            onChange={handleChange}
                        />
                    }
                    label="Is this a public school?"
                />
            </FormGroup>
        </Box>
    )
}

const SchoolsAddPageFormPublicSchoolDetails: React.FC = () => {
    const { values, touched, errors, handleChange } = useFormikContext<ISchoolsAddPageFormValues>()
    const sharedClasses = useSharedStyles()
    return (
        <Box className={sharedClasses.vspacing2}>
            <Typography variant="h1">Public School Details</Typography>
            <TextField
                value={values.agency_name}
                id="agency_name"
                name="agency_name"
                label="District Name"
                fullWidth
                error={touched.agency_name && !!errors.agency_name}
                onChange={handleChange}    
            />
            <TextField
                value={values.school_nces_code}
                id="school_nces_code"
                name="school_nces_code"
                label="NCES Code"
                fullWidth
                error={touched.school_nces_code && !!errors.school_nces_code}
                onChange={handleChange}    
            />
            <TextField
                value={values.school_nces_status}
                id="school_nces_status"
                name="school_nces_status"
                label="NCES Status"
                fullWidth
                error={touched.school_nces_status && !!errors.school_nces_status}
                onChange={handleChange}    
            />
            <TextField
                value={values.ansi_state_code}
                id="ansi_state_code"
                name="ansi_state_code"
                label="ANSI State Code"
                fullWidth
                error={touched.ansi_state_code && !!errors.ansi_state_code}
                onChange={handleChange}    
            />
            <TextField
                value={values.lea_id}
                id="lea_id"
                name="lea_id"
                label="LEAID"
                fullWidth
                error={touched.lea_id && !!errors.lea_id}
                onChange={handleChange}    
            />
            <TextField
                value={values.state_agency_id}
                id="state_agency_id"
                name="state_agency_id"
                label="State Agency ID"
                fullWidth
                error={touched.state_agency_id && !!errors.state_agency_id}
                onChange={handleChange}    
            />
        </Box>
    )
}

const SchoolsAddPageFormSchoolTypeGradeSelect: React.FC<{name: string, label: string, error: boolean | undefined, value: string}> = ({name, label, error, value}) => {
    const { handleChange } = useFormikContext<ISchoolsAddPageFormValues>()
    return (
        <Select
            id={name}
            name={name}
            label={label}
            fullWidth
            value={value}
            error={error}
            onChange={handleChange}
        >
            <MenuItem value="-1">Pre-K</MenuItem>
            <MenuItem value="0">Kindergarten</MenuItem>
            <MenuItem value="1">1st</MenuItem>
            <MenuItem value="2">2nd</MenuItem>
            <MenuItem value="3">3rd</MenuItem>
            <MenuItem value="4">4th</MenuItem>
            <MenuItem value="5">5th</MenuItem>
            <MenuItem value="6">6th</MenuItem>
            <MenuItem value="7">7th</MenuItem>
            <MenuItem value="8">8th</MenuItem>
            <MenuItem value="9">9th</MenuItem>
            <MenuItem value="10">10th</MenuItem>
            <MenuItem value="11">11th</MenuItem>
            <MenuItem value="12">12th</MenuItem>  
        </Select>
    )
}