import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ISubscription } from 'types/ISubscription';

export type ISubscriptionActiveState = 'active' | 'expired' | 'upcoming' | 'downgraded'

export const SubscriptionsActiveIndicator: React.FC<{subscription: ISubscription | undefined}> = ({subscription}) => {
    const [state, setState] = useState<ISubscriptionActiveState>();
    const [color, setColor] = useState<string>();
    
    useEffect(() => {
        if (!subscription) {
          return
        }
        const isStarted = subscription?.start_date ? new Date(subscription?.start_date) <= new Date() : false;
        const isEnded = subscription?.end_date ? new Date(subscription?.end_date) < new Date() : false;

        if (subscription?.purchase_process?.state === 'delinquent') {
            setState('downgraded')
            setColor('#e84461')
            return
        }

        if (!isStarted) {
            setState('upcoming')
            setColor('#e6e6e6')
            return
        }

        if (isEnded) {
            setState('expired')
            setColor('#e6e6e6')
            return
        }

        if (isStarted && !isEnded) {
            setState('active')
            setColor('#98c355')
            return
        }
    }, [subscription])
    
    if (!state || !color) {
        return null
    }

    return (
        <Box pb={1} display="flex" alignItems="center" justifyContent="center" style={{backgroundColor: color, padding: '.5rem 1rem'}}>
            <Typography style={{textTransform: 'capitalize'}}><strong>{state}</strong></Typography>
        </Box>
    )
}