import React from 'react';
import CategoryVideos from './shared/CategoryVideos';

const CreateActivityVideosPage: React.FC = () => {
  return (
    <CategoryVideos
      title="Creative Activity Videos"
      resourceType="creative_project_videos"
      link="creative-activities"
    />
  );
}

export default CreateActivityVideosPage;
