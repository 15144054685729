import React from "react";
import {DialogStateProps} from "hooks/useDialogState";
import {IKlass} from "types/IKlass";
import {Dialog} from "@material-ui/core";
import PrintDialog from "components/dialogs/PrintDialog";
import {routes} from "routes";
import {TrackParentInviteMethodLocation} from "../../utils/ParentInvites";

export const StudentInstructionsDialog: React.VFC<DialogStateProps & { klass: IKlass }> = ({open, onClose, klass}) => {
  return <Dialog open={open}>
    <PrintDialog
      open={true}
      onClose={() => {
        onClose()
      }}
      handoutUrl={routes.handouts.studentHandouts(klass?.id || '').slice(1)}
      filename={`Kodable Login Instructions - ${klass.klass_name}`}
      v2={true}
      isParentLetters={true}
      klass={klass}
      location={TrackParentInviteMethodLocation.getStudentsLoggedIn}
    />
  </Dialog>;
}