import {
    Box,
    CircularProgress, Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    useTheme
} from "@material-ui/core";
import Button from "../../components/ui/buttons/Button";
import PageContainer from "components/ui/PageContainer"
import React, {useEffect, useMemo, useState} from "react";
import useSharedStyles from "components/useSharedStyles";
import {GameTitle} from "../../enums/GameTitle";
import useSWR, {mutate} from "swr";
import {ICourseData} from "./ICourseData";
import endpoints from "../../endpoints";
import {
    convertCreativeBuildingsToSectionedCourses,
    getOrderedCoursesBySection,
    orderedSections
} from "./courseSections";
import {creativeBuildingsData} from "./CreativeBuildingsData";
import {IKlass} from "../../types/IKlass";
import {useRouteMatch} from "react-router-dom";
import {IRouteParams} from "../../AppRoutes";
import {GameSelectDropdown} from "../../components/games/GameSelectDropdown";
import AssignmentsCourseList from "./components/AssignmentsCourseList";
import {justFetch} from "../../mutations/mutate";
import {useAlert} from "../../context/AlertProvider";
import {gradesArray} from "../../types/Grades";
import {reset} from "linkifyjs";

const AssignmentsIndex: React.FC = () => {
    const sharedClasses = useSharedStyles()
    const theme = useTheme()

    const [selectedGame, setSelectedGame] = useState<GameTitle>(GameTitle.BASICS);
    const [selectedGrade, setSelectedGrade] = useState<string>("k");
    const [customAssignments, setCustomAssignments] = useState<boolean>(false);
    const [resetAssignments, setResetAssignments] = useState<boolean>(false);

    const { params } = useRouteMatch<IRouteParams>();

    const { data: courseData, error: courseDataError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
    const { data: creatorCourseData, error: creatorCourseDataError } = useSWR<ICourseData[]>(endpoints.allCreatorCourses);

    const { data: klassData } = useSWR<IKlass>(endpoints.classById(params.classId, ['assignments']))

    const alert = useAlert();
    
    const orderedCourses = useMemo(() => {
        if (!courseData || !creatorCourseData) {
            return undefined
        }
        courseData.forEach(course => { 
            course.meta.gameTitle = GameTitle.BASICS;
            course.meta.isCreativeArea = false;
        });
        creatorCourseData.forEach(course => {
            course.meta.gameTitle = GameTitle.CREATOR;
            course.meta.isCreativeArea = false;
        });
        const orderedBySection = getOrderedCoursesBySection([
            ...courseData!,
            ...convertCreativeBuildingsToSectionedCourses(creativeBuildingsData) as ICourseData[],
            ...creatorCourseData!
        ]);
        const result = orderedSections
            .reduce((acc: ICourseData[], section) => {
                return acc.concat(orderedBySection[section] || []);
            }, []);
        return result;

    },[courseData, creatorCourseData, creativeBuildingsData]);

    const courseDataLoading = !klassData || (!courseData && !courseDataError || (!creatorCourseData && !creatorCourseDataError)) || !orderedCourses;

    function filteredCourses(): ICourseData[] {
        return orderedCourses!.filter(course => {
            
            if (course.id === 6){
                return false;
            }
            if (selectedGame !== course.meta.gameTitle) {
                return false;
            }
            return true;
        });
    }
    
    const hasAssignments = useMemo(() => {
        if (klassData?.assignments?.courses?.length 
            && courseData?.length 
            && klassData?.assignments?.courses?.length < courseData?.length){
            return true; // a basics course is unassigned
        }
        if (klassData?.assignments?.creator_courses?.length 
            && creatorCourseData?.length 
            && klassData?.assignments?.creator_courses?.length < creatorCourseData?.length){
            return true; // a creator course is unassigned
        }
        if (Object.values(klassData?.assignments?.creative_locks || {}).includes(true)) {
            return true; // a creative area is unassigned
        }
        if (klassData?.assignments?.mission_omissions?.length) {
            return true; // a mission is unassigned
        }
        return false;
    }, [klassData]);

    const creativeCourseKey = (course : ICourseData)=> course.meta.dashboardTitle!.replace(/\s/g,"")

    const handleToggleAll = async (checked: boolean) => {

        // handle courses
        const courseIDs = filteredCourses().filter((course)=> !course.meta.isCreativeArea).map((course) => {return course.meta.gameTitle == GameTitle.CREATOR ? course.id - 1000 : course.id});
        const coursesForClass = selectedGame == GameTitle.BASICS? endpoints.coursesForClass : endpoints.creatorCoursesForClass;
        const coursesRes = await justFetch(coursesForClass(klassData?.id!), checked ? 'POST' : 'DELETE', { course_id: courseIDs })
        if (!coursesRes.ok) {
            alert.error(`Unable to  ${checked ? 'Assign' : 'Unassign'}`);
            return;
        }

        // Handle creative areas
        const creativeAreasKeys = filteredCourses().filter((course)=> course.meta.isCreativeArea).map((course) => {return creativeCourseKey(course)});

        const creativeLocks = creativeAreasKeys.reduce((obj, key) => {
            obj[key] = !checked;
            return obj;
        }, {} as { [key: string]: boolean });

        const creativeLocksRes = await justFetch(endpoints.klassCreativeLock(klassData?.id!), 'PUT', creativeLocks)
        if (!creativeLocksRes.ok) {
            alert.error(`Unable to  ${checked ? 'Assign' : 'Unassign'}`);
            return;
        }

        // mutate state
        const assignmentsKey = selectedGame == GameTitle.BASICS? "courses" : "creator_courses";
        let newAssignments = {...klassData!.assignments}
        newAssignments['creative_locks'] = creativeLocks;
        newAssignments[assignmentsKey] = checked ? [...courseIDs] : []

        await mutate(endpoints.classById(klassData?.id!, ['assignments']), {
            ...klassData,
            assignments: newAssignments
        });

        // 
        alert.success(` ${checked ? 'All Assigned' : 'All Unassigned'}`);
    }
    
    const setDefaultAssignments = async () => {        
        let creativeLocks = {"Pets": false, "MazeMaker": false, "FuzzBuilder": false};
        let basicsOmissions: number[] = [];
        let creatorOmissions: number[] = [];
        let missionOmissions: string[] = [];
        
        switch (selectedGrade) {
            case "k":
                creativeLocks = {"Pets": true, "MazeMaker": true, "FuzzBuilder": false};
                basicsOmissions = [3,4,7,9];
                creatorOmissions = [1,2,3,4];
                missionOmissions = ["2-2","2-3"]
                break;
            case "1":
                basicsOmissions = [9];
                creatorOmissions = [1,2,3,4];
                missionOmissions = ["3-2","3-3","4-2","4-3","8-0","8-1"]
                break;
            case "2":
                creatorOmissions = [1,2,3,4];
                missionOmissions = ["2-0","2-1","2-2","8-0","8-1","8-2"]
                break;
            case "3":
                creatorOmissions = [4]
        }

        const coursesRes = await justFetch(endpoints.coursesForClass(klassData?.id!), 'PUT', { course_id: basicsOmissions })
        if (!coursesRes.ok) {
            alert.error(`Unable to  process basics courses`);
            return;
        }
        const creatorRes = await justFetch(endpoints.creatorCoursesForClass(klassData?.id!), 'PUT', { course_id: creatorOmissions })
        if (!creatorRes.ok) {
            alert.error(`Unable to  process creator courses`);
            return;
        }

        const creativeLocksRes = await justFetch(endpoints.klassCreativeLock(klassData?.id!), 'PUT', creativeLocks)
        if (!creativeLocksRes.ok) {
            alert.error(`Unable to  process creative`);
            return;
        }
        const missionsRes = await justFetch(endpoints.missionsForClass(klassData?.id!), 'PUT', { missions: missionOmissions })
        if (!missionsRes.ok) {
            alert.error(`Unable to  process missions`);
            return;
        }

        
        let newAssignments = {...klassData!.assignments}
        newAssignments['creative_locks'] = creativeLocks;
        newAssignments['courses'] = basicsOmissions;
        newAssignments['creator_courses'] = creatorOmissions;
        newAssignments['mission_omissions'] = missionOmissions;

        await mutate(endpoints.classById(klassData?.id!, ['assignments']), {
            ...klassData,
            assignments: newAssignments
        });
        setCustomAssignments(true); // grades 4 and 5 just have everything assigned
    }
    
    

    return (
        <>
            {(hasAssignments || customAssignments) && !resetAssignments ? (<>
            <Box zIndex={2}
                 display="flex"
                 flexDirection="row"
                 justifyContent="flex-start"
                 alignItems="center"
                 paddingLeft="39px"
                 style={{marginTop:"76px",
                     height:"100px",
                     width:"100%",
                     borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                     position:"fixed",
                     backgroundColor:"#fff",
                     gap: "50px"}}>
                <Button variant="outlined" style={{height:"40px", width:"200px"}} onClick={() => {
                    setResetAssignments(true);
                    setCustomAssignments(false);
                }}>Grade Suggestions</Button>
                <Box width="372px" marginTop="20px" height="60px" style={{
                    borderRadius: "10px",
                }}>
                    <GameSelectDropdown
                        klassId={Number(params.classId)}
                        selectedGame={selectedGame}
                        setSelectedGame={setSelectedGame}
                    />
                </Box>
                <Button variant="outlined" color="primary" style={{height:"40px", width:"200px"}} onClick={() => {
                    handleToggleAll(true);
                }}>Assign All</Button>
                <Button variant={"outlined"} color="red" style={{height:"40px", width:"200px"}}onClick={() => {
                    handleToggleAll(false);
                }}>Unassign All</Button>
            </Box>
            <PageContainer>

                <Box style={{overflow:"auto", width:"100%", marginTop: "100px"}}>
                    <Box className={sharedClasses?.vspacing4}>
                        {courseDataLoading || !klassData ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        ):(
                            <AssignmentsCourseList courses={filteredCourses()} klassData={klassData!} />
                        )}
                    </Box>
                </Box>
            </PageContainer>
                </>) : 
                
                (<Box display="flex" flexDirection="column" mt="25px" justifyContent="center" alignItems="center">
                    <Typography variant="h4">Customize Course Assignments</Typography>
                    <Typography>Select grade levels to use as a template for your custom course assignments</Typography>
                    <Box height="20px" />
                    <Box ml="35px">
                    <FormControl component="fieldset" >
                        <RadioGroup aria-label="grade" name="grade" value={selectedGrade} onChange={(e) => setSelectedGrade(e.target.value)}>
                            {gradesArray.map((grade) => {
                                if (grade.key == "all"){
                                    return <></>
                                }
                                return (
                                    
                                    <FormControlLabel value={grade.key} control={<Radio />} label={
                                        <Box display="flex" flexDirection="row" alignItems="center" style={{gap:"3px"}}>
                                            <Box mr={0.5} borderRadius="50%" height={18} width={18} fontSize={12} style={{ 
                                                backgroundColor: ["k", "1", "2"].includes(grade.key) ? theme.palette.newOrange.main : theme.palette.newPink.main,
                                                color: 'white' }} display="flex" alignItems="center" justifyContent="center">
                                            {grade.key.toLocaleUpperCase()}
                                            </Box>
                                            <Typography>{grade.name}</Typography>
                                        </Box>
                                    } />
                                )
                            })}
                        </RadioGroup>
                    </FormControl></Box>
                    <Box height="20px" />
                    <Button variant="contained" color="primary" onClick={() => {
                        setCustomAssignments(false);
                        setResetAssignments(false);
                        setDefaultAssignments();
                    }}>Use Selected Grade</Button>
                    <Box height="40px" width="300px" display="flex" flexDirection="row" alignItems="center" justifyContent="center" style={{gap:"5px"}}>
                        <Box width="100px" height = "1px" style={{backgroundColor: "#bbb"}}/>
                        <Typography>or</Typography>
                        <Box width="100px" height = "1px" style={{backgroundColor: "#bbb"}}/>
                    </Box>
                    <Button variant="outlined" color="primary" onClick={() => {
                        setCustomAssignments(true);
                        setResetAssignments(false);
                    }}>Advanced Customization</Button>
                    
                </Box>)}
        </>
    )
}

export default AssignmentsIndex





