import React, { useState } from 'react';
import useStudent from 'loaders/useStudent';
import useProjects from 'loaders/useProjects';
import FuzzImage from 'components/misc/FuzzImage';
import MazeImage from 'components/misc/MazeImage';
import FuzzDetailsDialog from 'components/dialogs/student/content/FuzzDetailsDialog';
import PrintModal from 'components/modal/PrintModal';
import Loading from 'components/loading/Loading';
import {format, fromUnixTime, parseISO} from 'date-fns';
import Amplitude from 'utils/Amplitude';
import usePrintMazePdf from 'mutations/usePrintMazePdf';
import usePrintFuzzPdf from 'mutations/usePrintFuzzPdf';
import endpoints from "../../endpoints";
import {Box, Typography} from "@material-ui/core";
import ReactImageFallback from "react-image-fallback";
import StudentWorkGameCard from "../classes/StudentCreations/StudentWorkGameCard";
import useSharedStyles from "../../components/useSharedStyles";
import {IStudent} from "../../types/IStudent";
import {IFuzzData} from "../../types/IFuzzData";


interface IMazeProject {
  id: string,
  created_at: string,
  category: string,
  clazzName: string
}
interface IGameProject {
  id: string,
  created_at: string,
  category: string,
  clazzName: string
}

const FuzzProject: React.VFC<{student: IStudent, project: IFuzzData}> = ({ student, project }) => {
  const [open, setOpen] = useState(false);
  const printFuzzPdf = usePrintFuzzPdf(student.id, project.id);
  const [printing, setPrinting] = useState(false);

  return (
    <div className="lg:min-h-full max-w-lg rounded flex justify-center items-start flex-1 shadow-md border bg-white p-3">
      {printing && <PrintModal type="Fuzz" />}
      {open && (
        <FuzzDetailsDialog
          open={true}
          student={student}
          fuzz={project}
          onClose={() => setOpen(false)}
          onDelete={() => {}}
        />
      )}
      <div className="flex flex-col w-full h-full">
        <FuzzImage
          fuzzId={project.id}
          studentCode={student!.student_code}
        />
        <div className="flex flex-col justify-end items-end">
          <div className="w-full flex justify-between items-center">
            <p className="text-xl text-center font-bold">{project.config?.clazzName}</p>
            <p className="text-sm">
              Fuzz created {format(fromUnixTime(Number(project.created_at) / 1000), 'MMMM dd, yyyy')}
            </p>
          </div>

          <div className="w-full mt-4 flex items-end justify-end">
            <button
              onClick={() => setOpen(true)}
              className="mr-4 flex justify-center items-center font-bold text-sm lg:text-lg text-left text-kodable-blue border-2 border-kodable-blue py-1 px-2 lg:py-2 lg:px-4 rounded hover:text-white hover:bg-kodable-blue-dark active:text-white active:bg-kodable-blue-darker active:border-kodable-blue">
              <svg
                className="h-4 w-4 lg:w-6 lg:h-6 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                />
              </svg>
              View Code
            </button>
            <button
              onClick={() => {
                setPrinting(true);
                printFuzzPdf().then((data) => {
                  Amplitude.track('Generated Fuzz Handout');
                  setPrinting(false);
                });
              }}
              className="flex justify-center items-center font-bold text-sm lg:text-lg text-left text-kodable-green border-2 border-kodable-green py-1 px-2 lg:py-2 lg:px-4 rounded hover:text-white hover:bg-kodable-green-dark active:text-white active:bg-kodable-green-darker active:border-kodable-green">
              <svg
                className="h-4 w-4 lg:w-6 lg:h-6 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                  clipRule="evenodd"
                />
              </svg>
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const GameProject: React.VFC<{project: IGameProject}> = ({ project}) => {
  const [open, setOpen] = useState(false);
  const sharedClasses = useSharedStyles()

  return (
    <div className="lg:min-h-full max-w-lg rounded flex justify-center items-start flex-1 shadow-md border bg-white p-3">
      <div className="flex flex-col w-full h-full items-center">
        <StudentWorkGameCard
          gameId={project.id}
          cardImageSrc={endpoints.contentImageById(project.id)}
          cardImageFallbackSrc="/images/student-creations-page/no-game.png"
          cardContent={`Created ${format(parseISO(project.created_at), 'MMMM dd, yyyy')}`}
          dialogContent={<Box display="flex" flexDirection="column" className={sharedClasses.vspacing2}>
            <Box display="flex" justifyContent="center">
              <ReactImageFallback
                src={endpoints.contentImageById(project.id)}
                fallbackImage="/images/student-creations-page/no-game.png"
                style={{
                  width: '100%',
                }}
              />
            </Box>
            <Typography variant="body2">Game created {format(parseISO(project.created_at), 'MMMM dd, yyyy')}</Typography>
          </Box>}
        />
      </div>
    </div>
  );
}

const MazeProject: React.VFC<{student?: IStudent, project: IMazeProject}> = ({ student, project }) => {
  const printMazePdf = usePrintMazePdf(student!.id, project.id);
  const [printing, setPrinting] = useState(false);

  return (
    <div className="lg:min-h-full max-w-lg rounded flex justify-center items-start flex-1 shadow-md border p-3 bg-white">
      {printing && <PrintModal type="Maze" />}
      <div className="flex flex-col w-full">
        <div className="flex flex-col justify-between items-center w-full">
          <div className="p-2">
            {project.category === 'Maze' &&
              <MazeImage
                className="m-auto flex-1 object-cover shadow-md rounded"
                mazeId={project?.id}
                studentCode={student!.student_code}
              />
            }
          </div>
          <div className="w-full flex justify-end items-center py-1">
            <p className="text-sm">
              Maze created {format(parseISO(project.created_at), 'MMMM dd, yyyy')}
            </p>
          </div>
          <div className="w-full mt-4 flex items-end justify-end">
            <button
              onClick={() => {
                setPrinting(true);
                printMazePdf().then((data) => {
                  Amplitude.track('Generated Maze Handout');
                  setPrinting(false);
                });
              }}
              className="flex justify-center items-center font-bold text-sm lg:text-lg text-left text-kodable-green border-2 border-kodable-green py-1 px-2 lg:py-2 lg:px-4 rounded hover:text-white hover:bg-kodable-green-dark active:text-white active:bg-kodable-green-darker active:border-kodable-green">
              <svg
                className="h-4 w-4 lg:w-6 lg:h-6 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                  clipRule="evenodd"
                />
              </svg>
              Print & Share
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function StudentProjectsPage() {
  const student = useStudent();
  const { projects, loading } = useProjects();

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-32 h-64">
        <Loading />
      </div>
    );
  }

  return (
    <div className="max-w-screen-lg my-8 mx-8">
      <div className="flex mb-16">
        {projects.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full">
            {projects.map((project) => {
              if (project.type === 'maze' && student) {
                return (
                  <MazeProject
                    key={project.id}
                    project={project}
                    student={student}
                  />
                );
              } else if (project.type === 'fuzz') {
                return (
                  <FuzzProject
                    key={project.id}
                    project={project}
                    student={student}
                  />
                );
              } else if (project.type === 'game') {
                return (
                  <GameProject
                    key={project.id}
                    project={project}
                  />
                )
              }
            })}
          </div>
        ) : (
          <div className="flex w-full flex-col mb-12 border shadow rounded pb-4 pt-2 px-4 text-gray-800 bg-white">
            <h1 className="text-2xl mb-2">No Data</h1>
            <div>
              <p>
                It looks like this Learner hasn't created anything in Kodable
                yet!
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
